import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../context/GeneralContext";
import {Link, useHistory} from "react-router-dom";

//Componentes MUI
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";

//Iconos
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import EmailIcon from "@material-ui/icons/Email"
import { green, grey, red } from "@material-ui/core/colors";

import MarketingService from "../../services/MarketingService";
import NegociosServices from "../../services/NegociosServices";

export default function Marketing() {
  const {
    handleErrorMessage,
    handleActiveError,
    handleSelectRecord
  } = useContext(GeneralContext);
  let history = useHistory()

  const [loading, setLoading] = React.useState(false);
  const [resultado, setResultado] = React.useState<any[]>([]);

  useEffect(() => {
    obtenerCampanas();
  }, []);

  const obtenerCampanas = () => {
    setLoading(true);
    MarketingService.instance
      .obtenerCampanas()
      .then((response) => {
        const { error, result } = response;
        if (!error) {
          setResultado(result);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        handleErrorMessage("message");
        handleActiveError(true);
      });
  };

  const editRecord = (record: any) => {
    const { id } = record
    handleSelectRecord(record)
    history.push(`/admin-home/marketing/${id}`)
  }
  
  const obtenerIcono = (tipo: string) => {
    if (tipo == "email"){
      return <>
        <EmailIcon
          fontSize="large"
          style={{ color: red[500] }}
        />
      </>
    } else {
      return <>
        <AndroidIcon
          fontSize="large"
          style={{ color: green[500] }}
        />
        <AppleIcon fontSize="large" style={{ color: grey[500] }} />
      </>
    }   
  }

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Marketing
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                component={Link}
                to="marketing/create"
                fullWidth
                variant="contained"
                color="primary"
              >
                Crear Campaña
              </Button>
            </Grid>
            <Chip style={{ marginLeft: 5 }} label="Todos" color="primary" />
            <Chip style={{ marginLeft: 5 }} label="Activo" color="primary" />
            <Chip
              style={{ marginLeft: 5 }}
              label="Desactivados"
              color="primary"
            />
            <Chip
              style={{ marginLeft: 5 }}
              label="Automaticos"
              color="primary"
            />
            <Chip style={{ marginLeft: 5 }} label="Manuales" color="primary" />
            <Chip style={{ marginLeft: 5 }} label="Android" color="primary" />
            <Chip style={{ marginLeft: 5 }} label="iOS" color="primary" />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            {resultado.map((resultado) => (
              <Grid
                key={resultado.index}
                item
                style={{ marginRight: 15 }}
                xs={3}

              >
                <Card  onClick={() => {
                  editRecord(resultado)
                }} variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe">
                        {resultado.modo[0].toUpperCase()}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={resultado.nombre.slice(0,10)}
                    subheader={resultado.fecha}
                  />
                  <CardContent>
                    <Typography variant="body1">Plataforma</Typography>
                    {obtenerIcono(resultado.tipo)}
                    <br />
                    <Typography variant="body1">Notificación</Typography>
                    <Typography variant="body2">
                      Titulo:
                      <Typography variant="body2" color="textSecondary">
                        {resultado.titulo.slice(0,20)}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      Cuerpo:
                      <Typography variant="body2" color="textSecondary">
                        {resultado.cuerpo.slice(0,20)}
                      </Typography>
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      Modo de ejecución:
                      <Typography variant="body2" color="textSecondary">
                        {resultado.modo}{" "}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {resultado.hora}{" "}
                      </Typography>
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      Estado:
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ color: green[500] }}
                      >
                        {resultado.estado}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
