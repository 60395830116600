import SwipeableViews from 'react-swipeable-views';
import { AppBar, Backdrop, BottomNavigation, BottomNavigationAction, Box, Button, Fade, FormControl, Grid, ImageList, ImageListItem, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, Tab, Tabs, TextField, Typography } from "@material-ui/core"
import { Favorite, KeyboardReturnTwoTone, LocationOn, Restore } from "@material-ui/icons"
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab"
import { Link, useLocation, useParams } from "react-router-dom"
import { EditRecordInterface, NegocioInterface, UserInterface } from "../../interfaces/Interfaces"
import { useContext, useEffect, useState } from "react"
import NegociosServices from "../../services/NegociosServices"
import { GeneralContext } from "../../context/GeneralContext"
import localStorage from '../../libs/localStorage';
import CrudService from "../../services/CrudService"
import { Rutas, departamentos, listamunicipios } from "../../resources/Statics"
import { DropzoneDialog } from "material-ui-dropzone"
import LoadingButton from "../../components/LoadingButton"
import DocumentosService from "../../services/DocumentosService"
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { get } from 'http';
import { Data } from 'victory';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { render } from '@testing-library/react';
import PageLoader from '../../components/PageLoader';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '60vw',
  },
  imageList: {
    width: 'auto',
    height: 600,
  },
  archivos: {
    marginRight: 0,  // Inicialmente oculto
  },
  button: {
    margin: theme.spacing(1),
  },
  inputPadding: {
    paddingTop: '1%'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CantidadPosMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      
      maxlength="19"
    />
  );
}

CantidadPosMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const OrdenesCreacionEdicion = () => {

  const { id } = useParams<EditRecordInterface>();
  const { search } = useLocation(); 

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord
  } = useContext(GeneralContext);

  interface ClienteCreate {
    id: number;
    NegocioId: number;
    direccion: string;
    correo: string;
    telefono: string;
    nit?: string;
    dpi?: string;
  }

  interface LogisticaCreate {
    id: number;
    TipoLogisticaId: number;
    UbicacionId?: number;
    departamento?: string;
    municipio?: string;
    direccion?: string;
    logistica?: string;
    zona?: string;
    nombreContactoPrincipal?: string;
    nombreContactoSecundario?: string;
    contactoPrincipal?: string;
    contactoSecundario?: string;
    numeroGuia?: string;
    notas?: string;
    urlComprobantes?: string[];
  }

  interface Orden {
    id: number,
    cliente: ClienteCreate;
    MetodoPagoId: number;
    UsuarioVendeId: number;
    urlComprobantes?: string[];
    notas: string;
    cantidadPos?: number | null;
    numeroAutorizacion?: string;
    logistica: LogisticaCreate;
    pos: number[];
  }

  interface MetodoPago {
    id: number;
    descripcion: string;
    requeridoRespaldo: boolean;
  }

  interface TipoLogistica {
    id: number;
    descripcion: string;
  }

  interface Ubicacion {
    id: number;
    descripcion: string;
  }

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  
  const [metodosPago, setMetodosPago] = useState<MetodoPago[]>([]);
  const [ubicaciones, setUbicaciones] = useState<Ubicacion[]>([]);
  const [metodoPago, setMetodoPago] = useState<MetodoPago | null>(null);
  const [tiposLogistica, setTiposLogistica] = useState<TipoLogistica[]>([]);
  const [negocios, setNegocios] = useState<NegocioInterface[]>([]);
  const [negocio, setNegocio] = useState<NegocioInterface | null>(null);
  const [logistica, setLogistica] = useState<string>('');
  const [notas, setNotas] = useState<string>('');
  const [estadoOrden, setEstadoOrden] = useState<string>('');
  const [estadoPago, setEstadoPago] = useState<string>('');
  const [usuario, setUsuario] = useState<UserInterface>({
    id: 0,
    nombre: '',
    telefono: '',
    hash: '',
    activada: false,
    eliminado: false,
    documentosSubidos: false,
    documentosAprobados: false,
    forzarCambioPassword: false,
  });
  const [openModalAdjuntos, setOpenModalAdjuntos] = useState(false);
  const [openModalAdjuntosGuia, setOpenModalAdjuntosGuia] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowsPos, setRowsPos] = useState<{id: number}[]>([]);
  const [searchValueDialogPos, setSearchValueDialogPos] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [pagePos, setPagePos] = useState<number>(1);
  const [pageSizePos, setPageSizePos] = useState<number>(5);
  const [totalPagesPos, setTotalPagesPos] = useState<number>(1);
  const [arrayImages, setArrayImages] = useState<any[]>([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [deshabilitarCampos, setDeshabilitarCampos] = useState<boolean>(true);
  const [usuarios, setUsuarios] = useState<{
    id: number;
    nombre: string;
  }[]>();

  const [values, setValues] = useState<Orden>({
    id: id ? parseInt(id) : 0,
    cliente: {
      id: 0,
      NegocioId: 0,
      direccion: '',
      correo: '',
      telefono: '',
      nit: '',
      dpi: ''
    },
    MetodoPagoId: 0,
    UsuarioVendeId: 0,
    urlComprobantes: [],
    notas: '',
    cantidadPos: 0,
    numeroAutorizacion: '',
    logistica: {
      id: 0,
      TipoLogisticaId: 0,
      UbicacionId: 0,
      departamento: '',
      municipio: '',
      direccion: '',
      logistica: '',
      zona: '',
      nombreContactoPrincipal: '',
      nombreContactoSecundario: '',
      contactoPrincipal: '',
      contactoSecundario: '',
      numeroGuia: '',
      notas: '',
      urlComprobantes: []
    },
    pos: []
  });

  useEffect(() => {
    if (id) {
      //setValues({...values, id: parseInt(id)})
      getOrden()
    }
    getPos()
    getNegocios()
    
    getMetodosPago()
    getTipoLogistica()
    getUbicaciones()
    getUsuarios()
  } , [id])

  useEffect(() => {
    if (selectionModel.length > 0) {
      setValues({ ...values, pos: selectionModel });
    }
  }, [selectionModel])

  useEffect(() => {
    if (values.MetodoPagoId) {
      const metodoPago: MetodoPago | undefined = metodosPago.find(metodoPago => metodoPago.id === values.MetodoPagoId);
      setMetodoPago(metodoPago !== undefined ? metodoPago : null);
    }
  } , [values.MetodoPagoId])

  useEffect(() => {
    if (!id && tiposLogistica.length > 0 ) {
      setValues({
        ...values,
        logistica: {
          ...values.logistica,
          TipoLogisticaId: tiposLogistica[0].id,
        },
      })
    }
    if (!id && ubicaciones.length > 0 ) {
      setValues({
        ...values,
        logistica: {
          ...values.logistica,
          UbicacionId: ubicaciones[0].id
        },
      })
    }
    if (!id && usuarios && usuarios?.length > 0) {
      const usuarioSesion = usuarios.find(usuario => usuario.nombre.includes('(YO)'));
      setValues({
        ...values,
        UsuarioVendeId: usuarioSesion ? usuarioSesion?.id : usuarios[0].id
      })
    }
    
  } , [tiposLogistica, ubicaciones, usuarios])

  useEffect(() => {
    if (id && tiposLogistica.length > 0 && values.logistica.TipoLogisticaId) {
      setValue(tiposLogistica.findIndex(tipoLogistica => tipoLogistica.id === values.logistica.TipoLogisticaId))
    }
  } , [values.logistica.TipoLogisticaId, tiposLogistica, id])

  

  


  const getOrden = () => {
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    crudService
      .getOne(parseInt(id))
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setValues({...values, ...result});
          setNegocio(result?.negocio)
          setEstadoOrden(result.estadoOrden)
          setEstadoPago(result.estadoPago)
          // setValue(tiposLogistica.findIndex(tipoLogistica => tipoLogistica.id === result.logistica.TipoLogisticaId))
          // getPos()
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  }

  const getMetodosPago = () => {
    const crudService = CrudService.instance(Rutas.instance.urlOrdenMetodosPago);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setMetodosPago(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getPos = () => {
    setLoading(true);
    const params = `?page=${pagePos}&pageSize=${pageSizePos}&ordenId=${search === '?gestionar' ? 0 : values.id }&gestionar=${search === '?gestionar' ? 1 : 0 }`;
    const crudService = CrudService.instance(Rutas.instance.urlOrdenMpos);
    crudService
      .getAll(params)
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setRowsPos(result.pos);
          setSelectionModel(result.seleccionados)
          
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getTipoLogistica = () => {
    const crudService = CrudService.instance(Rutas.instance.urlOrdenTipoLogistica);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          if (result && result.length > 0) {
            setTiposLogistica(result);
          }
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  useEffect(() => {
    if (estadoOrden === 'Enviado' || estadoOrden === 'Entregado' || search === '?gestionar') {
      setDeshabilitarCampos(true)
    } else {
      setDeshabilitarCampos(false)
    }

  }, [estadoOrden, search])

  const getUbicaciones = () => {
    const crudService = CrudService.instance(`${Rutas.instance.urlUbicacionesInventarioMpos}/me`);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
          // if (!id && result.length > 0) {
          //   setValues({
          //     ...values,
          //     logistica: {
          //       ...values.logistica,
          //       UbicacionId: Number(result[0].id)
          //     }
          //   })
          // }
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getUsuario = async () => {
    const usuario = await localStorage.instance.getUserParseado();
    if (!usuario) {
      handleErrorMessage('No se pudo obtener el usuario');
      handleActiveError(true);
      return;
    }
    setUsuario(usuario)
  }

  const getUsuarios = async () => {
    const usuario = await localStorage.instance.getUserParseado();
    if (usuario)
      setUsuario(usuario)
    const crudService = CrudService.instance(Rutas.instance.urlUsuario+'/ventas');
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          const nuevoResultusuarioSesion = result.map((user: any) => {
            if (usuario && user.id === usuario.id) {
              return {
                id: usuario.id,
                nombre: `(YO) ${user.nombre}`
              }
            }
            return {
              id: user.id,
              nombre: user.nombre
            }
          })
          setUsuarios(nuevoResultusuarioSesion);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  }

  const handleBusinessSelect = (event: React.ChangeEvent<{}>, value: NegocioInterface | null) => {
    setNegocio(value);
    setValues({
      ...values,
      cliente: {
        ...values.cliente,
        direccion: `${value?.municipio || ''} ${value?.departamento || ''} ${value?.numeroCasa || ''}`,
        telefono: value?.telefono || '',
        NegocioId: value?.id || 0
      }
    });
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    getNegocios('?nombre='+searchValue)
    setNegocio(null);
  };

  const getNegocios = (params="") => {
    NegociosServices.instance.get(params).then(response => {
        const { result } = response
        setNegocios(result)
    }).catch(e => {
        handleErrorMessage('message')
        handleActiveError(true)
    })
  }

  const handleDocumentos = (files: any, tipo='comprobantes') => {

    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });
    handleLoading(true);
    setUploading(true);
    DocumentosService.instance
      .uploadFileDocSinId(data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const links: string[] = result.map((file: any) => file.Location);
          if (tipo === 'guia') {
            setValues({
              ...values,
              logistica: {
                ...values.logistica,
                urlComprobantes: [...(values.logistica.urlComprobantes || []), ...links]
              }
            });
          } else {
            setValues({
              ...values,
              urlComprobantes: [...(values.urlComprobantes || []), ...links]
            });
          }
          setOpenModalAdjuntos(false);
          setOpenModalAdjuntosGuia(false);
          handleLoading(false);
          handleSuccessMessage('Comprobante adjuntado correctamente');
          handleActiveSuccess(true);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
          handleLoading(false);
        }
      })
      .catch(e => {
        setOpenModalAdjuntos(false);
        setOpenModalAdjuntosGuia(false);
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(false);
      });
  };

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setValues({
      ...values,
      logistica: {
        ...values.logistica,
        TipoLogisticaId: tiposLogistica[newValue].id
      }
    })
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
    setValues({
      ...values,
      logistica: {
        ...values.logistica,
        TipoLogisticaId: tiposLogistica[index].id
      }
    })
  };

  const handleChanges = (prop: any) => (event: any) => {
    setValues({
      ...values,
      logistica: {
        ...values.logistica,
        [prop]: event.target.value
      }
    });
  };

  const columnsPos = [
  
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 100,
    },
    {
        field: 'lote',
        headerName: 'Lote',
        sortable: false,
        width: 130,
    },
    {
      field: 'numeroDeSerie',
      headerName: 'Número de serie',
      sortable: false,
      width: 300,
    },
    {
      field: 'terminalEpayRetail',
      headerName: 'Terminal',
      sortable: false,
      width: 160,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 150,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row?.estado === 'Activado' ? 
              <span style={{color:'rgb(254, 131, 0)'}}>Activado</span>
            : params.row?.estado}
          </div>
        );
      }
    },
    {
      field: 'ubicacion',
      headerName: 'Ubicación',
      sortable: false,
      width: 350,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row?.ubicacionInventario?.descripcion}
          </div>
        );
      },
    }
  ];

  const handleSelectionModelChange = (selectionModel: any) => {
    setSelectionModel(selectionModel);
  };

  const confirmSave = () => {
    const tipoLogistica = tiposLogistica.find(tipoLogistica => tipoLogistica.id === values.logistica.TipoLogisticaId);
    if (!values.cliente.NegocioId) {
      handleErrorMessage('Verifique que esté seleccionado un negocio');
      handleActiveError(true);
      return;
    }
    if (!values.MetodoPagoId) {
      handleErrorMessage('Verifique que esté seleccionado un método de pago');
      handleActiveError(true);
      return;
    }
    if (!values.logistica.TipoLogisticaId){
      handleErrorMessage('Verifique que esté seleccionado un tipo de logística');
      handleActiveError(true);
      return;
    }
    if ((values?.pos?.length === 0 && tipoLogistica && tipoLogistica?.descripcion !== 'Envío')) {
      handleErrorMessage('Por favor seleccione al menos un POS');
      handleActiveError(true);
      return;
    }

    if (tipoLogistica && tipoLogistica?.descripcion === 'Envío' && Number(values.cantidadPos) <= 0) {
      handleErrorMessage('La cantidad solicitada de POS debe ser al menos 1');
      handleActiveError(true);
      return;
    }

    if (values?.urlComprobantes?.length === 0) {
      handleErrorMessage('Por favor adjunte al menos un comprobante de pago');
      handleActiveError(true);
      return;
    }

    if (!values.UsuarioVendeId) {
      handleErrorMessage('Verifique que esté seleccionado un vendedor.');
      handleActiveError(true);
      return;
    }

    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    crudService
    .create(values)
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        window.location.href = '/admin-home/ordenes'
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  const openFiles = (link: any) => {
    try {
        try {
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          }
        } catch (error) {
          console.log('error', error);
        }
      
    } catch (error) {
      console.log(error);
    }
  };

  const openFile = (item: any) => {
    try {
      window.open(item, '_blank');
    } catch (error) {
      console.log(error);
      alert('No se pudo abrir el archivo');
    }
  };

  const confirmUpdate = () => {
    if (values.pos.length === 0) {
      handleErrorMessage('Por favor debe seleccionar POS');
      handleActiveError(true);
      return;
    }
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    crudService
    .updateOne(values.id, { pos: values.pos })
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        window.location.href = '/admin-home/ordenes'
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  const confirmUpdateAll = () => {
    const tipoLogistica = tiposLogistica.find(tipoLogistica => tipoLogistica.id === values.logistica.TipoLogisticaId);
    if (!values.cliente.NegocioId || !values.logistica.TipoLogisticaId || (values.pos.length === 0 && tipoLogistica && tipoLogistica?.descripcion !== 'Envío')) {
      handleErrorMessage('Por favor verifique que todos los campos obligatorios estén llenos');
      handleActiveError(true);
      return;
    }
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    crudService
    .updateOneComplemento(values.id, values, '/actualiza' )
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        window.location.href = '/admin-home/ordenes'
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  return (
    <Grid container >
      <Grid
        item
        xs={12}
      >
        <Typography variant='h6'>
          <Button
            component={Link}
            to='/admin-home/ordenes'
            color="secondary"
            startIcon={<KeyboardReturnTwoTone />}
          >
              REGRESAR
          </Button>
          
        </Typography>
        <Box
          mt={1}
          mb={2}
          pt={2}
          pl={2}
          pr={2}
          pb={4}
          component={Paper}
        >
          <Grid container style={{padding: '1.5em'}} >
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <Typography variant='h6'>
                <strong>CLIENTE</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '1em'}}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  disabled={deshabilitarCampos}
                  id="autocomplete"
                  options={negocios}
                  getOptionLabel={(business) => business.nombre}
                  value={negocio || null}
                  onChange={handleBusinessSelect}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      label='Negocio'
                      disabled={deshabilitarCampos}
                      onChange={handleSearchChange}
                  />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='Dirección'
                  placeholder='Dirección'
                  disabled={deshabilitarCampos}
                  variant='outlined'
                  name='direccion'
                  value={values.cliente.direccion}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cliente: {
                        ...values.cliente,
                        direccion: e.target.value
                      }
                    })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='NIT'
                  placeholder='Nit'
                  variant='outlined'
                  disabled={deshabilitarCampos}
                  name='nit'
                  value={values?.cliente?.nit}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cliente: {
                        ...values.cliente,
                        nit: e.target.value
                      }
                    })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='DPI'
                  placeholder='Dpi'
                  variant='outlined'
                  name='dpi'
                  value={values?.cliente?.dpi}
                  disabled={deshabilitarCampos}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cliente: {
                        ...values.cliente,
                        dpi: e.target.value
                      }
                    })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='Correo'
                  placeholder='Correo'
                  variant='outlined'
                  name='correo'
                  value={values?.cliente?.correo}
                  disabled={deshabilitarCampos}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cliente: {
                        ...values.cliente,
                        correo: e.target.value
                      }
                    })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='Teléfono'
                  placeholder='Teléfono'
                  variant='outlined'
                  name='telefono'
                  value={values?.cliente?.telefono}
                  disabled={deshabilitarCampos}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cliente: {
                        ...values.cliente,
                        telefono: e.target.value
                      }
                    })
                  }}
                />
              </FormControl>
            </Grid>
            
          </Grid>
        </Box>
        

        <Box
          mt={1}
          mb={2}
          pt={2}
          pl={2}
          pr={2}
          pb={4}
          component={Paper}
        >
          <Grid container style={{padding: '1.5em'}}>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1.5em'}}
            >
              <Typography variant='h6'>
                <strong>LOGÍSTICA</strong>
              </Typography>
            </Grid>
            
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  title={id !== '0' ? 'No se puede modificar el tipo de logística' : ''}  
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  disabled={values.id === 0 ? false : true}
                >
                  {tiposLogistica && tiposLogistica.length > 0 && tiposLogistica.map((tipoLogistica) => (
                    <Tab  disabled={values.id === 0 ? false : true} key={tipoLogistica.id+'tab'} label={tipoLogistica?.descripcion} {...a11yProps(tipoLogistica.id)} />
                  ))}
                </Tabs>
              </AppBar>
              <SwipeableViews
                disabled={values.id === 0 ? false : true}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {tiposLogistica.map((tipoLogistica, index) => (
                  <TabPanel key={tipoLogistica.id+'tabpanel'} value={value} index={index} dir={theme.direction}>
                    {(tipoLogistica?.descripcion === 'Recoger') &&
                      <Grid key={tipoLogistica.id+'tabpanelcontainer'} container >
                        <Grid
                          item
                          xs={12}
                          style={{paddingBottom: '1em'}}
                          key={tipoLogistica.id+'tabpanelitemgrid'}
                        >
                          <FormControl key={tipoLogistica.id+'tabpanelform'} variant="outlined" fullWidth >
                            <InputLabel key={tipoLogistica.id+'tabpanellabel'} id="metodoPago-label">Ubicación del despacho</InputLabel>
                            <Select
                              labelId="metodoPago-label"
                              id="metodopago"
                              fullWidth
                              disabled={deshabilitarCampos}
                              key={'ubicacion-log_'+index}
                              value={values.logistica.UbicacionId}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  logistica: {
                                    ...values.logistica,
                                    UbicacionId: e.target.value as number
                                  }
                                })
                              }}
                              label="Ubicación del despacho"
                            >
                              {ubicaciones.map((ubicacion) => (
                                <MenuItem key={ubicacion.id+'ubi'} value={ubicacion.id}>{ubicacion.descripcion}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    
                    }
                    {tipoLogistica?.descripcion === 'Envío' &&
                    <>
                      <Grid key={tipoLogistica.id+'tabpanelitemgride'} item xs={12} style={{paddingBottom: '1em'}}>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          key={tipoLogistica.id+'tabpanelforme'}
                        >
                          <InputLabel key={tipoLogistica.id+'tabpanellabele'} id='departammento-select-label'>Departamento</InputLabel>
                          <Select
                            key={'departamento-log_'+index}
                            labelId='departammento-select-label'
                            id='dep-negocio-select'
                            label='Departamento'
                            value={values.logistica.departamento}
                            onChange={handleChanges('departamento')}
                            disabled={deshabilitarCampos}
                          >
                            <MenuItem key={tipoLogistica.id+'menuf'} value=''>
                              <em>Seleccione</em>
                            </MenuItem>
                            {negocio &&
                              negocio.tipoNegocio &&
                              negocio.tipoNegocio.pais &&
                              negocio.tipoNegocio.pais.nombre &&
                              departamentos &&
                              departamentos[negocio.tipoNegocio.pais.nombre.toLowerCase()] &&
                              Array.isArray(departamentos[negocio.tipoNegocio.pais.nombre.toLowerCase()])
                              ? departamentos[negocio.tipoNegocio.pais.nombre.toLowerCase()].map((dep: any) => {
                                return (
                                  <MenuItem
                                    key={dep}
                                    value={dep.toLocaleLowerCase()}
                                  >
                                    {dep}
                                  </MenuItem>
                                );
                              })
                              : null}

                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid key={tipoLogistica.id+'tabpanelitemgridmuni'} item xs={12} style={{paddingBottom: '1em'}}>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          key={tipoLogistica.id+'tabpanelformmuni'}
                        >
                          <InputLabel key={tipoLogistica.id+'tabpanelitemgridlabels'} id='municipio-select-label'>Municipio</InputLabel>
                          <Select
                            key={'municipio-log_'+index}
                            labelId='municipio-select-label'
                            id='muni-negocio-select'
                            label='Municipio'
                            value={values.logistica.municipio}
                            onChange={handleChanges('municipio')}
                            disabled={deshabilitarCampos}
                          >
                            <MenuItem key={tipoLogistica.id+'menuw'} value=''>
                              <em>Seleccione</em>
                            </MenuItem>
                            {values &&
                              values.logistica.departamento &&
                              listamunicipios &&
                              listamunicipios[values.logistica.departamento] &&
                              Array.isArray(listamunicipios[values.logistica.departamento])
                              ? listamunicipios[values.logistica.departamento].map((municipio: any) => {
                                return (
                                  <MenuItem
                                    key={municipio}
                                    value={municipio.toLocaleLowerCase()}
                                  >
                                    {municipio}
                                  </MenuItem>
                                );
                              })
                              : null}
                          </Select>
                        </FormControl>
                      </Grid>
                      
                      <Grid
                        item
                        xs={12}
                        key={tipoLogistica.id+'tabpanelitemgriddire'}
                        style={{paddingBottom: '1em'}}
                      >
                        <FormControl key={tipoLogistica.id+'tabpanelitemgridff'} fullWidth variant="outlined">
                          <TextField
                            key={'logistica_'+index}
                            label='Dirección detallada'
                            placeholder='Dirección detallada'
                            variant='outlined'
                            disabled={deshabilitarCampos}
                            value={values.logistica.logistica}
                            onChange={(e) => {
                              setValues({
                                  ...values,
                                  logistica: {
                                      ...values.logistica,
                                      logistica: e.target.value
                                  }
                              });
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{paddingBottom: '1em'}}
                        key={tipoLogistica.id+'tabpanelitemgridnotasz'}
                      >
                        <FormControl key={tipoLogistica.id+'tabpanelitemgridfpotz'} fullWidth variant="outlined">
                          <TextField
                            label='Zona'
                            key={'zona-log_'+index}
                            placeholder='Zona'
                            variant='outlined'
                            name='zonaLogistica'
                            value={values.logistica.zona}
                            onChange={handleChanges('zona')}
                            disabled={deshabilitarCampos}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        
                        key={tipoLogistica.id+'tabpanelitemgridncontactop'}
                      >
                        <Grid container>
                          <Grid item xs={12} md={6} style={{paddingBottom: '1em'}}>
                            <FormControl key={tipoLogistica.id+'tabpanelitemgridncontactop'} fullWidth variant="outlined">
                              <TextField
                                label='Nombre contacto principal'
                                key={'ncontactop-log_'+index}
                                placeholder='Nombre contacto principal'
                                variant='outlined'
                                name='nombreContactoPrincipal'
                                value={values.logistica.nombreContactoPrincipal}
                                onChange={handleChanges('nombreContactoPrincipal')}
                                disabled={deshabilitarCampos}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} style={{paddingBottom: '1em'}}>
                            <FormControl key={tipoLogistica.id+'tabpanelitemgridcontactop'} fullWidth variant="outlined">
                              <TextField
                                label='Número contacto principal'
                                key={'contactop-log_'+index}
                                placeholder='Número contacto principal'
                                variant='outlined'
                                name='contactoPrincipal'
                                value={values.logistica.contactoPrincipal}
                                onChange={handleChanges('contactoPrincipal')}
                                disabled={deshabilitarCampos}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      
                      <Grid
                        item
                        xs={12}
                        
                        key={tipoLogistica.id+'tabpanelitemgridncontactos'}
                      >
                        <Grid container>
                          <Grid item xs={12} md={6} style={{paddingBottom: '1em'}}>
                            <FormControl key={tipoLogistica.id+'tabpanelitemgridncontactos'} fullWidth variant="outlined">
                              <TextField
                                label='Nombre contacto secundario'
                                key={'ncontactop-log_'+index}
                                placeholder='Nombre contacto secundario'
                                variant='outlined'
                                name='nombreContactoSecundario'
                                value={values.logistica.nombreContactoSecundario}
                                onChange={handleChanges('nombreContactoSecundario')}
                                disabled={deshabilitarCampos}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} style={{paddingBottom: '1em'}}>
                            <FormControl key={tipoLogistica.id+'tabpanelitemgridcontactos'} fullWidth variant="outlined">
                              <TextField
                                label='Número contacto secundario'
                                key={'contactos-log_'+index}
                                placeholder='Número contacto secundario'
                                variant='outlined'
                                name='contactoSecundario'
                                value={values.logistica.contactoSecundario}
                                onChange={handleChanges('contactoSecundario')}
                                disabled={deshabilitarCampos}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{paddingBottom: '1em'}}
                        key={tipoLogistica.id+'tabpanelitemgridnguia'}
                      >
                        <FormControl key={tipoLogistica.id+'tabpanelitemgridnguia'} fullWidth variant="outlined">
                          <TextField
                            label='Número de guía'
                            key={'nguia-log_'+index}
                            placeholder='Número de guía'
                            variant='outlined'
                            name='numeroGuia'
                            value={values.logistica.numeroGuia}
                            onChange={handleChanges('numeroGuia')}
                            disabled={deshabilitarCampos}
                          />
                        </FormControl>
                      </Grid>
                      {values.logistica.numeroGuia &&
                        <Grid
                          item
                          xs={12}
                          style={{paddingBottom: '1em'}}
                        >
                          <FormControl fullWidth variant="outlined" style={{height: '100%'}}>
                            {!deshabilitarCampos &&
                              <LoadingButton
                                loading={uploading}
                                color='primary'
                                variant='contained'
                                title='Agregar comprobante de guía'
                                onClick={() => {
                                  setOpenModalAdjuntosGuia(true)
                                }}
                              />
                            }
                            <DropzoneDialog
                              dropzoneText='Arrastra los documentos o presiona aquí'
                              previewText='Vista prévia'
                              dialogTitle={'Elige los archivos'}
                              submitButtonText='Subir archivos'
                              cancelButtonText='Cancelar'
                              open={openModalAdjuntosGuia}
                              onSave={(files: any) => handleDocumentos(files, 'guia')}
                              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                              showPreviews={true}
                              maxFileSize={25000000}
                              filesLimit={10}
                              onClose={() => setOpenModalAdjuntosGuia(false)}
                            />
                            {values && values.logistica.urlComprobantes && values.logistica.urlComprobantes.length > 0 && (
                              <DataGrid
                                rows={values.logistica.urlComprobantes.map((url, index) => ({ id: index, link: url })) }
                                columns={[
                                  { field: 'id', headerName: 'ID', hide: true},
                                  { field: 'link', headerName: 'Comprobantes de guía', width: 300, flex: 1 }
                                ]}
                                onRowClick={(row) => {
                                  openFiles([row.row.link]);
                                }}
                                style={{cursor: 'pointer', minHeight: 150, height: 200}}
                                rowHeight={33}
                                checkboxSelection={false}
                                headerHeight={33}
                                hideFooterRowCount
                                hideFooterSelectedRowCount
                                hideFooter
                              />
                            )}
                          </FormControl>
                        </Grid>
                      }
                      <Grid
                        item
                        xs={12}
                        style={{paddingBottom: '1em'}}
                        key={tipoLogistica.id+'tabpanelitemgridnotas'}
                      >
                        <FormControl key={tipoLogistica.id+'tabpanelitemgridfpot'} fullWidth variant="outlined">
                          <TextField
                            label='Notas'
                            key={'notas-log_'+index}
                            placeholder='Notas'
                            variant='outlined'
                            disabled={deshabilitarCampos}
                            name='notasLogistica'
                            value={values.logistica.notas}
                            onChange={handleChanges('notas')}
                          />
                        </FormControl>
                      </Grid>
                    </>
                    }
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={1}
          mb={2}
          pt={2}
          pl={2}
          pr={2}
          pb={4}
          component={Paper}
        >
          <Grid container style={{padding: '1.5em'}}>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1.5em'}}
            >
              <Typography variant='h6'>
                <strong>ORDEN</strong>
              </Typography>
            </Grid>
            
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl variant="outlined" fullWidth >
                <Autocomplete
                  id="usuariovende"
                  options={usuarios || []}
                  getOptionLabel={(option) => option.nombre}
                  value={usuarios?.find(user => user.id === values.UsuarioVendeId) || null}
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      UsuarioVendeId: newValue ? newValue.id : 0
                    });
                  }}
                  noOptionsText="La búsqueda no coincide con ningún resultado"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuario vende"
                      variant="outlined"
                      disabled={deshabilitarCampos}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl variant="outlined" fullWidth >
                <InputLabel id="demo-simplepago-select-outlined-label">Método de pago</InputLabel>
                <Select
                  labelId="metodoPago-label"
                  id="metodopago"
                  fullWidth
                  disabled={deshabilitarCampos}
                  value={values.MetodoPagoId}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      MetodoPagoId: e.target.value as number
                    })
                    const metodoPago: MetodoPago | undefined = metodosPago.find(metodoPago => metodoPago.id === e.target.value);
                    setMetodoPago(metodoPago !== undefined ? metodoPago : null);
                  }}
                  label="Método de pago"
                >
                  {metodosPago.map((metodoPago) => (
                    <MenuItem key={metodoPago.id} value={metodoPago.id}>{metodoPago?.descripcion}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {metodoPago?.requeridoRespaldo && (
              <Grid
                item
                xs={12}
                style={{paddingBottom: '1em'}}
              >
                <FormControl fullWidth variant="outlined" style={{height: '100%'}}>
                  {!deshabilitarCampos &&
                    <LoadingButton
                      loading={uploading}
                      color='primary'
                      variant='contained'
                      title='Agregar comprobante de pago'
                      onClick={() => {
                        setOpenModalAdjuntos(true)
                      }}
                    />
                  }
                  <DropzoneDialog
                    dropzoneText='Arrastra los documentos o presiona aquí'
                    previewText='Vista prévia'
                    dialogTitle={'Elige los archivos'}
                    submitButtonText='Subir archivos'
                    cancelButtonText='Cancelar'
                    open={openModalAdjuntos}
                    onSave={(files: any)=>handleDocumentos(files)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                    showPreviews={true}
                    maxFileSize={25000000}
                    filesLimit={10}
                    onClose={() => setOpenModalAdjuntos(false)}
                  />
                  {values && values.urlComprobantes && values.urlComprobantes.length > 0 && (
                    <DataGrid
                      rows={values.urlComprobantes.map((url, index) => ({ id: index, link: url })) }
                      columns={[
                        { field: 'id', headerName: 'ID', hide: true},
                        { field: 'link', headerName: 'Comprobantes de pago', width: 300, flex: 1 }
                      ]}
                      onRowClick={(row) => {
                        openFiles([row.row.link]);
                      }}
                      style={{cursor: 'pointer', minHeight: 150, height: 200}}
                      rowHeight={33}
                      checkboxSelection={false}
                      headerHeight={33}
                      hideFooterRowCount
                      hideFooterSelectedRowCount
                      hideFooter
                    />
                  )}
                </FormControl>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='Número de autorización'
                  placeholder='Número de autorización'
                  variant='outlined'
                  name='numeroAutorizacion'
                  disabled={deshabilitarCampos}
                  value={values.numeroAutorizacion}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      numeroAutorizacion: e.target.value
                    })
                  }}
                />
              </FormControl>
            </Grid>
            { tiposLogistica && tiposLogistica?.length > 0 && tiposLogistica[value]?.descripcion === 'Envío' &&
              <Grid
                item
                xs={12}
                style={{paddingBottom: '1em'}}
              >
                <FormControl fullWidth variant="outlined">
                  
                <TextField
                  type='number'
                  label='Cantidad POS'
                  placeholder='Cantidad POS'
                  variant='outlined'
                  name='cantidadPos'
                  disabled={deshabilitarCampos}
                  value={values.cantidadPos}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cantidadPos: Number(e.target.value)
                    })
                  }}
                />
                </FormControl>
              </Grid>
            }
            <Grid
              item
              xs={12}
              style={{paddingBottom: '1em'}}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  label='Notas'
                  placeholder='Notas'
                  variant='outlined'
                  name='notas'
                  value={values.notas}
                  disabled={deshabilitarCampos}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      notas: e.target.value
                    })
                  }}
                />
              </FormControl>
            </Grid>
            
            
          </Grid>
        </Box>

        
          {(tiposLogistica && tiposLogistica.length > 0 && tiposLogistica[value]?.descripcion !== 'Envío') || (search === '?gestionar') || (estadoOrden === 'Enviado' || estadoOrden === 'Entregado')  ? (
            <Box
              mt={1}
              mb={2}
              pt={2}
              pl={2}
              pr={2}
              pb={10}
              component={Paper}
            >
              <Grid container style={{padding: '1.5em'}}>
                <Grid
                  item
                  xs={12}
                  style={{paddingBottom: '1.5em'}}
                >
                  <Typography variant='h6'>
                    Productos
                  </Typography>
                </Grid>
                
                <Grid
                  item
                  xs={12}
                  style={{paddingBottom: '1em', height: 350, width: 'auto'}}
                >
                  <Grid container>
                    <Grid item xs={12} md={6} style={{paddingTop: '1%'}}>
                    {values.id === 0 ? 'Seleccione los POS a vender' : 'POS para la venta' } 
                    </Grid>
                    <Grid item xs={12} md={6} style={{paddingTop: '1%', textAlign:'right'}}>
                      
                        <TextField
                          label='Buscar'
                          placeholder='Número de serie'
                          variant='outlined'
                          size='small'
                          fullWidth
                          name='search'
                          value={searchValueDialogPos}
                          onChange={(event) => setSearchValueDialogPos(event.target.value)}
                        />
                      
                    </Grid>
                  </Grid>
                  <DataGrid
                    filterModel={{
                      items: [{ columnField: 'numeroDeSerie', operatorValue: 'contains', value: searchValueDialogPos }],
                    }}
                    loading={loading}
                    rows={rowsPos}
                    columns={columnsPos}
                    rowHeight={33}
                    rowsPerPageOptions={[5, 10, 15, 100]}
                    checkboxSelection={(!deshabilitarCampos || search === '?gestionar') ? true : false}
                    onSelectionModelChange={(!deshabilitarCampos || search === '?gestionar') ? handleSelectionModelChange : () => {} }
                    headerHeight={33}
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                    selectionModel={selectionModel}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              mt={1}
              mb={3}
              pt={2}
              pl={2}
              pr={2}
              pb={2}
              component={Paper}
            >
              <Grid container style={{padding: '1.5em'}}>
                <Grid
                  item
                  xs={12}
                >
                  <Alert severity="info">
                    <AlertTitle><strong>IMPORTANTE</strong></AlertTitle>
                    Has seleccionado la logística de: <strong>{tiposLogistica.find(tipoLogistica => tipoLogistica.id === values.logistica.TipoLogisticaId)?.descripcion}</strong>, por lo que no se requiere seleccionar los POS. <br/>
                    La asignación de los POS para esta orden será gestionado por el <strong>Supervisor de Bodega y Logística</strong>.
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          ) 
         }
        <Grid container >
          <Grid
            item
            xs={12}
            style={{paddingBottom: '1.5em'}}
          >
            <Grid container >
              <Grid
                item
                xs={12}
                md={6}
              ></Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{paddingBottom: '1.5em'}}
              >
                {(values.id === 0 || search === '?gestionar') &&
                  <Button onClick={ values.id === 0 ? confirmSave : confirmUpdate } variant='contained' color='primary' fullWidth >
                    {values.id === 0 ? 'Crear' : 'Procesar gestión de envío'} 
                  </Button>
                }
                {(!deshabilitarCampos && !search && id) &&
                  <Button
                    onClick={confirmUpdateAll}
                    variant='contained'
                    fullWidth
                    color='primary'
                  >
                    Modificar
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          className={classes.modal}
          open={openImagesModal}
          onClose={() => {
            setOpenImagesModal(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}

        >
          <Fade in={openImagesModal}>
            <div className={classes.paper}>
              <h2 id='transition-modal-title'>Documentos</h2>
              <p id='transition-modal-description'></p>
              <ImageList
                rowHeight={160}
                className={classes.imageList}
                cols={1}
              >
                {arrayImages.map((item: any) => (
                  <ImageListItem
                    key={item}
                    cols={1}
                    rows={4}
                  >
                    {item.includes('pdf') ? (
                      <iframe
                        src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                        style={{ width: '100%', height: '600px' }}
                        key={item}
                        title="PDF Viewer"

                      />
                    ) : (
                      <img
                        style={{ width: '600px' }}
                        src={item}
                        onClick={() => openFile(item)}
                      />
                    )}

                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </Fade>
        </Modal>
      </Grid>
      <PageLoader loading={loading} />
    </Grid>
  )
}

export default OrdenesCreacionEdicion