/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as momentZone from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { FindInPageTwoTone, List, RemoveRedEye } from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { Edit, Delete, History, Add } from '@material-ui/icons';
import ModalHistorico from '../../components/ModalHistorico';
import localStorage from '../../libs/localStorage';

import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import {
  Backdrop,
  Chip,
  createStyles,
  Fade,
  FormControl,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
  Typography,
  FormHelperText,
  Button

} from '@material-ui/core';
/**
 * Internal Libraries
 */
import { Rutas, settlementStatus } from '../../resources/Statics';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
import LoadingButton from '../../components/LoadingButton';
/**
 * Interfaces
 */
import { ContactsInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
import RazonesService from '../../services/RazonesService';
import ModalRespaldoEstadoLiquidacion from '../../components/ModalRespaldoEstadoLiquidacion';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '40vw',
    },
  }),
);

export default function Liquidaciones() {
    useContext(GeneralContext);
  const classes = useStyles();
  const history = useHistory();

  const [settlements, setSettlements] = useState<ContactsInterface[]>([]);
  const [endDate, setEndDate] = useState(momentZone.tz('America/Guatemala').endOf('week').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(momentZone.tz('America/Guatemala').startOf('week').format('YYYY-MM-DD'));
  const [openModalTransactions, setOpenModalTransactions] = useState(false);
  const [files, setFiles] = useState<[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenModalRespaldo, setIsOpenModalRespaldo] = useState(false)
  const [comentarios, setComentarios] = useState('')

  const [userId, setUserId] = useState<number>()
  const [estado, setEstado] = useState('')
  const [estadoAntiguo, setEstadoAntiguo] = useState('')

  const [id, setId] =useState<number>()
  const [openImagesModal, setOpenImagesModal] = React.useState(false);

  const [arrayImages, setArrayImages] = React.useState<any[]>([]);

  const openFiles = (row: any) => {
    try {
      console.log(row[0]?.documentos)

       if (row[0].documentos) {
        try {
          const link = JSON.parse(row[0]?.documentos);
          if (Array.isArray(link)) {
            console.log(link)
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([row[0]?.documentos[0]]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row[0]?.documentos[0]]);
          setOpenImagesModal(true);
        }
      } else {
        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [rowsHistorialEstados, setRowsHistorialEstados] = useState<any>([]);
  const columnsHistorialEstados = [
    {
      field: 'documento',
      headerName: 'Documento',
      width: 160,
      title: 'Documento',
      renderCell: (params: any) => {
        return (
          <>
            { params.row.documentos.length > 0 && (
                  <div
                  className='d-flex justify-content-between align-items-center'
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton
                    size='small'
                     onClick={() => openFiles([params.row])}
                  >
                    <FindInPageTwoTone />
                  </IconButton>
                </div>
              
            )}
          </>
        )
      }
    },
    {
      field: 'descripcion',
      headerName: 'Cambio',
      width: 520,
      title: 'Cambios',
      renderCell: (params: any) => {
        return <span title={params.row.descripcion}>{params.row.descripcion}</span>;
      }
    },
    {
      field: 'valor',
      headerName: 'Estado actual',
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'comentarios',
      headerName: 'Comentarios',
      sortable: false,
      width: 520,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        console.log(params.row)
        return <span>{params.row.comentarios}</span>;
      },
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    
  ];

  const {
    loading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    selectedRecord,
  } = useContext(GeneralContext);

  useEffect(() => {
    getSettlements();
    const getUser = async () => {
      let user = await localStorage.instance.getUserParseado();
      console.log(user)
      setUserId(user?.id)
      console.log(selectedRecord)
    }
    getUser()
  }, []);

  const getSettlements = () => {
    handleLoading(true);
    ReportesServices.instance
      .getSettlements(startDate, endDate)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setSettlements(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const selectRecord = (record: any) => {
    console.log(record)
    const { id } = record;
    handleSelectRecord(record);
    history.push(`/admin-home/liquidaciones/transacciones/${id}`);
  };

  const getSettlementFiles = (selectedSettlement: string | number) => {
    setOpenModalTransactions(true);
    handleLoading(true);
    ReportesServices.instance
      .getSettlementFiles(selectedSettlement)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setFiles(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const columns: GridColumns = [
    {
      field: 'Acciones',
      headerName: 'Acciones',
      width: 125,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', lineHeight: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <RemoveRedEye
              titleAccess='Editar'
              color='primary'
              style={{ lineHeight: 0 }}
              onClick={() => selectRecord(params.row)}
            ></RemoveRedEye>
          </div>
        );
      },
    },
    { field: 'id', headerName: 'ID', minWidth: 100 },
    { field: 'nombre', headerName: 'Código', flex: 1, minWidth: 150 },
    {
      field: 'correlativo',
      headerName: 'Correlativo',
      minWidth: 150,
    },
    {
      field: 'Reportes',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      align: 'center',
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => getSettlementFiles(params.row.id)}
            >
              <List />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.estado}
                onChange={async (e: any) => {
                  setIsOpenModalRespaldo(true)
                  setEstadoAntiguo(params.row.estado)
                  setEstado(e.target.value)
                  setId(params.row.id)
                }}
                style={{ minWidth: 140, lineHeight: 'unset' }}
              >
                {settlementStatus.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                    >
                      <Chip
                        label={status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'historial', headerName: 'Historico Estados', flex: 2, minWidth: 150, 
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button  color='inherit' onClick={(event) => verHistorial(params?.row?.id)} >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params?.row?.tipo ? 'INTERNO' : 'EXTERNO'}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Exportación',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaInicial',
      headerName: 'Fecha Inicio',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaInicial'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaFinal',
      headerName: 'Fecha Final',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaFinal'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
  ];

  const columnsFiles: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'nombre',
      headerName: 'Archivo',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            href={params.row.link}
            target={'_blank'}
          >
            {params.row.nombre}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
  ];

  
  const verHistorial = async (id: any, origenLike='liquidacion_transaccion_estados') => {
    const params = '?origenLike='+origenLike;
    await RazonesService.instance
      .getByLiquidacion(id,params)
      .then((response: any )=> {
        const { error, message, result } = response;
        console.log(result)
        if (!error) {
          setRowsHistorialEstados(result);
          handleLoading(false);
          setIsOpenModal(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  }

  return (
    
    <>
      <Box
        mt={1}
        p={2}
        component={Paper}
        style={{ maxHeight: '100vh', overflow: 'auto' }}
      >
        <Grid
          container
          direction='column'
          spacing={2}
        >
          <Grid
            item
            container
            style={{ padding: 10 }}
            alignItems='center'
          >
            <Grid
              item
              sm={6}
              style={{ padding: 10 }}
            >
              <Typography variant='h6'>Transacciones por Liquidación</Typography>
            </Grid>

            <Grid
              item
              sm={2}
            >
              <TextField
                type={'date'}
                label='Fecha inicio'
                variant='outlined'
                value={startDate}
                onChange={field => setStartDate(field.target.value)}
              />
            </Grid>

            <Grid
              item
              sm={2}
            >
              <TextField
                type={'date'}
                label='Fecha fin'
                variant='outlined'
                value={endDate}
                onChange={field => setEndDate(field.target.value)}
              />
            </Grid>

            <Grid
              item
              sm={2}
            >
              <LoadingButton
                loading={loading}
                color='primary'
                variant='contained'
                title='Filtrar'
                onClick={getSettlements}
              />
            </Grid>
          </Grid>

          <Grid
            item
            className='HideScrollbars'
            style={{ height: 'calc(100vh - 225px)', width: 'auto' }}
          >
            <DataGrid
              rowHeight={50}
              rows={settlements}
              columns={columns}
              rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            />
          </Grid>
        </Grid>

        <Modal
          className={classes.modal}
          open={openModalTransactions}
          onClose={() => setOpenModalTransactions(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalTransactions}>
            <div className={classes.paper}>
              <Box style={{ paddingTop: '1rem', marginTop: '1rem' }}>
                <Grid
                  container
                  direction='column'
                  spacing={3}
                >
                  <Grid
                    item
                    style={{ height: 400, width: 'auto' }}
                  >
                    <DataGrid
                      rows={files}
                      rowHeight={50}
                      loading={loading}
                      columns={columnsFiles}
                      rowsPerPageOptions={[25, 50, 100, 500, 1000]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Fade>
        </Modal>

        <PageLoader loading={loading} />
      </Box>

      <Modal
        className={classes.modal}
        open={openImagesModal}
        onClose={() => {
          setOpenImagesModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={openImagesModal}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Documento</h2>
            <p id='transition-modal-description'></p>
             { arrayImages.map((item: any) => {
                if (typeof item.link === 'string') {
                  if (item.link.includes('pdf') ) {
                    return (
                      <iframe
                      src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item.link}`}
                      style={{ width: '100%', height: '600px' }}
                      key={item}
                      title="PDF Viewer"
    
                    />
                    )
                  } else {
                    return (
                      <img
                        src={item.link}
                      />
                    )
                  }
                }
             })}

          </div>
        </Fade>
      </Modal>
      { isOpenModal &&         
        <ModalHistorico isOpen={isOpenModal} setIsOpen={setIsOpenModal} historialRows={rowsHistorialEstados} columnsHistorialEstados={columnsHistorialEstados}  />
      }
      { isOpenModalRespaldo && 
        <ModalRespaldoEstadoLiquidacion getSettlements={getSettlements} estadoAntiguo={estadoAntiguo} isOpen={isOpenModalRespaldo} UsuarioId={userId}  setIsOpen={setIsOpenModalRespaldo} setComentarios={setComentarios} comentarios={comentarios} id={id} status={estado} setLinksDocumentosRazones={setArrayImages} linksDocumentosRazones={arrayImages}/>
      }
     
    </>
    
  );
}
