/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import * as momentZone from 'moment-timezone';
import { NavigateNext } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { useContext, useEffect, useState } from 'react';
import { Breadcrumbs, Chip, FormControl, Link, MenuItem, Select, Typography } from '@material-ui/core';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Interfaces
 */
import { ContactsInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
/**
 * Constants
 */
import { transactionSettlementState } from '../../resources/Statics';
/**
 * Component
 */
export default function DetalleLiquidaciones() {
  const history = useHistory();

  const {
    handleErrorMessage,
    handleActiveError,
    handleLoading,
    loading,
    selectedRecord,
    handleActiveSuccess,
    handleSuccessMessage,
  } = useContext(GeneralContext);

  const [transactions, setTransactions] = useState<ContactsInterface[]>([]);

  useEffect(() => {
    getSettlements();
  }, []);

  const getSettlements = () => {
    handleLoading(true);
    ReportesServices.instance
      .getTransactionsSettlement(selectedRecord.id)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setTransactions(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = async (e: any, params: any) => {
    handleLoading(true);
    ReportesServices.instance
      .updateTransactionSettlementState({ status: e.target.value, type: params.row.status }, params.row.id)
      .then(response => {
        const { error, message } = response;
        if (!error) {
          getSettlements();
          handleActiveSuccess(true);
          handleSuccessMessage(message);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleActiveError(true);
        handleErrorMessage(e.message);
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 150 },
    {
      field: 'negocio',
      headerName: 'Negocio',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params.row.negocio.nombre}</span>;
      },
    },
    { field: 'monto', headerName: 'Monto', flex: 1, minWidth: 150 },
    { field: 'montoNeto', headerName: 'Monto Neto', flex: 1, minWidth: 150 },
    {
      field: 'liquidacionEstado',
      headerName: 'Estado liquidación',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        console.log(params);
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.liquidacionEstado === null ? 'Pendiente' : params.row.liquidacionEstado}
                onChange={e => handleChange(e, params)}
                style={{ minWidth: 140, lineHeight: 'unset' }}
                disabled={params.row.liquidacionEstado === 'Retenida' ? true : false}
              >
                {transactionSettlementState.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                      disabled={status.disabled}
                    >
                      <Chip
                        label={status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'fechaRealizoPago',
      headerName: 'Fecha Pago',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaRealizoPago'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
  ];

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ maxHeight: '100vh', overflow: 'auto' }}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid
          container
          style={{ padding: 10 }}
        >
          <Grid
            item
            xs={10}
          >
            <Breadcrumbs
              separator={<NavigateNext fontSize='medium' />}
              aria-label='breadcrumb'
            >
              <Link
                href='#'
                underline='hover'
                variant='h6'
                onClick={() => history.goBack()}
              >
                {selectedRecord.nombre}
              </Link>
              <Typography
                variant='h6'
                color='textPrimary'
              >
                Transacciones en Liquidación
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid
          item
          style={{ height: 'calc(100vh - 200px)', width: 'auto' }}
        >
          <DataGrid
            rowHeight={50}
            loading={loading}
            columns={columns}
            rows={transactions}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Grid>
      </Grid>

      <PageLoader loading={loading} />
    </Box>
  );
}
