import React from 'react';
import * as momentZone from 'moment-timezone';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ViewListIcon from '@material-ui/icons/ViewList';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CrudDataGrid from '../../Crud/CrudDataGrid';
import { Rutas } from '../../../resources/Statics';
import Rols from './Rols';
import Accesos from './AccesosAvanzados';
import UsuariosPanel from './UsuariosPanel';
import { FormField } from '../../../interfaces/Interfaces';
import AccesosVisuales from './AccesosVisuales';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const columnsPermisos = [
  {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 70,
      disableClickEventBubbling: true,
  },
  {
      field: 'descripcion',
      headerName: 'Descripcion',
      sortable: false,
      width: 400,
      disableClickEventBubbling: true,
      editable: true
  },
  {
    field: 'createdAt',
    headerName: 'Fecha creacion',
    width: 190,
    renderCell: (params: any) => {
      const fechaTZ6 = momentZone
        .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
        .locale('es')
        .format('YYYY-MM-DD HH:mm A');
      return <span>{fechaTZ6}</span>;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Fecha actualizacion',
    width: 220,
    renderCell: (params: any) => {
      const fechaTZ6 = momentZone
        .tz(params.getValue(params.id, 'updatedAt'), 'America/Guatemala')
        .locale('es')
        .format('YYYY-MM-DD HH:mm A');
      return <span>{fechaTZ6}</span>;
    },
  },
];

const formConfig: FormField[] = [
  {
    type: 'text',
    label: 'Descripcion',
    name: 'descripcion',
    required: true,
    error: 'Debe ingresar una descripcion',
    value: '',
  }
]

export default function PermisosContenedor() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
      style={{ maxHeight: '240vh', height: '90vh' }}
    >
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label="Usuarios" icon={<PersonPinIcon />} {...a11yProps(0)} />
            <Tab label="Roles" icon={<GroupAddIcon />} {...a11yProps(1)} />
            <Tab label="Permisos" icon={<ViewListIcon />} {...a11yProps(2)} />
            <Tab label="Accesos visuales" icon={<LockOpenIcon />} {...a11yProps(3)} />
            <Tab label="Accesos avanzados" icon={<LockOpenIcon />} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <UsuariosPanel />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Rols />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CrudDataGrid  formConfig={formConfig} apiEndpoint={Rutas.instance.urlPermisos} columns={columnsPermisos} placeholder='descripcion' />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AccesosVisuales />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Accesos />
        </TabPanel>
        
        
        
        
      </div>
    </Box>
  );
}
