import momentZone from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  IconButton,
  ListSubheader,
  TextField,
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import { EditTwoTone as Edit } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/CheckTwoTone';
import ClearIcon from '@material-ui/icons/ClearTwoTone';
import { Pagination } from '@material-ui/lab';
import { Text, Text as TextAux } from '@react-pdf/renderer';
import { GeneralContext } from '../../context/GeneralContext';
import { DealersInterface, ListadoMotivos, NegocioInterface, UserInterface } from '../../interfaces/Interfaces';
import ValidationFields from '../../libs/validationFields';
import DealersService from '../../services/DealersService';
import NegociosServices from '../../services/NegociosServices';
import PaisesServices from '../../services/PaisesService';
import VerificationService from '../../services/VerificationService';
import localStorage from '../../libs/localStorage';
import { temporalNegocioLimites } from '../../resources/Statics';
import PageLoader from '../../components/PageLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function Negocios() {
  const {
    handleErrorMessage,
    handleActiveError,
    handleSelectRecord,
    handleSuccessMessage,
    handleActiveSuccess,
  } = useContext(GeneralContext);
  let history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [usuariosAfiliadores, setUsuariosAfiliadores] = React.useState<UserInterface[]>([]);
  const [dealers, setDealers] = useState<DealersInterface[]>([]);

  const [listadoMotivos, setListadoMotivos] = useState<ListadoMotivos[]>([]);
  const [listadoBusqueda, setListadoBusqueda] = useState<any[]>([]);
  const [motivoTemp, setMotivoTemp] = useState<Record<string, number>>({});

  const [tipoNegocioTemp, setTipoNegocioTemp] = useState<Record<string, string>>({});
  const [verifications, setVerifications] = useState([]);
  const [paises, setPaises] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [countryFilter, setCountryFilter] = useState('all');
  const controllerAllVerifications = new AbortController();
  const signalAllVerifications = controllerAllVerifications.signal;

  const getListadoMotivos = async () => {
    try {
      //setLoading(true);
      let { result } = await NegociosServices.instance.getListReasons();
      setListadoMotivos([{ process: '', title: '' }, ...result]);
      //setLoading(false);
    } catch (error: any) {
      //setLoading(false);
    }
  };

  const getListadoBusqueda = async () => {
    try {
      let user = await localStorage.instance.getUserParseado() ;
      const userId = user?.id?.toString() || ''
      let { result } = await NegociosServices.instance.getHistorialBusqueda(userId);
      setListadoBusqueda(result)
    } catch (error) {
      console.log(error)
    }
  }

  const editNegocio = (values: any) => {
    let validation = ValidationFields.instance.newNegocio(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        setLoading(true);
        NegociosServices.instance
          .update(values.id, values)
          .then(response => {
            setLoading(false);
            const { error, message } = response;
            if (!error) {
              handleSuccessMessage(message);
              handleActiveSuccess(true);
            } else {
              handleErrorMessage(message);
              handleActiveError(true);
            }
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage(e.message);
            handleActiveError(true);
          });
      }
    }
  };

  const updateTipoNegocio = (id: string, tipoNegocio: string) => {
    if (!loading) {
      setLoading(true);
      NegociosServices.instance
        .updateTipoNegocio(+id, { tipoNegocio })
        .then(response => {
          setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };



  useEffect(() => {
    //setLoading(true);
    if (page > 0) getNegocios();
    //getNegocios();
    //getAllVerifications();
    //getUsuariosAdministradoresAfiliadores();
    //getListadoMotivos();

    return () => {
      controllerAllVerifications.abort();
    };
  }, [page, pageSize]);

  useEffect(() => {
    getPaises();
    getUsuariosAdministradoresAfiliadores();
    getListadoMotivos();
    setPage(1)
    getListadoBusqueda()
  }, []);

  const validNegociosColumns = (negocios: NegocioInterface[]) => {
    return new Promise<NegocioInterface[]>(resolve => {
      const negociosList: NegocioInterface[] = [];
      for (let i = 0; i < negocios.length; i++) {
        negociosList[i] = { ...negocios[i] };
        negociosList[i].pais = '';

        //Validacion para pais
        if (negocios[i].tipoNegocio && negocios[i].tipoNegocio.pais && negocios[i].tipoNegocio.pais.nombre) {
          negociosList[i].pais = negocios[i].tipoNegocio.pais.nombre;
        }

        //validacion para afiliacion

        negociosList[i].afiliacion = '';
        let afiliacion = '';
        if (
          negocios[i] &&
          negocios[i].rolUsuario &&
          negocios[i].rolUsuario[0] &&
          negocios[i].rolUsuario[0].rol &&
          negocios[i].rolUsuario[0].rol.tipo
        ) {
          afiliacion = negocios[i].rolUsuario[0].rol.tipo;
          afiliacion = afiliacion.replace('administrador', '');
          afiliacion = afiliacion.replace('_', '');
          afiliacion = afiliacion.replace('_', ' ');
          negociosList[i].afiliacion = afiliacion;
        }

        negociosList[i].limite = '';
        if (negocios[i] && negocios[i] && negocios[i].tipoNegocio && negocios[i].tipoNegocio?.limitePOS) {
          negociosList[i].limite = negocios[i].tipoNegocio?.limitePOS;
        }

        negociosList[i].activa = false;
        if (negociosList[i].rolUsuario && negociosList[i].rolUsuario[0] && negociosList[i].rolUsuario[0].usuario) {
          negociosList[i].activa = negociosList[i].rolUsuario[0].usuario.contratoFirmado;
        }
        let verificacionEstado = 'No iniciado';
        if (!negociosList[i].verificacionMetamap) {
          verificacionEstado = 'No iniciado';
        }
        if (negociosList[i].verificacionMetamap && !negociosList[i].verificacionMetamap.verificado) {
          verificacionEstado = 'Pendiente';
        }
        if (negociosList[i].verificacionMetamap && negociosList[i].verificacionMetamap.verificado) {
          verificacionEstado = 'Verificado';
        }
        negociosList[i].verificacionEstado = verificacionEstado;
      }

      resolve(negociosList);
    });
  };

  const columns = [
    {
      field: '',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={async () => {
                const userRol = await localStorage.instance.getUserRol();
                editRecord(params.row);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'veri',
      headerName: 'Verificación',
      sortable: false,
      minWidth: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        
        // if (verifications){
        //   const negocioVerifications = verifications.filter(
        //     (verification: any) => verification.NegocioId == params.row.id,
        //   );
        //   const negocioVerificationsVerificated = verifications.filter(
        //     (verification: any) => verification.NegocioId == params.row.id && verification.verificado == true,
        //   );

        //   const nVL = negocioVerifications.length;
        //   const nVVL = negocioVerificationsVerificated.length;

        //   return (
        //     <div
        //       className='d-flex justify-content-between align-items-center'
        //       style={{ cursor: 'pointer' }}
        //     >
        //       <Chip
        //         onClick={() =>
        //           openVerificationDetail(
        //             nVVL > 0 ? negocioVerificationsVerificated[0] : nVL > 0 ? negocioVerifications[0] : null,
        //           )
        //         }
        //         label={nVVL > 0 ? 'Verificado' : nVL > 0 ? 'Pendiente' : 'Sin verificar'}
        //         color={nVVL > 0 ? 'primary' : 'default'}
        //       />
        //     </div>
        //   );
        // } else {
        //   return (
        //     <div
        //       className='d-flex justify-content-between align-items-center'
        //       style={{ cursor: 'pointer' }}
        //     >
        //       <Chip
        //         label={'Sin verificar'}
        //         color={'default'}
        //       />
        //     </div>
        //   );
        // }

        const verificaciones = params.row?.verificaciones;
        const verificado = verificaciones.filter(
          (verification: any) => verification.verificado == true,
        );
        const noVerificado = verificaciones.filter(
          (verification: any) => verification.verificado == false,
        );

        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <Chip
              onClick={() =>
                openVerificationDetail(
                  verificado.length > 0 ? verificado[0] : noVerificado.length > 0 ? noVerificado[0] : null,
                )
              }
              label={verificado.length > 0 ? 'Verificado' : noVerificado.length > 0 ? 'Pendiente' : 'Sin verificar'}
              color={verificado.length > 0 ? 'primary' : 'default'}
            />
          </div>
        );

      },
    },
    { field: 'id', headerName: 'Id', flex: 1, minWidth: 90 },

    {
      field: 'pais',
      headerName: 'País',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params.row.pais}</span>;
      },
    },

    { field: 'nombre', headerName: 'Nombre', flex: 1, minWidth: 150 },
    { field: 'telefono', headerName: 'Teléfono', flex: 1, minWidth: 140 },
    { field: 'nit', headerName: 'NIT', flex: 1, minWidth: 150 },
    {
      field: 'afiliacion',
      headerName: 'Afiliación',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params.row.afiliacion}</span>;
      },
    },
    {
      field: 'afiliacion',
      headerName: 'Tipo de negocio',
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => {
        let afiliacion = '';
        if (
          params &&
          params.row &&
          params.row.rolUsuario &&
          params.row.rolUsuario[0] &&
          params.row.rolUsuario[0].rol &&
          params.row.rolUsuario[0].rol.tipo
        ) {
          afiliacion = params.row.rolUsuario.find((rol: any) => rol.rol.nombre === 'administrador')?.rol?.tipo ?? '';
        }

        afiliacion = afiliacion.replace('administrador', '');
        if (afiliacion.startsWith('_')) afiliacion = afiliacion.substring(1);
        let tipos = [];
        const tiposPayfac = [
          { name: 'No formalizado', value: 'no_formalizado' },
          { name: 'No formalizado Medio', value: 'no_formalizado_medio' },
          { name: 'No formalizado Plus', value: 'no_formalizado_plus' },

          { name: 'title', value: 'Emprendedor / Comerciante' },
          { name: 'Pequeño Contribuyente', value: 'emprendedor' },
          { name: 'Pequeño Contribuyente Plus', value: 'emprendedor_plus' },

          { name: 'title', value: 'Persona individual' },
          { name: 'Persona individual', value: 'persona_individual_payfac' },
          { name: 'Persona individual Plus', value: 'persona_individual_plus' },

          { name: 'title', value: 'Sociedad anónima' },
          { name: 'Sociedad anónima', value: 'sociedad_anonima_payfac' },
          { name: 'Sociedad anónima Plus', value: 'sociedad_anonima_plus' },

          { name: 'title', value: 'Pequeño contribuyente' },
          { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'pequeno_contribuyente_payfac' },
          { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'pequeno_contribuyente_plus' },
        ];
        const tiposPayfacHonduras = [
          { name: 'No formalizado', value: 'h_no_formalizado' },
          { name: 'No formalizado Plus', value: 'h_no_formalizado_plus' },

          { name: 'title', value: 'Emprendedor / Comerciante' },
          { name: 'Pequeño Contribuyente', value: 'h_emprendedor' },
          { name: 'Pequeño Contribuyente Plus', value: 'h_emprendedor_plus' },

          { name: 'title', value: 'Sociedad anónima' },
          { name: 'Sociedad anónima', value: 'h_sociedad_anonima_payfac' },
          { name: 'Sociedad anónima Plus', value: 'h_sociedad_anonima_plus' },

          { name: 'title', value: 'Pequeño contribuyente' },
          { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'h_pequeno_contribuyente_payfac' },
          { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'h_pequeno_contribuyente_plus' },
        ];

        const tiposAgregador = [
          { name: 'Persona individual', value: 'persona_individual' },
          { name: 'Pequeño contribuyente', value: 'pequeno_contribuyente' },
          { name: 'Sociedad anónima', value: 'sociedad_anonima' },
          { name: 'Servicios Profesionales', value: 'servicios_profesionales' },
          { name: 'Fundación', value: 'fundacion' },
          { name: 'Institución Educativa', value: 'institucion_educativa' },
        ];

        if (params.row?.tipoNegocio?.categoriaVisa === 'payfac') {
          afiliacion = params.row?.tipoNegocio?.nombre;
          tipos.push({
            name: params.row?.tipoNegocio?.nombre,
            value: params.row?.tipoNegocio?.nombre,
          });
          tipos = params.row.tipoNegocio.pais.nombre === 'Honduras' ? tiposPayfacHonduras : tiposPayfac;
        } else {
          tipos = tiposAgregador;
        }

        if (!tipos.map(tipo => tipo.value).includes(afiliacion)) {
          tipos.push({
            name: afiliacion,
            value: afiliacion,
          });
        }

        const stateValue = tipoNegocioTemp[params.row.id];

        let negocioId = '';
        if (params && params.row && params.row.id) negocioId = params.row.id;

        const businessType: string = stateValue || afiliacion;
        let plusTypeIndex = tipos.findIndex(
          tipo =>
            tipo.value === `${businessType}_plus` ||
            tipo.value === `h_${businessType}_plus` ||
            tipo.value === `${businessType?.replace('_medio', '')}_plus`,
        );

        return (
          <FormControl fullWidth>
            <Select
              fullWidth
              value={businessType}
              name='tiponegocio'
              
              inputProps={{ 'aria-label': 'tipo_negocio' }}
              onChange={e => {
                updateTipoNegocio(negocioId, e.target.value as string);
                setTipoNegocioTemp(prevTipos => ({ ...prevTipos, [params.row.id]: e.target.value }));
              }}
            >
              {tipos.map((tipo, index) =>
                tipo.name === 'title' ? (
                  <ListSubheader key={`subheader_${tipo.value}`}>
                    <hr />
                  </ListSubheader>
                ) : (
                  <MenuItem
                    value={tipo.value}
                    key={`tipo_negocio_${tipo.value}`}
                    disabled={tipo.value === afiliacion}
                  >
                    <Text style={{ fontWeight: plusTypeIndex === index ? 'bold' : 'normal' }}>{tipo.name}</Text>
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'limite',
      headerName: 'Limite transaccional',
      flex: 1,
      minWidth: 220,
      renderCell: (params: any) => {
        return <span>{params.row.limite}</span>;
      },
    },
    {
      field: 'limitePos',
      headerName: 'Limite POS',
      flex: 1,
      minWidth: 160,
      renderCell: (params: any) => {
        if (Number(params.row.id) > 6502 && params?.row?.tipoNegocio?.pais?.nombre === 'Guatemala')
          return (
            <span>
              {Number(temporalNegocioLimites[params?.row?.tipoNegocio?.nombre]?.pos ?? 0) === 0
                ? 'Sin limite'
                : temporalNegocioLimites[params?.row?.tipoNegocio?.nombre].pos}
            </span>
          );
        else
          return (
            <span>
              {params?.row?.tipoNegocio?.limitePOS === 0 ? 'Sin límite' : params?.row?.tipoNegocio?.limitePOS}
            </span>
          );
      },
    },
    {
      field: 'limiteLink',
      headerName: 'Limite Link',
      flex: 1,
      minWidth: 160,
      renderCell: (params: any) => {
        if (Number(params.row.id) > 6502 && params?.row?.tipoNegocio?.pais?.nombre === 'Guatemala')
          return <span>{Math.round(Number(temporalNegocioLimites[params?.row?.tipoNegocio?.nombre]?.link ?? 0))}</span>;
        else
          return (
            <span>
              {params?.row?.tipoNegocio?.limiteLink === '0.0000' ? 'Sin límite' : params?.row?.tipoNegocio?.limiteLink}
            </span>
          );
      },
    },
    {
      field: 'rolUsuario',
      headerName: 'Archivos',
      sortable: false,
      minWidth: 200,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Chip
              label={`${params.row.archivosAprobados} de ${params.row.archivosPendientes}`}
              color={params.row.archivosAprobados === params.row.archivosPendientes ? 'default' : 'secondary'}
            />
            <p style={{ marginLeft: '10px' }}>{params.row.haveAutoApproved ? 'Pre-Aprobado' : ''}</p>
          </div>
        );
      },
    },
    {
      field: 'Activa',
      headerName: 'Activa',
      sortable: false,
      minWidth: 100,
      disableClickEventBubbling: true,
      valueGetter: (params: any) =>
        params &&
        params.row &&
        params.row.rolUsuario &&
        params.row.rolUsuario[0] &&
        params.row.rolUsuario[0].usuario &&
        params.row.rolUsuario[0].usuario &&
        params.row.rolUsuario[0].usuario.contratoFirmado
          ? 'Activada'
          : 'Desactivada',
      valueOptions: ['Activada', 'Desactivada'],
      filterModel: [
        {
          items: [
            { columnField: 'Activa', operatorValue: 'equals', value: 'Activada', id: 1 },
            { columnField: 'Activa', operatorValue: 'equals', value: 'Desactivada', id: 2 },
          ],
        },
      ],
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            {params.row.activa ? <CheckIcon color='primary' /> : <ClearIcon color='secondary' />}
          </div>
        );
      },
    },

    {
      field: 'afiliador',
      headerName: 'Afiliador',
      sortable: false,
      minWidth: 200,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        let userAfiliadorId = '';
        let negocioId = '';
        if (params && params.row && params.row.UsuarioAfiliadorId) userAfiliadorId = params.row.UsuarioAfiliadorId;

        if (params && params.row && params.row.id) negocioId = params.row.id;

        const afiliadores = usuariosAfiliadores.filter(
          user => !user.nombre?.startsWith('BAM') && !user?.apodo?.startsWith('Dealer'),
        );
        const usuariosBAM = usuariosAfiliadores.filter(user => user.nombre?.startsWith('BAM'));

        // @ts-ignore
        const usuariosDealers = usuariosAfiliadores.filter(user => user?.apodo?.startsWith('Dealer'));

        const users = [
          { id: 'Afiliadores (Fuerza interna)', nombre: 'divider' },
          ...afiliadores,
          { id: 'BAM', nombre: 'divider' },
          ...usuariosBAM,
          { id: 'Dealers (Externos)', nombre: 'divider' },
          ...usuariosDealers,
        ].filter(
          user =>
            (user.nombre?.length ?? 0) > 0 &&
            !['Farid', 'Luis', 'Contabilidad', 'Jmarquez', 'lorena', 'Estefani'].includes(user.nombre ?? ''),
        );

        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.UsuarioAfiliadorId ? params.row.UsuarioAfiliadorId : 0}
                onChange={async (e: any) => {
                  let respuesta = await updateAfiliadorNegocio(negocioId, e.target.value);
                  if (respuesta) params.row.UsuarioAfiliadorId = e.target.value;
                }}
                name='age'
                inputProps={{ 'aria-label': 'age' }}
                style={{ minWidth: 150 }}
              >
                {users.map(user => {
                  return user.nombre === 'divider' ? (
                    <ListSubheader>
                      {user.id}
                      <hr />
                    </ListSubheader>
                  ) : (
                    <MenuItem
                      style={{ minWidth: 150 }}
                      key={user.id}
                      value={user.id}
                    >
                      <ListItemIcon>
                        <Checkbox
                          style={{ display: 'inline' }}
                          checked={params.row.UsuarioAfiliadorId == user.id}
                        />
                      </ListItemIcon>
                      <Typography
                        variant='inherit'
                        noWrap
                      >
                        {user.nombre}
                      </Typography>

                      {/* <Checkbox style={{ display: 'inline' }} checked={params.row.UsuarioAfiliadorId == user.id} />
                                        <ListItemText style={{ display: 'inline' }} primary={user.nombre} /> */}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'ubicacion',
      headerName: 'Ubicación',
      minWidth: 150,
      renderCell: (params: any) => {
        const currentAfiliador: UserInterface | undefined = usuariosAfiliadores.find(
          user => user.id === params.row.UsuarioAfiliadorId,
        );

        const ubicacion = currentAfiliador?.apodo?.startsWith('Dealer')
          ? currentAfiliador.apodo?.replace('Dealer', '')
          : '';
        return ubicacion;
      },
    },

    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'NegocioPerdido',
      headerName: 'Motivo de perdida',
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => {
        const rowValue = params.row?.NegocioPerdido?.[params.row?.NegocioPerdido?.length - 1 ?? 0]
          ? params.row.NegocioPerdido[params.row?.NegocioPerdido?.length - 1].motivoPerdida.id
          : '0';
        const stateValue = motivoTemp[params.row.id];
        return (
          <FormControl fullWidth>
            <Select
              fullWidth
              value={stateValue ?? rowValue}
              onChange={async (e: any) => {
                const motivo = listadoMotivos[e.target.value];
                editNegocio({ ...params.row, motivoPerdida: [motivo] });
                setMotivoTemp(prevMotivos => ({ ...prevMotivos, [params.row.id]: e.target.value }));
              }}
              name='age'
              inputProps={{ 'aria-label': 'motivos' }}
            >
              {listadoMotivos.map((motivos, key) => {
                return (
                  <MenuItem
                    key={`motivos_${key}`}
                    value={key}
                    disabled={motivos.title === ''}
                  >
                    {motivos.title || 'Sin motivos'}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      },
    },
  ];

  const getPaises = () => {
    PaisesServices.instance
      .get()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPaises(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getNegocios = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    addHistorialBusqueda();
    const params = `?page=${page}&pageSize=${pageSize}&paisId=${countryFilter}&nombre=${searchValue}`;
    NegociosServices.instance
      .get(params,signal)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          const negociosConArchivosValidados = await validArchivosPendientes(result);
          const negociosConColumnasValidadas = await validNegociosColumns(negociosConArchivosValidados);
          setRows(negociosConColumnasValidadas);
          setTotalPages(totalPages);
        }
        setLoading(false);
        getListadoBusqueda()
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const validArchivosPendientes = (negocios: any): Promise<any> => {
    return Promise.all(
      negocios.map((negocio: any) => {
        let archivosPendientes = 0;
        let archivosAprobados = 0;
        let haveAutoApproved = false;
        const archivosRequeridosList = negocio.documentosNegocio.filter((doc: any) => {
          if (doc.observaciones?.toLowerCase()?.includes('pre-aprobado')) haveAutoApproved = true;
          return !doc.quitarLimite;
        });

        archivosRequeridosList.forEach((archivo: any) => {
          if (archivo.status === 'aprobado') {
            archivosAprobados++;
          }
        });
        archivosPendientes = archivosRequeridosList.length;

        return {
          ...negocio,
          haveAutoApproved,
          archivosPendientes,
          archivosAprobados,
        };
      }),
    );
  };

  const updateAfiliadorNegocio = async (NegocioId: any, UsuarioAfiliadorId: any) => {
    setLoading(true);
    let req = await NegociosServices.instance.updateAfiliadorNegocio(NegocioId, UsuarioAfiliadorId);
    if (!req) {
      handleErrorMessage('No se pudo realizar la accion');
      handleActiveError(true);
      setLoading(false);
      return false;
    } else if (req.error) {
      handleErrorMessage(req.message);
      handleActiveError(true);
      setLoading(false);
      return false;
    } else {
      handleSuccessMessage(req.message);
      handleActiveSuccess(true);
      setLoading(false);
      return true;
    }
  };

  const getUsuariosAdministradoresAfiliadores = async () => {
    //setLoading(true);
    let req = await NegociosServices.instance.getUsuariosAdministradoresAfiliadores();
    const res = await DealersService.instance.getDealersList();

    setDealers(res.result.map((dealer: UserInterface) => ({ ...dealer, nombre: `${dealer.nombre} (Dealer)` })));

    setUsuariosAfiliadores(req.result);
    //setLoading(false);
  };

  const editRecord = (record: any) => {
    const { id } = record;
    handleSelectRecord({});
    history.push(`/admin-home/negocios/${id}`);
  };

  const getAllVerifications = async () => {
    const response: any = await VerificationService.instance.getAllVerifications(signalAllVerifications);
    setVerifications(response.result);
  };

  const openVerificationDetail = async (params: any) => {
    try {
      if (params.hooks) {
        const hooks = JSON.parse(params.hooks);
        let urlRedirect = '';
        let verificacionId = params.verificacionId;
        for (let i = 0; i < hooks.length; i++) {
          if (hooks[i].matiDashboardUrl) {
            urlRedirect = hooks[i].matiDashboardUrl;
            break;
          }
        }

        if (!urlRedirect) {
          try {
            const result = await VerificationService.instance.getVerificationByMetaMapId(verificacionId);
            const identityId = result?.result?.identity?.id ?? '';
            urlRedirect = `https://dashboard.metamap.com/identity/${identityId}/verification/${verificacionId}`;
          } catch (error) {
            if (error instanceof Error) {
              handleErrorMessage(error.message);
            } else {
              handleErrorMessage('An unexpected error occurred.');
            }
          }
        }

        window.open(urlRedirect, '_blank');
      }
    } catch (err) {}
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      filtrar();
    }
  };

  const handleCountryFilterChange = (event: any) => {
    setCountryFilter(event.target.value);
  };
  const addHistorialBusqueda = async () => {
    let user = await localStorage.instance.getUserParseado() ;
    const userId = user?.id?.toString() || ''
    if (searchValue && userId){
      await NegociosServices.instance.addHistorialBusqueda(userId, searchValue);
    }
  }

  const filtrar = async () => {
    getNegocios();
  };

  const procesarGetNegocios = () => {
    if (page === 1) getNegocios();
    else setPage(1);
  }

  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(procesarGetNegocios, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  useEffect(() => {
    if (page === 1) getNegocios();
    else setPage(1);
  }, [countryFilter]);

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
    >
      <Grid
        container
        direction='column'
      >
        <Grid item>
          <Grid container>
            <Grid
              item
              xs={12}
              md={2}
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <Typography variant='h6'>NEGOCIOS</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
            >
              <Box
                justifyContent={['flex-start', 'flex-start', 'flex-end']}
                sx={{ flexDirection: 'row', display: 'flex' }}
              >
                 
                 <Autocomplete
                  freeSolo
                  id="busquedaNegocio"
                  disableClearable
                  options={listadoBusqueda.map((option) => option.busqueda)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Buscar negocio'
                      placeholder='1862'
                      variant='outlined'
                      style={{ marginRight: 10,  height: '40px', width: '240px' }}
                      size='small'
                      name='search'
                      value={searchValue}
                      onKeyDown={handleSearchKeyDown}
                      onChange={handleSearchChange}
                      InputProps={{ ...params.InputProps, type: 'search'}}
                    />
                  )}
                />
               
               
                <FormControl
                  style={{ marginRight: 10 }}
                  size='small'
                >
                  <Select
                    placeholder='País'
                    variant='outlined'
                    value={countryFilter}
                    onChange={handleCountryFilterChange}
                    fullWidth
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    {paises?.map((act: any) => {
                      return (
                        <MenuItem
                          key={act.id}
                          value={act.id}
                        >
                          {act.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button
                  component={Link}
                  to='negocio'
                  fullWidth
                  size='small'
                  style={{ width: '100px', height: '40px' }}
                  variant='contained'
                  color='primary'
                >
                  CREAR
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          style={{ height: '100%', marginTop: 20, width: 'auto' }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            rowHeight={50}
            paginationMode='server'
            pageSize={pageSize}
            rowCount={totalPages * pageSize}
            hideFooterPagination
            hideFooter
            autoHeight
          />
          <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
            <Pagination
              count={totalPages}
              page={page}
              shape='rounded'
              size='medium'
              variant='outlined'
              color='primary'
              style={{ padding: '8px', textAlign: 'center' }}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </Grid>
      </Grid>
      <PageLoader loading={loading} />
    </Box>
  );
}
