import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import PageLoader from '../../components/PageLoader';
import { Images } from '../../styles/images';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, PropTypes } from '@material-ui/core';
import PayWithLinkService from '../../services/PayWithLinkService';
import { Rutas } from '../../resources/Statics'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  header: {
    paddingTop: '1%',
  },
  titleContainer: {
    textAlign: 'center',
    marginTop: '4vh',
  },
  title: {
    fontWeight: 'normal',
  },
  boldText: {
    fontWeight: 'bold',
  },
  reciboTitle: {
    fontSize: '1rem',
    textAlign: 'center',
    marginTop: '1rem',
  },
  paperStyle: {
    padding: '1rem',
    width: '60vw',
    margin: 'auto',
  },
  pdfContainer: {
    margin: 'auto',
    width: '100%',
    height: '70vh',
  },
  contentContainer: {
    margin: 'auto',
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: "2rem"
  },
  footerContainer: {
    marginTop: "2rem",
    textAlign: "center"
  }
}));

export default function VoucherLink() {
  const classes = useStyles();
  const params = useParams<{ code: string }>();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState<any>({});
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    console.log('params', params);
    
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const result = await PayWithLinkService.instance.getInfoLink(params.code);
      console.log('result', result);
      setData(result.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const openLink = () => {
    window.open(data.linkVoucherCliente, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <div className={classes.root}>
        {!loading && (
          <Container>
            <div className={classes.header}>
              <img
                src={Images.instance.logo}
                style={{
                  marginTop: '0.5rem',
                  height: '60px',
                  width: 'auto',
                }}
              />
            </div>
            <div className={classes.titleContainer}>
              <h3 className={classes.title}>
                Se ha realizado un pago exitosamente a <span className={classes.boldText}>{data.negocio ? data.negocio.nombre: ""}</span>
              </h3>
            </div>
            <div className={classes.contentContainer}>
              <Paper
                elevation={2}
                className={classes.paperStyle}
              >
                <embed
                  src={`${Rutas.instance.urlBase}/pagoenlace/byHash/${params.code}`}
                  className={classes.pdfContainer}
                  type='application/pdf'
                ></embed>
              </Paper>
              <div className={classes.buttonContainer}>
                <Button
                  color='primary'
                  variant="contained"
                  onClick={openLink}
                >
                  Descargar
                </Button>
              </div>
            </div>

            <div className={classes.footerContainer}>
                <p>¿Buscas transaccionar rápidamente tarjetas de crédito? Descarga la aplicación y registrate</p>
                <div style={{ cursor: 'pointer', display: 'inline' }}>
                    <a href='https://play.google.com/store/apps/details?id=com.paggoapp'>
                        <img width={(width * 0.10)}  src={Images.instance.logoGooglePlay} alt="" />
                    </a>
                </div>
                <div style={{ cursor: 'pointer', display: 'inline', paddingLeft: '1%' }}>
                    <a href='https://apps.apple.com/gt/app/paggo/id1611051340'>
                        <img width={(width * 0.10)}  src={Images.instance.logoAppStore} alt="" />
                    </a>
                </div>
            </div>
          </Container>
        )}

        <PageLoader loading={loading} />
      </div>
    </>
  );
}
