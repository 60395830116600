import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { DataGrid, GridRowParams } from '@material-ui/data-grid';


type ModalHistorialProps = {
    isOpen: boolean;
    historialRows: [];
    columnsHistorialEstados: any[];
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ModalHistorial({historialRows = [], setIsOpen, isOpen , columnsHistorialEstados = [] }: ModalHistorialProps) {

  const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            },
            paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '75vw',
            },
            paper_2: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            minWidth: '30vw',
            },
            imageList: {
            width: 500,
            height: 450,
            },
        }),
    );
    
  const classes = useStyles();

  return (
    <Modal
          className={classes.modal}
          open={isOpen}
          onClose={() => { setIsOpen(false) }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
            <Fade in={isOpen}>
            <div className={classes.paper}>
              <p id='transition-modal-description'>Registro histórico</p>
              
              <Grid
                container
                direction='column'
                spacing={2}
              >
                <Grid
                  item
                  style={{ height: 700, width: 'auto' }}
                >
                  <DataGrid
                    isRowSelectable={(params: GridRowParams) => false}
                    rows={historialRows}
                    columns={columnsHistorialEstados}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
  )

}
