/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as momentZone from 'moment-timezone';
import {
  Backdrop,
  Breadcrumbs,
  Button,
  Chip,
  createStyles,
  Fade,
  FormControl,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { read, utils } from 'xlsx';
import Spreadsheet, { CellBase, Matrix } from 'react-spreadsheet';
import { useHistory } from 'react-router-dom';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { useContext, useEffect, useState } from 'react';
import { List, NavigateNext } from '@material-ui/icons';
/**
 * Internal Libraries
 */
import { settlementStatusBusiness } from '../../resources/Statics';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
/**
 * Interfaces
 */
import { BaseSyntheticEvent } from 'react';
import { FileData } from '../../interfaces/Interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '75vw',
    },
    paper_2: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      minWidth: '30vw',
    },
    imageList: {
      width: 500,
      height: 450,
    },
  }),
);

export default function LiquidacionesNegocioHonduras() {
  const {
    handleErrorMessage,
    handleActiveError,
    handleLoading,
    loading,
    selectedRecord,
    handleSuccessMessage,
    handleActiveSuccess,
  } = useContext(GeneralContext);

  const classes = useStyles();
  const history = useHistory();

  const [openModalTransactions, setOpenModalTransactions] = useState(false);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [settlements, setSettlements] = useState<[]>([]);
  const [pendingSettlements, setPendingSettlements] = useState<[]>([]);
  const [exportedSettlements, setExportedSettlements] = useState<[]>([]);
  const [payableSettlements, setPayableSettlements] = useState<[]>([]);

  const [transactions, setTransactions] = useState<[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState(0);
  const [fileData, setFileData] = useState<{ type: boolean; settlement?: string; transactions: FileData[] }>({
    type: true,
    settlement: '',
    transactions: [],
  });
  const [dataToShow, setDataToShow] = useState<any>([]);

  useEffect(() => {
    console.log('Entra en honduras');
    getBusinessSettlements();
  }, []);

  const getBusinessSettlements = () => {
    handleLoading(true);
    ReportesServices.instance
      .getBusinessSettlements(selectedRecord.id)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setSettlements(result);
          setPendingSettlements(result.filter((item: any) => item.estado !== 'Pagado'));
          setExportedSettlements(result.filter((item: any) => item.estado === 'Exportada'));
          setPayableSettlements(result.filter((item: any) => item.estado === 'Reprocesar'));
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getTransactionsSettlementByBusiness = () => {
    setOpenModalTransactions(true);
    handleLoading(true);
    ReportesServices.instance
      .getTransactionsSettlementsByBusiness(selectedRecord.id, selectedBusiness)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setTransactions(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const generatePendingSettlement = () => {
    ReportesServices.instance
      .generatePendingSettlement(selectedRecord.nombre)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            handleActiveError(true);
          }
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'nombre', headerName: 'Código', minWidth: 150 },
    { field: 'negocio', headerName: 'Negocio', minWidth: 150 },
    { field: 'monto', headerName: 'A liquidar', minWidth: 200 },
    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.estado}
                disabled={params.row.estado === 'Pagado'}
                onChange={async (e: any) => {
                  handleLoading(true);
                  ReportesServices.instance
                    .updateSettlementBusinessState({ status: e.target.value }, params.row.id)
                    .then(response => {
                      const { error, result } = response;
                      if (!error) {
                        getBusinessSettlements();
                      }
                      handleLoading(false);
                    })
                    .catch(e => {
                      handleLoading(false);
                      handleErrorMessage('message');
                      handleActiveError(true);
                    });
                }}
                style={{ minWidth: 140, lineHeight: 'unset' }}
              >
                {settlementStatusBusiness.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                    >
                      <Chip
                        label={status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'banco',
      headerName: 'Banco',
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => {
        return <span>{params.row.negocioid.informacionBancaria[0].banco.toUpperCase()}</span>;
      },
    },
    {
      field: 'cuenta',
      headerName: 'Cuenta',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return <span>{params.row.negocioid.informacionBancaria[0].numeroCuenta.toUpperCase()}</span>;
      },
    },
    {
      field: 'Transacciones',
      sortable: false,
      width: 150,
      align: 'center',
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={getTransactionsSettlementByBusiness}
            >
              <List />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Exportación',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaInicial',
      headerName: 'Fecha Inicio',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaInicial'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaFinal',
      headerName: 'Fecha Final',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaFinal'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
  ];

  const columnsTransactions = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'monto', headerName: 'Pagado', minWidth: 200 },
    { field: 'montoNeto', headerName: 'A liquidar', minWidth: 200 },
    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaRealizoPago',
      headerName: 'Fecha Pago',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fechaRealizoPago'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
  ];

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ maxHeight: '100vh', overflow: 'auto' }}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid
          container
          style={{ padding: 10 }}
          alignItems='center'
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Breadcrumbs
              separator={<NavigateNext fontSize='medium' />}
              aria-label='breadcrumb'
            >
              <Link
                href='#'
                underline='hover'
                variant='h6'
                onClick={() => history.goBack()}
              >
                {selectedRecord.nombre}
              </Link>
              <Typography
                variant='h6'
                color='textPrimary'
              >
                Liquidaciones por Negocio
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid
            item
            sm={3}
            xs={6}
          >
            <Button
              color='primary'
              disabled={
                pendingSettlements.length === 0 ||
                exportedSettlements.length === settlements.length ||
                payableSettlements.length === 0
              }
              variant='outlined'
              onClick={() => generatePendingSettlement()}
            >
              Exportar reprocesados
            </Button>
          </Grid>

          <Grid
            item
            sm={3}
            xs={6}
          >
            <Button
              color='primary'
              variant='contained'
              disabled={pendingSettlements.length === 0}
              onClick={() => {
                setDataToShow([]);
                setFileData({ type: false, transactions: [] });
                setOpenModalUploadFile(true);
              }}
            >
              Subir CSV
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          className='HideScrollbars'
          style={{ height: 'calc(100vh - 200px)', width: 'auto' }}
        >
          <DataGrid
            rowHeight={50}
            columns={columns}
            rows={settlements}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            onCellEnter={params => setSelectedBusiness(params.row.NegocioId)}
          />
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={openModalTransactions}
        onClose={() => setOpenModalTransactions(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalTransactions}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '1rem', marginTop: '1rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
              >
                <Grid
                  item
                  style={{ height: 400, width: 'auto' }}
                >
                  <DataGrid
                    rowHeight={50}
                    loading={loading}
                    rows={transactions}
                    columns={columnsTransactions}
                    rowsPerPageOptions={[25, 50, 100, 500, 1000]}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        className={classes.modal}
        open={openModalUploadFile}
        onClose={() => {
          setOpenModalUploadFile(false);
          setDataToShow([]);
          setFileData({ type: false, transactions: [] });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalUploadFile}>
          <div className={classes.paper_2}>
            <Grid
              container
              direction='column'
              spacing={3}
            >
              <Grid item>
                <input
                  type={'file'}
                  onChange={async (event: BaseSyntheticEvent<{}, {}, HTMLInputElement>) => {
                    try {
                      let internalOrExternal = true;

                      const internalKeys = [
                        'No op',
                        'Id Local',
                        'Cuenta a Acreditar',
                        'Nombre',
                        'Tipo Operacion',
                        'Monto',
                        'Adenda',
                        'Fecha env',
                        'Fecha proc',
                        'Estatus',
                      ];

                      const externalKeys = [
                        'NÚMERO',
                        'ID',
                        'NOMBRE',
                        'BANCO',
                        'NO CUENTA',
                        'TIPO DE CUENTA',
                        'DESCRIPCIÓN',
                        'CORREO',
                        'MONTO',
                        'ESTADO',
                      ];

                      const file = event.target.files![0];

                      if (
                        file.type === 'text/csv' ||
                        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ) {
                      } else {
                        throw new Error('Archivo no es valido');
                      }

                      const data = await file.arrayBuffer();

                      const workbook = read(data);

                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                      const json: FileData[] = utils.sheet_to_json(worksheet);

                      if (!json.length) throw new Error('Archivo sin operaciones por validar');

                      const fileDataKeys = Object.keys(json[0]);

                      if (fileDataKeys.toString() === externalKeys.toString()) {
                        internalOrExternal = false;
                      } else if (fileDataKeys.toString() === internalKeys.toString()) {
                        internalOrExternal = true;
                      } else {
                        throw new Error('Archivo no cumple los requisitos');
                      }

                      if (internalOrExternal !== selectedRecord.tipo) {
                        throw new Error(`Archivo no es del tipo - ${selectedRecord.tipo ? 'INTERNO' : 'EXTERNO'}`);
                      }

                      const result = internalOrExternal
                        ? json.filter((item: FileData) => item['Tipo Operacion'] === 'Credito')
                        : json;

                      if (internalOrExternal) {
                        const settlementName = result[0]['Id Local'].toString().split('-');

                        if (settlementName[0] !== `${selectedRecord.nombre}${selectedRecord.correlativo}`) {
                          throw new Error('El ID de las liquidaciones no es igual');
                        }
                      }

                      const spreadsheetData: Matrix<CellBase> = result.map((item: FileData) => {
                        const temp: CellBase[] = [];

                        Object.keys(item).forEach(key => {
                          if (
                            key === 'Cuenta a Acreditar' ||
                            key === 'Estatus' ||
                            key === 'Id Local' ||
                            key === 'Tipo Operacion' ||
                            key === 'Nombre' ||
                            key === 'Monto' ||
                            key === 'NOMBRE' ||
                            key === 'NÚMERO' ||
                            key === 'NO CUENTA' ||
                            key === 'MONTO' ||
                            key === 'ESTADO'
                          ) {
                            if (typeof item[key] === 'string') {
                              temp.push({ value: item[key].toString().replace(/'/g, ''), readOnly: true });
                            } else {
                              temp.push({ value: item[key], readOnly: true });
                            }
                          }
                        });

                        return temp;
                      });

                      if (internalOrExternal) {
                        spreadsheetData.unshift([
                          { value: 'Id Local' },
                          { value: 'Cuenta a Acreditar' },
                          { value: 'Nombre' },
                          { value: 'Tipo Operacion' },
                          { value: 'Monto' },
                          { value: 'Estatus' },
                        ]);
                      } else {
                        spreadsheetData.unshift([
                          { value: 'NÚMERO' },
                          { value: 'NOMBRE' },
                          { value: 'NO CUENTA' },
                          { value: 'MONTO' },
                          { value: 'ESTADO' },
                        ]);
                      }
                      
                      setDataToShow(spreadsheetData);

                      setFileData({
                        type: internalOrExternal,
                        settlement: selectedRecord.nombre,
                        transactions: result,
                      });

                      handleSuccessMessage('Archivo Cargado Exitosamente!');
                      handleActiveSuccess(true);
                    } catch (error) {
                      handleActiveError(true);
                      handleErrorMessage(error instanceof Error ? error.message : `Error: ${error}`);
                    }
                  }}
                />

                <Button
                  color='primary'
                  variant='contained'
                  disabled={fileData.transactions.length ? false : true}
                  onClick={() => {
                    handleLoading(true);
                    setOpenModalUploadFile(false);
                    ReportesServices.instance
                      .validateSettlements(fileData)
                      .then(response => {
                        const { error, message } = response;
                        if (!error) {
                          getBusinessSettlements();
                          handleActiveSuccess(true);
                          handleSuccessMessage(message);
                        } else {
                          getBusinessSettlements();
                          handleActiveError(true);
                          handleErrorMessage(message);
                        }
                        handleLoading(false);
                      })
                      .catch(e => {
                        handleLoading(false);
                        handleErrorMessage('message');
                        handleActiveError(true);
                      });
                  }}
                >
                  Enviar
                </Button>
              </Grid>

              {dataToShow.length ? (
                <Grid
                  item
                  style={{ height: 350, overflow: 'auto' }}
                >
                  <Spreadsheet data={dataToShow} />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>

      <PageLoader loading={loading} />
    </Box>
  );
}
