/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import { utils, writeFile } from 'xlsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as momentZone from 'moment-timezone';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Popover, Select, TextField, TextFieldProps, TextareaAutosize, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import ContracargosService from '../../services/ContracargosService';
import AbonoContracargoService from '../../services/AbonoContracargoService';
import HistoryIcon from '@material-ui/icons/History';
/**
 * External Libraries
 */
import Utilities from '../../libs/utilities';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import PageLoader from '../../components/PageLoader';
import { categoriasContracargos, toLiquidate } from '../../resources/Statics';
import LoadingButton from '../../components/LoadingButton';
import ReportesServices from '../../services/ReportesService';
import { DropzoneDialog } from 'material-ui-dropzone';
import DocumentosService from '../../services/DocumentosService';
import RazonesService from '../../services/RazonesService';
/**
 * Component Definition
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '70vw',
    },
    hideScrollbars: {
      height: 'calc(100vh - 310px)',
      width: '100%',
      marginTop: '25px',
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
      }
    },
    imageList: {
      width: 500,
      height: 450,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 300,
    },
  }),
);
export default function TransaccionesGenerales() {
  const { handleErrorMessage, handleActiveError, handleLoading, loading, handleActiveSuccess, handleSuccessMessage } =
    useContext(GeneralContext);

  const [filterType, setFilterType] = useState('todas');

  const startDateFilterRef = useRef<TextFieldProps>(null);
  const endDateFilterRef = useRef<TextFieldProps>(null);

  const [startDateFilter, setStartDateFilter] = useState<string | undefined>();
  const [endDateFilter, setEndDateFilter] = useState<string | undefined>();

  const [filterTransactions, setFilterTransactions] = useState<any[]>([]);
  const [allTransactions, setAllTransactions] = useState<any[]>([]);
  const [payFacTransactions, setPayFacTransactions] = useState<any[]>([]);
  const [agregatorTransactions, setAgregatorTransactions] = useState<any[]>([]);
  const [openDialogDeleteContracargo, setOpenDialogDeleteContracargo] = useState(false);
  const [contracargoActual, setContracargoActual] = useState<any>(null);
  const [estadoLiquidar, setEstadoLiquidar] = useState<string>('');
  const [transaccionActual, setTransaccionActual] = useState<any>(null);
  const [statusActual, setStatusActual] = useState<any>(null);

  const [index, setIndex] = useState(momentZone.tz('America/Guatemala').day());

  const [datesFiltered, setDatesFiltered] = useState<string>('');

  const [searchValue, setSearchValue] = useState('');

  const [openModalRazon, setOpenModalRazon] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);

  const [razonCategoria, setRazonCategoria] = useState('');
  const [linksDocumentosRazonesCategoria, setLinksDocumentosRazonesCategoria] = useState([]);
  const [valorLiquidar, setValorLiquidar] = useState('');
  const [paramsLiquidar, setParamsLiquidar] = useState<any>();
  const [categoria, setCategoria] = useState<string>('')
  const [rowsHistorialCredenciales, setRowsHistorialCredenciales] = useState([]);
  const [openHistorialRazones, setOpenHistorialRazones] = useState(false);
  const [open, setOpen] = useState(false);

  const [filterDays, setFilterDays] = useState([
    { name: 'Lunes', isSelected: false },
    { name: 'Martes', isSelected: false },
    { name: 'Miercoles', isSelected: false },
    { name: 'Jueves', isSelected: false },
    { name: 'Viernes', isSelected: false },
  ]);

  const classes = useStyles();

  useEffect(() => {
    filterDays.forEach((day, index) => {
      if (index === momentZone.tz('America/Guatemala').day() - 1) day.isSelected = true;
    });
  }, []);

  useEffect(() => {
    if (typeof startDateFilter === 'undefined' && typeof endDateFilter === 'undefined') {
      getData();
    }
  }, [startDateFilter, endDateFilter, index]);

  const verHistorial = async (id: any, contracargoId: any) => {
    await RazonesService.instance
      .getByTransaccionAndContraCargo(id,contracargoId)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsHistorialCredenciales(result);
          handleLoading(false);
          setOpenHistorialRazones(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      minWidth: 100,
      renderCell: (params: any) => {
        return <span>{params?.row?.id}</span>;
      },
    },
    {
      field: 'idnego',
      headerName: 'Id Negocio',
      minWidth: 160,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.id}</span>;
      },
    },
    {
      field: 'BIN',
      headerName: 'BIN',
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params?.row?.bin}</span>;
      },
    },
    {
      field: 'nombre',
      headerName: 'Negocio',
      minWidth: 260,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.nombre}</span>;
      },
    },
    {
      field: 'banco',
      headerName: 'Banco',
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.informacionBancaria?.[0]?.banco}</span>;
      },
    },
    {
      field: 'tipoCuenta',
      headerName: 'Tipo cuenta',
      minWidth: 210,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.informacionBancaria?.[0]?.tipoCuenta}</span>;
      },
    },
    {
      field: 'numeroCuenta',
      headerName: 'No. cuenta',
      minWidth: 160,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.informacionBancaria?.[0]?.numeroCuenta}</span>;
      },
    },
    {
      field: 'monto',
      headerName: 'Monto',
      minWidth: 150,
    },
    {
      field: 'montoNeto',
      headerName: 'Monto Neto',
      minWidth: 200,
    },
    {
      field: 'debitoContracargo',
      headerName: 'Debito Contracargo',
      minWidth: 225,
    },
    {
      field: 'comisionMcc',
      headerName: 'Comisión MCC',
      minWidth: 200,
    },
    {
      field: 'comisionVisa',
      headerName: 'Comisión Visa',
      minWidth: 200,
    },
    {
      field: 'comision3dsNeonet',
      headerName: 'Costo 3ds Neonet',
      minWidth: 240,
    },
    {
      field: 'retencion',
      headerName: 'Retención',
      minWidth: 200,
    },
    {
      field: 'liquidoPaggo',
      headerName: 'Líquido Paggo',
      minWidth: 200,
    },
    {
      field: 'comisionPaggo',
      headerName: 'Comisión Paggo',
      minWidth: 200,
    },
    {
      field: 'ivaComisionPaggo',
      headerName: 'IVA Comisión Paggo',
      minWidth: 240,
    },
    {
      field: 'nit',
      headerName: 'Nit',
      minWidth: 200,
    },
    {
      field: 'descuentoPaggo',
      headerName: 'Descuento Paggo',
      minWidth: 240,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.descuentoPaggo}</span>;
      },
    },
    { field: 'status', headerName: 'Estado', minWidth: 150 },
    {
      field: 'numeroDeVisaCuotas',
      headerName: 'Tipo de Pago',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return <span>{Utilities.dues(params.value)}</span>;
      },
    },
    {
      field: 'liquidar',
      headerName: 'Liquidar',
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.contracargo ? 'Contracargo' : params.row.liquidar ? 'Si' : 'No'}
                onChange={async (e: any) =>  {
                  if (e.target.value !== 'Si') {
                    handleopenModalRazon(e, params)
                  } else {
                    setValorLiquidar(e.target.value);
                    setParamsLiquidar(params);
                  }
                } 
                }
                style={{ minWidth: 140, lineHeight: 'unset' }}
              >
                {toLiquidate.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                    >
                      <Chip
                        label={status.label ? status.label : status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'historico',
      headerName: 'Historico liquidar',
      flex: 1,
      minWidth: 225,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id, params?.row?.contracargo?.id)} color='inherit' >
          <small>Ver historial</small> <HistoryIcon fontSize='small'></HistoryIcon>
        </Button>
      </FormHelperText>;
      },
    },
    {
      field: 'fechaRealizoPago',
      headerName: 'Fecha',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaRealizoPago'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'medio',
      headerName: 'Medio',
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <Chip
            label={params?.row?.status === 'pagado' ? 'Link' : 'Tarjeta'}
            style={{ background: params?.row?.status === 'pagado' ? '#66bb6a' : '#5166AF', color: 'white' }}
          />
        );
      },
    },
    {
      field: 'authorizationNumber',
      headerName: 'Codigo Autorización',
      flex: 1,
      minWidth: 225,
      renderCell: (params: any) => {
        return <span>{params?.row?.status === 'pagado' ? params.row.authorizationNumber : params.row.authNumber}</span>;
      },
    },
    {
      field: 'hash',
      headerName: 'Código de link',
      flex: 1,
      minWidth: 225,
      renderCell: (params: any) => {
        return <span>{params?.row?.status === 'pagado' ? params.row.hash : 'N/A'}</span>;
      },
    },
    {
      field: 'direccion',
      headerName: 'Dirección negocio',
      minWidth: 600,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.numeroCasa}</span>;
      },
    },
    {
      field: 'email',
      headerName: 'Email usuario negocio',
      minWidth: 350,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.rolUsuario?.[0]?.usuario?.email}</span>;
      },
    },
    {
      field: 'regimen',
      headerName: 'Régimen fiscal',
      minWidth: 350,
      flex: 1,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.tipoRegimen?.nombre}</span>;
      },
    },
    {
      field: 'tipoNegocio',
      headerName: 'Tipo negocio',
      minWidth: 300,
      flex: 1,
      renderCell: (params: any) => {
        //return <span>{params?.row?.negocio?.tipoNegocio?.nombre}</span>;
        return <span>{
          getTipoNegocio(params?.row?.negocio)
        }</span>;
      },
    },
  ];

  const getData = () => {
    handleLoading(true);
    ReportesServices.instance
      .transaccionesGenerales({ index, startDateFilter, endDateFilter, searchValue })
      .then(response => {
        let { error, result, message } = response;
        if (!error) {
          setAllTransactions(result);
          if (filterType === 'todas') {
            setFilterTransactions(result);
          }

          const agregatorTransactions = result.filter((value: any) => {
            return value.negocio?.tipoNegocio?.categoriaVisa === 'agregador';
          });

          const payFacTransactions = result.filter((value: any) => {
            return value.negocio?.tipoNegocio?.categoriaVisa === 'payfac';
          });

          setAgregatorTransactions(agregatorTransactions);
          setPayFacTransactions(payFacTransactions);

          if (filterType === 'payfac') {
            setFilterTransactions(payFacTransactions);
          } else if (filterType === 'agregador') {
            setFilterTransactions(agregatorTransactions);
          }

          setDatesFiltered(message);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleopenModalRazon = (e: any, params: any) => {
    setOpenModalRazon(true);
    setValorLiquidar(e.target.value);
    setParamsLiquidar(params);
  }

  useEffect(() => {
    if (valorLiquidar === 'Si' && paramsLiquidar )
      handleChange()
  }, [valorLiquidar, paramsLiquidar])
  

  const handleChange = async () => {
    handleLoading(true);
    let fechaTemp = momentZone
      .tz('America/Guatemala')
      .locale('es')
      .format('YYYY-MM-DD')
    if (valorLiquidar == 'Contracargo') {
      const resultContracargo = await ContracargosService.instance.createContracargo({
        valor: paramsLiquidar?.row?.id ? paramsLiquidar.row.id : paramsLiquidar.row.id,
        buscarPor: 'id',
        tipoPago: paramsLiquidar.row.authorizationNumber ? 'enlace' : 'tarjeta',
        fechaEmisionVisa: fechaTemp,
        categoria: categoria,
        documentos: linksDocumentosRazonesCategoria,
        razon: razonCategoria,
        pais: 1
      });

      if (resultContracargo.error) {
        handleErrorMessage(resultContracargo.message);
        handleActiveError(true);
        return;
      }

      const resultAbono = await AbonoContracargoService.instance.createAbonoContracargo({
        transactionId: paramsLiquidar.row.id,
        tipoPago: paramsLiquidar.row.authorizationNumber ? 'enlace' : 'tarjeta',
        ContracargoId: resultContracargo.result.id
      });

      if (resultAbono.error) {
        handleErrorMessage(resultAbono.message);
        handleActiveError(true);
        return;
      }

      const resultService = await ReportesServices.instance.updateTransactionState(
        { status: true, type: paramsLiquidar.row.status, categoria, razon: razonCategoria, documentos: linksDocumentosRazonesCategoria },
        paramsLiquidar.row.id,
      )
      if (resultService.error) {
        handleLoading(false);
        handleActiveError(true);
        handleErrorMessage(resultService.message);
        return;
      }

      getData();
    } else {
      if (paramsLiquidar.row.contracargo) {
        setOpenDialogDeleteContracargo(true)
        setContracargoActual(paramsLiquidar.row.contracargo.id)
        setStatusActual(valorLiquidar)
        setEstadoLiquidar(paramsLiquidar.row.status)
        setTransaccionActual(paramsLiquidar.row.id)
        handleLoading(false);
        return
      }

      ReportesServices.instance
        .updateTransactionState(
          { status: valorLiquidar === 'No' ? false : true, type: paramsLiquidar.row.status, categoria, razon: razonCategoria, documentos: linksDocumentosRazonesCategoria },
          paramsLiquidar.row.id,
        )
        .then(response => {
          const { error, message } = response;
          if (!error) {
            getData();
            handleActiveSuccess(true);
            handleSuccessMessage(message);
          }
          handleLoading(false);
        })
        .catch(e => {
          handleLoading(false);
          handleActiveError(true);
          handleErrorMessage(e.message);
        });
    }
    handleLoading(false);
    setOpenModalRazon(false);
    setRazonCategoria('');
    setLinksDocumentosRazonesCategoria([]);
    setParamsLiquidar(undefined);
    setValorLiquidar('');
    setCategoria('');
  };

  const handleChangeFilter = (event?: any) => {
    handleLoading(true);

    setFilterType(event.target.value);

    if (event.target.value === 'payfac') {
      setFilterTransactions(payFacTransactions);
    } else if (event.target.value === 'agregador') {
      setFilterTransactions(agregatorTransactions);
    } else {
      setFilterTransactions(allTransactions);
    }

    handleLoading(false);
  };

  const deleteContracargo = async () => {
    handleLoading(true);
    const result = await ContracargosService.instance.deleteContracargo({ contracargoId: contracargoActual });
    if (result.error) {
      handleErrorMessage(result.message);
      handleActiveError(true);
    } else {
      handleSuccessMessage(result.message);
      handleActiveSuccess(true);
    }
    setOpenDialogDeleteContracargo(false);
    handleLoading(false);

    ReportesServices.instance
      .updateTransactionState(
        { status: statusActual === 'No' ? false : true, type: estadoLiquidar },
        transaccionActual,
      )
      .then(response => {
        const { error, message } = response;
        if (!error) {
          getData();
          handleActiveSuccess(true);
          handleSuccessMessage(message);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleActiveError(true);
        handleErrorMessage(e.message);
      });
  }

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      getData();
    }
  }

  const filtrar = () => {
    getData();
  };

  const handleCloseModal = () => {
    setOpenHistorialRazones(false);
    setOpenModalRazon(false)
    setRazonCategoria('')
    setParamsLiquidar(undefined);
    setLinksDocumentosRazonesCategoria([])
    setValorLiquidar('');
    setCategoria('');
  };

  const handleOpenUpload = () => {
    setOpenUploadFile(true);
  };

  const handleDocumentosRazones = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);
    DocumentosService.instance
      .uploadFileDocSinId(data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const links = result.map((file: any) => file.Location);
          setLinksDocumentosRazonesCategoria(links)
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const getTipoNegocio = (values: any) => {
    let afiliacion = '';
    if (
      values &&
      values.rolUsuario &&
      values.rolUsuario[0] &&
      values.rolUsuario[0].rol &&
      values.rolUsuario[0].rol.tipo
    ) {
      afiliacion = values.rolUsuario.find((rol: any) => rol.rol.nombre === 'administrador')?.rol?.tipo ?? '';
    }

    afiliacion = afiliacion.replace('administrador', '');
    if (afiliacion.startsWith('_')) afiliacion = afiliacion.substring(1);
    let tipos = [];
    const tiposPayfac = [
      { name: 'No formalizado', value: 'no_formalizado' },
      { name: 'No formalizado Medio', value: 'no_formalizado_medio' },
      { name: 'No formalizado Plus', value: 'no_formalizado_plus' },

      { name: 'title', value: 'Emprendedor / Comerciante' },
      { name: 'Pequeño Contribuyente', value: 'emprendedor' },
      { name: 'Pequeño Contribuyente Plus', value: 'emprendedor_plus' },

      { name: 'title', value: 'Persona individual' },
      { name: 'Persona individual', value: 'persona_individual_payfac' },
      { name: 'Persona individual Plus', value: 'persona_individual_plus' },

      { name: 'title', value: 'Sociedad anónima' },
      { name: 'Sociedad anónima', value: 'sociedad_anonima_payfac' },
      { name: 'Sociedad anónima Plus', value: 'sociedad_anonima_plus' },

      { name: 'title', value: 'Pequeño contribuyente' },
      { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'pequeno_contribuyente_payfac' },
      { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'pequeno_contribuyente_plus' },
    ];
    const tiposPayfacHonduras = [
      { name: 'No formalizado', value: 'h_no_formalizado' },
      { name: 'No formalizado Plus', value: 'h_no_formalizado_plus' },

      { name: 'title', value: 'Emprendedor / Comerciante' },
      { name: 'Pequeño Contribuyente', value: 'h_emprendedor' },
      { name: 'Pequeño Contribuyente Plus', value: 'h_emprendedor_plus' },

      { name: 'title', value: 'Sociedad anónima' },
      { name: 'Sociedad anónima', value: 'h_sociedad_anonima_payfac' },
      { name: 'Sociedad anónima Plus', value: 'h_sociedad_anonima_plus' },

      { name: 'title', value: 'Pequeño contribuyente' },
      { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'h_pequeno_contribuyente_payfac' },
      { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'h_pequeno_contribuyente_plus' },
    ];

    const tiposAgregador = [
      { name: 'Persona individual', value: 'persona_individual' },
      { name: 'Pequeño contribuyente', value: 'pequeno_contribuyente' },
      { name: 'Sociedad anónima', value: 'sociedad_anonima' },
      { name: 'Servicios Profesionales', value: 'servicios_profesionales' },
      { name: 'Fundación', value: 'fundacion' },
      { name: 'Institución Educativa', value: 'institucion_educativa' },
    ];

    if (values?.tipoNegocio?.categoriaVisa === 'payfac' && values.tipoNegocio && values.tipoNegocio.pais && values.tipoNegocio.pais.nombre) {
      afiliacion = values?.tipoNegocio?.nombre;
      tipos.push({
        name: values?.tipoNegocio?.nombre,
        value: values?.tipoNegocio?.nombre,
      });
      tipos = values?.tipoNegocio?.pais.nombre === 'Honduras' ? tiposPayfacHonduras : tiposPayfac;
    } else {
      tipos = tiposAgregador;
    }

    if (!tipos.map(tipo => tipo.value).includes(afiliacion)) {
      tipos.push({
        name: afiliacion,
        value: afiliacion,
      });
    }

    const tipo: any = tipos.find(tipo => tipo.value === values?.tipoNegocio?.nombre) ?? ''
    return tipo?.name ?? ''; 

  }

  const columnsHistorialCredenciales = [
    
    {
      field: 'origen',
      headerName: 'Origen',
      sortable: false,
      width: 220,
    },
    {
      field: 'valor',
      headerName: 'Categoria asignada',
      sortable: false,
      width: 320,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'descripcion',
      headerName: 'Razón',
      width: 450,
      
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    
    {
      field: 'documentos',
      headerName: 'Documentos',
      width: 730,
      renderCell: (params: any) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const openC = Boolean(anchorEl);
        const idC = open ? 'simple-popover' : undefined;
        const handleClick = (event: any) => {
          console.log('event',event.currentTarget)
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
        if (params.row.documentos.length > 0) {
          return <div>
            <Button  variant="contained" color="primary" onClick={handleClick}>
              Ver links
            </Button>
            <Popover
              id={idC}
              open={openC}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ul>
                {params.row.documentos.map((documento: any, index: number) => (
                  <li key={index}>
                    <a href={documento.link} target="_blank" rel="noopener noreferrer">
                      {documento.link}
                    </a>
                  </li>
                ))}
              </ul>
            </Popover>
          </div>
        }
      },
    },
    
    
  ];

  return (
    <>
      <Dialog
        open={openDialogDeleteContracargo}
        onClose={() => setOpenDialogDeleteContracargo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Deseas continuar?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si cambias el estado de esta transacción eliminaras el contracargo y abonos del contracargo asociado a ella.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogDeleteContracargo(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteContracargo} color="primary" autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        mt={1}
        p={2}
        component={Paper}
        style={{ maxHeight: '100vh', overflow: 'auto' }}
      >
        {/* Filters and title */}
        <Grid
          container
          spacing={2}
          direction='row'
          alignItems='center'
        >
          {/* Title */}
          <Grid
            item
            sm={12}
          >
            <Typography variant='h6'>Transacciones Generales</Typography>
          </Grid>

          {/* Filters selector */}
          <Grid
            item
            container
            direction='row'
            spacing={2}
            sm={6}
          >
            {/* Day selector */}
            {filterDays.map((day, index) => {
              return (
                <Grid
                  item
                  key={index}
                >
                  <Chip
                    label={day.name}
                    color='primary'
                    variant={day.isSelected ? 'default' : 'outlined'}
                    onClick={(e: any) => {
                      const newState = filterDays.map(item => {
                        item.name === day.name ? (item.isSelected = true) : (item.isSelected = false);
                        return item;
                      });

                      setFilterDays(newState);

                      setIndex(index + 1);
                      setEndDateFilter(undefined);
                      setStartDateFilter(undefined);

                      if (startDateFilterRef.current) startDateFilterRef.current.value = undefined;
                      if (endDateFilterRef.current) endDateFilterRef.current.value = undefined;
                    }}
                  />
                </Grid>
              );
            })}

            {/* Filter selector */}
            <Box
              alignItems={'center'}
              display={'flex'}
              justifyContent={'center'}
              sx={{ minWidth: 120 }}
            >
              <FormControl>
                <Select
                  label='Filtro'
                  value={filterType}
                  onChange={(event: any) => handleChangeFilter(event)}
                >
                  <MenuItem value={'todas'}>Todas</MenuItem>
                  <MenuItem value={'agregador'}>Agregador</MenuItem>
                  <MenuItem value={'payfac'}>PayFac</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {/* Date selector */}
          <Grid
            item
            container
            direction='row'
            spacing={2}
            justifyContent={'flex-end'}
            alignItems={'center'}
            sm={6}
          >
            {/* Download excel */}

            <Grid
              item
              style={{ padding: '0px 20px' }}
            >
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  try {
                    handleLoading(true);

                    if (filterTransactions.length) {
                      const transactionsToExport = filterTransactions.map(item => {
                        let banco = item && item.negocio && item.negocio.informacionBancaria && item.negocio.informacionBancaria[0] && item.negocio.informacionBancaria[0].banco ? item.negocio.informacionBancaria[0].banco : '';
                        return {
                          Id: item.id,
                          NegocioId: item.negocio.id,
                          BIN: item.bin,
                          Negocio: item.negocio.nombre,
                          Banco: banco,
                          'Tipo de Cuenta': item.negocio?.informacionBancaria?.[0]?.tipoCuenta,
                          'Número De Cuenta': item.negocio?.informacionBancaria?.[0]?.numeroCuenta,
                          Monto: +item.monto,
                          'Monto Neto': item.montoNeto,
                          'Descuento por Contracargo': item.debitoContracargo,
                          'Comision MCC': +item.comisionMcc,
                          'Comision Visa': +item.comisionVisa,
                          "Costo 3ds Neonet": +item.comision3dsNeonet,
                          Retencion: +item.retencion,
                          'Liquido Paggo': +item.liquidoPaggo,
                          'Comision Paggo': +item.comisionPaggo,
                          'IVA Comision Paggo': +item.ivaComisionPaggo,
                          'Nit': item.nit,
                          Estado: item.status,
                          'Tipo de Pago': Utilities.dues(item.numeroDeVisaCuotas),
                          Liquidar: item.contracargo ? 'Contracargo' : item.liquidar ? 'Si' : 'No',
                          Fecha: momentZone.tz(item.fechaRealizoPago, 'America/Guatemala').format('YYYY-MM-DD HH:mm A'),
                          Medio: item.status === 'pagado' ? 'Link' : 'Tarjeta',
                          'Codigo de autorizacion': item.status === 'pagado' ? item.authorizationNumber : item.authNumber,
                          'Codigo de link': item.status === 'pagado' ? item.hash : 'N/A',
                          'Dirección Negocio': item.negocio?.numeroCasa,
                          'Email Usuario Negocio': item.negocio?.rolUsuario?.[0]?.usuario?.email,
                          'Régimen Fiscal': item.negocio?.tipoRegimen?.nombre,
                          'Tipo de Negocio': getTipoNegocio(item.negocio),
                        };
                      });

                      const worksheet = utils.json_to_sheet(transactionsToExport);

                      const workbook = utils.book_new();

                      utils.book_append_sheet(workbook, worksheet, 'Transacciones');

                      writeFile(workbook, `${filterType.toUpperCase()} - ${datesFiltered}.xlsx`);
                    } else {
                      handleLoading(false);
                      handleActiveError(true);
                      handleErrorMessage('No hay transacciones para descargar en este filtro');
                    }

                    handleLoading(false);
                  } catch (error) {
                    console.log(error);
                    handleLoading(false);
                    handleActiveError(true);
                    handleErrorMessage('Error al descargar el archivo');
                  }
                }}
              >
                Descargar
              </Button>
            </Grid>

            {/* Start date */}
            <Grid
              item
              style={{ padding: 0 }}
            >
              <TextField
                size='small'
                type={'date'}
                label='Fecha inicio'
                variant='outlined'
                inputRef={startDateFilterRef}
                value={startDateFilter}
                InputLabelProps={{ shrink: true }}
                onChange={e => {
                  setStartDateFilter(e.target.value);
                }}
              />
            </Grid>
            {/* End date */}
            <Grid
              item
              style={{ padding: '0px 20px 0px 20px' }}
            >
              <TextField
                size='small'
                type={'date'}
                label='Fecha fin'
                variant='outlined'
                inputRef={endDateFilterRef}
                value={endDateFilter}
                InputLabelProps={{ shrink: true }}
                onChange={e => {
                  setEndDateFilter(e.target.value);
                }}
              />
            </Grid>
            {/* Filter button */}
            <Grid
              item
              style={{ padding: '0px' }}
            >
              <LoadingButton
                loading={loading}
                color='primary'
                variant='contained'
                title='Filtrar'
                onClick={() => {
                  if (startDateFilter && endDateFilter) {
                    const newState = filterDays.map(item => {
                      item.isSelected = false;
                      return item;
                    });

                    setFilterDays(newState);
                    getData();
                  } else {
                    handleActiveError(true);
                    handleErrorMessage('Selecciona fecha de inicio y fin');
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* Filtered dates */}
          <Grid
            item
            sm={12}
          >
            <Typography variant='subtitle1'>{datesFiltered}</Typography>
          </Grid>
        </Grid>


        <Grid
          item
          xs={12}
          style={{ paddingBottom: '3px', paddingTop: '3px' }}
        >
          <>
            <Grid
              container
              direction='column'
            ></Grid>
            <Grid
              container
              spacing={3}
              key='t-0'
            >
              <Grid
                item
                xs={12}
                sm={10}
              >
                <TextField
                  label='Buscar por:'
                  type='search'
                  placeholder='Id, negocio, banco, tipo de cuenta, no. cuenta, codigo autorizacion, codigo link'
                  variant='filled'
                  fullWidth
                  name='search'
                  value={searchValue}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDownChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{ paddingTop: '18px' }}
              >
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={filtrar}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </>
        </Grid>


        {/* Data grid container */}
        <Grid
          item
          className={classes.hideScrollbars}
          >
          <DataGrid
            rowHeight={50}
            columns={columns}
            rows={filterTransactions}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Grid>


        <Modal
          className={classes.modal}
          open={openModalRazon}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalRazon}>
            <div className={classes.paper}>
              <h2 id='transition-modal-title'>ASIGNA UNA CATEGORIA PARA: <strong>{valorLiquidar === 'No' ? 'RETENER' : valorLiquidar === 'Contracargo' ? 'CONTRACARGAR' : valorLiquidar.toUpperCase()}</strong></h2>
              <p id='transition-modal-description'></p>
              <Grid
                item
                style={{ flexGrow: 1, display: 'flex' }}
              >
                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    value={categoria}
                    onChange={(e: any) => { setCategoria(e.target.value) }}
                    variant='outlined'
                    label="Categoria"
                  >
                    {categoriasContracargos.map((categoria) => 
                      <MenuItem value={categoria.value}>{categoria.label}</MenuItem>  
                    )}
                  </Select>
                </FormControl>
                
                
              </Grid>

              {categoria !== '' &&
              
              <Grid
                container
                direction='column'
                spacing={2}
              >
                <Grid item style={{padding: '10px'}}>
                  <p>INGRESA LA RAZON POR LA QUE ASIGNARAS LA CATEGORIA: <strong>{categoria}</strong> para <strong>{valorLiquidar === 'No' ? 'RETENER' : valorLiquidar === 'Contracargo' ? 'CONTRACARGAR' : valorLiquidar.toUpperCase()}</strong> (<span style={{color:'red'}}>*</span>) </p>
                  <TextareaAutosize onChange={(e) => { setRazonCategoria(e.target.value) }} autoFocus style={{width:'100%',padding: '10px'}} minRows={8} />
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={uploading}
                    color='primary'
                    variant='contained'
                    title='Agregar documentos de respaldo'
                    onClick={() => {
                      handleOpenUpload();
                    }}
                  />
                  <DropzoneDialog
                    dropzoneText='Arrastra los documentos o presiona aquí'
                    previewText='Vista prévia'
                    dialogTitle={'Elige los archivos'}
                    submitButtonText='Subir archivos'
                    cancelButtonText='Cancelar'
                    open={openUploadFile}
                    onSave={handleDocumentosRazones}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                    showPreviews={true}
                    maxFileSize={25000000}
                    filesLimit={10}
                    onClose={handleCloseUpload}
                  />
                </Grid>
                <Grid
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  style={{padding:'8px'}}
                >
                  <Grid item>
                    <Button disabled={razonCategoria.trim().length > 0 && !uploading ? false : true} onClick={() => handleChange() } style={{ textAlign:'center' }} color='default' variant='contained'>
                      Cambiar 
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              }
            </div>
          </Fade>
        </Modal>
        <Modal
          className={classes.modal}
          open={openHistorialRazones}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openHistorialRazones}>
            <div className={classes.paper}>
              <p id='transition-modal-description'>Registro histórico</p>
              
              <Grid
                container
                direction='column'
                spacing={2}
              >
                <Grid
                  item
                  style={{ height: 700, width: 'auto' }}
                >
                  <DataGrid
                    isRowSelectable={(params: GridRowParams) => false}
                    loading={loading}
                    rows={rowsHistorialCredenciales}
                    columns={columnsHistorialCredenciales}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>



        <PageLoader loading={loading} />
      </Box>
    </>
  );
}
