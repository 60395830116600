import http from "../libs/http";
import { Rutas } from "../resources/Statics";

export default class MarketingService {
  static instance = new MarketingService();

  obtenerCampanas = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlMarketing + "/";
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, false);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: "", result: req.result };
    } catch (error: any) {
      console.log("getAll Marketing service error", error);
      return { error: true, message: error.message, result: null };
    }
  };

  obtenerCampana = async (id: any) => {
    try {
      const ruta =
        Rutas.instance.urlBase + Rutas.instance.urlMarketing + "/" + id;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, false);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: "", result: req.result };
    } catch (error: any) {
      console.log("getAll Marketing service error", error);
      return { error: true, message: error.message, result: null };
    }
  };

  crearCampaña = async (params: any, UsuarioId: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlMarketing;
      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      console.log(req);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log("Create Negocio service error", error);
      return { error: true, message: error.message, result: null };
    }
  };

  actualizarCampana = async (params: any, UsuarioId: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlMarketing;
      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      console.log(req);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log("Create Negocio service error", error);
      return { error: true, message: error.message, result: null };
    }
  };

}
