import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class NegociosServices {
  static instance = new NegociosServices();

  get = async (params: any = "", signal?: AbortSignal) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/paginacion' + params;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getNegociosPayfacCommerces = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/getNegociosPayfacCommerces';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getById = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + `/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getDetalleNegocio = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + `/detalles/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Detalle Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getTransacciones = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/transacciones';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Transacciones service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getTransaccionesPorTipo = async (id: number, tipo: string, params: string) => {
    try {
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlNegocio}/${id}/transacciones/${tipo}${params}`;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Transacciones Por tipo service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getNewDocuments = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/documentosNegocio';
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('getDocuments Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getNewDocumentsNegocios = async (params: any = '') => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/documentosNegocios/paginados' + params;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('getDocuments Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  
  getNewDocumentsByStatusNegocios = async (params: any = '') => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/documentosNegociosByStatus/paginados' + params;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages, totalDocuments: req.totalDocuments, totalDocumentoRevisados: req.totalDocumentosRevisados , tiempoRevision: req.tiempoRevision};
    } catch (error: any) {
      console.log('getDocuments Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };


  getDocuments = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/documentos';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('getDocuments Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getDocumentsHistory = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/documentoshistory';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('getDocumentsHistory Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getInformacionBancaria = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/informacionbancaria';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('getInformacionBancaria Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getTipoDocumentos = async (id: number) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/tipodocumentosPayfac';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', docTipoNegocio: req.docTipoNegocio, rolUsuario: req.rolUsuario };
    } catch (error: any) {
      console.log('gettipodocumentos Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateDocumentStatus = async (id: number, params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id + '/updatestatus';

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  update = async (id: number, params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + id;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('update Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateTipoNegocio = async (id: number, params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/updateTipoDeNegocio/' + id;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('update Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateDescuentoPago = async (id: number, params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/updateDescuentoPago/' + id;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('update Descuento Pago service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateCredencialesNegocio = async (id: number, params: any, UsuarioId: any) => {
    try {
      const ruta =
        Rutas.instance.urlBase +
        Rutas.instance.urlNegocio +
        '/updateCredencialesNegocio/' +
        id +
        `?UsuarioId=${UsuarioId}`;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('update Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateInformacionBancaria = async (params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlInformacionBancaria + '/' + params.id;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.informacionBancaria };
    } catch (error: any) {
      console.log('update Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  create = async (params: any, UsuarioId: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + `/?UsuarioId=${UsuarioId}`;

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      console.log(req);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getUsuariosAdministradoresAfiliadores = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/getUsuariosAdministradoresAfiliadores';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateAfiliadorNegocio = async (NegocioId: any, UsuarioAfiliadorId: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/updateAfiliadorNegocio';

      let data = {
        NegocioId,
        UsuarioAfiliadorId,
      };

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, data, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('update Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getListReasons = async () => {
    try {
      const url = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/motivos';

      const httpInstance = new http();

      let req = await httpInstance.post(url, {}, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getEpayRetailReport = async (reportType: string, NegocioId: number | undefined, batch?: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlNegocio}/report/${NegocioId}/?report=${reportType}&batch=${batch}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getClosedTransactions = async (MposId: number | undefined) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlNegocio}/closedTransactions/${MposId}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('get closedTransactions service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  activarDesactivarCredenciales = async (params: any, negocioId: any) => {

    try {

        console.log(params)
        const ruta = Rutas.instance.urlBase + Rutas.instance.urlNegocio + '/' + negocioId + '/credenciales/activar'

        const httpInstance = new http();

        let req = await httpInstance.post(ruta, params, true)

        if (req.error) throw new Error(req.message)

        return { error: req.error, message: req.message, result: req.result }
    } catch (error: any) {
        console.log('ActivarDesactivar Credenciales Negocio service error', error)
        return { error: true, message: error.message, result: null }
    }

}

  getNegocioStatus = async (NegocioId: any, UsuarioId: any) => {
    try {
      const ruta = `${Rutas.instance.urlBase + Rutas.instance.urlNegocio}/negocioStatus?NegocioId=${NegocioId}&UsuarioId=${UsuarioId}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (!req) throw new Error("API no devuelve informacion");
      return { error: false, message: '', result: req };
    } catch (error: any) {
      console.log('get getNegocioStatus service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getHistorialBusqueda = async (id: string) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlHistorialBusqueda + '/' + id;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get search Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  addHistorialBusqueda = async (id: string, busqueda: string) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlHistorialBusqueda;
      
      const httpInstance = new http();

      let req = await httpInstance.post(ruta, { UsuarioId: id, busqueda } ,true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result };

    } catch (error: any) {
      console.log('add search Negocio service error', error);
      return { error: true, message: error.message, result: null };

    }
  };


}