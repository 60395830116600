import { DealersInterface, MposInventoryEditMasivoInterface, MposInventoryInterface } from '../interfaces/Interfaces';
import {
  ContactsInterface,
  NegocioInterface,
  NegocioWithTipoNegocioInterface,
  UsuarioInterface,
} from '../interfaces/Interfaces';

export default class ValidationFields {
  static instance = new ValidationFields();

  stepp1 = (telefono: any, email: any, password: any) => {
    if (!this.isNumber(telefono)) {
      return { error: true, message: 'El campo teléfono debe ser númerico' };
    } else if (!(telefono.length === 8)) {
      return { error: true, message: 'El campo teléfono debe ser igual a 8 cifras' };
    } else if (!email.includes('@')) {
      return { error: true, message: 'Debes ingresar un correo valido' };
    } else if (!(password.length >= 8)) {
      return { error: true, message: 'La contraseña debe tener min 8 caracteres' };
    } else if (!this.existLowerLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra minuscula' };
    } else if (!this.existUpperLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra mayuscula' };
    } else if (!this.existNumbers(password)) {
      return { error: true, message: 'La contraseña debe tener al menos un número' };
    }
    return { error: false, message: '' };
  };

  stepp1ByDealer = (telefono: any, email: any, password: any) => {
    if (!this.isNumber(telefono)) {
      return { error: true, message: 'El campo teléfono debe ser númerico' };
    } else if (!(telefono.length === 8)) {
      return { error: true, message: 'El campo teléfono debe ser igual a 8 cifras' };
    } else if (email && !email.includes('@')) {
      return { error: true, message: 'Debes ingresar un correo valido' };
    } else if (!(password.length >= 8)) {
      return { error: true, message: 'La contraseña debe tener min 8 caracteres' };
    } else if (!this.existLowerLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra minuscula' };
    } else if (!this.existUpperLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra mayuscula' };
    } else if (!this.existNumbers(password)) {
      return { error: true, message: 'La contraseña debe tener al menos un número' };
    }
    return { error: false, message: '' };
  };

  stepp2 = (codigoActivacion: any) => {
    if (codigoActivacion.length !== 6) {
      return { error: true, message: 'Ingrese un codigo de activacion valido' };
    }
    return { error: false, message: '' };
  };

  stepp3 = (nombre: any, apellidoPaterno: any, apellidoMaterno: any, cui: any) => {
    if (nombre.length === 0 || apellidoPaterno.length === 0 || apellidoMaterno.length === 0 || cui.length === 0) {
      return { error: true, message: 'Debes llenar todos los campos' };
    }
    return { error: false, message: '' };
  };

  stepp4 = (nombreNegocio: any, numeroCasa: any, departamento: any, municipio: any, telefonoNegocio: any, nit: any) => {
    if (
      nombreNegocio.length === 0 ||
      numeroCasa.length === 0 ||
      departamento.length === 0 ||
      municipio.length === 0 ||
      telefonoNegocio.length === 0 ||
      nit.length === 0
    ) {
      return { error: true, message: 'Debes llenar todos los campos' };
    } else if (!this.isNumber(telefonoNegocio)) {
      return { error: true, message: 'El campo teléfono debe ser númerico' };
    } else if (!(telefonoNegocio.length === 8)) {
      return { error: true, message: 'El campo teléfono debe ser igual a 8 cifras' };
    }
    return { error: false, message: '' };
  };

  stepp5 = (actividad: any, subActividad: any) => {
    if (actividad.length === 0 || subActividad.length === 0) {
      return { error: true, message: 'Debes llenar todos los campos' };
    }
    return { error: false, message: '' };
  };

  stepp6 = (banco: any, numeroCuenta: any, tipoCuenta: any, nombreCuenta: any) => {
    if (banco.length === 0 || numeroCuenta.length === 0 || tipoCuenta.length === 0 || nombreCuenta.length === 0) {
      return { error: true, message: 'Debes llenar todos los campos' };
    }
    return { error: false, message: '' };
  };

  password = (password: any) => {
    if (!(password.length >= 8)) {
      return { error: true, message: 'La contraseña debe tener min 8 caracteres' };
    } else if (!this.existLowerLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra minuscula' };
    } else if (!this.existUpperLetter(password)) {
      return { error: true, message: 'La contraseña debe tener al menos una letra mayuscula' };
    } else if (!this.existNumbers(password)) {
      return { error: true, message: 'La contraseña debe tener al menos un número' };
    }
    return { error: false, message: '' };
  };

  equalsPasswords = (password1: any, password2: any) => {
    if (password1 !== password2) {
      return { error: true, message: 'Las constraseñas no coinciden' };
    }
    return { error: false, message: '' };
  };

  existLowerLetter = (word: any) => {
    for (let index = 0; index < word.length; index++) {
      if (word.charCodeAt(index) >= 97 && word.charCodeAt(index) <= 122) return true;
    }
    return false;
  };

  existUpperLetter = (word: any) => {
    for (let index = 0; index < word.length; index++) {
      if (word.charCodeAt(index) >= 65 && word.charCodeAt(index) <= 90) return true;
    }
    return false;
  };

  existNumbers = (word: any) => {
    for (let index = 0; index < word.length; index++) {
      if (word.charCodeAt(index) >= 48 && word.charCodeAt(index) <= 57) return true;
    }
    return false;
  };

  isNumber = (word: any) => {
    for (let index = 0; index < word.length; index++) {
      if (!(word.charCodeAt(index) >= 48 && word.charCodeAt(index) <= 57)) return false;
    }
    return true;
  };

  creditCardType = (cc: string) => {
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

    let visa19digitos = new RegExp('^4[0-9]{15}[0-9]*$');
    let mastercard19digitos = new RegExp('^5[1-5][0-9]{15}[0-9]*$');

    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    let jcb = new RegExp('^35[0-9]{14}[0-9]*$');

    if (visa.test(cc)) {
      return 'VISA';
    }
    if (amex.test(cc)) {
      return 'AMEX';
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      return 'MASTERCARD';
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return 'DISCOVER';
    }
    if (diners.test(cc)) {
      return 'DINERS';
    }
    if (jcb.test(cc)) {
      return 'JCB';
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return 'CHINA_UNION_PAY';
    }

    if (visa19digitos.test(cc)) {
      return 'VISA';
    }

    if (mastercard19digitos.test(cc)) {
      return 'MASTERCARD';
    }

    return undefined;
  };

  newUser = (user: UsuarioInterface) => {
    if (!this.isNumber(user.telefono)) {
      return { error: true, message: 'El campo teléfono debe ser númerico' };
    } else if (!(user.telefono.length === 8)) {
      return { error: true, message: 'El campo teléfono debe ser igual a 8 cifras' };
    } else if (!user.email.includes('@')) {
      return { error: true, message: 'Debes ingresar un correo valido' };
    } else if (!(user.password.length >= 8)) {
      return { error: true, message: 'La contraseña debe tener min 8 caracteres' };
    }
    return { error: false, message: '' };
  };

  editUser = (user: UsuarioInterface) => {
    if (!this.isNumber(user.telefono)) {
      return { error: true, message: 'El campo teléfono debe ser númerico' };
    } else if (!(user.telefono.length === 8)) {
      return { error: true, message: 'El campo teléfono debe ser igual a 8 cifras' };
    } else if (!user.email.includes('@')) {
      return { error: true, message: 'Debes ingresar un correo valido' };
    }
    return { error: false, message: '' };
  };

  newNegocio = (negocio: NegocioWithTipoNegocioInterface | NegocioInterface) => {
    //TODO: validar negocio
    return { error: false, message: '' };
  };

  newInventory = (inventory: MposInventoryInterface) => {
    if (!(inventory.numeroDeSerie.length > 0)) {
      return { error: true, message: 'El numero de serie es obligatorio' };
    } else if (!(inventory.modelo.length > 0)) {
      return { error: true, message: 'El modelo es obligatorio' };
    } else if (!(inventory.terminalEpayRetail.length > 0)) {
      return { error: true, message: 'El terminal es obligatorio' };
    } else if (!(inventory.LoteId)) {
      return { error: true, message: 'El lote es obligatorio' };
    } else if (!(inventory.estado.length > 0)) {
      return { error: true, message: 'El estado es obligatorio' };
    } 
    return { error: false, message: '' };
  }

  editInventoryMasivo = (inventory: MposInventoryEditMasivoInterface) => {
    if (!(inventory.estado.length > 0)) {
      return { error: true, message: 'El estado es obligatorio' };
    } 
    return { error: false, message: '' };
  }

  newContact = (contact: ContactsInterface) => {
    if (!contact.nombre) {
      return { error: true, validation: 'El nombre es requerido' };
    } else if (!contact.apellido) {
      return { error: true, validation: 'El apellido es requerido' };
    }
    return { error: false, validation: '' };
  };

  newDealer = (dealer: DealersInterface) => {
    if (!dealer.nombre) {
      return { error: true, validation: 'El nombre es requerido' };
    }  else if (!dealer.idTipoDealer) {
      return { error: true, validation: 'El tipo Dealer es requerido' };
    }
    return { error: false, validation: '' };
  }
}