import EventEmitter from 'reactjs-eventemitter';

let Connected = false;
let Connected_Device;
let MPOS_DATA_Ready = false;
let Connected_Server;
let MPOS_SERVICE_FLAG = false;
let MPOS_Notify_State;

let MPOS_VALUE = '49535343-8841-43f4-a8d4-ecbe34729bb3';
let MPOS_SERVICE = '49535343-fe7d-4ae5-8fa9-9fafd205e455';
let notify_uuid = '49535343-1e4d-4bd9-ba61-23c647249616';

let mposID;

let mService = new QPOSService();
function QPOSServiceListenerImpl() {}
let qPOSServiceListenerImpl = new QPOSServiceListenerImpl();
mService.initListener(qPOSServiceListenerImpl);
mOnResult = new QPOSAnalyResult(qPOSServiceListenerImpl);

const DiscoveOrDisConnect = () => {
  if (Connected) {
    Connected_Device.gatt.disconnect();
    UpdateUI();
  } else {
    DiscoverDevice();
    UpdateUI();
  }

  return mposID;
};

const ConnectDevice = () => {
  Connected_Device.gatt.connect().then(
    function (server) {
      Connected = true;
      UpdateUI();
      Connected_Server = server;

      Connected_Device.addEventListener('gattserverdisconnected', function () {
        Connected = false;
        UpdateUI();
      });
      DiscoverService();
    },
    function (error) {
      Connected = false;
      UpdateUI();
    },
  );
};

function DiscoverService() {
  let ServicesDiscovered = 0;

  Connected_Server.getPrimaryServices().then(Services => {
    let ServiceSum = Services.length;

    Services.forEach(service => {
      if (service.uuid == MPOS_SERVICE) {
        MPOS_SERVICE_FLAG = true;
      }

      service.getCharacteristics().then(Characteristics => {
        ServicesDiscovered++;
        let CharacteristicsDiscovered = 0;
        let CharacteristicsSum = Characteristics.length;

        Characteristics.forEach(Characteristic => {
          CharacteristicsDiscovered++;
          if (Characteristic.uuid == MPOS_VALUE) {
            writeChar = Characteristic;
            MPOS_DATA_Ready = true;
            mService.setCommunicationMode(CommunicationMode.BLUETOOTH);
            new webBluetooth(mOnResult, writeChar);
          } else if (Characteristic.uuid == notify_uuid) {
            MPOS_Notify_State = Characteristic;
            registNotify(MPOS_Notify_State);
          }
          if (ServicesDiscovered == ServiceSum && CharacteristicsDiscovered == CharacteristicsSum) {
            UpdateUI();
            ReadModelStr();
          }
        });
      });
    });
  });
}

const UpdateUI = () => {
  if (Connected) {
  } else {
    MPOS_DATA_Ready = false;
  }

  if (MPOS_DATA_Ready) {
    getQPosId();
  } else {
  }
};

const ReadModelStr = () => {
  writeChar
    .readValue()
    .then(value => {
      data = new Uint8Array(value.buffer);
    })
    .catch(error => {
      return;
    });
};

const DiscoverDevice = () => {
  let options = {
    filters: [{ namePrefix: 'MPOS' }, { namePrefix: 'QPOS' }, { namePrefix: 'VEL' }, { namePrefix: 'watu' }],
    optionalServices: [MPOS_SERVICE],
  };

  navigator.bluetooth
    .requestDevice(options)
    .then(device => {
      Connected_Device = device;
      ConnectDevice();
    })
    .catch(error => {});
};

const getQPosId = () => {
  mService.getQPosId();
};

QPOSServiceListenerImpl.prototype.onQposIdResult = deviceId => {
  EventEmitter.dispatch('buttonClick', deviceId.posId);
  Connected_Device.gatt.disconnect();
};

export { DiscoveOrDisConnect };
