/**
 * External Libraries
 */
import { useState, useContext, useEffect } from 'react';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
import FacturasReport from '../../components/FacturasReport'
/**
 * Services
 */
import NegociosServices from '../../services/NegociosServices';
import FacturasNegocioService from '../../services/FacturasNegocioService';
/**
 * Interfaces
 */
import { NegocioWithTipoNegocioInterface } from '../../interfaces/Interfaces'

interface screenProps {

}

const FacturasNegocios = (props: screenProps) => {
    const negocioService = new NegociosServices()
    const facturasNegocioService = new FacturasNegocioService()

    const { handleErrorMessage, handleActiveError } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    const [negociosPayfac, setNegociosPayfac] = useState<NegocioWithTipoNegocioInterface[]>([])

    useEffect(() => {
        getNegociosPayfacCommerces()
    }, [])

    const getNegociosPayfacCommerces = async () => {
        let res = await negocioService.getNegociosPayfacCommerces()
        setNegociosPayfac(res.result)
    }

    const getReporteFacturas = (fechaInicio: string, fechaFin: string, negocioId: number) => {

        let params = {
            fechaInicio,
            fechaFin,
            negocioId
        };

        if (fechaInicio.length > 0 && fechaFin.length > 0 && !loading) {
            setLoading(true);

            facturasNegocioService
                .getExcelReporteFacturasByNegocio(params)
                .then((response: any) => {
                    setLoading(false);
                    try {
                        if (response.file?.blob instanceof Blob) {
                            const blob = new Blob([response.file?.blob as BlobPart], {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `reporte.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        } else {
                            handleErrorMessage(response.message ? response.message : 'Error al descargar el reporte');
                            handleActiveError(true);
                        }
                    } catch (error: any) {
                        handleErrorMessage(error.message);
                        handleActiveError(true);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    handleErrorMessage(e.message);
                    handleActiveError(true);
                });

        } else {
            handleErrorMessage('Debes completar fecha fin e inicio para exportar el reporte.');
            handleActiveError(true);
        }
    };


    return (
        <FacturasReport getReport={getReporteFacturas} negociosPayfac={negociosPayfac} />
    )
}

FacturasNegocios.propTypes = {}

export default FacturasNegocios