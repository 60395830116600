export class Images {
    public static instance = new Images()
    public logo = '/assets/images/logo2.png'
    public image1 = '/assets/images/imagen1.jpg'
    public pdfIcon = '/assets/images/pdfIcon.png'
    public iconoVisa = '/assets/images/iconoVisa.png'
    public iconoMasterCard = '/assets/images/logo-Mastercard.png'
    public candadoEnlace = '/assets/images/candado-enlace.png'
    public logoInvitacion = '/assets/images/logoInvitacion.png'
    public logoGooglePlay = '/assets/images/logoGooglePlay.png'
    public logoAppStore = '/assets/images/logoAppStore.png'
    public linkYaFuePagado = '/assets/images/link-ya-fue-pagado.png'
    public linkCaducado = '/assets/images/link-caducado.png'
    public linkAnulado = '/assets/images/link-anulado.png'
    public linkCancelado = '/assets/images/linkCancelado.png'
    public logoGooglePlay2024 = '/assets/images/descarga-googleplay.png'
    public logoAppStore2024 = '/assets/images/descarga-apple.png'
    public iconoPCI = '/assets/images/iconoPCI.png'
}