/**
 * External Libraries
 */
import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useState, useContext } from 'react';
import * as momentZone from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/*
* Components
*/
import LoadingButton from './../components/LoadingButton';

/*
* Interface
*/

interface componentProps {
    getReport: (fechaInicio: string, fechaFin: string) => void
    titleReport?: string
}


const TransactionsReport = (props: componentProps) => {

    let now = momentZone.tz('America/Guatemala');

    const [loading, setLoading] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(now.startOf('month').format('YYYY-MM-DD') as string);
    const [fechaFin, setFechaFin] = useState(now.endOf('month').format('YYYY-MM-DD') as string);

    return (
        <Box
            mt={1}
            p={2}
            component={Paper}
        >
            <Grid
                container
                direction='column'
                spacing={2}
            >
                <Grid item>
                    <Typography
                        variant='h6'
                        gutterBottom
                    >
                        {props.titleReport ? props.titleReport : 'Reporte de transacciones por fecha'}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        gutterBottom
                    >
                        Exportar reporte de transacciones
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        type={'date'}
                        label='Fecha inicio'
                        variant='outlined'
                        fullWidth
                        value={fechaInicio}
                        onChange={field => setFechaInicio(field.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        type={'date'}
                        label='Fecha fin'
                        variant='outlined'
                        fullWidth
                        value={fechaFin}
                        onChange={field => setFechaFin(field.target.value)}
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        loading={loading}
                        color='primary'
                        variant='contained'
                        title='Descargar reporte'
                        onClick={() => props.getReport(fechaInicio, fechaFin)}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TransactionsReport