/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useState, useContext, useEffect } from 'react';
import * as momentZone from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { utils, writeFile } from 'xlsx';

/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Components
 */
import LoadingButton from '../../components/LoadingButton';
import TransactionsReport from '../../components/TransactionsReport';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
import mPosService from '../../services/MposService';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function MposReports() {
  const classes = useStyles();
  const { handleErrorMessage, handleActiveError } = useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [mposRows, setMposRows] = useState([]);
  const [mposConfiguredFilter, setMposConfiguredFilter] = useState(1);

  useEffect(() => {
    getDataMpos(mposConfiguredFilter);
  }, []);

  const columns = [
    { field: 'numeroDeSerie', headerName: 'Serie', flex: 1, minWidth: 100 },
    {
      field: 'fechaEnlace',
      headerName: 'Tiempo que se configuro',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    { field: 'idNegocio', headerName: 'Id negocio', flex: 1, minWidth: 150 },
    { field: 'nombreNegocio', headerName: 'Nombre del negocio', flex: 1, minWidth: 140 },
    { field: 'nit', headerName: 'NIT', flex: 1, minWidth: 150 },
    { field: 'telefono', headerName: 'Telefono del negocio', flex: 1, minWidth: 120 },
    {
      field: 'correo',
      headerName: 'Correo del negocio',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {},
    },
  ];

  const getDataMpos = async (configuredMpos: number) => {
    try {
      setLoading(true);
      const response = await mPosService.instance.getMposList(configuredMpos);
      if (response.result) {
        const rows = response.result.map((row: any) => {
          return {
            id: row.id,
            fechaEnlace: row.fechaEnlace,
            numeroDeSerie: row.numeroDeSerie,
            idNegocio: row.NegocioId,
            nombreNegocio: row.negocio && row.negocio.nombre ? row.negocio.nombre : "",
            nit: row.negocio && row.negocio.nit ? row.negocio.nit : "",
            telefono: row.negocio && row.negocio.telefono ? row.negocio.telefono : "",
            correo: row.negocio && row.negocio.rolUsuario[0] ? row.negocio.rolUsuario[0].usuario.email : "",
          };
        });
        setMposRows(rows);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const handleChangeFilter = (event: any) => {
    setMposConfiguredFilter(event.target.value);
    getDataMpos(event.target.value);
  };

  const downloadReport = () => {

    const worksheet = utils.json_to_sheet(mposRows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'MPOS');

    /* fix headers */
    utils.sheet_add_aoa(worksheet, [["Id", "Tiempo de configuración", "Numero de serie", "Id negocio", "Nombre negocio", "NIT", "Telfono negocio", "Correo negocio"]], { origin: "A1" });

    worksheet['!cols'] = [
      { width: 10},
      { width: 25 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
    ];

    writeFile(workbook, 'ReportesMpos.xlsx', { compression: true });
  };
  return (
    <>
      <Box
        mt={1}
        p={2}
        component={Paper}
        style={{ maxHeight: '100vh', overflow: 'auto' }}
      >
        <Grid
          container
          direction='column'
          spacing={2}
        >
          <Grid item>
            <Grid container>
              <Grid
                item
                xs={10}
              >
                <Typography
                  variant='h6'
                  gutterBottom
                >
                  Reporte de MPos configurados
                </Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel id='demo-simple-select-label'>Filtrar Por</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={mposConfiguredFilter}
                    onChange={handleChangeFilter}
                  >
                    <MenuItem value={1}>Configurados</MenuItem>
                    <MenuItem value={0}>No configurados</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  onClick={downloadReport}
                  fullWidth
                  variant='contained'
                  color='primary'
                >
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{ height: 'calc(100vh - 200px)', width: 'auto' }}
          >
            <DataGrid
              loading={loading}
              rows={mposRows}
              columns={columns}
              rowHeight={50}
              hideFooter
              rowsPerPageOptions={[10]}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
