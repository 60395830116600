import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class facturaNegocioService {
  static instance = new facturaNegocioService();

  getExcelReporteFacturasByNegocio = async (params: {fechaInicio: string, fechaFin: string, negocioId: number}) => {
    try {
      const httpInstance = new http();
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlFacturasComision}/getExcelReporteFacturasByNegocio?NegocioId=${params.negocioId}&fechaInicio=${params.fechaInicio}&fechaFin=${params.fechaFin}`;

      return {
        error: false,
        file: await httpInstance.download(ruta, params, true),
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };
}
