import { useContext, useEffect, useState } from 'react'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryArea,
  VictoryVoronoiContainer,
} from 'victory';
import moment from 'moment';
import Utilities from '../../../libs/utilities';
import { GeneralContext } from '../../../context/GeneralContext';

interface statistics {
  max: string;
  min: string;
  total: string;
  cantidad: number;
}

interface statisticsNet {
  max: string;
  min: string;
  total: string;
  cantidad: number;
}

interface XY {
  x: number;
  y: string;
}

interface XYNet {
  x: any;
  y: any;
  yNet?: any;
}

interface datesfilter {
  startFilter: string;
  endFilter: string;

}

interface Results {
  statistics: statistics;
  statisticsNet: statisticsNet;
  results: XYNet[];
  resultsNet: XYNet[];
  cashResults: XYNet[];
  cardResults: XYNet[];
  linkResults: XYNet[];
  datesfilter: datesfilter;
};

type GlobalStyles = any;
type DatesFilter = any;

interface Props {
  results?: XYNet[];
  resultsTotal: number;
  resultsNetas?: XYNet[];
  resultsNetasTotal: number;
  moneda: string;
  datesfilter?: datesfilter;
  label: string;
  filtro: string;
}

const meses: {[key: number]: string} = {
  1: 'Ene',
  2: 'Feb',
  3: 'Mar',
  4: 'Abr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dic',
}


const VentasGrafico = (props: Props) => {

  const [moneda, setMoneda] = useState<string>(props.moneda);
  const [results, setResults] = useState<XYNet[]>(props.results || []);
  const [activeArea, setActiveArea] = useState(null);
  const format = 'DD'; // Define tu formato aquí
  const parse = 'ddd'; // Define tu parse aquí

  useEffect(() => {
    if (props.results) {
      setResults(props.results)
    }
  }, [props.results]);

  useEffect(() => {
    if (props.moneda) {
      setMoneda(props.moneda)
    }
  }, [props.moneda]);

  const uniqueXTickValues = props?.results && props?.results?.length > 0 ? Array.from(new Set(props?.results?.map((point) => point.x))) : [];

  const CustomTooltip = (prop:any) => {
    const fillColor =
      activeArea === "victory-scatter-1" ? "#5166AF" : "#4acdce";
    const { activePoints, text, ...resto } = prop;
    return (
      <VictoryTooltip
        {...resto}
        activePoints={prop.activePoints[0]}
        text={text[0]}
        style={{
          fontSize: 6,
          fill: "#FFF",
          padding: 1,
        }}
        cornerRadius={5}
        flyoutStyle={{
          fill: fillColor,
          strokeWidth: 0.1,
          padding: 1,
        }}
        flyoutPadding={5}
        renderInPortal
        pointerLength={1}
      />
    );
  };
  
  return (

    <>
    <VictoryChart
      height={170}
      width={500}
      padding={{ top: 15, left: 70, bottom: 25, right: 30 }}
      containerComponent={
        <VictoryVoronoiContainer
          labels={({ datum }) =>
            Number(props.filtro) <= 1
              ? `${moneda}${Utilities.formatoMoneda(datum.y)}, ${moment(
                  datum.x,
                  format,
                ).format(parse)} ${datum.x} ${moment().format('MMM')}`
              : `${moneda}${Utilities.formatoMoneda(datum.y)}, ${moment(
                  datum.x,
                  format,
                ).format(parse)}`
          }
          labelComponent={<CustomTooltip />}
          onActivated={(points) => {
            if (points && points.length > 0) {
              setActiveArea(points[0].childName);
            } else {
              setActiveArea(null);
            }
          }}
        />
      }
      animate={{
        duration: 500,
      }}
    >
      <VictoryAxis
        dependentAxis
        orientation='left'
        offsetX={70}
        style={{
          axis: { stroke: '#c9c9c9' },
          grid: { stroke: '#c9c9c9' },
          tickLabels: {
            fontSize: 6,
            fill: '#c9c9c9',
          },
        }}
        tickFormat={t => `${moneda}${Utilities.formatoMonedaSinDecimal(t)}`}
      />
      
      <VictoryAxis
        orientation='bottom'
        style={{
          axis: { stroke: '#c9c9c9' },
          grid: { stroke: '#c9c9c9' },
          tickLabels: {
            fontSize: 6,
            fill: '#c9c9c9',
          },
        }}  
        tickValues={uniqueXTickValues}
        tickFormat={t => {
          if (t > 0){
            if (props.filtro === '2') {
              return `${meses[parseInt(t)]}`
            } 
            return `${parseInt(t)}`
          }
        } 
        
          
        }
      />
      <VictoryScatter
        name='victory-scatter-1'
        data={props.results}
        size={({ active }) => (active ? 3.3 : 2.5)}
        style={{
          data: {
            fill: '#5166AF',
          },
        }}
        
      />

      <VictoryArea
        name='victory-area-1'
        data={props?.results}
        style={{
          data: {
            fill: 'transparent',
            stroke: '#5166AF',
            strokeWidth: 1.5,
            opacity: 0.5,
          },
        }}
      />


      <VictoryScatter
        name='victory-scatter-2'
        data={props.resultsNetas}
        size={({ active }) => (active ? 3.3 : 2.5)}
        style={{
          data: {
            fill: '#4acdce',
          },
        }}
        
      />
      <VictoryArea
        name='victory-area-2'
        data={props?.resultsNetas}
        style={{
          data: {
            fill: 'transparent',
            stroke: '#4acdce',
            strokeWidth: 1.5,
            opacity: 0.5,
          },
        }}
      />
    </VictoryChart>
    </>
  );

}

export default VentasGrafico