import { isFunctionDeclaration } from "typescript"
import { Permissions } from "../resources/Statics"
import { UserInterface } from './../interfaces/Interfaces'
const btoa = require('Base64').btoa

export default class localStorage {

    static instance = new localStorage()

    setSesion = async (token: any, user: any, procesoregistro: any, negocio: any, informacionbancaria: any, rol: any) => {
        try {
            if (token !== null && token !== undefined) window.localStorage.setItem('token', token)
            if (user !== null && user !== undefined) window.localStorage.setItem('user', JSON.stringify(user))
            if (procesoregistro !== null && procesoregistro !== undefined) window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))
            if (negocio !== null && negocio !== undefined) window.localStorage.setItem('negocio', JSON.stringify(negocio))
            if (informacionbancaria !== null && informacionbancaria !== undefined) window.localStorage.setItem('informacionbancaria', JSON.stringify(informacionbancaria))
            if (rol !== null && rol !== undefined) window.localStorage.setItem('rol', rol)
        } catch (error: any) {
            console.log('localStorage', error)
        }
    }

    cerrarSesion = async () => {
        try {
            await window.localStorage.removeItem('token')
            await window.localStorage.removeItem('user')
            await window.localStorage.removeItem('procesoregistro')
            await window.localStorage.removeItem('negocio')
            await window.localStorage.removeItem('informacionbancaria')
            await window.localStorage.removeItem('rol')
        } catch (error: any) {
            console.log('localStorage', error)
        }
    }

    getUserParseado = async (): Promise<UserInterface | null> => {
        try {
            let user = window.localStorage.getItem('user')
            if (!user) throw Error('No se encontro un ususario en localstorage')
            let userParseado = JSON.parse(user)
            return userParseado
        } catch (error) {
            console.log('localStorage', error)
            return null
        }
    }

    getUserId = async () => {
        try {
            let user = window.localStorage.getItem('user')
            if (!user) throw Error('No se encontro un usuario en localstorage')
            let userParseado = JSON.parse(user)
            if (!userParseado.id) throw Error('No se encontro id en usuario')
            return userParseado.id
        } catch (error) {
            console.log('localStorage', error)
            return null
        }
    }

    getNegocioId = async () => {
        try {
            let negocio = window.localStorage.getItem('negocio')
            if (!negocio) throw Error('No se encontro un negocio en localstorage')
            let negocioParseado = JSON.parse(negocio)
            if (!negocioParseado.id) throw Error('No se encontro id en negocio')
            return negocioParseado.id
        } catch (error) {
            console.log('localStorage', error)
            return null
        }
    }

    getUserEmail = async () => {
        try {
            let user = window.localStorage.getItem('user')
            if (!user) throw Error('No se encontro un ususario en localstorage')
            let userParseado = JSON.parse(user)
            if (!userParseado.email) throw Error('No se encontro email en usuario')
            return userParseado.email
        } catch (error) {
            console.log('localStorage', error)
            return null
        }
    }

    getUserRol = async () => {
        try {
            let rol = window.localStorage.getItem('rol')
            if (!rol) throw Error('No se encontro un rol de usuario en localstorage')
            return rol
        } catch (error) {
            console.log('localStorage', error)
            return null
        }
    }

}