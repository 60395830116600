import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class DocumentosRazonesService {
  static instance = new DocumentosRazonesService();

  updateDocumento = async (id: number, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDocumentosRazones}/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al actualizar el documento de razon', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }
}





