import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class ContracargosServices {
  static instance = new ContracargosServices();

  getContracargosPaginados = async (params: { offset: number, limit: number, valor?: string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo + `/getContracargosPaginados?offset=${params.offset}&limit=${params.limit}${params.valor ? `&valor=${params.valor}` : ''}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.results, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getCantidadContracargos = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo + `/getCantidadContracargos`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.results, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  createContracargo = async (params: { 
      valor: string, 
      buscarPor: string,
      tipoPago: string, 
      fechaEmisionVisa?: string, 
      categoria: string, 
      documentos: string[],
      razon: string,
      pais: number
     }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo;

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  sendSeguimiento = async (params: { 
    contracargoId: string,
    negocioId: string,
    motivo: number,
    motivoMensaje?: string
    UsuarioId: any
   }) => {
  try {
    const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo +  `/sendSeguimiento/${params.contracargoId}` ;

    const httpInstance = new http();

    let req = await httpInstance.post(ruta, params, true);

    if (req.error) throw new Error(req.message);

    return { error: req.error, message: req.message, result: req.results };
  } catch (error: any) {
    console.log('Create Negocio service error', error);
    return { error: true, message: error.message, result: null };
  }
};
  updateContracargo = async (id: number, body: { 
    categoria: string, 
    documentos: string[],
    razon: string
   }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo + '/' + id;

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, body, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  updateContracargoEstadoFinal = async (id: number, body: { 
    estadofinal: string, 
    documentos: string[],
    razon: string
   }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo + '/' + id + '/estadofinal';

      const httpInstance = new http();

      let req = await httpInstance.put(ruta, body, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  deleteContracargo = async (params: { contracargoId: number | string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlContracargo + '/deleteContracargo/' + params.contracargoId;

      const httpInstance = new http();

      let req = await httpInstance.delete(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
}