import { AppBar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { GeneralContext } from '../../context/GeneralContext';
import { DataGrid } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import momentZone from 'moment-timezone';
import { Rutas, estadosSolicitudes } from '../../resources/Statics';
import { VisibilityTwoTone } from '@material-ui/icons';
import Movimiento from './Movimiento';


interface Ubicacion {
  id: number;
  descripcion: string;
}

interface Movimientos {
  id: number;
  ResponsableUsuarioId: number;
  SolicitudPosUbicacionId: number;
  estado: string;
  razonRechazo: string;
  rollos: number;
  sobres: number;
  stickers: number;
}

const AprobarMovimientos = () => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
  } = useContext(GeneralContext);

  const [loading, setLoading] = useState(false);
  const [openCreacionModificacion, setOpenCreacionModificacion] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<{id: number}[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [accion, setAccion] = useState<string>('');
  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string}[]>([]);

  const [openSolicitud, setOpenSolicitud] = useState(false);
  const [cantidad, setCantidad] = useState(0);
  const [modelo, setModelo] = useState('');
  const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState<Ubicacion>({id: 0, descripcion: ''});
  const [movimientoSeleccionado, setMovimientoSeleccionado] = useState<Movimientos | undefined>({id: 0, ResponsableUsuarioId: 0, SolicitudPosUbicacionId:0, estado: '', razonRechazo: '', rollos: 0, sobres: 0, stickers: 0});
  const [pedidoId, setPedidoId] = useState(0);
  const [estadoNuevo, setEstadoNuevo] = useState('');
  const [motivoRechazo, setMotivoRechazo] = useState('');

  interface Movimiento {
    id: number;
    UbicacionDestinoId: number;
    ResponsableUsuarioId: number;
    pos: number[];
    estado: string;
  }

  const [values, setValues] = useState<Movimiento>(
    { id: 0, 
      UbicacionDestinoId: 0, 
      ResponsableUsuarioId: 0, 
      pos: [],
      estado: '',

    }
  );

  const openConfirmChangeEstado = (row: any, event: any) => {
    setValues(row);
    setEstadoNuevo(event.target.value);
    setOpenCreacionModificacion(true);
  }
  

  const handleChangeEstado = async () => {
    if (!estadoNuevo){
      handleErrorMessage('Debe seleccionar un estado');
      handleActiveError(true);
      return;
    }
    if (estadoNuevo === 'X' && !motivoRechazo){
      handleErrorMessage('Debe ingresar una razón de rechazo');
      handleActiveError(true);
      return;
    }
    const valor = estadoNuevo
    let registro = {['estado']: valor, ['razonRechazo']: ''};
    registro['estado'] = valor
    if (valor === 'X'){
      registro['razonRechazo'] = motivoRechazo
    }
    try {
      const crudService = CrudService.instance(Rutas.instance.urlMovimiento);
      const response = await crudService.updateOneExtra(values.id, registro, 'estado');
      const { error, message } = response;
      if (!error) {
          handleActiveSuccess(true);
          handleSuccessMessage(message || 'Se ha actualizado el estado correctamente.');
      } else {
          handleErrorMessage(message || 'Error al actualizar el estado del movimiento.');
          handleActiveError(true);
      }
      getRows();
      setOpenCreacionModificacion(false);
      reset();
    } catch (e) {
      console.error('Error al actualizar:', e);
      setOpenCreacionModificacion(false);
    }
  };

  const procesarMovimientosRecibidos = () => {
    getRows();
  }

  useEffect(() => {
    getRows();
    getUbicaciones();
  }, [page, pageSize]);


  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(procesarMovimientosRecibidos, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  

  const columns = [
    {
      field: '',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={async () => {
                setAccion('view')
                setPedidoId(params.row.SolicitudPosUbicacionId);
                setMovimientoSeleccionado(params.row);
                setUbicacionSeleccionada(params.row?.ubicacion);
                setCantidad(params.row?.solicitudPosUbicacion?.cantidad);
                setModelo(params.row?.solicitudPosUbicacion?.modelo);
                setOpenSolicitud(true);
              }}
            >
              <VisibilityTwoTone />
            </IconButton>
          </div>
        );
      },
    },
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select disabled={(params.row.estado === 'A' || params.row.estado === 'X' ? true : false)} 
                      value={params.row.estado} 
                      onChange={(event) => openConfirmChangeEstado(params.row, event) }>
                <MenuItem value={'P'}>
                  <Chip label={estadosSolicitudes.P.value} style={{ background: estadosSolicitudes.P.background, color: estadosSolicitudes.P.color }}/>
                </MenuItem>
                <MenuItem value={'A'}>
                <Chip label={estadosSolicitudes.A.value} style={{ background: estadosSolicitudes.A.background, color: estadosSolicitudes.A.color }}/>
                </MenuItem>
                <MenuItem value={'X'}>
                <Chip label={estadosSolicitudes.X.value} style={{ background: estadosSolicitudes.X.background, color: estadosSolicitudes.X.color }}/>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
        field: 'ubicacion',
        headerName: 'Mover a ubicación:',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return <span>{params.row?.ubicacion?.descripcion}</span>;
        }
    },
    {
      field: 'responsable',
      headerName: 'Usuario solicitante',
      sortable: false,
      width: 270,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {

        const user = params.row?.usuario
        const nombre = `${user.nombre} ${user.apellidoPaterno || ''} ${user.apellidoMaterno || '' }  ${(user.email || user.telefono)}`


        return <span>{nombre}</span>;
      }
    },
    {
      field: 'cantidadPos',
      headerName: 'Cantidad de POS',
      sortable: false,
      width: 220,
      disableClickEventBubbling: true,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

  ];


  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const getRows = () => {
    setLoading(true);
    const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento +'/recibidos');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRows(result);
          setTotalPages(totalPages);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getUbicaciones = () => {
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      getRows();
    }
  }

  const reset = () => {
    setUbicacionSeleccionada({id: 0, descripcion: ''});
    setMovimientoSeleccionado({id: 0, ResponsableUsuarioId: 0, SolicitudPosUbicacionId:0, estado: '', razonRechazo: '', rollos: 0, sobres: 0, stickers: 0});
    setPedidoId(0);
    setCantidad(0);
    setModelo('');
    setEstadoNuevo('');
    setMotivoRechazo('');
  }
    
  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>
            Aprobar Movimientos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box
            justifyContent={['flex-start', 'flex-start', 'flex-end']}
            sx={{ flexDirection: 'row', display: 'flex' }}
          >
            <Grid item>
              <TextField
                label='Buscar'
                placeholder='Buscar'
                variant='outlined'
                size='small'
                style={{ margin: 5}}
                name='search'
                value={searchValue}
                onKeyDown={handleKeyDownChange}
                onChange={handleSearchChange}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
        >
          
            
              <Grid container >
                <Grid
                  item
                  xs={12}
                >
                  <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    rowHeight={50}
                    paginationMode='server'
                    pageSize={pageSize}
                    rowCount={totalPages * pageSize}
                    hideFooterPagination
                    hideFooter
                    //onCellEditCommit={handleCellEditCommit}
                    autoHeight
                    rowsPerPageOptions={[10, 25, 50, 100]} />
                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        shape='rounded'
                        size='medium'
                        variant='outlined'
                        color='primary'
                        style={{ padding: '8px', textAlign: 'center' }}
                        onChange={(event, value) => setPage(value)}
                      />
                    </div>
                  
                </Grid>
              </Grid> 
            
            
        </Grid>
      </Grid>

      <Movimiento
        openSolicitud={openSolicitud}
        ubicacion={ubicacionSeleccionada}
        setOpenSolicitud={()=> setOpenSolicitud(false)}
        SolicitudPosUbicacionId={Number(pedidoId)}
        movimiento={movimientoSeleccionado}
        reset={reset}
        accion={accion}
        setAccion={setAccion}
        cantidad={cantidad}
        modelo={modelo}
      />

      <Dialog
        open={openCreacionModificacion}
        onClose={() => {
          setEstadoNuevo('');
          setMotivoRechazo('');
          setOpenCreacionModificacion(false)
        }}
        
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea cambiar el estado?
          </DialogContentText>
          <FormControl>
            <Typography variant='h6'>
              {values.estado && estadoNuevo &&
              <>
                Cambiarás de: <strong>{estadosSolicitudes[values.estado].value}</strong> a <strong>{estadosSolicitudes[estadoNuevo].value}</strong>
              </> }
            </Typography>
            {estadoNuevo === 'X' &&
              <TextField
                autoFocus
                margin='dense'
                id='razonRechazo'
                label='Motivo de rechazo'
                type='text'
                fullWidth
                onChange={(event: ChangeEvent<HTMLInputElement>) => setMotivoRechazo(event.target.value)}
              />
            } 
            
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: '2em' }}>
          <Button variant='contained' onClick={handleChangeEstado} color='secondary'>
            Si, continuar
          </Button>
          <Button variant='contained' onClick={() => setOpenCreacionModificacion(false)} color='primary'>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>


    </Box>  
  )
}

export default AprobarMovimientos
