import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

interface modalConfirmProps {
    handleClose: (open: boolean) => void
    handleConfirm: () => void
    open: boolean;
    buttonText: string;
    title: string;
    contentText: string;
    cancelText: string;
    confirmText: string;
  }

function ModalConfirm(props:modalConfirmProps) {
//   const [open, setOpen] = useState(false);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span dangerouslySetInnerHTML={{ __html: props.title }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='h6' dangerouslySetInnerHTML={{ __html: props.contentText }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose(false)} color="primary">
            {props.cancelText}
          </Button>
          <Button onClick={() => props.handleConfirm()} color="primary" autoFocus>
            {props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalConfirm;