import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Images } from '../styles/images'

const useStyles = makeStyles(() => ({
  fontFooter: {
      fontSize: '15px !important'
  },
  fontFooterBold: {
      fontSize: '16px !important',
      fontWeight: 'bold'
  },
}));

const FooterLogin = (props:{width: number}) => {

  const classes = useStyles();

  return (
    <Grid container style={{textAlign:'center'}}>
        <Grid item xs={12} >
            <Typography style={{paddingBottom:'6px'}} className={classes.fontFooterBold}>¿No tienes cuenta?</Typography>
            <Typography className={classes.fontFooter}>Descarga nuestra app y crea tu cuenta.</Typography>
            <Typography className={classes.fontFooter}>#CreceConPaggo</Typography>
        </Grid>
        <Grid item xs={12} style={{padding:'14px'}}>
            <a href='https://play.google.com/store/apps/details?id=com.paggoapp&hl=es&gl=US&pli=1' target='_blank'>
                <img width={ props.width > 800 ? (props.width * 0.11) : (props.width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoGooglePlay2024} alt="" />
            </a>
            <a href='https://apps.apple.com/gt/app/paggo/id1611051340' target='_blank'>
                <img width={ props.width > 800 ? (props.width * 0.11) : (props.width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoAppStore2024} alt="" />
            </a>
        </Grid>
    </Grid>
  )
}

export default FooterLogin