import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class GraficasServices {
    static instance = new GraficasServices();

    getGraficas = async (filter: string, pais?: string) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlGraficas + '/' + filter + '/panel?PaisId=' + pais
            const httpInstance = new http();
            let req = await httpInstance.get(ruta, true)
            if (req.error) throw new Error(req.result.message)
            return { error: req.error, message: '', result: req.results }
        } catch (error: any) {
            console.log('Get Graficos service error', error)
            return { error: true, message: error.message, result: null }
        }
    }
    
}