import { Permissions } from "../resources/Statics"
const btoa = require('Base64').btoa

export default class http {

    GETheaders: any = {};
    POSTheaders: any = {};
    DELETEheaders: any = {};
    TOKENheaders: any = {};
    TOKENheadersPost: any = {};

    constructor() {
        this.buildHeaders();
    }

    get = async (url: string, token: boolean = false, signal?: AbortSignal) => {
        try {
            let headers = this.GETheaders;
            if (token) headers = this.TOKENheaders;
            let req = await fetch(url, {
                method: "GET",
                headers: headers,
                signal: signal
            });
            let json = await req.json();
            return json;
        } catch (error: any) {
            console.log("http get method err", error);
            throw new Error(error);
        }
    }

    post = async (url: string, body: any, token: boolean = false) => {
        try {

            let headers = this.POSTheaders;
            if (token) headers = this.TOKENheadersPost;

            let req = await fetch(url, {
                method: "POST",
                body: JSON.stringify(body), // data can be `string` or {object}!
                headers: headers
            });

            let json = await req.json();
            return json;
        } catch (error: any) {
            console.log("http post method err", error);
            throw new Error(error);
        }
    }
    
    upload = async (url: string, body: any, token: boolean = false) => {
        try {

            let headers = this.POSTheaders;
            if (token) headers = this.TOKENheadersPost;

            let req = await fetch(url, {
                    headers: { 'Authorization': headers['Authorization']},
                    method: 'POST',
                    body
                });

            let json = await req.json();
            return json;
        } catch (error: any) {
            console.log("http post method err", error);
            throw new Error(error);
        }
    }

    download = async (url: string, body: any, token: boolean = false) => {
        try {

            let headers = this.POSTheaders;
            if (token) headers = this.TOKENheadersPost;
        
            let req = await fetch(url, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body)
                });

            let fileName = req.headers.get('content-disposition')?.split(';') || '';
            let blob = await req.blob();

            if (fileName.length >= 1 && fileName[1].includes('=')){
                return {
                blob,
                fileName: fileName[1].split('=')[1].replaceAll('"', ''),
                };
            } else if (req.status === 403) {
                throw new Error('No cuenta con los permisos necesarios para completar la acción');
            }

        } catch (error: any) {
            console.log("http download method err", error);
            throw new Error(error);
        }
    }

    put = async (url: string, body: any, token: boolean = false) => {
        try {
            let headers = this.POSTheaders;
            if (token) headers = this.TOKENheadersPost;

            let req = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(body), // data can be `string` or {object}!
                headers: headers
            });

            let json = await req.json();
            return json;

        } catch (error: any) {
            console.log("http post method err", error);
            throw new Error(error);
        }
    }

    delete = async (url: string, body: any, token: boolean = false) => {
      try {
          let headers = this.POSTheaders;
          if (token) headers = this.TOKENheadersPost;

          let req = await fetch(url, {
              method: "DELETE",
              body: JSON.stringify(body),
              headers: headers
          });

          let json = await req.json();
          return json;

      } catch (error: any) {
          console.log("http delete method err", error);
          throw new Error(error);
      }
  }

    async buildHeaders() {
        // Headers de auth con token
        let token: any = window.localStorage.getItem('token')
        if(token){
            this.TOKENheaders = { 'Authorization': `Bearer ${token}`};
            this.TOKENheadersPost = { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'    
            };
        }

        // Headers de auth basica
        this.GETheaders = {'Authorization': 'Basic ' + btoa(Permissions.instance.readCredentialsUser + ":" + Permissions.instance.readCredentialsPass)};
        this.DELETEheaders = {'Authorization': 'Basic ' + btoa(Permissions.instance.deleteCredentialsUser + ":" + Permissions.instance.deleteCredentialsPass)};
        this.POSTheaders = {
            'Authorization': 'Basic ' + btoa(Permissions.instance.writeCredentialsUser + ":" + Permissions.instance.writeCredentialsPass),
            'Content-Type': 'application/json'
        };
    }
}
