import { Box, Button, Chip, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { Add, HighlightOffTwoTone, RemoveTwoTone } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { Rutas } from '../../resources/Statics';
import { GeneralContext } from '../../context/GeneralContext';
import { Autocomplete } from '@material-ui/lab';
import { Link, useHistory, useParams } from 'react-router-dom';
import { estadosSolicitudes } from '../../resources/Statics';
import PageLoader from '../../components/PageLoader';

interface Movimiento {
  id: number;
  ResponsableUsuarioId: number;
  SolicitudPosUbicacionId: number;
  estado: string;
  razonRechazo: string;
  rollos: number;
  sobres: number;
  stickers: number;
}

interface ItemPedido {
  id: number;
  cantidad: number;
  modelo: string;
  UbicacionDestinoId: number;
  eliminar?: boolean;
  movimiento?: Movimiento;
}

interface Solicitud {
  pedidos: ItemPedido[];
  UsuarioDestinoId: number;
  descripcion: string;
  notas?: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formControlHeader: {
      margin: theme.spacing(1),
      minWidth: 280,
    },
    formControl: {
      margin: theme.spacing(1),
      
    },
    formControlCantidad: {
      margin: theme.spacing(1),
      
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(0),
      right: theme.spacing(2),
    },
  }),
);

const SolicitarPosOne = () => {

  const classes = useStyles();
  let history = useHistory()
  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord,
    loading,
  } = useContext(GeneralContext);

  let { id } = useParams<{id: string}>();
  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string, disponibles: number}[]>([]);
  const [responsables, setResponsables] = useState<{id: number, nombre: string}[]>([]);
  const [values, setValues] = useState<Solicitud>({
    pedidos: [{id: 0, cantidad: 1, modelo: 'Model', UbicacionDestinoId: 0, 
    movimiento: undefined
    }],
    UsuarioDestinoId: 0,
    descripcion: '',
    notas: ''
  });

  useEffect(() => {
    getSolicitud();
    getUbicaciones();
    getResponsables();
  }, []);


  const getSolicitud = () => {
    if (id !== '0') {
      const crudService = CrudService.instance(Rutas.instance.urlSolicitudPos);
      crudService
        .getOne(Number(id))
        .then(async response => {
          const { error, result } = response;
          if (!error) {
            if (result)
              setValues(result);
            else
              history.push('/admin-home/movimientos/solicitar-pos');
          }
        })
        .catch(e => {
          handleErrorMessage('message');
          handleActiveError(true);
        });
    }
  }

  const getUbicaciones = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getResponsables = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/recibensolicitudes');
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setResponsables(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleCantidadChange = (e: any, index: number, key: any, type: string = 'string') => {
    const newItemsPedidos = [...values.pedidos]; // Crear una nueva instancia del array
    newItemsPedidos[index] = {...newItemsPedidos[index], [key]: type === 'string' ? e.target.value : Number(e.target.value)}; // Actualizar la cantidad del elemento en el índice dado
    setValues({...values, pedidos: newItemsPedidos}); // Actualizar el estado
  };

  const guardarSolicitud = () => {
    if (values.descripcion === '') {
      handleErrorMessage('La descripción no puede estar vacía');
      handleActiveError(true);
      return;
    }
    if (values.UsuarioDestinoId === 0) {
      handleErrorMessage('Debe seleccionar un usuario destino');
      handleActiveError(true);
      return;
    }

    const cantidadTotal = values.pedidos.reduce((acc, pedido) => acc + pedido.cantidad, 0);
    const cantidadEliminados = values.pedidos.filter(pedido => pedido.eliminar).length;
    if (cantidadTotal === cantidadEliminados) {
      handleErrorMessage('Debe haber al menos un pedido');
      handleActiveError(true);
      return;
    }

    if (values.pedidos.find(pedido => pedido.UbicacionDestinoId === 0 && pedido.eliminar !== true)) {
      handleErrorMessage('Debe seleccionar una ubicación para cada uno de los pedidos');
      handleActiveError(true);
      return;
    }
    if (values.pedidos.find(pedido => pedido.cantidad <= 0 && pedido.eliminar !== true)) {
      handleErrorMessage('Ingrese una cantidad válida para cada uno de los pedidos');
      handleActiveError(true);
      return;
    }
    if (values.pedidos.find(pedido => pedido.modelo === '' && pedido.eliminar !== true)) {
      handleErrorMessage('El modelo de los pedidos no puede estar vacío');
      handleActiveError(true);
      return;
    }
    
    handleLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlSolicitudPos);
    if (id === '0') {
      crudService
        .create(values)
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage('Solicitud creada con éxito');
            handleActiveSuccess(true);
            history.push(`/admin-home/movimientos/solicitar-pos`)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          handleLoading(false);
        })
        .catch(e => {
          handleErrorMessage('message');
          handleActiveError(true);
          handleLoading(false);
        });
    } else {
      crudService
        .updateOne(Number(id), values)
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            history.push(`/admin-home/movimientos/solicitar-pos`)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          handleLoading(false);
        })
        .catch(e => {
          handleErrorMessage('Error al actualizar la solicitud');
          handleActiveError(true);
          handleLoading(false);
        });


      
    }
  }

  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
        style={{position: 'relative'}}
          item
          xs={12}
        >
          <Typography variant='h6'>
            { id === '0' ? 'Nueva solicitud de POS' : `Modificar solicitud de POS: ${id}`}
          </Typography>
         
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ padding: '0.5em', marginTop: '2em' }} >
            <Grid item xs={12}>
              <strong style={{backgroundColor:'rgba(0, 0, 0, 0.02)', padding:'1em'}}>DESCRIPCIÓN</strong>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{position: 'relative'}}
        >
          <FormControl className={classes.formControlHeader}>
            <TextField disabled={values?.pedidos.find(pedido => pedido?.movimiento) ? true : false} label="Descripción" value={values?.descripcion} onChange={(e)=>setValues({...values,descripcion: e.target.value}) } fullWidth multiline />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          style={{position: 'relative'}}
          >
          <FormControl className={classes.formControlHeader}>
            <InputLabel id="demo-simple-select-label">Enviar al usuario:</InputLabel>
            <Select
              value={values?.UsuarioDestinoId}
              disabled={values?.pedidos.find(pedido => pedido?.movimiento) ? true : false}
              onChange={(e) => setValues({...values, UsuarioDestinoId: Number(e.target.value)})}
            >
              {responsables.map((responsable) => (
                <MenuItem key={responsable.id} value={responsable.id}>
                  {responsable?.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Fab disabled={values?.pedidos.find(pedido => pedido?.movimiento) ? true : false} className={classes.fab} style={{margin:'2px'}} onClick={()=>{
              const newItemsPedidos: ItemPedido[] = [...values?.pedidos];
              setValues({...values, pedidos: [...newItemsPedidos, {id: 0, cantidad: 1, modelo: 'Model', UbicacionDestinoId: 0, movimiento: undefined}]});
          }} 
            color="primary" size='small' aria-label="add">
            <Add />
          </Fab>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid container style={{ padding: '0.5em', marginTop: '2em' }} >
            <Grid item xs={12}>
              <strong style={{backgroundColor:'rgba(0, 0, 0, 0.02)', padding:'1em'}}>DETALLE DE LA SOLICITUD</strong>
            </Grid>
          </Grid>
          {
            values && values.pedidos && values.pedidos.length > 0 &&
          values?.pedidos.map((itemPedido, index) => (
            itemPedido.eliminar === true ? null : 
              <>
                <Grid container style={{backgroundColor:'rgba(0, 0, 0, 0.02)', borderRadius:'20px', padding: '0.5em', marginTop: '0.5em' }} >
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Para la Ubicación: </InputLabel>
                      <Select
                        fullWidth
                        value={itemPedido.UbicacionDestinoId}
                        disabled={(!itemPedido.movimiento || id === '0') ? false : true}
                        onChange={(e) => {
                          handleCantidadChange(e, index, 'UbicacionDestinoId', 'number')
                        }}
                      >
                        {ubicaciones.map((ubicacion) => (
                          <MenuItem key={ubicacion.id} value={ubicacion.id}>
                            {ubicacion?.descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                      {itemPedido.UbicacionDestinoId === 0 ? <small style={{color:'rgba(0, 0, 0, 0.38)'}}>Seleccione una ubicación</small> :
                        <small style={{color:'rgba(0, 0, 0, 0.38)'}}>(<strong>{ubicaciones.find(ubicacion => ubicacion.id === itemPedido.UbicacionDestinoId)?.disponibles}</strong> POS disponibles en esta ubicación )</small>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth className={classes.formControlCantidad}>
                      <TextField
                        label="Cantidad"
                        disabled={(!itemPedido.movimiento || id === '0') ? false : true}
                        type='number'
                        fullWidth
                        onChange={(e) => handleCantidadChange(e, index, 'cantidad', 'number')}
                        value={itemPedido.cantidad}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        label="Modelo"
                        disabled={(!itemPedido.movimiento || id === '0') ? false : true}
                        fullWidth
                        value={itemPedido.modelo}
                        onChange={(e) => handleCantidadChange(e, index, 'modelo')}
                      />
                    </FormControl>
                  </Grid>
                  {id !== '0' &&
                    <Grid item xs={12} md={2}>
                      <FormControl style={{paddingLeft:'1em'}} className={classes.formControl}>
                          <small style={{color:'rgba(0, 0, 0, 0.38)'}}>Estado movimiento</small>
                          <Chip
                              label={itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].value : 'No atendido'}
                              style={{background: itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].background : '#e0e0e0', color: itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].color : '#000' }}
                            />
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={12} md={2} style={{padding:'1em'}}>
                    
                    <Fab  
                      disabled={(!itemPedido.movimiento || id === '0') ? false : true}
                      title={(!itemPedido.movimiento) ? 'Eliminar' : `No se puede eliminar ya que tiene un estado ${estadosSolicitudes[itemPedido.movimiento.estado].value}`}
                      onClick={()=>{
                        const cantidadSinEliminados = values?.pedidos.filter(pedido => !pedido.eliminar).length;
                        if (cantidadSinEliminados > 1) {
                          const newItemsPedidos: ItemPedido[] = [...values.pedidos];
                          newItemsPedidos[index].eliminar = true;
                          setValues({...values, pedidos: newItemsPedidos});
                        } else {
                          handleErrorMessage('Debe haber al menos un pedido');
                          handleActiveError(true);
                        }
                    }} 
                      color="primary" size='small' aria-label="add">
                      <RemoveTwoTone />
                    </Fab>
                  </Grid>
                </Grid>
              </>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          style={{position: 'relative', marginTop: '2em'}}
        >
          <FormControl fullWidth className={classes.formControl}>
            <TextField disabled={values?.pedidos.find(pedido => pedido?.movimiento) ? true : false} label="Notas adicionales" value={values?.notas} onChange={(e)=>setValues({...values,notas: e.target.value}) } fullWidth multiline />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={9}></Grid>
        <Grid item xs={12} md={3} style={{textAlign:'right', paddingTop: '1em'}}>
          <Button
            variant='contained'
            color='primary'
            style={{ margin: 6 }}
            onClick={guardarSolicitud}
          >
            Guardar
          </Button>
          <Button
            component={Link}
            to='/admin-home/movimientos/solicitar-pos'
            variant='contained'
            color='secondary'
            style={{ margin: 6 }}
          >
            Cancelar
          </Button>
        </Grid>
        
      </Grid>
      <PageLoader loading={loading} />
    </Box>
  )
}

export default SolicitarPosOne