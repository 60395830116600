import { Backdrop, Box, Button, Chip, Fade, FormControl, Grid, IconButton, ImageList, ImageListItem, InputLabel, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, Modal, Paper, Select, Tab, Tabs, TextField, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import { DataGrid, GridRowParams } from "@material-ui/data-grid"
import { Cancel, Check, CloudUpload, OpenInBrowser, PanTool } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { Rutas } from "../../resources/Statics";
import LoadingButton from "../../components/LoadingButton";
import { DropzoneDialog } from "material-ui-dropzone";
import * as momentZone from 'moment-timezone';
import DocumentosService from "../../services/DocumentosService";
import NegociosServices from "../../services/NegociosServices";
import { GeneralContext } from "../../context/GeneralContext";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { Text } from '@react-pdf/renderer';
import { Link } from "react-router-dom";
import PaisesServices from "../../services/PaisesService";
import { NegocioInterface } from "../../interfaces/Interfaces";
import RefreshIcon from '@material-ui/icons/RefreshTwoTone';
import ModalRevisionDocumentos from "../../components/ModalRevisionDocumentos";
import localStorage from '../../libs/localStorage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '60vw',
    },
    imageList: {
      width: 'auto',
      height: 600,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 300,
    },
    transitionGrid: {
      transition: 'margin-right 0.3s ease-in-out',  // Cambiado a 'right'
      position: 'fixed',  // Agregado para fijar la posición
      top: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
      bottom: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
    },
    archivos: {
      marginRight: 0,  // Inicialmente oculto
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

const Documentos = () => {

  const {
    loading: generalLoading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
  } = useContext(GeneralContext);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [arrayImages, setArrayImages] = useState<any[]>([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  //const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState('rtu');
  const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState('');
  const [tipoDocumentos, setTipoDocumentos] = useState<any[]>([]);
  const [documentoIdSelected, setDocumentoIdSelected] = useState(0);
  const [documentoDocTipoIdSelected, setDocumentoDocTipoIdSelected] = useState(0);
  const [messageRejected, setMessageRejected] = useState<string>('');
  const [uploading, setUploading] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [UsuarioId, setUsuarioId] = useState(0);
  const [NegocioId, setNegocioId] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [tipoNegocioTemp, setTipoNegocioTemp] = useState<Record<string, string>>({});
  const [paises, setPaises] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [countryFilter, setCountryFilter] = useState('all');
  const [negocios, setNegocios] = useState<NegocioInterface[]>([]);
  const [negocio, setNegocio] = useState<NegocioInterface | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [tab, setTab] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)
  const [totalDocumentoRevisados, setTotalDocumentoRevisados] = useState<number>(0)
  const [tiempoRevision, setTiempoRevision] = useState<number>(0)

  useEffect(() => {
    getPaises();
    getNegocios();
    setPage(1)
  } , []);

  useEffect(() => {
    const status = tab === 1 ? 'pendiente' : 'subido'
    if (page > 0) getDocumentos(status);
  }, [page, pageSize]);

  useEffect(() => {
    if (NegocioId) {
      getTipoDocumentos(NegocioId);
    }
  } , [NegocioId]);

  useEffect(() => {
    setPage(1)
  } , [tab]);

  const handleSetOpenModalFile = () => {
    setOpenModalFile(true);
  };

  const openFiles = (row: any) => {
    try {
      if (row.link) {
        try {
          const link = JSON.parse(row.link);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row.link]);
          setOpenImagesModal(true);
        }
      } else if (row.documento && row.documento.link) {
        try {
          const link = JSON.parse(row.documento.link);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([row.documento.link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row.documento.link]);
          setOpenImagesModal(true);
        }
      } else if (
        row.LinksDocumentoNegocio &&
        Array.isArray(row.LinksDocumentoNegocio) &&
        row.LinksDocumentoNegocio.length > 0
      ) {
        try {
          const link = JSON.parse(row.LinksDocumentoNegocio[row.LinksDocumentoNegocio.length - 1].links);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
        }
      } else {
        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openFile = (item: any) => {
    try {
      window.open(item, '_blank');
    } catch (error) {
      console.log(error);
      alert('No se pudo abrir el archivo');
    }
  };

  const getTipoDocumentos = (id: number) => {
    NegociosServices.instance
      .getTipoDocumentos(id)
      .then(response => {
        const { error, docTipoNegocio, rolUsuario, message } = response;

        if (!error) {
          if (docTipoNegocio) {
            setUsuarioId(rolUsuario?.UsuarioId || 0);

            const roleDocs = JSON.parse(rolUsuario?.rol?.documentosObligatorios ?? '[]');

            let docs = docTipoNegocio?.map((doc: any) => doc?.nombre || '') ?? [];
            let filtered = docs.filter((c: any, index: number) => docs.indexOf(c) === index);

            setTipoDocumentos(roleDocs.length > 0 ? roleDocs : filtered);
          } else {
            handleErrorMessage('Debes asignar un usuario a este negocio para poder adjuntar documentos.');
            handleActiveError(true);
          }
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getPaises = () => {
    PaisesServices.instance
      .get()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPaises(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleSetTipoDocumentoSelected = (event: any) => {
    setTipoDocumentoSelected(event.target.value);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setOpenModalFile(false);
    setNegocio(null);
    setNegocioId(0);
  };

  const handleOpenModal = () => {
    setOpen(true);
    setMessageRejected('');
  };

  const getDocumentos = async (status:string ='subido') => {
    setLoading(true);

    let user = await localStorage.instance.getUserParseado() ;
    const params = `?page=${page}&pageSize=${pageSize}&paisId=${countryFilter}&nombre=${searchValue}&status=${status}&usuarioRevisionId=${user?.id}`;
    NegociosServices.instance
      .getNewDocumentsByStatusNegocios(params)
      .then(response => {
        const { error, result, message, totalPages, totalDocuments,totalDocumentoRevisados, tiempoRevision } = response;
        
        setTotalPages(totalPages);
        setTotalFiles(totalDocuments)
        setTotalDocumentoRevisados(totalDocumentoRevisados)
        setTiempoRevision(tiempoRevision)
        setLoading(false);

        if (!error) setRows(result)
        else {
          setLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });

  };

  const updateSubirloNegocio = (id: number, subirloNegocio: boolean, estado: number, negocioId: number) => {
    if (id > 0) {
      setLoading(true);
      DocumentosService.instance
        .updateNewDocumentStatus(id, {
          negocioId,
          subirloNegocio,
          message: '',
          estado,
        })
        .then(response => {
          const { error, message } = response;

          if (!error) {
            getDocumentos();
            setOpen(false);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  function renderEstado(id: number, docTipoId: number, value: any, negocioId: number) {
    let color = 'red';
    switch (value) {
      case 'subido':
        color = '#5166AF';
        break;
      case 'aprobado':
        color = 'green';
        break;
      case 'rechazado':
        color = 'red';
        break;
      case 'pendiente':
        color = '#FCBF49';
        break;
      default:
        color = '#FCBF49';
        break;
    }
    return (
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
      >
        <Chip
          style={{ backgroundColor: color }}
          label={value}
          clickable
          color='primary'
          onClick={() => {
            setNegocioId(negocioId);
            setDocumentoIdSelected(id);
            setDocumentoDocTipoIdSelected(docTipoId);
            handleOpenModal();
          }}
        />
      </div>
    );
  }

  const handleOpenUpload = () => {
    setOpenUploadFile(true);
  };

  const handleTabs = (event: any, newValue: number) => {
    setTab(newValue);
    if (newValue === 1) {
      setLoading(true)
      getDocumentos('pendiente')
    } else if (newValue === 0){ 
      getDocumentos('subido')
    }
  };
  const handleCreateDocument = (params: any) => {
    setUploading(true);

    DocumentosService.instance
      .createDocumento(params)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getDocumentos();
          setOpenModalFile(false);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const cambiarEstadoDocumento = (estado: number, message?: string) => {
    setShow(false);

    if (documentoIdSelected > 0) {
      setLoading(true);
      DocumentosService.instance
        .updateNewDocumentStatus(documentoIdSelected, {
          negocioId: NegocioId,
          estado,
          message: message ?? '',
        })
        .then(response => {
          const { error, message } = response;

          if (!error) {
            getDocumentos();
            setOpen(false);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };
  

  const handleSave = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);

    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setUploading(false);
        if (response.length > 0) {
          const params = {
            tipoDocumento: tipoDocumentoSelected,
            UsuarioId,
            link: JSON.stringify(response),
            extension: '',
            nombre: 'archivo',
            estado: 'subido',
          };
          handleCreateDocument(params);
        } else {
          handleErrorMessage('No se pudo subir archivo');
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });

  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const handleChangeMessage = (e: any) => {
    setMessageRejected(e.target.value);
  };

  const updateTipoNegocio = (id: string, tipoNegocio: string) => {
    if (!loading) {
      setLoading(true);
      NegociosServices.instance
        .updateTipoNegocio(+id, { tipoNegocio })
        .then(response => {
          setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const metamapInfo = (verificaciones: []) => {
    const verificado:any = verificaciones.filter(
      (verification: any) => verification.verificado == true,
    );
    const noVerificado: any = verificaciones.filter(
      (verification: any) => verification.verificado == false,
    );
    let fullName = '';
    let dpi = '';
    if (verificado.length > 0) {
      const hooks = verificado[0]?.hooks ? JSON.parse(verificado[0]?.hooks) : null;
      
      hooks && hooks.length > 0 && hooks.forEach((hook: any) => {
        if (hook?.step?.id === 'document-reading' && hook?.step?.data?.fullName?.value && hook?.step?.data?.documentNumber?.value) {
          fullName = hook?.step?.data?.fullName?.value ?? '';
          dpi = hook?.step?.data?.documentNumber?.value ?? '';
          return;
        }
      })
    }
    return {fullName, dpi};

  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchChangeNegocio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();

    getNegocios('?nombre='+searchValue)
    setNegocio(null);
  };

  const getNegocios = (params="") => {
      NegociosServices.instance.get(params).then(response => {
          const { result } = response
          setNegocios(result)
          //setFilteredBusinesses(result);
      }).catch(e => {
          handleErrorMessage('message')
          handleActiveError(true)
      })
  }

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      filtrar();
    }
  };

  const handleCountryFilterChange = (event: any) => {
    setCountryFilter(event.target.value);
  };

  const filtrar = () => {
    getDocumentos();
  };

  const procesarGetNegocios = () => {
    if (page === 1) getDocumentos();
    else setPage(1);
  }

  const handleBusinessSelect = (event: React.ChangeEvent<{}>, value: NegocioInterface | null) => {
    setNegocio(value);
    setNegocioId(value?.id as number)
};

  const getDiasEnCola = (startDateStr: string, endDateStr:string) =>{
    const startDate = new Date(startDateStr);
    const endDate = new Date();
    const nextDay = new Date(startDate);
    let cnt = 0;
    do {
        cnt += (nextDay.getDay() >= 1 && nextDay.getDay() <= 5) ? 1 : 0;
        nextDay.setDate(nextDay.getDate() + 1);
    } while (nextDay <= endDate);
    return cnt
  }

  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(procesarGetNegocios, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  useEffect(() => {
    if (page === 1) getDocumentos();
    else setPage(1);
  }, [countryFilter]);

  const columns = [

    { field: 'id', headerName: 'Id', width: 100 },
    {
      field: 'acciones',
      headerName: 'Visualizar',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openFiles(params.row)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'docTipoNegocio',
      headerName: 'Documento',
      width: 230,
      renderCell: (params: any) => {
        return <span>{params.formattedValue.nombre}</span>;
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return renderEstado(params.id, params.id, params.value, params?.row?.negocio?.id);
      },
    },
    {
      field: 'negocioId',
      headerName: 'Id Negocio',
      width: 160,
      renderCell: (params: any) => {
        return <span>{params.row?.negocio?.id}</span>;
      },
    },
    {
      field: 'negocioNombre',
      headerName: 'Nombre Negocio',
      width: 240,
      renderCell: (params: any) => {
        return <span>{params.row?.negocio?.nombre}</span>;
      },
    },
    {
      field: 'dayOnQueque',
      headerName: 'Dias en cola de revision',
      width: 180,
      
      renderCell: (params: any) => {
        const days = getDiasEnCola(params.getValue(params.id, 'createdAt'), 'today' )
        return <span>{days}</span>;
      },
    },

    {
      field: 'tiponegocio',
      headerName: 'Tipo de negocio',
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => {
        let afiliacion = '';
        if (
          params &&
          params.row &&
          params.row.negocio &&
          params.row.negocio.rolUsuario &&
          params.row.negocio.rolUsuario[0] &&
          params.row.negocio.rolUsuario[0].rol &&
          params.row.negocio.rolUsuario[0].rol.tipo
        ) {
          afiliacion = params.row.negocio.rolUsuario.find((rol: any) => rol.rol.nombre === 'administrador')?.rol?.tipo ?? '';
        }

        afiliacion = afiliacion.replace('administrador', '');
        if (afiliacion.startsWith('_')) afiliacion = afiliacion.substring(1);
        let tipos = [];
        const tiposPayfac = [
          { name: 'No formalizado', value: 'no_formalizado' },
          { name: 'No formalizado Medio', value: 'no_formalizado_medio' },
          { name: 'No formalizado Plus', value: 'no_formalizado_plus' },

          { name: 'title', value: 'Emprendedor / Comerciante' },
          { name: 'Pequeño Contribuyente', value: 'emprendedor' },
          { name: 'Pequeño Contribuyente Plus', value: 'emprendedor_plus' },

          { name: 'title', value: 'Persona individual' },
          { name: 'Persona individual', value: 'persona_individual_payfac' },
          { name: 'Persona individual Plus', value: 'persona_individual_plus' },

          { name: 'title', value: 'Sociedad anónima' },
          { name: 'Sociedad anónima', value: 'sociedad_anonima_payfac' },
          { name: 'Sociedad anónima Plus', value: 'sociedad_anonima_plus' },

          { name: 'title', value: 'Pequeño contribuyente' },
          { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'pequeno_contribuyente_payfac' },
          { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'pequeno_contribuyente_plus' },
        ];
        const tiposPayfacHonduras = [
          { name: 'No formalizado', value: 'h_no_formalizado' },
          { name: 'No formalizado Plus', value: 'h_no_formalizado_plus' },

          { name: 'title', value: 'Emprendedor / Comerciante' },
          { name: 'Pequeño Contribuyente', value: 'h_emprendedor' },
          { name: 'Pequeño Contribuyente Plus', value: 'h_emprendedor_plus' },

          { name: 'title', value: 'Sociedad anónima' },
          { name: 'Sociedad anónima', value: 'h_sociedad_anonima_payfac' },
          { name: 'Sociedad anónima Plus', value: 'h_sociedad_anonima_plus' },

          { name: 'title', value: 'Pequeño contribuyente' },
          { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'h_pequeno_contribuyente_payfac' },
          { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'h_pequeno_contribuyente_plus' },
        ];

        const tiposAgregador = [
          { name: 'Persona individual', value: 'persona_individual' },
          { name: 'Pequeño contribuyente', value: 'pequeno_contribuyente' },
          { name: 'Sociedad anónima', value: 'sociedad_anonima' },
          { name: 'Servicios Profesionales', value: 'servicios_profesionales' },
          { name: 'Fundación', value: 'fundacion' },
          { name: 'Institución Educativa', value: 'institucion_educativa' },
        ];

        if (params.row?.negocio?.tipoNegocio?.categoriaVisa === 'payfac') {
          afiliacion = params.row?.negocio?.tipoNegocio?.nombre;
          tipos.push({
            name: params.row?.negocio?.tipoNegocio?.nombre,
            value: params.row?.negocio?.tipoNegocio?.nombre,
          });
          tipos = params.row.negocio.tipoNegocio.pais.nombre === 'Honduras' ? tiposPayfacHonduras : tiposPayfac;
        } else {
          tipos = tiposAgregador;
        }

        if (!tipos.map(tipo => tipo.value).includes(afiliacion)) {
          tipos.push({
            name: afiliacion,
            value: afiliacion,
          });
        }

        const stateValue = tipoNegocioTemp[params.row.negocio?.id];

        let negocioId = '';
        if (params && params.row && params.row.negocio && params.row.negocio.id) negocioId = params.row.negocio.id;

        const businessType: string = stateValue || afiliacion;
        let plusTypeIndex = tipos.findIndex(
          tipo =>
            tipo.value === `${businessType}_plus` ||
            tipo.value === `h_${businessType}_plus` ||
            tipo.value === `${businessType?.replace('_medio', '')}_plus`,
        );

        return (
          <FormControl fullWidth>
            <Select
              fullWidth
              value={businessType}
              name='tiponegocio'
              
              inputProps={{ 'aria-label': 'tipo_negocio' }}
              onChange={e => {
                updateTipoNegocio(negocioId, e.target.value as string);
                setTipoNegocioTemp(prevTipos => ({ ...prevTipos, [params.row.negocio?.id]: e.target.value }));
              }}
            >
              {tipos.map((tipo, index) =>
                tipo.name === 'title' ? (
                  <ListSubheader key={`subheader_${tipo.value}`}>
                    <hr />
                  </ListSubheader>
                ) : (
                  <MenuItem
                    value={tipo.value}
                    key={`tipo_negocio_${tipo.value}`}
                    disabled={tipo.value === afiliacion}
                  >
                    <Text style={{ fontWeight: plusTypeIndex === index ? 'bold' : 'normal' }}>{tipo.name}</Text>
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'tipoNegocio',
      headerName: 'NIT Negocio',
      width: 170,
      renderCell: (params: any) => {
        return <span>{params.row?.negocio?.nit}</span>;
      },
    },
    {
      field: 'razonSocial',
      headerName: 'Razon social',
      width: 240,
      renderCell: (params: any) => {
        return <span>{params.row?.negocio?.razonSocial}</span>;
      },
    },
    
    {
      field: 'metamapNombre',
      headerName: 'Metamap | Nombre',
      width: 470,
      renderCell: (params: any) => {
        const { fullName } = metamapInfo(params?.row?.verificaciones);
        return <span>{fullName}</span>;
      },
    },
    {
      field: 'metamapDPI',
      headerName: 'Metamap | DPI',
      width: 190,
      renderCell: (params: any) => {
        const { dpi } = metamapInfo(params?.row?.verificaciones);
        return <span>{dpi}</span>;
      },
    },
    {
      field: 'usuarioAfiliador',
      headerName: 'Afiliador a cargo',
      width: 300,
      renderCell: (params: any) => {
        return <span>{params?.row?.negocio?.UsuarioAfiliador?.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Subido',
      width: 180,
      
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'observaciones',
      headerName: 'Observaciones',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
    },
    
    {
      field: 'quitarLimite',
      headerName: 'Obligatorio',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (params.value ? 'No' : 'Si'),
    },
    {
      field: 'subirloNegocio',
      headerName: 'Responsabilidad',
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <Select
            fullWidth
            onChange={ev => {
              updateSubirloNegocio(params.id, ev.target.value === 'negocio', params.row.status, params.row.NegocioId);
            }}
            value={params.value ? 'negocio' : 'afiliador'}
            inputProps={{
              name: 'subirloNegocio',
              id: 'subirloNegocio-select',
            }}
          >
            <MenuItem value='negocio'>Negocio</MenuItem>
            <MenuItem value='afiliador'>Afiliador</MenuItem>
          </Select>
        );
      },
    },
    
    
  ];
  function setTabDocuments(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
    >
      <Grid 
        container 
        direction='column'
      >
        <Grid item>
          <Grid container>
            <Grid md= {12} xs={12} item >
                    <Typography
                      variant='h6'
                      gutterBottom
                    >
                      Revisar Documentos
                      <IconButton
                        aria-label='delete'
                        color='primary'
                        onClick={ ()=> getDocumentos(tab === 0 ? 'subido': 'pendiente' )}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Typography>

                    <Typography
                      variant='subtitle1'
                      gutterBottom
                    >
                      Documentos Pendientes de revision: <strong>{totalFiles}</strong>
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                    >
                      Documentos Revisados por usuario: <strong>{totalDocumentoRevisados}</strong>
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                    >
                      Tiempo promedio de revision: <strong>{tiempoRevision} minutos</strong>
                    </Typography>
                  </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                justifyContent={['flex-start', 'flex-start', 'flex-end']}
                sx={{ flexDirection: 'row', display: 'flex' }}
              >
                <TextField
                  label='Buscar'
                  placeholder='id, negocio, estado'
                  variant='outlined'
                  style={{ marginRight: 10 }}
                  size='small'
                  name='search'
                  value={searchValue}
                  onKeyDown={handleSearchKeyDown}
                  onChange={handleSearchChange}
                />

                <FormControl
                  style={{ marginRight: 10 }}
                  size='small'
                >
                  <Select
                    placeholder='País'
                    variant='outlined'
                    value={countryFilter}
                    onChange={handleCountryFilterChange}
                    fullWidth
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    {paises?.map((act: any) => {
                      return (
                        <MenuItem
                          key={act.id}
                          value={act.id}
                        >
                          {act.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button
                  fullWidth
                  size='small'
                  style={{ width: '100px' }}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setIsOpenModal(true)
                  }}
                  disabled={tab === 1}
                >
                  REVISAR
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} style={{ position: 'relative' }}>
                   
                <Tabs
                  scrollButtons='on'
                  variant='scrollable'
                  style={{ backgroundColor: '#efefef', marginTop: 20, marginBottom: 10 }}
                  indicatorColor='primary'
                  value={tab}
                  onChange={handleTabs}
                >
                    <Tab
                      style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                      label='Documentos Subidos'
                      {...setTabDocuments(0)}
                    />

                    <Tab
                      style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                      label='Documentos Pendientes'
                      {...setTabDocuments(1)}

                    />
                </Tabs>
              </Grid>
            <Grid
              item
              xs={12}
              style={{ height: 640, paddingTop: 10}}
            >
              <DataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                headerHeight={37}
                rowHeight={37}
                paginationMode='server'
                pageSize={pageSize}
                rowCount={totalPages * pageSize}
                hideFooterPagination
                hideFooter
                autoHeight
                style={{ maxHeight: '100%' }}  
              />
              <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  shape='rounded'
                  size='medium'
                  variant='outlined'
                  color='primary'
                  style={{ padding: '8px', textAlign: 'center' }}
                  onChange={(event, value) => setPage(value)}
                />
                </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      <Modal
        className={classes.modal}
        open={openModalFile}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalFile}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Subir archivo</h2>
            <p id='transition-modal-description'></p>
            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid item>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <Autocomplete
                      id="autocomplete"
                      options={negocios}
                      getOptionLabel={(business) => business.nombre}
                      value={negocio}
                      onChange={handleBusinessSelect}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          label='Escriba negocio a buscar'
                          onChange={handleSearchChangeNegocio}
                          variant="outlined"
                      />
                      )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
              <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel id='documento-select-label'>Tipo de documento</InputLabel>
                  <Select
                    labelId='documento-select-label'
                    id='documento-select'
                    label='Tipo de documento'
                    value={tipoDocumentoSelected}
                    disabled={NegocioId === 0 || !NegocioId}
                    onChange={i => handleSetTipoDocumentoSelected(i)}
                  >
                    <MenuItem value=''>
                      <em>Seleccione</em>
                    </MenuItem>
                    {tipoDocumentos.map((tipo: any) => {
                      return (
                        <MenuItem
                          key={tipo}
                          value={tipo}
                        >
                          {tipo}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={uploading}
                  disabled={tipoDocumentoSelected && NegocioId > 0 ? false : true}
                  color='primary'
                  variant='contained'
                  title='Elegir archivo'
                  onClick={() => {
                    handleOpenUpload();
                  }}
                />
                <DropzoneDialog
                  dropzoneText='Arrastra los documentos o presiona aquí'
                  previewText='Vista prévia'
                  dialogTitle={'Elige archivo'}
                  submitButtonText='Subir archivo'
                  cancelButtonText='Cancelar'
                  open={openUploadFile}
                  onSave={(files) => handleSave(files)}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                  showPreviews={true}
                  maxFileSize={5000000000}
                  filesLimit={10}
                  onClose={handleCloseUpload}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <Modal
        className={classes.modal}
        open={openImagesModal}
        onClose={() => {
          setOpenImagesModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        
      >
        <Fade in={openImagesModal}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Documentos</h2>
            <p id='transition-modal-description'></p>
            <ImageList
              rowHeight={160}
              className={classes.imageList}
              cols={1}
            >
              {arrayImages.map((item: any) => (
                <ImageListItem
                  key={item}
                  cols={1}
                  rows={4}
                >
                  {item.includes('pdf') ? (
                    <iframe 
                    src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                    style={{width: '100%', height: '600px'}}
                    key={item}
                    title="PDF Viewer" 
                    />
                  ) : (
                    <img
                      style={{width:'600px'}}
                      src={item}
                      onClick={() => openFile(item)}
                    />
                  )}
                  
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Fade>
      </Modal>

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Cambiar estado</h2>
            <p id='transition-modal-description'></p>
            <List
              component='nav'
              aria-label='secondary mailbox folders'
            >
              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(4, '')}
              >
                <ListItemIcon>
                  <PanTool style={{ color: '#FCBF49' }} />
                </ListItemIcon>
                <ListItemText primary='Pendiente' />
              </ListItem>

              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(1, '')}
              >
                <ListItemIcon>
                  <CloudUpload style={{ color: '#5166AF' }} />
                </ListItemIcon>
                <ListItemText primary='Subido' />
              </ListItem>

              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(2, '')}
              >
                <ListItemIcon>
                  <Check style={{ color: 'green' }} />
                </ListItemIcon>
                <ListItemText primary='Aprobado' />
              </ListItem>

              <ListItem
                button
                onClick={() => setShow(true)}
              >
                <ListItemIcon>
                  <Cancel style={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText primary='Rechazado' />
              </ListItem>

              {show ? (
                <ListItem>
                  <Grid container>
                    <TextField
                      label='Motivo de rechazo'
                      variant='outlined'
                      fullWidth
                      onChange={handleChangeMessage}
                    />
                  </Grid>

                  <Button
                    disabled={messageRejected.length > 0 ? false : true}
                    variant='contained'
                    color='primary'
                    style={{ margin: 10 }}
                    onClick={() => cambiarEstadoDocumento(3, messageRejected)}
                  >
                    Guardar
                  </Button>
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </div>
        </Fade>
      </Modal>

      { isOpenModal &&         
        <ModalRevisionDocumentos isLoadinPage={loading} isOpen={isOpenModal} setIsOpen={setIsOpenModal} documentoId="1" negocioId="" negocioName={rows[0].negocio?.nombre}  negocios={rows} page={page} setPage={setPage} totalPages={totalPages}/>
      }

    </Box>
  )
}

export default Documentos