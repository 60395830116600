import React, { useContext, useEffect, useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/LoadingButton';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import { NegocioInterface, RoleInterface, UsuarioInterface } from '../../interfaces/Interfaces';
import UsersServices from '../../services/UsersServices';
import NegociosServices from '../../services/NegociosServices';
import ValidationFields from '../../libs/validationFields';
import RoleServices from '../../services/RoleServices';
import { useHistory } from "react-router-dom";



export default function UsersCreate() {
    const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess } = useContext(GeneralContext)

    let history = useHistory()

    const [loading, setLoading] = React.useState(false);

    const [roleId, setRoleId] = React.useState(0);
    const [role, setRole] = React.useState<RoleInterface>({
        id:0,
        nombre:'',
        tipo:''
    });
    const [roles, setRoles] = React.useState<RoleInterface[]>([]);

    const [negocio, setNegocio] = React.useState<NegocioInterface>({
        id: 0,
        nombre: ''
    });

    const [negocioId, setNegocioId] = React.useState(0);
    const [negocios, setNegocios] = React.useState<NegocioInterface[]>([]);

    const [values, setValues] = useState<UsuarioInterface>({
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        cui: '',
        telefono: '',
        email: '',
        password: ''
    });
    useEffect(() => {
        getRoles()
        getNegocios()
    }, [])

    useEffect(() => {
        if(negocioId > 0){
            const item = negocios.find(i => i.id  === negocioId )
            setNegocio(item as NegocioInterface)
        }
    }, [negocioId])

    useEffect(() => {
        if(roleId > 0){
            const item = roles.find(i => i.id  === roleId )
            setRole(item as RoleInterface)
        }
    }, [roleId])

    
    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const getNegocios = () => {
        NegociosServices.instance.get().then(response => {
            const { result }  = response
            setNegocios(result)

        }).catch(e => {
            handleErrorMessage('message')
            handleActiveError(true)
        })
    }
    const getRoles = () => {
        RoleServices.instance.get().then(response => {
            const { result }  = response
            setRoles(result)
        }).catch(e => {
            setRoles([])
            handleErrorMessage('message')
            handleActiveError(true)
        })
    };

    const createPassword = () => {
        const password = Math.random().toString(36).slice(-8);
        setValues({ ...values, password });
        handleSuccessMessage("Password generado")
        handleActiveSuccess(true)
    }

    const createAccount = () => {
        let validation = ValidationFields.instance.newUser(values)
        if(validation.error){
            handleErrorMessage(validation.message)
            handleActiveError(true)
        }else {
            if(negocioId == 0){
                handleErrorMessage('Debes seleccionar un negocio')
                handleActiveError(true)
                return;
            }
            if(roleId == 0){
                handleErrorMessage('Debes seleccionar un rol para el usuario')
                handleActiveError(true)
                return;
            }
            if (!loading) {
            setLoading(true);
            UsersServices.instance.create({ ...values, role, negocio }).then(response => {
                setLoading(false);
                const { error, message } = response
                if(!error){
                    handleSuccessMessage(message)
                    handleActiveSuccess(true)
                    history.push('/admin-home/users')
                }else{
                    handleErrorMessage(message)
                    handleActiveError(true)
                }
                
            }).catch(e => {
                setLoading(false);
                handleErrorMessage(e.message)
                handleActiveError(true)
            })
            
            }
        }

    };

    return (<Box mt={1} p={2} component={Paper}>
                <Grid container direction="column" spacing={2} >
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Nuevo usuario
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Creación de cuenta del usuario
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="negocio-select-label">Negocio</InputLabel>
                            <Select
                                labelId="negocio-select-label"
                                id="negocio-select"
                                value={negocioId}
                                label="Negocio"
                                onChange={(item)=> setNegocioId(item.target.value as number)  }>
                                {negocios.map(({id, nombre}) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="role-select-label">Rol</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                value={roleId}
                                label="Rol"
                                onChange={(item)=>{ setRoleId(item.target.value as number) }}>
                                {roles.map(({id, nombre, tipo}) => <MenuItem key={id} value={id}>{nombre} - {tipo}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField label="Nombre" variant="outlined" fullWidth value={values.nombre}  onChange={handleChange('nombre')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Apellido Paterno" variant="outlined" fullWidth value={values.apellidoPaterno}  onChange={handleChange('apellidoPaterno')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Apellido Materno" variant="outlined" fullWidth value={values.apellidoMaterno}  onChange={handleChange('apellidoMaterno')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Teléfono" variant="outlined" fullWidth value={values.telefono}  onChange={handleChange('telefono')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="DPI" variant="outlined" fullWidth value={values.cui}  onChange={handleChange('cui')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Correo" variant="outlined" fullWidth value={values.email}  onChange={handleChange('email')}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Contraseña" variant="outlined" fullWidth  value={values.password}  onChange={handleChange('password')}/>
                    </Grid>
                    <Grid item>
                        <Button fullWidth onClick={createPassword}>Generar contraseña</Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton loading={loading} color="primary" variant="contained" title='Crear cuenta' onClick={createAccount} /> 
                    </Grid>
                </Grid>
            </Box>)
}
