import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, PropTypes } from '@material-ui/core';

interface LoadingButtonProps {
    loading?: boolean;
    disabled?: boolean;
    title: string;
    variant?: 'text' | 'outlined' | 'contained';
    onClick: any;
    color?: PropTypes.Color;
}

const useStyles = makeStyles({
    loader: {
        marginLeft: 10,
        color:'#FFFFFF'
    },
});
export default function LoadingButton({loading=false, color='primary', title, variant='contained', onClick, disabled=false}: LoadingButtonProps) {
    const classes = useStyles();

    return (<Button color={color} disabled={disabled}  fullWidth onClick={onClick} variant={variant} >{title}  {loading && <CircularProgress size={15} className={classes.loader}/>}</Button>);
}
 