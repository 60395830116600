import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class RegisterService {
    static instance = new RegisterService();

    correoVerificacionSms = async (email: any, telefono: any) => {

        try {
            let data = {
                email: email,
                telefono: telefono
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/correoVerificacionSms'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    correoVerificacionCorreo = async (email: any) => {

        try {
            let data = {
                email: email
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/correoVerificacionCorreo'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    getActivities = async () => {

        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlActividad

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)


            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: [] }
        }

    }

    getActivitiesByCategoryVisa = async (category: any) => {

        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlActividad + '/categoryvisa/'+category

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)


            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: [] }
        }

    }

    getSubActivities = async () => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlSubActividad

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service err', error)
            return { error: true, message: error.message, result: [] }
        }

    }

    getSubActivitiesByActivityId = async (id: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlSubActividad + '/actividad/' + id

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service err', error)
            return { error: true, message: error.message, result: [] }
        }

    }

    step1 = async (telefono: any, email: any, password: any, tipoAfiliacion: any) => {

        try {

            let data = {
                telefono: telefono,
                email: email,
                hash: password,
                tipoAfiliacion: tipoAfiliacion
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso1'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data)
            console.log(req)
            if (req.error) throw new Error(req.result.message)

            let token = req.result.token
            let user = req.result.user
            let procesoregistro = req.result.procesoregistro
            let negocio = req.result.negocio
            let informacionbancaria = req.result.informacionbancaria

            window.localStorage.setItem('token', token)
            window.localStorage.setItem('user', JSON.stringify(user))
            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))
            window.localStorage.setItem('negocio', JSON.stringify(negocio))
            window.localStorage.setItem('informacionbancaria', JSON.stringify(informacionbancaria))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    step1ByDealer = async (telefono: any, email: any, password: any, tipoVerificacion: any) => {

        try {

            let data = {
                telefono: telefono,
                email: email,
                hash: password,
                tipoVerificacion: tipoVerificacion,
                country: 'Guatemala'
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistroUsuario + '/step1'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);
            console.log('req',req);
            if (req.error) throw new Error(req.message);

            return { error: req.error, message: '', result: req }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    step2ByDealer = async (codigoActivacion: any, idUser: any) => {

        try {

            let data = {
                codigoActivacion: codigoActivacion
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistroUsuario + '/step2Delears/' + idUser

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data);
            console.log("req",req);
            
            if (req.error) throw new Error(req.message);

            return { error: req.error, message: '', result: req }
        } catch (error: any) {
            console.log('Register service error2', error)
            return { error: true, message: error.message, result: null }
        }
    }


    step2 = async (codigoActivacion: any, idUser: any) => {

        try {

            let data = {
                codigoActivacion: codigoActivacion
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso2/' + idUser

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)
            if (req.error) throw new Error(req.result.message)

            let user = req.result.user
            let procesoregistro = req.result.procesoregistro

            window.localStorage.setItem('user', JSON.stringify(user))
            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    step3 = async (nombre: any, apellidoPaterno: any, apellidoMaterno: any, cui: any, idUser: any) => {

        try {
            let data = {
                nombre: nombre,
                apellidoPaterno: apellidoPaterno,
                apellidoMaterno: apellidoMaterno,
                cui: cui
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso3/' + idUser

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            let user = req.result.user
            let procesoregistro = req.result.procesoregistro

            window.localStorage.setItem('user', JSON.stringify(user))
            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }
    }


    step4 = async (nombreNegocio: any, numeroCasa: any, departamento: any, municipio: any, telefonoNegocio: any, nit: any, idUser: any, idNegocio: any) => {

        try {
            let data = {
                nombre: nombreNegocio,
                numeroCasa: numeroCasa,
                departamento: departamento,
                municipio: municipio,
                telefono: telefonoNegocio,
                nit: nit
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso4/' + idUser + '/' + idNegocio

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            let procesoregistro = req.result.procesoregistro
            let negocio = req.result.negocio

            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))
            window.localStorage.setItem('negocio', JSON.stringify(negocio))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    step5 = async (actividad: any, subActividad: any, idUser: any, idNegocio: any) => {

        try {
            let data = {
                actividadId: actividad,
                subActividadId: subActividad
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso5/' + idUser + '/' + idNegocio

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            let procesoregistro = req.result.procesoregistro
            let negocio = req.result.negocio

            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))
            window.localStorage.setItem('negocio', JSON.stringify(negocio))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }
    }


    step6 = async (banco: any, numeroCuenta: any, tipoCuenta: any, nombreCuenta: any, idUser: any, idNegocio: any) => {

        try {
            let data = {
                banco: banco,
                numeroCuenta: numeroCuenta,
                tipoCuenta: tipoCuenta,
                nombreCuenta: nombreCuenta
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRegistro + '/paso6/' + idUser + '/' + idNegocio

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.result.message)

            let procesoregistro = req.result.procesoregistro
            let informacionbancaria = req.result.informacionbancaria

            window.localStorage.setItem('procesoregistro', JSON.stringify(procesoregistro))
            window.localStorage.setItem('informacionbancaria', JSON.stringify(informacionbancaria))

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    guardarReferenciaDealer = async (idUsuario: number, codigoDealer: string, negocioId: string | number) => {

        try {
            let data = {
                idUsuario: idUsuario,
                codigoDealer: codigoDealer,
                negocioId: negocioId
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlDealers + '/guardarReferenciaDealer'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req }
        } catch (error: any) {
            console.log('Register service error', error)
            return { error: true, message: error.message, result: null }
        }

    }
}
