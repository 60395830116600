import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class LogsService {
    static instance = new LogsService();

    get = async (params: string = '') => {

        try {
        
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlLogs + '/getLogs' + params

            const httpInstance = new http();

            let req = await httpInstance.get(ruta, true)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result, totalPages: req.totalPages }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }
}