import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class invitacionService {
    static instance = new invitacionService();

    getInvitacion = async (codigoInvitacion: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/getInvitacion' + '/' + codigoInvitacion

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.invitacion, user: req.user }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    registroPaso1 = async (nombre: any, apellidoPaterno: any, apellidoMaterno: any, telefono: any, email: any, password: any, negocioId: any, rolId: any, codigoInvitacion: any, usuarioIdInvitado: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/registroPaso1'

            const httpInstance = new http();

            const data = {
                nombre,
                apellidoPaterno,
                apellidoMaterno,
                telefono,
                email,
                password,
                negocioId,
                rolId,
                codigoInvitacion,
                usuarioIdInvitado
            }

            let req = await httpInstance.post(ruta, data, false)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.userId }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    registroPaso2 = async (usuarioId: any, codigoActivacion: any, codigoInvitacion: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/registroPaso2'

            const httpInstance = new http();

            const data = {
                usuarioId,
                codigoActivacion,
                codigoInvitacion
            }

            let req = await httpInstance.post(ruta, data, false)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.invitacion }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    borrarPaso1 = async (codigoInvitacion: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/borrarPaso1'

            const data = {
                codigoInvitacion
            }

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.invitacion }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    codigoVerificacionSms = async (telefono: any, idUsuarioInvitado: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/codigoVerificacionSms'

            const data = {
                telefono,
                idUsuarioInvitado
            }

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '' }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    codigoVerificacionCorreo = async (email: any, idUsuarioInvitado: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlInvitacion + '/codigoVerificacionCorreo'

            const data = {
                email,
                idUsuarioInvitado
            }

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '' }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

}