/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as momentZone from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { List, RemoveRedEye } from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import {
  Backdrop,
  Chip,
  createStyles,
  Fade,
  FormControl,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
/**
 * Internal Libraries
 */
import { settlementStatus } from '../../resources/Statics';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
import LoadingButton from '../../components/LoadingButton';
/**
 * Interfaces
 */
import { ContactsInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '40vw',
    },
  }),
);

export default function LiquidacionesNegociosHonduras() {
  const { handleErrorMessage, handleActiveError, handleLoading, loading, handleSelectRecord } =
    useContext(GeneralContext);

  const classes = useStyles();
  const history = useHistory();

  const [settlements, setSettlements] = useState<ContactsInterface[]>([]);
  const [endDate, setEndDate] = useState(momentZone.tz('America/Guatemala').endOf('week').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(momentZone.tz('America/Guatemala').startOf('week').format('YYYY-MM-DD'));
  const [openModalTransactions, setOpenModalTransactions] = useState(false);
  const [files, setFiles] = useState<[]>([]);

  useEffect(() => {
    getSettlements();
  }, []);

  const getSettlements = () => {
    handleLoading(true);
    ReportesServices.instance
      .getSettlementsHonduras(startDate, endDate)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setSettlements(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const selectRecord = (record: any) => {
    const { id } = record;
    handleSelectRecord(record);
    history.push(`/admin-home/liquidaciones/negocios/${id}`);
  };

  const getSettlementFiles = (selectedSettlement: string | number) => {
    setOpenModalTransactions(true);
    handleLoading(true);
    ReportesServices.instance
      .getSettlementFiles(selectedSettlement)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setFiles(result);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const columns: GridColumns = [
    {
      field: 'Acciones',
      headerName: 'Acciones',
      width: 125,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', lineHeight: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <RemoveRedEye
              titleAccess='Ver'
              color='primary'
              style={{ lineHeight: 0 }}
              onClick={() => selectRecord(params.row)}
            ></RemoveRedEye>
          </div>
        );
      },
    },
    { field: 'id', headerName: 'ID', minWidth: 100 },
    { field: 'nombre', headerName: 'Código', flex: 1, minWidth: 150 },
    {
      field: 'correlativo',
      headerName: 'Correlativo',
      minWidth: 150,
    },
    {
      field: 'Reportes',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      align: 'center',
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => getSettlementFiles(params.row.id)}
            >
              <List />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.estado}
                disabled={params.row.estado === 'Exitoso' || params.row.estado === 'Terminado'}
                onChange={async (e: any) => {
                  handleLoading(true);
                  ReportesServices.instance
                    .updateSettlementState({ status: e.target.value }, params.row.id)
                    .then(response => {
                      const { error } = response;
                      if (!error) {
                        getSettlements();
                      }
                      handleLoading(false);
                    })
                    .catch(e => {
                      handleLoading(false);
                      handleErrorMessage('message');
                      handleActiveError(true);
                    });
                }}
                style={{ minWidth: 140, lineHeight: 'unset' }}
              >
                {settlementStatus.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                    >
                      <Chip
                        label={status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params?.row?.tipo ? 'INTERNO' : 'EXTERNO'}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Exportación',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaInicial',
      headerName: 'Fecha Inicio',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaInicial'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'fechaFinal',
      headerName: 'Fecha Final',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'fechaFinal'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'Acciones',
      headerName: 'Acciones',
      width: 125,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', lineHeight: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <RemoveRedEye
              titleAccess='Ver'
              color='primary'
              style={{ lineHeight: 0 }}
              onClick={() => selectRecord(params.row)}
            ></RemoveRedEye>
          </div>
        );
      },
    },
  ];

  const columnsFiles: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'nombre',
      headerName: 'Archivo',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            href={params.row.link}
            target={'_blank'}
          >
            {params.row.nombre}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
  ];

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ maxHeight: '100vh', overflow: 'auto' }}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid
          container
          style={{ padding: 10 }}
          alignItems='center'
        >
          <Grid
            item
            xs={6}
          >
            <Typography variant='h6'>Liquidaciones por Negocios Honduras</Typography>
          </Grid>

          <Grid
            item
            xs={2}
          >
            <TextField
              type={'date'}
              label='Fecha inicio'
              variant='outlined'
              value={startDate}
              onChange={field => setStartDate(field.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <TextField
              type={'date'}
              label='Fecha fin'
              variant='outlined'
              value={endDate}
              onChange={field => setEndDate(field.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <LoadingButton
              loading={loading}
              color='primary'
              variant='contained'
              title='Filtrar'
              onClick={getSettlements}
            />
          </Grid>
        </Grid>

        <Grid
          item
          className='HideScrollbars'
          style={{ height: 'calc(100vh - 225px)', width: 'auto' }}
        >
          <DataGrid
            rowHeight={50}
            rows={settlements}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={openModalTransactions}
        onClose={() => setOpenModalTransactions(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalTransactions}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '1rem', marginTop: '1rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
              >
                <Grid
                  item
                  style={{ height: 400, width: 'auto' }}
                >
                  <DataGrid
                    rows={files}
                    rowHeight={50}
                    loading={loading}
                    columns={columnsFiles}
                    rowsPerPageOptions={[25, 50, 100, 500, 1000]}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>

      <PageLoader loading={loading} />
    </Box>
  );
}
