import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class CrudService {

  private URL = ''
  private httpInstance = new http()

  constructor(urlCrud: string) {
    this.URL =  `${Rutas.instance.urlBase}${urlCrud}`;
  }

  static instance = (urlCrud: string) => new CrudService(urlCrud);

  getAll = async (params: any = "") => {
    try {
      const ruta = `${this.URL}/all/${params}`;
      let req = await this.httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('getAll '+ this.URL +' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
  
  getPaginationFilter = async (params: any = "") => {
    try {
      const ruta = `${this.URL}${params}`;
      let req = await this.httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('getPaginationFilter '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  get = async () => {
    try {
      const ruta = `${this.URL}`;
      let req = await this.httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result};
    } catch (error: any) {
      console.log('get '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getOne = async (id: number) => {
    try {
      const ruta = `${this.URL}/${id}`;
      let req = await this.httpInstance.get(ruta, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('getOne '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  deleteOne = async (id: number) => {
    try {
      const ruta = `${this.URL}/${id}`;
      let req = await this.httpInstance.delete(ruta, {}, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('deleteOne '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateOne = async (id: number, params: any) => {
    try {
      const ruta = `${this.URL}/${id}`;
      let req = await this.httpInstance.put(ruta, params, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('updateOne '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
  updateOneComplemento = async (id: number, params: any, urlComplemento: string) => {
    try {
      const ruta = `${this.URL}/${id}${urlComplemento}`;
      let req = await this.httpInstance.put(ruta, params, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('updateOne '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateOneExtra = async (id: number, params: any, extra: string) => {
    try {
      const ruta = `${this.URL}/${id}/${extra}`;
      let req = await this.httpInstance.put(ruta, params, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('updateOne '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  create = async (params: any) => {
    try {
      const ruta = this.URL;
      let req = await this.httpInstance.post(ruta, params, true);
      if (req.error) throw new Error(req.message);
      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Create '+this.URL+' service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  downloadExcelRows = async (startDateSettlement?: string, endDateSettlement?: string, searchValue?: string) => {
    try {
      const ruta = `${this.URL}/descargar/excel?search=${searchValue}`;;
      let req = await this.httpInstance.download(ruta, { startDateSettlement, endDateSettlement }, true);
      return { error: false, file: req };
    } catch (error) {
      console.log('downloadExcelRows service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };


}