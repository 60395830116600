const cardText = {
    button: {
      pending: 'PENDIENTE',
      process: 'EN PROCESO',
    },
  
    title: {
      limitless: '¡Tu limite se ha levantado!',
      limitedNoFormal: '¡Ya puedes transaccionar con el POS!',
      limitedLessThan6502: '¡Ya puedes transaccionar!',
      limitlessLessThan6502: '¡Ya puedes transaccionar!',
      limitlessNoFormalLessThan6502: '¡Ya puedes transaccionar!',
      limitedNoFormalLessThan6502: '¡Ya puedes transaccionar!',
      limitlessNoFormal: '¡Ya puedes transaccionar!',
      limited: '¡Ya puedes transaccionar con el POS!',
      pendingDocuments: 'Activación pendiente',
      documentsNotApproved: 'Activación pendiente',
      metamapInProcess: 'Activación pendiente',
      metamapNotStarted: 'Activación pendiente',
      allButPending: 'Activación pendiente',
    },
  
    body: {
      // ESTADOS DE LIMITE
      limitlessNoFormal:
        '¡Tu límite de links ha subido a {moneda}{limiteLink} mensuales y puede cobrar ilimitado con el POS! 💳 🚀',
      limitedNoFormal:
        'Ingresa a tu aplicación móvil de Paggo para subir documentos y habilitar links de Paggo. ¡Disfruta de transacciones ilimitadas con el POS! 💳',
      limitless:
        '¡Tu límite de links ha subido a {moneda}{limiteLink} mensuales y puede cobrar ilimitado con el POS! 💳 🚀',
      limited:
        'Ingresa a tu aplicación móvil de Paggo para subir documentos y habilitar links de Paggo. ¡Disfruta de transacciones ilimitadas con el POS! 💳',
  
      // ESTADOS DE LIMITE ID < 6502
      limitlessNoFormalLessThan6502:
        '¡Ya puedes transaccionar ilimitadamente con links y con tu POS! 💳 🚀',
  
      limitedNoFormalLessThan6502:
        'Tu límite mensual para links es de Q500 y Q2500 en POS. Aumenta el límite ingresando a tu aplcación móvil de Paggo. 💳🚀',
  
      limitlessLessThan6502:
        '¡Ya puedes transaccionar ilimitadamente con links y con tu POS! 💳 🚀',
  
      limitedLessThan6502:
        'Tu límite mensual para links es de {moneda}{limiteLink} e ilimitado en POS. Aumenta el límite ingresando a tu aplcación móvil de Paggo. 💳🚀',
  
      // ESTADOS DE DOCUMENTOS
      pendingDocuments: 'Envía tus documentos para activar tu cuenta.',
      documentsNotApproved:
        'Estamos verificando tus documentos para activar tu cuenta.',
  
      // ESTADOS DE METAMAP
      metamapInProcess: 'Estamos verificando tu identidad.',
      metamapNotStarted: '¡Completa tu verificación para realizar transacciones!',
  
      // TODOS LOS ESTADOS COMPLETADOS
      allButPending:
        'Estamos verificando todos tus datos para activar tu cuenta.',
    },
  };
  
  export default cardText;
  