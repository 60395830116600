import { ContactsInterface } from '../interfaces/Interfaces';
import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class ContactsService {
  static instance = new ContactsService();

  getContacts = async () => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlContacts}/`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: '',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get contacts with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  createContact = async (params: ContactsInterface) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlContacts}/create`;

      const httpInstance = new http();

      let { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Get contacts with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  deleteContact = async (params: ContactsInterface, contactId: number | undefined) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlContacts}/${contactId}`;

      const httpInstance = new http();

      let { error, message } = await httpInstance.delete(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Delete contact with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  updateContact = async (params: ContactsInterface, contactId: number | undefined) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlContacts}/${contactId}/edit`;

      const httpInstance = new http();

      let { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Update contacts with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };
}
