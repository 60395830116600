/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import * as momentZone from 'moment-timezone';
import { useState, useContext, useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
import PageLoader from '../../components/PageLoader';
import { Badge, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';

export default function CrearLiquidacion() {
  const { handleErrorMessage, handleActiveError, loading, handleLoading } = useContext(GeneralContext);

  //Filtros por dia
  let filtrosDia = [
    { id: 1, dia: 'Lunes', startDate: '', endDate: '', estado: false },
    { id: 2, dia: 'Martes', startDate: '', endDate: '', estado: false },
    { id: 3, dia: 'Miércoles', startDate: '', endDate: '', estado: false },
    { id: 4, dia: 'Jueves', startDate: '', endDate: '', estado: false },
    { id: 5, dia: 'Viernes', startDate: '', endDate: '', estado: false },
  ];

  interface RowDay {id: number, dia: string, diaNumber: number, fecha: string, label: string, 
    startDateToShow: string, endDateToShow: string, disabled: boolean}

  const startDateSettlementRef = useRef<TextFieldProps>(null);
  const endDateSettlementRef = useRef<TextFieldProps>(null);

  const startDate2SettlementRef = useRef<TextFieldProps>(null);
  const endDate2SettlementRef = useRef<TextFieldProps>(null);

  const [startDateSettlement, setStartDateSettlement] = useState<string | undefined>();
  const [endDateSettlement, setEndDateSettlement] = useState<string | undefined>();

  const [startDate2Settlement, setStartDate2Settlement] = useState<string | undefined>();
  const [endDate2Settlement, setEndDate2Settlement] = useState<string | undefined>();

  const [loadingDays, setLoadingDays] = useState<boolean>(false);
  const [daysSettlement, setDaysSettlement] = useState<[]>([])

  const [confirmLiquidacion, setConfirmLiquidacion] = useState<boolean>(false);
  const [selectDay, setSelectDay] = useState<RowDay>();

  const [confirmar, setConfirmar] = useState<string>('');
  const [tipo, setTipo] = useState<string>('');

  useEffect(() => {
    getDaysSettlements()
  }, [])
  

  const getSettlement = (type: string, index: number, fechaDia: string) => {
    handleLoading(true);
    ReportesServices.instance
      .getSettlement(type, index, fechaDia)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            console.log('response',response)
            handleActiveError(true);
            handleErrorMessage(response?.message || 'Liquidación anterior pendiente de procesar');
          }
          setConfirmLiquidacion(false)
          setConfirmar('')
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
        }
        handleLoading(false);
        setConfirmLiquidacion(false)
        setConfirmar('')
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
        setConfirmLiquidacion(false)
        setConfirmar('')
      });
  };

  const getDaysSettlements = () => {
    setLoadingDays(true);
    ReportesServices.instance
      .getDaysSettlement()
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setDaysSettlement(result);
        }
        setLoadingDays(false);
      })
      .catch(e => {
        setLoadingDays(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getParticipants = (type: string, index: number) => {
    handleLoading(true);
    ReportesServices.instance
      .getParticipants(type, index, startDate2Settlement, endDate2Settlement)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            handleActiveError(true);
          }
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const confirmModalLiquidacion = (filtro: RowDay, tipoReporte: string) => {
    setTipo(tipoReporte);
    setConfirmLiquidacion(true);
    setSelectDay(filtro);
  }

  const handleClose = () => {
    setConfirmar('')
    setConfirmLiquidacion(false);
  }

  const handleChange = (event: any) => {
    setConfirmar(event.target.value.toUpperCase());
  };

  const obtnerFiltrosDia = (tipoReporte: string) => {
    return (
      <>
        { !loadingDays ?  daysSettlement.map((
          filtro: RowDay) => {
            return (
              <Grid
                item
                style={{ marginTop: 5, textAlign: 'center' }}
                key={filtro.id}
              >
                
                  <Button
                    color='primary'
                    variant='contained'
                    size={filtro.id === 0 ? 'large' : filtro.id === 1 ? 'medium' : 'small'}
                    title={filtro.dia}
                    disabled={filtro.disabled}
                    onClick={() => confirmModalLiquidacion(filtro,tipoReporte) }
                  >
                    {filtro.dia}
                  </Button>
                
                <br/>
                <Chip className='text-center' label={filtro.id === 0 ? 'HOY' : filtro.label} size="small" color={filtro.id === 0 ? 'secondary' : 'default'} />
              </Grid>
            );
        })
        :
        (
        <Grid
            style={{ paddingTop: 10 }}
            direction='row'
            justifyContent='center'
            alignItems='center'
            container
            spacing={2}
          >
            {
              daysSettlement.map(() => {
                return (
                  
                    <Grid
                      item
                      lg={2}
                      xs={12}
                      sm={4}
                      style={{ marginTop: 5, textAlign: 'center' }}
                      key={0}
                    >
                      <Skeleton  width='100%' height={60} />
                    </Grid>
                );
              })
            }
        </Grid>
        )
        }
      </>     
    );
  };

  return (
    <Box
      display='flex'
      mt={1}
    >
      <Grid
        item
        sm={6}
        xs={12}
        container
        spacing={3}
        component={Paper}
        direction='column'
        style={{ padding: 10, margin: 10 }}
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            Participantes
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Exportar reporte de participantes (negocios)
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type={'date'}
            label='Fecha inicio'
            variant='outlined'
            inputRef={startDate2SettlementRef}
            value={startDate2Settlement}
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              setStartDate2Settlement(e.target.value);
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type={'date'}
            label='Fecha fin'
            variant='outlined'
            value={endDate2Settlement}
            inputRef={endDate2SettlementRef}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDate2Settlement(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => getParticipants('interno', momentZone.tz('America/Guatemala').day())}
          >
            Participantes (Interno)
          </Button>
        </Grid>

        <Grid item>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => getParticipants('externo', momentZone.tz('America/Guatemala').day())}
          >
            Participantes (Externo)
          </Button>
        </Grid>

        <Grid
          item
          hidden
        >
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => {
              setEndDate2Settlement(undefined);
              setStartDate2Settlement(undefined);
              if (startDate2SettlementRef.current) startDate2SettlementRef.current.value = '';
              if (endDate2SettlementRef.current) endDate2SettlementRef.current.value = '';
            }}
          >
            Limpiar Filtros
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={3}
        direction='column'
        component={Paper}
        style={{ padding: 10, margin: 10 }}
        sm={6}
        xs={12}
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            Reporte de liquidaciones internas/externas
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Exportar reporte de liquidaciones
          </Typography>
        </Grid>

        <Grid
          item
          hidden
        >
          <TextField
            fullWidth
            type={'date'}
            label='Fecha inicio'
            variant='outlined'
            inputRef={startDateSettlementRef}
            value={startDateSettlement}
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              setStartDateSettlement(e.target.value);
            }}
          />
        </Grid>

        <Grid
          item
          hidden
        >
          <TextField
            fullWidth
            type={'date'}
            label='Fecha fin'
            variant='outlined'
            value={endDateSettlement}
            inputRef={endDateSettlementRef}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDateSettlement(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Typography
            paragraph
            variant='subtitle2'
          >
            Liquidación interna
          </Typography>

          <Grid
            style={{ paddingTop: 10 }}
            direction='row'
            justifyContent='center'
            alignItems='center'
            container
            spacing={2}
          >
            {obtnerFiltrosDia('interno')}
          </Grid>
        </Grid>

        <Grid item>
          <Typography
            paragraph
            variant='subtitle2'
          >
            Liquidación externa
          </Typography>

          <Grid
            style={{ paddingTop: 10 }}
            direction='row'
            justifyContent='center'
            alignItems='center'
            container
            spacing={2}
          >
            {obtnerFiltrosDia('externo')}
          </Grid>
        </Grid>

        <Grid
          item
          hidden
        >
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => {
              setEndDateSettlement(undefined);
              setStartDateSettlement(undefined);
              if (startDateSettlementRef.current) startDateSettlementRef.current.value = '';
              if (endDateSettlementRef.current) endDateSettlementRef.current.value = '';
            }}
          >
            Limpiar Filtros
          </Button>
        </Grid>
      </Grid>

      <Dialog  open={confirmLiquidacion} onClose={handleClose}>
        <DialogTitle>CONFIRMACIÓN DE LIQUIDACIÓN {tipo === 'interno' ? 'INTERNA' : 'EXTERNA'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info">
              <AlertTitle>Rango seleccionado:</AlertTitle>
              <div><strong>Desde:</strong> {selectDay?.startDateToShow}</div>
              <strong>Hasta:</strong> {selectDay?.endDateToShow}
            </Alert>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="confirmacion"
            name="confirmacion"
            label="Ingrese la palabra 'CONFIRMAR'"
            type="text"
            value={confirmar}
            fullWidth
            variant="standard"
            error={confirmar !== 'CONFIRMAR'}
            onChange={handleChange}
          />
          {( selectDay?.id !== 0 &&
            <Alert severity="error">
                <AlertTitle>
                  <small>Recuerda que generarás una liquidación del día que no es el actual.</small>
                </AlertTitle>
              </Alert>
          )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button disabled={confirmar === 'CONFIRMAR' ? false : true} onClick={() => getSettlement(tipo, selectDay?.diaNumber as number, selectDay?.fecha as string)}>Continuar</Button>
        </DialogActions>
      </Dialog>


      <PageLoader loading={loading} />
    </Box>
  );
}
