
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import { Rutas } from '../resources/Statics';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { Button, Chip, DialogContent, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridRowParams } from '@material-ui/data-grid';
import React from 'react';
import * as momentZone from 'moment-timezone';
import { Cancel, Check, CloudUpload, OpenInBrowser, PanTool } from '@material-ui/icons';
import NegociosServices from '../services/NegociosServices';
import { Pagination } from '@material-ui/lab';
import DocumentosService from '../services/DocumentosService';
import localStorage from '../libs/localStorage'
import RegisterService from '../services/RegisterService';
import { InformacionBancaria } from '../interfaces/Interfaces';

type ModalRevisionDocsProps = {
    isOpen: boolean;
    documentoId: string;
    negocioId: string;
    negocioName: string;
    negocios: any[];
    page: number,
    totalPages: number,
    isLoadinPage:boolean,
    setPage:Dispatch<SetStateAction<number>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}
  
export default function ModalRevisionDocumentos({documentoId = '', setIsOpen, isOpen , negocioId = '', negocioName ='', negocios, page, setPage, totalPages, isLoadinPage }: ModalRevisionDocsProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            },
            paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '60vw',
            },
            imageList: {
            width: 'auto',
            height: 600,
            },
            modalLiquidacion: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '50vw',
            height: 300,
            },
            transitionGrid: {
            transition: 'margin-right 0.3s ease-in-out',  // Cambiado a 'right'
            position: 'fixed',  // Agregado para fijar la posición
            top: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
            bottom: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
            },
            archivos: {
            marginRight: 0,  // Inicialmente oculto
            },
            button: {
            margin: theme.spacing(1),
            },
        }),
    );
    const classes = useStyles();
    const [rows, setRows] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [arrayImages, setArrayImages] = React.useState<any[]>([]);
    const [openImagesModal, setOpenImagesModal] = React.useState(false);
    const [ documentosDetails, setDocumentosDetails] = React.useState<any[]>([])
    const [index, setIndex] =  React.useState(0);
    const [position, setPosition] =  React.useState(0);

    const [negocioSelect, setNegocioSelect] =  React.useState<any>(negocios[0])
    const [negocioSelectId, setNegocioSelectId] =  React.useState<any>(negocios[0].NegocioId)

    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [documentoIdSelected, setDocumentoIdSelected] = React.useState(0);
    const [messageRejected, setMessageRejected] = React.useState<string>('');
    const [inicioRevison, setInicioRevision] = React.useState(new Date())
    const [tiempoRevision, setTiempoRevision] = React.useState(0)
    const [usuarioIdRevision, setUsuarioIdRevision] = React.useState<number>()
    const [informacionNegocio , setInformacionNegocion ] = React.useState<any>()
    const [isBack , setIsBack ] = React.useState(false)
    const [isNext, setIsNext ] = React.useState(false)
    const [negociosDocs , setNegociosDocs] =  React.useState<any[]>([]);
    const [informacionBancaria, setInformacionBancaria] = React.useState<InformacionBancaria>({
      id: 0,
      banco: '',
      numeroCuenta: '',
      tipoCuenta: '',
      nombreCuenta: '',
      NegocioId: 0,
    });

    useEffect(() => {
      setLoading(true)
      const getUser = async () => {
        let user = await localStorage.instance.getUserParseado() ;
        setUsuarioIdRevision(user?.id)
      }
      getUser()
      console.log(negocioSelectId)
      const unique = negocios.map(item => item.NegocioId)
      .filter((value, index, self) => self.indexOf(value) === index)
      setNegociosDocs(unique)
      const idSelect = unique[0]
      const negocio = negocios.find(neg => neg.NegocioId === idSelect)

      setNegocioSelect(negocio)
      getDocumentos(idSelect);
      setLoading(false)

    }, [])

    useEffect(() => {
      const idSelect = negociosDocs[position]
      const negocio = negocios.find(neg => neg.NegocioId === idSelect)
      setNegocioSelect(negocio)
      if (idSelect) {
        getDocumentos(idSelect);
      }
      setLoading(false)

    }, [position])

    useEffect(() => {
      const unique = negocios.map(item => item.NegocioId)
      .filter((value, index, self) => self.indexOf(value) === index)
      setNegociosDocs(unique)
      if (isNext) {
        setPosition(0)
        setIsNext(false)
        setIsBack(false)
      } else if (isBack) {
        setPosition(unique.length - 1)
        setIsNext(false)
        setIsBack(false)
      }
      setLoading(false)

    }, [negocios])

    const getDiasEnCola = (startDateStr: string, endDateStr:string) =>{
      const startDate = new Date(startDateStr);
      const endDate = new Date();
      const nextDay = new Date(startDate);
      let cnt = 0;
      do {
          cnt += (nextDay.getDay() >= 1 && nextDay.getDay() <= 5) ? 1 : 0;
          nextDay.setDate(nextDay.getDate() + 1);
      } while (nextDay <= endDate);
      return cnt
    }

    const getActivities = async (infoNegocio:any ): Promise<any> => {
        let req = await RegisterService.instance.getActivitiesByCategoryVisa(negocioSelect?.negocio.tipoNegocio?.categoriaVisa);
        req.result = req.result.filter((item: any) => item.categoriaVisa === negocioSelect?.negocio.tipoNegocio?.categoriaVisa);
        const actividad = req.result.find((act:any) => act.id === infoNegocio?.ActividadId)

        const reqsub = await RegisterService.instance.getSubActivitiesByActivityId(infoNegocio?.ActividadId);
        const subactividades = reqsub.result.find((subAct : any) => subAct.id === infoNegocio?.SubActividadId)
        return {
          subActividad: subactividades.nombre,
          actividad: actividad.nombre
        }
    }

    const columns = [

        {
          field: 'acciones',
          headerName: 'Documentos',
          sortable: false,
          width: 150,
          disableClickEventBubbling: true,
          renderCell: (params: any) => {
            return (
              <div
                className='d-flex justify-content-between align-items-center'
                style={{ cursor: 'pointer' }}
              >
                <IconButton
                  size='small'
                  onClick={async () => {
                    await openFiles(params.row)
                  }}
                >
                  <OpenInBrowser />
                </IconButton>
              </div>
            );
          },
        },
        {
          field: 'status',
          headerName: 'Estado',
          sortable: false,
          width: 140,
          disableClickEventBubbling: true,
          renderCell: (params: any) => {
            return renderEstado(params.id, params.id, params.value);
          },
        },
        {
          field: 'docTipoNegocio',
          headerName: 'Tipo',
          width: 230,
          renderCell: (params: any) => {
            return <span>                                        
              {params.formattedValue.nombre === "foto_negocio" ? 'foto_selfie_negocio' : params.formattedValue.nombre}
            </span>;
          },
        },
        {
          field: 'createdAt',
          headerName: 'Fecha Subido',
          width: 180,
    
          renderCell: (params: any) => {
            const fechaTZ6 = momentZone
              .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
              .locale('es')
              .format('YYYY-MM-DD HH:mm A');
            return <span>{fechaTZ6}</span>;
          },
        },
        {
          field: 'dayOnQueque',
          headerName: 'Dias en cola de revision',
          width: 180,
          
          renderCell: (params: any) => {    
              const days = getDiasEnCola(params.getValue(params.id, 'createdAt'), 'today' )
            return <span>{days}</span>;
          },
        },
        {
          field: 'observaciones',
          headerName: 'Observaciones',
          sortable: false,
          width: 200,
          disableClickEventBubbling: true,
        },
    
        {
          field: 'quitarLimite',
          headerName: 'Obligatorio',
          sortable: false,
          width: 140,
          disableClickEventBubbling: true,
          renderCell: (params: any) => (params.value ? 'No' : 'Si'),
        },
        {
          field: 'subirloNegocio',
          headerName: 'Responsabilidad',
          sortable: false,
          width: 180,
          disableClickEventBubbling: true,
          renderCell: (params: any) => {
            return (
              <Select
                fullWidth
                onChange={ev => {
                  //updateSubirloNegocio(params.id, ev.target.value === 'negocio', params.row.status);
                }}
                value={params.value ? 'negocio' : 'afiliador'}
                inputProps={{
                  name: 'subirloNegocio',
                  id: 'subirloNegocio-select',
                }}
              >
                <MenuItem value='negocio'>Negocio</MenuItem>
                <MenuItem value='afiliador'>Afiliador</MenuItem>
              </Select>
            );
          },
        },
        { field: 'id', headerName: 'Id', width: 100 },
    
      ];

    const getDocumentos = async (id: number) => {
      setLoading(true);
      NegociosServices.instance
        .getNewDocuments(id)
        .then( response => {
          const { error, result, message } = response;
          
          const uploadeDocuments = result.filter((value: any) => value.status === 'subido')
          if (!error) setRows(uploadeDocuments);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
        });
    };

    const handleOpenModal = () => {
      setOpen(true);
      setMessageRejected('');
    };

    function renderEstado(id: number, docTipoId: number, value: any) {
      let color = 'red';
      switch (value) {
        case 'subido':
          color = '#5166AF';
          break;
        case 'aprobado':
          color = 'green';
          break;
        case 'rechazado':
          color = 'red';
          break;
        case 'pendiente':
          color = '#FCBF49';
          break;
        default:
          color = '#FCBF49';
          break;
      }
      return (
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <Chip
            style={{ backgroundColor: color }}
            label={value}
            clickable
            color='primary'
            onClick={() => {
              setDocumentoIdSelected(id);
              handleOpenModal();
            }}
          />
        </div>
      );
    }

    const handleCloseModal = () => {
      setOpen(false);
    }

    const openFiles = async (row: any) => {
      const now = new Date()
      setInicioRevision(now)
      await getInfoDocuments(row.docTipoNegocio?.nombre)
      try {
        if (row.link) {
          try {
            const link = JSON.parse(row.link);
            if (Array.isArray(link)) {
              setArrayImages(link);
              setOpenImagesModal(true);
            } else {
              setArrayImages([link]);
              setOpenImagesModal(true);
            }
          } catch (error) {
            setArrayImages([row.link]);
            setOpenImagesModal(true);
          }
        } else if (row.documento && row.documento.link) {
          try {
            const link = JSON.parse(row.documento.link);
            if (Array.isArray(link)) {
              setArrayImages(link);
              setOpenImagesModal(true);
            } else {
              setArrayImages([row.documento.link]);
              setOpenImagesModal(true);
            }
          } catch (error) {
            setArrayImages([row.documento.link]);
            setOpenImagesModal(true);
          }
        } else if (
          row.LinksDocumentoNegocio &&
          Array.isArray(row.LinksDocumentoNegocio) &&
          row.LinksDocumentoNegocio.length > 0
        ) {
          try {
            const link = JSON.parse(row.LinksDocumentoNegocio[row.LinksDocumentoNegocio.length - 1].links);
            if (Array.isArray(link)) {
              setArrayImages(link);
              setOpenImagesModal(true);
            } else {
              setArrayImages([link]);
              setOpenImagesModal(true);
            }
          } catch (error) {
            // setArrayImages([row.link]);
            // setOpenImagesModal(true);
          }
        } else {
          setArrayImages([]);
          setOpenImagesModal(true);
        }
      } catch (error) {
        console.log(error);
        //setArrayImages([items.row.link])
        //setOpenImagesModal(true)
        //alert("No se pudieron abrir las imagenes")
      }
    };

    const openFile = (item: any) => {
      try {
        window.open(item, '_blank');
      } catch (error) {
        console.log(error);
        alert('No se pudo abrir el archivo');
      }
    };

    const cambiarEstadoDocumento = (estado: number, message?: string) => {
      setShow(false);
      const inicioRev = momentZone.tz(inicioRevison, 'America/Guatemala')
      const finRevision =  momentZone.tz(new Date(), 'America/Guatemala')
      setTiempoRevision(finRevision.diff(inicioRev, 'minutes'))
      console.log(documentoIdSelected)
      if (documentoIdSelected > 0) {
        setLoading(true);
        DocumentosService.instance
          .updateNewDocumentStatus(documentoIdSelected, {
            negocioId:  negocioSelect?.NegocioId ? negocioSelect?.NegocioId : negocioSelectId,
            estado,
            message: message ?? '',
            tiempoRevision: tiempoRevision,
            usuarioRevisionId: usuarioIdRevision 
          })
          .then(response => {
            const { error, message } = response;
  
            if (!error) {
              if (negocioSelect?.NegocioId) {
                getDocumentos(negocioSelect?.NegocioId);

              } else {
                getDocumentos(negocioSelectId);

              }
              setOpen(false);
              // handleSuccessMessage(message);
              // handleActiveSuccess(true);
            } else {
              // handleErrorMessage(message);
              // handleActiveError(true);
            }
            setLoading(false);
          })
          .catch(e => {
            setLoading(false);
            // handleErrorMessage(e.message);
            // handleActiveError(true);
          });
      }
    };

    const handleChangeMessage = (e: any) => {
      setMessageRejected(e.target.value);
    };


  

    const getInformacionBancaria = async(id: number) => {
      //setLoading(true);
      NegociosServices.instance
        .getInformacionBancaria(id)
        .then(response => {
          const { error, result, message } = response;
          if (!error) {
            result.banco = result.banco.toLowerCase()
            result.tipoCuenta = result.tipoCuenta.toLowerCase()
            setInformacionBancaria(result);
          } 
          //setLoading(false);
        })
        .catch(e => {
          setLoading(false);
        });
    };
  


    const getInfoDocuments =  async (type: any ) => {
      let docDetails: any[] = []
      if (type === 'rtu') {
        const negocioId = negocioSelect?.NegocioId || negocioSelectId
        const negocioInfo =  await NegociosServices.instance.getById(negocioId)
        setInformacionNegocion(negocioInfo.result[0])
        const activityInfo = await getActivities(negocioInfo.result[0])
        docDetails = [
            {
              key: 'Nombre del Solicitante',
              value: negocioSelect.negocio.nombre
            },
            {
              key: 'Razon Social',
              value: negocioSelect.negocio.razonSocial
            },
            {
              key: 'NIT',
              value: negocioSelect.negocio.nit
            },
            {
              key: 'Régimen',
              value: negocioSelect.negocio?.tipoNegocio?.nombre || ''
            },
            {
              key: 'Actividad económica',
              value: activityInfo.actividad
            },
            {
              key: 'Subactividad económica',
              value: activityInfo.subActividad
            },
          ]
      } else if (type === 'cuenta_bancaria') {
        const negocioId = negocioSelect?.NegocioId || negocioSelectId

        getInformacionBancaria(negocioId)

        docDetails = [
          {
            key: 'Nombre del cuentahabiente',
            value: informacionBancaria.nombreCuenta,
          },
          {
            key: 'Número de cuenta',
            value: informacionBancaria.numeroCuenta
          },
          {
            key: 'Tipo de cuenta',
            value: informacionBancaria.tipoCuenta
          }
          
        ]
      } else if (type === 'recibo'){
        const negocioId = negocioSelect?.NegocioId  || negocioSelectId
        const negocioInfo = await NegociosServices.instance.getById(negocioId)
        setInformacionNegocion(negocioInfo.result[0])

        docDetails = [
          {
            key: 'Dirección',
            value: negocioInfo.result[0]?.numeroCasa || ''
          },
          {
            key: 'Departamento',
            value: negocioInfo.result[0]?.departamento || ''
          },
          {
            key: 'Municipio',
            value: negocioInfo.result[0]?.municipio || ''
          },
        ]
      } else if ( type === 'acta'){
        const negocioId = negocioSelect?.NegocioId || negocioSelectId
        const negocioInfo =  await NegociosServices.instance.getById(negocioId)
        setInformacionNegocion(negocioInfo.result[0])
        const activityInfo = await getActivities(negocioInfo.result[0])

        docDetails = [
          {
            key: 'Actividad económica',
            value: activityInfo.actividad
          },
          {
            key: 'Subactividad económica',
            value: activityInfo.subActividad
          },
        
          {
            key: 'Razon Social',
            value: negocioSelect.negocio.razonSocial
          },
          {
            key: 'NIT',
            value: negocioSelect.negocio.nit
          },
        ]
  
      }
        setDocumentosDetails(docDetails)
      }
    
    const siguienteNegocio = () => {
      setLoading(true)
      const chanceNext = position + 1
      if (chanceNext <= negociosDocs.length - 1) {
        setPosition(chanceNext)
      } else {
        setPage(page + 1)
        setIsNext(true)
      }
    }

    const regresarNegocio = () => {
      setLoading(true)
      if (position > 0) {
        setPosition(position - 1)
      } else {
        setPosition(0)
        if (page > 1 ) {
          setIsBack(true)
          setPage(page - 1)
        } else {
          setIsBack(true)
          setPage(1)
        }
      }
    }

    return (
      <>
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={() => { setIsOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Fade in={isOpen}>
            <div className={classes.paper}>
                <h2 id='transition-modal-title'>Revision de Documentos Negocio: {loading || isLoadinPage? 'Cargando...' : negocioSelect?.negocio?.nombre ? negocioSelect?.negocio?.nombre : negocioName  } </h2>

                <Grid
                    item
                    style={{ height: 300, width: 'auto',  margin:10}}
                >
                    <DataGrid
                        isRowSelectable={(params: GridRowParams) => false}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        rowHeight={38}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </Grid>
                <Button
                      // component={Link}
                      //to='negocio'
                      fullWidth
                      size='small'
                      style={{ width: '100px', margin:5 }}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        regresarNegocio()
                      }}
                      disabled={(position === 0 && page === 1)|| loading }
                    >
                      Regresar
                    </Button>
                <Button
                      // component={Link}
                      //to='negocio'
                      fullWidth
                      size='small'
                      style={{ width: '100px', margin:5 }}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        siguienteNegocio()
                      }}
                      disabled={ loading || (page === totalPages && position === negociosDocs.length - 1 )}
                    >
                      Siguiente
                    </Button>
               
            </div>
            </Fade>
            
        </Modal>
        
        <Modal
          className={classes.modal}
          open={openImagesModal}
          onClose={() => {
            setOpenImagesModal(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}

        >
          <Fade in={openImagesModal}>
            <div className={classes.paper}>
              <h2 id='transition-modal-title'>Documentos</h2>
              <p id='transition-modal-description'></p>
              <DialogContent dividers>

                <Grid container spacing={3} >
                  <Grid style={{padding: '2px'}} item xs={12} md={3}>
                    <>
                      {documentosDetails && documentosDetails.map((item, index) => (
                        <Grid key={index} item xs={12}>
                          <Paper key={index+'paper'} style={{
                                padding: 10,
                                textAlign: 'left',
                                marginTop:10
                            }}>
                            <Typography key={index+'label'} variant='subtitle2' >
                              <strong>{item?.key}:</strong>
                            </Typography>
                            <Typography key={index+'value'} variant='body2' >
                              {item.value}
                            </Typography>
                          </Paper>
                        </Grid>                                        
                      ))}
                    </>
                  </Grid>


                  <Grid item xs={12} md={9}>
                  <ImageList
                  rowHeight={160}
                  className={classes.imageList}
                  cols={1}
                >
                  {arrayImages.map((item: any) => (
                    <ImageListItem
                      key={item}
                      cols={1}
                      rows={4}
                    >
                      {item.includes('pdf') ? (
                        <iframe
                          src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                          style={{ width: '100%', height: '600px' }}
                          key={item}
                          title="PDF Viewer"

                        />
                      ) : (
                        <img
                          style={{ width: '600px' }}
                          src={item}
                          onClick={() => openFile(item)}
                        />
                      )}

                    </ImageListItem>
                  ))}
                </ImageList>
                  </Grid>

                </Grid>
              </DialogContent>

            </div>
          </Fade>
        </Modal>

        <Modal
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Cambiar estado</h2>
            <p id='transition-modal-description'></p>
            <List
              component='nav'
              aria-label='secondary mailbox folders'
            >
              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(4, '')}
              >
                <ListItemIcon>
                  <PanTool style={{ color: '#FCBF49' }} />
                </ListItemIcon>
                <ListItemText primary='Pendiente' />
              </ListItem>

              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(1, '')}
              >
                <ListItemIcon>
                  <CloudUpload style={{ color: '#5166AF' }} />
                </ListItemIcon>
                <ListItemText primary='Subido' />
              </ListItem>

              <ListItem
                button
                onClick={() => cambiarEstadoDocumento(2, '')}
              >
                <ListItemIcon>
                  <Check style={{ color: 'green' }} />
                </ListItemIcon>
                <ListItemText primary='Aprobado' />
              </ListItem>

              <ListItem
                button
                onClick={() => setShow(true)}
              >
                <ListItemIcon>
                  <Cancel style={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText primary='Rechazado' />
              </ListItem>

              {show ? (
                <ListItem>
                  <Grid container>
                    <TextField
                      label='Motivo de rechazo'
                      variant='outlined'
                      fullWidth
                      onChange={handleChangeMessage}
                    />
                  </Grid>

                  <Button
                    disabled={messageRejected.length > 0 ? false : true}
                    variant='contained'
                    color='primary'
                    style={{ margin: 10 }}
                    onClick={() => cambiarEstadoDocumento(3, messageRejected)}
                  >
                    Guardar
                  </Button>
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </div>
        </Fade>
      </Modal>
      </>
    


    );
}
