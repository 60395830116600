import React, { useState } from 'react';

import { Box, Button, Chip, Grid, Paper, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import * as momentZone from 'moment-timezone';

import PageLoader from '../../components/PageLoader';
import { useFacturacionNegocios } from './hooks';

interface FacturacionNegociosProps {}

const PAGE_SIZE = 10;
export const FacturacionNegocios: React.FC<FacturacionNegociosProps> = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const changePage = (_e: unknown, page: number) => setPageNumber(page);

  const { loading, facturacionNegocios } = useFacturacionNegocios();

  const totalPages = Math.ceil(facturacionNegocios.length / PAGE_SIZE);

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
    >
      <Typography
        variant='h4'
        style={{ fontWeight: 'bold', fontSize: '1.4em' }}
      >
        Facturación de negocios
      </Typography>

      <Grid
        container
        direction='column'
      >
        <Grid
          item
          style={{ height: '100%', marginTop: 20, width: 'auto' }}
        >
          <DataGrid
            sortModel={[{ field: 'estado', sort: 'asc'  }]}
            loading={loading}
            rows={facturacionNegocios}
            columns={[
              { field: 'id', headerName: 'id', width: 100 },
              {
                field: 'NegocioId',
                minWidth: 150,
                headerName: 'Negocio ID',
              },
              {
                field: 'estado',
                headerName: 'Estado',
                width: 150,
                renderCell: params => (
                  <Chip
                    label={params.value}
                    color={params.value === 'habilitado' ? 'primary' : 'secondary'}
                  />
                ),
              },
              {
                field: 'firma',
                headerName: 'Firma',
                minWidth: 150,
                renderCell: params => (
                  <a
                    target='_blank'
                    href={(params.value || '').toString()}
                  >
                    {params.value}
                  </a>
                ),
              },
              {
                field: 'firmaPassword',
                headerName: 'Contraseña de firma',
                minWidth: 200,
              },
              {
                field: 'afiliacionIVA',
                headerName: 'Regimen ',
                minWidth: 160,
              },
              {
                field: 'createdAt',
                headerName: 'Fecha de creación',
                renderCell: (params: any) => {
                  const fechaTZ6 = momentZone
                    .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
                    .locale('es')
                    .format('YYYY-MM-DD HH:mm A');
                  return <span>{fechaTZ6}</span>;
                },
                minWidth: 220,
              },
              {
                field: 'actions',
                headerName: 'Acciones',
                renderCell: (params: any) => (
                  <Button
                    variant='contained'
                    color='inherit'
                    size='small'
                    onClick={() => window.open(`/admin-home/negocios/${params.getValue(params.id, 'NegocioId')}`)}
                  >
                    Ver más
                  </Button>
                ),
                minWidth: 220,
              },
            ]}
            rowHeight={50}
            paginationMode='client'
            pageSize={PAGE_SIZE}
            page={pageNumber - 1}
            rowCount={totalPages * PAGE_SIZE}
            hideFooterPagination
            hideFooter
            autoHeight
          />
          <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              shape='rounded'
              size='medium'
              variant='outlined'
              color='primary'
              style={{ padding: '8px', textAlign: 'center' }}
              onChange={changePage}
            />
          </div>
        </Grid>
      </Grid>
      <PageLoader loading={loading} />
    </Box>
  );
};
