import {useState } from 'react'
import CrudDataGrid from '../Crud/CrudDataGrid';
import { Rutas, estadosColorEnlace } from '../../resources/Statics';
import momentZone from 'moment-timezone';

const TransaccionesEfectivo = () => {
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.row['createdAt'], 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{ params.row['createdAt'] ?  fechaTZ6 : ''}</span>;
      },
    },
      {
          field: 'concepto',
          headerName: 'Concepto',
          sortable: false,
          width: 270,
          disableClickEventBubbling: true,
      },
      
      {
        field: 'fecha',
        headerName: 'Fecha de pago',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          const fechaTZ6 = momentZone
            .tz(params.row['fecha'], 'America/Guatemala')
            .locale('es')
            .format('YYYY-MM-DD HH:mm A');
          return <span>{ params.row['fecha'] ?  fechaTZ6 : ''}</span>;
        },
    },
      {
        field: 'monto',
        headerName: 'Monto',
        sortable: false,
        width: 180,
        disableClickEventBubbling: true,
      },
      {
        field: 'nombreCliente',
        headerName: 'Usuario',
        sortable: false,
        width: 240,
        disableClickEventBubbling: true,
      }
    ];
    
  return (
    <CrudDataGrid title='EFECTIVO' descargar refresh={refresh} apiEndpoint={Rutas.instance.urlPagoEfectivo}  columns={columns} placeholder='Transaccion, monto, usuario' />
  )
}

export default TransaccionesEfectivo