/**
 * External Libraries
 */
import { read, utils } from 'xlsx';
import Box from '@material-ui/core/Box';
import momentZone from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Spreadsheet, { CellBase, Matrix } from 'react-spreadsheet';
import { useContext, useEffect, useState, BaseSyntheticEvent } from 'react';
import { DataGrid, GridCellParams, GridRowData, GridRowId, GridRowParams, GridToolbar } from '@material-ui/data-grid';
import {
  Backdrop,
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
/**
 * Internal Libraries
 */
import { Rutas, mposInventoryStatus } from '../../resources/Statics';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import MposInventoryService from '../../services/MposInventoryService';
/**
 * Interfaces
 */
import { FileDataMposInventory, MposInventoryEditMasivoInterface, MposInventoryInterface } from '../../interfaces/Interfaces';
import { Edit, Delete, History, Add, FindInPageTwoTone } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import RazonesService from '../../services/RazonesService';
import ValidationFields from '../../libs/validationFields';
import CrudService from '../../services/CrudService';
import ModalConfirm from '../../components/ModalConfirm';
import ModalRazonDocumentos from '../../components/ModalRazonDocumentos';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '75vw',
    },
    paper_2: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      minWidth: '30vw',
    },
    imageList: {
      width: 500,
      height: 450,
    },
  }),
);

export default function PosInventory() {
  const { handleErrorMessage, handleActiveError, handleLoading, loading, selectedRecord, handleSuccessMessage, handleActiveSuccess, handleSelectRecord } =
    useContext(GeneralContext);

  let history = useHistory();
  const classes = useStyles();

  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [openModalMposInventoryEdit, setOpenModalMposInventoryEdit] = useState(false);

  const [mposInventoryData, setMposInventoryData] = useState<[]>([]);
  const [rowsSeleccionados, setRowsSeleccionados] = useState<[]>([]);

  const [fileData, setFileData] = useState<{ mposInventory: FileDataMposInventory[] }>({
    mposInventory: [],
  });

  const [dataToShow, setDataToShow] = useState<Matrix<CellBase>>([]);

  const [mposInventoryValues, setMposInventoryValues] = useState();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmEditMasivo, setConfirmEditMasivo] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [rowsHistorialEstados, setRowsHistorialEstados] = useState([]);
  const [openHistorialEstados, setOpenHistorialEstados] = useState(false);
  const [openModalEditMasivo, setOpenModalEditMasivo] = useState(false);
  const [openModalUbicacionEdit, setOpenModalUbicacionEdit] = useState(false);
  const [modalConfirmarEliminarSeleccionados, setModalConfirmarEliminarSeleccionados] = useState(false);
  const [confirmDeleteMasivo, setConfirmDeleteMasivo] = useState(false);
  const [ubicacionIdACambiar, setUbicacionIdACambiar] = useState(0);
  const [ubicacionACambiar, setUbicacionACambiar] = useState('');
  const [idInventary, setIdInventary] = useState(0);  
  const [arrayImages, setArrayImages] = useState<any[]>([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);

  const [values, setValues] = useState<MposInventoryEditMasivoInterface>({
    estado: '',
    notas: undefined,
    fechaEntrega: new Date(),
  });

  const [bodyUbicacion, setBodyUbicacion] = useState({
    UbicacionId: 0,
    razon: '',
    documentos: [],
  });

  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string}[]>([])
  const [lotes, setLotes] = useState<{id: number, nombre: string}[]>([])

  useEffect(() => {
    getUbicaciones()
    getLotes()
  }, [])
  

  const getUbicaciones = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getLotes = () => {
    const crudService = CrudService.instance(Rutas.instance.urlLotesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setLotes(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  useEffect(() => {
    getInventoryMpos();
  }, []);

  const editRecord = (record: any) => {
    const { id } = record;
    handleSelectRecord({});
    history.push(`/admin-home/inventario/pos/${id}`);
  };

  const deleteRecord = (record: any) => {
    handleSelectRecord(record);
    setConfirmDelete(true);
  };

  const confirmDeleteRecord = async () => {
    handleLoading(true);
    MposInventoryService
        .delete(
          selectedRecord.id,
        )
        .then(response => {
          const { error, message } = response;
          if (!error) {
            getInventoryMpos();
            handleActiveSuccess(true);
            handleSuccessMessage(message);
          }
          handleLoading(false);
          setConfirmDelete(false);
        })
        .catch(e => {
          handleLoading(false);
          handleActiveError(true);
          handleErrorMessage(e.message);
          setConfirmDelete(false);
        });
  }

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      getInventoryMpos();
    }
  }

  const filtrar = () => {
    getInventoryMpos();
  };

  const verHistorial = async (id: any, origenLike='mpos_inventario_estados') => {
    const params = '?origenLike='+origenLike;
    await RazonesService.instance
      .getByInventarioMposId(id,params)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsHistorialEstados(result);
          handleLoading(false);
          setOpenHistorialEstados(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  }

  const handleSelectionModelChange = (selectionModel: any) => {
    setRowsSeleccionados(selectionModel)
  };

  const handleChange = async (e: any, row: MposInventoryInterface) => {
    row.estado = e.target.value;
    updateInventary(row);
  }

  const updateUbicacionInventary = (data: any) => {
    handleLoading(true);
    if (idInventary)
      if (data.documentos && data.documentos.length > 0) {
        MposInventoryService
          .updateUbicacion(
            idInventary,
            data
          )
          .then(response => {
            const { error, message } = response;
            if (!error) {
              handleActiveSuccess(true);
              handleSuccessMessage(message);
              getInventoryMpos();
              setOpenModalUbicacionEdit(false);
            } else {
              handleActiveError(true);
              handleErrorMessage(message);
            }
            handleLoading(false);
          })
          .catch(e => {
            setOpenModalUbicacionEdit(false);
            handleLoading(false);
            handleActiveError(true);
            handleErrorMessage(e.message);
          });
        } else {
          handleLoading(false);
          handleActiveError(true);
          handleErrorMessage('Debe subir al menos un documento');
        }
  }

  const updateInventary = (row: MposInventoryInterface) => {
    handleLoading(true);
    if (row.id)
      MposInventoryService
        .update(
          row.id,
          row
        )
        .then(response => {
          const { error, message } = response;
          if (!error) {
            handleActiveSuccess(true);
            handleSuccessMessage(message);
          } else {
            handleActiveError(true);
            handleErrorMessage(message);
          }
          getInventoryMpos();
          handleLoading(false);
        })
        .catch(e => {
          handleLoading(false);
          handleActiveError(true);
          handleErrorMessage(e.message);
        });
  }

  const handleChangeUbicacion = async (e: any, row: MposInventoryInterface) => {
    row.UbicacionId = e.target.value === 0 ? null : e.target.value;
    //updateInventary({});
  }

  const handleChangeLote = async (e: any, row: MposInventoryInterface) => {
    row.LoteId = e.target.value === 0 ? null : e.target.value;
    updateInventary(row);
  }

  const columns = [
    {
      field: 'acciones',
      headerName: ' ',
      sortable: false,
      width: 90,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            
            <IconButton
              size='small'
              disabled={params.row.estado === 'Activado'}
              onClick={
                async () => {
                  editRecord(params.row)
                }
              }
            >
            <Edit />
            </IconButton>
            
            <IconButton
              size='small'
              disabled={params.row.estado === 'Activado'}
              onClick={
                async () => {
                  deleteRecord(params.row)
                }
              }
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowNew: MposInventoryInterface = params.row as MposInventoryInterface;
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.estado}
                onChange={e => handleChange(e, rowNew)}
                style={{ minWidth: 110, lineHeight: 'unset' }}
              >
                {mposInventoryStatus.map(status => {
                  
                    return (
                      <MenuItem
                        style={{ padding: 10 }}
                        key={status.value}
                        value={status.value}
                      >
                        <Chip
                          label={status.value}
                          style={{ background: status.color, color: 'white' }}
                        />
                      </MenuItem>
                    );
                  
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'historico',
      headerName: 'Historico estados',
      flex: 1,
      minWidth: 225,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id)} color='inherit' >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    { field: 'id', headerName: 'Id' },
    { field: 'numeroDeSerie', headerName: 'Serie', minWidth: 250, flex: 1 },
    { field: 'terminalEpayRetail', headerName: 'Terminal', minWidth: 150, flex: 1 },
    { 
      field: 'Mpos.negocio.id', 
      headerName: 'Id Negocio',
      width: 160,
      renderCell: (params: any) => {
        return params.row["Mpos.negocio.id"];
      }
    },
    {
      field: 'Mpos.negocio.nombre',
      headerName: 'Negocio',
      sortable: false,
      width: 240,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            { params.row["Mpos.negocio.nombre"] }
          </div>
        );
      },
    },
    { field: 'modelo', headerName: 'Modelo', minWidth: 200, flex: 1 },
    { field: 'ubicacionInventario.descripcion', headerName: 'Ubicación', minWidth: 250, flex: 1,
      renderCell: (params: any) => {
        const valor = params.row["ubicacionInventario.id"] || 0;
        return (
          
            <FormControl>
            <Select
              value={valor}
              //onChange={(event) => handleChangeUbicacion(event, params.row)}
              onChange={(event) => {
                //handleChangeUbicacion(event, params.row)
                setIdInventary(params.row.id)
                setUbicacionIdACambiar(Number(event.target.value))
                setUbicacionACambiar(ubicaciones.find((ubicacion) => ubicacion.id === Number(event.target.value))?.descripcion || '')
                setOpenModalUbicacionEdit(true)
              }}
            >
              <MenuItem key={'000ubi'} style={{color:'rgba(0, 0, 0, 0.08)'}} value={0}>{'-- No asignada --'}</MenuItem>
              {ubicaciones.map((opcion:{id: number,descripcion:string},index) => (
                
                <MenuItem key={'ubi'+opcion.id} value={opcion.id}>{opcion.descripcion}</MenuItem>
              ))}
            </Select>
            </FormControl>
          
        );
      },
    },
    {
      field: 'historicoubicacion',
      headerName: 'Historico ubicaciones',
      flex: 1,
      minWidth: 235,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id,'mpos_inventario_ubicaciones')} color='inherit' >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    { field: 'direccion', headerName: 'Dirección de entrega', minWidth: 250, flex: 1 },
    // { field: 'lote', headerName: 'Lote', minWidth: 130 },
    { field: 'loteInventario.nombre', headerName: 'Lote', minWidth: 150, flex: 1,
      renderCell: (params: any) => {
        const valor = params.row["loteInventario.id"] || 0;
        return (
            <FormControl>
            <Select
              value={valor}
              onChange={(event) => handleChangeLote(event, params.row)}
            >
              <MenuItem key={'000lote'} style={{color:'rgba(0, 0, 0, 0.08)'}} value={0}>{'-- No asignado --'}</MenuItem>
              {lotes.map((opcion:{id: number,nombre:string},index) => (
                
                <MenuItem key={'lote'+opcion.id} value={opcion.id}>{opcion.nombre}</MenuItem>
              ))}
            </Select>
            </FormControl>
          
        );
      },
    },
    {
      field: 'historicolotes',
      headerName: 'Historico lotes',
      flex: 1,
      minWidth: 235,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id,'mpos_inventario_lotes')} color='inherit' >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    { field: 'notas', headerName: 'Notas', minWidth: 150, flex: 1 },
    {
      field: 'fechaEntrega',
      headerName: 'Fecha de entrega',
      flex: 1,
      minWidth: 210,
      renderCell: (params: GridCellParams) => {
        const field = params.getValue(params.id, 'fechaEntrega');

        return (
          <span>{field ? momentZone.tz(field, 'America/Guatemala').format('YYYY-MM-DD HH:mm A') : 'Sin fecha'}</span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      flex: 1,
      minWidth: 210,
      renderCell: (params: GridCellParams) => {
        const field = params.getValue(params.id, 'createdAt');
        return (
          <span>{field ? momentZone.tz(field, 'America/Guatemala').format('YYYY-MM-DD HH:mm A') : 'Sin fecha'}</span>
        );
      },
    },
    
  ];

  const getInventoryMpos = () => {
    handleLoading(true);
    MposInventoryService.getInventoryMpos(searchValue)
      .then(response => {
        const { error, message, results } = response;
        if (error) {
          handleActiveError(true);
          handleErrorMessage(message);
        }

        setMposInventoryData(results);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });

    handleLoading(false);
  };

  const openModalEditarSeleccionados = () => {
    setValues({
      estado: '',
      notas: undefined,
      ubicacion: undefined
    })
    setOpenModalEditMasivo(true)
  }

  const handleCloseModal = () => {
    setOpenHistorialEstados(false);
    setOpenModalEditMasivo(false);
    setOpenModalUbicacionEdit(false);
  };

  // const handleChangeMposInventoryValues = (prop: any) => (event: any) => {
  //   setMposInventoryValues({ ...mposInventoryValues, [prop]: event.target.value });
  // };

  const columnsHistorialEstados = [
    {
      field: 'acciones',
      headerName: 'Acciones',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        console.log('params.row', params.row)
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            {params.row?.documentos?.length > 0 && (
              <IconButton
                size='small'
                title='Ver documento'
                onClick={() => openFiles(params.row.documentos.map((doc: any) => doc?.link ))}
              >
                <FindInPageTwoTone />
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      field: 'descripcion',
      headerName: 'Cambio',
      width: 520,
      title: 'Cambios',
      renderCell: (params: any) => {
        return <span title={params.row.descripcion}>{params.row.descripcion}</span>;
      }
    },
    {
      field: 'valor',
      headerName: 'Estado actual',
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    
  ];

  const handleChangeEdit = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleConfirmDeleteMasivo = () => {
    handleLoading(true);
    MposInventoryService['deleteMasivo']({ ids: rowsSeleccionados })
      .then(response => {
        handleLoading(false);
        const { error, message } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getInventoryMpos();
          setModalConfirmarEliminarSeleccionados(false);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  }

  const deleteMasivo = () => {
    handleLoading(true);
    setOpenModalUploadFile(false);
    setConfirmDeleteMasivo(false)
    MposInventoryService.deleteInventoryMpos(fileData)
      .then(response => {
        const { error, message } = response;
        if (!error) {
          getUbicaciones()
          getInventoryMpos();
          handleActiveSuccess(true);
          handleSuccessMessage(message);
        } else {
          handleActiveError(true);
          handleErrorMessage(message);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  }



  const editInventory = () => {
    let validation = ValidationFields.instance.editInventoryMasivo(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        handleLoading(true)
        MposInventoryService['updateMasivo']({ ids: rowsSeleccionados, data: values})
        .then(response => {
          handleLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            getInventoryMpos()
            setOpenModalEditMasivo(false)
            setConfirmEditMasivo(false)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          handleLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
      }
    }
  };

  const openFiles = (link: string[]) => {

    try {
      if (link) {
        try {

          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([link]);
          setOpenImagesModal(true);
        }
      } else {

        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log('ERROR AQUI', error);
    }
  };

  

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
      style={{ maxHeight: '240vh', height: '90vh' }}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid
          container
          style={{ padding: 10 }}
          alignItems='center'
        >
          <Grid
            item
            xs={10}
          >
            <Typography variant='h6'>Inventario MPOS</Typography>
          </Grid>

          <Grid
            item
            style={{ paddingTop: '18px' }}
            xs={2}
          >
            <Button
              color='primary'
              variant='contained'
              fullWidth
              onClick={() => {
                setDataToShow([]);
                setFileData({ mposInventory: [] });
                setOpenModalUploadFile(true);
              }}
            >
              Subir Excel
            </Button>
          </Grid>

          
        </Grid>
        <Grid
          item
          xs={12}
          style={{ paddingBottom: '3px', paddingTop: '3px' }}
        >
          <>
            <Grid
              container
              direction='column'
            ></Grid>
            <Grid
              container
              spacing={3}
              key='t-0'
            >
              <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
                <Fab color="primary" aria-label="add" title='Agregar'  onClick={() => {
                history.push(`/admin-home/inventario/poscreate`);
              }}>
                  <Add />
                </Fab>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
                <Fab disabled={rowsSeleccionados.length === 0} color="primary" title='Editar seleccionados' aria-label="add"  onClick={openModalEditarSeleccionados}>
                  <Edit />
                </Fab>
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
                <Fab disabled={rowsSeleccionados.length === 0} color="primary" title='Eliminar seleccionados' aria-label="delete"  onClick={()=> setModalConfirmarEliminarSeleccionados(true) }>
                  <Delete />
                </Fab>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
              >
                <TextField
                  label='Buscar por:'
                  type='search'
                  placeholder='Id, serie, terminal, negocio, estado, modelo, ubicacion, direccion'
                  variant='filled'
                  fullWidth
                  name='search'
                  value={searchValue}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDownChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{ paddingTop: '18px' }}
              >
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={filtrar}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid
          item
          
          style={{ height: 'calc(100vh - 200px)', width: 'auto' }}
        >
          {mposInventoryData ? (
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              loading={loading}
              rowHeight={50}
              columns={columns}
              rows={mposInventoryData}
              rowsPerPageOptions={[25, 50, 100, 500, 1000]}
              checkboxSelection
              onSelectionModelChange={handleSelectionModelChange}
              isRowSelectable={(params: GridRowParams) => params.row.estado !== 'Activado'}
            />
          ) : (
            <div>Cargando...</div>
          )}
        </Grid>
      </Grid>

      <ModalConfirm
          buttonText="Confirmar"
          title="Borrar POS"
          contentText={`¿Estás seguro de borrar ${rowsSeleccionados.length} POS?`}
          cancelText="Cancelar"
          confirmText="Confirmar"
          handleConfirm={handleConfirmDeleteMasivo}
          handleClose={() => setModalConfirmarEliminarSeleccionados(false)}
          open={modalConfirmarEliminarSeleccionados}
        />

      <ModalRazonDocumentos 
        open={openModalUbicacionEdit} 
        valor={ubicacionIdACambiar} 
        textoAMostrar={ubicacionACambiar}  
        handleCloseModal={handleCloseModal}
        handleUpdate={updateUbicacionInventary}
      />

      {/* Upload Inventory Excel */}
      <Modal
        className={classes.modal}
        open={openModalUploadFile}
        onClose={() => {
          setOpenModalUploadFile(false);
          setDataToShow([]);
          setFileData({ mposInventory: [] });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalUploadFile}>
          <div className={classes.paper_2}>
            <Grid
              container
              direction='column'
              spacing={3}
            >
              <Grid item>
                <input
                  type={'file'}
                  onChange={async (event: BaseSyntheticEvent<{}, {}, HTMLInputElement>) => {
                    try {
                      handleLoading(true);
                      const inventoryKeys = ['Serie', 'Lote', 'Modelo', 'Ubicación', 'Terminal', 'Estado'];

                      const file = event.target.files![0];

                      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                        throw new Error('Archivo no es valido');

                      const data = await file.arrayBuffer();

                      const workbook = read(data);

                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                      const json: FileDataMposInventory[] = utils.sheet_to_json(worksheet);

                      if (!json.length) throw new Error('Archivo sin datos para guardar');

                      const fileDataKeys = Object.keys(json[0]);

                      fileDataKeys.splice(6, 1);

                      if (fileDataKeys.toString() !== inventoryKeys.toString())
                        throw new Error(
                          `Archivo no cumple los requisitos debe de contener las siguientes columnas: ${inventoryKeys}`,
                        );

                      const spreadsheetData: Matrix<CellBase> = json.map((item: FileDataMposInventory) => {
                        const temp: CellBase[] = [];

                        const object = {
                          Serie: item.Serie || '',
                          Lote: item.Lote || '',
                          Modelo: item.Modelo || '',
                          Ubicación: item.Ubicación || '',
                          Terminal: item.Terminal || '',
                          Estado: item.Estado || '',
                          Notas: item.Notas || '',
                        };

                        Object.values(object).forEach(value => {
                          temp.push({ value, readOnly: true });
                        });

                        return temp;
                      });

                      setDataToShow(spreadsheetData);

                      setFileData({
                        mposInventory: json,
                      });

                      handleLoading(false);
                      handleActiveSuccess(true);
                      handleSuccessMessage('Archivo Cargado Exitosamente!');
                    } catch (error) {
                      handleActiveError(true);
                      handleErrorMessage(error instanceof Error ? error.message : `Error: ${error}`);
                    }
                  }}
                />

                <Button
                  style={{ margin: 15 }}
                  color='primary'
                  variant='contained'
                  disabled={fileData.mposInventory.length ? false : true}
                  onClick={() => {
                    handleLoading(true);
                    setOpenModalUploadFile(false);
                    MposInventoryService.createInventoryMpos(fileData)
                      .then(response => {
                        const { error, message } = response;
                        if (!error) {
                          getUbicaciones()
                          getInventoryMpos();
                          handleActiveSuccess(true);
                          handleSuccessMessage(message);
                        } else {
                          handleActiveError(true);
                          handleErrorMessage(message);
                        }
                        handleLoading(false);
                      })
                      .catch(e => {
                        handleLoading(false);
                        handleErrorMessage('message');
                        handleActiveError(true);
                      });
                  }}
                >
                  Enviar
                </Button>

                <Button
                  style={{ margin: 5 }}
                  color='primary'
                  variant='contained'
                  disabled={fileData.mposInventory.length ? false : true}
                  onClick={() => {
                    setConfirmDeleteMasivo(true);
                  }}
                >
                  Eliminar
                </Button>
                <ModalConfirm
                  buttonText="Confirmar"
                  title="Borrar POS"
                  contentText={`¿Estás seguro de borrar ${fileData.mposInventory.length} POS?`}
                  cancelText="Cancelar"
                  confirmText="Confirmar"
                  handleConfirm={deleteMasivo}
                  handleClose={() => setConfirmDeleteMasivo(false)}
                  open={confirmDeleteMasivo}
                />
              </Grid>

              {dataToShow.length ? (
                <Grid
                  item
                  style={{ height: 350, overflow: 'auto' }}
                >
                  <Spreadsheet data={dataToShow} />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>

      <Modal
          className={classes.modal}
          open={openHistorialEstados}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openHistorialEstados}>
            <div className={classes.paper}>
              <p id='transition-modal-description'>Registro histórico</p>
              
              <Grid
                container
                direction='column'
                spacing={2}
              >
                <Grid
                  item
                  style={{ height: 700, width: 'auto' }}
                >
                  <DataGrid
                    isRowSelectable={(params: GridRowParams) => false}
                    loading={loading}
                    rows={rowsHistorialEstados}
                    columns={columnsHistorialEstados}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
        <Dialog maxWidth='lg' fullWidth={true} open={openModalEditMasivo} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
          <DialogContent>
          <Grid
            container
            direction='column'
            spacing={3}
            key='t-0'
          >
            <Grid item>
              <Typography
                variant='h6'
                gutterBottom
              >
                Editar <strong>({rowsSeleccionados.length})</strong> Mpos Seleccionado{rowsSeleccionados.length > 1 ? 's' : ''}
              </Typography>
            </Grid>

            

            
            
            <Grid item>
              <FormControl variant="outlined" fullWidth >
              <InputLabel htmlFor="outlined-age-native-simple">Estado</InputLabel>
                <Select
                  value={values.estado}
                  variant='outlined'
                  fullWidth
                  label='Estado'
                  onChange={handleChangeEdit('estado')}
                >
                  {mposInventoryStatus.map(status => {
                      return (
                        <MenuItem
                          style={{ padding: 10 }}
                          key={status.value}
                          value={status.value}
                        >
                          <Chip
                            label={status.value}
                            style={{ background: status.color, color: 'white' }}
                          />
                        </MenuItem>
                      );
                    
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                label='Notas'
                variant='outlined'
                fullWidth
                value={values.notas}
                onChange={handleChangeEdit('notas')}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Fecha de entrega"
                type="datetime-local"
                variant='outlined'
                fullWidth
                value={values?.fechaEntrega ? values.fechaEntrega : ''}
                onChange={handleChangeEdit('fechaEntrega')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
          </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={() => setConfirmEditMasivo(true) } color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>

      {/* Epay Retail Credentials */}
      {/* <Modal
        className={classes.modal}
        open={openModalMposInventoryEdit}
        onClose={() => {
          setOpenModalMposInventoryEdit(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalMposInventoryEdit}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '2rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
                key='t-0'
              >
                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <TextField
                      fullWidth
                      label='Número de Serie'
                      variant='outlined'
                      value={mposValues.numeroDeSerie}
                      onChange={handleChangeMposValues('numeroDeSerie')}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      size='medium'
                      color='primary'
                      style={{ margin: 5 }}
                      onClick={() => {}}
                    ></IconButton>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    label='Afiliación ePayRetail'
                    variant='outlined'
                    fullWidth
                    value={mposValues.afiliacionEpayRetail}
                    onChange={handleChangeMposValues('afiliacionEpayRetail')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Terminal ePayRetail'
                    variant='outlined'
                    fullWidth
                    value={mposValues.terminalEpayRetail}
                    onChange={handleChangeMposValues('terminalEpayRetail')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Merchant User ePayRetail'
                    variant='outlined'
                    fullWidth
                    disabled
                    value={mposValues.merchantUserEpayRetail}
                    onChange={handleChangeMposValues('merchantUserEpayRetail')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Merchant Password ePayRetail'
                    variant='outlined'
                    fullWidth
                    disabled
                    value={mposValues.merchantPasswordEpayRetail}
                    onChange={handleChangeMposValues('merchantPasswordEpayRetail')}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction='row'
                    spacing={3}
                    key='t-0'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Button
                        size='medium'
                        color='primary'
                        variant='contained'
                        onClick={initialConfigurationsEpayRetail}
                      >
                        Configurar Terminal
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal> */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro de eliminar el Mpos?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDeleteRecord} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmEditMasivo}
        onClose={() => setConfirmEditMasivo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`¿Estas seguro de modificar los ${rowsSeleccionados.length} Mpos seleccionados?`}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmEditMasivo(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={editInventory} color="primary" autoFocus>
            Si, continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        className={classes.modal}
        open={openImagesModal}
        onClose={() => {
          setOpenImagesModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={openImagesModal}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Documento</h2>
            <p id='transition-modal-description'></p>

            {arrayImages.map((item: any) => (
              item.includes('pdf') ? (
                <iframe
                  src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                  style={{ width: '100%', height: '600px' }}
                  key={item}
                  title="PDF Viewer"

                />
              ) : (item.includes('mp4') ? (
                <video
                  width='100%'
                  height='600px'
                  controls
                  autoPlay
                  key={item}
                >
                  <source src={item} type='video/mp4' />
                </video>
              ) : (
                <img
                  src={item}
                //onClick={() => openFile(item)}
                />
              ))


            ))}

          </div>
        </Fade>
      </Modal>
      <PageLoader loading={loading} />
    </Box>
  );
}
