import http from '../libs/http'
import { Rutas } from '../resources/Statics'
import { valuesInterface3dsTransaction } from '../interfaces/Interfaces';

export default class PayWithLinkService {
    static instance = new PayWithLinkService();

    getEnlaceInfo = async (payCode: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/enlacePublico/' + payCode

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    payLink = async (payCode: string, tarjetaData: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/pagarEnlace/' + payCode

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, tarjetaData);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    payLinkHonduras = async (tarjetaData: any) => {
        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/initiateHondurasTransaction'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, tarjetaData);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }
    }

    threeDSHondurasTransaction = async (params: { hashPaggoEnlace: string }) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/threeDSHondurasTransaction'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, params);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }
    }

    getInfoLink = async (hash: string) => {
        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/information/' + hash

            const httpInstance = new http();

            let req = await httpInstance.get(ruta)

            if (req.error) throw new Error(req.message)
            return { error: req.error, message: '', result: req.result }

        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }
    }

    Paso1y3Epayserver3dSecure = async (payCode: string, tarjetaData: valuesInterface3dsTransaction) => {
        try {
            let data = {
                "datosTransaccion": {
                    "numeroTarjeta": tarjetaData.numeroTarjeta.trim(),
                    "expiracionTarjeta": tarjetaData.expiracionFormatoVisa.trim(),
                    "cvvTarjeta": tarjetaData.cvv.trim(),
                    "monto": tarjetaData.montoString.trim(),
                    "primerNombre": tarjetaData.nombre.trim(),
                    "primerApellido": tarjetaData.apellido.trim(),
                    "nombreTarjetaHabiente": tarjetaData.nombreTitular.trim(),
                    // "compania": "NA",
                    // "direccion": "AVENIDA REFORMA ZONA 10",
                    // "direccion2": "AVENIDA REFORMA ZONA 10",
                    // "ciudad": "GUATEMALA",
                    // "areaAdministrativa": "GU",
                    // "codigoPostal": "01010",
                    // "pais": "GT",
                    // "telefono": "12345678",
                    "compania": tarjetaData.compania.trim(),
                    "direccion": tarjetaData.direccionUno.trim(),
                    "direccion2": tarjetaData.direccionDos.trim(),
                    "ciudad": tarjetaData.localidad.trim(),
                    "areaAdministrativa": tarjetaData.areaAdministrativa.trim(),
                    "codigoPostal": tarjetaData.codigoPostal.trim(),
                    "pais": tarjetaData.pais.trim(),
                    "telefono": tarjetaData.numeroTelefonico.trim(),
                    "correo": tarjetaData.email.trim(),
                },
                "datosPagoEnlace": {
                    "hashPagoEnlace": payCode,
                    "nit": tarjetaData.nit,
                    "numeroCuotas": tarjetaData.numeroDeVisaCuotas,
                    "concepto": tarjetaData.concepto
                }
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/Paso1Epayserver3dSecure'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    Paso1Epayserver3dSecure = async (payCode: string, tarjetaData: valuesInterface3dsTransaction) => {
        try {
            let data = {
                "datosTransaccion": {
                    "numeroTarjeta": tarjetaData.numeroTarjeta.trim(),
                    "expiracionTarjeta": tarjetaData.expiracionFormatoVisa.trim(),
                    "cvvTarjeta": tarjetaData.cvv.trim(),
                    "monto": tarjetaData.montoString.trim(),
                    "primerNombre": tarjetaData.nombre.trim(),
                    "primerApellido": tarjetaData.apellido.trim(),
                    "nombreTarjetaHabiente": tarjetaData.nombreTitular.trim(),
                    // "compania": "NA",
                    // "direccion": "AVENIDA REFORMA ZONA 10",
                    // "direccion2": "AVENIDA REFORMA ZONA 10",
                    // "ciudad": "GUATEMALA",
                    // "areaAdministrativa": "GU",
                    // "codigoPostal": "01010",
                    // "pais": "GT",
                    // "telefono": "12345678",
                    "compania": tarjetaData.compania.trim(),
                    "direccion": tarjetaData.direccionUno.trim(),
                    "direccion2": tarjetaData.direccionDos.trim(),
                    "ciudad": tarjetaData.localidad.trim(),
                    "areaAdministrativa": tarjetaData.areaAdministrativa.trim(),
                    "codigoPostal": tarjetaData.codigoPostal.trim(),
                    "pais": tarjetaData.pais.trim(),
                    "telefono": tarjetaData.numeroTelefonico.trim(),
                    "correo": tarjetaData.email.trim(),
                },
                "datosPagoEnlace": {
                    "hashPagoEnlace": payCode,
                    "nit": tarjetaData.nit,
                    "numeroCuotas": tarjetaData.numeroDeVisaCuotas,
                    "concepto": tarjetaData.concepto
                }
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/Paso1Epayserver3dSecure'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    Paso3Epayserver3dSecure = async (payCode: string) => {

        try {
            let data = {
                "hashPagoEnlace": payCode,
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/Paso3Epayserver3dSecure'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    Paso5Epayserver3dSecure = async (payCode: string) => {

        try {
            let data = {
                "hashPagoEnlace": payCode,
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/Paso5Epayserver3dSecure'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    TransaccionPayfacRest = async (payCode: string, tarjetaData: valuesInterface3dsTransaction) => {
        try {
            let data = {
                "datosTransaccion": {
                    "numeroTarjeta": tarjetaData.numeroTarjeta.trim(),
                    "expiracionTarjeta": tarjetaData.expiracionFormatoVisa.trim(),
                    "cvvTarjeta": tarjetaData.cvv.trim(),
                    "monto": tarjetaData.montoString.trim(),
                    "primerNombre": tarjetaData.nombre.trim(),
                    "primerApellido": tarjetaData.apellido.trim(),
                    "nombreTarjetaHabiente": tarjetaData.nombreTitular.trim(),
                    // "compania": "NA",
                    // "direccion": "AVENIDA REFORMA ZONA 10",
                    // "direccion2": "AVENIDA REFORMA ZONA 10",
                    // "ciudad": "GUATEMALA",
                    // "areaAdministrativa": "GU",
                    // "codigoPostal": "01010",
                    // "pais": "GT",
                    // "telefono": "12345678",
                    "compania": tarjetaData.compania.trim(),
                    "direccion": tarjetaData.direccionUno.trim(),
                    "direccion2": tarjetaData.direccionDos.trim(),
                    "ciudad": tarjetaData.localidad.trim(),
                    "areaAdministrativa": tarjetaData.areaAdministrativa.trim(),
                    "codigoPostal": tarjetaData.codigoPostal.trim(),
                    "pais": tarjetaData.pais.trim(),
                    "telefono": tarjetaData.numeroTelefonico.trim(),
                    "correo": tarjetaData.email.trim(),
                },
                "datosPagoEnlace": {
                    "hashPagoEnlace": payCode,
                    "nit": tarjetaData.nit,
                    "numeroCuotas": tarjetaData.numeroDeVisaCuotas,
                    "concepto": tarjetaData.concepto
                }
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/TransaccionPayfacRest'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    checkForLimits  = async (data: { NegocioId: string, monto: number }) => {
        try{
          const ruta = `${Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace}/checkForLimits`;
          const httpInstance = new http();
          let req = await httpInstance.post(ruta, data);
  
          return { error: req.error, message: req.message, result: req.result }
        }
        catch(error:any){
          return { error: true, message: 'Error al obtener limites mensuales' }
        }
    }

    crearLinkPaggo = async (data: any ) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data, true);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    obtenerPagoEnlacePorId = async (PagoEnlaceId: number) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/id/' + PagoEnlaceId

            const httpInstance = new http();

            let req = await httpInstance.get(ruta, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

    TransaccionPayfacRestHn = async (payCode: string, tarjetaData: valuesInterface3dsTransaction) => {
        try {
            let data = {
                "datosTransaccion": {
                    "numeroTarjeta": tarjetaData.numeroTarjeta.trim(),
                    "expiracionTarjeta": tarjetaData.expiracionFormatoVisa.trim(),
                    "cvvTarjeta": tarjetaData.cvv.trim(),
                    "monto": tarjetaData.montoString.trim(),
                    "primerNombre": tarjetaData.nombre.trim(),
                    "primerApellido": tarjetaData.apellido.trim(),
                    "nombreTarjetaHabiente": tarjetaData.nombreTitular.trim(),
                    // "compania": "NA",
                    // "direccion": "AVENIDA REFORMA ZONA 10",
                    // "direccion2": "AVENIDA REFORMA ZONA 10",
                    // "ciudad": "GUATEMALA",
                    // "areaAdministrativa": "GU",
                    // "codigoPostal": "01010",
                    // "pais": "GT",
                    // "telefono": "12345678",
                    "compania": tarjetaData.compania.trim(),
                    "direccion": tarjetaData.direccionUno.trim(),
                    "direccion2": tarjetaData.direccionDos.trim(),
                    "ciudad": tarjetaData.localidad.trim(),
                    "areaAdministrativa": tarjetaData.areaAdministrativa.trim(),
                    "codigoPostal": tarjetaData.codigoPostal.trim(),
                    "pais": tarjetaData.pais.trim(),
                    "telefono": tarjetaData.numeroTelefonico.trim(),
                    "correo": tarjetaData.email.trim(),
                },
                "datosPagoEnlace": {
                    "hashPagoEnlace": payCode,
                    "nit": tarjetaData.nit,
                    "numeroCuotas": tarjetaData.numeroDeVisaCuotas,
                    "concepto": tarjetaData.concepto
                }
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace + '/TransaccionPayfacRestHn'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data);

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            return { error: true, message: error.message, result: null }
        }

    }

}
