import { useContext, useEffect, useState } from 'react'
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { Rutas } from '../../../resources/Statics';
import CrudService from '../../../services/CrudService';
import { GeneralContext } from '../../../context/GeneralContext';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { FormField } from '../../../interfaces/Interfaces';


const UsuariosPanel = () => {

  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);
  const [refresh, setRefresh] = useState(false);
  const [roles, setRoles] = useState<{id: number, nombre: string, tipo: string, panel: boolean}[]>([])

  useEffect(() => {
    getRolesPanel()
  }, [])
  

  const getRolesPanel = () => {
    const crudService = CrudService.instance(Rutas.instance.urlRole);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setRoles(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = async (event: any, row: any, field: string = 'RolId') => {
    const valor = event.target.value; // Valores seleccionados del Select
    let registro = {[field]: valor}
    registro[field] = valor
    registro['RolUsuarioId'] = row.rolUsuario[0].id;
    try {
      const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/panel');
      const response = await crudService.updateOne(row.id, registro);
      const { error, result } = response;
      if (!error) {
          setRefresh(true);
      } else {
          handleErrorMessage('message');
          handleActiveError(true);
      }
      setRefresh(false);
      
    } catch (e) {
      console.error('Error al actualizar la base de datos:', e);
    }
  };

  const columns = [
    
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 70,
        disableClickEventBubbling: true,
    },
    {
        field: 'nombre',
        headerName: 'Nombre',
        sortable: false,
        width: 170,
        disableClickEventBubbling: true,
        editable: true,
        
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      width: 280,
      disableClickEventBubbling: true,
      editable: true,
      
    },
    {
      field: 'rolUsuario',
      headerName: 'Rol',
      sortable: false,
      width: 380,
      disableClickEventBubbling: true,
      editable: true,
      renderCell: (params: any) => {
        const rol = params.row?.rolUsuario[0]?.rol;
        const valor = rol?.id;
        return (
          
            <FormControl>
            <Select
              value={valor}
              onChange={(event) => handleChange(event, params.row, 'RolId')}
            >
              {roles.map((opcion:{id: number,nombre:string,tipo:string},index) => (
                
                <MenuItem key={index} value={opcion.id}>{opcion.tipo}</MenuItem>
              ))}
            </Select>
            </FormControl>
          
        );
      },
    },
    {
      field: 'panel',
      headerName: 'Usuario de Panel',
      sortable: false,
      width: 190,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          params.row?.rolUsuario[0]?.rol?.panel ? 'Si' : 'No'
        )
      }
      
    },
    {
      field: 'activada',
      headerName: 'Activado',
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const activadas = [1, 0]
        const valor = params.row.activada === true ? 1 : 0
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select value={valor} onChange={(event) => handleChange(event, params.row, 'activada')}>
                {activadas.map((opcion,index) => (
                  <MenuItem key={index} value={opcion}>{opcion === 1 ? 'Si' : 'No'}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'password',
      headerName: 'Password',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      editable: true,
      renderCell: (params: any) => {
        return '*******'
      }
    },
    
  ];

  const formConfig: FormField[] = [
    {
      type: 'text',
      label: 'Nombre',
      name: 'nombre',
      required: true,
      error: 'Debe ingresar un nombre',
      value: '',
    },
    {
      type: 'text',
      label: 'Telefono',
      name: 'telefono',
      required: true,
      error: 'Debe ingresar un telefono',
      value: '',
    },
    {
      type: 'email',
      label: 'Email',
      name: 'email',
      required: true,
      error: 'Debe ingresar un email',
      value: '',
    },
    {
      type: 'password',
      label: 'Password',
      name: 'password',
      required: true,
      error: 'Debe ingresar un password',
      value: '',
    },
    {
      type: 'select',
      label: 'Rol',
      name: 'RolId',
      options: roles.filter(rol => rol.panel === true).map((rol) => ({value: rol.id, label: rol.nombre + ' - ' + rol.tipo})),
      required: true,
      error: 'Debe seleccionar un rol',
      value: 0,
    },
  ];

  return (
    <CrudDataGrid formConfig={formConfig} refresh={refresh} apiEndpoint={Rutas.instance.urlUsuario+'/panel'} columns={columns} placeholder='nombre, email' />
  )
}

export default UsuariosPanel