import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Dispatch, SetStateAction, useContext } from 'react';
import { FormControl, Grid, Button } from '@material-ui/core';
import LoadingButton from './LoadingButton';
import { DropzoneDialog } from 'material-ui-dropzone';
import React from 'react';
import DocumentosService from '../services/DocumentosService';
import {GeneralContext} from '../context/GeneralContext';
import TextAreaAutoSize from '@material-ui/core/TextareaAutosize';
import CheckIcon from '@material-ui/icons/CheckTwoTone';
import ReportesServices from '../services/ReportesService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: '60vw',
      },
      imageList: {
        width: 'auto',
        height: 600,
      },
      modalLiquidacion: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: '50vw',
        height: 300,
      },
      transitionGrid: {
        transition: 'margin-right 0.3s ease-in-out',  // Cambiado a 'right'
        position: 'fixed',  // Agregado para fijar la posición
        top: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
        bottom: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
      },
      archivos: {
        marginRight: 0,  // Inicialmente oculto
      },
      button: {
        margin: theme.spacing(1),
      },
    }),
  );

type ModalRespaldoEstadoProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    comentarios: string;
    setComentarios: Dispatch<SetStateAction<string>>;
    linksDocumentosRazones?: string[] | [];
    setLinksDocumentosRazones: Dispatch<SetStateAction<string[]>>;
    UsuarioId?: number | undefined;
    status: string;
    estadoAntiguo: string;
    id: number | undefined;
    getSettlements: () => void
}

export default function ModalRespaldoEstadoLiquidacion( {setIsOpen, isOpen ,UsuarioId=0, comentarios, setComentarios, linksDocumentosRazones = [], setLinksDocumentosRazones, status='', id=0, estadoAntiguo='', getSettlements }: ModalRespaldoEstadoProps) {
    const { handleErrorMessage, handleActiveError, handleLoading, loading, selectedRecord, handleSuccessMessage, handleActiveSuccess, handleSelectRecord } =
    useContext(GeneralContext);

    const classes = useStyles();
    const [uploading, setUploading] = React.useState(false);
    const [openUploadFile, setOpenUploadFile] = React.useState(false);

    const handleOpenUpload = () => {
        setOpenUploadFile(true);
      };
   
    
      const handleCloseUpload = () => {
        setOpenUploadFile(false);
      };
    
    const handleDocumentosRazones = (files: any) => {
      setOpenUploadFile(false);
      let data = new FormData();
      files.forEach((file: any) => {
        data.append('file[]', file);
      });
  
      setUploading(true);
      DocumentosService.instance
        .uploadFileDoc(UsuarioId, data)
        .then(response => {
          setUploading(false);
          const { error, message, result } = response;
          console.log( result )
          if (!error) {
            const links = result.map((file: any) => {
              console.log(file.Location)
              return file.Location
            });
            console.log(links)
             setLinksDocumentosRazones(links)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setUploading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    };
    
    const cambiarEstado = () => {
      ReportesServices.instance
      .updateSettlementState({ status, statusAntiguo: estadoAntiguo, comentarios, documentos: linksDocumentosRazones }, id)
      .then(response => {
        const { error, result, message } = response;
        handleLoading(false);
        handleOpenUpload()
        setIsOpen(false)
        handleSuccessMessage(message);
        getSettlements()
        setComentarios('')
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
    }
     

    
    return (
        <Modal
        className={classes.modal}
        open={isOpen}
        onClose={(event) => {
            setIsOpen
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Agregar Respaldo</h2>
            <p id='transition-modal-description'></p>
            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid item>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                    <p>Ingresa el motivo por el cual vas a cambiar de estado (<span style={{ color: 'red' }}>*</span>) </p>
                    <TextAreaAutoSize onChange={(e) => { setComentarios(e.target.value) }} autoFocus style={{ width: '100%', padding: '10px' }} minRows={8} />

                </FormControl>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={uploading}
                  color='primary'
                  variant='contained'
                  title='Elegir archivo de respaldo'
                  onClick={() => {
                    handleOpenUpload();
                  }}
                />
                <DropzoneDialog
                  dropzoneText='Arrastra los documentos o presiona aquí'
                  previewText='Vista prévia'
                  dialogTitle={'Elige archivo'}
                  submitButtonText='Subir archivo'
                  cancelButtonText='Cancelar'
                  open={openUploadFile}
                  onSave={handleDocumentosRazones}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                  showPreviews={true}
                  maxFileSize={5000000000}
                  filesLimit={10}
                  onClose={handleCloseUpload}
                />
              </Grid>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                style={{ padding: '8px' }}
              >
                <Grid item>
                  <Button onClick={() => cambiarEstado()} disabled= {comentarios.trim().length > 0 && !uploading ? false : true}  style={{ textAlign: 'center' }} color='default' variant='contained'>
                    <><CheckIcon /> &nbsp; Cambiar estado </>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>

    )
}
