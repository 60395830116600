import { Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridFilterItem, GridFilterModel } from '@material-ui/data-grid';
import React, { FC, useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { Rutas } from '../../resources/Statics';
import { GeneralContext } from '../../context/GeneralContext';
import { Alert } from '@material-ui/lab';
import { Label } from '@material-ui/icons';
import PageLoader from '../../components/PageLoader';

interface Ubicacion {
  id: number;
  descripcion: string;
}

interface Movement {
  id: number;
  ResponsableUsuarioId: number;
  SolicitudPosUbicacionId: number;
  estado: string;
  razonRechazo: string;
  rollos: number;
  sobres: number;
  stickers: number;
}

interface Props {
  openSolicitud: boolean;
  setOpenSolicitud: (open: boolean) => void;
  ubicacion: Ubicacion;
  movimiento?: Movement;
  SolicitudPosUbicacionId: number;
  reset: () => void;
  accion: string;
  setAccion: (accion: string) => void;
  cantidad: number;
  modelo: string;
}

interface Movimientos {
  id: number;
  UbicacionDestinoId: number;
  ResponsableUsuarioId: number;
  pos: number[];
  SolicitudPosUbicacionId: number;
  stickers: number;
  sobres: number;
  rollos: number;
}

interface Pos {
  id: number;
  lote: string;
  numeroDeSerie: string;
  terminalEpayRetail: string;
  estado: string;
}




const Movimiento: FC<Props> = ({ openSolicitud, ubicacion, setOpenSolicitud, movimiento, SolicitudPosUbicacionId, reset, accion='', setAccion, cantidad, modelo }) => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    loading,
    handleLoading,
  } = useContext(GeneralContext);
  const [values, setValues] = useState<Movimientos>(
    { id: 0, 
      UbicacionDestinoId: 0, 
      ResponsableUsuarioId: 0, 
      pos: [],
      SolicitudPosUbicacionId: 0,
      stickers: 0,
      sobres: 0,
      rollos: 0
    }
  );
  //const [accion, setAccion] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(openSolicitud);
  const [searchValueDialogPos, setSearchValueDialogPos] = useState('');
  const [desde, setDesde] = useState('');
  const [hasta, setHasta] = useState('');
  const [loadingGrid, setLoading] = useState(false);
  const [rowsPos, setRowsPos] = useState<Pos[]>([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [responsables, setResponsables] = useState<{id: number, nombre: string}[]>([]);
  const [pagePos, setPagePos] = useState<number>(1);
  const [pageSizePos, setPageSizePos] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<{id: number}[]>([]);
  const [rowsPosOriginal, setRowsPosOriginal] = useState<Pos[]>([]);

  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <strong style={{padding:'1em'}}>
          {movimiento && movimiento.estado === 'A' ? 
            'POS MOVIDOS:'
            : 'POS A MOVER:'
          }
          
        </strong>
      </React.Fragment>
    );
  };

  const columnsPos = [
  
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 100,
    },
    {
        field: 'lote',
        headerName: 'Lote',
        sortable: false,
        width: 130,
    },
    {
      field: 'numeroDeSerie',
      headerName: 'Número de serie',
      sortable: false,
      width: 300,
    },
    {
      field: 'terminalEpayRetail',
      headerName: 'Terminal',
      sortable: false,
      width: 160,
    },
    {
      field: 'modelo',
      headerName: 'Modelo',
      sortable: false,
      width: 150,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 350,
    },
  ];

  useEffect(() => {
    console.log('ingresa a openSolicitud', openSolicitud)
    if (openSolicitud) {
      if (ubicacion.id) {
        getResponsables();
      }
      getPos();
      
      
    }
  } , [openSolicitud]);

  const inicializar = () => {
    setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [], SolicitudPosUbicacionId: 0, stickers: 0, sobres: 0, rollos: 0});
    setAccion('');
    setDesde('');
    setHasta('');
    setLoading(false);
    setRowsPos([]);
    setSelectionModel([]);
    setResponsables([]);
    setPagePos(1);
    setPageSizePos(10);
    setTotalPages(1);
    setSearchValue('');
    setRows([]);
    setRowsPosOriginal([]);
  }

  useEffect(() => { 
    if (ubicacion.id) {
      getResponsables();
    }
  } , [ubicacion.id]);

  // useEffect(() => {
  //   setValues({ ...values, SolicitudPosUbicacionId: SolicitudPosUbicacionId || 0, UbicacionDestinoId: ubicacion.id || 0, ResponsableUsuarioId: movimiento?.ResponsableUsuarioId || 0, id: movimiento?.id || 0, stickers: movimiento?.stickers || 0, sobres: movimiento?.sobres || 0, rollos: movimiento?.rollos || 0});
  //   getPos();
  // }, [SolicitudPosUbicacionId, ubicacion, movimiento]);

  useEffect(() => {
    if (desde && hasta) {
      const newRows = rowsPosOriginal.filter((row) => {
        return BigInt(row.numeroDeSerie) >= BigInt(desde) && BigInt(row.numeroDeSerie) <= BigInt(hasta)
      })
      setRowsPos(newRows);
    }
    if (!desde && !hasta) {
      setRowsPos(rowsPosOriginal);
    }
    if (!desde && hasta) {
      const newRows = rowsPosOriginal.filter((row) => {
        return BigInt(row.numeroDeSerie) <= BigInt(hasta)
      })
      setRowsPos(newRows);
    }
    if (desde && !hasta) {
      const newRows = rowsPosOriginal.filter((row) => {
        return BigInt(row.numeroDeSerie) >= BigInt(desde)
      })
      setRowsPos(newRows);
    }
  } , [desde, hasta]);

  const getResponsables = () => {
    console.log('entrando a responsables')
    const params =`?UbicacionId=${ubicacion.id}`
    const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/ubicaciones/responsables');
    crudService
      .getAll(params)
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setResponsables(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleSelectionModelChange = (selectionModel: any) => {
    setValues({ ...values, pos: selectionModel });
    setSelectionModel(selectionModel);
  };

  const handleChange = (prop: any,type:string='string') => (event: any) => {
    if (event.target.value)
      setValues({ ...values, [prop]: type === 'string' ? event.target.value : Number(event.target.value) });
  };

  const getPos = () => {
    setLoading(true);
    const params = `?page=${pagePos}&pageSize=${pageSizePos}&movimientoId=${movimiento?.id || 0}&accion=${accion}`;
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento +'/responsable/ubicacion/pos');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRowsPos(result.pos);
          setRowsPosOriginal(result.pos);
          setSelectionModel(result.seleccionados)
          setValues({ ...values, pos: result.seleccionados, SolicitudPosUbicacionId: SolicitudPosUbicacionId || 0, UbicacionDestinoId: ubicacion.id || 0, ResponsableUsuarioId: movimiento?.ResponsableUsuarioId || 0, id: movimiento?.id || 0, stickers: movimiento?.stickers || 0, sobres: movimiento?.sobres || 0, rollos: movimiento?.rollos || 0});
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const confirmSave = () => {
    console.log('values', values)
    if (!values.ResponsableUsuarioId || !values.UbicacionDestinoId || values.pos.length === 0 || !values.stickers || !values.sobres || !values.rollos || !values.SolicitudPosUbicacionId) {
      handleErrorMessage('Por favor ingrese todos los campos obligatorios');
      handleActiveError(true);
      return;
    }
    handleLoading(true);
    const crudService = CrudService.instance('/movimiento');
    crudService
    .create(values)
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [], SolicitudPosUbicacionId: 0, stickers: 0, sobres: 0, rollos: 0});
        setOpenSolicitud(false);
        inicializar();
        reset();
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      handleLoading(false);
    })
    .catch(e => {
      handleLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  const confirmUpdate = () => {
    console.log('values', values)
    if (!values.ResponsableUsuarioId || !values.UbicacionDestinoId || values.pos.length === 0) {
      handleErrorMessage('Por favor ingrese todos los campos obligatorios');
      handleActiveError(true);
      return;
    }
    

    handleLoading(true);
    const crudService = CrudService.instance('/movimiento');
    crudService
    .updateOne(values.id, values)
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [], SolicitudPosUbicacionId: 0, stickers: 0, sobres: 0, rollos: 0});
        setOpenSolicitud(false);
        inicializar();
        reset();
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      handleLoading(false);
    })
    .catch(e => {
      handleLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  

  return (
    <>
      <Dialog
        open={openSolicitud}
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [], SolicitudPosUbicacionId: 0, stickers: 0, sobres: 0, rollos: 0});
          setOpenSolicitud(false)
          inicializar();
          setAccion('')
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {accion === 'view' ? 'Solicitud de movimiento' : 
            (values.id === 0 ? 'Solicitar movimiento' : `Modificar solicitud de movimiento ID: ${values.id}`) 
          }
          <DialogActions>
            <small style={{textTransform: 'uppercase'}}>SE SOLICITA: <strong>{cantidad}</strong> POS | modelo: <strong>{modelo}</strong> | para: <strong>{ubicacion.descripcion}</strong></small>
          </DialogActions>

          
          {movimiento ?
          <>
            {movimiento.estado === 'X' &&
              <Alert severity="error">
                <strong>ESTADO:</strong> RECHAZADO
                <br />
                <strong>RAZÓN:</strong> {movimiento.razonRechazo}
              </Alert>
            } 
            {movimiento.estado === 'P' &&
              <Alert severity="info">
                <strong>ESTADO:</strong> PENDIENTE
              </Alert>
            }
            {movimiento.estado === 'A' &&
              <Alert severity="success">
                <strong>ESTADO:</strong> APROBADO
              </Alert>
            }
          </>
            : 
              <Alert severity="warning">
                <strong>Aún no se ha atendido esta solicitud</strong>
              </Alert>
            }
        </DialogTitle>
        
        <DialogContent>
          {accion === 'view' ? null :
            <DialogContentText id="alert-dialog-description">
              <Grid container>
                <Grid item xs={12} md={3} style={{paddingTop: '1%', textAlign:'right'}}>
                  <TextField
                    label='Desde'
                    placeholder='Número de serie'
                    variant='outlined'
                    size='small'
                    fullWidth
                    name='search'
                    value={desde}  
                    onChange={(event) => {
                      setDesde(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} style={{paddingTop: '1%', textAlign:'right'}}>
                  <TextField
                    label='Hasta'
                    placeholder='Número de serie'
                    variant='outlined'
                    size='small'
                    fullWidth
                    name='search'
                    value={hasta}
                    onChange={(event) => setHasta(event.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          }
          <Grid container >
            <Grid
              item
              xs={12}
              style={{ minHeight: 220, height: 320, width: 'auto' }}
            >
              <DataGrid
                components={{ Toolbar: CustomToolbar }} 
                loading={loadingGrid}
                rows={rowsPos}
                columns={columnsPos}
                rowHeight={33}
                rowsPerPageOptions={[5, 10, 15, 100]}
                checkboxSelection={accion === 'view' ? false : true}
                disableSelectionOnClick={true}
                onSelectionModelChange={handleSelectionModelChange}
                headerHeight={33}
                selectionModel={selectionModel}
              />
              
          </Grid>
          <Grid
              item
              xs={12}
              style={{ paddingTop: 20}}
            >
              <Grid item xs={12} style={{paddingTop: '1%'}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Ubicación destino</InputLabel>
                  <Select disabled value={ubicacion.descripcion}>
                      <MenuItem value={ubicacion.descripcion}>
                        {ubicacion.descripcion}
                      </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{paddingTop: '1%'}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Responsable</InputLabel>
                  <Select disabled={accion === 'view' ? true : false} value={values.ResponsableUsuarioId} onChange={handleChange('ResponsableUsuarioId')}>
                    {responsables.map((responsable) => (
                      <MenuItem key={responsable.id} value={responsable.id}>
                        {responsable.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{paddingTop: '1%'}}>
                <TextField
                  disabled={accion === 'view' ? true : false}
                  label='Stickers'
                  placeholder='Stickers'
                  variant='outlined'
                  type='number'
                  fullWidth
                  name='stickers'
                  value={values.stickers}
                  onChange={handleChange('stickers','number')}
                />
              </Grid>
              <Grid item xs={12} style={{paddingTop: '1%'}}>
                <TextField
                  disabled={accion === 'view' ? true : false}
                  label='Sobres'
                  placeholder='Sobres'
                  variant='outlined'
                  fullWidth
                  type='number'
                  name='sobres'
                  value={values.sobres}
                  onChange={handleChange('sobres','number')}
                />
              </Grid>
              <Grid item xs={12} style={{paddingTop: '1%'}}>
                <TextField
                  disabled={accion === 'view' ? true : false}
                  label='Rollos'
                  placeholder='Rollos'
                  variant='outlined'
                  type='number'
                  fullWidth
                  name='rollos'
                  value={values.rollos}
                  onChange={handleChange('rollos','number')}
                />
              </Grid>
            </Grid>
          </Grid> 
        </DialogContent>
        <DialogActions style={{padding:'2em'}}>
          
          {accion === 'view' ? null : 
            <Button variant='contained' color='primary' onClick={ values.id === 0 ? confirmSave : confirmUpdate } autoFocus>
              {values.id === 0 ? 'Solicitar' : 'Modificar'}
            </Button>
          }
        </DialogActions>
      </Dialog>
      <PageLoader loading={loading} />
    </>
  )
}

export default Movimiento