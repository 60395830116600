import React, { createContext, useState } from "react";

interface ContextInterface {
    loading: boolean,
    handleLoading: (value: boolean) => void,
    activeError: boolean,
    handleDismissError: (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => void,
    handleActiveError: (value: boolean) => void,
    errorMessage: string,
    handleErrorMessage: (value: string) => void
    activeSuccess: boolean,
    handleDismissSuccess: (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => void,
    handleActiveSuccess: (value: boolean) => void,
    successMessage: string,
    handleSuccessMessage: (value: string) => void,
    handleSelectRecord: (value: any) => void,
    selectedRecord: any,
    handleDeviceDataCollectionUrl: (value: string) => void,
    deviceDataCollectionUrl: string,
    rolUsuario: string,
    handleRolUsuario: (value: string) => void,
    esCliente: boolean,
    handleEsCliente: (value: boolean) => void
}

export const GeneralContext = createContext<ContextInterface>({
    loading: false,
    handleLoading: (value: boolean) => { },
    activeError: false,
    handleDismissError: () => (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => { },
    handleActiveError: (value: boolean) => { },
    errorMessage: '',
    handleErrorMessage: (value: string) => { },
    activeSuccess: false,
    handleDismissSuccess: () => (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => { },
    handleActiveSuccess: (value: boolean) => { },
    successMessage: '',
    handleSuccessMessage: (value: string) => { },
    handleSelectRecord: (value: any) => { },
    selectedRecord: {},
    handleDeviceDataCollectionUrl: (value: string) => { },
    deviceDataCollectionUrl: '',
    rolUsuario: '',
    handleRolUsuario: (value: string) => { },
    esCliente: false,
    handleEsCliente: (value: boolean) => { }
})

const GeneralContextComponent = (props: any) => {

    /*------------ active loading or progress bar ------------*/

    const [loading, setLoading] = useState(false)

    const handleLoading = (value: boolean) => {
        setLoading(value)
    }

    /*------------ error message ------------*/

    const [activeError, setActiveError] = useState(false)

    const handleDismissError = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setActiveError(false);
    };

    const handleActiveError = (value: boolean) => {
        setActiveError(value)
    }

    const [errorMessage, setErrorMessage] = useState('')

    const handleErrorMessage = (value: string) => {
        if (value === 'TypeError: Failed to fetch' || value.includes('TypeError: Failed to fetch')) value = 'Error de conexion por favor intente mas tarde'
        setErrorMessage(value)
    }

    /*------------ success message ------------*/

    const [activeSuccess, setActiveSuccess] = useState(false)

    const handleDismissSuccess = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setActiveSuccess(false);
    };

    const handleActiveSuccess = (value: boolean) => {
        setActiveSuccess(value)
    }

    const [successMessage, setSuccessMessage] = useState('')

    const handleSuccessMessage = (value: string) => {
        setSuccessMessage(value)
    }

    const [selectedRecord, setSelectedRecord] = useState({})

    const handleSelectRecord = (item: any) => {
        setSelectedRecord(item)
    }

    const [deviceDataCollectionUrl, setDeviceDataCollectionUrl] = useState('')
    const handleDeviceDataCollectionUrl = (value: string) => {
        setDeviceDataCollectionUrl(value)
    }

    const [rolUsuario, setRolUsuario] = useState('')

    const handleRolUsuario = (value: string) => {
        setRolUsuario(value)
    }

    const [esCliente, setEsCliente] = useState(false)
    const handleEsCliente = (value: boolean) => {
        setEsCliente(value)
    }

    return (
        <GeneralContext.Provider value={{ esCliente, handleEsCliente, handleRolUsuario, rolUsuario, deviceDataCollectionUrl, handleDeviceDataCollectionUrl, successMessage, activeSuccess, handleDismissSuccess, handleActiveSuccess, handleSuccessMessage, loading, handleLoading, activeError, handleActiveError, handleDismissError, errorMessage, handleErrorMessage, selectedRecord, handleSelectRecord }}>
            {props.children}
        </GeneralContext.Provider>
    )
}

export default GeneralContextComponent
