
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import { Rutas } from '../resources/Statics';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Dispatch, SetStateAction } from 'react';

type ModalVoucherProps = {
    isOpen: boolean;
    documentLink: string;
    voucherId: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}
  


export default function ModalVoucher({voucherId = '', setIsOpen, isOpen , documentLink = '' }: ModalVoucherProps) {
    

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            },
            paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '60vw',
            },
            imageList: {
            width: 'auto',
            height: 600,
            },
            modalLiquidacion: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '50vw',
            height: 300,
            },
            transitionGrid: {
            transition: 'margin-right 0.3s ease-in-out',  // Cambiado a 'right'
            position: 'fixed',  // Agregado para fijar la posición
            top: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
            bottom: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
            },
            archivos: {
            marginRight: 0,  // Inicialmente oculto
            },
            button: {
            margin: theme.spacing(1),
            },
        }),
    );
    
    const classes = useStyles();
   


    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={() => { setIsOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Fade in={isOpen}>
            <div className={classes.paper}>
                <h2 id='transition-modal-title'>Voucher</h2>
                <p id='transition-modal-description'></p>
                <ImageList
                    rowHeight={160}
                    className={classes.imageList}
                    cols={1}
                >
                    <ImageListItem
                        key={voucherId}
                        cols={1}
                        rows={4}
                    >
                        <iframe
                            src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${documentLink}`}
                            style={{ width: '100%', height: '600px' }}
                            key={documentLink}
                            title="PDF Viewer"

                        />
                    </ImageListItem>
                </ImageList>
            </div>
            </Fade>
        </Modal>
    )
}
