import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class mPosService {
  static instance = new mPosService();

  initialConfigurationsEpayRetail = async (negocioId: number, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlMpos}/${negocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.post(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update EpayRetail credentials with error', error.message);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getMpos = async (NegocioId: any) => {
    try {
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlMpos}/${NegocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('Get mpos con error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateEpayRetailCredentials = async (mposId: number | undefined, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlMpos}/${mposId}/edit`;

      const httpInstance = new http();

      let req = await httpInstance.post(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update mpos con error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  deleteMpos = async (mposId: number | undefined, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlMpos}/${mposId}`;

      const httpInstance = new http();

      let req = await httpInstance.delete(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update mpos con error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getMposList = async (configured: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlMpos}/list?configEpay=${configured}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update mpos con error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }
}
