import * as momentZone from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Avatar, Chip, Popover, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridRowParams } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/PageviewOutlined';
import { green, pink } from '@material-ui/core/colors';
import { GeneralContext } from '../../context/GeneralContext';
import RazonesService from '../../services/RazonesService';
import ModalPreviewDocumentos from '../../components/ModalPreviewDocumentos';

interface LinkAws {
  id: number,
  link: string;
  extension: string;
  estado: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
  }),
);

interface Info {
  label: string;
  value: string;
}

export default function RespaldosRetenidas() {
  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);
  let history = useHistory();

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [searchValue, setSearchValue] = useState('');
  const [rowsRetenciones, setRowsRetenciones] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [links, setLinks] = React.useState<LinkAws[]>([]);
  const [info, setInfo] = React.useState<Info[]>([]);


  useEffect(() => {
    getRetenciones();
  }, [page, pageSize]);

  const handleOpen = (row: any) => {
    const documentos: LinkAws[] = row.documentos;
    setLinks(documentos);

    const info: Info[] = [
      { label: 'ID Transacción', value: row.pagosenlace ? row.PagosEnlaceId : row.PagosTarjetaId },
      { label: 'ID Negocio', value: row.pagosenlace ? row.pagosenlace?.negocio?.id : row.pagostarjeta?.negocio?.id },
      { label: 'Nombre Negocio', value: row.pagosenlace ? row.pagosenlace?.negocio?.nombre : row.pagostarjeta?.negocio?.nombre },
      { label: 'Teléfono Negocio', value: row.pagosenlace ? row.pagosenlace?.negocio?.telefono : row.pagostarjeta?.negocio?.telefono },
      { label: 'Medio', value: row.pagosenlace ? 'Link' : 'Tarjeta' },
    ];
    setInfo(info);

    setOpen(true);
  };

  const handleClose = () => {
    setLinks([]);
    setOpen(false);
  };

  const columnsRetenciones = [
    
    {
        field: 'idtransaccion',
        headerName: 'ID Transacción',
        sortable: false,
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return <span>{params?.row?.pagosenlace ? params?.row?.PagosEnlaceId : params.row.PagosTarjetaId}</span>;
        },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const liquidar = params?.row?.pagosenlace ? params?.row?.pagosenlace?.liquidar :  params?.row?.pagostarjeta?.liquidar;
        return <Chip
            label={liquidar === true ? 'Liberada' : 'Retenida'}
            style={{ background: liquidar === true ? '#66bb6a' : '#f44336', color: 'white' }}
          />;
      },
    },
    {
      field: 'documentos',
      headerName: 'Documentos subidos',
      width: 230,
      renderCell: (params: any) => {
        const longitud = params.row.documentos.length
        if (longitud > 0) {
          return <div style={{width:'100%'}}>
            <Button fullWidth variant="outlined" color="primary" onClick={() => handleOpen(params.row)}>
             Ver {longitud} {longitud > 1 ? 'documentos' : 'documento'}    
            </Button>
          </div>
        }
      },
    },
    {
      field: 'idNegocio',
      headerName: 'Id Negocio',
      sortable: false,
      width: 155,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{
          params?.row?.pagosenlace ? params?.row?.pagosenlace?.negocio?.id : params?.row?.pagostarjeta?.negocio?.id
        }</span>;
      },
    },
    {
      field: 'nombreNegocio',
      headerName: 'Nombre Negocio',
      sortable: false,
      width: 280,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{
          params?.row?.pagosenlace ? params?.row?.pagosenlace?.negocio?.nombre : params?.row?.pagostarjeta?.negocio?.nombre
        }</span>;
      },
    },
    {
      field: 'telefonoNegocio',
      headerName: 'Telefono Negocio',
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params?.row?.pagosenlace?.negocio?.telefono}</span>;
      },
    },
    {
        field: 'medio',
        headerName: 'Medio',
        sortable: false,
        width: 140,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return <span>{params?.row?.pagosenlace ? 'Link' : 'Tarjeta'}</span>;
        },
    },
    {
      field: 'usuario',
      headerName: 'Usuario que subio',
      sortable: false,
      width: 260,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params?.row?.usuario?.nombre + ' ' + params?.row?.usuario?.apellidoPaterno}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
  ];


  const getRetenciones = () => {
    setLoading(true);
    const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
    RazonesService.instance
      .getRetenidas(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRowsRetenciones(result);
          setTotalPages(totalPages);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const filtrar = () => {
    getRetenciones();
  };

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={4}
      component={Paper}
      style={{ maxHeight: '240vh', height: '90vh' }}
    >
      <Grid
        container
        direction='column'
      >
        <Grid item>
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='h6'
                gutterBottom
              >
                RESPALDOS DE TRANSACCIONES RETENIDAS
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
            item
            xs={12}
            style={{ paddingBottom: '3px', paddingTop: '3px' }}
        >
            <>
            <Grid
                container
                direction='column'
            ></Grid>
            <Grid
                container
                spacing={3}
                key='t-0'
            >
                <Grid
                    item
                    xs={10}
                    >
                    <TextField
                        label='Buscar por:'
                        placeholder='Id de transaccion'
                        variant='filled'
                        fullWidth
                        name='search'
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    style={{ paddingTop: '18px' }}
                    >
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={filtrar}
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>
            </>
        </Grid>

        <Grid
          item
          style={{ height: 'calc(95vh - 220px)', width: 'auto' }}
        >
            <DataGrid
                isRowSelectable={(params: GridRowParams) => false}
                loading={loading}
                rows={rowsRetenciones}
                columns={columnsRetenciones}
                paginationMode='server'
                pageSize={pageSize}
                rowCount={totalPages * pageSize}
                hideFooterPagination
                rowsPerPageOptions={[10, 25, 50, 100]}
            />
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={totalPages}
              page={page}
              shape='rounded'
              size='large'
              color='primary'
              style={{ padding: '8px', textAlign: 'center' }}
              onChange={(event, value) => setPage(value)}
            />
          </div>
        </Grid>
      </Grid>
      <ModalPreviewDocumentos reload={getRetenciones} info={info} links={links} open={open} onClose={handleClose} />
    </Box>
  );
}
