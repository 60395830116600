import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowParams, GridToolbarContainer } from '@material-ui/data-grid';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../context/GeneralContext';
import CrudService from '../../services/CrudService';
import { Delete } from '@material-ui/icons';
import { FormField } from '../../interfaces/Interfaces';
import PageLoader from '../../components/PageLoader';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

interface CrudDataGridProps {
    apiEndpoint: string;
    columns: GridColDef[];
    placeholder: string,
    refresh?: boolean,
    formConfig?: FormField[],
    delete?: boolean,
    params?: string
    descargar?: boolean,
    title?: string,
}

function getStyles(value: string, values: string[], theme: Theme) {
  return {
    fontWeight:
      values.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles({
  rowBkgColor1: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  rowBkgColor2: {
    backgroundColor: 'transparent',
  },
  rowBkgColorDownload: {
    backgroundColor: '#fafafa',
    display: 'inline-block',
    borderRadius: '12px',
    padding: '8px',
    boxShadow: 'inset 0 0 10px -7px rgba(0, 0, 0, 0.5)'
  },
  fieldset: {
    display: 'inline-block',
    border: '1px solid #ccc', /* Borde del fieldset */
    borderRadius: '10px', /* Bordes redondeados */
    backgroundColor: '#f9f9f9', /* Color de fondo */
    transition: 'all 0.3s ease', /* Transición suave */
  },
  
  legend: {
    fontSize: '1.2em', /* Tamaño del texto del título */
    fontWeight: 'bold' /* Texto en negrita */
  }
  // ...
});


const CrudDataGrid = (
    props: CrudDataGridProps
) => {

    const {
        handleErrorMessage,
        handleActiveError,
        handleSuccessMessage,
        handleActiveSuccess,
        handleSelectRecord,
        handleLoading,
        selectedRecord
      } = useContext(GeneralContext);

      const classes = useStyles();
    
      const [loading, setLoading] = useState(false);
      const [page, setPage] = useState<number>(1);
      const [pageSize, setPageSize] = useState<number>(10);
      const [totalPages, setTotalPages] = useState<number>(1);
      const [confirmDelete, setConfirmDelete] = useState(false);
      const [searchValue, setSearchValue] = useState('');
      const [rows, setRows] = useState<{id: number}[]>([]);
      const [isAdding, setIsAdding] = useState(false);
      const [newRow, setNewRow] = useState({});
      const [newRowData, setNewRowData] = useState<{ [key: string]: string | number | string[] | boolean }>({});
      const [startDateDownload, setStartDateDownload] = useState<string | undefined>('');
      const [endDateDownload, setEndDateDownload] = useState<string | undefined>('');
      

      const deleteRecord = (record: any) => {
        handleSelectRecord(record);
        setConfirmDelete(true);
      };

      const deleteAction = {
        field: 'delete',
        headerName: ' ',
        sortable: false,
        width: 50,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return (
            <div
              className='d-flex justify-content-between align-items-center'
              style={{ cursor: 'pointer' }}
            >
              <IconButton
                size='small'
                onClick={
                  async () => {
                    deleteRecord(params.row)
                  }
                }
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      };

      const [columns,setColumns] = useState([...props.columns])
      const [formConfig, setFormConfig] = useState(props.formConfig)
      const theme = useTheme();

      useEffect(() => {
        getRows();
      }, [page, pageSize]);

      useEffect(() => {
        if (props.refresh)
          getRows();
      }, [props.refresh]);

      useEffect(() => {
          setColumns(props.columns)
          if (props.delete){
            setColumns([
              deleteAction,
              ...props.columns,
            ])
          }
      }, [props.columns]);

      useEffect(() => {
        setFormConfig(props.formConfig)
    }, [props.formConfig]);

    const procesarGetNegocios = () => {
      if (page === 1) getRows();
      else setPage(1);
    }
  
    useEffect(() => {
      if (searchValue){
        const timerId = setTimeout(procesarGetNegocios, 1000);
        return () => clearTimeout(timerId);
      }
    }, [searchValue]);

    useEffect(() => {
      
      procesarGetNegocios()
      
    }, [startDateDownload, endDateDownload]);
    
      const handleAddRow = () => {
        setIsAdding(true);
        const initialNewRowData: { [key: string]: string | number | string[] | boolean } = {};
        props.formConfig?.forEach((field) => {
          initialNewRowData[field.name] = field.value;
        });
        setNewRowData(initialNewRowData);

        
      };

      const handleSearchChange = (event: any) => {
        setSearchValue(event.target.value);
      };

      const handleKeyDownChange = (event: any) => {
        if (event.key === 'Enter') {
          getRows();
        }
      }

      const handleCancelAdd = () => {
        setIsAdding(false);
        setNewRow({});
        setNewRowData({});
      };

      const handleSaveRow = () => {
        const missingFields = props.formConfig?.filter((field) => field.required && !newRowData[field.name]);
        if (missingFields && missingFields.length > 0) {
          handleErrorMessage('Por favor ingrese todos los campos obligatorios');
          handleActiveError(true);
          return;
        }

        setLoading(true);
        const crudService = CrudService.instance(props.apiEndpoint);
        crudService
        .create(newRowData)
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            getRows()    
            setIsAdding(false)  
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage('message');
          handleActiveError(true);
        });
      };

      const getRows = () => {
        setLoading(true);
        const params = props.params ? 
        `?page=${page}&pageSize=${pageSize}&valor=${searchValue}&fechaDesde=${startDateDownload}&fechaHasta=${endDateDownload}&${props.params}` 
        : `?page=${page}&pageSize=${pageSize}&valor=${searchValue}&fechaDesde=${startDateDownload}&fechaHasta=${endDateDownload}`;
        const crudService = CrudService.instance(props.apiEndpoint);
        crudService
          .getPaginationFilter(params)
          .then(async response => {
            const { error, result, totalPages } = response;
            if (!error) {
              setRows(result);
              setTotalPages(totalPages);
            }
            setLoading(false);
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage('message');
            handleActiveError(true);
          });
      };

      const handleCellEditCommit = async (params: any) => {
        setLoading(true);
        const crudService = CrudService.instance(props.apiEndpoint);
        crudService
        .updateOne(params.id, { [params.field]: params.value })
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            getRows()      
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
            getRows() 
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage('message');
          handleActiveError(true);
        });
        
      };
      const confirmDeleteRecord = async () => {
        setLoading(true);
        const crudService = CrudService.instance(props.apiEndpoint);
        crudService
            .deleteOne(
              selectedRecord.id,
            )
            .then(response => {
              const { error, message } = response;
              if (!error) {
                getRows();
                handleActiveSuccess(true);
                handleSuccessMessage(message);
              } else {
                handleErrorMessage(message);
                handleActiveError(true);
                getRows() 
              }
              setLoading(false);
              setConfirmDelete(false);
            })
            .catch(e => {
              setLoading(false);
              handleActiveError(true);
              handleErrorMessage(e.message);
              setConfirmDelete(false);
            });
      }
  
  const getRowClassName = (params: GridRowParams) => {
    if (params.row.PadreId === null && params.row.isMenu === true) {
      return classes.rowBkgColor1;
    } else {
      return classes.rowBkgColor2;
    }
  };

  const NoRowsOverlay = () => {
    return <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '200px',
    }}>No hay registros para mostrar.</div>; // Mensaje predeterminado
  };

  const downloadExcelRows = () => {
    handleLoading(true);
    const crudService = CrudService.instance(props.apiEndpoint);
        crudService.downloadExcelRows(startDateDownload, endDateDownload, searchValue)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            handleActiveError(true);
          }
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  return (
    <Box
      mt={1}
      mb={2}
      pt={1}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
          <Grid container >
            <Grid
              item
              xs={12}
              md={4}
              
            >
              <Typography variant='h6'>{
                props.title ? props.title : '' 
              }</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
            >
              <Box
                justifyContent={['flex-start', 'flex-start', 'flex-end']}
                sx={{ flexDirection: 'row', display: 'flex' }}
              >
                <Grid>
                  <Grid item>
                    {props.formConfig &&
                      <Button
                        onClick={handleAddRow}
                        fullWidth
                        style={{ width: '100px',  margin: 10 }}
                        variant='contained'
                        color='primary'
                      >
                        CREAR
                      </Button>
                    }

                    <fieldset className={classes.fieldset}>
                      <legend className={classes.legend} style={{paddingLeft:5, fontSize:'10px'}}>FILTROS</legend>
                      
                        <TextField
                          label='Buscar'
                          placeholder={props.placeholder}
                          variant='outlined'
                          size='small'
                          style={{ margin: 5}}
                          name='search'
                          value={searchValue}
                          onKeyDown={handleKeyDownChange}
                          onChange={handleSearchChange}
                        />
                      {props.descargar &&
                      <>
                        <TextField
                          size='small'
                          type={'date'}
                          label={'Desde (Fecha pago)'}
                          variant='outlined'
                          style={{ margin: 5 }}
                          value={startDateDownload}
                          InputLabelProps={{ shrink: true }}
                          onChange={e => {
                            setStartDateDownload(e.target.value);
                          }}
                        />
                        <TextField
                          size='small'
                          type={'date'}
                          label={'Hasta (Fecha pago)'}
                          variant='outlined'
                          value={endDateDownload}
                          style={{ margin: 5 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={e => {
                            setEndDateDownload(e.target.value);
                          }}
                        />
                      <Button style={{ margin: 5 }} onClick={downloadExcelRows}  variant='contained' color='primary'>Descargar</Button>
                      </>
                      }
                    </fieldset>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        
        <Grid
          item
          style={{ height: '100%', marginTop: 12, width: 'auto' }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            rowHeight={50}
            paginationMode='server'
            pageSize={pageSize}
            rowCount={totalPages * pageSize}
            hideFooterPagination
            hideFooter
            onCellEditCommit={handleCellEditCommit}
            autoHeight
            components={
              {
                NoRowsOverlay: NoRowsOverlay,
              }
            }
            getRowClassName={getRowClassName}
            rowsPerPageOptions={[10, 25, 50, 100]} />
          
            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                shape='rounded'
                size='medium'
                variant='outlined'
                color='primary'
                style={{ padding: '8px', textAlign: 'center' }}
                onChange={(event, value) => setPage(value)}
              />
            </div>
        
        </Grid>
        {isAdding &&
        <Dialog maxWidth='lg' fullWidth={true} open={isAdding} onClose={handleCancelAdd}>
          <DialogTitle>Agregar Nuevo Registro</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2} >
              {formConfig?.map((field, index) => (
                <Grid item key={index} xs={12}>
                  <FormControl fullWidth variant="outlined">
                  {field.type === 'text' && (
                    <>
                    <InputLabel>{field.label}</InputLabel>
                    <OutlinedInput
                      label={field.label}
                      disabled={field.disabled}
                      value={newRowData[field.name]}
                      onChange={(e) =>{
                        setNewRowData({
                          ...newRowData,
                          [field.name]: e.target.value,
                        })
                      }}
                      error={field.required && !newRowData[field.name]}
                      fullWidth
                    />
                    </>
                  )}
                  {field.type === 'number' && (
                    <>
                    <InputLabel>{field.label}</InputLabel>
                    <OutlinedInput
                      label={field.label}
                      type='number'
                      disabled={field.disabled}
                      value={newRowData[field.name]}
                      onChange={(e) =>{
                        setNewRowData({
                          ...newRowData,
                          [field.name]: Number(e.target.value),
                        })
                      }}
                      error={field.required && !newRowData[field.name]}
                      fullWidth
                    />
                    </>
                  )}
                  {field.type === 'password' && (
                    <>
                    <InputLabel>{field.label}</InputLabel>
                    <OutlinedInput
                      type='password'
                      label={field.label}
                      disabled={field.disabled}
                      value={newRowData[field.name]}
                      onChange={(e) =>
                        setNewRowData({
                          ...newRowData,
                          [field.name]: e.target.value,
                        })
                      }
                      error={field.required && !newRowData[field.name]}
                      fullWidth
                    />
                    </>
                  )}
                  {field.type === 'email' && (
                    <>
                    <InputLabel>{field.label}</InputLabel>
                    <OutlinedInput
                      type='email'
                      label={field.label}
                      disabled={field.disabled}
                      value={newRowData[field.name]}
                      onChange={(e) =>
                        setNewRowData({
                          ...newRowData,
                          [field.name]: e.target.value,
                        })
                      }
                      error={field.required && !newRowData[field.name]}
                      fullWidth
                    />
                    </>
                  )}
                  {field.type === 'date' && (
                    <>
                    <InputLabel>{field.label}</InputLabel>
                    <OutlinedInput
                      type='date'
                      label={field.label}
                      disabled={field.disabled}
                      value={newRowData[field.name]}
                      onChange={(e) =>
                        setNewRowData({
                          ...newRowData,
                          [field.name]: e.target.value,
                        })
                      }
                      error={field.required && !newRowData[field.name]}
                      fullWidth
                    />
                    </>
                  )}
                  {field.type === 'select' && (
                    <>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        value={newRowData[field.name]}
                        disabled={field.disabled}
                        label={field.label}
                        onChange={(e) =>
                          setNewRowData({
                            ...newRowData,
                            [field.name]: e.target.value as string | number,
                          })
                        }
                        error={field.required && (!newRowData[field.name])}
                      >
                        {field.options?.map((opcion:{ value: string|number|boolean, label: string },index) => (
                          
                          <MenuItem key={index} value={opcion.value as string | number}>{opcion.label}</MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  {field.type === 'boolean' && (
                    <>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        value={newRowData[field.name] === true ? 1 : 0 }
                        disabled={field.disabled}
                        label={field.label}
                        onChange={(e) =>
                          setNewRowData({
                            ...newRowData,
                            [field.name]: e.target.value === 1 ? true : false,
                          })
                        }
                        error={field.required && (!newRowData[field.name])}
                      >
                        {field.options?.map((opcion:{ value: string|number|boolean, label: string },index) => (
                          
                          <MenuItem key={index} value={opcion.value === true ? 1 : 0}>{opcion.label}</MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  
                  {field.type === 'select-multiple' && (
                    <>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        multiple
                        value={newRowData[field.name]}
                        label={field.label}
                        disabled={field.disabled}
                        renderValue={(selected) => (selected as string[]).join(', ')}
                        onChange={(e) => {
                          let valor = e.target.value as string[];
                          if (valor.indexOf('Todos') > -1) {
                            valor = ['Todos']
                          }
                          setNewRowData({
                            ...newRowData,
                            [field.name]: valor,
                          })
                          }
                        }
                        error={field.required && (
                          (Array.isArray(newRowData[field.name]) ? newRowData[field.name].toString().length : 0) === 0
                        )}
                      >
                        {field.options?.map((opcion:{ value: string|number|boolean, label: string }) => (
                          
                          <MenuItem key={opcion.value as string} value={opcion.value as string | number} style={getStyles(opcion.value as string, newRowData[field.name] as string[], theme)}>
                            {opcion.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}

                  {field.type === 'select-multiple-autocomplete' && (
                    <>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={field.options as { value: string | number | boolean; label: string; }[]}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        onChange={(e,valor) => {
                          let valornuevo = valor.map((v) => v.value) as string[];
                          setNewRowData({
                            ...newRowData,
                            [field.name]: valornuevo,
                          })
                           }
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={field.label}
                            placeholder={field.label}
                          />
                        )}
                      />
                    </>
                  )}

                  

                  {field.type !== 'select-multiple' && field.type !== 'select-multiple-autocomplete' ? 
                    field.required && !newRowData[field.name] && (
                      <Typography color="error">{field.error}</Typography>
                    )
                  : field.required && (
                    (Array.isArray(newRowData[field.name]) ? newRowData[field.name].toString().length : 0) === 0
                  ) && (
                    <Typography color="error">{field.error}</Typography>
                  )}

                    
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveRow}>Guardar</Button>
            <Button onClick={handleCancelAdd}>Cancelar</Button>
          </DialogActions>
        </Dialog>
        }
        {confirmDelete && props.delete &&
          <Dialog
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"¿Estas seguro de eliminar el registro?"}</DialogTitle>
            <DialogActions>
              <Button onClick={() => setConfirmDelete(false)} color="primary">
                Cancelar
              </Button>
              <Button onClick={confirmDeleteRecord} color="primary" autoFocus>
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
        }
        <PageLoader loading={loading} />
      
    </Box>
  )
}

export default CrudDataGrid