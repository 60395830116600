/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { Edit, Delete } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import * as momentZone from 'moment-timezone';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { useContext, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Backdrop, Checkbox, createStyles, Fade, FormControlLabel, FormLabel, IconButton, InputLabel, ListItemIcon, makeStyles, Radio, RadioGroup, TextField, Theme } from '@material-ui/core';
/**
 * Internal Libraries
 */
import { CONTACTMPOS, contactOrigin, contactStatus, departamentos, listamunicipios, listapaises } from '../../resources/Statics';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Interfaces
 */
import { ContactsInterface, NegocioInterface, UserInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ContactsService from '../../services/ContactsService';
import NegociosServices from '../../services/NegociosServices';
import RegisterService from '../../services/RegisterService';
import Utilities from '../../libs/utilities';
/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
    },
  }),
);

export default function Contacts() {
  const { handleErrorMessage, handleActiveError, handleSelectRecord, handleSuccessMessage, handleActiveSuccess } =
    useContext(GeneralContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<NegocioInterface[]>([]);
  const [contacts, setContacts] = useState<ContactsInterface[]>([]);
  const [openModalEditContact, setOpenModalEditContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactsInterface>({});
  const [activities, setActivities] = useState<any[]>([]);
  const [subActivities, setSubActivities] = useState<any[]>([]);
  const [usuariosAfiliadores, setUsuariosAfiliadores] = useState<UserInterface[]>([]);

  useEffect(() => {
    getContacts();
    getBusiness();
  }, []);

  const handleChange = (prop: any) => (event: any) => {
    setSelectedContact({ ...selectedContact, [prop]: event.target.value });
  };

  const getUsuariosAdministradoresAfiliadores = async () => {
    setLoading(true)
    let req = await NegociosServices.instance.getUsuariosAdministradoresAfiliadores()
    setUsuariosAfiliadores(req.result)
    setLoading(false)
  }

  const handleCloseModal = () => {
    setOpenModalEditContact(false);
    setSelectedContact({});
  };

  const getContacts = () => {
    setLoading(true);
    ContactsService.instance
      .getContacts()
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setContacts(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getBusiness = () => {
    setLoading(true);
    NegociosServices.instance
      .get()
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setBusiness(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const selectContact = (selectedContact: any) => {
    setSelectedContact({ ...selectedContact });
    setOpenModalEditContact(true);
  };

  const deleteContact = (selectedContact: any) => {
    setLoading(true);
    ContactsService.instance.deleteContact(selectedContact, selectedContact.id).then(response => {
      const { error, result } = response;
      if (!error) {
        getContacts();
        handleSuccessMessage('message');
        handleActiveSuccess(true);
      } else {
        handleErrorMessage('message');
        handleActiveError(true);
      }
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      handleErrorMessage(e.message);
      handleActiveError(true);
    });
  }

  const editContact = async () => {
    setLoading(true);

    ContactsService.instance
      .updateContact(selectedContact, selectedContact.id)
      .then(response => {
        setLoading(false);
        const { error, message } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getContacts();
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });

    setOpenModalEditContact(false);
  };

  useEffect(() => {
      async function start() {
          let req = await RegisterService.instance.getActivities()
          let reqsub = await RegisterService.instance.getSubActivities()
          setActivities([{},...req.result])
          setSubActivities([{},...reqsub.result])
      }
      start();
      getUsuariosAdministradoresAfiliadores()
  }, [])

  const columns = [
    { field: 'nombre', headerName: 'Nombre', flex: 1, minWidth: 150 },
    { field: 'apellido', headerName: 'Apellido', flex: 1, minWidth: 150 },
    { field: 'nombreNegocio', headerName: 'Nombre del negocio', flex: 1, minWidth: 250 },
    {
      field: 'ActividadId',
      headerName: 'Actividad del negocio',
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => {
        return <span>{params?.row?.ActividadId ? activities[+(params?.row?.ActividadId ?? "0")]?.nombre.toLocaleUpperCase() : '' }</span>;
      },
    },
    {
      field: 'SubActividadId',
      headerName: 'Sub-Actividad del negocio',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params?.row?.SubActividadId ? subActivities[+(params?.row?.SubActividadId ?? "0")]?.nombre.toLocaleUpperCase() : ''}</span>;
      },
    },
    { field: 'telefono', headerName: 'Teléfono', flex: 1, minWidth: 150 },
    { field: 'telefono2', headerName: 'Teléfono 2', flex: 1, minWidth: 150 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
    { field: 'pais', headerName: 'Pais', flex: 1, minWidth: 150 },
    { field: 'departamento', headerName: 'Departamento', flex: 1, minWidth: 150 },
    { field: 'municipio', headerName: 'Municipio', flex: 1, minWidth: 150 },
    { field: 'direccion', headerName: 'Dirección', flex: 1, minWidth: 150 },
    { field: 'notas', headerName: 'Notas', flex: 1, minWidth: 200 },
    { field: 'origen', headerName: 'Origen', flex: 1, minWidth: 200 },
    {
      field: "UsuarioAfiliador",
      headerName: "Afiliador",
      sortable: false,
      minWidth: 200,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {

          let userAfiliadorId = ''
          let negocioId = ''
          if (
              params
              && params.row
              && params.row.UsuarioAfiliadorId
          ) userAfiliadorId = params.row.UsuarioAfiliadorId

          if (
              params
              && params.row
              && params.row.id
          ) negocioId = params.row.id

          return (<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
              <FormControl>
                  <Select
                      value={params.row.UsuarioAfiliadorId ? params.row.UsuarioAfiliadorId : 0}
                      onChange={(e) => {
                        setLoading(true);

                        ContactsService.instance
                          .updateContact({...params.row, UsuarioAfiliadorId: e.target.value}, params.row.id)
                          .then(response => {
                            setLoading(false);
                            const { error, message } = response;
                            if (!error) {
                              handleSuccessMessage(message);
                              handleActiveSuccess(true);
                              getContacts();
                            } else {
                              handleErrorMessage(message);
                              handleActiveError(true);
                            }
                          })
                          .catch(e => {
                            setLoading(false);
                            handleErrorMessage(e.message);
                            handleActiveError(true);
                          });
                      }}
                      name="age"
                      inputProps={{ 'aria-label': 'age' }}
                      style={{ minWidth: 150, }}
                  >
                      {
                          usuariosAfiliadores.map((user) => {
                              return <MenuItem style={{ minWidth: 150, }} key={user.id} value={user.id}>

                                  <ListItemIcon>
                                      <Checkbox style={{ display: 'inline' }} checked={params.row.UsuarioAfiliadorId == user.id} />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                      {user.nombre}
                                  </Typography>
                              </MenuItem>
                          })
                      }
                  </Select>

              </FormControl>
          </div>);
      }
  },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      minWidth: 200,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={params.row.estado}
                onChange={async (e: any) => {
                  setLoading(true);

                  ContactsService.instance
                    .updateContact({...params.row, estado: e.target.value}, params.row.id)
                    .then(response => {
                      setLoading(false);
                      const { error, message } = response;
                      if (!error) {
                        handleSuccessMessage(message);
                        handleActiveSuccess(true);
                        getContacts();
                      } else {
                        handleErrorMessage(message);
                        handleActiveError(true);
                      }
                    })
                    .catch(e => {
                      setLoading(false);
                      handleErrorMessage(e.message);
                      handleActiveError(true);
                    });
                }}
                name='age'
                inputProps={{ 'aria-label': 'age' }}
                style={{ minWidth: 150, lineHeight: 'unset' }}
              >
                {contactStatus.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status}
                      value={status}
                    >
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'Negocio',
      headerName: 'Negocio asignado',
      sortable: false,
      minWidth: 200,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        let assignedBusiness = params.row.Negocio ? params.row.Negocio.id : 'Sin negocio';
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                value={assignedBusiness}
                onChange={(e: any) => {
                  setLoading(true);

                  ContactsService.instance
                    .updateContact({ ...params.row, NegocioId: e.target.value }, params.row.id)
                    .then(response => {
                      setLoading(false);
                      const { error, message } = response;
                      if (!error) {
                        handleSuccessMessage(message);
                        handleActiveSuccess(true);
                        getContacts();
                      } else {
                        handleErrorMessage(message);
                        handleActiveError(true);
                      }
                    })
                    .catch(e => {
                      setLoading(false);
                      handleErrorMessage(e.message);
                      handleActiveError(true);
                    });
                }}
                name='bussiness'
                inputProps={{ 'aria-label': 'bussiness' }}
                style={{ minWidth: 150, lineHeight: 'unset' }}
              >
                {business.map(({ nombre, id }) => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={id}
                      value={id}
                    >
                      {nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'Acciones',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => selectContact(params.row)}
            >
              <Edit />
            </IconButton>
            <IconButton
              size='small'
              onClick={() => deleteContact(params.row)}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ maxHeight: '100vh', overflow: 'auto' }}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid item>
          <Grid container>
            <Grid
              item
              xs={10}
            >
              <Typography
                variant='h6'
                gutterBottom
              >
                CRM
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <Button
                component={Link}
                to='contacts/create'
                fullWidth
                variant='contained'
                color='primary'
              >
                Crear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ height: 500, width: 'auto' }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            loading={loading}
            rows={contacts}
            columns={columns}
            rowHeight={50}
            {...contacts}
          />
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={openModalEditContact}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalEditContact}>
          <div className={classes.paper} style={{maxHeight: "90vh", overflowY: 'scroll'}}>
            <Box style={{ paddingTop: '2rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
                key='t-0'
              >
                <Grid
                  container
                  item
                  direction='row'
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      label='Nombre'
                      variant='outlined'
                      value={selectedContact.nombre}
                      onChange={handleChange('nombre')}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      label='Apellido'
                      variant='outlined'
                      value={selectedContact.apellido}
                      onChange={handleChange('apellido')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    label='Nombre del negocio'
                    variant='outlined'
                    fullWidth
                    value={selectedContact.nombreNegocio}
                    onChange={handleChange('nombreNegocio')}
                  />
                </Grid>

                <Grid container item direction="row" spacing={3}>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="actividad-select-label" >Actividad del negocio</InputLabel>
                            <Select
                                labelId="actividad-select-label"
                                id="contact-activity-select"
                                label="Actividad del negocio"
                                value={selectedContact.ActividadId}
                                onChange={handleChange('ActividadId')}>
                                <MenuItem value="">
                                    <em>Seleccione</em>
                                </MenuItem>
                                {activities.map((act: any) => {
                                    return <MenuItem key={act.id} value={act.id}>{act?.nombre?.toLocaleUpperCase()}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="sub-actividad-select-label" >Sub-Actividad del negocio</InputLabel>
                            <Select
                                labelId="sub-actividad-select-label"
                                id="contact-subactivity-select"
                                label="SubActividad del negocio"
                                value={selectedContact.SubActividadId}
                                onChange={handleChange('SubActividadId')}>
                                <MenuItem value="">
                                    <em>Seleccione</em>
                                </MenuItem>
                                {subActivities.map((act: any) => {
                                    if (selectedContact.ActividadId == act.ActividadId || selectedContact.ActividadId === act.ActividadId) {
                                        return <MenuItem key={act.id} value={act.id}>{act.nombre?.toLocaleUpperCase() + ' - ' + Utilities.instance.aproximaDecimal(act.msc * 100, 3) + '%'}</MenuItem>
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                <Grid container item direction="row" spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      variant='outlined'
                    >
                      <InputLabel id='pais-select-label'>Pais</InputLabel>
                      <Select
                        labelId='pais-select-label'
                        id='pais-contactos-select'
                        label='Pais'
                        value={selectedContact.pais}
                        onChange={handleChange('pais')}
                      >
                        <MenuItem value=''>
                          <em>Seleccione</em>
                        </MenuItem>

                        {listapaises.map(pais => {
                          return (
                            <MenuItem
                              key={pais}
                              value={pais.toLocaleLowerCase()}
                            >
                              {pais}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      variant='outlined'
                    >
                      <InputLabel id='departammento-select-label'>Departamento</InputLabel>
                      <Select
                        labelId='departammento-select-label'
                        id='dep-contactos-select'
                        label='Departamento'
                        value={selectedContact.departamento}
                        onChange={handleChange('departamento')}
                      >
                        <MenuItem value=''>
                          <em>Seleccione</em>
                        </MenuItem>
                        {selectedContact &&
                        selectedContact.pais &&
                        departamentos &&
                        departamentos[selectedContact.pais] &&
                        Array.isArray(departamentos[selectedContact.pais])
                          ? departamentos[selectedContact.pais].map((dep: any) => {
                              return (
                                <MenuItem
                                  key={dep}
                                  value={dep.toLocaleLowerCase()}
                                >
                                  {dep}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                
                <Grid container item direction="row" spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      variant='outlined'
                    >
                      <InputLabel id='municipio-select-label'>Municipio</InputLabel>
                      <Select
                        labelId='municipio-select-label'
                        id='muni-contactos-select'
                        label='Municipio'
                        value={selectedContact.municipio}
                        onChange={handleChange('municipio')}
                      >
                        <MenuItem value=''>
                          <em>Seleccione</em>
                        </MenuItem>
                        {selectedContact &&
                        selectedContact.departamento &&
                        listamunicipios &&
                        listamunicipios[selectedContact.departamento] &&
                        Array.isArray(listamunicipios[selectedContact.departamento])
                          ? listamunicipios[selectedContact.departamento].map((municipio: any) => {
                              return (
                                <MenuItem
                                  key={municipio}
                                  value={municipio.toLocaleLowerCase()}
                                >
                                  {municipio}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label='Dirección'
                      variant='outlined'
                      fullWidth
                      value={selectedContact.direccion}
                      onChange={handleChange('direccion')}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction='row'
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      label='Teléfono'
                      variant='outlined'
                      value={selectedContact.telefono}
                      onChange={handleChange('telefono')}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      label='Teléfono 2'
                      variant='outlined'
                      value={selectedContact.telefono2}
                      onChange={handleChange('telefono2')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label='Email'
                    variant='outlined'
                    value={selectedContact.email}
                    onChange={handleChange('email')}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label='Notas'
                    variant='outlined'
                    value={selectedContact.notas}
                    onChange={handleChange('notas')}
                  />
                </Grid>
                
                <Grid container item direction="row" spacing={3}>
                  <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        variant='outlined'
                      >
                        <InputLabel id='origen-select-label'>Origen</InputLabel>
                        <Select
                          labelId='origen-select-label'
                          id='origen-select'
                          label='Origen'
                          value={selectedContact.origen}
                          onChange={handleChange('origen')}
                        >
                          <MenuItem>
                            <em>Seleccione</em>
                          </MenuItem>
                          {contactOrigin.map(origin => {
                            return (
                              <MenuItem
                                key={origin}
                                value={origin}
                              >
                                {origin.toUpperCase()}
                              </MenuItem>
                            );
                          })}
                        </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        variant='outlined'
                      >
                        <InputLabel id='estado-select-label'>Estado</InputLabel>
                        <Select
                          labelId='estado-select-label'
                          id='estado-select'
                          label='Estado'
                          value={selectedContact.estado}
                          onChange={handleChange('estado')}
                        >
                          <MenuItem>
                            <em>Seleccione</em>
                          </MenuItem>
                          {contactStatus.map(status => {
                            return (
                              <MenuItem
                                key={status}
                                value={status}
                              >
                                {status.toUpperCase()}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                  </Grid>
                </Grid>

                <Grid item>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="afiliador-select-label">Usuario afiliador</InputLabel>
                        <Select
                            labelId="afiliador-select-label"
                            id="contact-afiliate-select"
                            label="Usuario afiliador"
                            value={selectedContact.UsuarioAfiliadorId}
                            onChange={handleChange('UsuarioAfiliadorId')}>
                            <MenuItem value="">
                                <em>Seleccione</em>
                            </MenuItem>
                            {
                                  usuariosAfiliadores.map((user) => {
                                      return <MenuItem style={{ minWidth: 150, }} key={user.id} value={user.id}>
                                          <Typography variant="inherit" noWrap>
                                              {user.nombre}
                                          </Typography>
                                      </MenuItem>
                                  })
                              }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <Paper variant="outlined" style={{ padding: '16px' }}>
                        <FormControl>
                            <RadioGroup
                              row
                              value={selectedContact.mPOS}
                              onChange={handleChange('mPOS')}
                              name="row-radio-buttons-group"
                              aria-labelledby="mpos-row-radio-buttons-group-label"
                            >
                              <FormControlLabel value={CONTACTMPOS.VIRGEN} control={<Radio color="primary"/>} label="Virgen" />
                              <FormControlLabel value={CONTACTMPOS.MEDIOVIRGEN} control={<Radio  color="primary"/>} label="Medio Virgen" />
                              <FormControlLabel value={CONTACTMPOS.NOVIRGEN} control={<Radio  color="primary"/>} label="No Virgen" />
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='row'
                    spacing={3}
                    key='t-0'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Button
                        size='medium'
                        color='primary'
                        variant='contained'
                        onClick={() => editContact()}
                      >
                        Editar Contacto
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>

      <PageLoader loading={loading} />
    </Box>
  );
}
