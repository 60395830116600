import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import {
  tiposNotificacion,
  modosNotificacion,
  estadosNotificacion,
  parametrosNotificacion,
} from '../../resources/Statics';
import utilities from '../../libs/utilities';
import LoadingButton from '../../components/LoadingButton';
import localStorage from '../../libs/localStorage';
import { useHistory } from 'react-router-dom';
import MarketingService from '../../services/MarketingService';

export default function MarketingEdit() {
  let fechaDate = new Date();
  let fecha = utilities.convertirFecha(fechaDate, 'date', 'YYYY-MM-DD 00:00:00');

  const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess, selectedRecord } =
    useContext(GeneralContext);

  const [resultado, setResultado] = React.useState<any[]>([]);

  useEffect(() => {
    obtenerCampana();
  }, []);

  const obtenerCampana = () => {
    setLoading(true);
    MarketingService.instance
      .obtenerCampana(selectedRecord.id)
      .then(response => {
        const { error, result } = response;
        if (!error) {
          setValues(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = React.useState(false);

  let history = useHistory();

  const guardarCampana = async () => {
    if (!loading) {
      setLoading(true);
      let UsuarioAfiliadorID = await localStorage.instance.getUserId();
      MarketingService.instance
        .actualizarCampana({ ...values }, UsuarioAfiliadorID)
        .then(response => {
          setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            history.push('/admin-home/marketing');
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const elminarCampana = async () => {
    values.isDelete = true;
    if (!loading) {
      setLoading(true);
      let UsuarioAfiliadorID = await localStorage.instance.getUserId();
      MarketingService.instance
        .actualizarCampana({ ...values }, UsuarioAfiliadorID)
        .then(response => {
          setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            history.push('/admin-home/marketing');
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const [values, setValues] = useState({
    id: -1,
    nombre: '',
    plataforma: '',
    titulo: '',
    cuerpo: '',
    tipo: '',
    modo: '',
    estado: '',
    fecha: '',
    parametro: '',
    dias: 0,
    hora: '',
    isDelete: false,
  });

  const obtenerSeccionMensaje = (tipo: string) => {
    if (tipo == 'email') {
      return (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              Mensaje
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Asunto'
              variant='outlined'
              fullWidth
              value={values.titulo}
              onChange={handleChange('titulo')}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Plantilla HTML'
              variant='outlined'
              fullWidth
              value={values.cuerpo}
              onChange={handleChange('cuerpo')}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='subtitle1'
              gutterBottom
            >
              Mensaje
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Titulo'
              variant='outlined'
              fullWidth
              value={values.titulo}
              onChange={handleChange('titulo')}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Cuerpo'
              variant='outlined'
              fullWidth
              value={values.cuerpo}
              onChange={handleChange('cuerpo')}
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
    >
      <Grid
        container
        direction='row'
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='h6'
            gutterBottom
          >
            Editar campaña
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Datos generales
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            label='Nombre de la campaña'
            variant='outlined'
            fullWidth
            value={values.nombre}
            onChange={handleChange('nombre')}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='tipo-select-label'>Tipo de notificación</InputLabel>
            <Select
              labelId='tipo-select-label'
              id='campaña-select'
              label='Tipo de notificación'
              value={values.tipo}
              onChange={handleChange('tipo')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {tiposNotificacion.map((act: any) => {
                return (
                  <MenuItem
                    key={act.id}
                    value={act.id}
                  >
                    {act.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {/*{values.tipo === "notificacion-push" && (
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="plataforma-select-label">Plataforma</InputLabel>
              <Select
                labelId="plataforma-select-label"
                id="campaña-select"
                label="Plataforma para notificación"
                value={values.plataforma}
                onChange={handleChange("plataforma")}
              >
                <MenuItem value="">
                  <em>Seleccione</em>
                </MenuItem>
                {plataformasNotificacion.map((act: any) => {
                  return (
                    <MenuItem key={act.id} value={act.id}>
                      {act.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}*/}
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Configuración
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='modo-select-label'>Modo de notificación</InputLabel>
            <Select
              labelId='modo-select-label'
              id='campaña-select'
              label='Tipo de notificación'
              value={values.modo}
              onChange={handleChange('modo')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {modosNotificacion.map((act: any) => {
                return (
                  <MenuItem
                    key={act.id}
                    value={act.id}
                  >
                    {act.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={8}
        >
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='parametro-select-label'>Parametros</InputLabel>
            <Select
              labelId='parametro-select-label'
              id='campaña-select'
              label='Parametros'
              value={values.parametro}
              onChange={handleChange('parametro')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {parametrosNotificacion.map((act: any) => {
                return (
                  <MenuItem
                    key={act.id}
                    value={act.id}
                  >
                    {act.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='estado-select-label'>Estado de campaña</InputLabel>
            <Select
              labelId='estado-select-label'
              id='campaña-select'
              label='Estado de campaña'
              value={values.estado}
              onChange={handleChange('estado')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {estadosNotificacion.map((act: any) => {
                return (
                  <MenuItem
                    key={act.id}
                    value={act.id}
                  >
                    {act.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {values.modo != 'manual' && (
          <>
            <Grid
              item
              xs={2}
            >
              <TextField
                label='Dias de inactividad'
                variant='outlined'
                fullWidth
                value={values.dias}
                type='number'
                onChange={handleChange('dias')}
              />
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                style={{ width: 200, marginLeft: 5 }}
                id='time'
                label='Seleciona una hora'
                type='time'
                onChange={handleChange('hora')}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Grid>
          </>
        )}
        {obtenerSeccionMensaje(values.tipo)}
        <Grid
          item
          xs={3}
        >
          <LoadingButton
            loading={loading}
            color='primary'
            variant='contained'
            title='Guardar cambios '
            onClick={guardarCampana}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <LoadingButton
            loading={loading}
            color='primary'
            variant='contained'
            title='Eliminar'
            onClick={elminarCampana}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
