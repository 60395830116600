const DEV = false;
const TEST = false;

export class Rutas {
    static instance = new Rutas()

    public urlBaseDev = "http://localhost:4160/api";
    public urlBaseHostValidation = DEV ? "http://localhost:3000" : TEST ? "https://pay-staging.paggoapp.com" : "https://web.paggoapp.com";
    public urlBaseHost = process.env.WEB_HOST || this.urlBaseHostValidation;
    public urlBaseProd = process.env.REACT_APP_URL_BASE || 'https://api.paggoapp.com/api';
    public urlBaseTest = 'https://api-staging.paggoapp.com/api';
    public urlAnalyzeFiles = 'https://fvknxficw5.execute-api.us-east-2.amazonaws.com/staging'
    public urlBase = DEV ? this.urlBaseDev : TEST ? this.urlBaseTest : this.urlBaseProd;
    public urlRegistro = '/registro'
    public urlRegistroUsuario = '/procesoRegistroUsuario'
    public urlUsuario = '/usuario'
    public urlActividad = '/actividad'
    public urlFEL = '/facturacionNegocios'
    public urlSubActividad = '/subActividad'
    public urlPagoEnlace = '/pagoEnlace'
    public urlDocumentos = '/documento'
    public urlDocumentoRespaldo = '/documentoRespaldo'
    public urlHistorialDocumentoRespaldo = '/historialDocumentoRespaldo'
    public urlHistorialBusqueda = '/historialBusqueda'
    public urlNegocio = '/negocio'
    public urlPais = '/pais'
    public urlRole = '/rol'
    public urlReportes = '/reporteria'
    public urlLogs = '/logs'
    public urlInvitacion = '/invitacion'
    public urlInformacionBancaria = '/informacionBancaria'
    public urlMpos = '/mpos'
    public urlMarketing = '/marketing'
    public urlContacts = '/contacts'
    public urlFacturasComision = '/facturasComision'
    public urlDealers = '/dealers'
    public mposInventory = '/mposInventory'
    public urlContracargo = '/contracargo'
    public urlAbonoContracargo = '/abonoContracargo'
    public urlAbonoContracargoOtrosMedios = '/createAbonoOtrosMedios'
    public urlRazones = '/razon'
    public urlPermisos = '/permiso'
    public urlEndPoint = '/endpoint'
    public urlRutaMenu = '/rutaMenu'
    public urlDocumentosRazones = '/documentoRazon'
    public urlGraficas = '/graficas'
    public urlPagoTarjeta = '/pagoTarjeta'
    public urlDocumentosContracargo = '/documentoContracargo'
    public urlUbicacionesInventarioMpos = '/ubicacion/inventario/mpos'
    public urlPagoEfectivo = '/pagoEfectivo'
    public urlOrden = '/orden'
    public urlOrdenEstados = '/ordenEstados'
    public urlOrdenMetodosPago = '/ordenMetodosPago'
    public urlOrdenTipoLogistica = '/ordenTipoLogistica'
    public urlOrdenMpos = '/ordenMpos'
    public urlMovimiento = '/movimiento'
    public urlLotesInventarioMpos = '/lote/inventario/mpos'
    public urlSolicitudPos = '/solicitudPos'
    public urlTiposRegimenes = '/tipoRegimen'
}

export class Permissions {
    static instance = new Permissions()

    public readCredentialsUser = 'api-read';
    public readCredentialsPass = '2fFxsK8Xfm';

    public writeCredentialsUser = 'api-write';
    public writeCredentialsPass = 'PgmHWYsVRr';

    public deleteCredentialsUser = 'api-delete';
    public deleteCredentialsPass = 'blOI6SZvIm';

}

export const categorias: string[] = ["ALIMENTOS", "TEXTIL", "INSUMOS", "CONSTRUCCION"];
export const subcategorias: any = {
    "alimentos": ["TIENDA DE BARRIO", "ABARROTERIA", "VERDULERIA", "DEPOSITO", "PANADERIA"],
    'textil': ["INSUMOS PARA SECTOR TEXTIL", "SASTRERIA", "PACA", "BOUTIQUE"],
    'insumos': ["LIBRERIA", "IMPRESIONES"],
    'construccion': ["FERRETERIA", "BLOQUERA"],
}
export const listapaises: string[] = ['GUATEMALA', 'HONDURAS'];
//export const departamentos: string[] = ["GUATEMALA", "EL PROGRESO", "SACATEPEQUEZ", "CHIMALTENANGO", "ESCUINTLA", "SANTA ROSA", "SOLOLA", "TOTONICAPAN", "QUETZALTENANGO", "SUCHITEPEQUEZ", "RETALHULEU", "SAN MARCOS", "HUEHUETENANGO", "QUICHE", "BAJA VERAPAZ", "ALTA VERAPAZ", "PETEN", "IZABAL", "ZACAPA", "CHIQUIMULA", "JALAPA", "JUTIAPA"];
export const departamentos: any = {
    'guatemala': ["GUATEMALA", "EL PROGRESO", "SACATEPEQUEZ", "CHIMALTENANGO", "ESCUINTLA", "SANTA ROSA", "SOLOLA", "TOTONICAPAN", "QUETZALTENANGO", "SUCHITEPEQUEZ", "RETALHULEU", "SAN MARCOS", "HUEHUETENANGO", "QUICHE", "BAJA VERAPAZ", "ALTA VERAPAZ", "PETEN", "IZABAL", "ZACAPA", "CHIQUIMULA", "JALAPA", "JUTIAPA"],
    'honduras': ["ATLÁNTIDA", "CHOLUTECA", "COLÓN", "COMAYAGUA", "COPÁN", "CORTÉS", "EL PARAÍSO", "FRANCISCO MORAZÁN", "GRACIAS A DIOS", "INTIBUCÁ", "ISLAS DE LA BAHÍA", "LA PAZ", "LEMPIRA", "OCOTEPEQUE", "OLANCHO", "SANTA BÁRBARA", "VALLE", "YORO"]
}
export const listamunicipios: any = {
    /* GUATEMALA */
    "guatemala": ["GUATEMALA", "SANTA CATARINA PINULA", "SAN JOSE PINULA", "SAN JOSE DEL GOLFO", "PALENCIA", "CHINAUTLA", "SAN PEDRO AYAMPUC", "MIXCO", "SAN PEDRO SACATEPEQUEZ", "SAN JUAN SACATEPEQUEZ", "SAN RAYMUNDO", "CHUARRANCHO", "FRAIJANES", "AMATITLAN", "VILLA NUEVA", "VILLA CANALES", "SAN MIGUEL PETAPA"],
    'el progreso': ["GUASTATOYA", "MORAZAN", "SAN AGUSTIN ACASAGUASTLAN", "SAN CRISTOBAL ACASAGUASTLAN", "EL JICARO", "SANSARE", "SANARATE", "SAN ANTONIO LA PAZ"],
    'sacatepequez': ["ANTIGUA", "JOCOTENANGO", "PASTORES", "SUMPANGO", "SANTO DOMINGO XENACOJ", "SANTIAGO SACATEPEQUEZ", "SAN BARTOLOME MILPAS ALTAS", "SAN LUCAS SACATEPEQUEZ", "SANTA LUCIA MILPAS ALTAS", "MAGDALENA MILPAS ALTAS", "SANTA MARIA DE JESUS", "CIUDAD VIEJA", "SAN MIGUEL DUENAS", "ALOTENANGO", "SAN ANTONIO AGUAS CALIENTES", "SANTA CATARINA BARAHONA"],
    'chimaltenango': ["CHIMALTENANGO", "SAN JOSE POAQUIL", "SAN MARTIN JILOTEPEQUE", "SAN JUAN COMALAPA", "SANTA APOLONIA", "TECPAN GUATEMALA", "PATZUN", "SAN MIGUEL POCHUTA", "PATZICIA", "SANTA CRUZ BALANYA", "ACATENANGO", "SAN PEDRO YEPOCAPA", "SAN ANDRES ITZAPA", "PARRAMOS", "ZARAGOZA", "EL TEJAR"],
    'escuintla': ["ESCUINTLA", "SANTA LUCIA COTZUMALGUAPA", "LA DEMOCRACIA", "SIQUINALA", "MASAGUA", "TIQUISATE", "LA GOMERA", "GUANAGAZAPA", "SAN JOSE", "IZTAPA", "PALIN", "SAN VICENTE PACAYA"],
    'santa rosa': ["CUILAPA", "BARBERENA", "SANTA ROSA DE LIMA", "CASILLAS", "SAN RAFAEL LAS FLORES", "ORATORIO", "SAN JUAN TECUACO", "CHIQUIMULILLA", "TAXISCO", "SANTA MARIA IXHUATAN", "GUAZACAPAN", "SANTA CRUZ NARANJO", "municipio NUEVO VIÑAS", "NUEVA SANTA ROSA"],
    'solola': ["SOLOLA", "SAN JOSE CHACAYA", "SANTA MARIA VISITACION", "SANTA LUCIA UTATLAN", "NAHUALA", "SANTA CATARINA IXTAHUACAN", "SANTA CLARA LA LAGUNA", "CONCEPCION", "SAN ANDRES SEMETABAJ", "PANAJACHEL", "SANTA CATARINA PALOPO", "SAN ANTONIO PALOPO", "SAN LUCAS TOLIMAN", "SANTA CRUZ LA LAGUNA", "SAN PABLO LA LAGUNA", "SAN MARCOS LA LAGUNA", "SAN JUAN LA LAGUNA", "SAN PEDRO LA LAGUNA", "SANTIAGO ATITLAN"],
    'totonicapan': ["TOTONICAPAN", "SAN CRISTOBAL TOTONICAPAN", "SAN FRANCISCO EL ALTO", "SAN ANDRES XECUL", "MOMOSTENANGO", "SANTA MARIA CHIQUIMULA", "SANTA LUCIA LA REFORMA", "SAN BARTOLO AGUAS CALIENTES"],
    'quetzaltenango': ["QUETZALTENANGO", "SALCAJA", "OLINTEPEQUE", "SAN CARLOS SIJA", "SIBILIA", "CABRICAN", "CAJOLA", "SAN MIGUEL SIGUILA", "SAN JUAN OSTUNCALCO", "SAN MATEO", "CONCEPCION CHIQUIRICHAPA", "SAN MARTIN SACATEPEQUEZ", "ALMOLONGA", "CANTEL", "HUITAN", "ZUNIL", "COLOMBA COSTA CUCA", "SAN FRANCISCO LA UNION", "EL PALMAR", "COATEPEQUE", "GENOVA COSTA CUCA", "FLORES COSTA CUCA", "LA ESPERANZA", "PALESTINA DE LOS ALTOS"],
    'suchitepequez': ["MAZATENANGO", "CUYOTENANGO", "SAN FRANCISCO ZAPOTITLAN", "SAN BERNARDINO", "SAN JOSE EL IDOLO", "SANTO DOMINGO SUCHITEPEQUEZ", "SAN LORENZO", "SAMAYAC", "SAN PABLO JOCOPILAS", "SAN ANTONIO SUCHITEPEQUEZ", "SAN MIGUEL PANAM", "SAN GABRIEL", "CHICACAO", "PATULUL", "SANTA BARBARA", "SAN JUAN BAUTISTA", "SANTO TOMAS LA UNION", "ZUNILITO", "municipio NUEVO", "RIO BRAVO"],
    'retalhuleu': ["RETALHULEU", "SAN SEBASTIAN", "SANTA CRUZ MULUA", "SAN MARTIN ZAPOTITLAN", "SAN FELIPE", "SAN ANDRES VILLA SECA", "CHAMPERICO", "NUEVO SAN CARLOS", "EL ASINTAL"],
    'san marcos': ["SAN MARCOS", "SAN PEDRO SACATEPEQUEZ", "SAN ANTONIO SACATEPEQUEZ", "COMITANCILLO", "SAN MIGUEL IXTAHUACAN", "CONCEPCION TUTUAPA", "TACANA", "SIBINAL", "TAJUMULCO", "TEJUTLA", "SAN RAFAEL PIE DE LA CUESTA", "NUEVO PROGRESO", "EL TUMBADOR", "SAN JOSE EL RODEO", "MALACATAN", "CATARINA", "AYUTLA (TECUN UMAN)", "OCOS", "SAN PABLO", "EL QUETZAL", "LA REFORMA", "PAJAPITA", "IXCHIGUAN", "SAN JOSE OJETENAN", "SAN CRISTOBAL CUCHO", "SIPACAPA", "ESQUIPULAS PALO GORDO", "RIO BLANCO", "SAN LORENZO"],
    'huehuetenango': ["HUEHUETENANGO", "CHIANTLA", "MALACATANCITO", "CUILCO", "NENTON", "SAN PEDRO NECTA", "JACALTENANGO", "SAN PEDRO SOLOMA", "SAN ILDEFONSO IXTAHUACAN", "SANTA BARBARA", "LA LIBERTAD", "LA DEMOCRACIA", "SAN MIGUEL ACATAN", "SAN RAFAEL LA INDEPENDENCIA", "TODOS SANTOS CUCHUMATAN", "SAN JUAN ATITAN", "SANTA EULALIA", "SAN MATEO IXTATAN", "COLOTENANGO", "SAN SEBASTIAN HUEHUETENANGO", "TECTITAN", "CONCEPCION HUISTA", "SAN JUAN IXCOY", "SAN ANTONIO HUISTA", "SAN SEBASTIAN COATAN", "SANTA CRUZ BARILLAS", "AGUACATAN", "SAN RAFAEL PETZAL", "SAN GASPAR IXCHIL", "SANTIAGO CHIMALTENANGO", "SANTA ANA HUISTA", "UNIÓN CANTINIL"],
    'quiche': ["SANTA CRUZ DEL QUICHE", "CHICHE", "CHINIQUE", "ZACUALPA", "CHAJUL", "STO TOMAS CHICHICASTENANGO", "PATZITE", "SAN ANTONIO ILOTENANGO", "SAN PEDRO JOCOPILAS", "CUNEN", "SAN JUAN COTZAL", "JOYABAJ", "NEBAJ", "SAN ANDRES SAJCABAJA", "SAN MIGUEL USPANTAN", "SACAPULAS", "SAN BARTOLOME JOCOTENANGO", "CANILLA", "CHICAMAN", "IXCAN", "PACHALUN", "PLAYA GRANDE"],
    'baja verapaz': ["SALAMA", "SAN MIGUEL CHICAJ", "RABINAL", "CUBULCO", "GRANADOS", "SANTA CRUZ EL CHOL", "SAN JERONIMO", "PURULHA"],
    'alta verapaz': ["COBAN", "SANTA CRUZ VERAPAZ", "SAN CRISTOBAL VERAPAZ", "TACTIC", "TAMAHU", "SAN MIGUEL TUCURU", "PANZOS", "SENAHU", "SAN PEDRO CARCHA", "SAN JUAN CHAMELCO", "LANQUIN", "SANTA MARIA CAHABON", "CHISEC", "CHAHAL", "FRAY BARTOLOME DE LAS CASAS", "LA TINTA", "RAXRUHÁ"],
    'peten': ["FLORES", "SAN JOSE", "SAN BENITO", "SAN ANDRES", "LA LIBERTAD", "SAN FRANCISCO", "SANTA ANA", "DOLORES", "SAN LUIS", "SAYAXCHE", "MELCHOR DE MENCOS", "POPTUN"],
    'izabal': ["PUERTO BARRIOS", "LIVINGSTON", "EL ESTOR", "MORALES", "LOS AMATES"],
    'zacapa': ["ZACAPA", "ESTANZUELA", "RIO HONDO", "GUALAN", "TECULUTAN", "USUMATLAN", "CABANAS", "SAN DIEGO", "LA UNION", "HUITE"],
    'chiquimula': ["CHIQUIMULA", "SAN JOSE LA ARADA", "SAN JUAN LA ERMITA", "JOCOTAN", "CAMOTAN", "OLOPA", "ESQUIPULAS", "CONCEPCION LAS MINAS", "QUEZALTEPEQUE", "SAN JACINTO", "IPALA"],
    'jalapa': ["JALAPA", "SAN PEDRO PINULA", "SAN LUIS JILOTEPEQUE", "SAN MANUEL CHAPARRON", "SAN CARLOS ALZATATE", "MONJAS", "MATAQUESCUINTLA"],
    'jutiapa': ["JUTIAPA", "EL PROGRESO", "SANTA CATARINA MITA", "AGUA BLANCA", "ASUNCION MITA", "YUPILTEPEQUE", "ATESCATEMPA", "JEREZ", "EL ADELANTO", "ZAPOTITLAN", "COMAPA", "JALPATAGUA", "CONGUACO", "MOYUTA", "PASACO", "SAN JOSE ACATEMPA", "QUESADA"],

    /* HONDURAS */
    "atlántida": ["LA CEIBA", "EL PORVENIR", "TELA", "JUTIAPA", "LA MASICA", "SAN FRANCISCO", "ARIZONA", "VILLA DE SAN FRANCISCO"],
    "choluteca": ["CHOLUTECA", "APACILAGUA", "CONCEPCIÓN DE MARIA", "DUYURE", "EL CORPUS", "EL TRIUNFO", "MARCOVIA", "MOROLICA", "NAMASIGÜE", "OROCUINA", "PESPIRE", "SAN ANTONIO DE FLORES", "SAN ISIDRO", "SAN JOSÉ", "SAN MARCOS DE COLÓN", "SANTA ANA DE YUSGUARE"],
    "colón": ["TRUJILLO", "BALFATE", "IRIONA", "LIMÓN", "SABÁ", "SANTA FE", "SANTA ROSA DE AGUÁN", "SONAGUERA", "TOCOA", "BONITO ORIENTAL"],
    "comayagua": ["COMAYAGUA", "AJUTERIQUE", "EL ROSARIO", "ESQUÍAS", "HUMUYA", "LA LIBERTAD", "LAMANÍ", "LA TRINIDAD", "LEJAMANÍ", "MEÁMBAR", "MINAS DE ORO", "OJOS DE AGUA", "SAN JERÓNIMO", "SAN JOSÉ DE COMAYAGUA", "SAN JOSÉ DEL POTRERO", "SAN LUIS", "SAN SEBASTIÁN", "SIGUATEPEQUE", "VILLA DE SAN ANTONIO", "LAS LAJAS", "TAULABÉ"],
    "copán": ["SANTA ROSA DE COPÁN", "CABAÑAS", "CONCEPCIÓN", "COPÁN RUINAS", "CORQUÍN", "CUCUYAGUA", "DOLORES", "DULCE NOMBRE", "EL PARAÍSO", "FLORIDA", "LA JIGUA", "LA UNIÓN", "NUEVA ARCADIA", "SAN AGUSTÍN", "SAN ANTONIO", "SAN JERÓNIMO", "SAN JOSÉ", "SAN JUAN DE OPOA", "SAN NICOLÁS", "SAN PEDRO", "SANTA RITA", "TRINIDAD DE COPÁN", "VERACRUZ"],
    "cortés": ["SAN PEDRO SULA", "CHOLOMA", "OMOA", "PIMIENTA", "POTRERILLOS", "PUERTO CORTÉS", "SAN ANTONIO DE CORTÉS", "SAN FRANCISCO DE YOJOA", "SAN MANUEL", "SANTA CRUZ DE YOJOA", "VILLANUEVA", "LA LIMA"],
    "el paraíso": ["YUSCARÁN", "ALAUCA", "DANLÍ", "EL PARAÍSO", "GÜINOPE", "JACALEAPA", "LIURE", "MOROCELÍ", "OROPOLÍ", "POTRERILLOS", "SAN ANTONIO DE FLORES", "SAN LUCAS", "SAN MATÍAS", "SOLEDAD", "TEUPASENTI", "TEXIGUAT", "VADO ANCHO", "YAUYUPE", "TROJES"],
    "francisco morazán": ["DISTRITO CENTRAL", "ALUBARÉN", "CEDROS", "CURARÉN", "EL PORVENIR", "GUAIMACA", "LA LIBERTAD", "LA VENTA", "LEPATERIQUE", "MARAITA", "MARALE", "NUEVA ARMENIA", "OJOJONA", "ORICA", "REITOCA", "SABANAGRANDE", "SAN ANTONIO DE ORIENTE", "SAN BUENAVENTURA", "SAN IGNACIO", "SAN JUAN DE FLORES", "SAN MIGUELITO", "SANTA ANA", "SANTA LUCÍA", "TALANGA", "TATUMBLA", "VALLE DE ÁNGELES", "VILLA DE SAN FRANCISCO", "VALLECILLO"],
    "gracias a dios": ["PUERTO LEMPIRA", "BRUS LAGUNA", "AHUAS", "JUAN FRANCISCO BULNES", "VILLEDA MORALES", "WAMPÚSIRPE"],
    "intibucá": ["LA ESPERANZA", "CAMASOLO", "COLOMONCAGUA", "CONCEPCIÓN", "DOLORES", "INTIBUCÁ", "JESÚS DE OTORO", "MAGDALENA", "MASAGUARA", "SAN ANTONIO", "SAN ISIDRO", "SAN JUAN", "SAN MARCOS DE LA SIERRA", "SAN MIGUELITO", "SANTA LUCÍA", "YAMARANGUILA", "SAN FRANCISCO DE OPALACA"],
    "islas de la bahía": ["ROATÁN", "GUANAJA", "JOSÉ SANTOS GUARDIOLA", "UTILA"],
    "la paz": ["LA PAZ", "AGUANQUETERIQUE", "CABAÑAS", "CANE", "CHINACLA", "GUAJIQUIRO", "LAUTERIQUE", "MARCALA", "MERCEDES DE ORIENTE", "OPATORO", "SAN ANTONIO DEL NORTE", "SAN JOSÉ", "SAN JUAN", "SAN PEDRO DE TUTULE", "SANTA ANA", "SANTA ELENA", "SANTA MARÍA", "SANTIAGO DE PURINGLA", "YARULA"],
    "lempira": ["GRACIAS", "BELÉN", "CANDELARIA", "COLOLACA", "ERANDIQUE", "GUALCINCE", "GUARITA", "LA CAMPA", "LA IGUALA", "LAS FLORES", "LA UNIÓN", "LA VIRTUD", "LEPAERA", "MAPULACA", "PIRAERA", "SAN ANDRÉS", "SAN FRANCISCO", "SAN JUAN GUARITA", "SAN MANUEL COLOHETE", "SAN RAFAEL", "SAN SEBASTIÁN", "SANTA CRUZ", "TALGUA", "TAMBLA", "TOMALÁ", "VALLADOLID", "VIRGINIA", "SAN MARCOS DE CAIQUÍN"],
    "ocotepeque": ["OCOTEPEQUE", "BELÉN GUALCHO", "CONCEPCIÓN", "DOLORES MERENDÓN", "FRATERNIDAD", "LA ENCARNACIÓN", "LA LABOR", "LUCERNA", "MERCEDES", "SAN FERNANDO", "SAN FRANCISCO DEL VALLE", "SAN JORGE", "SAN MARCOS", "SANTA FE", "SENSUNTEPEQUE", "SINUAPA"],
    "olancho": ["JUTICALPA", "CAMPAMENTO", "CATACAMAS", "CONCORDIA", "DULCE NOMBRE DE CULMÍ", "EL ROSARIO", "ESQUIPULAS DEL NORTE", "GUALACO", "GUARIZAMA", "GUATA", "GUAYAPE", "JANO", "LA UNIÓN", "MANGULILE", "MANTO", "SALAMÁ", "SAN ESTEBAN", "SAN FRANCISCO DE BECERRA", "SAN FRANCISCO DE LA PAZ", "SANTA MARÍA DEL REAL", "SILCA", "YOCÓN", "PATUCA"],
    "santa bárbara": ["SANTA BÁRBARA", "ARADA", "ATIMA", "AZACUALPA", "CEGUACA", "CONCEPCIÓN DEL NORTE", "CONCEPCIÓN DEL SUR", "CHINDA", "EL NÍSPERO", "GUALALA", "ILAMA", "LAS VEGAS", "MACUELIZO", "NARANJITO", "NUEVO CELILAC", "NUEVA FRONTERA", "PETOA", "PROTECCIÓN", "QUIMISTÁN", "SAN FRANCISCO DE OJUERA", "SAN JOSÉ DE LAS COLINAS", "SAN LUIS", "SAN MARCOS", "SAN NICOLÁS", "SAN PEDRO ZACAPA", "SAN VICENTE CENTENARIO", "SANTA RITA", "TRINIDAD"],
    "valle": ["NACAOME", "ALIANZA", "AMAPALA", "ARAMECINA", "CARIDAD", "GOASCORÁN", "LANGUE", "SAN FRANCISCO DE CORAY", "SAN LORENZO"],
    "yoro": ["YORO", "ARENAL", "EL NEGRITO", "EL PROGRESO", "JOCON", "MORAZÁN", "OLANCHITO", "SANTA RITA", "SULACO", "VICTORIA", "YORITO"]
}

export const codigosIsoDepartamentosHonduras: any = {
    "atlántida": {
        iso: "AT",
    },
    "choluteca": {
        iso: "CH",
    },
    "colón": {
        iso: "CL",
    },
    "comayagua": {
        iso: "CM"
    },
    "copán": {
        iso: "CP"
    },
    "cortés": {
        iso: "CR"
    },
    "el paraíso": {
        iso: "EP"
    },
    "francisco morazán": {
        iso: "FM"
    },
    "gracias a dios": {
        iso: "GD"
    },
    "intibucá": {
        iso: "IN"
    },
    "islas de la bahía": {
        iso: "IB"
    },
    "la paz": {
        iso: "LP"
    },
    "lempira": {
        iso: "LE"
    },
    "ocotepeque": {
        iso: "OC"
    },
    "olancho": {
        iso: "OL"
    },
    "santa bárbara": {
        iso: "SB"
    },
    "valle": {
        iso: "VA"
    },
    "yoro" : {
        iso: 'YO'
    }
}


export const tiposcuenta: string[] = ["Monetaria", "Ahorro"];

export const listabancos: string[] = [
    'Banco Agromercantil',
    'Banco Industrial',
    'Banco de America Central',
    'Banco Promerica',
    'Banco Internacional',
    'Banco G&T Continental',
    'Banco de Desarrollo Rural',
    'Banco de los Trabajadores',
    'Vivibanco',
    'Banco Ficohsa Guatemala',
    'Banco Credicorp',
    'Banco de Antigua',
    'Banco de Guatemala',
    'Bantrab',
    'Citibank',
    'Credito hipotecario nacional',
    'Banco Azteca',
    'Banco Inmobiliario',
    'Banco Nexa',
];

export const listaBancosHonduras: string[] = [
    'Banco Atlantida',
    'Banco Central de Honduras',
    'Banco Continental',
    'Banco CityBank de Honduras',
    'Banco de los Trabajadores',
    'Banco Occidente',
    'Banco de Honduras',
    'Banco Hondureño del Cafe',
    'Banco del Pais',
    'Banco LAFISE',
    'Banco FICENSA',
    'BAC-Bamer de Honduras',
    'Banco Proamerica',
    'Banco FICOHSA',
    'Banco HSBC',
    'Banrural',
    'Banco BANADESA',
    'CEPROBAN',
]

export const documentosIndividual: { nombre: string, id: string }[] = [
    { nombre: 'Copia de patente de la empresa', id: 'patente_empresa' },
    { nombre: 'Copia de RTU ratificado', id: 'rtu' },
    { nombre: 'Copia de DPI del propietario', id: 'dpi' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const documentosPequenoContribuyente: { nombre: string, id: string }[] = [
    { nombre: 'Copia de RTU ratificado', id: 'rtu' },
    { nombre: 'Copia de DPI del propietario', id: 'dpi' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Factura', id: 'factura' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const documentosSociedadAnonima: { nombre: string, id: string }[] = [
    { nombre: 'Copia de patente de la empresa', id: 'patente_empresa' },
    { nombre: 'Copia de patente de la sociedad', id: 'patente_sociedad' },
    { nombre: 'Copia de RTU actualizado y ratificado', id: 'rtu' },
    { nombre: 'Copia de la escritura de constitución de la sociedad', id: 'escritura_constitucion_sociedad' },
    { nombre: 'Copia de DPI del representante legal', id: 'dpi' },
    { nombre: 'Copia de la representacion legal con su vigencia', id: 'representacion_legal' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const documentosServiciosProfesionales: { nombre: string, id: string }[] = [
    { nombre: 'Copia de carne de colegiado', id: 'carne_colegiado' },
    { nombre: 'Copia de de colegiado activo o último recibo de pago de colegiado', id: 'colegiado_activo' },
    { nombre: 'Copia de factura de servicios', id: 'factura_servicios' },
    { nombre: 'Copia de RTU ratificado', id: 'rtu' },
    { nombre: 'Copia de DPI del profesional', id: 'dpi' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const documentosFundacion: { nombre: string, id: string }[] = [
    { nombre: 'Copia de DPI del representante legal', id: 'dpi' },
    { nombre: 'Copia del nombramiento legal', id: 'nombramiento_legal' },
    { nombre: 'Copia de los estatutos de la asociacion', id: 'estatutos_asociacion' },
    { nombre: 'Copia del acuerdo gubernativo emitido por gobernación', id: 'acuerdo_gubernativo' },
    { nombre: 'Copia de RTU ratificado', id: 'rtu' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Copia de la resolución de la sat donde indica que están exentos de IVA', id: 'resolucion_sat' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const documentosInstitucionEducativa: { nombre: string, id: string }[] = [
    { nombre: 'Copia de DPI del representante legal', id: 'dpi' },
    { nombre: 'Copia del nombramiento legal', id: 'nombramiento_legal' },
    { nombre: 'Copia de los estatutos del colegio', id: 'estatutos_colegio' },
    { nombre: 'Copia de la resolución y permisos, brindados por mineduc', id: 'resolucion_mineduc' },
    { nombre: 'Copia del acuerdo gubernativo emitido por gobernación', id: 'acuerdo_gubernativo' },
    { nombre: 'Copia de RTU ratificado', id: 'rtu' },
    { nombre: 'Copia de recibo de luz, agua o telefono donde estara el negocio', id: 'recibo' },
    { nombre: 'Copia de un cheque anulado', id: 'cheque_anulado' },
    { nombre: 'Copia de la resolución de la sat donde indica que están exentos de IVA', id: 'resolucion_sat' },
    { nombre: 'Fotografiá de tu establecimiento', id: 'foto_establecimiento' },
];

export const tiposAfiliacion: { nombre: string, id: string, documentos: { nombre: string, id: string }[] }[] = [
    { nombre: 'Persona individual', id: 'administrador_persona_individual', documentos: documentosIndividual },
    { nombre: 'Pequeño contribuyente', id: 'administrador_pequeno_contribuyente', documentos: documentosPequenoContribuyente },
    { nombre: 'Sociedad Anónima', id: 'administrador_sociedad_anonima', documentos: documentosSociedadAnonima },
    { nombre: 'Servicios profesionales', id: 'administrador_servicios_profesionales', documentos: documentosServiciosProfesionales },
    { nombre: 'Asociación o Fundación', id: 'administrador_fundacion', documentos: documentosFundacion },
    { nombre: 'Colegio o institución educativa', id: 'administrador_institucion_educativa', documentos: documentosInstitucionEducativa }
]


export class roles {
    static instance = new roles()
    rolAdministradorPersonaIndividual: string = 'administrador_persona_individual'
    rolAdministradorPequenoContribuyente: string = 'administrador_pequeno_contribuyente'
    rolAdministradorSociedadAnonima: string = 'administrador_sociedad_anonima'
    rolAdministradorServiciosProfesionales: string = 'administrador_servicios_profesionales'
    rolAdministradorFundacion: string = 'administrador_fundacion'
    rolAdministradorInstitucionEducativa: string = 'administrador_institucion_educativa'
    rolAdministradorMaster: string = 'administrador_master'
    gerente: string = 'gerente'
    financiero: string = 'financiero'
    cajero: string = 'cajero'
    afiliador: string = 'afiliador'
}

export class estadosEnlace {
    static instance = new estadosEnlace()
    pagado: string = 'pagado'
    anulado: string = 'anulado'
    rechazado: string = 'rechazado'
    caducado: string = 'caducado'
    pendiente: string = 'pendiente'
    OK: string = 'OK'
    APPROVED: string = 'APPROVED'
    PENDING: string = 'PENDING'
    REJECTED: string = 'REJECTED'
    FAILED: string = 'FAILED'
    cancelado: string = 'cancelado'
}

export const getMensajeDeErrorVisa = (codigoDeErrorVisa: any): string => {
    if (codigoDeErrorVisa == '00') return 'Exitoso'
    else if (codigoDeErrorVisa == '01') return 'Refiérase al Emisor'
    else if (codigoDeErrorVisa == '02') return 'Refiérase al Emisor'
    else if (codigoDeErrorVisa == '05') return 'Transacción No Aceptada'
    else if (codigoDeErrorVisa == '12') return 'Transacción Inválida'
    else if (codigoDeErrorVisa == '13') return 'Monto Inválido'
    else if (codigoDeErrorVisa == '19') return 'Transacción no realizada, intente de nuevo'
    else if (codigoDeErrorVisa == '31') return 'Tarjeta no soportada por switch'
    else if (codigoDeErrorVisa == '35') return 'Transacción ya ha sido anulada'
    else if (codigoDeErrorVisa == '36') return 'Transacción a anular no existe'
    else if (codigoDeErrorVisa == '37') return 'Transacción de anulación reversada'
    else if (codigoDeErrorVisa == '38') return 'Transacción a anular con error'
    else if (codigoDeErrorVisa == '41') return 'Tarjeta extraviada'
    else if (codigoDeErrorVisa == '43') return 'Tarjeta robada'
    else if (codigoDeErrorVisa == '51') return 'No tiene fondos disponibles'
    else if (codigoDeErrorVisa == '57') return 'Transacción no permitida'
    else if (codigoDeErrorVisa == '58') return 'Transacción no permitida en la terminal'
    else if (codigoDeErrorVisa == '65') return 'Límite de actividad excedido'
    else if (codigoDeErrorVisa == '80') return 'Fecha de expiración inválida'
    else if (codigoDeErrorVisa == '89') return 'Terminal inválida'
    else if (codigoDeErrorVisa == '91') return 'Emisor no disponible'
    else if (codigoDeErrorVisa == '94') return 'Transacción duplicada'
    else if (codigoDeErrorVisa == '96') return 'Error del sistema, intente mas tarde'
    else if (codigoDeErrorVisa == '97') return 'Limite mensual excedido'
    else return 'Error desconocido'
}

export const getResponseCodeMessageEpayRetail = (errorCode?: string) => {
    switch (errorCode) {
        case '00':
        case '10':
            return 'APROBADO'
        case '01':
        case '02':
            return 'REFIERASE AL EMISOR'
        case '03':
            return 'PROVEEDOR DE SERVICIOS NO VALIDO'
        case '04':
            return 'RECOGER'
        case '05':
            return 'TRANSACCIÓN NO ACEPTADA'
        case '06':
            return 'ERROR GENERAL'
        case '07':
            return 'TARJETA RECOGIDA, CONDICION ESPECIAL'
        case '08':
            return 'HONOR CON IDENTIFICACION'
        case '09':
            return 'SOLICITUD EN CURSO'
        case '11':
            return 'APROBACION VIP'
        case '12':
            return 'TRANSACCION INVALIDA'
        case '13':
            return 'MONTO INVALIDO'
        case '14':
            return 'NUMERO DE CUENTA INVALIDO'
        case '15':
            return 'EMISOR NO DISPONIBLE'
        case '16':
            return 'FONDOS INSUFICIENTES'
        case '17':
            return 'CANCELACION DE CLIENTES'
        case '19':
            return 'TRANSACCION NO REALIZADA, INTENTE DE NUEVO'
        case '20':
            return 'RESPUESTA INVALIDA'
        case '21':
            return 'NINGUNA ACCION TOMADA'
        case '22':
            return 'SOSPECHA DE MAL FUNCIONAMIENTO'
        case '25':
            return 'FALTA NUMERO DE CUENTA'
        case '28':
            return 'EL ARCHIVO NO ESTA DISPONIBLE TEMPORALMENTE'
        case '31':
            return 'TARJETA NO SOPORTADA'
        case '41':
            return 'TARJETA EXTRAVIADA'
        case '43':
            return 'TARJETA ROBADA'
        case '51':
            return 'NO TIENE FONDOS'
        case '52':
            return 'NO TIENE CUENTA CORRIENTE'
        case '53':
            return 'NO TIENE CUENTA DE AHORROS'
        case '54':
            return 'TARJETA EXPIRADA'
        case '55':
            return 'PIN INCORRECTO'
        case '57':
            return 'TRANSACCION NO PERMITIDA AL TARJETAHABIENTE'
        case '58':
            return 'TRANSACCION NO PERMITIDA A LA TERMINAL'
        case '59':
            return 'SOSPECHA DE FRAUDE'
        case '61':
            return 'LIMITE DE MONTO EXCEDIDO'
        case '62':
            return 'TARJETA RESTRINGIDA'
        case '63':
            return 'VIOLACION DE SEGURIDAD'
        case '65':
            return 'LIMITE DE CANTIDAD EXCEDIDO'
        case '68':
            return 'RESPUESTA EXCEDIO TIEMPO DE ESPERA'
        case '75':
            return 'LIMITE DE ENTRADA PIN EXCEDIDO'
        case '76':
            return 'NO COINCIDE REFERENCIA'
        case '77':
            return 'DATOS INCONSISTENTES CON EL MENSAJE ORIGINAL'
        case '78':
            return 'BLOQUEADO, PRIMER USO'
        case '80':
            return 'TRANSACCION DE VISA, EMISOR NO DISPONIBLE'
        case '81':
            return 'ERROR CRIPTOGRAMA DE PIN'
        case '82':
            return 'RESULTADOS NEGATIVOS DE CAM, dCVV'
        case '83':
            return 'NO SE PUEDE VERIFICAR EL PIN'
        case '85':
            return 'NO HAY RAZON A RECHAZAR SOLICITUD DE VERIFICACION'
        case '89':
            return 'TERMINAL INVALIDA'
        case '91':
            return 'EMISOR NO DISPONIBLE'
        case '92':
            return 'NO SE PUEDE ENCONTRAR EL DESTINO DE ENRUTAMIENTO'
        case '93':
            return 'TRANSACCION NO PUEDE SER COMPLETADA'
        case '94':
            return 'TRANSACCION DUPLICADA'
        case '95':
            return 'ERROR DE RECONCILIACION'
        case '96':
            return 'NO SE PUDO PROCESAR LA INFORMACION'
        case '97':
            return 'LIMITE MENSUAL EXCEDIDO'
        default:
            return 'DESCONOCIDO';
    }
}

//Marketing
export const tiposNotificacion = [
    { id: "notificacion-push", nombre: "Notificación a moviles" },
    { id: "email", nombre: "Notificación a correo electrónico" },
];

export const plataformasNotificacion = [
    { id: "android", nombre: "Android" },
    { id: "ios", nombre: "iOS" },
    { id: "todos", nombre: "Todos las plataformas" },
];

export const modosNotificacion = [
    { id: "automatico", nombre: "Notificaciónes automaticas" },
    { id: "manual", nombre: "Notificaciónes manuales" },
];

export const estadosNotificacion = [
    { id: "inactivo", nombre: "Desactivada" },
    { id: "activo", nombre: "Activa" },
];

export const parametrosNotificacion = [
    { id: "transaccion", nombre: "Notificaciónes a usuario con x dias de su ultima transacción" },
    { id: "documentos-pendientes", nombre: "Notificaciónes a usuarios con x dias de tener documentos pendientes" },
    { id: "proceso-registro", nombre: "Notificaciónes a usuarios con x dias de no tener su registro completado" },
    { id: "todos", nombre: "Todos los usuarios" },
];

export const contactStatus: string[] = ['Nuevo prospecto', 'Rellamada', 'Primer contacto', 'Comercio interesado pendiente de confirmacion', 'Ingreso a proceso', 'Falta de actualizacion de RTU'];
export const contactOrigin: string[] = [
    'Facebook',
    'Messenger',
    'Instagram',
    'Whatsapp',
    'Landing',
    'PBX',
    'Descarga',
    'Dealers',
    'Referido de Paggo Konta',
    'Referido de comercio afiliado',
    'Referido de Paggo',
    'Toque en frio',
    'Referido eventos',
];
export enum CONTACTMPOS {
    VIRGEN = 'VIRGEN',
    MEDIOVIRGEN = 'MEDIO_VIRGEN',
    NOVIRGEN = 'NO_VIRGEN'
}

export const settlementStatus: { value: string; color: string }[] = [
    { value: 'Exportada', color: '#5166AF' },
    { value: 'Subido', color: '#FE8300' },
    { value: 'Exitoso', color: '#66bb6a' },
    { value: 'Pendiente', color: '#f44336' },
    { value: 'Terminado', color: '#29b6f6' },
];

export const settlementStatusBusiness: { value: string; color: string }[] = [
    { value: 'Exportada', color: '#5166AF' },
    { value: 'Reprocesar', color: '#FE8300' },
    { value: 'Por pagar', color: '#f44336' },
    { value: 'Pagado', color: '#66bb6a' },
    { value: 'Cheque emitido', color: '#29b6f6' },
    { value: 'Liquida Visa', color: '#b0ae21' },
];

export enum categoriasVisaTipoNegocio {
    agregador = 'agregador',
    payfac = 'payfac',
}

export const toLiquidate: { value: string; color: string, label: string }[] = [
    { value: 'Si', color: '#66bb6a', label: 'Si' },
    { value: 'No', color: '#f44336', label: 'Retener' },
    { value: 'Contracargo', color: '#F68C00', label: 'Contracargo' },
];

export const transactionSettlementState: { value: string; color: string, disabled: boolean }[] = [
    { value: 'Pagado', color: '#66bb6a', disabled: false },
    { value: 'Pendiente', color: '#f44336', disabled: false },
    { value: 'Retenida', color: '#f44336', disabled: true },
];

export const mposInventoryStatus: { value: string; color: string }[] = [
    { value: 'Entregado', color: '#66bb6a' },
    { value: 'Inventario', color: '#5166AF' },
    { value: 'Activado', color: '#FE8300' },
    { value: 'Reventa', color: '#556B4C' },
    { value: 'Traslado', color: '#3A98CB' },
    { value: 'Dañado', color: '#E73719' },
    { value: 'Enviado', color: '#53055f' },
    { value: 'Tránsito', color: '#999933' },
    { value: 'Asignado', color: '#FFA500' },
    { value: 'Desvinculado', color: '#CFC9C8' },
];

export const paggoAfiliations = (DEV || TEST) ? '31032022' : '135086000';

// LIMITES TEMPORALES PARA NUEVOS NEGOCIOS
export const temporalNegocioLimites: Record<string, { pos: number; link: number }> = {
  no_formalizado: {
    pos: 0,
    link: 0.0001,
  },
  no_formalizado_plus: {
    pos: 0,
    link: 5_000,
  },
  emprendedor: {
    pos: 0,
    link: 0.0001,
  },
  emprendedor_plus: {
    pos: 0,
    link: 5_000,
  },
  persona_individual_payfac: {
    pos: 0,
    link: 0.0001,
  },
  persona_individual_plus: {
    pos: 0,
    link: 20_000,
  },
  sociedad_anonima_payfac: {
    pos: 0,
    link: 0.0001,
  },
  sociedad_anonima_plus: {
    pos: 0,
    link: 20_000,
  },
  pequeno_contribuyente_payfac: {
    pos: 0,
    link: 0.0001,
  },
  pequeno_contribuyente_plus: {
    pos: 0,
    link: 5_000,
  },
};

export const categoriasContracargos: { value: string; label: string }[] = [
    { value: '10.3 Fraude de tarjeta presente', label: '10.3 Fraude de tarjeta presente' },
    { value: '10.4 Fraude de tarjeta ausente', label: '10.4 Fraude de tarjeta ausente' },
    { value: '11.2 Autorización denegada', label: '11.2 Autorización denegada' },
    { value: '11.3 Sin autorización', label: '11.3 Sin autorización' },
    { value: '12.1 Presentación tardía', label: '12.1 Presentación tardía' },
    { value: '12.5 Monto Incorrecto', label: '12.5 Monto Incorrecto' },
    { value: '12.6 Transaccion duplicada', label: '12.6 Transaccion duplicada' },
    { value: '12.7 Datos inválidos', label: '12.7 Datos inválidos' },
    { value: '13.1 Servicios no prestados', label: '13.1 Servicios no prestados' },
    { value: '13.3 Servicios o Mercadería distinta a lo descrito o defectuosa', label: '13.3 Servicios o Mercadería distinta a lo descrito o defectuosa' },
    { value: '13.6 Credito no procesado', label: '13.6 Credito no procesado' },
    { value: '13.7 Mercancía o servicios cancelados', label: '13.7 Mercancía o servicios cancelados' },
    { value: '4808 Transacción no autorizada', label: '4808 Transacción no autorizada' },
    { value: '4837 Sin autorizacion del TH', label: '4837 Sin autorizacion del TH' },
    { value: '4849 Actividad sospechosa del comercio', label: '4849 Actividad sospechosa del comercio' },
    { value: '4853 Mercancia defectuosa', label: '4853 Mercancia defectuosa' },
    { value: '4855 Mercancia o Servicios Cancelados', label: '4855 Mercancia o Servicios Cancelados' },
    { value: 'Fraude amigo', label: 'Fraude amigo' },
    { value: 'Fraude', label: 'Fraude' },
    { value: 'Falso', label: 'Falso' },
];

export const documentosRazonesEstados: { value: string; color: string }[] = [
    { value: 'Subido', color: '#66bb6a' },
    { value: 'Aceptado', color: '#FE8300' },
    { value: 'Rechazado', color: '#E73719' },
];
        
export const estadosContracargos: { value: string; label: string }[] = [
    { value: 'Pendiente', label: 'Pendiente' },
    { value: 'Perdido', label: 'Perdido' },
    { value: 'Ganado', label: 'Ganado' }
]

export const afiliacionApayRetailGuatemala = process.env.REACT_APP_AFILIACION_EPAYRETAIL_GUATEMALA || '31032022';
export const afiliacionApayRetailHonduras = process.env.REACT_APP_AFILIACION_EPAYRETAIL_HONDURAS || '0298201599';

export const guatemalaBussinesTypes: Record<
    string,
    string
  > = {
    persona_individual_payfac: 'persona_individual_payfac',
    pequeno_contribuyente_payfac: 'pequeno_contribuyente_payfac',
    sociedad_anonima_payfac: 'sociedad_anonima_payfac',
    persona_individual: 'persona_individual',
    pequeno_contribuyente: 'pequeno_contribuyente',
    sociedad_anonima: 'sociedad_anonima',
    servicios_profesionales: 'servicios_profesionales',
    fundacion: 'fundacion',
    institucion_educativa: 'institucion_educativa',
    no_formalizado: 'no_formalizado',
    emprendedor: 'emprendedor',
    profesional: 'profesional',
    empresa: 'empresa',
    no_formalizado_plus: 'no_formalizado_plus',
    emprendedor_plus: 'emprendedor_plus',
    persona_individual_plus: 'persona_individual_plus',
    sociedad_anonima_plus: 'sociedad_anonima_plus',
    pequeno_contribuyente_plus: 'pequeno_contribuyente_plus',
  };

  export const paises = {
    guatemala: 'Guatemala',
    honduras: 'Honduras',
  };

  export const currencyConfig: {
        [key: string]: {
        locale: string;
        currencyCode: string;
        currencyDisplay: string;
        }
    } = {
    Guatemala: {
        locale: "es-GT",
        currencyCode: "GTQ",
        currencyDisplay: "symbol"
    },
    Honduras: {
        locale: "es-HN",
        currencyCode: "HNL",
        currencyDisplay: "symbol"
    },
  };

export const estadosColorEnlace: {[key: string]: string} = {
    "rechazado": "#f44336",
    "aprobado": "#00bcd4",
    "pagado": "#00bcd4",
    "anulado": "#f44336",
    "reversada": "#f44336",
    "eliminado": "#f44336",
    "cancelado": "#f44336",
    "no procesado": "#f44336",
    "pendiente": "#ffc107",
    "ok": "#00bcd4",
    "caducado": "#f44336",
    "failed": "#f44336",
    "approved": "#00bcd4",
    "approved_partial": "#ffc107",
    "partial_expired": "#f44336",
    "rejected": "#f44336",
    "pending_validation": "#f44336",
    "pending_process": "#ffc107",
    "refunded": "#f44336",
    "error": "#f44336",
    "unknown": "#f44336",
    "manual": "#f44336",
    "dispute": "#f44336",
}

export const estadosSolicitudes: { [key: string]: {[key: string]: string} } = {
    A: { value: 'Aprobado', background: '#66bb6a', color: '#fff' },
    P: { value: 'Pendiente', background: 'rgb(232, 244, 253)', color: '#000' },
    X: { value: 'Rechazado', background: '#E73719', color: '#fff' },
};
