import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import LoginService from '../services/LoginService'
import { LoginInterface } from '../interfaces/Interfaces'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Images } from '../styles/images'
import { GeneralContext } from '../context/GeneralContext'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import FooterLogin from './FooterLogin';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    buttonIniciar: {
        marginRight: theme.spacing(1),
        paddingTop: '4%',
        paddingBottom: '4%',
        display: 'block'
    },
    container: {
        padding: '5%',
        paddingTop: '1%'
    },
    inputPadding: {
        paddingTop: '1%'
    },
}));


export default function Login() {
    const classes = useStyles();
    const [values, setValues] = useState<LoginInterface>({
        email: '',
        password: ''
    });

    const [openAlert, setOpenAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [tokenCaptcha, setTokenCaptcha] = useState('');
    const captchaRef = useRef<HCaptcha | null>(null);
    const { loading, handleLoading, handleErrorMessage, handleActiveError, handleRolUsuario, handleEsCliente } = useContext(GeneralContext)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })

    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const correoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Ingresa tu Correo</InputLabel>
                <OutlinedInput
                    type='email'
                    value={values.email}
                    onChange={handleChange('email')}
                    className={classes.inputPadding}
                    onKeyDown={handleLoginEnter}
                />
            </FormControl>
        )
    }

    const passwordInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Ingresa tu Contraseña</InputLabel>
                <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    className={classes.inputPadding}
                    onKeyDown={handleLoginEnter}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    const handleLogin = async () => {
        if (!tokenCaptcha) {
            handleErrorMessage('Por favor verifica que eres un humano')
            handleActiveError(true)
            return;
        }
        handleLoading(true)
        let req = await LoginService.instance.login(values.email, values.password, tokenCaptcha)
        if (!req.error) {
            let esCliente = false
            if (
                req
                && req.result
                && req.result.user
                && req.result.user.rolUsuario
                && req.result.user.rolUsuario[0].rol
                && req.result.user.rolUsuario[0].rol.tipo
                &&
                (
                    req.result.user.rolUsuario[0].rol.tipo == 'administradorPayFac'
                    || req.result.user.rolUsuario[0].rol.tipo == 'cajeroPayFac'
                    || req.result.user.rolUsuario[0].rol.tipo == 'financieroPayFac'
                    || req.result.user.rolUsuario[0].rol.tipo == 'gerentePayFac'
                    || req.result.user.rolUsuario[0].rol.tipo == 'administrador'
                    || req.result.user.rolUsuario[0].rol.tipo == 'cajero'
                    || req.result.user.rolUsuario[0].rol.tipo == 'financiero'
                    || req.result.user.rolUsuario[0].rol.tipo == 'gerente'
                )
            ) {
                esCliente = true
                handleEsCliente(true)
                handleRolUsuario(req.result.user.rolUsuario[0].rol.tipo)
            }

            if (req.result.panel && esCliente) {
                window.location.href = '/home'
            } else {
                window.location.href = '/admin-home'
            }
        } else {
            handleErrorMessage(req.message)
            handleActiveError(true)
        }
        setTokenCaptcha('')
        handleLoading(false)
        await captchaRef?.current?.resetCaptcha();
    }

    const handleLoginEnter = async (e: any) => {
        if (e.key != 'Enter') return
        if (!tokenCaptcha) {
            handleErrorMessage('Por favor verifica que no eres un robot')
            handleActiveError(true)
            return;
        }
        handleLoading(true)
        let req = await LoginService.instance.login(values.email, values.password, tokenCaptcha)
        if (!req.error) {
            //if (req.result.rol === 'administrador_master') {
            if (req.result.panel) {
                window.location.href = '/admin-home'
            } else {
                window.location.href = '/home'
            }
        } else {
            handleErrorMessage(req.message)
            handleActiveError(true)
        }

        handleLoading(false)
    }

    return (
        <>
            {width > 800 ?
                <div className={classes.root} >
                    <div className={classes.root}>
                        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error">
                                {window.localStorage.getItem('erroractual') || 'Algo mal paso en tu proceso de logueo'}
                            </Alert>
                        </Snackbar>
                    </div>

                    <div className={classes.container}>

                        <Grid container spacing={3} style={{ marginTop: '2%' }}>

                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <img width={(width * 0.30)} src={Images.instance.logo} alt="" />
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: '1%' }}>
                                {correoInput()}
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: '1%' }}>
                                {passwordInput()}
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4} style={{ textAlign: 'center', marginTop: '1%' }}>
                                <HCaptcha
                                    ref={captchaRef}
                                    size='normal'
                                    sitekey={process.env.REACT_APP_SITE_KEY_HCAPTCHA || '0325b5ea-a0ff-49fb-ba84-e2ac11732523'}
                                    onVerify={(token, ekey) => setTokenCaptcha(token)}
                                    onExpire={() => setTokenCaptcha('')}
                                />
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ paddingTop: '3%' }}>
                                    <Button onClick={handleLogin} disabled={loading} fullWidth={true} color='primary' variant="contained" className={classes.buttonIniciar} >Iniciar</Button>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                {/* <Typography style={{ color: 'gray' }}>
                                    {'¿No tienes una cuenta? '}
                                    <Link style={{ color: '#1565D8' }} onClick={() => {
                                        window.localStorage.clear()
                                    }}
                                        href="/register"
                                    >
                                        {' Registrate'}
                                    </Link>
                                </Typography> */}
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                {/* <Typography variant="body2">
                                    <Link style={{ color: 'gray' }} href="/forgotpassword" >
                                        ¿Olvidaste tu constraseña?
                                    </Link>
                                </Typography> */}
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>

                        </Grid>
                        <FooterLogin width={width} />
                    </div>


                </div >

                :


                <div className={classes.root}>

                    <div className={classes.root}>
                        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error">
                                {window.localStorage.getItem('erroractual') || 'Algo mal paso en tu proceso de logueo'}
                            </Alert>
                        </Snackbar>
                    </div>

                    <div className={classes.container}>


                        <Grid container spacing={3}>

                            <Grid item xs={12}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <img width={(width * 0.40)} src={Images.instance.logo} alt=""
                                />
                            </Grid>

                            <Grid item xs={12}
                            >
                                <div style={{ paddingTop: '5%' }}>
                                    {correoInput()}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ paddingTop: '2%' }}>
                                    {passwordInput()}
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <div style={{ paddingTop: '2%' }}></div>
                                <HCaptcha
                                    ref={captchaRef}
                                    size='normal'
                                    sitekey={process.env.REACT_APP_SITE_KEY_HCAPTCHA || '0325b5ea-a0ff-49fb-ba84-e2ac11732523'}
                                    onVerify={(token, ekey) => setTokenCaptcha(token)}
                                    onExpire={() => setTokenCaptcha('')}
                                />
                            </Grid>


                            <Grid item xs={4}>
                            </Grid>

                            <Grid item xs={4}>
                                <div style={{ paddingTop: '8%' }}>
                                    <Button onClick={handleLogin} disabled={loading} fullWidth={true} color='primary' variant="contained" className={classes.buttonIniciar} >Iniciar</Button>
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                            </Grid>



                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={8}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                {/* <Typography style={{ color: 'gray' }}>
                                    {'¿No tienes una cuenta? '}
                                    <Link style={{ color: '#1565D8' }} onClick={() => {
                                        window.localStorage.clear()
                                    }}
                                        href="/register"
                                    >
                                        {' Registrate'}
                                    </Link>
                                </Typography> */}
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>



                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={8}
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                {/* <Typography variant="body2">
                                    <Link style={{ color: 'gray' }} href="/forgotpassword" >
                                        ¿Olvidaste tu constraseña?
                                    </Link>
                                </Typography> */}
                            </Grid>


                        </Grid>
                        <FooterLogin width={width} />

                    </div>

                </div >

            }

        </>

    );
}