import './App.css';
import { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core'
import RegisterPage from './pages/Register'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import PayWithLink from './pages/payWithLink'
import PayWithLinkV2 from './pages/payWithLinkV2'
import PayWithLinkV2Paso4 from './pages/payWithLinkV2Paso4'
import PayWithLinkV2Paso5 from './pages/payWithLinkV2Paso5'
import PayWithLinkPayfac from './pages/payWithLinkPayfac'
import PayWithLinkPayfacHn from './pages/payWithLinkPayfacHn'
import PayWithLinkHn from './pages/payWithLinkHn';
import Invitacion from './pages/Invitacion';
import Home from './pages/Home';
import AdminHome from './pages/AdminHome';
import { GeneralContext } from './context/GeneralContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LoginService from './services/LoginService'
import localStorage from './libs/localStorage'
import VoucherLink from './pages/VoucherLink/VoucherLink';
import VoucherLinkHn from './pages/VoucherLink/VoucherLinkHn';
import RegistroPorDealers from './pages/RegistroPorDealers/RegistroPorDealers';

export const defColors = ['#4259A9', '#4CDADD', '042'];
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '100px',
        fontSize: '1rem',
        padding: '5px 15px',
      },
      outlined: {
        borderColor: 'rgba(0,0,0,.05)',
        color: '#828282',
        fontSize: '1rem',
        padding: '10px 15px',
        transition: 'color 0.1s ease-in-out',

        '& > .MuiButton-startIcon': {
          transition: 'color 0.1s ease-in-out',
        },

        '&:hover': {
          borderColor: `${defColors[0]}`,
          backgroundColor: '#FBFBFB',
          color: `${defColors[0]}`,

          '& > .MuiButton-startIcon': {
            color: `${defColors[0]}`,
          },
        },
      },
    },
    MuiPaper: {
      elevation0: {
        borderRadius: '20px',
        boxShadow: 'none',
      },
      elevation1: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation2: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation3: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation4: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation5: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation6: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation7: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation8: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation9: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation10: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation11: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation12: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation13: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation14: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation15: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation16: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation17: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation18: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation19: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation20: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation21: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation22: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation23: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
      elevation24: {
        borderRadius: '20px',
        boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
      },
    },
    MuiListItem: {
      root: {
        borderRadius: '12px',
      },
    },

    MuiListItemText: {
      primary: {
        fontWeight: 600,
      },
    },
    MuiDrawer: {
      docked: {},
      paperAnchorDockedLeft: {
        backgroundColor: 'transparent',
        borderRight: 'none',
      },
      paper: {
        paddingLeft: 20,
        borderRadius: 0,
        border: 'none',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#292865',
      },
    },
    MuiInputBase: {
      input: {
        color: '#292865',
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#292865',
        borderRadius: '12px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        zIndex: 10,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '0.8rem !important',
      },
    }
  },
  palette: {
    secondary: {
      main: `${defColors[1]}`,
      light: `${defColors[1]}`,
      dark: `${defColors[3] ?? '#547BAE'}`,
    },
    primary: {
      main: `${defColors[0]}`,
      light: `${defColors[0]}`,
      dark: `${defColors[0] ?? '#547BAE'}`,
    },
  },
});

function App() {
  const {
    loading,
    activeError,
    handleDismissError,
    errorMessage,
    activeSuccess,
    handleDismissSuccess,
    successMessage,
  } = useContext(GeneralContext);

  useEffect(() => {
    async function refreshToken() {
      let pathTemp: string = window.location.pathname;
      let pathArray: string[] = pathTemp.split('/');

      if (
        pathArray[1] === 'PayWithLink' ||
        pathArray[1] === 'PayWithLinkV2' ||
        pathArray[1] === 'PayWithLinkV2Paso4' ||
        pathArray[1] === 'PayWithLinkV2Paso5' ||
        pathArray[1] === 'PayWithLinkHn' ||
        pathArray[1] === 'register' ||
        pathArray[1] === 'forgotpassword' ||
        pathArray[1] === 'invitacion' ||
        pathArray[1] === 'registerByDealer' ||
        pathArray[1] === 'PayWithLinkPayfac' ||
        pathArray[1] === 'PayWithLinkPayfacHn'
      )
        return;

      let token: any = window.localStorage.getItem('token');
      let res = await LoginService.instance.refreshLogin(token);
      let user = await localStorage.instance.getUserParseado();


      let rolusuario: string = ''
      let rolPanel: boolean = false
      let esCliente: boolean = false

      //if (user && user.rolUsuario && user.rolUsuario[0].rol) rolusuario = user.rolUsuario[0].rol.tipo.toString()
      if (user && user.rolUsuario && user.rolUsuario[0].rol)
        rolPanel = user.rolUsuario[0].rol.panel



      if (
        user
        && user.rolUsuario
        && user.rolUsuario[0].rol
        && user.rolUsuario[0].rol.tipo
        &&
        (
          user.rolUsuario[0].rol.tipo == 'administradorPayFac'
          || user.rolUsuario[0].rol.tipo == 'cajeroPayFac'
          || user.rolUsuario[0].rol.tipo == 'financieroPayFac'
          || user.rolUsuario[0].rol.tipo == 'gerentePayFac'
          || user.rolUsuario[0].rol.tipo == 'administrador'
          || user.rolUsuario[0].rol.tipo == 'cajero'
          || user.rolUsuario[0].rol.tipo == 'financiero'
          || user.rolUsuario[0].rol.tipo == 'gerente'
        )
      )
        esCliente = true

      if (res.error && window.location.pathname != '/' && !(pathArray[1] == 'PayWithLink') && !(pathArray[1] == 'voucherLink') && !(pathArray[1] == 'voucherLinkHn') && !(pathArray[1] == 'PayWithLinkHn') && !(pathArray[1] == 'PayWithLinkV2Paso5') && !(pathArray[1] == 'register') && !(pathArray[1] == 'forgotpassword')) {
        window.location.href = '/'
        //} else if (!res.error && user && user.rolUsuario && (rolusuario == roles.instance.afiliador || rolusuario == roles.instance.rolAdministradorMaster) && window.location.pathname == '/') {
      } else if (!res.error && user && user.rolUsuario && rolPanel && window.location.pathname == '/' && esCliente) {
        window.location.href = '/home'
      } else if (!res.error && user && user.rolUsuario && rolPanel && window.location.pathname == '/') {
        window.location.href = '/admin-home'
      }
    }
    refreshToken()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <>{loading ? <LinearProgress /> : null}</>


      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={activeSuccess}
        autoHideDuration={3000}
        onClose={handleDismissSuccess}
      >
        <Alert
          onClose={handleDismissSuccess}
          severity='success'
        >
          {successMessage}
        </Alert>
      </Snackbar>



      <Snackbar
        open={activeError}
        autoHideDuration={3000}
        onClose={handleDismissError}
      >
        <Alert
          onClose={handleDismissError}
          severity='error'
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/register">
            <RegisterPage />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/PayWithLinkV2Paso5/:payCode">
            <PayWithLinkV2Paso5 />
          </Route>
          <Route exact path="/payWithLink/:payCode">
            <PayWithLink />
          </Route>
          <Route exact path="/payWithLinkV2/:payCode">
            <PayWithLinkV2 />
          </Route>
          <Route exact path="/payWithLinkV2Paso4/:jwtValue">
            <PayWithLinkV2Paso4 />
          </Route>
          <Route exact path="/PayWithLinkPayfac/:payCode">
            <PayWithLinkPayfac />
          </Route>
          <Route exact path="/PayWithLinkPayfacHn/:payCode">
            <PayWithLinkPayfacHn/>
          </Route>
          <Route exact path="/payWithLinkHn/:payCode">
            <PayWithLinkHn />
          </Route>
          <Route exact path="/voucherLink/:code">
            <VoucherLink />
          </Route>
          <Route exact path="/voucherLinkHn/:code">
            <VoucherLinkHn />
          </Route>
          <Route exact path="/voucherLinkHn/:code/:simple">
            <VoucherLinkHn />
          </Route>
          <Route exact path="/invitacion/:codigoInvitacion">
            <Invitacion />
          </Route>
          <Route path="/home">
            <AdminHome />
          </Route>
          <Route path="/admin-home">
            <AdminHome />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );

}

export default App;
