import { useContext, useEffect, useState } from 'react'
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { Rutas } from '../../../resources/Statics'
import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../../context/GeneralContext';
import CrudService from '../../../services/CrudService';
import { FormField } from '../../../interfaces/Interfaces';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const panels = [
  {value: 'GET', label: 'Lectura'}, 
  {value: 'POST', label: 'Creación'}, 
  {value: 'PUT', label: 'Actualización'}, 
  {value: 'DELETE', label: 'Eliminación'}
]
const AccesosAvanzados = () => {

  const [permisos, setPermisos] = useState<{id: number, descripcion: string}[]>([]);
  const [refresh, setRefresh] = useState(false);
  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);

  type Row = {
    id: number;
    descripcion: string;
    method: string;
    modulo: string;
    path: string;
    permisos: string;
  };

  useEffect(() => {
    getPermisos();
  }, []);

  const handleChange = async (event: any, row: any, field: string = 'permisos') => {
    const valor = event.target.value;
    let registro = {[field]: valor}
    try {
        if (registro) {
          const crudService = CrudService.instance(Rutas.instance.urlEndPoint);
          const response = await crudService.updateOne(row.id, registro);
      
          const { error, result } = response;
          if (!error) {
            setRefresh(true);
          } else {
              handleErrorMessage('message');
              handleActiveError(true);
          }
          setRefresh(false);
        }
      } catch (e) {
        console.error('Error al actualizar la base de datos:', e);
      }
  };

  const getPermisos = () => {
    const crudService = CrudService.instance(Rutas.instance.urlPermisos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPermisos(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const columns = [
    
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 70,
        disableClickEventBubbling: true,
    },
    {
        field: 'modulo',
        headerName: 'Modulo',
        sortable: false,
        width: 170,
        disableClickEventBubbling: true,
        editable: true
    },
    {
        field: 'descripcion',
        headerName: 'Descripcion',
        sortable: false,
        width: 370,
        disableClickEventBubbling: true,
        editable: true
    },
    {
      field: 'path',
      headerName: 'Path',
      sortable: false,
      width: 370,
      disableClickEventBubbling: true,
      editable: true
    },
    {
        field: 'method',
        headerName: 'Accion',
        sortable: false,
        width: 170,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          const valor = params.row?.method;
          return (
            <div className='d-flex justify-content-between align-items-center'
              style={{ cursor: 'pointer' }}>
              <FormControl>
                <Select value={valor} onChange={(event) => handleChange(event, params.row, 'method')}>
                  {panels.map((opcion,index) => (
                    <MenuItem key={index} value={opcion.value}>{opcion.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          );
        },
    },
    {
      field: 'permisos',
      headerName: 'Permisos',
      sortable: false,
      width: 470,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const permisosSelect = JSON.parse(params.row.permisos)
        return (
            <FormControl>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={permisosSelect}
              onChange={(event) => handleChange(event, params.row)}
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(', ')}
              MenuProps={MenuProps}
            >
              {permisos.map((permiso) => (
                <MenuItem key={permiso.descripcion} value={permiso.descripcion}>
                  <Checkbox checked={permisosSelect.indexOf(permiso.descripcion) > -1} />
                  <ListItemText primary={permiso.descripcion} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
        );
      },
    },
    
    
    
  ];

  const formConfig: FormField[] = [
    {
      type: 'text',
      label: 'Modulo',
      name: 'modulo',
      required: true,
      error: 'Debe ingresar un nombre del modulo',
      value: '',
    },
    {
      type: 'text',
      label: 'Descripcion',
      name: 'descripcion',
      required: true,
      error: 'Debe ingresar una descripcion del modulo',
      value: '',
    },
    {
      type: 'text',
      label: 'Path',
      name: 'path',
      required: true,
      error: 'Debe ingresar un path del modulo',
      value: '',
    },
    {
      type: 'select',
      label: 'Accion',
      name: 'method',
      options: panels,
      required: true,
      error: 'Debe seleccionar una accion del endpoint',
      value: 0,
    },
    {
      type: 'select-multiple',
      label: 'Permisos',
      name: 'permisos',
      required: true,
      error: 'Debe seleccionar permisos',
      options: permisos.map((permiso) => ({value: permiso.descripcion, label: permiso.descripcion})),
      value: [],
    },
  ];
  

  return (
    <CrudDataGrid delete={true} formConfig={formConfig} refresh={refresh} apiEndpoint={Rutas.instance.urlEndPoint} columns={columns} placeholder='descripcion' />
  )
}

export default AccesosAvanzados