import { UsuarioInterface } from '../interfaces/Interfaces';
import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class LinkService {
    static instance = new LinkService();

    getLinkRapido = async (UsuarioId: number ,NegocioId: number) => {
        try {
            let data = {
                nombre: "",
                status: "activo",
                monto: 0,
                longitud: '0',
                latitud: '0',
                link: "paggo.com",
                versionApp: '',
                nombreCliente: "",
                concepto: 'LinkRapido',
                email: '',
                nit: 'CF',
                ipCreador: '',
                rapido: true,

                UsuarioId: UsuarioId,
                NegocioId: NegocioId,
            };

            let url = Rutas.instance.urlBase + Rutas.instance.urlPagoEnlace;
            const httpInstance = new http();

            let req = await httpInstance.post(url, data, true);
            if (!req || req.error) throw new Error(req.message);

            return { error: false, mensaje: '', result: req.result }
        } catch (error: any) {
            console.log('Error al registrar enlace: ', error)
            return { error: true, mensaje: error.message }
        }
    }

}