import React, { useState, useEffect, useContext, BaseSyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageLoader from '../../components/PageLoader';
import { useHistory, useParams } from 'react-router-dom';
import DealersService from '../../services/DealersService';
import { DataGrid } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DealersInterface, tipoDealerInterface } from '../../interfaces/Interfaces';
import { read, utils } from 'xlsx';
import { GeneralContext } from '../../context/GeneralContext';
import { Rutas } from '../../resources/Statics';
import { IconButton, Fade } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import Modal from '@material-ui/core/Modal';
import * as momentZone from 'moment-timezone';
import ModalConfirm from '../../components/ModalConfirm';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    width: '90%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '50vw',
  },
}));

export default function Dealers() {
  const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess } =
    useContext(GeneralContext);
  const history = useHistory();
  const classes = useStyles();
  const params = useParams<{ code: string }>();
  const [loading, setLoading] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [dealerDelete, setDealerDelete] = React.useState<DealersInterface>({});
  const [data, setData] = useState<any>([]);
  const [dataReferidosList, setDataReferidosList] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [tipoDealer, setTipoDealer] = useState(null);
  const [tiposDealer, setTiposDealer] = useState<Array<tipoDealerInterface>>([]);
  const [openReferidosModal, setOpenReferidosModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const handleCloseModal = () => {
    setOpenReferidosModal(false);
  };

  const openReferenciasModal = (id: string | number) => {
    setOpenReferidosModal(true);
    getDataReferidosList(id);
  };

  const getData = async () => {
    await getDataTipos();
    await getDealersList();
  };

  const columnsReferidos = [
    { field: 'id', headerName: 'id', flex: 1, minWidth: 100 },
    {
      field: 'registro',
      headerName: 'registro',
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => {
        return <span>{params.row.registro ? 'Registrado' : 'No Registrado'}</span>;
      },
    },
    {
      field: 'registroCompleto',
      headerName: 'registro completo',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params.row.registro ? 'Registro completo' : 'Registro incompleto'}</span>;
      },
    },
    { field: 'createdAt', headerName: 'Fecha de registro', flex: 1, minWidth: 150 },
    {
      field: 'usuario',
      headerName: 'Usuario email',
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.email}</span>;
      },
    },
  ];
  const columns = [
    { field: 'id', headerName: 'id', flex: 1, minWidth: 100 },
    { field: 'codigo', headerName: 'Codigo', flex: 1, minWidth: 250 },
    { field: 'nombre', headerName: 'Nombre', flex: 1, minWidth: 150 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
    { field: 'dpi', headerName: 'DPI', flex: 1, minWidth: 120 },
    { field: 'ubicacion', headerName: 'Ubicación', flex: 1, minWidth: 120 },
    {
      field: 'meta',
      headerName: 'Meta Mensual',
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.cantidadReferidos}/{params.row.meta ? params.row.meta : 0}
          </span>
        );
      },
    },
    {
      field: 'link',
      headerName: 'link',
      flex: 1,
      minWidth: 230,
      renderCell: (params: any) => {
        return (
          <span>
            <a
              target='_blank'
              href={`${Rutas.instance.urlBaseHost}/registerByDealer/${params.row.tipoDealer.nombre}/${params.row.codigo}`}
            >{`${Rutas.instance.urlBaseHost}/registerByDealer/${params.row.tipoDealer.nombre}/${params.row.codigo}`}</a>
          </span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'fecha Creacion',
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => {
        const fecha = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fecha}</span>;
      },
    },
    {
      field: 'tipoDealer',
      headerName: 'Tipo dealer',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return <span>{params.row.tipoDealer.nombre}</span>;
      },
    },
    {
      field: 'opciones',
      headerName: 'Opciones',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => editRecord(params.row)}
            >
              <Edit />
            </IconButton>
            <IconButton
              size='small'
              onClick={() => handleOpdenModalDelete(params.row)}
            >
              <Delete />
            </IconButton>
            <Button
              size='small'
              color='primary'
              onClick={() => openReferenciasModal(params.row.id)}
            >
              Ver referencias
            </Button>
          </div>
        );
      },
    },
  ];

  const editRecord = (params: any) => {
    history.push(`/admin-home/dealers/edit/${params.id}`);
  };
  const getDealersList = async () => {
    try {
      const res = await DealersService.instance.getDealersList();
      console.log('dealers list', res);
      setData(res.result);
    } catch (err) {}
  };

  const handleOpdenModalDelete = async (params:any) => {
    setDealerDelete(params);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setOpenDeleteModal(false)
      setLoading(true);
      try {
        const res = await DealersService.instance.deleteDealer(dealerDelete.id || 0);
        handleSuccessMessage("Dealer eliminado correctamente");
        handleActiveSuccess(true);
        await getData();
      } catch (err) {
        console.log('err',err)
      };
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleCloseDelete = async () => {
    setOpenDeleteModal(false);
  };

  const getDataTipos = async () => {
    try {
      const res = await DealersService.instance.getDealersTipos();
      console.log('dealers tipos list', res);
      setTiposDealer(res.result);
    } catch (err) {}
  };

  const getDataReferidosList = async (id: string | number) => {
    try {
      const res = await DealersService.instance.getDealersReferidosList(id);
      console.log('dealers tipos list', res);
      setDataReferidosList(res.result);
    } catch (err) {}
  };

  const handleChangeTipoDealer = (event: any) => {
    console.log('value change', event.target.value);
  };

  const cargarExcel = async (event: any) => {
    const file = event.target.files![0];
    if (file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    } else {
      handleErrorMessage('Archivo no valido');
      handleActiveError(true);
      return;
    }
    try {
      setLoading(true);
      const data = await file.arrayBuffer();
      const workbook = read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonDealerList: any[] = utils.sheet_to_json(worksheet);
      const dealerListBulk: Array<DealersInterface> = [];
      for (let i = 0; i < jsonDealerList.length; i++) {
        let tipoDealer = tiposDealer.find(
          e => e.nombre.toLowerCase() === jsonDealerList[i]['tipo dealer'].toLowerCase(),
        );
        console.log('tipo dealer', tipoDealer);
        if (jsonDealerList[i].dpi && jsonDealerList[i].email && jsonDealerList[i].nombre && tipoDealer) {
          dealerListBulk.push({
            dpi: jsonDealerList[i].dpi.toString(),
            email: jsonDealerList[i].email.toString(),
            nombre: jsonDealerList[i].nombre.toString(),
            meta: jsonDealerList[i].meta.toString(),
            ubicacion: jsonDealerList[i].ubicacion.toString(),
            telefono: jsonDealerList[i].telefono.toString(),
            idTipoDealer: parseInt(tipoDealer.id),
          });
        }
      }
      const response = await DealersService.instance.createBulkDealers({ dealers: dealerListBulk });
      setLoading(false);
      const { error, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        await getDealersList();
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      handleErrorMessage(e.message);
      handleActiveError(true);
    }
  };

  return (
    <>
      <Box
        mt={1}
        p={2}
        component={Paper}
        style={{ maxHeight: '100vh', overflow: 'auto' }}
      >
        <Grid
          container
          direction='column'
          spacing={2}
        >
          <Grid item>
            <Grid container>
              <Grid
                item
                xs={8}
              >
                <Typography
                  variant='h6'
                  gutterBottom
                >
                  Dealers
                </Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel id='demo-simple-select-label'>Tipo dealer</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={tipoDealer}
                    onChange={handleChangeTipoDealer}
                  >
                    {tiposDealer.map(tipo => (
                      <MenuItem
                        key={tipo.id}
                        value={tipo.id}
                      >
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  component={Link}
                  to='dealers/create'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.button}
                >
                  Crear
                </Button>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  // onClick={cargarExcel}
                  fullWidth
                  variant='contained'
                  color='primary'
                  component='label'
                  className={classes.button}
                >
                  Cargar Excel
                  <input
                    onChange={cargarExcel}
                    type='file'
                    hidden
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{ height: 'calc(100vh - 200px)', width: 'auto' }}
          >
            <DataGrid
              loading={loading}
              rows={data}
              columns={columns}
              rowHeight={50}
              hideFooter
              rowsPerPageOptions={[10]}
            />
          </Grid>
        </Grid>

        <Modal
          className={classes.modal}
          open={openReferidosModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openReferidosModal}>
            <div
              className={classes.paper}
              style={{ maxHeight: '90vh', overflowY: 'scroll' }}
            >
              <h3>Lista de referidos por este dealer</h3>
              <Grid
                item
                style={{ height: 'calc(100vh - 300px)', width: 'auto' }}
              >
                <DataGrid
                  loading={loading}
                  rows={dataReferidosList}
                  columns={columnsReferidos}
                  rowHeight={50}
                  hideFooter
                  rowsPerPageOptions={[10]}
                />
              </Grid>
            </div>
          </Fade>
        </Modal>
        <ModalConfirm
          buttonText="Confirmar"
          title="Borrar dealer"
          contentText="¿Estás seguro de borrar este dealer?"
          cancelText="Cancelar"
          confirmText="Confirmar"
          handleConfirm={handleConfirmDelete}
          handleClose={handleCloseDelete}
          open={openDeleteModal}
        />
      </Box>
    </>
  );
}
