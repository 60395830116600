import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Dispatch, SetStateAction, useContext } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import ContracargosService from '../services/ContracargosService';
import { GeneralContext } from '../context/GeneralContext';
import localStorage from '../libs/localStorage'


type ModalSeguimientoProps = {
    isOpen: boolean;
    contracargoId: string
    negocioId: string
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const seguimientos = [
  {
    id :1,
    name: 'Comercio sin documentos'
  },
  {
    id :2,
    name: 'Solicitud de más información'
  },
  {
    id: 3,
    name: 'Sin contacto'
  },
  {
    id: 4,
    name: 'Otro'
  },
  {
    id: 5,
    name: 'Recordatorio'
  },
  {
    id: 6,
    name: 'Traslado de documentación a banco. (Pendiente activación)'
  },
  {
    id: 7,
    name: 'Contracargo saldado'
  }
]


export default function ModalSeguimiento({contracargoId , setIsOpen, isOpen , negocioId }: ModalSeguimientoProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
          modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            
          },
          paper: {

            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minWidth: '50vw',
          },
          imageList: {
            width: 'auto',
            height: 600,
          },
         
          transitionGrid: {
            transition: 'margin-right 0.3s ease-in-out',  // Cambiado a 'right'
            position: 'fixed',  // Agregado para fijar la posición
            top: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
            bottom: 0,  // Otra propiedad de posición fija para ajustar según tus necesidades
          },
          archivos: {
            marginRight: 0,  // Inicialmente oculto
          },
          button: {
            margin: theme.spacing(1),
          },
        }),
      );
  const {
    selectedRecord,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    
  } = useContext(GeneralContext);
  const classes = useStyles();
  const [seguimientoMotivo, setSeguimientoMotivo] = React.useState<any>(0);
  const [seguimientoMotivoPersonalizado, setSeguimientoMotivoPersonalizado] = React.useState<any>('');

  const sendSeguimiento = async (seguimiento: any, motivoPersonalizado:any) => {
    try {
      let user = await localStorage.instance.getUserParseado();
      const result = await ContracargosService.instance.sendSeguimiento({ contracargoId, motivo:seguimiento, motivoMensaje: motivoPersonalizado, negocioId , UsuarioId: user?.id});
      handleSuccessMessage(result.message);
      handleActiveSuccess(true);
      setIsOpen(false);
      setSeguimientoMotivo(0)
      setSeguimientoMotivoPersonalizado('')
    } catch (error) {
      handleErrorMessage(`Error: ${error}`);
      handleActiveError(true);
    }

  }
  
  return (
    <Modal
    className={classes.modal}
    open={isOpen}
    onClose={() => { setIsOpen(false) }}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
      <Fade in={isOpen}>
      <div className={classes.paper}>
        <h3 id='transition-modal-description'>Enviar Seguimiento</h3>
        
        <Grid
          container
          direction='column'
          spacing={2}
         
        >
          <Grid
            item
            style={{ minHeight: 200, height: 'auto', width: 'auto' }}
          >
            <Grid item xs={12} style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}>
             <FormControl fullWidth variant="outlined">
              <InputLabel  id="demo-simple-select-outlined-label">
                Motivo Seguimiento
              </InputLabel>

              <Select
                fullWidth
                variant='outlined'
                placeholder='Tipo de Segimiento'
                label="Motivo Seguimiento"
                value={seguimientoMotivo}
                onChange={(e) => setSeguimientoMotivo(e.target.value)}
                style={{ 
                  paddingRight: '5',
                  paddingLeft: '5',
                }}
                inputProps={{
                  name: 'seguimiento-motivo',
                  id: 'seguimiento-motivo-select',
                }}
                >
                  { seguimientos.map((seguimiento, index) => (
                    <MenuItem key={index} value={seguimiento.id}>{seguimiento.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
             
            </Grid>
            { seguimientoMotivo === 4 ?
             <Grid item xs={12} style={{
              marginTop: '20',
              marginBottom: '20',
            }}>
             <TextField
                label='Escriba motivo de seguimiento personalizado'
                variant="outlined"
                onChange={(e) => setSeguimientoMotivoPersonalizado(e.target.value)}
                style={{
                  width: '100%'
                }}
              />
            </Grid> : null
             
            }

            <Grid item xs={12} style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               marginTop: 10,
               marginBottom: 10
            }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                alignItems: 'center',
              }}
              onClick={(event:any) => {
                event.stopPropagation();
                //setContracargoId(e.id);
                // setOpenModalSeguimiento(true)
                 sendSeguimiento(seguimientoMotivo, seguimientoMotivoPersonalizado)

              }}
              className={classes.button}
              disabled= {(seguimientoMotivo === 0) || (seguimientoMotivo === 4 && seguimientoMotivoPersonalizado.length <= 1 )}
            >
              Enviar
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                alignItems: 'center',
              }}
              onClick={(event:any) => {
                event.stopPropagation();
                setSeguimientoMotivo(0)
                setSeguimientoMotivoPersonalizado('')
                setIsOpen(false)
              }}
              className={classes.button}
            >
              Cancelar
            </Button>
          </Grid>
          </Grid>
        
        </Grid>
      </div>
    </Fade>
  </Modal>
  )

}
