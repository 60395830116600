import React, { useContext } from 'react';
import { Button, Card, CardContent, Chip, Dialog, DialogContent, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GeneralContext } from '../context/GeneralContext';
import RazonesService from '../services/RazonesService';
import { documentosRazonesEstados, mposInventoryStatus } from '../resources/Statics';
import DocumentosRazonesService from '../services/DocumentosRazonService';

interface Link {
    id: number
  link: string;
  extension: string;
  estado: string;
}

interface Info {
  label: string;
  value: string;
}

interface CarouselDialogProps {
  links: Link[];
  open: boolean;
  info?: Info[];
  onClose: () => void;
  reload: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }),
);

const CarouselDialog: React.FC<CarouselDialogProps> = ({ links, open, onClose, info, reload }) => {
  const { handleErrorMessage, handleActiveError, handleLoading, loading, selectedRecord, handleSuccessMessage, handleActiveSuccess, handleSelectRecord } =
  useContext(GeneralContext);
  const classes = useStyles();
  const [motivo, setMotivo] = React.useState('');
  const [openMotivo, setOpenMotivo] = React.useState(false);
  const [linkActual, setLinkActual] = React.useState<Link>();
  const [estadoActual, setEstadoActual] = React.useState<string>('');

    const handleChange = async (e: any, link: Link) => {
      
      handleLoading(true);
      if (link.id)
        DocumentosRazonesService.instance
          .updateDocumento(
            link.id,
            { estado: e.target.value, motivo: motivo}
          )
          .then(response => {
            const { error, message } = response;
            if (!error) {
              link.estado = e.target.value;
              reload();
              handleActiveSuccess(true);
              handleSuccessMessage(message);
            } else {
              handleActiveError(true);
              handleErrorMessage(message);
            }
            //getInventoryMpos();
            handleLoading(false);
          })
          .catch(e => {
            handleLoading(false);
            handleActiveError(true);
            handleErrorMessage(e.message);
          });
    }

    const onCloseMotivo = () => {
      setOpenMotivo(false);
      setMotivo('');
      setLinkActual(undefined);
      setEstadoActual('');
    }

    const setEstado = (e: any, link: Link) => {

      if (e.target.value === 'Rechazado'){
        setLinkActual(link);
        setEstadoActual(e.target.value);
        setOpenMotivo(true);
      } else {
        handleChange(e, link);
      }
    }

  return (
    <>
    <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
        <DialogContent dividers>
            <Grid container spacing={3}>
                <Grid style={{padding: '2px'}} item xs={12} md={3}>
                    <>
                        <Typography variant='h6' gutterBottom>
                            {links.length} archivo{links.length > 1 ? 's' : ''} encontrado{links.length > 1 ? 's' : ''}
                        </Typography>
                        <>
                        {info && info.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Paper key={index+'paper'} className={classes.paper}>
                              <Typography key={index+'label'} variant='subtitle2' >
                                <strong>{item.label}:</strong>
                              </Typography>
                              <Typography key={index+'value'} variant='body2' >
                                {item.value}
                              </Typography>
                            </Paper>
                          </Grid>
                            
                        ))}
                        </>
                    </>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Carousel showThumbs={false}>
                        {links.map((link, index) => {
                            const extension = link.extension.trim().toLowerCase();

                            const estado = (<FormControl>
                                <Select
                                    value={link.estado}
                                    disabled={link.estado === 'Aceptado'}
                                    onChange={e => setEstado(e, link)}
                                    style={{ minWidth: 110, lineHeight: 'unset' }}
                                >
                                    {documentosRazonesEstados.map(status => {
                                    
                                        return (
                                        <MenuItem
                                            style={{ padding: 10 }}
                                            key={status.value}
                                            value={status.value}
                                        >
                                            <Chip
                                            label={status.value}
                                            style={{ background: status.color, color: 'white' }}
                                            />
                                        </MenuItem>
                                        );
                                    
                                    })}
                                </Select>
                                </FormControl>)


                        if (extension === 'pdf') {
                            return (
                                <>
                                {estado}
                                <iframe 
                                src={link.link}
                                style={{width: '80%', height: '600px'}}
                                key={index}
                                />
                                </>
                            );
                        } else if (['jpg','png', 'jpeg'].indexOf(extension) !== -1) {
                            return ( 
                                <>
                                {estado}
                                    <img style={{textAlign:'center'}} src={link.link} alt={`Carousel item ${index + 1}`} key={index} />
                                </>
                            );
                        } else {
                            return <div>Unsupported file type</div>;
                        }
                        })}
                    </Carousel>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
    <Dialog fullWidth maxWidth='md' open={openMotivo} onClose={onCloseMotivo}>
      <DialogContent dividers style={{padding:'2em'}}>
        <TextField
          id="outlined-multiline-motivo"
          label="Ingrese el motivo del rechazo"
          multiline
          fullWidth
          onChange={e => setMotivo(e.target.value)}
          rows={4}
          defaultValue={motivo}
          variant="outlined"
          value={motivo}
        />
        <br/>
        <br/>
        
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => {
            if (motivo.trim() === '') {
              handleActiveError(true);
              handleErrorMessage('Debe ingresar un motivo');
              return;
            }
            handleChange({target: {value: estadoActual}}, linkActual as Link);
            onCloseMotivo();
          }}
        >
          Guardar
        </Button>
      </DialogContent>
    </Dialog>

    
    </>
  );
};

export default CarouselDialog;