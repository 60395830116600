import * as momentZone from 'moment-timezone';
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { FormField } from '../../../interfaces/Interfaces'
import { Rutas } from '../../../resources/Statics'
import { useContext, useEffect, useState } from 'react';
import CrudService from '../../../services/CrudService';
import { GeneralContext } from '../../../context/GeneralContext';
import { FormControl, Input, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UbicacionInventarioMpos() {
  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);
  const [responsables, setResponsables] = useState<{id: number, nombre: string}[]>([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getResponsables();
  }, [refresh]);

  const handleChange = async (event: any, row: any, field = 'responsables') => {
    const valor = event.target.value;
    let registro = {[field]: valor}
    try {
      const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
      const response = await crudService.updateOne(row.id, registro);
      const { error, result } = response;
      if (!error) {
          setRefresh(true);
      } else {
          handleErrorMessage('message');
          handleActiveError(true);
      }
      setRefresh(false);
      
    } catch (e) {
      console.error('Error al actualizar la base de datos:', e);
    }
  };

  const getResponsables = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/ubicaciones/responsables');
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setResponsables(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const formConfig: FormField[] = [
    {
      type: 'text',
      label: 'Descripcion',
      name: 'descripcion',
      required: true,
      error: 'Debe ingresar una descripcion',
      value: '',
    },
    {
      type: 'select-multiple-autocomplete',
      label: 'Responsable/s',
      name: 'responsables',
      required: true,
      error: 'Debe seleccionar al menos un responsable',
      options: responsables.map((responsable) => ({value: responsable.id, label: responsable.nombre})),
      value: '',
    },
  ]
  
  const columnsUbicaciones = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 70,
        disableClickEventBubbling: true,
    },
    {
        field: 'descripcion',
        headerName: 'Descripcion',
        sortable: false,
        width: 270,
        disableClickEventBubbling: true,
        editable: true
    },
    {
      field: 'responsables',
      headerName: 'Responsable/s',
      sortable: false,
      width: 780,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const valoresresponsables = params.row.responsables || []
        return (
          
            <FormControl fullWidth>
            <Autocomplete
              multiple
              fullWidth
              id="tags-outlined"
              options={responsables.map((responsable) => ({value: responsable.id, label: responsable.nombre}))}
              getOptionLabel={(option) => option.label}
              defaultValue={valoresresponsables.map((res:{id:number,nombre:string}) => ({value: res.id, label: res.nombre}))}
              onChange={(e,valor) => {
                let valornuevo = valor.map((v) => v.value) as string[];
                handleChange({target:{value:valornuevo}}, params.row, 'responsables')
              }}
              filterSelectedOptions
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
            </FormControl>
          
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creacion',
      width: 190,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha actualizacion',
      width: 220,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'updatedAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
  ];

  return (
      <CrudDataGrid delete title='Ubicaciones inventario' refresh={refresh} formConfig={formConfig} apiEndpoint={Rutas.instance.urlUbicacionesInventarioMpos} columns={columnsUbicaciones} placeholder='descripcion' />
  )
}
