import momentZone from 'moment-timezone';
import { Box, Button, Chip, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CrudService from '../../services/CrudService';
import { Rutas } from '../../resources/Statics';
import { GeneralContext } from '../../context/GeneralContext';
import { DataGrid } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import { AssignmentTwoTone, DoneOutlineTwoTone, Edit, HourglassEmpty, HourglassEmptyTwoTone } from '@material-ui/icons';

const AtenderSolicitudes = () => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord
  } = useContext(GeneralContext);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (page > 0) getRows();
  } , [page]);

  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(procesarGetRows, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  const procesarGetRows = () => {
    if (page === 1) getRows();
    else setPage(1);
  }

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      getRows();
    }
  }

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const getRows = () => {
    setLoading(true);
    const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
    const crudService = CrudService.instance(Rutas.instance.urlSolicitudPos + '/atender');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRows(result);
          setTotalPages(totalPages);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const columns = [
    {
      field: 'acciones',
      headerName: ' ',
      width: 50,
      renderCell: (params: any) => (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              component={Link}
              to={`atender-solicitudes/${params.row.id}`}
              title='Atender solicitud'
            >
              <AssignmentTwoTone />
            </IconButton>
          </div>
      ),
    },
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'descripcion', headerName: 'Descripción', width: 200 },
    { field: 'cantidad', headerName: 'Cantidad de POS', width: 200 },
    { field: 'UsuarioSolicitaId', headerName: 'Usuario solicita', width: 260,
    renderCell: (params: any) => {
      const usuarioSolicita = params.getValue(params.id, 'usuarioSolicita');
      return <span>{usuarioSolicita?.nombre} - {usuarioSolicita?.email}</span>;
      }
     },
    { field: 'movimientosRealizados', headerName: 'Movimientos atendidos', width: 250,
    renderCell: (params: any) => {
      const pedidos = params.getValue(params.id, 'pedidos');
      const atendidos = params.getValue(params.id, 'atendidos');
      return (
        atendidos !== pedidos ?
          <Chip
            icon={<HourglassEmptyTwoTone />}
            size="medium"
            label={`${atendidos} de ${pedidos}`}
            clickable
          />
          :
          <Chip
          icon={<DoneOutlineTwoTone />}
            size="medium"
            label={`${atendidos} de ${pedidos}`}
            clickable
            color="secondary"
          />
      )
    }},
    { field: 'createdAt', headerName: 'Fecha', width: 200,
    renderCell: (params: any) => {
      const fechaTZ6 = momentZone
        .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
        .locale('es')
        .format('YYYY-MM-DD HH:mm A');
      return <span>{fechaTZ6}</span>;
    }},
  ];

  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>
            Solicitudes de POS recibidas
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box
            justifyContent={['flex-start', 'flex-start', 'flex-end']}
            sx={{ flexDirection: 'row', display: 'flex' }}
          >
            <Grid item>
              
              <TextField
                label='Buscar'
                placeholder='Buscar'
                variant='outlined'
                size='small'
                style={{ margin: 5}}
                name='search'
                value={searchValue}
                onKeyDown={handleKeyDownChange}
                onChange={handleSearchChange}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            rowHeight={50}
            paginationMode='server'
            pageSize={pageSize}
            rowCount={totalPages * pageSize}
            hideFooterPagination
            hideFooter
            //onCellEditCommit={handleCellEditCommit}
            autoHeight
            rowsPerPageOptions={[10, 25, 50, 100]} />
            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                shape='rounded'
                size='medium'
                variant='outlined'
                color='primary'
                style={{ padding: '8px', textAlign: 'center' }}
                onChange={(event, value) => setPage(value)}
              />
            </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AtenderSolicitudes