import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { primary, grisFigma, grisFigma2 } from '../styles/colors';
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import PayWithLinkService from '../services/PayWithLinkService';
import { GeneralContext } from '../context/GeneralContext';
import PaymentIcon from '@material-ui/icons/Payment';
import * as momentZone from 'moment-timezone';
import { Images } from '../styles/images';
import ValidationFields from '../libs/validationFields';
import { estadosEnlace, departamentos } from '../resources/Statics';
import PageLoader from '../components/PageLoader';
import { ConfirmPayment } from './ConfirmPayment';
import moment from 'moment';
import { Divider } from '@material-ui/core';

const localization = require('moment/locale/es');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    paddingTop: '5%',
  },
  inputPadding: {
    paddingTop: '1%',
  },
}));

function CardNumberMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      autoComplete='cc-number'
      maxLength='19'
    />
  );
}

CardNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirationMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

ExpirationMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CvvMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      autoComplete='cc-number'
      maxLength='19'
    />
  );
}

CvvMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CodigoPostalMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      autoComplete='cc-number'
      maxLength='19'
    />
  );
}

CodigoPostalMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function PayWithLink() {
  const classes = useStyles();
  const params = useParams<{ payCode: string }>();
  const history = useHistory();
  const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage } =
    useContext(GeneralContext);

  const [values, setValues] = useState({
    numeroTarjeta: '',
    nombreTitular: '',
    expiracion: '',
    nombre: '',
    apellido: '',
    compania: '',
    direccionUno: '',
    direccionDos: '',
    ciudad: '',
    codigoPostal: '',
    numeroTelefonico: '',
    cvv: '',
    nit: '',
    email: '',
    numeroDeVisaCuotas: '',
    total: 0,
    number: '',

    // Datos de link rapido
    concepto: '',
    monto: 0,
    marcaTarjeta: '',
  });

  // const [values, setValues] = useState({
  //     numeroTarjeta: '4000 0000 0000 5944',
  //     nombreTitular: 'Jose Santizo',
  //     expiracion: '01/24',
  //     nombre: 'Jose ',
  //     apellido: 'Santizo',
  //     compania: '',
  //     direccionUno: 'Direccion Nueva',
  //     direccionDos: '',
  //     ciudad: 'GUATEMALA',
  //     codigoPostal: '010203',
  //     numeroTelefonico: '22233345',
  //     cvv: '123',
  //     nit: 'CF',
  //     email: 'josesantizo148@gmail.com',
  //     numeroDeVisaCuotas: '',
  //     total: 0,
  //     number: '',

  //     // Datos de link rapido
  //     concepto: '123',
  //     monto: 0,
  //     marcaTarjeta: "",

  // });

  const [width, setWidth] = useState(window.innerWidth);

  const [monto, setMonto] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [pagoRapido, setEsPagoRapido] = React.useState(false);
  const [nombreEmpresa, setNombreEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [cantidadCuotas, setCantidadCuotas] = React.useState('');

  const [enlacePagado, setEnlacePagado] = React.useState(false);
  const [enlaceCaducado, setEnlaceCaducado] = React.useState(false);
  const [enlaceAnulado, setEnlaceAnulado] = React.useState(false);

  const [typeCard, setTypeCard] = React.useState<string | undefined>('');
  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const [negocioId, setNegocioId] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    async function getLinkInfo() {
      let temp = values.numeroTarjeta.replace(/\s+/g, '');

      let tarjeta = ValidationFields.instance.creditCardType(temp);

      setTypeCard(tarjeta);

      handleLoading(true);
      if (params.payCode !== undefined) {
        let result = (await PayWithLinkService.instance.getEnlaceInfo(params.payCode)) as any;
        if (!result.error) {
          if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.pagado
          ) {
            setEnlacePagado(true);
            handleLoading(false);
            return;
          } else if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.anulado
          ) {
            setEnlaceAnulado(true);
            handleLoading(false);
            return;
          } else if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.caducado
          ) {
            setEnlaceCaducado(true);
            handleLoading(false);
            return;
          }

          console.log(result.result.status);

          setMonto(result.result.monto);
          setDescripcion(result.result.concepto);
          setEsPagoRapido(result.result.rapido);
          setNombreEmpresa(result.result.negocio.nombre);
          setEmail(result.result.usuario.email);
          setTelefono(result.result.negocio.telefono);
          setDireccion(result.result.negocio.numeroCasa);
          setNegocioId(result.result.NegocioId);

          values.email = result.result.email;
          values.numeroDeVisaCuotas = result.result.numeroDeVisaCuotas;

          switch (result.result.numeroDeVisaCuotas) {
            case 'VC02':
              setCantidadCuotas('2 cuotas');
              break;
            case 'VC03':
              setCantidadCuotas('3 cuotas');
              break;
            case 'VC06':
              setCantidadCuotas('6 cuotas');
              break;
            case 'VC10':
              setCantidadCuotas('10 cuotas');
              break;
            case 'VC12':
              setCantidadCuotas('12 cuotas');
              break;
            case 'VC15':
              setCantidadCuotas('15 cuotas');
              break;
            case 'VC18':
              setCantidadCuotas('18 cuotas');
              break;
            case 'VC24':
              setCantidadCuotas('24 cuotas');
              break;
            case 'VC36':
              setCantidadCuotas('36 cuotas');
              break;
            case 'VC48':
              setCantidadCuotas('48 cuotas');
              break;
            default:
              setCantidadCuotas('Contado');
              break;
          }

          setValues(values);

          let fechaGuatemala = momentZone.tz(moment(), 'America/Guatemala').locale('es').format('LLLL');

          setFecha(fechaGuatemala.toString());
        } else {
          handleErrorMessage(result.message);
          handleActiveError(true);
        }
        //handleLoading(false)
      }
    }

    getLinkInfo();
  }, []);

  const validateEmail = (email: string): boolean => {
    // Expresión regular para validar direcciones de correo electrónico.
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validarTarjetaCredito = (number: string): boolean => {
    number = number.replace(/\s+/g, ''); // Elimina los espacios en blanco.

    // Si la suma total es un múltiplo de 10, el número es válido.
    return number.length > 0;
  };

  const validarFechaPago = (fechaIngresada: string): { error: boolean; message: string } => {
    // Verifica si la fecha ingresada tiene el formato MM/YY
    if (!/^\d{2}\/\d{2}$/.test(fechaIngresada)) {
      return { error: true, message: 'El formato de la fecha de expiración de la tarjeta no es válido' };
    }

    // Parsea la fecha ingresada
    const partesFecha = fechaIngresada.split('/');
    const mes = parseInt(partesFecha[0], 10);
    const anio = parseInt(partesFecha[1], 10);

    // Verifica si el mes es válido (de 1 a 12)
    if (mes < 1 || mes > 12) {
      return { error: true, message: 'El mes de la fecha de expiración de la tarjeta no es válido' };
    }

    // Obtiene la fecha actual
    const fechaActual = moment();

    // Define la fecha límite como el último día del mes ingresado
    const fechaLimite = moment()
      .year(anio + 2000)
      .month(mes - 1)
      .endOf('month');

    // Compara la fecha ingresada con la fecha
    if (fechaActual.isBefore(fechaLimite)) {
      return { error: false, message: 'La fecha de expiración de la tarjeta es válida' };
      // La fecha ingresada es válida
    }

    return { error: true, message: 'La fecha de expiración de la tarjeta no es válida' }; // La fecha ingresada no es válida
  };

  const payLink = async () => {
    let tempValues = { ...values };

    if (values.nombre.trim() == '') {
      handleErrorMessage('Ingrese el nombre del nombre para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.apellido.trim() == '') {
      handleErrorMessage('Ingrese el apellido del nombre para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.direccionUno.trim() == '') {
      handleErrorMessage('Ingrese la dirección uno para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.ciudad == '') {
      handleErrorMessage('Seleccione el departamento para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.nit.trim() == '') {
      handleErrorMessage('Ingrese el NIT para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.codigoPostal.trim() == '') {
      handleErrorMessage('Ingrese un código postal para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.codigoPostal.trim().length < 5) {
      handleErrorMessage('Ingrese un código postal valido para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.numeroTelefonico.trim() == '') {
      handleErrorMessage('Ingrese un número telefónico para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.numeroTelefonico.trim().length < 8) {
      handleErrorMessage('Ingrese un número telefónico valido para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (values.email == '') {
      handleErrorMessage('Ingrese el correo electrónico para los datos de facturación');
      handleActiveError(true);
      return;
    } else if (validateEmail(values.email) == false) {
      handleErrorMessage('Ingrese un correo electrónico válido');
      handleActiveError(true);
      return;
    } else if (values.numeroTarjeta == '') {
      handleErrorMessage('Ingrese el número de la tarjeta');
      handleActiveError(true);
      return;
    } else if (values.numeroTarjeta.length < 19) {
      handleErrorMessage('Ingrese el número de la tarjeta completo');
      handleActiveError(true);
      return;
    } else if (validarTarjetaCredito(values.numeroTarjeta) == false) {
      handleErrorMessage('Ingrese un número de tarjeta válido');
      handleActiveError(true);
      return;
    } else if (values.nombreTitular.trim() == '') {
      handleErrorMessage('Ingrese el nombre del titular de la tarjeta');
      handleActiveError(true);
      return;
    } else if (values.expiracion == '') {
      handleErrorMessage('Ingrese la fecha de expiración de la tarjeta');
      handleActiveError(true);
      return;
    } else if (validarFechaPago(values.expiracion).error) {
      handleErrorMessage(validarFechaPago(values.expiracion).message);
      handleActiveError(true);
      return;
    } else if (values.cvv == '') {
      handleErrorMessage('Ingrese el CVV de la tarjeta');
      handleActiveError(true);
      return;
    } else if (typeCard != 'VISA' && typeCard != 'MASTERCARD') {
      handleErrorMessage('Ingrese una tarjeta valida');
      handleActiveError(true);
      return;
    }
    if (values.compania.trim() == '') tempValues.compania = 'NA';
    if (values.direccionDos.trim() == '') tempValues.direccionDos = '';

    tempValues.numeroTarjeta = tempValues.numeroTarjeta.trim().replace(/\s+/g, '');
    tempValues.marcaTarjeta = typeCard as string;
    tempValues.total = tempValues.monto;
    tempValues.number = tempValues.numeroTarjeta;
    //tempValues.numeroDeVisaCuotas = tempValues.numeroDeVisaCuotas.trim().replaceAll('VC01', '')
    // tempValues.nombre = tempValues.nombre.trim()
    // tempValues.nit = tempValues.nit.trim()
    // tempValues.email = tempValues.email.trim()
    // tempValues.nombreTitular = tempValues.nombreTitular.trim()

    handleLoading(true);

    // const res = await axios.get('https://geolocation-db.com/json/')
    // tempValues.ipCliente = res.data.IPv4

    let req = await PayWithLinkService.instance.payLink(params.payCode, tempValues);

    if (!req.error) {
      setValues({
        ...values,
        numeroTarjeta: '',
        nombreTitular: '',
        expiracion: '',
        nombre: '',
        cvv: '',
        nit: '',

        // Datos de link rapido
        concepto: '',
        monto: 0,
      });

      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleSuccessMessage('Error de conexion por favor intente mas tarde');
      } else {
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        history.push(`/voucherLink/${params.payCode}`);
      }
      handleActiveSuccess(true);
    } else {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde');
      } else {
        handleErrorMessage(req.message);
      }
      handleActiveError(true);
    }

    handleLoading(false);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeNumberCard = (prop: any) => (event: any) => {
    let temp = event.target.value;

    setValues({ ...values, [prop]: event.target.value });

    temp = temp.replace(/\s+/g, '');

    let tarjeta = ValidationFields.instance.creditCardType(temp);

    setTypeCard(tarjeta);
  };

  const nombreInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Nombre{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='name'
          autoComplete='name'
          value={values.nombre}
          onChange={handleChange('nombre')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const apellido = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Apellido{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='apellido'
          autoComplete='apellido'
          value={values.apellido}
          onChange={handleChange('apellido')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const direccionUno = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Dirección uno{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='direccionUno'
          autoComplete='direccionUno'
          value={values.direccionUno}
          onChange={handleChange('direccionUno')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const direccionDos = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Dirección dos</InputLabel>
        <OutlinedInput
          type='text'
          name='direccionDos'
          autoComplete='direccionDos'
          value={values.direccionDos}
          onChange={handleChange('direccionDos')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const compania = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Compañía</InputLabel>
        <OutlinedInput
          type='text'
          name='compania'
          autoComplete='compania'
          value={values.compania}
          onChange={handleChange('compania')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const localidad = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
        style={{
          minWidth: 120,
        }}
      >
        <InputLabel
          variant='filled'
          id='demo-simple-select-outlined-label'
        >
          Departamento{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <Select
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          name='ciudad'
          value={values.ciudad}
          defaultValue={values.ciudad}
          onChange={handleChange('ciudad')}
          className={classes.inputPadding}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {departamentos['guatemala'].map((item: string, index: string) => {
            return (
              <MenuItem
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const codigoPostal = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Código Postal{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='codigoPostal'
          autoComplete='codigoPostal'
          value={values.codigoPostal}
          onChange={handleChange('codigoPostal')}
          className={classes.inputPadding}
          inputComponent={CodigoPostalMask}
        />
      </FormControl>
    );
  };

  const numeroTelefonico = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Número Telefónico{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='tel'
          name='numeroTelefonico'
          autoComplete='numeroTelefonico'
          value={values.numeroTelefonico}
          onChange={handleChange('numeroTelefonico')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const conceptoInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Concepto</InputLabel>
        <OutlinedInput
          type='text'
          value={values.concepto}
          onChange={handleChange('concepto')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const montoInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Monto</InputLabel>
        <OutlinedInput
          type='number'
          value={values.monto == 0 ? '' : values.monto}
          onChange={handleChange('monto')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const emailInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Correo electrónico (para compartir voucher){' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='email'
          name='email'
          autoComplete='email'
          value={values.email}
          onChange={handleChange('email')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const nitInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          NIT{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          value={values.nit}
          onChange={handleChange('nit')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const expiracionInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Expiración{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          autoComplete='cc-exp'
          value={values.expiracion}
          onChange={handleChange('expiracion')}
          className={classes.inputPadding}
          inputComponent={ExpirationMask}
        />
      </FormControl>
    );
  };

  const numeroTarjetaInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Número de tarjeta{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          value={values.numeroTarjeta}
          onChange={handleChangeNumberCard('numeroTarjeta')}
          className={classes.inputPadding}
          inputComponent={CardNumberMask}
          endAdornment={
            typeCard !== undefined && typeCard === 'VISA' ? (
              <FaCcVisa size={30} />
            ) : typeCard !== undefined && typeCard === 'MASTERCARD' ? (
              <FaCcMastercard size={30} />
            ) : null
          }
        />
      </FormControl>
    );
  };

  const nombreTitularInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Nombre del titular{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          autoComplete='cc-name'
          value={values.nombreTitular}
          onChange={handleChange('nombreTitular')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const cvvInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          CVV{' '}
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='number'
          value={values.cvv}
          autoComplete='cc-csc'
          onChange={handleChange('cvv')}
          className={classes.inputPadding}
          inputComponent={CvvMask}
        />
      </FormControl>
    );
  };

  return (
    <>
      <div className={classes.root}>
        {enlacePagado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkYaFuePagado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceCaducado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCaducado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceAnulado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkAnulado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : showConfirmComponent ? (
          <ConfirmPayment businessName={nombreEmpresa} />
        ) : (
           (
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: '3%', 
                background: '#f5f5f5',
              }}
            >
              <div
                style={{
                  margin: '0 auto', 
                  maxWidth: '870px',
                  paddingTop: '5%',
                  boxShadow: '0 5px 10px rgba(0,0,0,0.07)',
                  background: '#fff',
                  padding: 50,
                  borderRadius: 50,
                }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color: '#292865',
                          textAlign: 'left',
                          fontSize: '2rem',
                        }}
                        variant='h3'
                      >
                        {nombreEmpresa}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Correo electrónico:
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontWeight: 400,
                            color: '#5166AF',
                            display: 'inline',
                          }}
                        >
                          {' ' + email}
                        </Typography>
                      </Typography>

                      {!pagoRapido ? (
                        <>
                          {cantidadCuotas.length > 0 ? (
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: grisFigma,
                              }}
                            >
                              Pago {`${cantidadCuotas == 'Contado' ? 'de' : 'en'} ${cantidadCuotas}`}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {cantidadCuotas.includes('cuotas') ? (
                            <Typography
                              variant='subtitle1'
                              style={{
                                fontSize: '12px',
                                color: grisFigma,
                              }}
                            >
                              <Typography
                                component='span'
                                color='error'
                              >
                                *
                              </Typography>{' '}
                              Tarjetas emitidas por BAC no son aceptadas por Visa Cuotas
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : null}
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Fecha: {fecha}
                      </Typography>
                      {!pagoRapido ? (
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: grisFigma,
                          }}
                        >
                          Concepto: {descripcion}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex' }}
                    alignItems='flex-end'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        textAlign: 'right',
                        width: '100%',
                        fontWeight: '800',
                        color: '#292865',
                        fontSize: '1.8rem',
                        display: 'block',
                      }}
                    >
                      {' Q' + monto}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      style={{
                        marginTop: 20,
                        color: grisFigma,
                        fontWeight: 550,
                      }}
                    >
                      Datos de facturación
                    </Typography>
                  </Grid>

                  {pagoRapido ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: '2%',
                        }}
                      >
                        {conceptoInput()}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: '2%',
                        }}
                      >
                        {montoInput()}
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nombreInput()}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {apellido()}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionUno()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionDos()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {localidad()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {compania()}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nitInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {codigoPostal()}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {numeroTelefonico()}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {emailInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                        fontWeight: 550,
                      }}
                    >
                      Datos de tarjeta
                      <div style={{ display: 'inline', paddingLeft: '1%' }}>
                        <img
                          width={30}
                          src={Images.instance.iconoMasterCard}
                        />
                        <img
                          width={40}
                          src={Images.instance.iconoVisa}
                        />
                      </div>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {numeroTarjetaInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {nombreTitularInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {expiracionInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {cvvInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '7%',
                    }}
                  >
                    <Button
                      fullWidth={true}
                      color='primary'
                      disabled={loading}
                      variant='contained'
                      className={classes.button}
                      onClick={payLink}
                    >
                      Pagar
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      style={{
                        color: grisFigma2,
                        textAlign: 'center',
                        fontWeight: 600,
                        paddingTop: 15,
                      }}
                      variant='subtitle2'
                    >
                      <div style={{ display: 'inline', paddingRight: '1%' }}>
                        <img
                          width={15}
                          src={Images.instance.candadoEnlace}
                        />
                      </div>
                      Por políticas de seguridad, tú paggo es seguro.
                    </Typography>
                    <Typography
                      style={{
                        color: grisFigma2,
                        textAlign: 'center',
                        paddingBottom: 50,
                      }}
                      variant='subtitle2'
                    >
                      Ni el comercio afiliado, ni Paggo recopila la información de tú tarjeta. Solamente tú puedes ver
                      esta información.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20
                }}
              >
                <img
                  width={width >= 700 ? width * 0.15 : 200}
                  src={Images.instance.logo}
                  alt=''
                  style={{
          
                    marginBottom: 40,
                  }}
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </div>
          )
        )}

        <PageLoader loading={loading} />
      </div>
    </>
  );
}
