import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'
import { primary, grisFigma } from '../styles/colors'
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import PayWithLinkService from '../services/PayWithLinkService'
import { GeneralContext } from '../context/GeneralContext'
import PaymentIcon from '@material-ui/icons/Payment';
import * as momentZone from 'moment-timezone'
import { Images } from '../styles/images'
import ValidationFields from '../libs/validationFields'
import { estadosEnlace } from '../resources/Statics'
import PageLoader from '../components/PageLoader';
import { ConfirmPayment } from './ConfirmPayment';
import moment from "moment";
import { valuesInterface3dsTransaction } from '../interfaces/Interfaces';
import { InputAdornment } from '@material-ui/core';

const localization = require('moment/locale/es')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    paddingTop: '5%'
  },
  inputPadding: {
    paddingTop: '1%'
  }
}));

function CardNumberMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="23"
    />
  );
}

CardNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirationMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

ExpirationMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CvvMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="19"
    />
  );
}

CvvMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CodigoPostalMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="19"
    />
  );
}

CodigoPostalMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumeroTelefonicoMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

NumeroTelefonicoMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const departamentosHonduras: { departamento: string, iso: string, upperCase: string, municipios: { municipio: string, iso: string, upperCase: string }[] }[] = [
  {
    departamento: 'Atlántida', iso: 'AT', upperCase: 'ATLANTIDA'
    , municipios: [
      { municipio: 'La Ceiba', iso: 'LC', upperCase: 'LA CEIBA' },
      { municipio: 'El Porvenir', iso: 'EP', upperCase: 'EL PORVENIR' },
      { municipio: 'Esparta', iso: 'ES', upperCase: 'ESPARTA' },
      { municipio: 'Jutiapa', iso: 'JU', upperCase: 'JUTIAPA' },
      { municipio: 'La Masica', iso: 'LM', upperCase: 'LA MASICA' },
      { municipio: 'San Francisco', iso: 'SF', upperCase: 'SAN FRANCISCO' },
      { municipio: 'Tela', iso: 'TE', upperCase: 'TELA' },
    ]
  },
  {
    departamento: 'Choluteca', iso: 'CH', upperCase: 'CHOLUTECA'
    , municipios: [
      { municipio: 'Choluteca', iso: 'CH', upperCase: 'CHOLUTECA' },
      { municipio: 'Apacilagua', iso: 'AP', upperCase: 'APACILAGUA' },
      { municipio: 'Concepción de María', iso: 'CM', upperCase: 'CONCEPCION DE MARIA' },
      { municipio: 'Duyure', iso: 'DU', upperCase: 'DUYURE' },
      { municipio: 'El Corpus', iso: 'EC', upperCase: 'EL CORPUS' },
      { municipio: 'El Triunfo', iso: 'ET', upperCase: 'EL TRIUNFO' },
      { municipio: 'Marcovia', iso: 'MA', upperCase: 'MARCOVIA' },
      { municipio: 'Morolica', iso: 'MO', upperCase: 'MOROLICA' },
      { municipio: 'Namasigüe', iso: 'NA', upperCase: 'NAMASIGUE' },
      { municipio: 'Orocuina', iso: 'OR', upperCase: 'OROCUINA' },
      { municipio: 'Pespire', iso: 'PE', upperCase: 'PESPIRE' },
      { municipio: 'San Marcos de Colón', iso: 'SM', upperCase: 'SAN MARCOS DE COLON' },
    ]
  },
  {
    departamento: 'Colón', iso: 'CO', upperCase: 'COLON'
    , municipios: [
      { municipio: 'Trujillo', iso: 'TR', upperCase: 'TRUJILLO' },
      { municipio: 'Balfate', iso: 'BA', upperCase: 'BALFATE' },
      { municipio: 'Iriona', iso: 'IR', upperCase: 'IRIONA' },
      { municipio: 'Limón', iso: 'LI', upperCase: 'LIMON' },
      { municipio: 'Sabá', iso: 'SA', upperCase: 'SABA' },
      { municipio: 'Santa Fe', iso: 'SF', upperCase: 'SANTA FE' },
      { municipio: 'Santa Rosa de Aguan', iso: 'SR', upperCase: 'SANTA ROSA DE AGUAN' },
      { municipio: 'Sonaguera', iso: 'SO', upperCase: 'SONAGUERA' },
      { municipio: 'Tocoa', iso: 'TO', upperCase: 'TOCOA' },
    ]
  },
  {
    departamento: 'Comayagua', iso: 'CM', upperCase: 'COMAYAGUA'
    , municipios: [
      { municipio: 'Comayagua', iso: 'CM', upperCase: 'COMAYAGUA' },
      { municipio: 'Ajuterique', iso: 'AJ', upperCase: 'AJUTERIQUE' },
      { municipio: 'El Rosario', iso: 'ER', upperCase: 'EL ROSARIO' },
      { municipio: 'Esquías', iso: 'ES', upperCase: 'ESQUIAS' },
      { municipio: 'Humuya', iso: 'HU', upperCase: 'HUMUYA' },
      { municipio: 'La Libertad', iso: 'LL', upperCase: 'LA LIBERTAD' },
      { municipio: 'Lamaní', iso: 'LA', upperCase: 'LAMANI' },
      { municipio: 'La Trinidad', iso: 'LT', upperCase: 'LA TRINIDAD' },
      { municipio: 'Lejamaní', iso: 'LE', upperCase: 'LEJAMANI' },
      { municipio: 'Meámbar', iso: 'ME', upperCase: 'MEAMBAR' },
      { municipio: 'Minas de Oro', iso: 'MO', upperCase: 'MINAS DE ORO' },
      { municipio: 'Ojos de Agua', iso: 'OA', upperCase: 'OJOS DE AGUA' },
      { municipio: 'San Jerónimo', iso: 'SJ', upperCase: 'SAN JERONIMO' },
      { municipio: 'San José de Comayagua', iso: 'SJ', upperCase: 'SAN JOSE DE COMAYAGUA' },
      { municipio: 'San José del Potrero', iso: 'SJ', upperCase: 'SAN JOSE DEL POTRERO' },
      { municipio: 'San Luis', iso: 'SL', upperCase: 'SAN LUIS' },
      { municipio: 'San Sebastián', iso: 'SS', upperCase: 'SAN SEBASTIAN' },
      { municipio: 'Siguatepeque', iso: 'SI', upperCase: 'SIGUATEPEQUE' },
    ]
  },
  {
    departamento: 'Copán', iso: 'CP', upperCase: 'COPAN'
    , municipios: [
      { municipio: 'Santa Rosa de Copán', iso: 'SR', upperCase: 'SANTA ROSA DE COPAN' },
      { municipio: 'Cabañas', iso: 'CA', upperCase: 'CABANAS' },
      { municipio: 'Concepción', iso: 'CO', upperCase: 'CONCEPCION' },
      { municipio: 'Copán Ruinas', iso: 'CR', upperCase: 'COPAN RUINAS' },
      { municipio: 'Corquín', iso: 'CO', upperCase: 'CORQUIN' },
      { municipio: 'Cucuyagua', iso: 'CU', upperCase: 'CUCUYAGUA' },
      { municipio: 'Dolores', iso: 'DO', upperCase: 'DOLORES' },
      { municipio: 'Dulce Nombre', iso: 'DU', upperCase: 'DULCE NOMBRE' },
      { municipio: 'El Paraíso', iso: 'EP', upperCase: 'EL PARAISO' },
      { municipio: 'Florida', iso: 'FL', upperCase: 'FLORIDA' },
      { municipio: 'La Jigua', iso: 'LJ', upperCase: 'LA JIGUA' },
      { municipio: 'La Unión', iso: 'LU', upperCase: 'LA UNION' },
      { municipio: 'Nueva Arcadia', iso: 'NA', upperCase: 'NUEVA ARCADIA' },
      { municipio: 'San Agustín', iso: 'SA', upperCase: 'SAN AGUSTIN' },
      { municipio: 'San Antonio', iso: 'SA', upperCase: 'SAN ANTONIO' },
      { municipio: 'San Jerónimo', iso: 'SJ', upperCase: 'SAN JERONIMO' },
      { municipio: 'San José', iso: 'SJ', upperCase: 'SAN JOSE' },
      { municipio: 'San Juan de Opoa', iso: 'SJ', upperCase: 'SAN JUAN DE OPOA' },
      { municipio: 'San Nicolás', iso: 'SN', upperCase: 'SAN NICOLAS' },
      { municipio: 'San Pedro', iso: 'SP', upperCase: 'SAN PEDRO' },
      { municipio: 'Santa Rita', iso: 'SR', upperCase: 'SANTA RITA' },
      { municipio: 'Trinidad de Copán', iso: 'TC', upperCase: 'TRINIDAD DE COPAN' },
    ]
  },
  {
    departamento: 'Cortés', iso: 'CR', upperCase: 'CORTES'
    , municipios: [
      { municipio: 'San Pedro Sula', iso: 'SP', upperCase: 'SAN PEDRO SULA' },
      { municipio: 'Choloma', iso: 'CH', upperCase: 'CHOLOMA' },
      { municipio: 'La Lima', iso: 'LL', upperCase: 'LA LIMA' },
      { municipio: 'Omoa', iso: 'OM', upperCase: 'OMOA' },
      { municipio: 'Pimienta', iso: 'PI', upperCase: 'PIMIENTA' },
      { municipio: 'Potrerillos', iso: 'PO', upperCase: 'POTRERILLOS' },
      { municipio: 'Puerto Cortés', iso: 'PC', upperCase: 'PUERTO CORTES' },
      { municipio: 'San Antonio de Cortés', iso: 'SA', upperCase: 'SAN ANTONIO DE CORTES' },
      { municipio: 'San Francisco de Yojoa', iso: 'SF', upperCase: 'SAN FRANCISCO DE YOJOA' },
      { municipio: 'San Manuel', iso: 'SM', upperCase: 'SAN MANUEL' },
      { municipio: 'Santa Cruz de Yojoa', iso: 'SC', upperCase: 'SANTA CRUZ DE YOJOA' },
      { municipio: 'Villanueva', iso: 'VI', upperCase: 'VILLANUEVA' },
    ]
  },
  {
    departamento: 'El Paraíso', iso: 'EP', upperCase: 'EL PARAISO'
    , municipios: [
      { municipio: 'Yuscarán', iso: 'YU', upperCase: 'YUSCARAN' },
      { municipio: 'Alauca', iso: 'AL', upperCase: 'ALAUCA' },
      { municipio: 'Danlí', iso: 'DA', upperCase: 'DANLI' },
      { municipio: 'El Paraíso', iso: 'EP', upperCase: 'EL PARAISO' },
      { municipio: 'Guinope', iso: 'GU', upperCase: 'GUINOPE' },
      { municipio: 'Jacaleapa', iso: 'JA', upperCase: 'JACALEAPA' },
      { municipio: 'Liure', iso: 'LI', upperCase: 'LIURE' },
      { municipio: 'Morocelí', iso: 'MO', upperCase: 'MOROCELI' },
      { municipio: 'Oropolí', iso: 'OR', upperCase: 'OROPOLI' },
      { municipio: 'Potrerillos', iso: 'PO', upperCase: 'POTRERILLOS' },
      { municipio: 'San Antonio de Flores', iso: 'SA', upperCase: 'SAN ANTONIO DE FLORES' },
      { municipio: 'San Lucas', iso: 'SL', upperCase: 'SAN LUCAS' },
      { municipio: 'San Matías', iso: 'SM', upperCase: 'SAN MATIAS' },
      { municipio: 'Soledad', iso: 'SO', upperCase: 'SOLEDAD' },
      { municipio: 'Teupasenti', iso: 'TE', upperCase: 'TEUPASENTI' },
      { municipio: 'Texiguat', iso: 'TE', upperCase: 'TEXIGUAT' },
      { municipio: 'Trojes', iso: 'TR', upperCase: 'TROJES' },
      { municipio: 'Vado Ancho', iso: 'VA', upperCase: 'VADO ANCHO' },
      { municipio: 'Yauyupe', iso: 'YA', upperCase: 'YAUYUPE' },
    ]
  },
  {
    departamento: 'Francisco Morazán', iso: 'FM', upperCase: 'FRANCISCO MORAZAN'
    , municipios: [
      { municipio: 'Distrito Central', iso: 'DC', upperCase: 'DISTRITO CENTRAL' },
      { municipio: 'Alubarén', iso: 'AL', upperCase: 'ALUBAREN' },
      { municipio: 'Cedros', iso: 'CE', upperCase: 'CEDROS' },
      { municipio: 'Curarén', iso: 'CU', upperCase: 'CURAREN' },
      { municipio: 'El Porvenir', iso: 'EP', upperCase: 'EL PORVENIR' },
      { municipio: 'Guaimaca', iso: 'GU', upperCase: 'GUAIMACA' },
      { municipio: 'La Libertad', iso: 'LL', upperCase: 'LA LIBERTAD' },
      { municipio: 'La Venta', iso: 'LV', upperCase: 'LA VENTA' },
      { municipio: 'Lepaterique', iso: 'LE', upperCase: 'LEPATERIQUE' },
      { municipio: 'Maraita', iso: 'MA', upperCase: 'MARAITA' },
      { municipio: 'Marale', iso: 'MA', upperCase: 'MARALE' },
      { municipio: 'Nueva Armenia', iso: 'NA', upperCase: 'NUEVA ARMENIA' },
      { municipio: 'Ojojona', iso: 'OJ', upperCase: 'OJOJONA' },
      { municipio: 'Orica', iso: 'OR', upperCase: 'ORICA' },
      { municipio: 'Reitoca', iso: 'RE', upperCase: 'REITOCA' },
      { municipio: 'Sabanagrande', iso: 'SA', upperCase: 'SABANAGRANDE' },
      { municipio: 'San Antonio de Oriente', iso: 'SA', upperCase: 'SAN ANTONIO DE ORIENTE' },
      { municipio: 'San Buenaventura', iso: 'SB', upperCase: 'SAN BUENAVENTURA' },
      { municipio: 'San Ignacio', iso: 'SI', upperCase: 'SAN IGNACIO' },
      { municipio: 'San Juan de Flores', iso: 'SJ', upperCase: 'SAN JUAN DE FLORES' },
      { municipio: 'San Miguelito', iso: 'SM', upperCase: 'SAN MIGUELITO' },
      { municipio: 'Santa Ana', iso: 'SA', upperCase: 'SANTA ANA' },
      { municipio: 'Santa Lucía', iso: 'SL', upperCase: 'SANTA LUCIA' },
      { municipio: 'Talanga', iso: 'TA', upperCase: 'TALANGA' },
      { municipio: 'Tatumbla', iso: 'TA', upperCase: 'TATUMBLA' },
      { municipio: 'Valle de Ángeles', iso: 'VA', upperCase: 'VALLE DE ANGELES' },
      { municipio: 'Vallecillo', iso: 'VA', upperCase: 'VALLECILLO' },
      { municipio: 'Villa de San Francisco', iso: 'VS', upperCase: 'VILLA DE SAN FRANCISCO' },
      { municipio: 'Zambrano', iso: 'ZA', upperCase: 'ZAMBRANO' },
    ]
  },
  {
    departamento: 'Gracias a Dios', iso: 'GD', upperCase: 'GRACIAS A DIOS'
    , municipios: [
      { municipio: 'Brus Laguna', iso: 'BL', upperCase: 'BRUS LAGUNA' },
      { municipio: 'Juan Francisco Bulnes', iso: 'JF', upperCase: 'JUAN FRANCISCO BULNES' },
      { municipio: 'Puerto Lempira', iso: 'PL', upperCase: 'PUERTO LEMPIRA' },
      { municipio: 'Ramón Villeda Morales', iso: 'RM', upperCase: 'RAMON VILLEDA MORALES' },
      { municipio: 'Wampusirpi', iso: 'WA', upperCase: 'WAMPUSIRPI' },
    ]
  },
  {
    departamento: 'Intibucá', iso: 'IN', upperCase: 'INTIBUCA'
    , municipios: [
      { municipio: 'La Esperanza', iso: 'LE', upperCase: 'LA ESPERANZA' },
      { municipio: 'Camasca', iso: 'CA', upperCase: 'CAMASCA' },
      { municipio: 'Colomoncagua', iso: 'CO', upperCase: 'COLOMONCAGUA' },
      { municipio: 'Concepción', iso: 'CO', upperCase: 'CONCEPCION' },
      { municipio: 'Dolores', iso: 'DO', upperCase: 'DOLORES' },
      { municipio: 'Intibucá', iso: 'IN', upperCase: 'INTIBUCA' },
      { municipio: 'Jesús de Otoro', iso: 'JO', upperCase: 'JESUS DE OTORO' },
      { municipio: 'Magdalena', iso: 'MA', upperCase: 'MAGDALENA' },
      { municipio: 'Masaguara', iso: 'MA', upperCase: 'MASAGUARA' },
      { municipio: 'San Antonio', iso: 'SA', upperCase: 'SAN ANTONIO' },
      { municipio: 'San Isidro', iso: 'SI', upperCase: 'SAN ISIDRO' },
      { municipio: 'San Juan', iso: 'SJ', upperCase: 'SAN JUAN' },
      { municipio: 'San Marcos de la Sierra', iso: 'SM', upperCase: 'SAN MARCOS DE LA SIERRA' },
      { municipio: 'San Miguelito', iso: 'SM', upperCase: 'SAN MIGUELITO' },
      { municipio: 'Santa Lucía', iso: 'SL', upperCase: 'SANTA LUCIA' },
      { municipio: 'Yamaranguila', iso: 'YA', upperCase: 'YAMARANGUILA' },
    ]
  },
  {
    departamento: 'La Paz', iso: 'LP', upperCase: 'LA PAZ'
    , municipios: [
      { municipio: 'La Paz', iso: 'LP', upperCase: 'LA PAZ' },
      { municipio: 'Aguanqueterique', iso: 'AG', upperCase: 'AGUANQUETERIQUE' },
      { municipio: 'Cabañas', iso: 'CA', upperCase: 'CABANAS' },
      { municipio: 'Cane', iso: 'CA', upperCase: 'CANE' },
      { municipio: 'Chinacla', iso: 'CH', upperCase: 'CHINACLA' },
      { municipio: 'Guajiquiro', iso: 'GU', upperCase: 'GUAJIQUIRO' },
      { municipio: 'Lauterique', iso: 'LA', upperCase: 'LAUTERIQUE' },
      { municipio: 'Marcala', iso: 'MA', upperCase: 'MARCALA' },
      { municipio: 'Mercedes de Oriente', iso: 'ME', upperCase: 'MERCEDES DE ORIENTE' },
      { municipio: 'Opatoro', iso: 'OP', upperCase: 'OPATORO' },
      { municipio: 'San Antonio del Norte', iso: 'SA', upperCase: 'SAN ANTONIO DEL NORTE' },
      { municipio: 'San José', iso: 'SJ', upperCase: 'SAN JOSE' },
      { municipio: 'San Juan', iso: 'SJ', upperCase: 'SAN JUAN' },
      { municipio: 'San Pedro de Tutule', iso: 'SP', upperCase: 'SAN PEDRO DE TUTULE' },
      { municipio: 'Santa Ana', iso: 'SA', upperCase: 'SANTA ANA' },
      { municipio: 'Santa Elena', iso: 'SE', upperCase: 'SANTA ELENA' },
      { municipio: 'Santa María', iso: 'SM', upperCase: 'SANTA MARIA' },
      { municipio: 'Santiago de Puringla', iso: 'SP', upperCase: 'SANTIAGO DE PURINGLA' },
      { municipio: 'Yarula', iso: 'YA', upperCase: 'YARULA' },
    ]
  },
  {
    departamento: 'Lempira', iso: 'LE', upperCase: 'LEMPIRA'
    , municipios: [
      { municipio: 'Gracias', iso: 'GR', upperCase: 'GRACIAS' },
      { municipio: 'Belén', iso: 'BE', upperCase: 'BELEN' },
      { municipio: 'Candelaria', iso: 'CA', upperCase: 'CANDELARIA' },
      { municipio: 'Cololaca', iso: 'CO', upperCase: 'COLOLACA' },
      { municipio: 'Erandique', iso: 'ER', upperCase: 'ERANDIQUE' },
      { municipio: 'Gualcince', iso: 'GU', upperCase: 'GUALCINCE' },
      { municipio: 'Guarita', iso: 'GU', upperCase: 'GUARITA' },
      { municipio: 'La Campa', iso: 'LC', upperCase: 'LA CAMPA' },
      { municipio: 'La Iguala', iso: 'LI', upperCase: 'LA IGUALA' },
      { municipio: 'Las Flores', iso: 'LF', upperCase: 'LAS FLORES' },
      { municipio: 'La Unión', iso: 'LU', upperCase: 'LA UNION' },
      { municipio: 'La Virtud', iso: 'LV', upperCase: 'LA VIRTUD' },
      { municipio: 'Lepaera', iso: 'LE', upperCase: 'LEPAERA' },
      { municipio: 'Mapulaca', iso: 'MA', upperCase: 'MAPULACA' },
      { municipio: 'Piraera', iso: 'PI', upperCase: 'PIRAERA' },
      { municipio: 'San Andrés', iso: 'SA', upperCase: 'SAN ANDRES' },
      { municipio: 'San Francisco', iso: 'SF', upperCase: 'SAN FRANCISCO' },
      { municipio: 'San Juan Guarita', iso: 'SJ', upperCase: 'SAN JUAN GUARITA' },
      { municipio: 'San Manuel Colohete', iso: 'SM', upperCase: 'SAN MANUEL COLOHETE' },
      { municipio: 'San Rafael', iso: 'SR', upperCase: 'SAN RAFAEL' },
      { municipio: 'San Sebastián', iso: 'SS', upperCase: 'SAN SEBASTIAN' },
      { municipio: 'Santa Cruz', iso: 'SC', upperCase: 'SANTA CRUZ' },
      { municipio: 'Talgua', iso: 'TA', upperCase: 'TALGUA' },
      { municipio: 'Tambla', iso: 'TA', upperCase: 'TAMBLA' },
      { municipio: 'Tomalá', iso: 'TO', upperCase: 'TOMALA' },
      { municipio: 'Valladolid', iso: 'VA', upperCase: 'VALLADOLID' },
      { municipio: 'Virginia', iso: 'VI', upperCase: 'VIRGINIA' },
      { municipio: 'San Marcos de Caiquín', iso: 'SM', upperCase: 'SAN MARCOS DE CAIQUIN' },
    ]
  },
  {
    departamento: 'Ocotepeque', iso: 'OC', upperCase: 'OCOTEPEQUE'
    , municipios: [
      { municipio: 'Nueva Ocotepeque', iso: 'NO', upperCase: 'NUEVA OCOTEPEQUE' },
      { municipio: 'Belén Gualcho', iso: 'BG', upperCase: 'BELEN GUALCHO' },
      { municipio: 'Concepción', iso: 'CO', upperCase: 'CONCEPCION' },
      { municipio: 'Dolores Merendón', iso: 'DM', upperCase: 'DOLORES MERENDON' },
      { municipio: 'La Encarnación', iso: 'LE', upperCase: 'LA ENCARNACION' },
      { municipio: 'La Labor', iso: 'LL', upperCase: 'LA LABOR' },
      { municipio: 'Lucerna', iso: 'LU', upperCase: 'LUCERNA' },
      { municipio: 'Mercedes', iso: 'ME', upperCase: 'MERCEDES' },
      { municipio: 'San Fernando', iso: 'SF', upperCase: 'SAN FERNANDO' },
      { municipio: 'San Francisco del Valle', iso: 'SF', upperCase: 'SAN FRANCISCO DEL VALLE' },
      { municipio: 'San Jorge', iso: 'SJ', upperCase: 'SAN JORGE' },
      { municipio: 'San Marcos', iso: 'SM', upperCase: 'SAN MARCOS' },
      { municipio: 'Santa Fe', iso: 'SF', upperCase: 'SANTA FE' },
      { municipio: 'Sensenti', iso: 'SE', upperCase: 'SENSENTI' },
      { municipio: 'Sinuapa', iso: 'SI', upperCase: 'SINUAPA' },
    ]
  },
  {
    departamento: 'Olancho', iso: 'OL', upperCase: 'OLANCHO'
    , municipios: [
      { municipio: 'Juticalpa', iso: 'JU', upperCase: 'JUTICALPA' },
      { municipio: 'Campamento', iso: 'CA', upperCase: 'CAMPAMENTO' },
      { municipio: 'Catacamas', iso: 'CA', upperCase: 'CATACAMAS' },
      { municipio: 'Concordia', iso: 'CO', upperCase: 'CONCORDIA' },
      { municipio: 'Dulce Nombre de Culmí', iso: 'DN', upperCase: 'DULCE NOMBRE DE CULMI' },
      { municipio: 'El Rosario', iso: 'ER', upperCase: 'EL ROSARIO' },
      { municipio: 'Esquipulas del Norte', iso: 'EN', upperCase: 'ESQUIPULAS DEL NORTE' },
      { municipio: 'Gualaco', iso: 'GU', upperCase: 'GUALACO' },
      { municipio: 'Guarizama', iso: 'GU', upperCase: 'GUARIZAMA' },
      { municipio: 'Guata', iso: 'GU', upperCase: 'GUATA' },
      { municipio: 'Guayape', iso: 'GU', upperCase: 'GUAYAPE' },
      { municipio: 'Jano', iso: 'JA', upperCase: 'JANO' },
      { municipio: 'La Unión', iso: 'LU', upperCase: 'LA UNION' },
      { municipio: 'Mangulile', iso: 'MA', upperCase: 'MANGULILE' },
      { municipio: 'Manto', iso: 'MA', upperCase: 'MANTO' },
      { municipio: 'Salamá', iso: 'SA', upperCase: 'SALAMA' },
      { municipio: 'San Esteban', iso: 'SE', upperCase: 'SAN ESTEBAN' },
      { municipio: 'San Francisco de Becerra', iso: 'SF', upperCase: 'SAN FRANCISCO DE BECERRA' },
      { municipio: 'San Francisco de La Paz', iso: 'SF', upperCase: 'SAN FRANCISCO DE LA PAZ' },
      { municipio: 'Santa María del Real', iso: 'SM', upperCase: 'SANTA MARIA DEL REAL' },
      { municipio: 'Silca', iso: 'SI', upperCase: 'SILCA' },
      { municipio: 'Yocón', iso: 'YO', upperCase: 'YOCON' },
    ]
  },
  {
    departamento: 'Santa Bárbara', iso: 'SB', upperCase: 'SANTA BARBARA'
    , municipios: [
      { municipio: 'Santa Bárbara', iso: 'SB', upperCase: 'SANTA BARBARA' },
      { municipio: 'Arada', iso: 'AR', upperCase: 'ARADA' },
      { municipio: 'Atima', iso: 'AT', upperCase: 'ATIMA' },
      { municipio: 'Azacualpa', iso: 'AZ', upperCase: 'AZACUALPA' },
      { municipio: 'Ceguaca', iso: 'CE', upperCase: 'CEGUACA' },
      { municipio: 'Concepción del Norte', iso: 'CO', upperCase: 'CONCEPCION DEL NORTE' },
      { municipio: 'Concepción del Sur', iso: 'CO', upperCase: 'CONCEPCION DEL SUR' },
      { municipio: 'Chinda', iso: 'CH', upperCase: 'CHINDA' },
      { municipio: 'El Níspero', iso: 'EN', upperCase: 'EL NISPERO' },
      { municipio: 'Gualala', iso: 'GU', upperCase: 'GUALALA' },
      { municipio: 'Ilama', iso: 'IL', upperCase: 'ILAMA' },
      { municipio: 'Las Vegas', iso: 'LV', upperCase: 'LAS VEGAS' },
      { municipio: 'Macuelizo', iso: 'MA', upperCase: 'MACUELIZO' },
      { municipio: 'Naranjito', iso: 'NA', upperCase: 'NARANJITO' },
      { municipio: 'Nuevo Celilac', iso: 'NC', upperCase: 'NUEVO CELILAC' },
      { municipio: 'Petoa', iso: 'PE', upperCase: 'PETOA' },
      { municipio: 'Protección', iso: 'PR', upperCase: 'PROTECCION' },
      { municipio: 'Quimistán', iso: 'QU', upperCase: 'QUIMISTAN' },
      { municipio: 'San Francisco de Ojuera', iso: 'SF', upperCase: 'SAN FRANCISCO DE OJUERA' },
      { municipio: 'San José de Colinas', iso: 'SJ', upperCase: 'SAN JOSE DE COLINAS' },
      { municipio: 'San Luis', iso: 'SL', upperCase: 'SAN LUIS' },
      { municipio: 'San Marcos', iso: 'SM', upperCase: 'SAN MARCOS' },
      { municipio: 'San Nicolás', iso: 'SN', upperCase: 'SAN NICOLAS' },
      { municipio: 'San Pedro Zacapa', iso: 'SP', upperCase: 'SAN PEDRO ZACAPA' },
      { municipio: 'Santa Rita', iso: 'SR', upperCase: 'SANTA RITA' },
      { municipio: 'San Vicente Centenario', iso: 'SV', upperCase: 'SAN VICENTE CENTENARIO' },
      { municipio: 'Trinidad', iso: 'TR', upperCase: 'TRINIDAD' },
    ]
  },
  {
    departamento: 'Valle', iso: 'VA', upperCase: 'VALLE'
    , municipios: [
      { municipio: 'Nacaome', iso: 'NA', upperCase: 'NACAOME' },
      { municipio: 'Alianza', iso: 'AL', upperCase: 'ALIANZA' },
      { municipio: 'Amapala', iso: 'AM', upperCase: 'AMAPALA' },
      { municipio: 'Aramecina', iso: 'AR', upperCase: 'ARAMECINA' },
      { municipio: 'Caridad', iso: 'CA', upperCase: 'CARIDAD' },
      { municipio: 'Goascorán', iso: 'GO', upperCase: 'GOASCORAN' },
      { municipio: 'Langue', iso: 'LA', upperCase: 'LANGUE' },
      { municipio: 'San Francisco de Coray', iso: 'SF', upperCase: 'SAN FRANCISCO DE CORAY' },
      { municipio: 'San Lorenzo', iso: 'SL', upperCase: 'SAN LORENZO' },
    ]
  },
  {
    departamento: 'Yoro', iso: 'YO', upperCase: 'YORO'
    , municipios: [
      { municipio: 'Yoro', iso: 'YO', upperCase: 'YORO' },
      { municipio: 'Arenal', iso: 'AR', upperCase: 'ARENAL' },
      { municipio: 'El Negrito', iso: 'EN', upperCase: 'EL NEGRITO' },
      { municipio: 'El Progreso', iso: 'EP', upperCase: 'EL PROGRESO' },
      { municipio: 'Jocón', iso: 'JO', upperCase: 'JOCON' },
      { municipio: 'Morazán', iso: 'MO', upperCase: 'MORAZAN' },
      { municipio: 'Olanchito', iso: 'OL', upperCase: 'OLANCHITO' },
      { municipio: 'Santa Rita', iso: 'SR', upperCase: 'SANTA RITA' },
      { municipio: 'Sulaco', iso: 'SU', upperCase: 'SULACO' },
      { municipio: 'Victoria', iso: 'VI', upperCase: 'VICTORIA' },
      { municipio: 'Yorito', iso: 'YO', upperCase: 'YORITO' },
    ]
  },
]


export default function PayWithLinkHn() {
  const classes = useStyles();
  const params = useParams<{ payCode: string }>()
  const history = useHistory();
  const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage, handleDeviceDataCollectionUrl } = useContext(GeneralContext)

  const [values, setValues] = useState<valuesInterface3dsTransaction>({
    numeroTarjeta: '',
    nombreTitular: '',
    expiracion: '',
    expiracionFormatoVisa: '',
    cvv: '',
    nit: '',
    email: '',
    numeroDeVisaCuotas: '',
    nombre: '',
    apellido: '',
    concepto: '',
    monto: 0,
    marcaTarjeta: '',
    montoString: '0',
    compania: '',
    direccionUno: '',
    direccionDos: '',
    localidad: '',
    areaAdministrativa: '',
    codigoPostal: '',
    pais: 'GT',
    numeroTelefonico: '',
  });

  // const [values, setValues] = useState<valuesInterface3dsTransaction>({
  //   numeroTarjeta: '4012 0020 0001 9400', //promerica
  //   nombreTitular: 'ALEJANDRO SANTIZO',
  //   expiracion: '11/27',
  //   expiracionFormatoVisa: '',
  //   nombre: 'Jose',
  //   cvv: '079',
  //   nit: '123456',
  //   email: 'josesantizo148@gmail.com',
  //   numeroDeVisaCuotas: '',
  //   apellido: 'cotto',

  //   // Datos de link rapido
  //   concepto: 'test',
  //   monto: 0,
  //   marcaTarjeta: "",
  //   montoString: '0',

  //   compania: '',
  //   direccionUno: '7ma calle 11-52 zona 11 col roosevelt',
  //   direccionDos: '',
  //   localidad: '',
  //   areaAdministrativa: '',
  //   codigoPostal: '01010',
  //   pais: 'GT',
  //   numeroTelefonico: '50311501',
  // });

  const [width, setWidth] = useState(window.innerWidth);

  const [monto, setMonto] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [pagoRapido, setEsPagoRapido] = React.useState(false);
  const [nombreEmpresa, setNombreEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [cantidadCuotas, setCantidadCuotas] = React.useState('');

  const [enlacePagado, setEnlacePagado] = React.useState(false);
  const [enlaceCaducado, setEnlaceCaducado] = React.useState(false);
  const [enlaceAnulado, setEnlaceAnulado] = React.useState(false);
  const [enlaceCancelado, setEnlaceCancelado] = React.useState(false);

  const [typeCard, setTypeCard] = React.useState<string | undefined>('');
  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const [mostrarIFrame, setMostrarIFrame] = useState<boolean>(false);
  const [AccessToken, setAccessToken] = useState<string>('');
  const [DeviceDataCollectionUrl, setDeviceDataCollectionUrl] = useState<string>('');
  const [municipiosActuales, setMunicipiosActuales] = useState<{ municipio: string, iso: string, upperCase: string }[]>([]);
  const [departamentoActual, setDepartamentoActual] = useState<string>('');
  const [mostrarIframe, setMostrarIframe] = useState<boolean>(false);
  const [hashPagoEnlace, setHashPagoEnlace] = useState<string>('');
  const [negocioId, setNegocioId] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {

    async function getLinkInfo() {

      let temp = values.numeroTarjeta.replace(/\s+/g, '');

      let tarjeta = ValidationFields.instance.creditCardType(temp)
      setTypeCard(tarjeta)

      handleLoading(true)
      if (params.payCode !== undefined) {
        let result = await PayWithLinkService.instance.getEnlaceInfo(params.payCode) as any
        if (!result.error) {
          if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.pagado) {
            setEnlacePagado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.anulado) {
            setEnlaceAnulado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.caducado) {
            setEnlaceCaducado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.cancelado) {
            setEnlaceCancelado(true)
            handleLoading(false)
            return
          }

          setMonto(result.result.monto)
          setDescripcion(result.result.concepto)
          setEsPagoRapido(result.result.rapido)
          setNombreEmpresa(result.result.negocio.nombre)
          setEmail(result.result.usuario.email)
          setTelefono(result.result.negocio.telefono)
          setDireccion(result.result.negocio.numeroCasa)
          setNegocioId(result.result.NegocioId)
          setValues({ ...values, email: result.result.email, numeroDeVisaCuotas: result.result.numeroDeVisaCuotas });

          setValues({
            numeroTarjeta: values.numeroTarjeta,
            nombreTitular: values.nombreTitular,
            expiracion: values.expiracion,
            nombre: values.nombre,
            cvv: values.cvv,
            nit: values.nit,
            email: values.email,
            numeroDeVisaCuotas: result.result.numeroDeVisaCuotas ?? '',
            apellido: values.apellido,

            // Datos de link rapido
            concepto: values.concepto,
            monto: values.monto,
            marcaTarjeta: values.marcaTarjeta,
            montoString: values.montoString,
            expiracionFormatoVisa: '',
            compania: '',
            direccionUno: values.direccionUno,
            direccionDos: values.direccionDos,
            localidad: values.localidad,
            areaAdministrativa: values.areaAdministrativa,
            codigoPostal: values.codigoPostal,
            pais: values.pais,
            numeroTelefonico: values.numeroTelefonico,
          })

          switch (result.result.numeroDeVisaCuotas) {
            case 'VC02':
              setCantidadCuotas('2 cuotas');
              break;
            case 'VC03':
              setCantidadCuotas('3 cuotas');
              break;
            case 'VC06':
              setCantidadCuotas('6 cuotas');
              break;
            case 'VC10':
              setCantidadCuotas('10 cuotas');
              break;
            case 'VC12':
              setCantidadCuotas('12 cuotas');
              break;
            case 'VC15':
              setCantidadCuotas('15 cuotas');
              break;
            case 'VC18':
              setCantidadCuotas('18 cuotas');
              break;
            case 'VC24':
              setCantidadCuotas('24 cuotas');
              break;
            case 'VC36':
              setCantidadCuotas('36 cuotas');
              break;
            case 'VC48':
              setCantidadCuotas('48 cuotas');
              break;
            default:
              setCantidadCuotas('Contado');
              break;
          }

          let fechaGuatemala = momentZone.tz(moment(), 'America/Guatemala').locale('es').format('LLLL')

          setFecha(fechaGuatemala.toString())
        } else {
          handleErrorMessage(result.message)
          handleActiveError(true)
        }
        handleLoading(false)
      }
    }

    getLinkInfo()

  }, [])

  useEffect(() => {
    const execute = async () => {
      const stepUpForm = document.querySelector('#step_up_form_3ds_paso1') as any;

      if (stepUpForm) {
        await stepUpForm.submit();
        await payLinkPaso3()
        handleLoading(false)
      }

    }
    if (mostrarIFrame) execute()
  }, [mostrarIFrame])


  const validarFechaPago = (fechaIngresada: string): { error: boolean, message: string } => {
    // Verifica si la fecha ingresada tiene el formato MM/YY
    if (!/^\d{2}\/\d{2}$/.test(fechaIngresada)) {
      return { error: true, message: 'El formato de la fecha de expiración de la tarjeta no es válido' }
    }

    // Parsea la fecha ingresada
    const partesFecha = fechaIngresada.split('/');
    const mes = parseInt(partesFecha[0], 10);
    const anio = parseInt(partesFecha[1], 10);

    // Verifica si el mes es válido (de 1 a 12)
    if (mes < 1 || mes > 12) {
      return { error: true, message: 'El mes de la fecha de expiración de la tarjeta no es válido' };
    }

    // Obtiene la fecha actual
    const fechaActual = moment();

    // Define la fecha límite como el último día del mes ingresado
    const fechaLimite = moment().year(anio + 2000).month(mes - 1).endOf('month');

    // Compara la fecha ingresada con la fecha 
    if (fechaActual.isBefore(fechaLimite)) {
      return { error: false, message: 'La fecha de expiración de la tarjeta es válida' }
      // La fecha ingresada es válida
    }

    return { error: true, message: 'La fecha de expiración de la tarjeta no es válida' }; // La fecha ingresada no es válida
  }

  const validarTarjetaCredito = (number: string): boolean => {
    number = number.replace(/\s+/g, '');  // Elimina los espacios en blanco.
    // Convierte la cadena de caracteres en un array de dígitos.
    const digits = number.split('').map((char) => parseInt(char));

    let sum = 0;
    for (let i = digits.length - 1; i >= 0; i--) {
      let digit = digits[i];

      // Si el índice es impar (cuenta desde 0), duplica el dígito.
      if ((digits.length - 1 - i) % 2 === 1) {
        digit *= 2;
        // Si el doble es mayor que 9, resta 9.
        if (digit > 9) {
          digit -= 9;
        }
      }

      // Suma el dígito al acumulador.
      sum += digit;
    }

    // Si la suma total es un múltiplo de 10, el número es válido.
    return sum % 10 === 0;
  };

  const validateEmail = (email: string): boolean => {
    // Expresión regular para validar direcciones de correo electrónico.
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const payLinkPaso1 = async () => {
    let tempValues = { ...values }

    if (values.nombre.trim() == '') {
      handleErrorMessage('Ingrese el nombre del nombre para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.apellido.trim() == '') {
      handleErrorMessage('Ingrese el apellido del nombre para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.direccionUno.trim() == '') {
      handleErrorMessage('Ingrese la dirección uno para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.localidad == '') {
      handleErrorMessage('Ingrese la localidad para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.nit.trim() == '') {
      handleErrorMessage('Ingrese el DNI o RTN para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.codigoPostal.trim() == '') {
      handleErrorMessage('Ingrese un código postal para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.codigoPostal.trim().length < 5) {
      handleErrorMessage('Ingrese un código postal valido para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.numeroTelefonico.trim() == '') {
      handleErrorMessage('Ingrese un número telefónico para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.numeroTelefonico.trim().length < 8) {
      handleErrorMessage('Ingrese un número telefónico valido para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.email == '') {
      handleErrorMessage('Ingrese el correo electrónico para los datos de facturación')
      handleActiveError(true)
      return
    } else if (validateEmail(values.email) == false) {
      handleErrorMessage('Ingrese un correo electrónico válido')
      handleActiveError(true)
      return
    } else if (values.numeroTarjeta == '') {
      handleErrorMessage('Ingrese el número de la tarjeta')
      handleActiveError(true)
      return
    } else if (values.numeroTarjeta.length < 19) {
      handleErrorMessage('Ingrese el número de la tarjeta completo')
      handleActiveError(true)
      return
    } else if (validarTarjetaCredito(values.numeroTarjeta) == false) {
      handleErrorMessage('Ingrese un número de tarjeta válido')
      handleActiveError(true)
      return
    } else if (values.nombreTitular.trim() == '') {
      handleErrorMessage('Ingrese el nombre del titular de la tarjeta')
      handleActiveError(true)
      return
    } else if (values.expiracion == '') {
      handleErrorMessage('Ingrese la fecha de expiración de la tarjeta')
      handleActiveError(true)
      return
    } else if (validarFechaPago(values.expiracion).error) {
      handleErrorMessage(validarFechaPago(values.expiracion).message)
      handleActiveError(true)
      return
    } else if (values.cvv == '') {
      handleErrorMessage('Ingrese el CVV de la tarjeta')
      handleActiveError(true)
      return
    } else if (typeCard != 'VISA' && typeCard != 'MASTERCARD') {
      handleErrorMessage('Ingrese una tarjeta valida')
      handleActiveError(true)
      return
    }
    if (values.compania.trim() == '') tempValues.compania = 'NA'
    if (values.direccionDos.trim() == '') tempValues.direccionDos = ''

    tempValues.marcaTarjeta = typeCard as string;

    let expiracionSeparado = tempValues.expiracion.split('/')
    let mes = expiracionSeparado[0]
    let anio = expiracionSeparado[1]
    tempValues.expiracionFormatoVisa = anio + mes

    if (monto.toString() != '' && (typeof monto) == 'number') tempValues.montoString = monto.toFixed(2).replace('.', '')
    else tempValues.montoString = monto.toString().replace('.', '')

    tempValues.numeroDeVisaCuotas = tempValues.numeroDeVisaCuotas.trim().replaceAll('VC01', '')
    tempValues.nombre = tempValues.nombre.trim()
    tempValues.nit = tempValues.nit.trim()
    tempValues.email = tempValues.email.trim()
    tempValues.nombreTitular = tempValues.nombreTitular.trim()
    tempValues.nombre = tempValues.nombre.replaceAll('ñ', 'n').replaceAll('Ñ', 'N').trim()
    tempValues.apellido = tempValues.apellido.replaceAll('ñ', 'n').replaceAll('Ñ', 'N').trim()
    tempValues.numeroTelefonico = tempValues.numeroTelefonico.trim().replace(' ', '').replace('-', '');

    handleLoading(true)

    let req = await PayWithLinkService.instance.TransaccionPayfacRestHn(params.payCode, tempValues)

    if (!req.error) {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
      } else {
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        handleActiveSuccess(true)
      }
    } else {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
      } else {
        handleErrorMessage(req.message)
      }
      handleActiveError(true)
    }

    handleLoading(false)
  }

  const payLinkPaso3 = async () => {

    handleLoading(true)

    let req = await PayWithLinkService.instance.Paso3Epayserver3dSecure(hashPagoEnlace)

    if (!req.error) {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
      } else if (req && req.result && req.result.PayerAuthentication && req.result.PayerAuthentication.Step && req.result.PayerAuthentication.Step != '3') {
        handleDeviceDataCollectionUrl(req.result.PayerAuthentication.DeviceDataCollectionUrl)
        setMostrarIFrame(true)

        history.push(`/payWithLinkV2Paso4/${req.result.PayerAuthentication.AccessToken}`);
      } else {
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        handleActiveSuccess(true)
      }
    } else {
      console.log('error aquiii')
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
      } else {
        handleErrorMessage(req.message)
      }
      handleActiveError(true)
    }
    handleLoading(false)
    setMostrarIFrame(false)
  }

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeNumberCard = (prop: any) => (event: any) => {

    let temp = event.target.value

    setValues({ ...values, [prop]: event.target.value });

    temp = temp.replace(/\s+/g, '');

    let tarjeta = ValidationFields.instance.creditCardType(temp)

    setTypeCard(tarjeta)
  };

  const onChangeLocalidad = (upperCaseDepartamento: string) => {
    const departamento = departamentosHonduras.find((item) => item.upperCase === upperCaseDepartamento);
    if (!departamento) return;
    setDepartamentoActual(departamento.upperCase);
    setValues({
      ...values,
      areaAdministrativa: departamento.iso,
    });
    setMunicipiosActuales(departamento.municipios);
  };

  const onChangeMunicipio = (municipio: string) => {
    setValues({
      ...values,
      localidad: municipio,
    });
  };


  const nombre = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Nombre<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name="name"
          autoComplete="name"
          value={values.nombre}
          onChange={handleChange('nombre')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const apellido = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Apellido<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="apellido"
          autoComplete="apellido"
          value={values.apellido}
          onChange={handleChange('apellido')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const direccionUno = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección de la calle<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="direccionUno"
          autoComplete="direccionUno"
          value={values.direccionUno}
          onChange={handleChange('direccionUno')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const direccionDos = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección dos</InputLabel>
        <OutlinedInput
          type='text'
          name="direccionDos"
          autoComplete="direccionDos"
          value={values.direccionDos}
          onChange={handleChange('direccionDos')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const compania = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Compañía</InputLabel>
        <OutlinedInput
          type='text'
          name="compania"
          autoComplete="compania"
          value={values.compania}
          onChange={handleChange('compania')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const localidad = () => {
    return (
      <FormControl fullWidth={true} variant='outlined' style={{
        minWidth: 120,
      }}>
        <InputLabel variant='filled' id="demo-simple-select-outlined-label">Departamento<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={departamentoActual}
          defaultValue={departamentoActual}
          onChange={(event: any) => onChangeLocalidad(event.target.value)}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {
            departamentosHonduras.map((item, index) => {
              return (
                <MenuItem key={index} value={item.upperCase}>{item.upperCase}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  const municipio = () => {
    return (
      <FormControl fullWidth={true} variant='outlined' style={{
        minWidth: 120,
      }}>
        <InputLabel variant='filled' id="demo-simple-select-outlined-label">Municipio<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.localidad}
          defaultValue={values.localidad}
          onChange={(event: any) => onChangeMunicipio(event.target.value)}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {
            municipiosActuales.map((item, index) => {
              return (
                <MenuItem key={index} value={item.upperCase}>{item.upperCase}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  const codigoPostal = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Código Postal<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="codigoPostal"
          autoComplete="codigoPostal"
          value={values.codigoPostal}
          onChange={handleChange('codigoPostal')}
          className={classes.inputPadding}
          inputComponent={CodigoPostalMask}
        />
      </FormControl>
    )
  }

  const numeroTelefonico = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Número Telefónico<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='tel'
          name="numeroTelefonico"
          autoComplete="numeroTelefonico"
          value={values.numeroTelefonico}
          onChange={handleChange('numeroTelefonico')}
          className={classes.inputPadding}
          startAdornment={<InputAdornment position="start">+ 504</InputAdornment>}
          inputComponent={NumeroTelefonicoMask}
        />
      </FormControl>
    )
  }

  const conceptoInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Concepto</InputLabel>
        <OutlinedInput
          type='text'
          value={values.concepto}
          onChange={handleChange('concepto')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const montoInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Monto</InputLabel>
        <OutlinedInput
          type='number'
          value={monto == 0 ? '' : monto}
          onChange={e => {
            let montoInt = parseFloat(e.target.value)
            setMonto(montoInt)
          }}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const emailInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Correo electrónico<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='email'
          name="email"
          autoComplete="email"
          value={values.email}
          onChange={handleChange('email')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const nitInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>DNI o RTN<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          value={values.nit}
          onChange={handleChange('nit')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const expiracionInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Expiración MM/YY<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          autoComplete="cc-exp"
          value={values.expiracion}
          onChange={handleChange('expiracion')}
          className={classes.inputPadding}
          inputComponent={ExpirationMask}
        />
      </FormControl>
    )
  }

  const numeroTarjetaInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Número de tarjeta<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          value={values.numeroTarjeta}
          onChange={handleChangeNumberCard('numeroTarjeta')}
          className={classes.inputPadding}
          inputComponent={CardNumberMask}
          endAdornment={
            typeCard !== undefined && typeCard === 'VISA' ?
              <FaCcVisa size={30} />
              :
              typeCard !== undefined && typeCard === 'MASTERCARD' ?
                <FaCcMastercard size={30} />
                :
                null
          }
        />
      </FormControl>
    )
  }

  const nombreTitularInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Nombre del titular<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          autoComplete="cc-name"
          value={values.nombreTitular}
          onChange={handleChange('nombreTitular')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const cvvInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>CVV<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type="number"
          value={values.cvv}
          autoComplete="cc-csc"
          onChange={handleChange('cvv')}
          className={classes.inputPadding}
          inputComponent={CvvMask}
        />
      </FormControl>
    )
  }

  return (
    <>
      {
        mostrarIFrame &&
        (
          <div >
            <iframe
              id="step_up_iframe_3ds_paso1"
              style={{
                border: 'none',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
              }}
              height="800"
              width="100%"
              name="stepUpIframe"
            />
            <form
              id="step_up_form_3ds_paso1"
              name="stepup"
              method="POST"
              target="stepUpIframe"
              action={DeviceDataCollectionUrl}
            >
              <input
                id="step_up_form_jwt_input_3ds_paso1"
                type="hidden"
                name="JWT"
                value={AccessToken}
              />
            </form>
          </div>
        )
      }

      <div className={classes.root}>
        {enlacePagado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkYaFuePagado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceCaducado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCaducado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceAnulado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkAnulado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceCancelado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCancelado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          showConfirmComponent
            ?
            <ConfirmPayment businessName={nombreEmpresa} />
            :
            <div
              style={{
                paddingTop: '3%',
                paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
                paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              }}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div>
                    <img
                      width={width >= 700 ? width * 0.15 : 200}
                      src={Images.instance.logo}
                      alt=''
                      onClick={() => (window.location.href = '/')}
                    />
                  </div>
                </Grid>
              </Grid>

              <div style={{ paddingTop: '5%' }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      style={{
                        fontWeight: 550,
                        color: 'black',
                        textAlign: 'center',
                      }}
                      variant='h3'
                    >
                      {nombreEmpresa}
                    </Typography>
                    <Typography
                      style={{
                        color: 'black',
                        textAlign: 'center',
                      }}
                      variant='subtitle1'
                    >
                      Te envió un link de paggo {pagoRapido ? 'rápido' : ''}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                      }}
                    >
                      Información de:
                      <Typography
                        variant='subtitle1'
                        style={{
                          fontWeight: 500,
                          color: 'black',
                          display: 'inline',
                        }}
                      >
                        {' ' + nombreEmpresa}
                      </Typography>
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                      }}
                    >
                      Correo electrónico:
                      <Typography
                        variant='subtitle1'
                        style={{
                          fontWeight: 400,
                          color: '#5166AF',
                          display: 'inline',
                        }}
                      >
                        {' ' + email}
                      </Typography>
                    </Typography>

                    {!pagoRapido ? (
                      <>
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: grisFigma,
                          }}
                        >
                          Monto a pagar:
                          <Typography
                            variant='subtitle1'
                            style={{
                              fontWeight: 500,
                              color: 'black',
                              display: 'inline',
                            }}
                          >
                            {' L' + monto}
                          </Typography>
                        </Typography>

                        {cantidadCuotas.length > 0 ? (
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: grisFigma,
                            }}
                          >
                            Pago {`${cantidadCuotas == 'Contado' ? 'de' : 'en'} ${cantidadCuotas}`}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {cantidadCuotas.includes('cuotas') ? (
                          <Typography
                            variant='subtitle1'
                            style={{
                              fontSize: '12px',
                              color: grisFigma,
                            }}
                          >
                            <Typography
                              component='span'
                              color='error'
                            >
                              *
                            </Typography>{' '}
                            Tarjetas emitidas por BAC no son aceptadas por Visa Cuotas
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : null}
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                      }}
                    >
                      Fecha: {fecha}
                    </Typography>
                    {!pagoRapido ? (
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Concepto: {descripcion}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                        fontWeight: 550,
                      }}
                    >
                      Datos de facturación
                    </Typography>
                  </Grid>

                  {pagoRapido ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: '2%',
                        }}
                      >
                        {conceptoInput()}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: '2%',
                        }}
                      >
                        {montoInput()}
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nombre()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {apellido()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionUno()}
                  </Grid>

                  {/* <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionDos()}
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {localidad()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {municipio()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {compania()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nitInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {codigoPostal()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {numeroTelefonico()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {emailInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                        fontWeight: 550,
                      }}
                    >
                      Datos de tarjeta
                      <div style={{ display: 'inline', paddingLeft: '1%' }}>
                        <img
                          width={30}
                          src={Images.instance.iconoMasterCard}
                        />
                        <img
                          width={40}
                          src={Images.instance.iconoVisa}
                        />
                      </div>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {numeroTarjetaInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {nombreTitularInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {expiracionInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {cvvInput()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '7%',
                    }}
                  >
                    <Button
                      fullWidth={true}
                      color='primary'
                      disabled={loading}
                      variant='contained'
                      className={classes.button}
                      onClick={payLinkPaso1}
                    >
                      Pagar
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      style={{
                        color: '#ABB8C8',
                        textAlign: 'center',
                        fontWeight: 600,
                        paddingTop: 15,
                      }}
                      variant='subtitle2'
                    >
                      <div style={{ display: 'inline', paddingRight: '1%' }}>
                        <img
                          width={15}
                          src={Images.instance.candadoEnlace}
                        />
                      </div>
                      Por políticas de seguridad, tú paggo es seguro.
                    </Typography>
                    <Typography
                      style={{
                        color: '#ABB8C8',
                        textAlign: 'center',
                        paddingBottom: 30,
                      }}
                      variant='subtitle2'
                    >
                      Ni el comercio afiliado, ni Paggo recopila la información de tú tarjeta. Solamente tú puedes ver
                      esta información.
                    </Typography>
                    <div
                    style={{
                      textAlign: 'center',
                    }}
                    >
                    <img src={Images.instance.iconoPCI} alt='' />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
        )}

        <PageLoader loading={loading} />

      </div>
    </>
  );
}
