import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class RoleServices {
    static instance = new RoleServices();

    get = async () => {

        try {
        
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole + '/all'

            const httpInstance = new http();

            let req = await httpInstance.get(ruta, true)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }


    getAll = async (params: any = "") => {
        try {
          const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole + '/paginacion' + params;
    
          const httpInstance = new http();
    
          let req = await httpInstance.get(ruta, true);
    
          if (req.error) throw new Error(req.message);
    
          return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
        } catch (error: any) {
          console.log('get Roles service error', error);
          return { error: true, message: error.message, result: null };
        }
      };
    
      
    
      getOne = async (id: number) => {
        try {
          const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole + `/${id}`;
    
          const httpInstance = new http();
    
          let req = await httpInstance.get(ruta, true);
    
          if (req.error) throw new Error(req.message);
    
          return { error: req.error, message: '', result: req.result };
        } catch (error: any) {
          console.log('get Rol service error', error);
          return { error: true, message: error.message, result: null };
        }
      };
    
      deleteOne = async (id: number) => {
        try {
          const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole + `/${id}`;
    
          const httpInstance = new http();
    
          let req = await httpInstance.delete(ruta, {}, true);
    
          if (req.error) throw new Error(req.message);
    
          return { error: req.error, message: '', result: req.result };
        } catch (error: any) {
          console.log('delete Rol service error', error);
          return { error: true, message: error.message, result: null };
        }
      };
    
      update = async (id: number, params: any) => {
        try {
          const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole + '/' + id;
    
          const httpInstance = new http();
    
          let req = await httpInstance.put(ruta, params, true);
    
          if (req.error) throw new Error(req.message);
    
          return { error: req.error, message: req.message, result: req.result };
        } catch (error: any) {
          console.log('update Rol service error', error);
          return { error: true, message: error.message, result: null };
        }
      };
    
      create = async (params: any) => {
        try {
          const ruta = Rutas.instance.urlBase + Rutas.instance.urlRole;
    
          const httpInstance = new http();
    
          let req = await httpInstance.post(ruta, params, true);
    
          console.log(req);
    
          if (req.error) throw new Error(req.message);
    
          return { error: req.error, message: req.message, result: req.result };
        } catch (error: any) {
          console.log('Create Rol service error', error);
          return { error: true, message: error.message, result: null };
        }
      };
}