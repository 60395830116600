import { AppBar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { GeneralContext } from '../../context/GeneralContext';
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarExport } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import momentZone from 'moment-timezone';
import { Rutas } from '../../resources/Statics';
import { get } from 'http';
import { Edit, VisibilityTwoTone } from '@material-ui/icons';
import { on } from 'events';

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const CustomToolbar = () => {
  return (
    <div>
      <GridToolbar />
    </div>
  );
};

const MovimientosInventarioMposUbicacion = () => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord
  } = useContext(GeneralContext);

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [openCreacionModificacion, setOpenCreacionModificacion] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueDialogPos, setSearchValueDialogPos] = useState('');
  const [rows, setRows] = useState<{id: number}[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [accion, setAccion] = useState<string>('');
  const [rowsPos, setRowsPos] = useState<{id: number}[]>([]);
  const [pagePos, setPagePos] = useState<number>(1);
  const [pageSizePos, setPageSizePos] = useState<number>(5);
  const [totalPagesPos, setTotalPagesPos] = useState<number>(1);

  const [rowsEnviados, setRowsEnviados] = useState<{id: number}[]>([]);
  const [pageEnviados, setPageEnviados] = useState<number>(1);
  const [pageSizeEnviados, setPageSizeEnviados] = useState<number>(10);
  const [totalPagesEnviados, setTotalPagesEnviados] = useState<number>(1);
  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string}[]>([]);
  const [responsables, setResponsables] = useState<{id: number, nombre: string}[]>([]);
  const [selectionModel, setSelectionModel] = useState([]);

  interface PosSeleccionados {
    id: number;
  }

  interface Movimiento {
    id: number;
    UbicacionDestinoId: number;
    ResponsableUsuarioId: number;
    pos: number[];
  }

  const [values, setValues] = useState<Movimiento>(
    { id: 0, 
      UbicacionDestinoId: 0, 
      ResponsableUsuarioId: 0, 
      pos: []
    }
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (prop: any) => (event: any) => {
    if (event.target.value)
      setValues({ ...values, [prop]: event.target.value });
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

  const handleChangeEstado = async (event: any, row: any, field: any) => {
    const valor = event.target.value; // Valores seleccionados del Select
    let registro = {[field]: valor}
    registro[field] = valor
    try {
      const crudService = CrudService.instance(Rutas.instance.urlMovimiento);
      const response = await crudService.updateOne(row.id, registro);
      const { error, result } = response;
      if (!error) {
          handleActiveSuccess(true);
          handleSuccessMessage('Se ha aprobado correctamente el movimiento de ubicación');
      } else {
          handleErrorMessage('message');
          handleActiveError(true);
      }
      value === 0 ? getRows() : getRowsEnviados();
      //setRefresh(false);
      
    } catch (e) {
      console.error('Error al actualizar la base de datos:', e);
    }
  };

  const procesarMovimientosRecibidos = () => {
    if (page === 1) getRows();
    else setPage(1);
  }

  useEffect(() => {
    getRows();
    getUbicaciones();
  }, [page, pageSize]);

  useEffect(() => {
    if (values.UbicacionDestinoId)
      getResponsables();
  }, [values.UbicacionDestinoId]);

  // useEffect(() => {
  //   getPos();
  // }, []);

  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(value === 0 ? procesarMovimientosRecibidos : procesarMovimientosEnviados, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  const procesarMovimientosEnviados = () => {
    if (pageEnviados === 1) getRowsEnviados();
    else setPageEnviados(1);
  }

  useEffect(() => {
    getRowsEnviados();
  }, [pageEnviados, pageSizeEnviados]);

  useEffect(() => {
    if (value === 0) {
      getRows();
    }else {
      getRowsEnviados();
    }
  }, [value]);

  useEffect(() => {
    if (values.id !== 0) {
      getPos();
      setOpenCreacionModificacion(true);
    }
  }, [values.id]);

  

  const columns = [
    {
      field: '',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={async () => {
                setAccion('view')
                editRecord(params.row.id);
              }}
            >
              <VisibilityTwoTone />
            </IconButton>
          </div>
        );
      },
    },
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
    },
    {
      field: 'estado',
      headerName: 'Estado Aprobación',
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select disabled={value === 1 ? true : (params.row.estado === 'A' ? true : false)} value={params.row.estado} onChange={(event) => handleChangeEstado(event, params.row, 'estado')}>
                <MenuItem value={'P'}>
                  <Chip label={'Pendiente'} style={{ background: '#E73719', color: 'white' }}/>
                </MenuItem>
                <MenuItem value={'A'}>
                  <Chip label={'Aceptado'} style={{ background: '#FE8300', color: 'white' }}/>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
        field: 'ubicacion',
        headerName: 'Mover a ubicación:',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return <span>{params.row?.ubicacion?.descripcion}</span>;
        }
    },
    {
      field: 'responsable',
      headerName: 'Usuario solicitante',
      sortable: false,
      width: 270,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {

        const user = params.row?.usuario
        const nombre = `${user.nombre} ${user.apellidoPaterno || ''} ${user.apellidoMaterno || '' }  ${(user.email || user.telefono)}`


        return <span>{nombre}</span>;
      }
    },
    {
      field: 'cantidadPos',
      headerName: 'Cantidad de POS',
      sortable: false,
      width: 220,
      disableClickEventBubbling: true,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

  ];

  const columnsEnviados = [
    {
      field: '',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
          {(params.row.estado === 'A' ? null : 
            <IconButton
              size='small'
              onClick={async () => {
                setAccion('')
                editRecord(params.row.id);
              }}
            >
              <Edit />
            </IconButton>
          )} 
            
          </div>
        );
      },
    },
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
    },
    {
      field: 'estado',
      headerName: 'Estado Aprobación',
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select disabled={value === 1 ? true : (params.row.estado === 'A' ? true : false)} value={params.row.estado} onChange={(event) => handleChangeEstado(event, params.row, 'estado')}>
                <MenuItem value={'P'}>
                  <Chip label={'Pendiente'} style={{ background: '#E73719', color: 'white' }}/>
                </MenuItem>
                <MenuItem value={'A'}>
                  <Chip label={'Aceptado'} style={{ background: '#FE8300', color: 'white' }}/>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
        field: 'ubicacion',
        headerName: 'Ubicación',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          return <span>{params.row?.ubicacion?.descripcion}</span>;
        }
    },
    {
      field: 'usuario',
      headerName: 'Usuario destino',
      sortable: false,
      width: 270,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {

        const user = params.row?.responsable
        const nombre = `${user.nombre} ${user.apellidoPaterno || ''} ${user.apellidoMaterno || '' }  ${(user.email || user.telefono)}`


        return <span>{nombre}</span>;
      }
    },
    {
      field: 'cantidadPos',
      headerName: 'Cantidad de POS',
      sortable: false,
      width: 220,
      disableClickEventBubbling: true,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

  ];

  const columnsPos = [
  
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 100,
    },
    {
        field: 'lote',
        headerName: 'Lote',
        sortable: false,
        width: 130,
    },
    {
      field: 'numeroDeSerie',
      headerName: 'Número de serie',
      sortable: false,
      width: 300,
    },
    {
      field: 'terminalEpayRetail',
      headerName: 'Terminal',
      sortable: false,
      width: 160,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 350,
    },
  ];

  const handleAddRow = () => {
    getPos();
    setOpenCreacionModificacion(true);

    
  };

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const getRows = () => {
    setLoading(true);
    const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento +'/recibidos');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRows(result);
          setTotalPages(totalPages);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getUbicaciones = () => {
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getPos = () => {
    setLoading(true);
    const params = `?page=${pagePos}&pageSize=${pageSizePos}&movimientoId=${values.id}&accion=${accion}`;
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento +'/responsable/ubicacion/pos');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRowsPos(result.pos);
          setSelectionModel(result.seleccionados)
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const editRecord = (id: number) => {
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento+'/obtener');
    crudService
      .getOne(id)
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setValues(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getRowsEnviados = () => {
    setLoading(true);
    const params = `?page=${pageEnviados}&pageSize=${pageSizeEnviados}&valor=${searchValue}`;
    const crudService = CrudService.instance(Rutas.instance.urlMovimiento + '/enviados');
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRowsEnviados(result);
          setTotalPagesEnviados(totalPages);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      value == 0 ? getRows() : getRowsEnviados();
    }
  }

  const confirmSave = () => {
    if (!values.ResponsableUsuarioId || !values.UbicacionDestinoId || values.pos.length === 0) {
      handleErrorMessage('Por favor ingrese todos los campos obligatorios');
      handleActiveError(true);
      return;
    }
    setLoading(true);
    const crudService = CrudService.instance('/movimiento');
    crudService
    .create(values)
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        value === 0 ? getRows() : getRowsEnviados();   
        setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [] });
        setOpenCreacionModificacion(false);
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  const confirmUpdate = () => {
    if (!values.ResponsableUsuarioId || !values.UbicacionDestinoId || values.pos.length === 0) {
      handleErrorMessage('Por favor ingrese todos los campos obligatorios');
      handleActiveError(true);
      return;
    }
    

    setLoading(true);
    const crudService = CrudService.instance('/movimiento');
    crudService
    .updateOne(values.id, values)
    .then(async response => {
      const { error, result, message } = response;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
        value === 0 ? getRows() : getRowsEnviados();   
        setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [] });
        setOpenCreacionModificacion(false);
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      handleErrorMessage('message');
      handleActiveError(true);
    });
  };

  const handleSelectionModelChange = (selectionModel: any) => {
    setValues({ ...values, pos: selectionModel });
    setSelectionModel(selectionModel);
  };

  const getResponsables = () => {
    const params =`?UbicacionId=${values.UbicacionDestinoId}`
    const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/ubicaciones/responsables');
    crudService
      .getAll(params)
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setResponsables(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };
    
  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>
            Movimientos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box
            justifyContent={['flex-start', 'flex-start', 'flex-end']}
            sx={{ flexDirection: 'row', display: 'flex' }}
          >
            <Grid item>
              <Button
                onClick={handleAddRow}
                fullWidth
                style={{ width: '100px',  margin: 6 }}
                variant='contained'
                color='primary'
              >
                CREAR
              </Button>
              <TextField
                label='Buscar'
                placeholder='Buscar'
                variant='outlined'
                size='small'
                style={{ margin: 5}}
                name='search'
                value={searchValue}
                onKeyDown={handleKeyDownChange}
                onChange={handleSearchChange}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Recibidos" {...a11yProps(0)} />
              <Tab label="Enviados" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
            <TabPanel value={value} index={0}>
              <Grid container >
                <Grid
                  item
                  xs={12}
                >
                  <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    rowHeight={50}
                    paginationMode='server'
                    pageSize={pageSize}
                    rowCount={totalPages * pageSize}
                    hideFooterPagination
                    hideFooter
                    //onCellEditCommit={handleCellEditCommit}
                    autoHeight
                    rowsPerPageOptions={[10, 25, 50, 100]} />
                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        shape='rounded'
                        size='medium'
                        variant='outlined'
                        color='primary'
                        style={{ padding: '8px', textAlign: 'center' }}
                        onChange={(event, value) => setPage(value)}
                      />
                    </div>
                  
                </Grid>
              </Grid> 
            </TabPanel>
            <TabPanel value={value} index={1} >
              <Grid container >
                <Grid
                  item
                  xs={12}
                >
                  <DataGrid
                    loading={loading}
                    components={{ Toolbar: GridToolbar }} 
                    rows={rowsEnviados}
                    columns={columnsEnviados}
                    rowHeight={50}
                    paginationMode='server'
                    pageSize={pageSizeEnviados}
                    rowCount={totalPagesEnviados * pageSizeEnviados}
                    hideFooterPagination
                    hideFooter
                    //onCellEditCommit={handleCellEditCommit}
                    autoHeight
                    rowsPerPageOptions={[10, 25, 50, 100]} />
                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                      <Pagination
                        count={totalPagesEnviados}
                        page={pageEnviados}
                        shape='rounded'
                        size='medium'
                        variant='outlined'
                        color='primary'
                        style={{ padding: '8px', textAlign: 'center' }}
                        onChange={(event, value) => setPageEnviados(value)}
                      />
                    </div>
                </Grid>
              </Grid> 
            </TabPanel>
            
        </Grid>
      </Grid>

      {openCreacionModificacion &&
        <Dialog
          open={openCreacionModificacion}
          fullWidth
          maxWidth='lg'
          onClose={() => {
            setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [] });
            setOpenCreacionModificacion(false)
            setAccion('')
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {accion === 'view' ? 'Solicitud de movimiento' : 
              (values.id === 0 ? 'Crear movimiento' : `Modificar movimiento ID: ${values.id}`) 
            }
          </DialogTitle>
          
          <DialogContent>
            {accion === 'view' ? null :
              <DialogContentText id="alert-dialog-description">
                <Grid container>
                  <Grid item xs={12} md={6} style={{paddingTop: '1%'}}>
                  Seleccione los POS a mover
                  </Grid>
                  <Grid item xs={12} md={6} style={{paddingTop: '1%', textAlign:'right'}}>
                    <TextField
                      label='Buscar'
                      placeholder='Número de serie'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='search'
                      value={searchValueDialogPos}
                      
                      onChange={(event) => setSearchValueDialogPos(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            }
            <Grid container >
              <Grid
                item
                xs={12}
                style={{ height: 320, width: 'auto' }}
              >
                <DataGrid
                  filterModel={{
                    items: [{ columnField: 'numeroDeSerie', operatorValue: 'contains', value: searchValueDialogPos }],
                  }}
                  loading={loading}
                  rows={rowsPos}
                  columns={columnsPos}
                  rowHeight={33}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  checkboxSelection={accion === 'view' ? false : true}
                  disableSelectionOnClick={accion === 'view' ? true : false}
                  onSelectionModelChange={handleSelectionModelChange}
                  headerHeight={33}
                  hideFooterRowCount
                  hideFooterSelectedRowCount
                  selectionModel={selectionModel}
                />
                
            </Grid>
            <Grid
                item
                xs={12}
                style={{ paddingTop: 20}}
              >
                <Grid item xs={12} style={{paddingTop: '1%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Ubicación destino</InputLabel>
                    <Select disabled={values.id > 0 ? true : false} value={values.UbicacionDestinoId} onChange={handleChange('UbicacionDestinoId')}>
                      {ubicaciones.map((ubicacion) => (
                        <MenuItem key={ubicacion.id} value={ubicacion.id}>
                          {ubicacion?.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '1%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Responsable</InputLabel>
                    <Select disabled={accion === 'view' ? true : false} value={values.ResponsableUsuarioId} onChange={handleChange('ResponsableUsuarioId')}>
                      {responsables.map((responsable) => (
                        <MenuItem key={responsable.id} value={responsable.id}>
                          {responsable.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid> 
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setValues({ id: 0, UbicacionDestinoId: 0, ResponsableUsuarioId: 0, pos: [] });
              setOpenCreacionModificacion(false)
              }} color="primary">
              Cancelar
            </Button>
            {accion === 'view' ? null : 
              <Button onClick={ values.id === 0 ? confirmSave : confirmUpdate } color="primary" autoFocus>
                {values.id === 0 ? 'Crear' : 'Modificar'}
              </Button>
            }
          </DialogActions>
        </Dialog>
      }


    </Box>  
  )
}

export default MovimientosInventarioMposUbicacion
