import http from '../libs/http'
import { Rutas } from '../resources/Statics'
import localStorage from '../libs/localStorage'

export default class LoginService {
    static instance = new LoginService();

    userActual = 0;
    negocioActual = 0;

    login = async (email: any, password: any, tokenCaptcha: any = '') => {

        try {

            let data = {
                email: email,
                password: password,
                tokenCaptcha: tokenCaptcha
            }

            const httpInstance = new http();

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/login'

            let req = await httpInstance.post(ruta, data)

            if (req.error) throw new Error(req.message)

            let token = req.token
            let user = req.user
            let procesoregistro = req.procesoregistro
            let negocio = req.negocio
            let informacionbancaria = req.informacionbancaria
            let rol = req.rol

            localStorage.instance.setSesion(token, user, procesoregistro, negocio, informacionbancaria, rol)

            return { error: req.error, message: '', result: req }
        } catch (error: any) {
            console.log('Login service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    refreshLogin = async (token: any) => {

        try {

            let data = {
                token: token
            }

            const httpInstance = new http();

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/refreshLogin'

            let req = await httpInstance.post(ruta, data, true)

            if (req.error) throw new Error(req.message)

            let newToken = req.token
            let user = req.user
            let procesoregistro = req.procesoregistro
            let negocio = req.negocio
            let informacionbancaria = req.informacionbancaria
            let rol = req.rol

            localStorage.instance.setSesion(newToken, user, procesoregistro, negocio, informacionbancaria, rol)

            return { error: req.error, message: '', result: req }
        } catch (error: any) {
            console.log('Login service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    forgotPasswordStep1 = async (email: any) => {

        try {

            let data = {
                email: email
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/forgotpasswordstep1'

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data)
            if (req.error) throw new Error(req.message)

            let token = req.token
            window.localStorage.setItem('token', token)

            return { error: req.error, message: '', result: null }
        } catch (error: any) {
            console.log('Login service error', error)
            return { error: true, message: error.message, result: null }
        }
    }


    forgotPasswordStep2 = async (email: any, codigoActivacion: any) => {

        try {

            let data = {
                email: email,
                code: codigoActivacion
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/forgotpasswordstep2'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, data, true)
            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: null }
        } catch (error: any) {
            console.log('Login service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

    forgotPasswordStep3 = async (email: any, newPassword: any) => {

        try {

            let data = {
                email: email,
                newpassword: newPassword
            }

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/forgotpasswordstep3'

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, data, true)
            if (req.error) throw new Error(req.message)

            return { error: req.error, message: '', result: null }
        } catch (error: any) {
            console.log('Login service error', error)
            return { error: true, message: error.message, result: null }
        }
    }

}