import http from '../libs/http';
import { Rutas } from '../resources/Statics';

interface UpdateNewStatusBody {
  subirloNegocio?: boolean
  negocioId: number;
  message: string;
  estado: number;
}

export default class RazonesService {
  static instance = new RazonesService();

  getByNegocio = async (negocioId: number,params: any = "") => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/negocio/' + negocioId + '/' + params;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByNegocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getByContracargo = async (contracargoId: number,params: any = "") => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/contracargo/' + contracargoId + '/' + params;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByContracargo service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getByTransaccionAndContraCargo = async (transaccionId: number,contracargoId: number) => {
    try {
      const base = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/transaccion/' + transaccionId;
      const ruta = contracargoId ? (base + '?contracargoId='+contracargoId) : base;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByTransaccionAndContraCargo service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getByInventarioMposId = async (inventarioMposId: number, params: any = "") => {
    try {
      const base = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/mposInventary/' + inventarioMposId + '/' + params;;

      const httpInstance = new http();

      let req = await httpInstance.get(base, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByInventarioMposId service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getByOrdenId = async (OrdenId: number, params: any = "") => {
    try {
      const base = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/orden/' + OrdenId + '/' + params;;

      const httpInstance = new http();

      let req = await httpInstance.get(base, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByOrdenId service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getRetenidas = async (params: any = "") => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/retenidas' + params;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('getRetenidas service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateDocumento = async (id: number, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlRazones}/documentos/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al actualizar el documento de razon', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  getByLiquidacion = async(liquidacionId: number, params: any = '') => {
    try {
      const base = Rutas.instance.urlBase + Rutas.instance.urlRazones + '/liquidacionNegocio/' + liquidacionId + '/' + params;;

      const httpInstance = new http();

      let req = await httpInstance.get(base, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result, totalPages: req.totalPages };
    } catch (error: any) {
      console.log('get Razones getByLiquidacion service error', error);
      return { error: true, message: error.message, result: null };
    }
  }
}





