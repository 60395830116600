import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Button,
  Select,
  Theme,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import VentasGrafico from './Components/VentasGrafico';
import GraficasServices from '../../services/GraficasService';
import { GeneralContext } from '../../context/GeneralContext';
import PaisesServices from '../../services/PaisesService';
import { LocalAtmTwoTone as LocalAtm, MonetizationOnTwoTone as MonetizationOn } from '@material-ui/icons';
import Utilities from '../../libs/utilities';
import { Skeleton } from '@material-ui/lab';
import ReportesServices from '../../services/ReportesService';
import localStorage from '../../libs/localStorage';
import moment from 'moment';

interface statistics {
  max: string;
  min: string;
  total: string;
  cantidad: number;
}

interface statisticsNet {
  max: string;
  min: string;
  total: string;
  cantidad: number;
}

interface XY {
  x: number;
  y: string;
}

interface XYNet {
  x: any;
  y: any;
  yNet?: any;
}

interface datesfilter {
  startFilter: string;
  endFilter: string;
}

interface Results {
  statistics: statistics;
  statisticsNet: statisticsNet;
  results: XYNet[];
  resultsNet: XYNet[];
  cashResults: XYNet[];
  cardResults: XYNet[];
  linkResults: XYNet[];
  datesfilter: datesfilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      paddingBottom: '0em',
      paddingTop: '0em',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    boxShadow: {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important',
    },
    labelMoneda: {
      color: '#5166AF',
      textAlign: 'center',
      fontWeight: 600,
      opacity: 0.8,
    },
    labelMonedaNetas: {
      color: '#4acdce',
      textAlign: 'center',
      fontWeight: 600,
      opacity: 0.8,
    },
    gridContainer: {
      paddingBottom: '0.8em',
    },
    progress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

const informacion: { [key: string]: string } = {
  '0': 'Tus ventas de esta semana',
  '1': 'Tus ventas de este mes',
  '2': 'Tus ventas de este año',
  '3': 'Tus ventas completas',
};

const reporteServices = new ReportesServices();
const storage = new localStorage();
const Dashboard = () => {
  const { handleErrorMessage, handleActiveError } = useContext(GeneralContext);

  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [resultsTotalNet, setResultsTotalNet] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<Record<string, boolean>>({
    cash: true,
    cards: true,
    links: true,
  });
  const [results, setResults] = useState<Results>();
  const [values, setValues] = useState({
    rango: '0',
    pais: '',
  });
  const [resultsVentaTotal, setResultsVentaTotal] = useState<XYNet[]>();
  const [resultsVentaNeto, setResultsVentaNeto] = useState<XYNet[]>();
  const classes = useStyles();
  const [paises, setPaises] = useState<{ id: number; nombre: string }[]>([]);
  const [labelIndex, setLabelIndex] = useState(informacion[values.rango]);
  const [moneda, setMoneda] = useState<string>('');

  const changeFilterName = (name: string) => () => {
    setFilters({ ...filters, [name]: !filters?.[name] ?? false });
  };

  useEffect(() => {
    getPaises();
  }, []);

  const getPaises = () => {
    PaisesServices.instance
      .getParaPanel()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPaises(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  useEffect(() => {
    if (paises && paises.length > 0 && values.pais === '') {
      setValues({ ...values, pais: paises[0]?.id?.toString() });
    }
  }, [paises]);

  useEffect(() => {
    setLoading(true);
    GraficasServices.instance
      .getGraficas(values.rango, values.pais)
      .then(async response => {
        const { error, result } = response;

        if (!error) {
          generarNewResults(result);
          generarNewResultsNet(result);
          setLabelIndex(informacion[values.rango]);
          if (result?.moneda) setMoneda(result?.moneda);
        } else {
          handleErrorMessage('message');
          handleActiveError(true);
        }
        setLoading(false);
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
        setLoading(false);
      });
  }, [values.rango, values.pais, filters.cash, filters.cards, filters.links]);

  const generarNewResults = (results: Results) => {
    let resultsTotalLocal: number = 0;
    if (results) {
      let newResults = results.results;
      let newCashResults = results.cashResults;
      let newCardResults = results.cardResults;
      let newLinkResults = results.linkResults;

      newCashResults =
        newCashResults?.map(item =>
          Number.isNaN(item.y)
            ? { ...item, yNet: 0, y: 0 }
            : {
                ...item,
                yNet: Number.isNaN(parseFloat(item.yNet)) ? 0 : parseFloat(item.yNet),
                y: parseFloat(item.y),
              },
        ) ?? [];

      newCardResults =
        newCardResults?.map(item =>
          Number.isNaN(item.y)
            ? { ...item, yNet: 0, y: 0 }
            : { ...item, yNet: parseFloat(item.yNet), y: parseFloat(item.y) },
        ) ?? [];

      newLinkResults =
        newLinkResults?.map(item =>
          Number.isNaN(item.y)
            ? { ...item, yNet: 0, y: 0 }
            : { ...item, yNet: parseFloat(item.yNet), y: parseFloat(item.y) },
        ) ?? [];

      newResults =
        newResults?.map(item => (Number.isNaN(item.y) ? { x: item.x, y: 0 } : { x: item.x, y: parseFloat(item.y) })) ??
        [];

      newResults = newResults.map(res => {
        const x = res.x;
        const cash = newCashResults.find(item => item.x === x);
        const card = newCardResults.find(item => item.x === x);
        const link = newLinkResults.find(item => item.x === x);
        const y: number =
          (filters.cash ? (cash && cash.y ? Number(cash?.y) : 0) : 0) +
          (filters.cards ? (card && card.y ? Number(card?.y) : 0) : 0) +
          (filters.links ? (link && link.y ? Number(link?.y) : 0) : 0);
        resultsTotalLocal += y;
        return { x, y };
      });
      setResultsVentaTotal(newResults);
      setResultsTotal(resultsTotalLocal);
    }
  };

  const generarNewResultsNet = (results: Results) => {
    let resultsTotalNetLocal = 0;
    let newResultsNet = results.resultsNet;
    let newCashResults = results.cashResults;
    let newCardResults = results.cardResults;
    let newLinkResults = results.linkResults;

    newCashResults =
      newCashResults?.map(item =>
        Number.isNaN(item.y)
          ? { ...item, yNet: 0, y: 0 }
          : {
              ...item,
              yNet: Number.isNaN(parseFloat(item.yNet)) ? 0 : parseFloat(item.yNet),
              y: parseFloat(item.y),
            },
      ) ?? [];

    newCardResults =
      newCardResults?.map(item =>
        Number.isNaN(item.y)
          ? { ...item, yNet: 0, y: 0 }
          : { ...item, yNet: parseFloat(item.yNet), y: parseFloat(item.y) },
      ) ?? [];

    newLinkResults =
      newLinkResults?.map(item =>
        Number.isNaN(item.y)
          ? { ...item, yNet: 0, y: 0 }
          : { ...item, yNet: parseFloat(item.yNet), y: parseFloat(item.y) },
      ) ?? [];

    newResultsNet =
      newResultsNet?.map(item => (Number.isNaN(item.y) ? { x: item.x, y: 0 } : { x: item.x, y: parseFloat(item.y) })) ??
      [];
    newResultsNet = newResultsNet.map(res => {
      const x = res.x;
      const cash = newCashResults.find(item => item.x === x);
      const card = newCardResults.find(item => item.x === x);
      const link = newLinkResults.find(item => item.x === x);
      const y: number =
        (filters.cash ? (cash && cash.y ? Number(cash?.y) : 0) : 0) +
        (filters.cards ? (card && card.yNet ? Number(card?.yNet) : 0) : 0) +
        (filters.links ? (link && link.yNet ? Number(link?.yNet) : 0) : 0);
      resultsTotalNetLocal += y;
      return { x, y };
    });
    setResultsVentaNeto(newResultsNet);
    setResultsTotalNet(resultsTotalNetLocal);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const sendReport = async () => {
    const email = await storage.getUserEmail();
    reporteServices.reporteApp(1, Number(values.rango), email);
  };

  return (
    <Box
      mt={1}
      mb={2}
      p={2}
      pb={2}
      component={Paper}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
        >
          <Typography variant='h6'>{labelIndex.toLocaleUpperCase()}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
        >
          <Grid
            container
            spacing={2}
            style={{ paddingBottom: '1em', paddingTop: '1em', paddingRight: '0.5em' }}
          >
            <FormControl
              variant='outlined'
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id='demo-simple-select-outlined-label'>Rango</InputLabel>
              <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={values.rango}
                fullWidth
                onChange={handleChange('rango')}
                label='Rango'
              >
                <MenuItem value={'0'}>Esta semana</MenuItem>
                <MenuItem value={'1'}>Este mes</MenuItem>
                <MenuItem value={'2'}>Este año</MenuItem>
                <MenuItem value={'3'}>Todo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Box mb={2}>
        <Button
          onClick={changeFilterName('links')}
          variant='contained'
          color={filters.links ? 'primary' : 'default'}
          style={{ boxShadow: 'none', borderRadius: 10, marginRight: 10, fontSize: '1em' }}
        >
          Links
        </Button>
        <Button
          onClick={changeFilterName('cards')}
          variant='contained'
          color={filters.cards ? 'primary' : 'default'}
          style={{ boxShadow: 'none', borderRadius: 10, marginRight: 10, fontSize: '1em' }}
        >
          POS
        </Button>
        <Button
          onClick={changeFilterName('cash')}
          variant='contained'
          color={filters.cash ? 'primary' : 'default'}
          style={{ boxShadow: 'none', borderRadius: 10, marginRight: 10, fontSize: '1em' }}
        >
          Efectivo
        </Button>
      </Box>

      <Grid container>
        <Grid
          container
          className={classes.gridContainer}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={5}
            sm={12}
          >
            <Card className={classes.boxShadow}>
              <CardHeader
                center
                avatar={<MonetizationOn fontSize='large' />}
                style={{ color: '#5166AF' }}
                title='VENTAS TOTALES'
              />
              <CardContent style={{ paddingTop: '0' }}>
                <Typography
                  variant='h4'
                  className={classes.labelMoneda}
                >
                  {loading ? <CircularProgress /> : `${moneda}${Utilities.formatoMoneda(resultsTotal)}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={5}
            sm={12}
          >
            <Card className={classes.boxShadow}>
              <CardHeader
                center
                avatar={<LocalAtm fontSize='large' />}
                style={{ color: '#4acdce' }}
                title='VENTAS NETAS'
              />
              <CardContent style={{ paddingTop: '0' }}>
                <Typography
                  variant='h4'
                  className={classes.labelMonedaNetas}
                >
                  {loading ? (
                    <CircularProgress color='secondary' />
                  ) : (
                    `${moneda}${Utilities.formatoMoneda(resultsTotalNet)}`
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Card className={classes.boxShadow}>
              <CardContent>
                {loading ? (
                  <div className={classes.progress}>
                    <Skeleton
                      animation='pulse'
                      variant='rect'
                    />
                    <Skeleton
                      animation='pulse'
                      variant='rect'
                    />
                    <Skeleton
                      animation='pulse'
                      variant='rect'
                      height={200}
                    />
                  </div>
                ) : (
                  <VentasGrafico
                    key={'ventasTotales'}
                    filtro={values.rango}
                    label='Ventas totales'
                    moneda={moneda}
                    results={resultsVentaTotal}
                    resultsTotal={resultsTotal}
                    resultsNetas={resultsVentaNeto}
                    resultsNetasTotal={resultsTotalNet}
                    datesfilter={results?.datesfilter}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Box
        p={2}
        bgcolor='#EFF2FB'
        overflow='hidden'
        borderRadius={8}
        display='flex'
        alignItems='center'
        mt={2}
        flexDirection='row'
        justifyContent='space-between'
      >
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontWeight: 'bold' }}
          >
            Reporte de Ventas
          </Typography>
          <Typography>
            Envia a tu correo registrado{' '}
            <Typography
              component='span'
              style={{ fontWeight: 'bold' }}
            >
              {informacion?.[values.rango]?.toLowerCase()}
            </Typography>{' '}
            registradas por medio de un excel y un PDF
          </Typography>
        </Box>

        <Button
          variant='contained'
          color='primary'
          onClick={sendReport}
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <Typography
            variant='subtitle1'
            style={{ fontWeight: 'bold', color: '#fff' }}
          >
            Enviar
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
