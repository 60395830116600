import { paises } from '../../../resources/Statics';
import PayWithLinkService from '../../../services/PayWithLinkService';

export const validarMontoMinimoHonduras = (negocioStatusFlags: any, monto: string) => {
  if (negocioStatusFlags?.tipoNegocio?.pais?.nombre === paises.honduras && Number(monto) < 12) {
    return {
        error: true,
        message: 'Atención. El monto mínimo es de L12.00'
    };
  }
  return {
    error: false,
    message: ''
  };
}

export const validarNegocioStatusFlags = (negocioStatusFlags: any) => {
  let error = false;
  let message = ""
  if (!negocioStatusFlags?.metamapStarted) {
    error = true;
    message = "verifícate"
  }

  if (!negocioStatusFlags?.uploadedDocuments) {
    error = true;
    message = message != "" ? `${message} y completa tus documentos` : " y completa tus documentos"
  }

  message = message === 'verifícate' ? 'y verifícate para habilitarlos.' : `${message} para habilitarlos.`
  if (error) {
    return {
      error: error,
      message: `Ingresa a tu aplicación móvil de Paggo ${message}`
    };
  }

  if (!negocioStatusFlags?.approvedDocuments) {
    return {
      error: true,
      message: '¡Pronto podrás empezar a transaccionar!'
    };
  }

  if (!negocioStatusFlags?.signedContract) {
    return {
      error: true,
      message: '¡Pronto podrás empezar a transaccionar!'
    };
  }

  return {
    error: false,
    message: ''
  };
}

export const habilitarCuotas = (negocioStatusFlags: any) => {
  if (negocioStatusFlags?.tipoNegocio?.id != 7 && negocioStatusFlags?.tipoNegocio?.pais?.nombre === paises.guatemala) {
    return true;
  }
  return false;
}

export const checkForLimits = async (data: {NegocioId: string, monto: number}) => {
  let limitedValidation;
  const checkForLimit = await PayWithLinkService.instance.checkForLimits(data)
  limitedValidation = checkForLimit?.result?.isLimited ?? false;
  if (limitedValidation) {
    return {
      error: true,
      title: 'Has llegado a tu límite mensual.',
      message: 'Ingresa a tu aplicación móvil de Paggo a la sección de ajustes para verificar tu límite y compartir tu información.\n\nSi ya subiste tu límite y necesitas transaccionar más, contacta a soporte'
    };
  } else {
    return {
      error: false,
      message: '',
      title: ''
    };
  }
}