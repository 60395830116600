import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class DocumentosRespaldoService {
    static instance = new DocumentosRespaldoService();

    getDocumentosRespaldo = async (NegocioId: number) => {
        try {
            const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDocumentoRespaldo}/`;
            console.log(url)
            const httpInstance = new http();

            let req = await httpInstance.get(url);
            console.log(req)
            if (req.error) throw new Error(req.message);

            return {
                error: req.error,
                message: req.message,
                result: req.documentos,
              };
        } catch (error: any) {
            console.log('Error al actualizar el documento de razon', error);
            return {
              error: true,
              message: error.message,
              result: null,
            };
        }
    }

    createDocumentoRespaldo = async(params: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentoRespaldo + '/create';
            const httpInstance = new http();
    
            let req = await httpInstance.post(ruta, params, true);
      
            if (req.error) throw new Error(req.message);
      
            return { error: req.error, message: req.message, result: req.result };
        } catch (error:any) {
            console.log('createDocumento service error', error);
            return { error: true, message: error.message, result: null };
        }
       
    }


    updateEstadoDocumentoRespaldo = async (id: number, params: any) => {
        try {
          const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDocumentoRespaldo}`;
    
          const httpInstance = new http();
    
          let req = await httpInstance.put(url, params, true);
    
          if (req.error) throw new Error(req.message);
    
          return {
            error: req.error,
            message: req.message,
            result: req.result,
          };
        } catch (error: any) {
          console.log('Error al actualizar el documento de razon', error);
          return {
            error: true,
            message: error.message,
            result: null,
          };
        }
    }


    getHistorialDocumentoRespaldo = async () => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlHistorialDocumentoRespaldo}/`;
        console.log(url)
        const httpInstance = new http();

        let req = await httpInstance.get(url);
        console.log(req)
        if (req.error) throw new Error(req.message);

        return {
            error: req.error,
            message: req.message,
            result: req.historial,
          };
    } catch (error: any) {
        console.log('Error al actualizar el documento de razon', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
    }
    }
}
