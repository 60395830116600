import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility'
import { Invitacion } from '../interfaces/Interfaces'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ValidationFields from '../libs/validationFields'
import { GeneralContext } from '../context/GeneralContext'
import { RegisterInterface } from '../interfaces/Interfaces'
import { primary } from '../styles/colors'
import RegisterService from '../services/RegisterService'
import InvitacionService from '../services/InvitacionService'
import { useTheme } from '@material-ui/core/styles';
import { Images } from '../styles/images'
import invitacionService from '../services/InvitacionService';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        paddingTop: '10%',
        paddingBottom: '10%'
    },
    buttonIniciar: {
        marginRight: theme.spacing(1),
        backgroundColor: primary,
        color: 'white',
        paddingTop: '4%',
        paddingBottom: '4%',
        display: 'block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    container: {
        padding: '10%',
        paddingTop: '1%'
    },
    buttonNext: {
        paddingTop: '5%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    stepTittle: {
        fontWeight: 550
    },
    inputPadding: {
        paddingTop: '1%'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    rootResponsive: {
        width: '100%',
    },
    buttonResponsive: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    resInputsPadding: {
        paddingTop: '2%'
    }

}));

export default function InvitacionPage() {
    const classes = useStyles();
    const params = useParams<{ codigoInvitacion: string }>()
    const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage } = useContext(GeneralContext)

    const [values, setValues] = useState<RegisterInterface>({
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        telefono: '',
        email: '',
        password: '',

        codigoActivacion: '',
        cui: '',
        nombreNegocio: '',
        numeroCasa: '',
        pais: '',
        departamento: '',
        municipio: '',
        telefonoNegocio: '',
        nit: '',
        actividad: '',
        subActividad: '',
        banco: '',
        numeroCuenta: '',
        tipoCuenta: '',
        nombreCuenta: '',
        tipoAfiliacion: ''
    });
    const [step, setStep] = useState(1);
    const [ochoCaracteresMinimos, setOchoCaracteresMinimos] = useState(false);
    const [mayusculas, setMayusculas] = useState(false);
    const [minusculas, setMinusculas] = useState(false);
    const [numeros, setNumeros] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [nombreNegocio, setNombreNegocio] = useState('');
    const [invitacion, setInvitacion] = useState<Invitacion | null>(null)

    const theme = useTheme();

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        async function getInvitacion() {
            let result = await InvitacionService.instance.getInvitacion(params.codigoInvitacion)
            if (!result) {
                handleErrorMessage('Error, de conexión')
                handleActiveError(true)
                return
            } else if (result.error) {
                handleErrorMessage(result.message)
                handleActiveError(true)
                return
            }
            if (result && result.user) {
                values.nombre = result.user.nombre
                values.apellidoPaterno = result.user.apellidoPaterno
                values.apellidoMaterno = result.user.apellidoMaterno
                values.telefono = result.user.telefono
                values.email = result.user.email
                setValues(values)
            }
            setInvitacion(result.result)
            if (result.result.procesoActual == 1) setStep(2)
        }
        getInvitacion()
    }, [])

    const handleNext = async () => {

        // setStep(step + 1)

        handleLoading(true)

        if (step === 1) {

            let validation = ValidationFields.instance.stepp1(values.telefono, values.email, values.password)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await InvitacionService.instance.registroPaso1(values.nombre, values.apellidoPaterno, values.apellidoMaterno, values.telefono, values.email, values.password, invitacion?.NegocioId, invitacion?.RolId, invitacion?.codigo, invitacion?.UsuarioIdInvitado)
                if (res && res.error) {
                    handleErrorMessage(res.message)
                    handleActiveError(true)
                } else if (res && !res.error) {
                    console.log(res)
                    if (invitacion) invitacion.UsuarioIdInvitado = res.result
                    setInvitacion(invitacion)
                    setStep(step + 1)
                }
            }

        } else if (step === 2) {

            let validation = ValidationFields.instance.stepp2(values.codigoActivacion)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await invitacionService.instance.registroPaso2(invitacion?.UsuarioIdInvitado, values.codigoActivacion, invitacion?.codigo)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {
                    setStep(step + 1)
                }
            }
        }

        handleLoading(false)

    }

    const handlePrevious = async () => {
        handleLoading(true)
        let res = await invitacionService.instance.borrarPaso1(invitacion?.codigo)
        if (res.error) {
            handleErrorMessage(res.message)
            handleActiveError(true)
        }
        setStep(step - 1)
        handleLoading(false)
    }

    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleChangePassword = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
        if (event.target.value.length >= 8) setOchoCaracteresMinimos(true)
        else if (event.target.value.length < 8) setOchoCaracteresMinimos(false)
        if (ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(true)
        else if (!ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(false)
        if (ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(true)
        else if (!ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(false)
        if (ValidationFields.instance.existNumbers(event.target.value)) setNumeros(true)
        else if (!ValidationFields.instance.existNumbers(event.target.value)) setNumeros(false)
    };

    const correoVerificacionCorreo = async () => {
        handleLoading(true)
        let req = await InvitacionService.instance.codigoVerificacionCorreo(values.email, invitacion?.UsuarioIdInvitado)

        if (req.error) {
            handleErrorMessage(req.message)
            handleActiveError(true)
        } else {
            handleSuccessMessage('Se reenvió un correo a tu cuenta: ' + values.email)
            handleActiveSuccess(true)
        }
        handleLoading(false)
    }

    const correoVerificacionSms = async () => {
        handleLoading(true)
        let req = await InvitacionService.instance.codigoVerificacionSms(values.telefono, invitacion?.UsuarioIdInvitado)

        if (req.error) {
            handleErrorMessage(req.message)
            handleActiveError(true)
        } else {
            handleSuccessMessage('Se reenvió un codigo a tu telefono: ' + values.telefono)
            handleActiveSuccess(true)
        }
        handleLoading(false)
    }

    const telefonoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Teléfono</InputLabel>
                <OutlinedInput
                    type='tel'
                    value={values.telefono}
                    onChange={handleChange('telefono')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const correoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Correo</InputLabel>
                <OutlinedInput
                    type='email'
                    value={values.email}
                    onChange={handleChange('email')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const passwordInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Contraseña</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChangePassword('password')}
                        className={classes.inputPadding}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: ochoCaracteresMinimos ? '#009688' : '#766865' }}>8 caracteres</FormHelperText>
                    <FormHelperText style={{ color: minusculas ? '#009688' : '#766865' }}>Letra minúsculas</FormHelperText>
                    <FormHelperText style={{ color: mayusculas ? '#009688' : '#766865' }}>Letra mayúsculas</FormHelperText>
                    <FormHelperText style={{ color: numeros ? '#009688' : '#766865' }}>Número</FormHelperText>
                </div>
            </>
        )
    }

    const codigoActivacionInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Codigo de activación</InputLabel>
                    <OutlinedInput
                        type='text'
                        value={values.codigoActivacion}
                        onChange={handleChange('codigoActivacion')}
                        className={classes.inputPadding}
                    />
                </FormControl>
                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: '#766865' }}>
                        Enviamos un código a tu correo
                        <FormHelperText style={{ color: '#766865', fontWeight: 'bold', display: 'inline' }}>
                            {' ' + values.email}
                        </FormHelperText>
                    </FormHelperText>
                    <FormHelperText style={{ color: '#766865' }}>
                        Enviamos un código a tu teléfono
                        <FormHelperText style={{ color: '#766865', fontWeight: 'bold', display: 'inline' }}>
                            {' ' + values.telefono}
                        </FormHelperText>
                    </FormHelperText>
                </div>
            </>
        )
    }

    const nombreClienteInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Nombre</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.nombre}
                    onChange={handleChange('nombre')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const apellidoPaternoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Primer apellido</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.apellidoPaterno}
                    onChange={handleChange('apellidoPaterno')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const apellidoMaternoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Segundo apellido</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.apellidoMaterno}
                    onChange={handleChange('apellidoMaterno')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const responsiveButtons = () => {
        return (
            <Grid container spacing={3}>

                {
                    step == 2 ?
                        <>
                            <Grid item xs={6}>
                                <Button
                                    disabled={loading}
                                    onClick={handlePrevious}
                                    className={classes.buttonResponsive}
                                >
                                    ATRÁS
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.buttonResponsive}
                                >
                                    FINALIZAR
                                </Button>
                            </Grid>
                        </>
                        :
                        null
                }
                {
                    step == 1 ?
                        <Grid item xs={12}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.buttonResponsive}
                            >
                                INICIAR
                            </Button>
                        </Grid>
                        :
                        null
                }
                {
                    step == 2 ?
                        <>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    variant='text'
                                    className={classes.buttonResponsive}
                                    onClick={correoVerificacionCorreo}
                                    endIcon={<RefreshIcon />}
                                >Reenviar Código Email
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    variant='text'
                                    className={classes.buttonResponsive}
                                    onClick={correoVerificacionSms}
                                    endIcon={<RefreshIcon />}
                                >Reenviar Código SMS
                                </Button>
                            </Grid>
                        </>
                        :
                        null
                }
            </Grid>
        )
    }

    return (
        <>
            {width > 800 ?
                <div className={classes.root}>
                    <div className={classes.container}>
                        <img style={{ cursor: 'pointer' }} width={(width * 0.15)} src={Images.instance.logo} alt="" onClick={() => window.location.href = '/'} />
                        <Stepper activeStep={step - 1}>
                            <Step >
                                <StepLabel  >Crea tu cuenta</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Valida tus datos</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Descarga la app</StepLabel>
                            </Step>
                        </Stepper>
                        <Grid container spacing={3}>
                            {
                                step == 3 ?
                                    null
                                    :
                                    <Grid item xs={7}>
                                        <div >
                                            <img width={(width * 0.40)} height={(height * 0.90)} src={Images.instance.image1} alt="" />
                                        </div>
                                    </Grid>
                            }
                            <Grid item xs={step == 3 ? 12 : 5}>
                                <div style={{ paddingTop: '5%' }}>
                                    {
                                        step === 1 ?
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.stepTittle} variant="h5">
                                                        {invitacion?.negocio?.nombre}
                                                    </Typography>
                                                    <Typography style={{}} variant='subtitle1'>
                                                        Te invitó a participar en su empresa.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography style={{}} variant='body2'>
                                                        Llena el siguiente formulario para crear tu usuario.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {nombreClienteInput()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {apellidoPaternoInput()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {apellidoMaternoInput()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {telefonoInput()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {correoInput()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {passwordInput()}
                                                </Grid>
                                            </Grid>
                                            :
                                            step === 2 ?
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.stepTittle} variant="h5">
                                                            Valida tu cuenta
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {codigoActivacionInput()}
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        <Typography className={classes.stepTittle} variant="h5">
                                                            ¡Listo!
                                                        </Typography>
                                                        <Typography variant="h5" style={{ display: 'inline' }}>
                                                            Se ha creado tu cuenta para
                                                        </Typography>
                                                        <Typography style={{ display: 'inline', fontWeight: 'bold' }} variant="h5">
                                                            {' ' + nombreNegocio}
                                                        </Typography>
                                                        <Typography style={{ fontWeight: 'bold' }} variant='subtitle2'>
                                                            Descarga la aplicación de paggo e ingresa tus credenciales.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        <div >
                                                            <img width={(width * 0.10)} src={Images.instance.logoInvitacion} alt="" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <div style={{ cursor: 'pointer' }} >
                                                            <a href='https://play.google.com/store/apps/details?id=com.paggoapp'>
                                                                <img width={(width * 0.10)} src={Images.instance.logoGooglePlay} alt="" />
                                                            </a>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <a href='https://apps.apple.com/gt/app/paggo/id1611051340'>
                                                                <img width={(width * 0.10)} src={Images.instance.logoAppStore} alt="" />
                                                            </a>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                    }
                                </div>
                                <Grid container spacing={3}>
                                    {
                                        step == 1 ?
                                            <Grid item xs={12}>
                                                <div style={{ paddingTop: '8%' }}>
                                                    <Button color="primary" disabled={loading} fullWidth={step == 1 ? true : false} variant='contained'
                                                        className={step == 1 ? classes.buttonIniciar : classes.buttonNext}
                                                        onClick={handleNext}>Iniciar</Button>
                                                </div>
                                            </Grid>
                                            :
                                            null
                                    }
                                    {
                                        step == 2 ?
                                            <>
                                                <Grid item xs={6}>
                                                    <div style={{ paddingTop: '8%' }}>
                                                        <Button color="primary" disabled={loading} variant='contained'
                                                            onClick={handlePrevious}>Atrás</Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div style={{ paddingTop: '8%' }}>
                                                        <Button color="primary" disabled={loading} variant='contained'
                                                            onClick={handleNext}>Finalizar</Button>
                                                    </div>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        step == 2 ?
                                            <>
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant='text'
                                                        className={classes.button}
                                                        onClick={correoVerificacionCorreo}
                                                        endIcon={<RefreshIcon />}
                                                    >Reenviar Código Email
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant='text'
                                                        className={classes.button}
                                                        onClick={correoVerificacionSms}
                                                        endIcon={<RefreshIcon />}
                                                    >Reenviar Código SMS
                                                    </Button>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div >

                :

                <div className={classes.rootResponsive}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ paddingTop: '5%', textAlign: 'center' }}>
                                <img width={(width * 0.50)} src={Images.instance.logo} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.stepTittle} style={{ textAlign: 'center' }} variant="h5">
                                {invitacion?.negocio?.nombre}
                            </Typography>
                            <Typography style={{ textAlign: 'center' }} variant='subtitle1'>
                                Te invito a participar en su empresa.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant='body2'>
                                Llena el siguiente formulario para crear tu usuario.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Stepper activeStep={(step - 1)} orientation="vertical">
                        <Step key={1}>
                            <StepLabel>Crea tu cuenta</StepLabel>
                            <StepContent>
                                <div className={classes.resInputsPadding}>{nombreClienteInput()}</div>
                                <div className={classes.resInputsPadding}>{apellidoPaternoInput()}</div>
                                <div className={classes.resInputsPadding}>{apellidoMaternoInput()}</div>
                                <div className={classes.resInputsPadding}>{telefonoInput()}</div>
                                <div className={classes.resInputsPadding}>{correoInput()}</div>
                                <div className={classes.resInputsPadding}>{passwordInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>
                        <Step key={2}>
                            <StepLabel>Valida tus datos</StepLabel>
                            <StepContent>
                                <div>{codigoActivacionInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>
                        <Step key={3}>
                            <StepLabel>Descarga la app</StepLabel>
                            <StepContent>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography className={classes.stepTittle} variant="h5">
                                        Listo!
                                    </Typography>
                                    <Typography variant="h5" style={{ display: 'inline' }}>
                                        Se ha creado tu cuenta para
                                    </Typography>
                                    <Typography style={{ display: 'inline', fontWeight: 'bold' }} variant="h5">
                                        {' ' + nombreNegocio}
                                    </Typography>
                                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle2'>
                                        Descarga la aplicación de paggo e ingresa tus credenciales.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <div >
                                        <img width={(width * 0.30)} src={Images.instance.logoInvitacion} alt="" />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center', paddingRight: '1%' }}>
                                    <div style={{ cursor: 'pointer', display: 'inline' }}>
                                        <a href='https://play.google.com/store/apps/details?id=com.paggoapp'>
                                            <img width={(width * 0.15)} height={19} src={Images.instance.logoGooglePlay} alt="" />
                                        </a>
                                    </div>
                                    <div style={{ cursor: 'pointer', display: 'inline', paddingLeft: '1%' }}>
                                        <a href='https://apps.apple.com/gt/app/paggo/id1611051340'>
                                            <img width={(width * 0.15)} height={19} src={Images.instance.logoAppStore} alt="" />
                                        </a>
                                    </div>
                                </Grid>
                            </StepContent>
                        </Step>
                    </Stepper>
                    {(step - 1) === 6 && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            <Typography>Completaste tu proceso de registro con exito!</Typography>
                        </Paper>
                    )}
                </div>
            }
        </>
    );
}