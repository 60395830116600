import {useState } from 'react'
import CrudDataGrid from '../Crud/CrudDataGrid';
import { Rutas } from '../../resources/Statics';
import momentZone from 'moment-timezone';
import { estadosColorEnlace } from '../../resources/Statics';
import Button from '@material-ui/core/Button';
import { DescriptionTwoTone } from '@material-ui/icons';
import ModalVoucher from '../../components/ModalVoucher';

const TransaccionesEnlace = () => {

  const [refresh, setRefresh] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentLink, setCurrentLink ] = useState('')
  const [voucherId, setVoucherId ] = useState('')

  const columns = [
      {
        field: 'createdAt',
        headerName: 'Fecha de creación',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          const fechaTZ6 = momentZone
            .tz(params.row['createdAt'], 'America/Guatemala')
            .locale('es')
            .format('YYYY-MM-DD HH:mm A');
          return <span>{ params.row['createdAt'] ?  fechaTZ6 : ''}</span>;
        },
      },
      {
          field: 'concepto',
          headerName: 'Concepto',
          sortable: false,
          width: 270,
          disableClickEventBubbling: true,
      },
      {
        field: 'fechaRealizoPago',
        headerName: 'Fecha de pago',
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          const fechaTZ6 = momentZone
            .tz(params.row['fechaRealizoPago'], 'America/Guatemala')
            .locale('es')
            .format('YYYY-MM-DD HH:mm A');
          return <span>{ params.row['fechaRealizoPago'] ?  fechaTZ6 : ''}</span>;
        },
    },
      {
        field: 'monto',
        headerName: 'Monto',
        sortable: false,
        width: 180,
        disableClickEventBubbling: true,
      },
      {
        field: 'status',
        headerName: 'Estado',
        sortable: false,
        width: 140,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          if (params.row.status)
            return <span style={{color: estadosColorEnlace[params.row.status.toLocaleLowerCase()], textTransform: 'capitalize'}}>{ params.row['status'] }</span>;
          else
            return null
        }
      },
      {
        field: 'nombre',
        headerName: 'Usuario',
        sortable: false,
        width: 240,
        disableClickEventBubbling: true,
      },
      {
        field: 'voucher',
        headerName: 'Voucher',
        sortable: false,
        width: 140,
        renderCell: (params: any) => {
          console.log(params.row.linkVoucherCliente)
          if (!params.row.linkVoucherCliente) return null;
          return (
            <Button
              variant='contained'
              color='primary'
              onClick={() => openModal(params.row)}
            >
              <DescriptionTwoTone />
            </Button>
          );
        },
      },
      
    ];
  const openModal = (item: any): any => {
    const { id, linkVoucherCliente } = item
    setVoucherId(id)
    setCurrentLink(linkVoucherCliente)
    setIsOpenModal(true)
  }


  return (
      <>
      { isOpenModal &&         
        <ModalVoucher isOpen={isOpenModal} setIsOpen={setIsOpenModal} documentLink={currentLink} voucherId={voucherId}  />
      }
        <CrudDataGrid title='LINKS DE PAGGO' descargar refresh={refresh} apiEndpoint={Rutas.instance.urlPagoEnlace}  columns={columns} placeholder='Transaccion, monto, usuario, estado, voucher' />
      </>
    )
}

export default TransaccionesEnlace

