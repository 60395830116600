import { DropzoneDialog } from 'material-ui-dropzone';
import * as momentZone from 'moment-timezone';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import {
    Button, FormControl, IconButton, ImageList, ImageListItem, InputLabel, ListItemIcon, MenuItem,
    OutlinedInput,
    Paper,
    Select,
    makeStyles
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';

import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import { GeneralContext } from '../../context/GeneralContext';
import {
    InformacionBancaria, ListadoMotivos, MposInterface, MposInventoryInterface, NegocioWithTipoNegocioInterface,
    TipoNegocio, UserInterface
} from '../../interfaces/Interfaces';

import Utilities from '../../libs/utilities';
import ValidationFields from '../../libs/validationFields';


import MposInventoryService from '../../services/MposInventoryService';
import { Rutas, mposInventoryStatus } from '../../resources/Statics';
import CrudService from '../../services/CrudService';


function numeroDeSerieMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

function terminalEpayRetailMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

numeroDeSerieMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
const now = new Date();

interface RouteParams {
  id: string;
}

const initialData = {
  id: 0,
  numeroDeSerie: '',
  modelo: '',
  lote: '',
  ubicacion: '',
  direccion: '',
  estado: '',
  notas: '',
  terminalEpayRetail: '',
  fechaEntrega: undefined
}

export default function PosInventoryEdit() {
  const { id } = useParams<RouteParams>();
  const {
    loading: generalLoading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    selectedRecord,
  } = useContext(GeneralContext);

  const [loading, setLoading] = React.useState(true);

  let history = useHistory();
  const [values, setValues] = useState<MposInventoryInterface>({
    id: 0,
    numeroDeSerie: '',
    modelo: '',
    lote: '',
    ubicacion: '',
    UbicacionId: 0,
    LoteId: 0,
    direccion: '',
    estado: '',
    notas: '',
    terminalEpayRetail: '',
    fechaEntrega: undefined
  });

  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string}[]>([])
  const [lotes, setLotes] = useState<{id: number, descripcion: string}[]>([])

  useEffect(() => {
    getUbicaciones()
    getLotes()
  }, [])
  

  const getUbicaciones = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getLotes = () => {
    const crudService = CrudService.instance(Rutas.instance.urlLotesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setLotes(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  useEffect(() => {
    if (id) {
      if (selectedRecord && selectedRecord.id > 0) {
        cargaData()
      } else {
        if (id)
          getInventoryDetalle(parseInt(id));
      }
    } else {
      handleSelectRecord(initialData)
    }
    setLoading(false);
    () => {
      handleSelectRecord(initialData)
    }
  }, [selectedRecord?.id]);

  const cargaData = () => {
    setLoading(true);
    const fecha = selectedRecord.fechaEntrega
    setValues({ ...selectedRecord });
  }

  const refreshData = () => {
    handleSelectRecord({});
    if (id) {
      setLoading(true)
      getInventoryDetalle(parseInt(id));
    }
    
  };


  const getInventoryDetalle = (id: number) => {
    setLoading(true);
    MposInventoryService
      .getInventoryDetalle(id)
      .then(response => {
        const { error, result, message } = response;
        console
        if (!error) handleSelectRecord(result);
        else {
          handleErrorMessage(message);
          handleActiveError(true);
          history.push(`/admin-home/inventario/pos`);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const editInventory = () => {
    let validation = ValidationFields.instance.newInventory(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        setLoading(true);
        if (id && values.id) 
          MposInventoryService['update'](values.id, values)
          .then(response => {
            setLoading(false);
            const { error, message } = response;
            if (!error) {
              handleSuccessMessage(message);
              handleActiveSuccess(true);
              //history.push('/admin-home/negocios')
            } else {
              handleErrorMessage(message);
              handleActiveError(true);
            }
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage(e.message);
            handleActiveError(true);
          });
      }
    }
  };

  const saveInventory = () => {
    delete values.id;
    let validation = ValidationFields.instance.newInventory(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        setLoading(true);
        MposInventoryService['createOne'](values)
          .then(response => {
            setLoading(false);
            const { error, message } = response;
            if (!error) {
              handleSuccessMessage(message);
              handleActiveSuccess(true);
              //history.push('/admin-home/negocios')
            } else {
              handleErrorMessage(message);
              handleActiveError(true);
            }
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage(e.message);
            handleActiveError(true);
          });
      }
    }
  };


  return (
    <>
    <Box
      mt={1}
      p={2}
      component={Paper}
      style={{ overflow: 'auto', width: '100%' }}
    >
      <Grid
        container
        direction='column'
        spacing={3}
        key='t-0'
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            { id ? <>Detalles del inventario { values.id } </> : <>Crear nuevo MPOS</> }
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Información principal
          </Typography>
        </Grid>

        <Grid
          item
          style={{ flexGrow: 1, display: 'flex' }}
        >
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel variant='outlined'>Número de Serie</InputLabel>
            <OutlinedInput
              color='primary'
              fullWidth
              label='Número de Serie'
              value={values.numeroDeSerie}
              onChange={handleChange('numeroDeSerie')}
              inputComponent={numeroDeSerieMask}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label='Modelo'
            variant='outlined'
            fullWidth
            value={values.modelo}
            onChange={handleChange('modelo')}
          />
        </Grid>
        <Grid item>
        <FormControl variant="outlined" fullWidth >
          <InputLabel htmlFor="outlined-age-native-simple">Ubicacion</InputLabel>
          <Select
            value={values.UbicacionId === null ? 0 : values.UbicacionId}
            variant='outlined'
            fullWidth
            label='Ubicacion'
            placeholder='Ubicacion'
            onChange={handleChange('UbicacionId')}
            disabled={id ? true : false}
          >
            <MenuItem key={'000ubi'} style={{color:'rgba(0, 0, 0, 0.08)'}} value={0}>{'-- No asignada --'}</MenuItem>
            {ubicaciones.map((opcion:{id: number,descripcion:string},index) => (
              
              <MenuItem key={'ubi'+opcion.id} value={opcion.id}>{opcion.descripcion}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        <Grid item>
          <TextField
            label='Direccion de entrega'
            variant='outlined'
            fullWidth
            value={values.direccion}
            onChange={handleChange('direccion')}
          />
        </Grid>
        <Grid
          item
          style={{ flexGrow: 1, display: 'flex' }}
        >
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel variant='outlined'>Terminal</InputLabel>
            <OutlinedInput
              color='primary'
              fullWidth
              label='Terminal'
              value={values.terminalEpayRetail}
              onChange={handleChange('terminalEpayRetail')}
              inputComponent={terminalEpayRetailMask}
            />
          </FormControl>
        </Grid>
        <Grid item>
        <FormControl variant="outlined" fullWidth >
          <InputLabel htmlFor="outlined-age-native-simple">Lote</InputLabel>
          <Select
            value={values.LoteId === null ? 0 : values.LoteId}
            variant='outlined'
            fullWidth
            label='Lote'
            placeholder='Lote'
            onChange={handleChange('LoteId')}
          >
            <MenuItem key={'000lote'} style={{color:'rgba(0, 0, 0, 0.08)'}} value={0}>{'-- No asignado --'}</MenuItem>
            {lotes.map((opcion:{id: number,descripcion:string},index) => (
              
              <MenuItem key={'lote'+opcion.id} value={opcion.id}>{opcion.descripcion}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth >
            <InputLabel htmlFor="outlined-age-native-simple">Estado</InputLabel>
            <Select
              value={values.estado}
              variant='outlined'
              fullWidth
              label='Estado'
              onChange={handleChange('estado')}
            >
              {mposInventoryStatus.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.value}
                      value={status.value}
                      disabled={!id ? status.value === 'Activado' : false}
                    >
                      <Chip
                        label={status.value}
                        style={{ background: status.color, color: 'white' }}
                      />
                    </MenuItem>
                  );
                
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            label='Notas'
            variant='outlined'
            fullWidth
            value={values.notas}
            onChange={handleChange('notas')}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Fecha de entrega"
            type="datetime-local"
            variant='outlined'
            fullWidth
            
            value={values?.fechaEntrega ? values.fechaEntrega : ''}
            onChange={handleChange('fechaEntrega')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading}
            color='primary'
            variant='contained'
            title={ id ? 'Editar Mpos' : 'Crear Mpos'}
            onClick={id ? editInventory : saveInventory}
          />
        </Grid>
      </Grid>
    </Box>
    </>
  );
}
