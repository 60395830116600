import { Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Step, StepLabel, Stepper, TextField, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { Rutas } from '../../resources/Statics';
import { GeneralContext } from '../../context/GeneralContext';
import { Add } from '@material-ui/icons';
import MovimientosInventarioMposUbicacion from '../Movimientos/MovimientosInventarioMposUbicacion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 280,
    },
    formControlCantidad: {
      margin: theme.spacing(1),
      minWidth: 80,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

function getSteps() {
  return ['Solicitud', 'Revisión', 'Movimientos'];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return 'Solicitud';
    case 1:
      return 'Revisión de solicitud';
    case 2:
      return 'Movimientos';
    default:
      return 'Unknown step';
  }
}



const CrearSolicitud = () => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord
  } = useContext(GeneralContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const steps = getSteps();
  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string}[]>([]);
  const [loading, setLoading] = useState(false);
  const [itemsPedidos, setItemsPedidos] = useState<{cantidad: number, modelo: string, ubicacion: number}[]>([
    {cantidad: 1, modelo: 'Model', ubicacion: 0},
  ]);

  useEffect(() => {
    getUbicaciones();
  }, []);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleCantidadChange = (e: any, index: number, key: any) => {
    const newItemsPedidos = [...itemsPedidos]; // Crear una nueva instancia del array
    newItemsPedidos[index] = {...newItemsPedidos[index], [key]: e.target.value}; // Actualizar la cantidad del elemento en el índice dado
    setItemsPedidos(newItemsPedidos); // Actualizar el estado
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getUbicaciones = () => {
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const agregarItem = () => {
    console.log('Agregar item');
  }


  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>
            Crear solicitud
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  {/* <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button> */}
                </div>
              ) : (
                <div>
                  <Grid container style={{padding:'0% 3% 3% 3%'}}>
                    <Grid item xs={12}>
                      {activeStep === 0 && (
                        itemsPedidos.map((itemPedido, index) => (
                          <Grid container >
                            <Grid item xs={8}>
                              <FormControl className={classes.formControlCantidad}>
                                <TextField
                                  label="Cantidad"
                                  type='number'
                                  fullWidth
                                  onChange={(e) => handleCantidadChange(e, index, 'cantidad')}
                                  value={itemPedido.cantidad}
                                />
                              </FormControl>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  label="Modelo"
                                  fullWidth
                                  value={itemPedido.modelo}
                                  onChange={(e) => handleCantidadChange(e, index, 'modelo')}
                                />
                              </FormControl>
                              <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Ubicación destino</InputLabel>
                                <Select
                                  value={itemPedido.ubicacion}
                                  onChange={(e) => handleCantidadChange(e, index, 'ubicacion')}
                                >
                                  {ubicaciones.map((ubicacion) => (
                                    <MenuItem key={ubicacion.id} value={ubicacion.id}>
                                      {ubicacion?.descripcion}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl style={{paddingTop:'15px'}}>
                                <Fab onClick={()=>{
                                  setItemsPedidos([...itemsPedidos, {cantidad: 1, modelo: 'Model', ubicacion: 0}])
                                }} 
                                  color="primary" size='small' aria-label="add">
                                  <Add />
                                </Fab>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ))
                      )}
                      {activeStep === 1 && (
                        <p>Revisando solicitud</p>
                      )}
                      {activeStep === 2 && (
                        <MovimientosInventarioMposUbicacion />
                      )}
                    </Grid>
                  </Grid>
                  
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Anterior
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CrearSolicitud