import * as momentZone from 'moment-timezone';
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { FormField } from '../../../interfaces/Interfaces'
import { Rutas } from '../../../resources/Statics'
import { TextField, TextFieldProps } from '@material-ui/core';
import CrudService from '../../../services/CrudService';
import { useContext, useRef, useState } from 'react';
import { GeneralContext } from '../../../context/GeneralContext';




export default function LoteInventarioMpos() {

  const [refresh, setRefresh] = useState(false);
  const fechaIngresoRef = useRef<TextFieldProps>(null);
  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);

  const formConfig: FormField[] = [
    {
      type: 'text',
      label: 'Nombre',
      name: 'nombre',
      required: true,
      error: 'Debe ingresar un nombre',
      value: '',
    },
    {
      type: 'text',
      label: 'Descripcion',
      name: 'descripcion',
      required: true,
      error: 'Debe ingresar una descripcion',
      value: '',
    },
    {
      type: 'text',
      label: 'Costos',
      name: 'costos',
      required: true,
      error: 'Debe ingresar costos',
      value: '',
    },
    {
      type: 'date',
      label: 'Fecha ingreso',
      name: 'fechaIngreso',
      required: true,
      error: 'Debe ingresar una fecha de ingreso',
      value: '',
    },
  ]
  
  const handleChange = async (event: any, row: any, field: any) => {
    const valor = event.target.value; // Valores seleccionados del Select
    let registro = {[field]: valor}
    registro[field] = valor
    try {
      const crudService = CrudService.instance(Rutas.instance.urlLotesInventarioMpos);
      const response = await crudService.updateOne(row.id, registro);
      const { error, result } = response;
      if (!error) {
          setRefresh(true);
      } else {
          handleErrorMessage('message');
          handleActiveError(true);
      }
      setRefresh(false);
      
    } catch (e) {
      console.error('Error al actualizar la base de datos:', e);
    }
  };
  
  const columnsLotes = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 70,
        disableClickEventBubbling: true,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      editable: true
    },
    {
        field: 'descripcion',
        headerName: 'Descripcion',
        sortable: false,
        width: 300,
        disableClickEventBubbling: true,
        editable: true
    },
    {
      field: 'costos',
      headerName: 'Costos',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      editable: true
    },
    {
      field: 'fechaIngreso',
      headerName: 'Fecha ingreso',
      width: 190,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fechaIngreso'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD');
        return <TextField
          size='small'
          type={'date'}
          inputRef={fechaIngresoRef}
          variant='outlined'
          value={fechaTZ6}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => handleChange(event, params.row, 'fechaIngreso')}
        />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creacion',
      width: 190,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha actualizacion',
      width: 220,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'updatedAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
  ];

  return (
      <CrudDataGrid delete title='Lotes inventario' refresh={refresh} formConfig={formConfig} apiEndpoint={Rutas.instance.urlLotesInventarioMpos} columns={columnsLotes} placeholder='descripcion' />
  )
}
