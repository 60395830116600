/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button } from '@material-ui/core';

import ValidationFields from '../../libs/validationFields';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Interfaces
 */
import { ContactsInterface, tipoDealerInterface, UserInterface, DealersInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import DealersService from '../../services/DealersService';
import { useParams } from 'react-router-dom';

export default function DealerCreate() {
  const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess } =
    useContext(GeneralContext);
  const params = useParams<{ id: string }>();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const [values, setValues] = useState<DealersInterface>({});

  const [tiposDealers, setTiposDealers] = useState<Array<tipoDealerInterface>>([]);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    setLoading(true);
    await getTipos();
    if (params.id) {
      setIsEditable(true);
      console.log('parasm id', params.id);
      await getDealerDetail();
    }
    setLoading(false);
  }

  const getDealerDetail = async () => {
    try {
      const response = await DealersService.instance.getDealerDeatil(params.id);
      setValues({
        dpi: response.result.dpi,
        email: response.result.email,
        nombre: response.result.nombre,
        meta: response.result.meta,
        ubicacion: response.result.ubicacion,
        idTipoDealer: response.result.idTipoDealer,
        telefono: response.result.telefono,
        id: response.result.id
      });
      console.log('response detail', response);
    } catch (err) {
      console.log('err detail', err);
    }
  };

  const updateDealer = async () => {
    setLoading(true);
    const { error, validation } = ValidationFields.instance.newDealer(values);

    if (error) {
      setLoading(false);
      handleErrorMessage(validation);
      handleActiveError(true);
    }
    {
      try {
        const response = await DealersService.instance.updateDealer({ ...values }, values.id ? values.id : 0);
        setLoading(false);
        const { error, message } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          history.push('/admin-home/dealers');
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      } catch (e: any) {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      }
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const getTipos = async () => {
    let req = await DealersService.instance.getDealersTipos();
    setTiposDealers(req.result);
  };

  const handleSave = async () => {
    if (params.id) {
      await updateDealer();
    } else {
      await createDealer();
    }
  }

  const createDealer = async () => {
    setLoading(true);
    const { error, validation } = ValidationFields.instance.newDealer(values);

    if (error) {
      setLoading(false);
      handleErrorMessage(validation);
      handleActiveError(true);
    } else {
      console.log('create dealer', values);
      try {
        const response = await DealersService.instance.createDealer({ ...values });
        setLoading(false);
        const { error, message } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          history.push('/admin-home/dealers');
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      } catch (e: any) {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
    >
      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <Grid
          container
          direction='column'
          spacing={2}
        >
          <Grid item>
            <Typography
              variant='h6'
              gutterBottom
            >
              Nuevo Dealer
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label='Nombre'
              variant='outlined'
              defaultValue={values.nombre}
              value={values.nombre}
              fullWidth
              onChange={handleChange('nombre')}
            />
          </Grid>

          <Grid item>
            <TextField
              label='DPI'
              variant='outlined'
              defaultValue={values.dpi}
              value={values.dpi}
              fullWidth
              onChange={handleChange('dpi')}
            />
          </Grid>
          <Grid item>
            <TextField
              label='email'
              variant='outlined'
              type='email'
              fullWidth
              value={values.email}
              defaultValue={values.email}
              onChange={handleChange('email')}
            />
          </Grid>
          <Grid item>
            <TextField
              label='telefono'
              variant='outlined'
              fullWidth
              value={values.telefono}
              defaultValue={values.telefono}
              onChange={handleChange('telefono')}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Ubicación'
              variant='outlined'
              fullWidth
              value={values.ubicacion}
              defaultValue={values.ubicacion}
              onChange={handleChange('ubicacion')}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Meta'
              variant='outlined'
              type="number"
              fullWidth
              value={values.meta}
              defaultValue={values.meta}
              onChange={handleChange('meta')}
            />
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              variant='outlined'
            >
              <InputLabel id='departammento-select-label'>Tipo de dealer</InputLabel>
              <Select
                labelId='departammento-select-label'
                id='dep-contactos-select'
                label='Tipo de dealer'
                value={values.idTipoDealer}
                defaultValue={values.idTipoDealer}
                onChange={handleChange('idTipoDealer')}
              >
                <MenuItem value='0'>
                  <em>Seleccione</em>
                </MenuItem>
                {tiposDealers.map(tipo => {
                  return (
                    <MenuItem
                      key={tipo.id}
                      value={tipo.id}
                    >
                      {tipo.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSave}
              fullWidth
            >
              { isEditable ? "Editar dealer" : "Crear Dealer"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
