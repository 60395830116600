import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class DocumentosContracargosService {
  static instance = new DocumentosContracargosService();

  crearDocumentos = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDocumentosContracargo}`;

      const httpInstance = new http();

      let req = await httpInstance.post(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al crear documentos de contracargos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  getByContracargo = async (contracargoId: string) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentosContracargo + '/' + contracargoId;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Razones getByContracargo service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
}





