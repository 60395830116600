import { useContext, useEffect, useState } from 'react'
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { Rutas } from '../../../resources/Statics'
import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../../context/GeneralContext';
import CrudService from '../../../services/CrudService';
import { FormField } from '../../../interfaces/Interfaces';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const habilitados = [
  {value: true, label: 'Si'}, 
  {value: false, label: 'No'}, 
]
const AccesosVisuales = () => {

  const [roles, setRoles] = useState<{id: number, nombre: string, tipo: string}[]>([])
  const [padres, setPadres] = useState<{id: number, label: string, icon?: string}[]>([])
  const [refresh, setRefresh] = useState(false);

  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);

  useEffect(() => {
    getRolesPanel()
    getItemsPadres()
  }, [])

  const getRolesPanel = () => {
    const crudService = CrudService.instance(Rutas.instance.urlRole + '/panel');
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setRoles(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getItemsPadres = () => {
    const crudService = CrudService.instance(Rutas.instance.urlRutaMenu);
    crudService
      .getAll('?padres=si&isMenu=si')
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPadres(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = async (event: any, row: any, field: string = 'RolesPermitidos') => {
    let valor = event.target.value;
    
    if (field === 'RolesPermitidos') {
      if (valor.indexOf('Todos') > -1) {
        valor = ['Todos']
      }
    }

    let registro = {[field]: valor}
    registro[field] = field === 'isExterno' ? (valor === 'Si' ? true : false) : valor
    try {
        if (registro) {
          const crudService = CrudService.instance(Rutas.instance.urlRutaMenu);
          const response = await crudService.updateOne(row.id, registro);
          const { error, result } = response;
          if (!error) {
            setRefresh(true);
          } else {
              handleErrorMessage('message');
              handleActiveError(true);
          }
          setRefresh(false);
        }
      } catch (e) {
        console.error('Error al actualizar la base de datos:', e);
      }
  };


  const columns = [
    {
      field: 'posicion',
      headerName: 'Orden',
      type: 'number',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      editable: true
    },
    {
      field: 'label',
      headerName: 'Item',
      sortable: false,
      width: 260,
      disableClickEventBubbling: true,
      editable: true,
    },
    {
      field: 'padre',
      headerName: 'Item Padre',
      sortable: false,
      width: 240,
      type: 'select',
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const valor = params.row?.PadreId ? params.row?.PadreId : '0';
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select value={valor} onChange={(event) => handleChange(event, params.row, 'PadreId')}>
                <MenuItem key={'000'} value={0}>{'Ninguno'}</MenuItem>
                {padres.map((opcion,index) => (
                  <MenuItem key={index} value={opcion.id}>
                    {opcion.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'path',
      headerName: 'Ruta',
      sortable: false,
      width: 370,
      disableClickEventBubbling: true,
      editable: true
    },
    
    {
      field: 'habilitado',
      headerName: 'Habilitado',
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      editable: true,
      type: 'boolean',
    },
    
    {
      field: 'isMenu',
      headerName: 'Para el Menu',
      sortable: false,
      type: 'boolean',
      width: 150,
      disableClickEventBubbling: true,
      editable: true,
    },
    {
      field: 'isExterno',
      headerName: 'Es Externo?',
      sortable: false,
      type: 'boolean',
      width: 150,
      disableClickEventBubbling: true,
      editable: true,
      renderCell: (params: any) => {
        const panels = [true, false]
        const valor = params.row.isExterno === true ? 'Si' : 'No'
        return (
          <div className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}>
            <FormControl>
              <Select value={valor} onChange={(event) => handleChange(event, params.row, 'isExterno')}>
                {panels.map((opcion,index) => (
                  <MenuItem key={index} value={opcion === true ? 'Si' : 'No'}>{opcion === true ? 'Si' : 'No'}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'icon',
      headerName: 'Icono',
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      editable: true
    },
    {
      field: 'componente',
      headerName: 'Componente',
      sortable: false,
      width: 280,
      disableClickEventBubbling: true,
      editable: true
    },
    
    {
      field: 'RolesPermitidos',
      headerName: 'Roles permitidos',
      sortable: false,
      width: 270,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const rolesSelect = JSON.parse(params.row.RolesPermitidos)
        return (
            <FormControl>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={rolesSelect}
              onChange={(event) => handleChange(event, params.row)}
              input={<Input />}
              renderValue={(selected) => (selected as string[]).join(', ')}
              MenuProps={MenuProps}
            >
              <MenuItem key={'1234'} value={'Todos'}>
                <Checkbox checked={rolesSelect.indexOf('Todos') > -1} />
                <ListItemText primary={'Todos'} />
              </MenuItem>
              {roles.map((rol) => (
                <MenuItem disabled={rolesSelect.indexOf('Todos') > -1 ? true : false} key={rol.id} value={rol.tipo}>
                  <Checkbox checked={rolesSelect.indexOf(rol.tipo) > -1} />
                  <ListItemText primary={rol.tipo} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
        );
      },
    },
    
    
    
  ];

  const formConfig: FormField[] = [
    {
      type: 'text',
      label: 'Item',
      name: 'label',
      required: true,
      error: 'Debe ingresar un nombre del item',
      value: '',
    },
    {
      type: 'text',
      label: 'Ruta',
      name: 'path',
      required: false,
      error: 'Debe ingresar la ruta del item',
      value: '',
    },
    {
      type: 'select',
      label: 'Item Padre',
      name: 'PadreId',
      options: padres.map((padre) => ({value: padre.id, label: padre.label})),
      required: false,
      error: 'Debe seleccionar una Item Padre',
      value: 0,
    },
    {
      type: 'select-multiple',
      label: 'Roles permitidos',
      name: 'RolesPermitidos',
      required: false,
      error: 'Debe seleccionar los roles permitidos',
      options: [{value: 'Todos', label: 'Todos'}, ...roles.map((rol) => ({value: rol.tipo, label: rol.tipo}))],
      value: [],
    },
    {
      type: 'number',
      label: 'Orden',
      name: 'posicion',
      required: true,
      error: 'Debe ingresar el orden del item',
      value: 1,
    },
    {
      type: 'boolean',
      label: 'Es para Menu?',
      name: 'isMenu',
      options: habilitados,
      required: false,
      error: 'Debe seleccionar si es para menu',
      value: true,
    },
    {
      type: 'boolean',
      label: 'Es Externo?',
      name: 'isExterno',
      options: habilitados,
      required: false,
      error: 'Debe seleccionar si es externo o no',
      value: false,
    },
    {
      type: 'text',
      label: 'Icono',
      name: 'icon',
      required: false,
      error: 'Debe ingresar nombre de Icon',
      value: '',
    },
    {
      type: 'text',
      label: 'Componente',
      name: 'componente',
      required: false,
      error: 'Debe ingresar el nombre del componente del item',
      value: '',
    },
    {
      type: 'boolean',
      label: 'Habilitado',
      name: 'habilitado',
      options: habilitados,
      required: true,
      error: 'Debe seleccionar si estara habilitado o no',
      value: true,
    },
  ];
  

  return (
    <CrudDataGrid formConfig={formConfig} refresh={refresh} apiEndpoint={Rutas.instance.urlRutaMenu} columns={columns} placeholder='descripcion' />
  )
}

export default AccesosVisuales