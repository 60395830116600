/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as momentZone from 'moment-timezone';
import { Chip, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
import PageLoader from '../../components/PageLoader';
/**
 * Component
 */
export default function VisualizacionLiquidacionesHonduras() {
  const { handleErrorMessage, handleActiveError, handleLoading, loading } = useContext(GeneralContext);

  const [visa, setVisa] = useState<number | undefined>();
  const [liquidar, setLiquidar] = useState<number | undefined>();
  const [paggo, setPaggo] = useState<number | undefined>();
  const [datesFiltered, setDatesFiltered] = useState<string>('');

  const [exportedAmount, setExportedAmount] = useState<number | undefined>();
  const [reprocessAmount, setReprocessAmount] = useState<number | undefined>();
  const [payableAmount, setPayableAmount] = useState<number | undefined>();
  const [paidAmount, setPaidAmount] = useState<number | undefined>();
  const [checkAmount, setCheckAmount] = useState<number | undefined>();

  const [filterDays, setFilterDays] = useState([
    { name: 'Martes', isSelected: true, day: 2 },
  ]);

  useEffect(() => {
    getData(2);

    filterDays.forEach((day, index) => {
      if (index === momentZone.tz('America/Guatemala').day() - 1) day.isSelected = true;
    });
  }, []);

  const getData = (index: number) => {
    handleLoading(true);

    ReportesServices.instance
      .visualizacionHonduras(index)
      .then(response => {
        const { error, result, message } = response;

        if (!error) {
          setVisa(result.visa);
          setPaggo(result.paggo);
          setLiquidar(result.liquidar);
          setExportedAmount(result.exportedAmount);
          setReprocessAmount(result.reprocessAmount);
          setPayableAmount(result.payableAmount);
          setPaidAmount(result.paidAmount);
          setCheckAmount(result.checkAmount);
          setDatesFiltered(message);
        }
        handleLoading(false);
      })
      .catch(e => {
        handleLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  return (
    <Box
      p={2}
      mt={1}
      component={Paper}
    >
      {/* Filter container */}
      <Grid
        container
        spacing={2}
        alignItems='center'
      >
        <Grid
          item
          sm={12}
        >
          <Typography variant='h6'>Visualizacion Honduras</Typography>
        </Grid>

        {/* Day selector */}
        <Grid
          item
          sm={12}
          container
          spacing={2}
          direction='row'
        >
          {filterDays.map((day, index) => {
            return (
              <Grid
                item
                key={index}
              >
                <Chip
                  label={day.name}
                  color='primary'
                  variant={day.isSelected ? 'default' : 'outlined'}
                  onClick={() => {
                    const newState = filterDays.map(item => {
                      item.name === day.name ? (item.isSelected = true) : (item.isSelected = false);
                      return item;
                    });

                    setFilterDays(newState);

                    getData(day.day);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>

        {/* Dates filtered text */}
        <Grid
          item
          sm={12}
        >
          <Typography variant='subtitle1'>{datesFiltered}</Typography>
        </Grid>
      </Grid>

      {/* Principal amounts */}
      <Grid
        container
        spacing={3}
      >
        {/* Amount from Visanet */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{
              padding: 20,
              borderWidth: 3,
              minHeight: 150,
              display: 'flex',
              borderRadius: 10,
              borderColor: '#5166AF',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            variant='outlined'
          >
            <Typography variant='h6'>Adquiriente</Typography>

            <Typography variant='h3'>LPS. {visa}</Typography>
          </Card>
        </Grid>

        {/* Amount to settle */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{
              padding: 20,
              borderWidth: 3,
              minHeight: 150,
              display: 'flex',
              borderRadius: 10,
              borderColor: '#7bebe3',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            variant='outlined'
          >
            <Typography variant='h6'>Monto a liquidar</Typography>

            <Typography variant='h3'>LPS. {liquidar}</Typography>
          </Card>
        </Grid>

        {/* Amount to Paggo */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{ borderColor: '#7d8fc8', borderWidth: 3, borderRadius: 10, minHeight: 100 }}
            variant='outlined'
          >
            <Typography
              style={{ marginLeft: 15, marginTop: 15 }}
              variant='h6'
              gutterBottom
            >
              Monto de paggo
            </Typography>

            <Typography
              style={{ marginLeft: 20, marginTop: 15 }}
              variant='h3'
              gutterBottom
            >
              LPS. {paggo}
            </Typography>
            <div></div>
          </Card>
        </Grid>
      </Grid>

      {/* General Amounts Title*/}
      <Grid
        container
        direction='column'
        spacing={2}
        style={{ padding: '15px 0px' }}
      >
        <Grid item>
          <Typography variant='h6'>Montos Generales</Typography>
        </Grid>

        <Grid item>
          {/* Pending Transactions Amount Title*/}
          <Typography variant='subtitle1'>
            *Esta sección muestra la suma por estado de las liquidaciones por negocio.
          </Typography>
        </Grid>
      </Grid>

      {/* General Amounts*/}
      <Grid
        container
        spacing={3}
      >
        {/* Amount of exported settlements */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{
              padding: 20,
              borderWidth: 3,
              minHeight: 150,
              display: 'flex',
              borderRadius: 10,
              borderColor: '#5166AF',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            variant='outlined'
          >
            <Typography variant='h6'>Exportadas</Typography>

            <Typography variant='h3'>LPS. {exportedAmount}</Typography>
          </Card>
        </Grid>

        {/* Amount of settlement to reprocess */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{
              padding: 20,
              borderWidth: 3,
              minHeight: 150,
              display: 'flex',
              borderRadius: 10,
              borderColor: '#FE8300',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            variant='outlined'
          >
            <Typography variant='h6'>Reprocesar</Typography>

            <Typography variant='h3'>LPS. {reprocessAmount}</Typography>
          </Card>
        </Grid>

        {/* Payable amount */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{ borderColor: '#f44336', borderWidth: 3, borderRadius: 10, minHeight: 100 }}
            variant='outlined'
          >
            <Typography
              style={{ marginLeft: 15, marginTop: 15 }}
              variant='h6'
              gutterBottom
            >
              Por pagar
            </Typography>

            <Typography
              style={{ marginLeft: 20, marginTop: 15 }}
              variant='h3'
              gutterBottom
            >
              LPS. {payableAmount}
            </Typography>
            <div></div>
          </Card>
        </Grid>

        {/* Amount paid */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{ borderColor: '#66bb6a', borderWidth: 3, borderRadius: 10, minHeight: 100 }}
            variant='outlined'
          >
            <Typography
              style={{ marginLeft: 15, marginTop: 15 }}
              variant='h6'
              gutterBottom
            >
              Pagado
            </Typography>

            <Typography
              style={{ marginLeft: 20, marginTop: 15 }}
              variant='h3'
              gutterBottom
            >
              LPS. {paidAmount}
            </Typography>
            <div></div>
          </Card>
        </Grid>

        {/* Check paid */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{ borderColor: '#29b6f6', borderWidth: 3, borderRadius: 10, minHeight: 100 }}
            variant='outlined'
          >
            <Typography
              style={{ marginLeft: 15, marginTop: 15 }}
              variant='h6'
              gutterBottom
            >
              Cheque emitido
            </Typography>

            <Typography
              style={{ marginLeft: 20, marginTop: 15 }}
              variant='h3'
              gutterBottom
            >
              LPS. {checkAmount}
            </Typography>
            <div></div>
          </Card>
        </Grid>
      </Grid>

      {/* Pending Transactions Amount Title*/}
      <Grid
        container
        direction='column'
        spacing={2}
        style={{ padding: '15px 0px' }}
      >
        <Grid item>
          <Typography variant='h6'>Transacciones Generales</Typography>
        </Grid>

        <Grid item>
          {/* Pending Transactions Amount Title*/}
          <Typography variant='subtitle1'>
            *Esta sección muestra la suma de todas las transacciones que se encuentran en estado pendiente de liquidar.
          </Typography>
        </Grid>
      </Grid>

      {/* Pending Transactions */}
      <Grid
        container
        spacing={3}
      >
        {/* Pending Transactions Amount */}
        <Grid
          item
          xs={4}
        >
          <Card
            style={{
              padding: 20,
              borderWidth: 3,
              minHeight: 150,
              display: 'flex',
              borderRadius: 10,
              borderColor: '#f44336',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            variant='outlined'
          >
            <Typography variant='h6'>Pendiente</Typography>

            <Typography variant='h3'>LPS.</Typography>
          </Card>
        </Grid>
      </Grid>
      <PageLoader loading={loading} />
    </Box>
  );
}
