import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColumns } from '@material-ui/data-grid'
import { useState } from 'react'
import { Link } from 'react-router-dom';

const SolicitudesProductos = () => {
  const [page, setPage] = useState(0);


  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'descripcion', headerName: 'Descripción', width: 180 },
    { field: 'cantidadtotal', headerName: 'Cantidad total', width: 180 },
    { field: 'fecha', headerName: 'Fecha', width: 150 },
    { field: 'movimientos', headerName: 'Movimientos', width: 170 },
    { field: 'usuario', headerName: 'Usuario solicita', width: 200 },
  ];

  const rows = [
    { id: 1, descripcion: 'Solicitud 1', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '3/5', usuario: 'Usuario 1'},
    { id: 2, descripcion: 'Solicitud 2', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '2/5', usuario: 'Usuario 2'},
    { id: 3, descripcion: 'Solicitud 3', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '2/3', usuario: 'Usuario 3'},
    { id: 4, descripcion: 'Solicitud 4', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '1/1', usuario: 'Usuario 4'},
    { id: 5, descripcion: 'Solicitud 5', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '3/3', usuario: 'Usuario 5'},
    { id: 6, descripcion: 'Solicitud 6', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '5/5', usuario: 'Usuario 6'},
    { id: 7, descripcion: 'Solicitud 7', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '5/5', usuario: 'Usuario 7'},
    { id: 8, descripcion: 'Solicitud 8', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '4/4', usuario: 'Usuario 8'},
    { id: 9, descripcion: 'Solicitud 9', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '2/2', usuario: 'Usuario 9'},
    { id: 10, descripcion: 'Solicitud 10', fecha: '2021-08-20', cantidadtotal: 10, movimientos: '1/3', usuario: 'Usuario 10'},
  ];


  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>
            Solicitudes de Productos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <div style={{ height: 400, width: '100%' }}>
            <Button 
              color='primary' 
              variant='contained'
              component={Link}
              to='productos/crear'
            >Crear solicitud</Button>
            <DataGrid
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={5}
              rowsPerPageOptions={[5]}
              pagination
              columns={columns}
              rows={rows}
            />
          </div>
        </Grid>
      </Grid>
    </Box>

  )
}

export default SolicitudesProductos