import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class MposInventoryService {
  static createInventoryMpos = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/create`;

      const httpInstance = new http();

      let req = await httpInstance.post(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al crear el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static deleteInventoryMpos = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/delete`;

      const httpInstance = new http();

      let req = await httpInstance.delete(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al eliminar el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static getInventoryMpos = async (searchValue: string = '') => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/?searchValue=${searchValue}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        results: req.results,
      };
    } catch (error: any) {
      console.log('Erro al obtener el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        results: null,
      };
    }
  }

  static getInventoryDetalle = async (id: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al obtener un inventario especifico de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static update = async (id: number, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al crear el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static updateMasivo = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/update/masivo`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al actualizar masivamente el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static deleteMasivo = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/delete/masivo`;

      const httpInstance = new http();

      let req = await httpInstance.delete(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al eliminar masivamente el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static delete = async (id: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.delete(url, {}, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al eliminar el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static createOne = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/createOne`;

      const httpInstance = new http();

      let req = await httpInstance.post(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al crear el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

  static updateUbicacion = async (id: number, params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.mposInventory}/${id}/ubicacion`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, params, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error: any) {
      console.log('Error al crear el inventario de mpos', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  }

}
