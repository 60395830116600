import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, OutlinedInput, Paper, Step, StepConnector, StepLabel, Stepper, Switch, Theme, Typography, createStyles, makeStyles, withStyles } from "@material-ui/core";
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import useAjustesCardProps from "./hooks";
import { useContext, useEffect, useState } from "react";
import { CreacionLinkPaggo, valuesInterface3dsTransaction } from "../../interfaces/Interfaces";
import { categoriasVisaTipoNegocio, currencyConfig } from '../../resources/Statics';
import { validarMontoMinimoHonduras, validarNegocioStatusFlags, habilitarCuotas, checkForLimits } from './utils/validations';
import { Images } from "../../styles/images";
import NoEncryptionTwoToneIcon from '@material-ui/icons/NoEncryptionTwoTone';
import { GeneralContext } from "../../context/GeneralContext";
import localStorage from '../../libs/localStorage'
import PayWithLinkService from "../../services/PayWithLinkService";
import Utilities from "../../libs/utilities";
import PageLoader from "../../components/PageLoader";
import MoneyInput from "@rschpdr/react-money-input";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#292865',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#292865',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
      padding: '1em',
    },
    buttonNoFull: {
      marginRight: theme.spacing(1),
      padding: '1em',
      width: '50%',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    alertpaggo: {
      backgroundColor: '#292865',
      padding: 16,
      marginBottom: 5,
      borderRadius: 15,
      flexDirection: 'row',
      alignItems: 'center',
      color: '#FFF',
    },
    inputPadding: {
      paddingTop: '1%'
    },
    buttonCuotas: {
      '&:hover': {
        backgroundColor: '#292865',
        color: '#FFF',
      },
      
      height: '50px',
      borderRadius: '12px',
      borderColor: '#4259A9',
      borderWidth: '1px',
    },
    buttonCuotasSelected: {
      
      height: '50px',
      borderRadius: '12px',
      borderColor: '#292865',
      backgroundColor: '#4259A9',
      borderWidth: '1px',
      color: '#FFF',
    },
    cuotasSubtitle: { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' },
    containerFileCopy: { backgroundColor: '#eff1fb', borderRadius: '6px', padding: '0.5em 0.1em 0.5em 0.1em' },
    filecopy: {color: '#292865'},
  }),
);

function getSteps() {
  return ['Crea un link', 'Comparte'];
}

enum Dues {
  'VC02',
  'VC03',
  'VC06',
  'VC10',
  'VC12',
  'VC15',
  'VC18',
  'VC24',
  'VC36',
  'VC48',
}

enum Titles {
  '2 Cuotas',
  '3 Cuotas',
  '6 Cuotas',
  '10 Cuotas',
  '12 Cuotas',
  '15 Cuotas',
  '18 Cuotas',
  '24 Cuotas',
  '36 Cuotas',
  '48 Cuotas',
}

enum Subtitles {
  '5.25% de comisión',
  '5.75% de comisión',
  '7.00% de comisión',
  '7.25% de comisión',
  '8.00% de comisión',
  '10.00% de comisión',
  '12.00% de comisión',
  '14.00% de comisión',
  '16.00% de comisión',
  '18.00% de comisión',
}

enum SubtitlesPayfac {
  '7.36% + IVA',
  '7.86% + IVA',
  '9.11% + IVA',
  '9.36% + IVA',
  '10.11% + IVA',
  '12.11% + IVA',
  '14.11% + IVA',
  '15.61% + IVA',
  '16.61% + IVA',
  '17.61% + IVA',
}

interface DuesInfo {
  title: string;
  subtitle: string;
  code: string;
  isSelected: boolean;
}

const LinkPaggoCreacion = () => {

  const {
    loading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleActiveSuccess,
    handleSuccessMessage,
    selectedRecord,
  } = useContext(GeneralContext);
  
  
  

  

  const [values, setValues] = useState<CreacionLinkPaggo>({
    NegocioId: 0,
    UsuarioId: 0,
    email: '',
    ipCreador: null,
    latitud: '0',
    link: 'paggo.com',
    versionApp: 'web',
    nombreCliente: '',
    longitud: '0',
    monto: 0,
    nit: '',
    nombre: '',
    numeroDeVisaCuotas: '',
    rapido: false,
    status: 'activo',
    concepto: ''
  });
  const [cuotas, setCuotas] = useState(false);
  const [cuotasType, setCuotasType] = useState<string>('')
  const [cantidadCuotas, setCantidadCuotas] = useState<string | undefined>('')
  const [showCuotasDialog, setShowCuotasDialog] = useState(false);
  const [showUsuarioDesactivado, setShowUsuarioDesactivado] = useState(false);
  const [showCheckLimits, setShowCheckLimits] = useState(false);
  const [selectedCoutasText, setSelectedCoutasText] = useState<string>('')
  const [datosCuotas, setDatosCuotas] = useState<DuesInfo[]>([])
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [width, setWidth] = useState(window.innerWidth);
  const [PagoEnlaceId, setPagoEnlaceId] = useState<number>(0);
  const [pagoEnlace, setPagoEnlace] = useState<any>(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [titleCheckLimits, setTitleCheckLimits] = useState('');
  const [descriptionLimits, setDescriptionLimits] = useState('');


  
  const { text, negocioStatusFlags, loadingHook, setLoadinHook } = useAjustesCardProps();

  useEffect(() => {
    function handleResize() {
        setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    setLoadingPage(loadingHook);
  }, [loadingHook]);

  useEffect(() => {
    
    const getDataForValues = async () => {
      setLoadinHook(true);
      let user = await localStorage.instance.getUserParseado();
      if (user && user.rolUsuario && user.rolUsuario[0] && user.rolUsuario[0].NegocioId && user.rolUsuario[0].UsuarioId) {
        const NegocioId = user.rolUsuario[0].NegocioId;
        const UsuarioId = user.rolUsuario[0].UsuarioId;
        setValues({...values, 
          nombre: negocioStatusFlags?.usuario?.nombre,
          nit: negocioStatusFlags?.usuario?.nit ? negocioStatusFlags?.usuario?.nit : 'CF',
          NegocioId: NegocioId,
          UsuarioId: UsuarioId
        });
      }
      setLoadinHook(false);
    }

    if (negocioStatusFlags && text){
      if (negocioStatusFlags?.usuario?.activada === false){
        localStorage.instance.cerrarSesion();
        setShowUsuarioDesactivado(true);
      }
      getDataForValues();
    }
  } , [negocioStatusFlags, text]);

  useEffect(() => {
    if (PagoEnlaceId > 0) {
      setLoadinHook(true);
      PayWithLinkService.instance.obtenerPagoEnlacePorId(PagoEnlaceId)
      .then(response => {
        const { error, result, message } = response;
        if (!error) {
          setPagoEnlace(result);
        };
        setLoadinHook(false);
      })
      .catch(e => {
        console.log(e);
        setLoadinHook(false);
      });
    }
  }, [PagoEnlaceId]);

  const myDataIterator = async () => {
    const myData: DuesInfo[] = [];
    for (let i = 0; i < 10; i++) {
      myData.push({
        title: Titles[i],
        subtitle:
          negocioStatusFlags?.tipoNegocio?.categoriaVisa ===
          categoriasVisaTipoNegocio.agregador
            ? Subtitles[i]
            : SubtitlesPayfac[i],
        code: Dues[i],
        isSelected: false,
      });
    }
    setDatosCuotas(myData);
  };

  const selectedDue = async (value: DuesInfo) => {
    let tempdatosCuotas = await datosCuotas.map(v =>
      v.code === value.code
        ? { ...v, isSelected: true }
        : { ...v, isSelected: false },
    );

    setDatosCuotas(tempdatosCuotas);
    setSelectedCoutasText(value.title);
    setCantidadCuotas(value.code);
    setValues({...values, numeroDeVisaCuotas: value.code})
    
  };

  const crearLinkPaggo = async () => {
    setLoadinHook(true);
    
    PayWithLinkService.instance.crearLinkPaggo(values)
    .then(response => {
      const { error, result, message } = response;
      if (!error) {
        setPagoEnlaceId(result.id);
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      } else {
        
        if (message === 'Usuario no activado.'){
          localStorage.instance.cerrarSesion();
          setShowUsuarioDesactivado(true);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      }

      setLoadinHook(false);
    })
    .catch(e => {
      console.log(e);
      setLoadinHook(false);
    });

  }


  const handleNext = async () => {
    if (activeStep === 0){
      const validacionMontoMinimoHonduras = validarMontoMinimoHonduras(negocioStatusFlags, values.monto.toString())
      if (validacionMontoMinimoHonduras.error){
        handleErrorMessage(validacionMontoMinimoHonduras.message);
        handleActiveError(true);
        return;
      }
      setLoadinHook(true);
      const checkForLimit = await checkForLimits({NegocioId: String(values.NegocioId), monto: values.monto})
      if (checkForLimit.error){
        setTitleCheckLimits(checkForLimit.title);
        setDescriptionLimits(checkForLimit.message);
        setShowCheckLimits(true);
        setLoadinHook(false);
        return;
      }
      if (values.monto < 1) {
        handleErrorMessage('El monto debe ser mayor a 1.');
        handleActiveError(true);
        setLoadinHook(false);
        return;
      }
      if (values.concepto === '') {
        handleErrorMessage('Por favor ingrese un concepto.');
        handleActiveError(true);
        setLoadinHook(false);
        return;
      }

      crearLinkPaggo();
    }



    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const montoInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Monto *</InputLabel>
        <MoneyInput
          customInput={OutlinedInput}
          variant="outlined"
          className={classes.inputPadding}
          currencyConfig={currencyConfig[negocioStatusFlags?.tipoNegocio?.pais?.nombre]}
          onChange={handleChange('monto')}
          value={values.monto}
          />
      </FormControl>
    )
  }

  const conceptoInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Concepto *</InputLabel>
        <OutlinedInput
          type='text'
          value={values.concepto}
          onChange={handleChange('concepto')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const handleClickCopy = () => {
    navigator.clipboard.writeText(pagoEnlace?.link);
    handleActiveSuccess(true);
    handleSuccessMessage('Link copiado al portapapeles');
  };

  return (
   
      <Grid
        container>

        {!loadingPage && 
        <>

          {negocioStatusFlags?.disabledCredentials ?
            <Grid item md={12} >
              <Paper elevation={1} style={{paddingTop: '8em',paddingBottom: '4em', minHeight: '45em'}}>
                <Typography variant='h5' style={{ textAlign: 'center' }}>
                  <strong><NoEncryptionTwoToneIcon /> Credenciales deshabilitadas</strong>
                </Typography>
                <br />
              </Paper>
            </Grid>
          : 
          
          <>
            <Grid item xs={12}>
              <Paper className={classes.alertpaggo} elevation={1}>
                <Typography variant='h6' style={{ textAlign: 'center' }}>
                  <span style={{color: '#FFF'}}>{text}</span>
                </Typography>
              </Paper>
            </Grid>
            { validarNegocioStatusFlags(negocioStatusFlags).error ? (
              <>
                <Grid item md={12} >
                  <Paper elevation={1} style={{paddingTop: '8em',paddingBottom: '4em', minHeight: '45em'}}>
                    <Typography variant='h5' style={{ textAlign: 'center' }}>
                      <strong>Aún no tienes habilitados tus links</strong>
                    </Typography>
                    <br />
                    <Typography variant='h6' style={{ textAlign: 'center' }}>
                      <span >{validarNegocioStatusFlags(negocioStatusFlags).message}</span>
                    </Typography>
                    <div style={{textAlign: 'center', paddingTop: '2em'}}>
                      <a href='https://play.google.com/store/apps/details?id=com.paggoapp&hl=es&gl=US&pli=1' target='_blank'>
                        <img width={ width > 800 ? (width * 0.11) : (width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoGooglePlay2024} alt="" />
                      </a>
                      <a href='https://apps.apple.com/gt/app/paggo/id1611051340' target='_blank'>
                        <img width={ width > 800 ? (width * 0.11) : (width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoAppStore2024} alt="" />
                      </a>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{padding:'14px'}}>
                  
                </Grid>
              </>
              )
              :
            (
            
              <Grid container >
                <Grid item xs={12}>
                  <Box
                    mt={1}
                    mb={2}
                    component={Paper}
                  >
                    <Grid container style={{ padding: "0em 1em 0em 1em" }}>
                      <Grid item xs={12} >
                        <Stepper  activeStep={activeStep} connector={<QontoConnector />}>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel >{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    </Grid>
                    <Grid container style={{ padding: "0em 3em 3em 3em" }}>
                      {activeStep === 0 && 
                        <>
                          <Grid item xs={12} >
                            <Typography variant='h5' style={{ textAlign: 'left', fontWeight: 'bold' }}>
                              Link de Paggo
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            {montoInput()}
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            {conceptoInput()}
                          </Grid>
                          
                            <Grid item xs={12} style={{ paddingTop: '2em' }} >
                              {habilitarCuotas(negocioStatusFlags) &&
                                <Grid  container alignItems="center" spacing={1}>
                                    <Grid item>
                                      <Typography variant='h5' style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        Habilitar cuotas
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                          checked={cuotas}
                                          onChange={e => {
                                            myDataIterator();
                                            setCuotas(!cuotas);
                                            setCantidadCuotas(undefined);
                                            setValues({...values, numeroDeVisaCuotas: undefined})
                                            setSelectedCoutasText('');
                                          }}
                                          name="activar_cuenta"
                                          color="primary"
                                        />
                                    </Grid>
                                </Grid>
                              }
                            </Grid>
                            {cuotas &&
                              <Grid item xs={12} style={{ paddingTop: '2em' }} >
                                <Grid  container alignItems="center" spacing={2}>
                                  <Grid item xs={12} md={4}>
                                    <Button onClick={()=>{
                                      setShowCuotasDialog(true);
                                      setCuotasType('OTRAS');
                                      setSelectedCoutasText('');
                                    }} 
                                    variant="outlined" fullWidth size="large" color="primary" 
                                    className={
                                      cuotasType === 'OTRAS' &&
                                      selectedCoutasText.length > 0
                                        ? classes.buttonCuotasSelected
                                        : classes.buttonCuotas
                                    }>
                                      <strong>
                                        {cuotasType === 'OTRAS' &&
                                        selectedCoutasText.length > 0
                                          ? `${selectedCoutasText}`
                                          : 'Todas las cuotas'}
                                      </strong>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Button onClick={()=>{
                                      setShowCuotasDialog(true);
                                      setCuotasType('BAM');
                                      setSelectedCoutasText('');
                                    }} 
                                    variant="outlined" fullWidth size="large" color="primary" 
                                    className={
                                      cuotasType === 'BAM' &&
                                      selectedCoutasText.length > 0
                                        ? classes.buttonCuotasSelected
                                        : classes.buttonCuotas
                                    }>
                                      <strong>
                                        {cuotasType === 'BAM' &&
                                        selectedCoutasText.length > 0
                                          ? `${selectedCoutasText} BAM`
                                          : 'Cuotas BAM'}
                                      </strong>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Button onClick={()=>{
                                      setShowCuotasDialog(true);
                                      setCuotasType('PROMERICA');
                                      setSelectedCoutasText('');
                                    }} 
                                    variant="outlined" fullWidth size="large" color="primary" 
                                    className={
                                      cuotasType === 'PROMERICA' &&
                                      selectedCoutasText.length > 0
                                        ? classes.buttonCuotasSelected
                                        : classes.buttonCuotas
                                    }>
                                      <strong>
                                        {cuotasType === 'PROMERICA' &&
                                        selectedCoutasText.length > 0
                                          ? `${selectedCoutasText} Promerica`
                                          : 'Cuotas Promerica'}
                                      </strong>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Button onClick={()=>{
                                      setShowCuotasDialog(true);
                                      setCuotasType('BI');
                                      setSelectedCoutasText('');
                                    }} 
                                    variant="outlined" fullWidth size="large" color="primary" 
                                    className={
                                      cuotasType === 'BI' &&
                                      selectedCoutasText.length > 0
                                        ? classes.buttonCuotasSelected
                                        : classes.buttonCuotas
                                    }>
                                      <strong>
                                        {cuotasType === 'BI' &&
                                        selectedCoutasText.length > 0
                                          ? `${selectedCoutasText} BI`
                                          : 'Cuotas BI'}
                                      </strong>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Button onClick={()=>{
                                      setShowCuotasDialog(true);
                                      setCuotasType('BANTRAB');
                                      setSelectedCoutasText('');
                                    }} 
                                    variant="outlined" fullWidth size="large" color="primary" 
                                    className={
                                      cuotasType === 'BANTRAB' &&
                                      selectedCoutasText.length > 0
                                        ? classes.buttonCuotasSelected
                                        : classes.buttonCuotas
                                    }>
                                      <strong>
                                        {cuotasType === 'BANTRAB' &&
                                        selectedCoutasText.length > 0
                                          ? `${selectedCoutasText} Bantrab`
                                          : 'Cuotas Bantrab'}
                                      </strong>
                                    </Button>
                                  </Grid>

                                </Grid>
                              </Grid>
                            }
                            <Grid item xs={12} style={{ paddingTop: '3em', paddingBottom: '2em', textAlign: 'center' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleNext}
                                fullWidth={width <= 800 ? true : false}
                                className={width <= 800 ? classes.button : classes.buttonNoFull}
                              >
                                {activeStep === steps.length - 1 ? 
                                'Terminar' : 
                                `Continuar ${
                                  selectedCoutasText.length > 0
                                    ? `con ${selectedCoutasText}`
                                    : 'sin cuotas'
                                }`
                                }
                              </Button>
                            </Grid>
                        </>
                      }
                      {activeStep >= 1 && 
                        <>
                          <Grid item xs={12} >
                            <Typography variant='h5' style={{ textAlign: 'left', fontWeight: 'bold' }}>
                              Link de Paggo
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              ¡Link generado! Tiene validez de 48 horas para ser cobrado.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%', wordWrap: 'break-word'}}>
                            <Typography variant='h6' style={{ textAlign: 'left', fontWeight: 'bold' }}>
                              <a href={pagoEnlace?.link} style={{paddingRight:'1em'}} target='_blank'>{pagoEnlace?.link}</a> 
                              <Button onClick={handleClickCopy} className={`${classes.containerFileCopy}`}>
                                <FileCopyTwoToneIcon className={classes.filecopy}  />
                              </Button>
                              
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              <strong>Monto</strong>
                            </Typography>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              {negocioStatusFlags?.tipoNegocio?.pais?.moneda} {Utilities.formatoMoneda(values.monto)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: '2%'}}>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              <strong>Concepto</strong>
                            </Typography>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              {values.concepto}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: '2em', paddingBottom: '1em', textAlign: 'center' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth={width <= 800 ? true : false}
                                className={width <= 800 ? classes.button : classes.buttonNoFull}
                                onClick={() => window.location.reload()}
                              >
                                Terminar
                              </Button>
                            </Grid>
                          {/*<Grid item xs={12} style={{paddingTop: '1%'}}>
                            <Typography variant='h6' style={{ textAlign: 'left' }}>
                              ¿Quieres administrar tus links? <Link to={'/home/transacciones/enlace'}><strong>Ve a Administrar Links</strong></Link>
                            </Typography>
                          </Grid>*/}
                        </>
                      }
                      
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            
            )
            }
          </>
          }

        <Dialog
          maxWidth={'xs'}
          open={showCuotasDialog}
          onClose={()=>{
            setShowCuotasDialog(false);
            setCantidadCuotas(!selectedCoutasText ? '' : cantidadCuotas);
            setValues({...values, numeroDeVisaCuotas: !selectedCoutasText ? '' : cantidadCuotas})
            setCuotasType(!selectedCoutasText ? '' : cuotasType);
          }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            <Typography variant='h5' style={{ textAlign: 'center', fontWeight: 'bold' }}> 
              Seleccionar cuotas
              <div className={classes.cuotasSubtitle}>  
              {cuotasType === 'OTRAS'
                ? 'Para todas las tarjetas'
                : `Para tarjetas ${
                    cuotasType.substr(0, 1).toUpperCase() +
                    cuotasType.substr(1).toLowerCase()
                  }`}
              </div>
            </Typography>
            
          </DialogTitle>
          <DialogContent>
            

            <List component="nav" aria-label="main mailbox folders">
              {datosCuotas
                .slice(
                  cuotasType === 'OTRAS' ? 0 : 7,
                  ['BAM', 'PROMERICA', 'BANTRAB', 'BI'].includes(
                    cuotasType,
                  )
                    ? 10
                    : 7,
                )
                .map(item => (
                  <ListItem button key={`listitem_${item.title}`} onClick={() => {
                    selectedDue(item);
                    setShowCuotasDialog(false);
                  }}>
                    <ListItemIcon key={`listitemicon_${item.title}`}>
                      <Chip label={item?.title?.substring(0, 2).trim() ?? '0'} style={{ fontSize: '1.5em', fontWeight: 'bold'}} />
                    </ListItemIcon>
                    <ListItemText key={`listitemtext_${item.title}`}>
                      {item?.title?.substr(2)?.trim() ?? ''} ({item.subtitle})
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
            
          </DialogContent>
          
        </Dialog>
        <Dialog
          maxWidth={'xs'}
          open={showUsuarioDesactivado}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            <Typography variant='h5' style={{ textAlign: 'center', fontWeight: 'bold' }}> 
              Usuario desactivado
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='h6' style={{ textAlign: 'center' }}>
              Por favor comunicarse con servicio al cliente para mayor información.
            </Typography>
          </DialogContent>
          <DialogActions style={{textAlign: 'center'}}>
            <Button variant="contained" onClick={() => {
              window.location.href = '/';
            }} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          maxWidth={'xs'}
          open={showCheckLimits}
          aria-labelledby="max-width-dialog-title"
          onClose={() => setShowCheckLimits(false)}
          
        >
          <DialogTitle id="max-width-dialog-title">
            <Typography variant='h5' style={{ textAlign: 'center', fontWeight: 'bold' }}> 
              {titleCheckLimits}
            </Typography>
          </DialogTitle>
          <DialogContent style={{padding: '2em'}}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>
              {descriptionLimits}
            </Typography>
            <div style={{textAlign: 'center', paddingTop: '2em'}}>
              <a href='https://play.google.com/store/apps/details?id=com.paggoapp&hl=es&gl=US&pli=1' target='_blank'>
                <img width={ width > 800 ? (width * 0.11) : (width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoGooglePlay2024} alt="" />
              </a>
              <a href='https://apps.apple.com/gt/app/paggo/id1611051340' target='_blank'>
                <img width={ width > 800 ? (width * 0.11) : (width * 0.3)} style={{padding:'10px'}} src={Images.instance.logoAppStore2024} alt="" />
              </a>
            </div>
          </DialogContent>
          <DialogActions style={{textAlign: 'center', paddingBottom: '2em', paddingRight: '2em'}}>
            <Button variant="contained" onClick={() => setShowCheckLimits(false)} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </>
      }
      <PageLoader loading={loadingPage} />
      </Grid>
    
  )
}

export default LinkPaggoCreacion