import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

interface PageLoaderProps {
  loading?: boolean;
}

const useStyles = makeStyles(theme => ({
  loader: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));

export default function PageLoader({ loading = false }: PageLoaderProps) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop
        className={classes.loader}
        open={loading}
      >
        <CircularProgress color='primary' size={60} />
      </Backdrop>
    </div>
  );
}
