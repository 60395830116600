import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Route, useRouteMatch } from 'react-router-dom';
import './AdminHome.css';
import CrudService from '../services/CrudService';
import { Rutas } from '../resources/Statics';
import { GeneralContext } from '../context/GeneralContext'
import AppBarAdmin from './AppBarAdmin';

// Componentes
import UsersCreate from '../pages/Users/UsersCreate';
import Users from '../pages/Users/Users';
import Logs from '../pages/Logs/Logs';
import UsersEdit from '../pages/Users/UsersEdit';
import Negocios from '../pages/Negocios/Negocios';
import { FacturacionNegocios } from './FacturacionNegocios';
import NegociosCreate from '../pages/Negocios/NegociosCreate';
import NegociosEdit from '../pages/Negocios/NegociosEdit';
import Transacciones from '../pages/Reportes/Transacciones';
import Marketing from '../pages/Marketing/Marketing';
import MarketingCreate from '../pages/Marketing/MarketingCreate';
import MarketingEdit from '../pages/Marketing/MarketingEdit';
import Contacts from '../pages/Contacts/Contacts';
import ContactsCreate from '../pages/Contacts/ContactsCreate';
import Contracargos from '../pages/Contracargos/Contracargos';
import LiquidacionesTransacciones from '../pages/Liquidaciones/LiquidacionesTransacciones';
import LiquidacionesNegocio from '../pages/Liquidaciones/LiquidacionesNegocio';
import DetalleLiquidaciones from '../pages/Liquidaciones/DetalleLiquidaciones';
import LiquidacionesNegocios from '../pages/Liquidaciones/LiquidacionesNegocios';
import CrearLiquidacion from '../pages/Liquidaciones/CrearLiquidacion';
import Visualizacion from '../pages/Liquidaciones/Visualizacion';
import VisualizacionLiquidacionesHonduras from '../pages/LiquidacionesHonduras/VisualizacionLiquidacionesHonduras';
import TransaccionesGenerales from "../pages/Reportes/TransaccionesGenerales";
import MposReports from '../pages/Mpos/MposReports';
import FacturasNegocios from '../pages/Reportes/FacturasNegocios'
import Dealers from '../pages/Dealers/Dealers';
import DealerCreate from '../pages/Dealers/DealersCreate';
import PosInventory from '../pages/Inventory/Pos';
import PosInventoryEdit from '../pages/Inventory/PosEdit';
import TransaccionesGeneralesHonduras from '../pages/Reportes/TransaccionesGeneralesHonduras';
import LiquidacionesTransaccionesHonduras from '../pages/LiquidacionesHonduras/LiquidacionesTransaccionesHonduras';
import LiquidacionesNegocioHonduras from '../pages/LiquidacionesHonduras/LiquidacionesNegocioHonduras';
import DetalleLiquidacionesHonduras from '../pages/LiquidacionesHonduras/DetalleLiquidacionesHonduras';
import LiquidacionesNegociosHonduras from '../pages/LiquidacionesHonduras/LiquidacionesNegociosHonduras';
import CrearLiquidacionHonduras from '../pages/LiquidacionesHonduras/CrearLiquidacionHonduras';
import RespaldosRetenidas from '../pages/Retenidas/RespaldosRetenidas';
import PermisosContenedor from '../pages/Configuracion/Permisos/PermisosContenedor';
import Dashboard from './Dashboard/Dashboard';
import TransaccionesEnlace from './Transacciones/TransaccionesEnlace';
import TransaccionesTarjeta from './Transacciones/TransaccionesTarjeta';
import UbicacionInventarioMpos from './Inventory/Ubicaciones/UbicacionInventarioMpos';
import { Grid } from '@material-ui/core';
import Documentos from './Documentos/Documentos';
import TransaccionesEfectivo from './Transacciones/TransaccionesEfectivo';
import LinkPaggoCreacion from './Transacciones/LinkPaggoCreacion';
import Ordenes from './Orders/Ordenes';
import OrdenesCreacionEdicion from './Orders/OrdenesCreacionEdicion';
import MovimientosInventarioMposUbicacion from './Movimientos/MovimientosInventarioMposUbicacion';
import LoteInventarioMpos from './Inventory/Lotes/LoteInventarioMpos';
import SolicitudesProductos from './SolicitudesProductos/SolicitudesProductos';
import CrearSolicitud from './SolicitudesProductos/CrearSolicitud';
import SolicitarPos from './Movimientos/SolicitarPos';
import SolicitarPosOne from './Movimientos/SolicitarPosOne';
import AtenderSolicitudes from './Movimientos/AtenderSolicitudes';
import AtenderSolicitudesOne from './Movimientos/AtenderSolicitudesOne';
import AprobarMovimientos from './Movimientos/AprobarMovimientos';
import AprobarMovimientosOne from './Movimientos/AprobarMovimientosOne';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#5166AF',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    logo: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      height: 'auto',
      width: '100%',
      padding: '0px 24px 24px 24px',
    },
  }),
);

interface Props {
  window?: () => Window;
}


interface Menu {
  id: number;
  label: string;
  path: string;
  componente: string;
  icon: string;
  submenus: Menu[];
  isExterno: boolean;
}

type ComponenteAdminKey = 'UsersCreate' | 'Users' | 'Logs' | 'UsersEdit' | 'Negocios' | 'NegociosCreate' | 'NegociosEdit' | 'Transacciones' | 'Marketing' | 'MarketingCreate' | 'MarketingEdit' | 'Contacts' | 'ContactsCreate' | 'Contracargos' | 'LiquidacionesTransacciones' | 'LiquidacionesNegocio' | 'DetalleLiquidaciones' | 'LiquidacionesNegocios' | 'CrearLiquidacion' | 'Visualizacion' | 'VisualizacionLiquidacionesHonduras' | 'TransaccionesGenerales' | 'MposReports' | 'FacturasNegocios' | 'Dealers' | 'DealerCreate' | 'PosInventory' | 'PosInventoryEdit' | 'TransaccionesGeneralesHonduras' | 'LiquidacionesTransaccionesHonduras' | 'LiquidacionesNegocioHonduras' | 'DetalleLiquidacionesHonduras' | 'LiquidacionesNegociosHonduras' | 'CrearLiquidacionHonduras' | 'RespaldosRetenidas' | 'PermisosContenedor'
interface RutaAdmin {
    path: string;
    componente: ComponenteAdminKey;
  }

export default function AdminHome(props: Props) {
  ///const { window } = props;
  let { path } = useRouteMatch();
  const { handleLoading, handleErrorMessage, handleActiveError } = useContext(GeneralContext)
  const classes = useStyles();
  const [routesAdmins, setRoutesAdmins] = useState<RutaAdmin[]>([])
  const [menus, setMenus] = useState<Menu[]>([]);

  useEffect(() => {
    if (routesAdmins.length === 0)
      getRoutes()
    if (menus.length === 0)
      getMenus()
  }, [])

  const getMenus = () => {
      const crudService = CrudService.instance(Rutas.instance.urlRutaMenu + '/menus/render');
      crudService
      .get()
      .then(async response => {
          const { error, result } = response;
          if (!error) {
          setMenus(result);
          } else {
              handleErrorMessage('message');
              handleActiveError(true);
          }
          handleLoading(false);
      })
      .catch(e => {
          handleLoading(false);
          handleErrorMessage('message');
          handleActiveError(true);
      });
  }

  const getRoutes = () => {
      const crudService = CrudService.instance(Rutas.instance.urlRutaMenu + '/rutas/render');
      crudService
        .get()
        .then(async response => {
          const { error, result } = response;
          if (!error) {
            setRoutesAdmins(result);
          } else {
              handleErrorMessage('message');
              handleActiveError(true);
          }
          handleLoading(false);
        })
        .catch(e => {
          handleLoading(false);
          handleErrorMessage('message');
          handleActiveError(true);
        });
  }

  const componentesAdmins: {[key: string]: any} = {
    'UsersCreate': UsersCreate,
    'Users': Users,
    'Logs': Logs,
    'UsersEdit': UsersEdit,
    'Negocios': Negocios,
    'NegociosCreate': NegociosCreate,
    'NegociosEdit': NegociosEdit,
    'Transacciones': Transacciones,
    'Marketing': Marketing,
    'MarketingCreate': MarketingCreate,
    'MarketingEdit': MarketingEdit,
    'Contacts': Contacts,
    'ContactsCreate': ContactsCreate,
    'Contracargos': Contracargos,
    'LiquidacionesTransacciones': LiquidacionesTransacciones,
    'LiquidacionesNegocio': LiquidacionesNegocio,
    'DetalleLiquidaciones': DetalleLiquidaciones,
    'LiquidacionesNegocios': LiquidacionesNegocios,
    'CrearLiquidacion': CrearLiquidacion,
    'Visualizacion': Visualizacion,
    'VisualizacionLiquidacionesHonduras': VisualizacionLiquidacionesHonduras,
    'TransaccionesGenerales': TransaccionesGenerales,
    'MposReports': MposReports,
    'FacturasNegocios': FacturasNegocios,
    'Dealers': Dealers,
    'DealerCreate': DealerCreate,
    'PosInventory': PosInventory,
    'PosInventoryEdit': PosInventoryEdit,
    'TransaccionesGeneralesHonduras': TransaccionesGeneralesHonduras,
    'LiquidacionesTransaccionesHonduras': LiquidacionesTransaccionesHonduras,
    'LiquidacionesNegocioHonduras': LiquidacionesNegocioHonduras,
    'DetalleLiquidacionesHonduras': DetalleLiquidacionesHonduras,
    'LiquidacionesNegociosHonduras': LiquidacionesNegociosHonduras,
    'CrearLiquidacionHonduras': CrearLiquidacionHonduras,
    'RespaldosRetenidas': RespaldosRetenidas,
    'PermisosContenedor': PermisosContenedor,
    'Dashboard': Dashboard,
    'TransaccionesEnlace': TransaccionesEnlace,
    'TransaccionesTarjeta': TransaccionesTarjeta,
    'UbicacionInventarioMpos': UbicacionInventarioMpos,
    'Documentos': Documentos,
    'TransaccionesEfectivo': TransaccionesEfectivo,
    'LinkPaggoCreacion': LinkPaggoCreacion,
    'Ordenes': Ordenes,
    'OrdenesCreacionEdicion': OrdenesCreacionEdicion,
    'MovimientosInventarioMposUbicacion': MovimientosInventarioMposUbicacion,
    'LoteInventarioMpos': LoteInventarioMpos,
    'SolicitarPos': SolicitarPos,
    'SolicitarPosOne': SolicitarPosOne,
    'AtenderSolicitudes': AtenderSolicitudes,
    'AtenderSolicitudesOne': AtenderSolicitudesOne,
    'AprobarMovimientos': AprobarMovimientos,
    'AprobarMovimientosOne': AprobarMovimientosOne,
    'FacturacionNegocios': FacturacionNegocios
  }

  return (
      
      <Grid container>
        <Grid item xs={12} sm={12} lg={2} className={classes.content}>
          <AppBarAdmin menus={menus} />
        </Grid>
        <Grid item xs={12} sm={12} lg={10} className={classes.content}>
          <div className={classes.toolbar} />
          {/* RUTAS ADMINS DINAMICAS  */}
          {routesAdmins && routesAdmins.length > 0 && routesAdmins.map((ruta, index) => {
            const Componente = componentesAdmins[ruta.componente];
              return (
                <Route exact path={`${path}${ruta.path}`} key={"route_"+index}>
                  { Componente ? <Componente key={'componente'+index} /> : <React.Fragment key={'componente'+index} /> }
                </Route>
              )
          })}
          <Route exact path="/admin-home/solicitudes/productos">
            <SolicitudesProductos />
          </Route>
          <Route exact path="/admin-home/solicitudes/productos/crear">
            <CrearSolicitud />
          </Route>
        </Grid>
      </Grid>
  );
}
