import moment from 'moment';

export default class Utilities {
  static instance = new Utilities();

  aproximaDecimal(n: number, decimales: number) {
    return Math.round((n + Number.EPSILON) * Math.pow(10, decimales)) / Math.pow(10, decimales);
  }

  static convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
    var fecha;
    if (formatoEntrada === 'date') fecha = moment(fecha);
    else fecha = moment(fecha, formatoEntrada);

    var res;
    if (formatoSalida === 'date') res = fecha;
    else res = fecha.format(formatoSalida);
    return res;
  }

  static dues(due?: string) {
    switch (due) {
      case 'VC02':
        return '2 cuotas';
      case 'VC03':
        return '3 cuotas';
      case 'VC06':
        return '6 cuotas';
      case 'VC10':
        return '10 cuotas';
      case 'VC12':
        return '12 cuotas';
      case 'VC15':
        return '15 cuotas';
      case 'VC18':
        return '18 cuotas';
      case 'VC24':
        return '24 cuotas';
      case 'VC36':
        return '36 cuotas';
      case 'VC48':
        return '48 cuotas';
      default:
        return 'Contado';
    }
  }
  static formatoMonto = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static formatoMoneda = (x: number) => {
    let monto = '0.00';
    if (!x || isNaN(x)) return monto;
    let montoSinParsear = (+x).toFixed(2);
    let parteEnteraMonto = this.formatoMonto(+montoSinParsear.split('.')[0]);
    let parteDecimal = montoSinParsear.split('.')[1];
    monto = `${parteEnteraMonto}.${parteDecimal}`;
    return monto;
  };

  static formatoMonedaSinDecimal = (x: number) => {
    let monto = '0.00';
    if (!x || isNaN(x)) return monto;
    let montoSinParsear = (+x).toFixed(2);
    let parteEnteraMonto = this.formatoMonto(+montoSinParsear.split('.')[0]);
    monto = `${parteEnteraMonto}`;
    return monto;
  };

  public formatCurrency = (num?: number) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
