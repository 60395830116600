import { DropzoneDialog } from 'material-ui-dropzone';
import * as momentZone from 'moment-timezone';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import './style.css';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Switch,
  Link,
  Popover,
  ListSubheader,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  DialogContent,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import TextAreaAutoSize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import { Transition } from 'react-transition-group';
import {
  BluetoothTwoTone as Bluetooth,
  CancelTwoTone as Cancel,
  CheckTwoTone as Check,
  CloudUploadTwoTone as CloudUpload,
  DeleteTwoTone as Delete,
  DescriptionTwoTone,
  EditTwoTone as Edit,
  Label,
  OpenInBrowserTwoTone as OpenInBrowser,
  PanToolTwoTone as PanTool,
  ReportTwoTone as Report,
  SaveOutlined,
} from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/CheckTwoTone';
import BlockIcon from '@material-ui/icons/BlockTwoTone';
import ClearIcon from '@material-ui/icons/ClearTwoTone';
import RefreshIcon from '@material-ui/icons/RefreshTwoTone';
import HistoryIcon from '@material-ui/icons/HistoryTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeftTwoTone';
import { Autocomplete, ToggleButton, ToggleButtonGroup, Pagination } from '@material-ui/lab';
import { BlobProvider, Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { TabVerification } from './components/ModalVerification';

import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import { GeneralContext } from '../../context/GeneralContext';
import {
  InformacionBancaria,
  ListadoMotivos,
  MposInterface,
  NegocioWithTipoNegocioInterface,
  TipoNegocio,
  UserInterface,
  rolUsuario,
  VerificationJsonInterface,
  FacturacionNegocios,
  EstablecimientosNegocio,
} from '../../interfaces/Interfaces';
import { DiscoveOrDisConnect } from '../../libs/MposService';
import Utilities from '../../libs/utilities';
import ValidationFields from '../../libs/validationFields';
import {
  departamentos,
  getMensajeDeErrorVisa,
  getResponseCodeMessageEpayRetail,
  listabancos,
  listaBancosHonduras,
  listamunicipios,
  paggoAfiliations,
  Rutas,
  settlementStatusBusiness,
  temporalNegocioLimites,
  tiposcuenta,
  afiliacionApayRetailGuatemala,
  afiliacionApayRetailHonduras,
  subcategorias,
} from '../../resources/Statics';
import DocumentosService from '../../services/DocumentosService';
import LinkService from '../../services/LinkService';
import MposService from '../../services/MposService';
import NegociosServices from '../../services/NegociosServices';
import RegisterService from '../../services/RegisterService';
import UsersServices from '../../services/UsersServices';
import { Images } from '../../styles/images';
import RazonesService from '../../services/RazonesService';
import VerificationService from '../../services/VerificationService';
import localStorage from '../../libs/localStorage';
import Logs from '../../pages/Logs/Logs';
import DocumentosRespaldoService from '../../services/DocumentosRespaldoService';
import CrudService from '../../services/CrudService';
import ModalConfirm from '../../components/ModalConfirm';
import FELService from '../../services/FEL';
import { render } from '@testing-library/react';

const EventEmitter = require('reactjs-eventemitter');

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  keyTab: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '70vw',
    },
    imageList: {
      width: 'auto',
      height: 600,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 300,
    },
    modalComision: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 'auto',
    },
    transitionGrid: {
      transition: 'margin-right 0.3s ease-in-out', // Cambiado a 'right'
      position: 'fixed', // Agregado para fijar la posición
      top: 0, // Otra propiedad de posición fija para ajustar según tus necesidades
      bottom: 0, // Otra propiedad de posición fija para ajustar según tus necesidades
    },
    archivos: {
      marginRight: 0, // Inicialmente oculto
    },
    button: {
      margin: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    inputComision: {
      '& input': {
        textAlign: 'center',
      },
    },
  }),
);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 50,
  },
  title: {
    justifyContent: 'center',
    marginBottom: 20,
    textAlign: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  content: {
    fontSize: 12,
  },
  contentRow: {
    fontSize: 12,
    width: '25%',
    maxWidth: '25%',
    padding: '5px',
    border: '1px solid #777',
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    padding: '9px 12px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  contentRowTitle: {
    fontSize: 12,
    width: '25%',
    maxWidth: '25%',
    padding: '5px',
    color: '#fff',
    backgroundColor: '#5166AF',
  },
  textContent: {
    fontSize: 12,
    marginBottom: 3,
  },
  subContent: {
    padding: '9px 12px',
  },
  sectionsContainer: {
    marginTop: '9px',
  },
  titleSection: {
    marginBottom: 19,
    borderRadius: '5px',
    border: '1px solid #777',
    borderTop: 'none',
    overflow: 'hidden',
  },
  subtitle: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 'bold',
    padding: '6px 12px',
    backgroundColor: '#5166AF',
  },
  subsubtitle: {
    fontSize: 13,
    color: '#fff',
    fontWeight: 'bold',
    padding: '6px 12px',
    backgroundColor: 'hsl(227deg 37% 60%)',
  },
  image: {
    width: 200,
    margin: '0 auto',
    marginBottom: 25,
  },
});

function numeroDeSerieMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

numeroDeSerieMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const now = new Date();

interface RouteParams {
  id: string;
}

const TABS = [
  'DATA',
  'CREDENCIALES',
  'POS',
  'INFORMACION_BANCARIA',
  'TRANSACCIONES_ENLACE',
  'TRANSACCIONES_TARJETA',
  'QR',
  'CONTRATO',
  'VERIFICATION_DATA',
  'ACCIONES',
  'FACTURACION',
];

export default function NegociosEdit() {
  const { id } = useParams<RouteParams>();
  const {
    loading: generalLoading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    selectedRecord,
  } = useContext(GeneralContext);

  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openModalComision, setOpenModalComision] = React.useState(false);
  const [openImagesModal, setOpenImagesModal] = React.useState(false);
  const [openLiquidationDetailModal, setOpenLiquidationDetailModal] = React.useState(false);
  const [openModalFile, setOpenModalFile] = React.useState(false);
  const [openModalFileContrato, setOpenModalFileContrato] = React.useState(false);
  const [openModalContrato, setOpenModalContrato] = React.useState(false);
  const [openModalMpos, setOpenModalMpos] = React.useState(false);
  const [openModalEditMpos, setOpenModalEditMpos] = React.useState(false);
  const [openConfirmDeleteMpos, setOpenConfirmDeleteMpos] = React.useState(false);
  const [confirmWord, setConfirmWord] = useState('');
  const [openModalGenerateReport, setOpenModalGenerateReport] = useState(false);
  const [openConfirmCloseTransactions, setOpenConfirmCloseTransactions] = React.useState(false);
  const [reportPDFEpayRetail, setReportPDFEpayRetail] = useState<any>();
  const [showClosedTransactions, setShowClosedTransactions] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  //const [loadingAux, setLoadingAux] = React.useState(true);
  const [valTabDocuments, setValTabDocuments] = React.useState(0);

  const [tipoDocumento, setTipoDocumento] = React.useState('');

  const [activities, setActivities] = useState([]);
  const [subActivities, setSubActivities] = useState([]);
  const [pageSelected, setPageSelected] = React.useState(0);
  const [pageSelectedName, setPageSelectedName] = React.useState('');

  const [uploading, setUploading] = React.useState(false);
  const [openUploadFile, setOpenUploadFile] = React.useState(false);

  const [tipoDocumentoSelected, setTipoDocumentoSelected] = React.useState('rtu');
  const [documentoIdSelected, setDocumentoIdSelected] = React.useState(0);
  const [documentoRespaldoIdSelected, setDocumentoRespaldoIdSelected] = React.useState(0);

  const [documentoDocTipoIdSelected, setDocumentoDocTipoIdSelected] = React.useState(0);

  const [UsuarioId, setUsuarioId] = React.useState(0);
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowsDocumentosRespaldo, setRowsDocumentosRespaldo] = React.useState<any[]>([]);
  const [rowsMpos, setRowsMpos] = React.useState<MposInterface[]>([]);
  const [rowsHistory, setRowsHistory] = React.useState<any[]>([]);
  const [rowsHistorialDocumentosRespaldo, setRowsHistorialDocumentosRespaldo] = React.useState<any[]>([]);

  const [rowsClosedTransactions, setRowsClosedTransactions] = React.useState([]);
  const [rowsHistorialCredenciales, setRowsHistorialCredenciales] = useState([]);

  const [rowsTransaccionesTarjeta, setRowsTransaccionesTarjeta] = React.useState<any[]>([]);
  const [rowsTransaccionesEnlace, setRowsTransaccionesEnlace] = React.useState<any[]>([]);
  const [rowsLiquidacionDetails, setRowsLiquidacionDetails] = React.useState<any[]>([]);
  const [columnsLiquidacionDetails, setColumnsLiquidacionDetails] = React.useState<any[]>([]);
  const [rowsContrato, setRowsContrato] = React.useState<UserInterface[]>([]);
  const [tipoDocumentos, setTipoDocumentos] = React.useState<any[]>([]);

  const [arrayImages, setArrayImages] = React.useState<any[]>([]);

  const [show, setShow] = useState(false);
  const [messageRejected, setMessageRejected] = useState<string>('');

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [linkRapido, setLinkRapido] = useState(null);
  const [openCredenciales, setOpenCredenciales] = React.useState(false);
  const [openHistorialRazones, setOpenHistorialRazones] = useState(false);

  const [pageEnlace, setPageEnlace] = useState<number>(1);
  const [pageSizeEnlace, setPageSizeEnlace] = useState<number>(6);
  const [totalPagesEnlace, setTotalPagesEnlace] = useState<number>(1);
  const [searchValueEnlace, setSearchValueEnlace] = useState('');

  const [pageTarjeta, setPageTarjeta] = useState<number>(1);
  const [pageSizeTarjeta, setPageSizeTarjeta] = useState<number>(6);
  const [totalPagesTarjeta, setTotalPagesTarjeta] = useState<number>(1);
  const [searchValueTarjeta, setSearchValueTarjeta] = useState('');
  const [comisionPaggoConDescuento, setComisionPaggoConDescuento] = useState<number>(0);
  const [comisionTotal, setComisionTotal] = useState<number>(0);
  const [comisionPaggo, setComisionPaggo] = useState<number>(0);
  const [showArchivos, setShowArchivos] = useState(false);
  const [tipoNegocioTemp, setTipoNegocioTemp] = useState<Record<string, string>>({});
  const [permisos, setPermisos] = useState<any[]>([]);
  const [tabsNoMostrar, setTabsNoMostrar] = useState<any[]>([]);
  const [tabsDinamicos, setTabsDinamicos] = useState<any[]>([]);
  const [verificationJson, setVerificationJson] = useState<VerificationJsonInterface>();
  const [rowsComisionDetalle, setRowsComisionDetalle] = useState<any>({
    tieneRetencion: false,
    descuentoPaggo: 0,
    ivaDeLasVentas: 0,
    ventasSinIva: 0,
    ivaTotal: 0,
    liquidoAPagar: 0,
    ivaTotalPorcentaje: 0,
    comisionTotal: 0,
    retencion: 0,
  });
  const [subActividad, setSubActividad] = useState(0);
  const [tiposRegimenes, setTiposRegimenes] = useState<any[]>([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState<string>('');
  const [loadingComision, setLoadingComision] = useState(false);
  const [openCambioTipoNegocio, setOpenCambioTipoNegocio] = useState(false);
  const [openCambioComision, setOpenCambioComision] = useState(false);
  const [tipoNegocioPreSeleccionado, setTipoNegocioPreSeleccionado] = useState<any>();
  const [textoConfirmacionCambioTipoNegocio, setTextoConfirmacionCambioTipoNegocio] = useState('');

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
  });

  const [values, setValues] = useState<NegocioWithTipoNegocioInterface>({
    id: 0,
    nombre: '',
    razonSocial: '',
    numeroCasa: '',
    departamento: '',
    municipio: '',
    telefono: '',
    telefonoAdicional: '',
    nit: '',
    status: '',
    eliminado: false,
    afiliacionVisa: '',
    terminalVisa: '',
    userVisa: '',
    passwordVisa: '',
    ActividadId: 0,
    SubActividadId: 0,
    descuentoPaggo: 0,
    comisionTotal: 0,
    tipoNegocio: {} as TipoNegocio,
    rolUsuario: [],
    TipoNegocioId: 0,
    TipoRegimenId: 0,
    acceptTerms: false,
    PayfacId: '',
    numeroColegiado: '',
    createdAt: now,
    updatedAt: now,
    disabledCredentials: false,
    verificacionMetamap: {
      verificacionId: '',
    },
  });

  // useEffect(() => {
  //   console.log('values', values)
  //   if (values && values?.TipoRegimenId || values?.TipoRegimenId === undefined) {
  //     editNegocio()
  //   }
  // }, [values.TipoRegimenId]);

  const [razonCredenciales, setRazonCredenciales] = useState('');

  const [documentosDetails, setDocumentosDetails] = useState<any[]>([]);

  const [linksDocumentosRazones, setLinksDocumentosRazones] = useState([]);

  const [mposValues, setMposValues] = useState<MposInterface>({
    NegocioId: 0,
    numeroDeSerie: '',
    terminalEpayRetail: '',
    afiliacionEpayRetail: paggoAfiliations,
    merchantUserEpayRetail: '',
    merchantPasswordEpayRetail: '',
  });

  const [informacionBancaria, setInformacionBancaria] = useState<InformacionBancaria>({
    id: 0,
    banco: '',
    numeroCuenta: '',
    tipoCuenta: '',
    nombreCuenta: '',
    NegocioId: 0,
  });

  const [listadoMotivos, setListadoMotivos] = useState<ListadoMotivos[]>([]);

  const [motivoTemp, setMotivoTemp] = useState<any[]>([]);

  const [verifications, setVerifications] = useState([]);

  const [facturacion, setFacturacion] = useState<FacturacionNegocios | null>(null);
  const [establecimientos, setEstablecimientos] = useState<EstablecimientosNegocio | null>(null);
  const [facturacionLoading, setFacturacionLoading] = useState<boolean>(false);
  const [facturacionUpload, setFacturacionUpload] = useState<boolean>(false);

  const {
    nombre: establecimientoNombre,
    direccion: establecimientoDireccion,
    codigo: establecimientoCodigo,
    codigoPostal: establecimientoCodigoPostal,
    departamento: establecimientoDepartamento,
    municipio: establecimientoMunicipio,
  } = facturacion?.negocio?.establecimientosNegocio?.[0] || {};
  useEffect(() => {
    setEstablecimientos(prev => ({
      ...prev,
      nombre: establecimientoNombre,
      direccion: establecimientoDireccion,
      codigo: establecimientoCodigo,
      codigoPostal: establecimientoCodigoPostal,
      departamento: establecimientoDepartamento,
      municipio: establecimientoMunicipio,
    }));
  }, [
    establecimientoNombre,
    establecimientoDireccion,
    establecimientoCodigo,
    establecimientoCodigoPostal,
    establecimientoDepartamento,
    establecimientoMunicipio,
  ]);

  const onChangeFacturacion = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFacturacion(prev => ({
      ...prev,
      estado: prev?.estado || 'deshabilitado',
      [ev.target.name]: ev.target.value,
    }));
  };

  const onChangeEstablecimientos = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEstablecimientos(prev => ({
      ...prev,

      [ev.target.name.replace('establecimiento-', '')]: ev.target.value,
    }));
  };

  // useEffect(() => {
  //   async function start() {
  //     // FILTRAR REQ.RESULT POR CATEGORIA VISA PAYFAC
  //     console.log('activities', activities)
  //     console.log('values', values)
  //     //if (activities.length === 0) {
  //     // if (values?.tipoNegocio?.categoriaVisa && values?.ActividadId) {
  //     //   let reqsub = await RegisterService.instance.getSubActivitiesByActivityId(values?.ActividadId);
  //     //   setSubActivities(reqsub.result);
  //     // }
  //     if (activities.length === 0 && values?.tipoNegocio?.categoriaVisa ) {
  //       let req = await RegisterService.instance.getActivitiesByCategoryVisa(values?.tipoNegocio?.categoriaVisa);
  //       req.result = req.result.filter((item: any) => item.categoriaVisa === values?.tipoNegocio?.categoriaVisa);
  //       setActivities(req.result);
  //     }
  //     //}
  //     setLoadingAux(false);
  //   }
  //   start();
  // }, [values?.tipoNegocio?.categoriaVisa, activities]);

  useEffect(() => {
    let resultado = TABS.filter(elemento => !tabsNoMostrar.includes(elemento));
    setTabsDinamicos(resultado);
  }, [tabsNoMostrar]);

  useEffect(() => {
    async function start() {
      let reqsub = await RegisterService.instance.getSubActivitiesByActivityId(values?.ActividadId);
      setSubActivities(reqsub.result);
    }
    start();
  }, [values.ActividadId]);

  useEffect(() => {
    if (values?.verificacionMetamap?.verificacionId) {
      getVerificationData();
    }
  }, [values?.verificacionMetamap?.verificacionId]);

  useEffect(() => {
    if (selectedRecord.id > 0) {
      getTransaccionesEnlace(selectedRecord.id);
    }
  }, [pageEnlace, pageSizeEnlace]);

  useEffect(() => {
    if (selectedRecord.id > 0) {
      getTransaccionesTarjeta(selectedRecord.id);
    }
  }, [pageTarjeta, pageSizeTarjeta]);

  useEffect(() => {
    if (selectedRecord.id > 0) {
      obtenerPermisos();
      cargaData();
    } else {
      if (id) getNegocioDetalle(parseInt(id));
    }

    () => {
      handleSelectRecord({});
    };
  }, [selectedRecord.id]);

  const handleToggle = () => {
    setShowArchivos(!showArchivos);
  };

  const obtenerPermisos = async () => {
    const usuario = await localStorage.instance.getUserParseado();
    if (usuario && usuario.rolUsuario && usuario.rolUsuario[0].rol && usuario.rolUsuario[0].rol.permisos)
      setPermisos(JSON.parse(usuario.rolUsuario[0].rol.permisos) || []);
  };

  useEffect(() => {
    if (permisos.length > 0) {
      const noshows = permisos
        .filter(elemento => elemento.includes('NOSHOW:NEGOCIO:'))
        .map(elemento => elemento.replace('NOSHOW:NEGOCIO:', ''));
      setTabsNoMostrar(noshows);
    }
  }, [permisos]);

  useEffect(() => {
    setTextoConfirmacionCambioTipoNegocio(
      `¿Estás seguro de seleccionar al tipo de negocio <strong>${tipoNegocioPreSeleccionado?.name}</strong>?`,
    );
    if (
      openCambioTipoNegocio &&
      comisionTotal &&
      tipoNegocioPreSeleccionado &&
      tipoNegocioPreSeleccionado?.comision &&
      tipoNegocioPreSeleccionado?.name
    ) {
      const comisionTipoNegocio = parseFloat(tipoNegocioPreSeleccionado.comision);
      console.log('comisionTipoNegocio', comisionTipoNegocio);
      console.log('comisionTotal', comisionTotal);
      if (comisionTotal !== comisionTipoNegocio) {
        setTextoConfirmacionCambioTipoNegocio(
          `Si seleccionas el tipo de negocio <strong>${tipoNegocioPreSeleccionado.name}</strong>, el Porcentaje de Comisión Total cambiará a <strong>${comisionTipoNegocio}%</strong>.<br/> ¿Estás seguro de continuar?`,
        );
      }
    }
  }, [openCambioTipoNegocio]);

  // useEffect(() => {
  //   if (comisionPaggo > 0 && tipoNegocioTemp) {
  //     const regimen = tipoNegocioTemp[selectedRecord.id];
  //     const params = `?porcentajeComisionPropio=${comisionPaggo}&regimen=${regimen}&monto=100`;
  //     const crudService = CrudService.instance(`${Rutas.instance.urlNegocio}/${selectedRecord.id}/comision${params}`);
  //     crudService.get()
  //     .then(response => {
  //       try {

  //         if (response && response.result) {
  //           setRowsComisionDetalle(response.result);
  //         }

  //       } catch (error: any) {
  //         handleErrorMessage(error.message);
  //         handleActiveError(true);
  //       }
  //       handleLoading(false);
  //     })
  //     .catch(e => {
  //       handleLoading(false);
  //       handleErrorMessage(e.message);
  //       handleActiveError(true);
  //     });
  //   }
  // }, [comisionPaggo,tipoNegocioTemp]);

  const cargaData = () => {
    async function getLinkRapido(selectedRecord: any) {
      if (
        selectedRecord &&
        selectedRecord.rolUsuario &&
        Array.isArray(selectedRecord.rolUsuario) &&
        selectedRecord.rolUsuario[0]
      ) {
        setTipoNegocioTemp(prevTipos => ({ ...prevTipos, [selectedRecord.id]: selectedRecord.tipoNegocio.nombre }));
        let { UsuarioId, NegocioId } = selectedRecord.rolUsuario[0];
        if (UsuarioId && NegocioId) {
          let res = await LinkService.instance.getLinkRapido(UsuarioId, NegocioId);
          if (res && !res.error) {
            setLinkRapido(res.result.link);
          }
        }
      }
    }

    setLoading(true);
    getAllVerifications();
    Promise.all([
      new Promise(resolve => {
        setValues({ ...selectedRecord });
        if (selectedRecord.subActividad && selectedRecord.subActividad.porcentajeComisionPropio) {
          const descuentoPaggo = Number(selectedRecord.descuentoPaggo) / 100;
          let porcentajePaggo: number = Number(selectedRecord.subActividad.porcentajeComisionPropio);
          porcentajePaggo = porcentajePaggo - porcentajePaggo * descuentoPaggo;
          setComisionPaggoConDescuento(Number((porcentajePaggo * 100).toFixed(2)));
          setComisionPaggo(Number(selectedRecord.subActividad.porcentajeComisionPropio));
        }
        let pais = selectedRecord.tipoNegocio.pais.nombre;
        if (pais === 'Guatemala') {
          setMposValues({ ...mposValues, afiliacionEpayRetail: afiliacionApayRetailGuatemala });
        } else if (pais === 'Honduras') {
          setMposValues({ ...mposValues, afiliacionEpayRetail: afiliacionApayRetailHonduras });
        }
        resolve(true);
      }),
      getDocumentos(selectedRecord.id),
      getDocumentosRespaldo(selectedRecord.id),
      getDocumentosHistorial(selectedRecord.id),
      getDocumentosRespaldoHistorial(selectedRecord.id),
      getTipoDocumentos(selectedRecord.id),
      getInformacionBancaria(selectedRecord.id),
      getTransaccionesEnlace(selectedRecord.id),
      getTransaccionesTarjeta(selectedRecord.id),
      getContrato(selectedRecord.id),
      getLinkRapido(selectedRecord),
      getMposById(selectedRecord.id),
      getActivities(),
      getListadoMotivos(),
      setSubActividad(values.SubActividadId),
      getTipoRegimenes(),
      //getDetallesComision()
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error('Alguna de las promesas falló.', error);
      });
  };

  const refreshData = () => {
    handleSelectRecord({});
    if (id) {
      setLoading(true);
      getNegocioDetalle(parseInt(id));
    }
  };

  function setTabDocuments(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabDocuments = (event: any, newValue: number) => {
    setValTabDocuments(newValue);
  };

  const handleOpenModal = () => {
    setOpen(true);
    setMessageRejected('');
  };

  const handleCloseModal = () => {
    setOpen(false);
    setOpenCredenciales(false);
    setOpenHistorialRazones(false);
    setShow(false);
    setOpenModalFile(false);
    setOpenModalFileContrato(false);
    setOpenUploadFile(false);
    setOpenModalContrato(false);
    setOpenModalMpos(false);
    setOpenModalEditMpos(false);
    setOpenConfirmDeleteMpos(false);
    setOpenModalGenerateReport(false);
    setReportPDFEpayRetail(undefined);
    setShowClosedTransactions(false);
    setOpenLiquidationDetailModal(false);
    setConfirmWord('');
    setMposValues({
      NegocioId: 0,
      numeroDeSerie: '',
      terminalEpayRetail: '',
      afiliacionEpayRetail: paggoAfiliations,
      merchantUserEpayRetail: '',
      merchantPasswordEpayRetail: '',
    });
  };

  const getActivities = async () => {
    if (selectedRecord?.tipoNegocio?.categoriaVisa) {
      let req = await RegisterService.instance.getActivitiesByCategoryVisa(selectedRecord?.tipoNegocio?.categoriaVisa);
      req.result = req.result.filter((item: any) => item.categoriaVisa === selectedRecord?.tipoNegocio?.categoriaVisa);
      setActivities(req.result);
    }
  };

  const getTipoRegimenes = async () => {
    const crudService = CrudService.instance(Rutas.instance.urlTiposRegimenes);
    let req = await crudService.getAll();
    setTiposRegimenes(req.result); //henry
  };

  const getListadoMotivos = async () => {
    try {
      //setLoading(true);
      let { result } = await NegociosServices.instance.getListReasons();
      setListadoMotivos(result);

      const motivos: any = [];
      if (
        selectedRecord.NegocioPerdido &&
        Array.isArray(selectedRecord.NegocioPerdido) &&
        selectedRecord.NegocioPerdido.length
      ) {
        selectedRecord.NegocioPerdido.forEach((element: any) => {
          motivos.push(element.motivoPerdida);
        });
      }

      setMotivoTemp(motivos);

      //setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getContrato = async (id: number) => {
    try {
      //setLoading(true);
      let req = await UsersServices.instance.getUserContrato(id);
      if (!req) throw new Error('No se pudo traer el contrato');
      let userArray: UserInterface[] = [];
      userArray.push(req.result.user);

      setRowsContrato(userArray);
      //setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // handleErrorMessage(error.message)
      // handleActiveError(true)
    }
  };

  const getDocumentos = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getNewDocuments(id)
      .then(response => {
        const { error, result, message } = response;
        let filtered = result.filter(
          (value: any, index: number, self: any) =>
            index ===
            self.findIndex(
              (t: any) =>
                t.DocTipoNegocioId === value.DocTipoNegocioId &&
                t.DocTipoNegocioId === value.DocTipoNegocioId &&
                !t.docTipoNegocio.nombre.includes('respaldo_'),
            ),
        );

        if (!error) setRows(filtered);
        else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getDocumentosRespaldo = (id: number) => {
    DocumentosRespaldoService.instance
      .getDocumentosRespaldo(id)
      .then(response => {
        const { error, result, message } = response;
        const documentos = result.filter((doc: any) => doc.NegocioId === id);
        if (!error) setRowsDocumentosRespaldo(documentos);
        else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getNegocioDetalle = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getDetalleNegocio(id)
      .then(response => {
        const { error, result, message } = response;

        if (!error) handleSelectRecord(result);
        else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getMposById = async (NegocioId: any) => {
    let req = await MposService.instance.getMpos(NegocioId);

    if (req && !req.error) {
      setRowsMpos(req.result);
    }
    //setLoading(false)
  };

  const getNegocioDetail = async (NegocioId: any) => {
    let req = await NegociosServices.instance.getById(NegocioId);

    if (req && !req.error) {
      setValues(prevState => {
        return {
          ...prevState,
          afiliacionVisa: req.result[0].afiliacionVisa,
          terminalVisa: req.result[0].terminalVisa,
          userVisa: req.result[0].userVisa,
          passwordVisa: req.result[0].passwordVisa,
        };
      });
    }
  };

  const getDocumentosHistorial = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getDocumentsHistory(id)
      .then(response => {
        const { error, result, message } = response;
        const history = result.filter((item: any) => !item.documento.tipoDocumento.includes('respaldo_'));

        if (!error) {
          setRowsHistory(history);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getDocumentosRespaldoHistorial = (id: number) => {
    //setLoading(true);
    DocumentosRespaldoService.instance
      .getHistorialDocumentoRespaldo()
      .then(response => {
        const { error, result, message } = response;
        const history = result.filter((item: any) => item.NegocioId === id);
        if (!error) {
          setRowsHistorialDocumentosRespaldo(history);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getTransacciones = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getTransacciones(id)
      .then(response => {
        const { error, result, message } = response;
        if (!error) {
          setRowsTransaccionesEnlace(result.pagos_enlace);
          setRowsTransaccionesTarjeta(result.pagos_tarjeta);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleSearchChangeEnlace = (event: any) => {
    setSearchValueEnlace(event.target.value);
  };

  const handleKeyDownChangeEnlace = (event: any) => {
    if (event.key === 'Enter') {
      if (selectedRecord.id > 0) {
        getTransaccionesEnlace(selectedRecord.id);
      }
    }
  };

  const filtrarEnlace = () => {
    if (selectedRecord.id > 0) {
      getTransaccionesEnlace(selectedRecord.id);
    }
  };

  const handleSearchChangeTarjeta = (event: any) => {
    setSearchValueTarjeta(event.target.value);
  };

  const handleTipoDocumentoRespaldo = (event: any) => {
    setTipoDocumento(event.toLocaleLowerCase().replace(/\s+/g, '_'));
    setTipoDocumentoSelected(event.toLocaleLowerCase().replace(/\s+/g, '_'));
  };

  const handleKeyDownChangeTarjeta = (event: any) => {
    if (event.key === 'Enter') {
      if (selectedRecord.id > 0) {
        getTransaccionesTarjeta(selectedRecord.id);
      }
    }
  };

  const filtrarTarjeta = () => {
    if (selectedRecord.id > 0) {
      getTransaccionesTarjeta(selectedRecord.id);
    }
  };

  const getTransaccionesEnlace = (id: number) => {
    //setLoading(true);
    const params = `?page=${pageEnlace}&pageSize=${pageSizeEnlace}&nombre=${searchValueEnlace}`;
    NegociosServices.instance
      .getTransaccionesPorTipo(id, 'enlace', params)
      .then(response => {
        const { error, result, message, totalPages } = response;
        if (!error) {
          setRowsTransaccionesEnlace(result);
          setTotalPagesEnlace(totalPages);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getTransaccionesTarjeta = (id: number) => {
    //setLoading(true);
    const params = `?page=${pageTarjeta}&pageSize=${pageSizeTarjeta}&nombre=${searchValueTarjeta}`;
    NegociosServices.instance
      .getTransaccionesPorTipo(id, 'tarjeta', params)
      .then(response => {
        const { error, result, message, totalPages } = response;
        if (!error) {
          setRowsTransaccionesTarjeta(result);
          setTotalPagesTarjeta(totalPages);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getInformacionBancaria = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getInformacionBancaria(id)
      .then(response => {
        const { error, result, message } = response;
        if (!error) {
          result.banco = result.banco.toLowerCase();
          result.tipoCuenta = result.tipoCuenta.toLowerCase();
          setInformacionBancaria(result);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const getTipoDocumentos = (id: number) => {
    //setLoading(true);
    NegociosServices.instance
      .getTipoDocumentos(id)
      .then(response => {
        const { error, docTipoNegocio, rolUsuario, message } = response;

        if (!error) {
          if (docTipoNegocio) {
            setUsuarioId(rolUsuario?.UsuarioId || 0);

            const roleDocs = JSON.parse(rolUsuario?.rol?.documentosObligatorios ?? '[]');

            let docs = docTipoNegocio?.map((doc: any) => doc?.nombre || '') ?? [];
            let filtered = docs.filter((c: any, index: number) => docs.indexOf(c) === index && !c.includes('respaldo'));

            setTipoDocumentos(roleDocs.length > 0 ? roleDocs : filtered);
          } else {
            handleErrorMessage('Debes asignar un usuario a este negocio para poder adjuntar documentos.');
            handleActiveError(true);
          }
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
        //setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const showLiquidationDetails = (params: any) => {
    if (params?.contracargo) {
      setOpenLiquidationDetailModal(true);
      const rowsLiquidacion = [
        {
          id: params.liquidacion.liquidaciones[0].id,
          no: params.liquidacion.liquidaciones[0].nombre,
          fecha: momentZone
            .tz(params.liquidacion.liquidaciones[0].fechaFinal, 'America/Guatemala')
            .format('YYYY-MM-DD HH:mm A'),
          montoContracargo: params.contracargo?.monto,
          montoAbonado: params.contracargo?.montoAbonado,
          resolucion: params.contracargo?.estadoFinal,
        },
      ];
      const columnsLiquidacion = [
        { field: 'id', headerName: 'Id', width: 150 },
        { field: 'no', headerName: 'No. de liquidación', width: 250 },
        { field: 'fecha', headerName: 'Fecha', width: 250 },
        { field: 'montoContracargo', headerName: 'Monto Contracargo', width: 240 },
        { field: 'montoAbonado', headerName: 'Monto Abonado', width: 200 },
        { field: 'resolucion', headerName: 'Resolución', width: 250 },
      ];
      setColumnsLiquidacionDetails(columnsLiquidacion);
      setRowsLiquidacionDetails(rowsLiquidacion);
    } else {
      setOpenLiquidationDetailModal(true);
      const rowsLiquidacion = [
        {
          id: params.liquidacion.liquidaciones[0].id,
          no: params.liquidacion.liquidaciones[0].nombre,
          fecha: momentZone
            .tz(params.liquidacion.liquidaciones[0].fechaFinal, 'America/Guatemala')
            .format('YYYY-MM-DD HH:mm A'),
        },
      ];
      const columnsLiquidacion = [
        { field: 'id', headerName: 'Id', width: 150 },
        { field: 'no', headerName: 'No. de liquidación', width: 250 },
        { field: 'fecha', headerName: 'Fecha', width: 250 },
      ];
      setColumnsLiquidacionDetails(columnsLiquidacion);
      setRowsLiquidacionDetails(rowsLiquidacion);
    }
  };

  const showLiquidationDetailsTarjeta = (params: any) => {
    if (params?.contracargo) {
      setOpenLiquidationDetailModal(true);
      const rowsLiquidacion = [
        {
          id: params.liquidacionId.id,
          no: params.liquidacionId.nombre,
          fecha: momentZone.tz(params.liquidacionId.fechaFinal, 'America/Guatemala').format('YYYY-MM-DD HH:mm A'),
          montoContracargo: params.contracargo?.monto,
          montoAbonado: params.contracargo?.montoAbonado,
          resolucion: params.contracargo?.estadoFinal,
        },
      ];
      const columnsLiquidacion = [
        { field: 'id', headerName: 'Id', width: 150 },
        { field: 'no', headerName: 'No. de liquidación', width: 250 },
        { field: 'fecha', headerName: 'Fecha', width: 250 },
        { field: 'montoContracargo', headerName: 'Monto Contracargo', width: 240 },
        { field: 'montoAbonado', headerName: 'Monto Abonado', width: 200 },
        { field: 'resolucion', headerName: 'Resolución', width: 250 },
      ];
      setColumnsLiquidacionDetails(columnsLiquidacion);
      setRowsLiquidacionDetails(rowsLiquidacion);
    } else {
      setOpenLiquidationDetailModal(true);
      const rowsLiquidacion = [
        {
          id: params.liquidacionId.id,
          no: params.liquidacionId.nombre,
          fecha: momentZone.tz(params.liquidacionId.fechaFinal, 'America/Guatemala').format('YYYY-MM-DD HH:mm A'),
        },
      ];
      const columnsLiquidacion = [
        { field: 'id', headerName: 'Id', width: 150 },
        { field: 'no', headerName: 'No. de liquidación', width: 250 },
        { field: 'fecha', headerName: 'Fecha', width: 250 },
      ];
      setColumnsLiquidacionDetails(columnsLiquidacion);
      setRowsLiquidacionDetails(rowsLiquidacion);
    }
  };

  function renderEstado(id: number, docTipoId: number, value: any) {
    let color = 'red';
    switch (value) {
      case 'subido':
        color = '#5166AF';
        break;
      case 'aprobado':
        color = 'green';
        break;
      case 'rechazado':
        color = 'red';
        break;
      case 'pendiente':
        color = '#FCBF49';
        break;
      default:
        color = '#FCBF49';
        break;
    }
    return (
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
      >
        <Chip
          style={{ backgroundColor: color }}
          label={value}
          clickable
          color='primary'
          onClick={() => {
            setDocumentoIdSelected(id);
            setDocumentoDocTipoIdSelected(docTipoId);
            handleOpenModal();
          }}
        />
      </div>
    );
  }

  function renderEstadoDocumentoRespaldo(id: number, docTipoId: number, value: any) {
    let color = 'red';
    switch (value) {
      case 'subido':
        color = '#5166AF';
        break;
      case 'aprobado':
        color = 'green';
        break;
      case 'rechazado':
        color = 'red';
        break;
      case 'pendiente':
        color = '#FCBF49';
        break;
      default:
        color = '#FCBF49';
        break;
    }
    return (
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
      >
        <Chip
          style={{ backgroundColor: color }}
          label={value}
          clickable
          color='primary'
          onClick={() => {
            setDocumentoRespaldoIdSelected(id);
            setDocumentoDocTipoIdSelected(docTipoId);
            handleOpenModal();
          }}
        />
      </div>
    );
  }

  const columns = [
    {
      field: 'acciones',
      headerName: 'Documentos',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openFiles(params.row)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return renderEstado(params.id, params.id, params.value);
      },
    },
    {
      field: 'docTipoNegocio',
      headerName: 'Tipo',
      width: 230,
      renderCell: (params: any) => {
        return (
          <span>
            {params.formattedValue.nombre === 'foto_negocio' ? 'foto_selfie_negocio' : params.formattedValue.nombre}
          </span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Subido',
      width: 180,

      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'observaciones',
      headerName: 'Observaciones',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
    },

    {
      field: 'quitarLimite',
      headerName: 'Obligatorio',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (params.value ? 'No' : 'Si'),
    },
    {
      field: 'subirloNegocio',
      headerName: 'Responsabilidad',
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <Select
            fullWidth
            onChange={ev => {
              updateSubirloNegocio(params.id, ev.target.value === 'negocio', params.row.status);
            }}
            value={params.value ? 'negocio' : 'afiliador'}
            inputProps={{
              name: 'subirloNegocio',
              id: 'subirloNegocio-select',
            }}
          >
            <MenuItem value='negocio'>Negocio</MenuItem>
            <MenuItem value='afiliador'>Afiliador</MenuItem>
          </Select>
        );
      },
    },
    { field: 'id', headerName: 'Id', width: 100 },
  ];

  const columnsRespaldos = [
    {
      field: 'acciones',
      headerName: 'Documentos',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openFiles(params.row)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return renderEstadoDocumentoRespaldo(params.id, params.id, params.row.estado);
      },
    },
    {
      field: 'docTipoNegocio',
      headerName: 'Tipo',
      width: 230,
      renderCell: (params: any) => {
        return <span>{params.row.tipoDocumento}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Subido',
      width: 180,

      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'observaciones',
      headerName: 'Observaciones',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
    },

    {
      field: 'quitarLimite',
      headerName: 'Obligatorio',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (!params.value ? 'No' : 'Si'),
    },
    {
      field: 'subirloNegocio',
      headerName: 'Responsabilidad',
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <Select
            fullWidth
            onChange={ev => {
              updateSubirloNegocio(params.id, ev.target.value === 'negocio', params.row.status);
            }}
            value={params.value ? 'negocio' : 'afiliador'}
            inputProps={{
              name: 'subirloNegocio',
              id: 'subirloNegocio-select',
            }}
          >
            <MenuItem value='negocio'>Negocio</MenuItem>
            <MenuItem value='afiliador'>Afiliador</MenuItem>
          </Select>
        );
      },
    },
    { field: 'id', headerName: 'Id', width: 100 },
  ];

  const columnsHistorialCredenciales = [
    {
      field: 'origen',
      headerName: 'Accion',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'descripcion',
      headerName: 'Razón',
      width: 230,
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

    {
      field: 'documentos',
      headerName: 'Documentos',
      width: 730,
      renderCell: (params: any) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const openC = Boolean(anchorEl);
        const idC = open ? 'simple-popover' : undefined;
        const handleClick = (event: any) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };
        if (params.row.documentos.length > 0) {
          return (
            <div>
              <Button
                aria-describedby={id}
                variant='contained'
                color='primary'
                onClick={handleClick}
              >
                Ver links
              </Button>
              <Popover
                id={idC}
                open={openC}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ul>
                  {params.row.documentos.map((documento: any, index: number) => (
                    <li key={index}>
                      <a
                        href={documento.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {documento.link}
                      </a>
                    </li>
                  ))}
                </ul>
              </Popover>
            </div>
          );
        }
      },
    },
  ];

  const columnsMpos: GridColumns = [
    { field: 'numeroDeSerie', headerName: 'Número de serie', width: 250, flex: 1 },
    // {
    //     field: "direccionMac", headerName: "Dirección MAC", width: 200,
    //     renderCell: (params: any) => {
    //         return (
    //             params.row.direccionMac == null ? <span>No enlazado</span> : <span>{params.row.direccionMac}</span>
    //         )
    //     }
    // },
    // {
    //   field: 'fechaEnlace',
    //   headerName: 'Activación',
    //   width: 200,
    //   renderCell: (params: any) => {
    //     const tempDate = momentZone
    //       .tz(params.getValue(params.id, 'fechaEnlace'), 'America/Guatemala')
    //       .format('YYYY-MM-DD HH:mm A');
    //     return params.row.fechaEnlace == null ? <span>No enlazado</span> : <span>{tempDate}</span>;
    //   },
    // },
    { field: 'afiliacionEpayRetail', headerName: 'Afiliación', width: 145, flex: 1 },
    { field: 'terminalEpayRetail', headerName: 'Terminal', width: 135, flex: 1 },
    {
      field: 'configuracionEpayRetail',
      headerName: 'Estado',
      sortable: false,
      minWidth: 100,
      valueGetter: (params: any) =>
        params && params.row && params.row.configuracionEpayRetail ? 'Activado' : 'Desactivado',
      valueOptions: ['Activado', 'Desactivado'],
      renderCell: (params: any) => {
        let activado: boolean = false;

        if (params && params.row && params.row.configuracionEpayRetail) {
          activado = params.row.configuracionEpayRetail;
        }

        return (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ cursor: 'pointer', lineHeight: 0 }}
          >
            {activado ? (
              <CheckIcon
                color='primary'
                style={{ lineHeight: 0 }}
              />
            ) : (
              <ClearIcon
                color='secondary'
                style={{ lineHeight: 0 }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: 'Acciones',
      headerName: 'Acciones',
      width: 150,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{ cursor: 'pointer', lineHeight: 0, width: '100%', display: 'flex', justifyContent: 'space-around' }}
          >
            <Edit
              titleAccess='Editar'
              color='primary'
              style={{ lineHeight: 0 }}
              onClick={() => openEditMpos(params.row)}
            ></Edit>

            <Delete
              titleAccess='Borrar'
              color='secondary'
              style={{ lineHeight: 0 }}
              onClick={() => openDeleteMpos(params.row)}
            ></Delete>
          </div>
        );
      },
    },
    {
      field: 'Reportes',
      headerName: 'Reportes',
      width: 125,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{ cursor: 'pointer', lineHeight: 0, width: '100%', display: 'flex', justifyContent: 'space-around' }}
          >
            <Report
              titleAccess='Reportes'
              color='primary'
              style={{ lineHeight: 0 }}
              onClick={() => openGenerateReport(params.row)}
            ></Report>
          </div>
        );
      },
    },
  ];

  const columnsClosedTransactions = [
    { field: 'reference', headerName: 'Referencia', width: 175 },
    { field: 'batch', headerName: 'Lote', width: 125 },
    {
      field: 'createdAt',
      headerName: 'Creado',
      flex: 1,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
  ];

  const columnsHistory = [
    { field: 'id', headerName: 'Id', width: 100 },
    {
      field: 'tipoDocumento',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.documento.tipoDocumento === 'foto_negocio'
              ? 'foto_selfie_negocio'
              : params.row.documento.tipoDocumento}
          </span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Subido',
      flex: 1,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fecha'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'acciones',
      headerName: 'Documentos',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openHistoryFiles(params.row)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const columnsHistoryRespaldo = [
    { field: 'id', headerName: 'Id', width: 100 },
    {
      field: 'docTipoNegocio',
      headerName: 'Tipo',
      width: 230,
      renderCell: (params: any) => {
        return <span>{params.row.tipoDocumento}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha Subido',
      flex: 1,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fecha'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'acciones',
      headerName: 'Documentos',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openHistoryFiles(params.row)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const columnsTransaccionesTarjeta = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'nombre', headerName: 'Nombre', width: 230 },
    { field: 'status', headerName: 'Status', width: 230 },
    { field: 'bin', headerName: 'bin', width: 230 },
    {
      field: 'liquidacion',
      headerName: 'Liquidación',
      width: 200,
      renderCell: (params: any) => {
        if (params.row && params.row.LiquidacionId && params.row.liquidacionId.liquidaciones) {
          const businessSettlement = params.row.liquidacionId.liquidaciones.find(
            (settlement: any) => settlement.NegocioId === selectedRecord.id,
          );
          let status = settlementStatusBusiness.find(status => businessSettlement?.estado === status.value);
          status = !status
            ? {
                value: params.row.liquidacionEstado,
                color: params.row.liquidacionEstado === 'Retenida' ? '#f44336' : '#5166AF',
              }
            : status.value === 'Pagado' && params.row.liquidacionEstado === 'Retenida'
            ? {
                value: params.row.liquidacionEstado,
                color: '#f44336',
              }
            : status;

          if (params.row?.contracargo) {
            status = {
              value: 'Contracargo',
              color: '#F68C00',
            };
          }

          return (
            <Chip
              label={status?.value}
              onClick={() => showLiquidationDetailsTarjeta(params.row)}
              style={{ background: status?.color, color: status?.color ? 'white' : 'black' }}
            />
          );
        } else {
          return (
            <Chip
              label='Sin estado'
              style={{ color: 'black' }}
            />
          );
        }
      },
    },
    { field: 'monto', headerName: 'Monto', width: 230 },
    { field: 'nombreCliente', headerName: 'Cliente', width: 230 },
    { field: 'concepto', headerName: 'Concepto', width: 230 },
    { field: 'authNumber', headerName: 'Autorización', width: 160 },

    {
      field: 'responseCode',
      headerName: 'Respuesta Visanet',
      width: 250,
      renderCell: (params: any) => {
        let mensaje = getResponseCodeMessageEpayRetail(params.value);
        return <small>{mensaje}</small>;
      },
    },
    {
      field: 'numeroDeVisaCuotas',
      headerName: 'Tipo de Pago',
      width: 200,
      renderCell: (params: any) => {
        switch (params.value) {
          case 'VC02':
            return <span>2 cuotas</span>;
          case 'VC03':
            return <span>3 cuotas</span>;
          case 'VC06':
            return <span>6 cuotas</span>;
          case 'VC10':
            return <span>10 cuotas</span>;
          case 'VC12':
            return <span>12 cuotas</span>;
          case 'VC15':
            return <span>15 cuotas</span>;
          case 'VC18':
            return <span>18 cuotas</span>;
          case 'VC24':
            return <span>24 cuotas</span>;
          case 'VC36':
            return <span>36 cuotas</span>;
          case 'VC48':
            return <span>48 cuotas</span>;
          default:
            return <span>Contado</span>;
        }
      },
    },
    { field: 'nombreCliente', headerName: 'Cliente', width: 230 },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      width: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fecha'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'Voucher',
      headerName: 'Voucher',
      width: 140,
      renderCell: (params: any) => {
        if (!params.row.linkVoucher) return null;
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => openFiles({ link: params.row.linkVoucher })}
          >
            <DescriptionTwoTone />
          </Button>
        );
      },
    },
  ];

  const columnsTransaccionesEnlace: GridColumns = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'nombre', headerName: 'Nombre', width: 230 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'bin', headerName: 'bin', width: 130 },
    {
      field: 'liquidacion',
      headerName: 'Liquidación',
      width: 200,
      renderCell: (params: any) => {
        if (params.row && params.row.liquidacion && params.row.liquidacion.liquidaciones) {
          const businessSettlement = params.row.liquidacion.liquidaciones.find(
            (settlement: any) => settlement.NegocioId === selectedRecord.id,
          );
          let status = settlementStatusBusiness.find(status => businessSettlement?.estado === status.value);
          status = !status
            ? {
                value: params.row.liquidacionEstado,
                color: params.row.liquidacionEstado === 'Retenida' ? '#f44336' : '#5166AF',
              }
            : status.value === 'Pagado' && params.row.liquidacionEstado === 'Retenida'
            ? {
                value: params.row.liquidacionEstado,
                color: '#f44336',
              }
            : status;

          if (params.row?.contracargo) {
            status = {
              value: 'Contracargo',
              color: '#F68C00',
            };
          }

          return (
            <Chip
              onClick={() => showLiquidationDetails(params.row)}
              label={status?.value}
              style={{ background: status?.color, color: status?.color ? 'white' : 'black' }}
            />
          );
        } else {
          return (
            <Chip
              label='Sin estado'
              style={{ color: 'black' }}
            />
          );
        }
      },
    },
    { field: 'monto', headerName: 'Monto', width: 130 },
    { field: 'nombreCliente', headerName: 'Cliente', width: 230 },
    { field: 'concepto', headerName: 'Concepto', width: 230 },
    { field: 'authorizationNumber', headerName: 'Autorización', width: 160 },
    { field: 'hash', headerName: 'Enlace', width: 160 },
    {
      field: 'responseCode',
      headerName: 'Respuesta visanet',
      width: 250,
      renderCell: (params: any) => {
        let mensaje = getMensajeDeErrorVisa(params.value);
        return <span>{params && params.value ? '(' + params.value + ') ' + mensaje : ''}</span>;
      },
    },
    {
      field: 'numeroDeVisaCuotas',
      headerName: 'Tipo de Pago',
      width: 200,
      renderCell: (params: any) => {
        switch (params.value) {
          case 'VC02':
            return <span>2 cuotas</span>;
            break;
          case 'VC03':
            return <span>3 cuotas</span>;
            break;
          case 'VC06':
            return <span>6 cuotas</span>;
            break;
          case 'VC10':
            return <span>10 cuotas</span>;
            break;
          case 'VC12':
            return <span>12 cuotas</span>;
            break;
          case 'VC15':
            return <span>15 cuotas</span>;
            break;
          case 'VC18':
            return <span>18 cuotas</span>;
            break;
          case 'VC24':
            return <span>24 cuotas</span>;
            break;
          case 'VC36':
            return <span>36 cuotas</span>;
            break;
          case 'VC48':
            return <span>48 cuotas</span>;
            break;
          default:
            return <span>Contado</span>;
            break;
        }
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      width: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fecha'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    {
      field: 'fechaRealizoPago',
      headerName: 'Fecha de pago',
      width: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'fechaRealizoPago'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{params.row.fechaRealizoPago ? fechaTZ6 : ''}</span>;
      },
    },
    {
      field: 'Voucher',
      headerName: 'Voucher',
      width: 140,
      renderCell: (params: any) => {
        if (!params.row.linkVoucher) return null;
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => openFiles({ link: params.row.linkVoucher })}
          >
            <DescriptionTwoTone />
          </Button>
        );
      },
    },
  ];

  const columnsContratos = [
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 230,
      renderCell: (params: any) => {
        return values.nombre;
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return renderEstadoContrato(params);
      },
    },
    {
      field: 'acciones',
      headerName: 'Documentos',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              onClick={() => openFilesContrato(params)}
            >
              <OpenInBrowser />
            </IconButton>
          </div>
        );
      },
    },
  ];

  function renderEstadoContrato(value: any) {
    let color = 'red';
    let elContratoEstaFirmado = value.row.contratoFirmado;

    switch (elContratoEstaFirmado) {
      case true:
        color = 'green';
        break;
      default:
        color = 'orange';
        break;
    }
    return (
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
      >
        <Chip
          style={{ backgroundColor: color }}
          label={elContratoEstaFirmado ? 'Comercio habilitado' : 'Comercio no habilitado'}
          clickable
          color='primary'
          onClick={() => {
            setOpenModalContrato(true);
          }}
        />
      </div>
    );
  }

  const openFilesContrato = (items: any) => {
    try {
      if (items && items.row && items.row.contrato) {
        const link = JSON.parse(items.row.contrato);
        if (Array.isArray(link)) {
          setArrayImages(link);
          setOpenImagesModal(true);
        } else {
          setArrayImages([]);
          setOpenImagesModal(true);
        }
      } else {
        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openHistoryFiles = (row: any) => {
    try {
      if (row.linkActual) {
        try {
          const link = JSON.parse(row.linkActual);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row.linkActual]);
          setOpenImagesModal(true);
        }
      } else {
        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log(error);
      //setArrayImages([items.row.link])
      //setOpenImagesModal(true)
      //alert("No se pudieron abrir las imagenes")
    }
  };

  const componenteFiles = (component: string) => {
    if (component === 'Nombre del Solicitante' || component === 'Nombre') {
      return nombreNegocioComponent();
    } else if (component === 'Razón social') {
      return razonSocialComponent();
    } else if (component === 'NIT') {
      return nitComponent();
    } else if (component === 'Régimen') {
      return tipoNegocioComponent();
    } else if (component === 'tipoNegocio') {
      return tipoNegocioComponent();
    } else if (component === 'Actividad económica') {
      return actividadNegocioComponent();
    } else if (component === 'Subactividad económica') {
      return subactividadNegocioComponent();
    } else if (component === 'Nombre del cuentahabiente') {
      return nombreCuentaComponent();
    } else if (component === 'Número de cuenta') {
      return numeroCuentaComponent();
    } else if (component === 'Tipo de cuenta') {
      return tipoCuentaComponent();
    } else if (component === 'Dirección') {
      return direccionComponent();
    } else if (component === 'Departamento') {
      return departamentoComponent();
    } else if (component === 'Municipio') {
      return municipioComponent();
    } else {
      return null;
    }
  };

  const municipioComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel id='municipio-select-label'>Municipio</InputLabel>
        <Select
          labelId='municipio-select-label'
          id='muni-negocio-select'
          label='Municipio'
          value={values?.municipio?.toLocaleLowerCase()}
          onChange={handleChange('municipio')}
        >
          <MenuItem value=''>
            <em>Seleccione</em>
          </MenuItem>
          {values &&
          values.departamento &&
          listamunicipios &&
          listamunicipios[values?.departamento?.toLocaleLowerCase()] &&
          Array.isArray(listamunicipios[values?.departamento?.toLocaleLowerCase()])
            ? listamunicipios[values?.departamento?.toLocaleLowerCase()].map((municipio: any) => {
                return (
                  <MenuItem
                    key={municipio}
                    value={municipio.toLocaleLowerCase()}
                  >
                    {municipio}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
    );
  };

  const departamentoComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel id='departammento-select-label'>Departamento</InputLabel>
        <Select
          labelId='departammento-select-label'
          id='dep-negocio-select'
          label='Departamento'
          value={values?.departamento?.toLocaleLowerCase()}
          onChange={handleChange('departamento')}
        >
          <MenuItem value=''>
            <em>Seleccione</em>
          </MenuItem>
          {selectedRecord &&
          selectedRecord.tipoNegocio &&
          selectedRecord.tipoNegocio.pais &&
          selectedRecord.tipoNegocio.pais.nombre &&
          departamentos &&
          departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()] &&
          Array.isArray(departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()])
            ? departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()].map((dep: any) => {
                return (
                  <MenuItem
                    key={dep}
                    value={dep.toLocaleLowerCase()}
                  >
                    {dep}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
    );
  };

  const direccionComponent = () => {
    return (
      <TextField
        label='Dirección'
        variant='outlined'
        fullWidth
        value={values.numeroCasa}
        onChange={handleChange('numeroCasa')}
      />
    );
  };

  const nombreNegocioComponent = () => {
    return (
      <TextField
        label='Nombre'
        variant='outlined'
        fullWidth
        value={values.nombre}
        onChange={handleChange('nombre')}
      />
    );
  };

  const razonSocialComponent = () => {
    return (
      <TextField
        label='Razón social'
        variant='outlined'
        fullWidth
        value={values.razonSocial}
        onChange={handleChange('razonSocial')}
      />
    );
  };

  const nitComponent = () => {
    return (
      <TextField
        label='NIT'
        variant='outlined'
        fullWidth
        value={values.nit}
        onChange={handleChange('nit')}
      />
    );
  };

  const tipoRegimenComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel>Tipo de Régimen</InputLabel>
        <Select
          labelId='documento-select-label'
          id='documento-select'
          label='Tipo de régimen'
          value={values?.TipoRegimenId}
          onChange={handleChange('TipoRegimenId')}
        >
          <MenuItem value={undefined}>
            <em>No seleccionado</em>
          </MenuItem>
          {tiposRegimenes.map((tipo: any) => {
            return (
              <MenuItem
                key={tipo.id}
                value={tipo.id}
              >
                {tipo.nombre}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const tipoNegocioComponent = () => {
    return TipoNegocioSelect();
  };

  const actividadNegocioComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel id='actividad-select-label'>Actividad del negocio</InputLabel>
        <Select
          labelId='actividad-select-label'
          id='negocio-select'
          label='Actividad del negocio'
          value={values.ActividadId}
          onChange={handleChange('ActividadId')}
        >
          <MenuItem value=''>
            <em>Seleccione</em>
          </MenuItem>
          {selectedRecord &&
          selectedRecord.tipoNegocio &&
          selectedRecord.tipoNegocio.pais &&
          (selectedRecord.tipoNegocio.pais.nombre === 'Guatemala' ||
            selectedRecord.tipoNegocio.pais.nombre === 'Honduras')
            ? activities
                .filter(
                  (act: any) =>
                    (selectedRecord.tipoNegocio.pais.nombre === 'Guatemala' && act.paisId === 1) ||
                    (selectedRecord.tipoNegocio.pais.nombre === 'Honduras' && act.paisId === 2),
                )
                .map((act: any) => {
                  return (
                    <MenuItem
                      key={act.id}
                      value={act.id}
                    >
                      {act.nombre.toLocaleUpperCase()}
                    </MenuItem>
                  );
                })
            : null}
        </Select>
      </FormControl>
    );
  };

  const subactividadNegocioComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <Autocomplete
          id='negocio-select'
          defaultValue={() => {
            return subActivities.find((subActividadObj: any) => subActividadObj?.id === values.SubActividadId);
          }}
          options={subActivities.map((subActivity: any) => {
            return subActivity;
          })}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setValues({ ...values, ['SubActividadId']: newValue.id });
            }
          }}
          getOptionLabel={(option: any) => `${option.codigoMcc} -  ${option.nombre?.toLocaleUpperCase()} `}
          renderInput={params => (
            <TextField
              {...params}
              label='SubActividad del negocio'
              variant='outlined'
              defaultValue={() => {
                return subActivities.find((subActividadObj: any) => subActividadObj?.id === values.SubActividadId);
              }}
            />
          )}
        />
      </FormControl>
    );
  };

  const nombreCuentaComponent = () => {
    return (
      <TextField
        label='Nombre de la cuenta'
        variant='outlined'
        fullWidth
        value={informacionBancaria.nombreCuenta}
        onChange={handleChangeInformacionBancaria('nombreCuenta')}
      />
    );
  };

  const numeroCuentaComponent = () => {
    return (
      <TextField
        label='Numero de cuenta'
        variant='outlined'
        fullWidth
        value={informacionBancaria.numeroCuenta}
        onChange={handleChangeInformacionBancaria('numeroCuenta')}
      />
    );
  };

  const tipoCuentaComponent = () => {
    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel id='tipocuenta-select-label'>Tipo de cuenta</InputLabel>
        <Select
          labelId='tipocuenta-select-label'
          id='tipo-cuenta-select'
          label='Tipo de cuenta'
          value={informacionBancaria.tipoCuenta}
          onChange={handleChangeInformacionBancaria('tipoCuenta')}
        >
          <MenuItem value=''>
            <em>Seleccione</em>
          </MenuItem>
          {(informacionBancaria.banco === 'banco azteca'
            ? tiposcuenta.filter(tipo => tipo.toLocaleLowerCase() === 'monetaria')
            : tiposcuenta
          ).map((act, index) => (
            <MenuItem
              key={index}
              value={act.toLocaleLowerCase()}
            >
              {act.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getInfoDocuments = (type: any) => {
    let docDetails: any[] = [];
    const actividad: any = activities.find((activity: any) => activity.id === values.ActividadId);

    if (type === 'rtu') {
      docDetails = [
        {
          key: 'Nombre del Solicitante',
          value: values.nombre,
        },
        {
          key: 'Razón social',
          value: values.razonSocial,
        },
        {
          key: 'NIT',
          value: values.nit,
        },
        {
          key: 'Régimen',
          value: values.tipoNegocio?.descripcion,
        },
        {
          key: 'Actividad económica',
          value: actividad?.nombre,
        },
        {
          key: 'Subactividad económica',
          value: values?.subActividad?.nombre,
        },
      ];
    } else if (type === 'cuenta_bancaria') {
      docDetails = [
        {
          key: 'Nombre del cuentahabiente',
          value: informacionBancaria.nombreCuenta,
        },
        {
          key: 'Número de cuenta',
          value: informacionBancaria.numeroCuenta,
        },
        {
          key: 'Tipo de cuenta',
          value: informacionBancaria.tipoCuenta,
        },
      ];
    } else if (type === 'recibo') {
      docDetails = [
        {
          key: 'Dirección',
          value: values.numeroCasa,
        },
        {
          key: 'Departamento',
          value: values.departamento,
        },
        {
          key: 'Municipio',
          value: values.municipio,
        },
      ];
    } else if (type === 'acta') {
      docDetails = [
        {
          key: 'Actividad económica',
          value: actividad?.nombre,
        },
        {
          key: 'Subactividad económica',
          value: values?.subActividad?.nombre,
        },
        {
          key: 'Nombre',
          value: values.nombre,
        },
        {
          key: 'Razón social',
          value: values.razonSocial,
        },
      ];
    }
    setDocumentosDetails(docDetails);
  };

  const openFiles = (row: any) => {
    setTipoDocumentoSeleccionado(row.docTipoNegocio?.nombre);
    const docDetails = getInfoDocuments(row.docTipoNegocio?.nombre);
    try {
      if (row.link) {
        try {
          const link = JSON.parse(row.link);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row.link]);
          setOpenImagesModal(true);
        }
      } else if (row.documento && row.documento.link) {
        try {
          const link = JSON.parse(row.documento.link);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([row.documento.link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([row.documento.link]);
          setOpenImagesModal(true);
        }
      } else if (
        row.LinksDocumentoNegocio &&
        Array.isArray(row.LinksDocumentoNegocio) &&
        row.LinksDocumentoNegocio.length > 0
      ) {
        try {
          const link = JSON.parse(row.LinksDocumentoNegocio[row.LinksDocumentoNegocio.length - 1].links);
          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          // setArrayImages([row.link]);
          // setOpenImagesModal(true);
        }
      } else {
        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log(error);
      //setArrayImages([items.row.link])
      //setOpenImagesModal(true)
      //alert("No se pudieron abrir las imagenes")
    }
  };

  const openFile = (item: any) => {
    try {
      window.open(item, '_blank');
    } catch (error) {
      console.log(error);
      alert('No se pudo abrir el archivo');
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTipoRegimen = () => (event: any) => {
    updateNegocioValuesEspecificos(selectedRecord.id, { ['TipoRegimenId']: event.target.value });
  };

  const handleTelefonoAdicional = (e: any) => {
    if (/^[0-9]{0,8}$/.test(e.target.value)) {
      setValues({ ...values, ['telefonoAdicional']: e.target.value || '' });
    }
  };

  const handleChangeRazon = (event: any) => {
    setRazonCredenciales(event.target.value);
  };

  const handleChangeMposValues = (prop: any) => (event: any) => {
    setMposValues({ ...mposValues, [prop]: event.target.value });
  };

  const handleChangeInformacionBancaria = (prop: any) => (event: any) => {
    
    if (prop === 'banco') {
      setInformacionBancaria({ ...informacionBancaria, [prop]: event.target.value, tipoCuenta: '' });
    } else {

      if (prop === 'numeroCuenta' && !/^[0-9]{0,20}$/.test(event.target.value)) {
        handleActiveError(true);
        handleErrorMessage('Está permitido ingresar solo números.');
        return;
      }

      setInformacionBancaria({ ...informacionBancaria, [prop]: event.target.value });
    }
  };

  const handleSetTipoDocumentoSelected = (event: any) => {
    setTipoDocumentoSelected(event.target.value);
  };

  const handleSetOpenModalFile = () => {
    setOpenModalFile(true);
  };

  const handleSetOpenModalFileContrato = () => {
    setOpenModalFileContrato(true);
  };

  const handleChangePage = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPageSelected(newValue);
  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const handleFacturaUploadSave = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);

    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setFacturacionUpload(false);
        if (response.length > 0) {
          setFacturacion(prev => {
            return {
              ...prev,
              estado: prev?.estado || 'deshabilitado',
              firma: JSON.stringify(response),
            };
          });

          updateFEL();
        } else {
          handleErrorMessage('No se pudo subir archivo');
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleSave = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);

    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setUploading(false);
        if (response.length > 0) {
          const params = {
            tipoDocumento: valTabDocuments === 0 ? tipoDocumentoSelected : `respaldo_${tipoDocumentoSelected}`,
            UsuarioId,
            link: JSON.stringify(response),
            extension: '',
            nombre: 'archivo',
            estado: 'subido',
          };
          handleCreateDocument(params);
          setDocumentoIdSelected(0);
          setTipoDocumento('');
        } else {
          setTipoDocumento('');
          setDocumentoIdSelected(0);
          handleErrorMessage('No se pudo subir archivo');
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });

    // DocumentosService.instance
    //   .uploadFileDoc(values.id, data)
    //   .then(response => {
    //     setUploading(false);
    //     const { error, message, result } = response;
    //     if (!error) {
    //       const link = result.map((file: any) => file.Location);
    //       const params = {
    //         tipoDocumento: tipoDocumentoSelected,
    //         UsuarioId,
    //         link: JSON.stringify(link),
    //         extension: '',
    //         nombre: 'archivo',
    //         estado: 'subido',
    //       };
    //       handleCreateDocument(params);
    //     } else {
    //       handleErrorMessage(message);
    //       handleActiveError(true);
    //     }
    //   })
    //   .catch(e => {
    //     setUploading(false);
    //     handleErrorMessage(e.message);
    //     handleActiveError(true);
    //   });
  };

  const handleSaveRespaldo = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setUploading(false);
        if (response.length > 0) {
          const params = {
            tipoDocumento: `respaldo_${tipoDocumentoSelected}`,
            link: JSON.stringify(response),
            extension: '',
            estado: 'subido',
            NegocioId: values.id,
          };
          handleCreateDocumentRespaldo(params);
          setDocumentoIdSelected(0);
          setTipoDocumento('');
        } else {
          setTipoDocumento('');
          setDocumentoIdSelected(0);
          handleErrorMessage('No se pudo subir archivo');
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
    setUploading(true);
  };

  const handleSaveContrato = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);
    DocumentosService.instance
      .uploadFileDoc(values.id, data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const link = result.map((file: any) => file.Location);

          handleCreateContrato(JSON.stringify(link));
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleDocumentosRazones = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);
    DocumentosService.instance
      .uploadFileDoc(values.id, data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const links = result.map((file: any) => file.Location);
          setLinksDocumentosRazones(links);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleCreateContrato = (params: any) => {
    setUploading(true);

    UsersServices.instance
      .updateContrato(UsuarioId, params)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          setOpenModalFile(false);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleSaveComision = () => {};

  const handleCreateDocument = (params: any) => {
    setUploading(true);

    DocumentosService.instance
      .createDocumento(params)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const documento = result.find((documento: any) => documento.tipoDocumento === 'rtu');
          if (documento?.estado === 'subido') {
            const { id, NegocioId } = documento;
            DocumentosService.instance
              .analyzefiles(id, NegocioId)
              .then(response => {
                console.log('starting analysis ->', response);
              })
              .catch(error => {
                console.log('error starting analysis ->', error);
                console.log(error);
              });
          }
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getDocumentos(selectedRecord.id);
          getDocumentosRespaldo(selectedRecord.id);
          getDocumentosHistorial(selectedRecord.id);
          getDocumentosRespaldoHistorial(selectedRecord.id);
          setOpenModalFile(false);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const handleCreateDocumentRespaldo = (params: any) => {
    setUploading(true);

    DocumentosRespaldoService.instance
      .createDocumentoRespaldo(params)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getDocumentosRespaldo(selectedRecord.id);
          getDocumentosRespaldoHistorial(selectedRecord.id);
          setOpenModalFile(false);
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };
  const handleOpenUpload = () => {
    setOpenUploadFile(true);
  };

  const updateNegocioValuesEspecificos = (id: number, nuevosValues: any) => {
    setLoading(true);
    NegociosServices.instance
      .update(id, nuevosValues)
      .then(response => {
        const { error, message } = response;
        if (!error) {
          setLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          setValues({ ...values, ...nuevosValues });
        } else {
          setLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const editNegocio = () => {
    let validation = ValidationFields.instance.newNegocio(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        setLoading(true);
        NegociosServices.instance
          .update(values.id, values)
          .then(response => {
            //setLoading(false);
            const { error, message } = response;
            if (!error) {
              // handleSuccessMessage(message);
              // handleActiveSuccess(true);
              updateTipoNegocio(values.id.toString(), tipoNegocioTemp[values.id]);
              //history.push('/admin-home/negocios')
            } else {
              setLoading(false);
              handleErrorMessage(message);
              handleActiveError(true);
            }
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage(e.message);
            handleActiveError(true);
          });
      }
    }
  };

  const updateCredencialesNegocio = () => {
    let validation = ValidationFields.instance.newNegocio(values);
    if (validation.error) {
      handleErrorMessage(validation.message);
      handleActiveError(true);
    } else {
      if (!loading) {
        setLoading(true);
        NegociosServices.instance
          .updateCredencialesNegocio(values.id, values, UsuarioId)
          .then(response => {
            setLoading(false);
            const { error, message } = response;
            if (!error) {
              handleSuccessMessage(message);
              handleActiveSuccess(true);
              //history.push('/admin-home/negocios')
            } else {
              handleErrorMessage(message);
              handleActiveError(true);
            }
          })
          .catch(e => {
            setLoading(false);
            handleErrorMessage(e.message);
            handleActiveError(true);
          });
      }
    }
  };

  const initialConfigurationsEpayRetail = () => {
    handleLoading(true);

    const { numeroDeSerie, terminalEpayRetail, afiliacionEpayRetail } = mposValues;

    MposService.instance
      .initialConfigurationsEpayRetail(selectedRecord.id, {
        numeroDeSerie,
        terminalEpayRetail,
        afiliacionEpayRetail,
      })
      .then(response => {
        const { error, message, result } = response;

        if (!error) {
          setMposValues({
            ...mposValues,
            terminalEpayRetail: result.terminalEpayRetail,
            merchantUserEpayRetail: result.merchantUserEpayRetail,
            merchantPasswordEpayRetail: result.merchantPasswordEpayRetail,
          });
          handleLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const openEditMpos = (params: MposInterface) => {
    setMposValues({ ...params });
    setOpenModalEditMpos(true);
  };

  const openDeleteMpos = (params: MposInterface) => {
    setMposValues({ ...params });
    setOpenConfirmDeleteMpos(true);
  };

  const openGenerateReport = (params: MposInterface) => {
    setMposValues({ ...params });
    setOpenModalGenerateReport(true);
  };

  const editEpayRetailCredentials = () => {
    handleLoading(true);

    const { id, numeroDeSerie, terminalEpayRetail, afiliacionEpayRetail } = mposValues;

    MposService.instance
      .updateEpayRetailCredentials(id, {
        numeroDeSerie,
        terminalEpayRetail,
        afiliacionEpayRetail,
      })
      .then(response => {
        const { error, message } = response;
        if (!error) {
          handleLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          refreshData();
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const deleteMpos = () => {
    handleLoading(true);

    const { id, numeroDeSerie, terminalEpayRetail, afiliacionEpayRetail } = mposValues;

    MposService.instance
      .deleteMpos(id, {
        numeroDeSerie,
        terminalEpayRetail,
        afiliacionEpayRetail,
      })
      .then(response => {
        const { error, message } = response;
        setOpenConfirmDeleteMpos(false);
        setConfirmWord('');
        refreshData();
        if (!error) {
          handleLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const getMposId = () => {
    EventEmitter.subscribe('buttonClick', (event: string) => {
      setMposValues({
        ...mposValues,
        numeroDeSerie: event,
      });
    });
  };

  const editInformacionBancaria = () => {
    if (!loading) {
      setLoading(true);
      NegociosServices.instance
        .updateInformacionBancaria(informacionBancaria)
        .then(response => {
          setLoading(false);
          const { error, message, result } = response;
          if (!error) {
            setInformacionBancaria(result);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const cambiarEstadoContrato = async (estado: boolean) => {
    try {
      setLoading(true);
      let req = await UsersServices.instance.updateContratoEstado(UsuarioId, values.id, estado);
      if (!req) throw new Error('No se pudo realizar la acción');
      if (req.error) throw new Error(req.message);
      getContrato(values.id);
      handleSuccessMessage(req.message);
      handleActiveSuccess(true);
      setOpenModalContrato(false);
      await getNegocioDetail(selectedRecord.id);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setOpenModalContrato(false);
      handleErrorMessage(error.message);
      handleActiveError(true);
    }
  };

  const cambiarEstadoDocumento = (estado: number, message?: string) => {
    setShow(false);

    if (documentoIdSelected > 0) {
      setLoading(true);
      DocumentosService.instance
        .updateNewDocumentStatus(documentoIdSelected, {
          negocioId: values.id,
          estado,
          message: message ?? '',
        })
        .then(response => {
          const { error, message } = response;

          if (!error) {
            getDocumentos(selectedRecord.id);
            getDocumentosRespaldo(selectedRecord.id);
            setOpen(false);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const cambiarEstadoDocumentoDespaldo = (estado: string, message?: string) => {
    setShow(false);
    if (documentoRespaldoIdSelected > 0) {
      setLoading(true);
      DocumentosRespaldoService.instance
        .updateEstadoDocumentoRespaldo(documentoRespaldoIdSelected, {
          estado,
          id: documentoRespaldoIdSelected,
        })
        .then(response => {
          const { error, message } = response;

          if (!error) {
            getDocumentosRespaldo(selectedRecord.id);
            setOpen(false);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
          setDocumentoRespaldoIdSelected(0);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
          setDocumentoRespaldoIdSelected(0);
        });
    }
  };

  const updateSubirloNegocio = (id: number, subirloNegocio: boolean, estado: number) => {
    if (id > 0) {
      setLoading(true);
      DocumentosService.instance
        .updateNewDocumentStatus(id, {
          negocioId: values.id,
          subirloNegocio,
          message: '',
          estado,
        })
        .then(response => {
          const { error, message } = response;

          if (!error) {
            getDocumentos(selectedRecord.id);
            setOpen(false);
            handleSuccessMessage(message);
            handleActiveSuccess(true);
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const handleChangeMessage = (e: any) => {
    setMessageRejected(e.target.value);
  };

  const getComisionTotal = (): {
    comisionTotal: number;
    retencion: number;
    iva: number;
  } => {
    let comision = 0;
    let msc =
      values.subActividad && values.subActividad.msc && !Number.isNaN(values.subActividad.msc)
        ? Number(values.subActividad.msc)
        : 0;
    let porcentajeComisionPropio =
      values.subActividad &&
      values?.subActividad?.porcentajeComisionPropio &&
      !Number.isNaN(values?.subActividad?.porcentajeComisionPropio)
        ? Number(values?.subActividad?.porcentajeComisionPropio)
        : 0;
    let porcentajeComisionVisa =
      values.subActividad &&
      values.subActividad.porcentajeComisionVisa &&
      !Number.isNaN(values.subActividad.porcentajeComisionVisa)
        ? Number(values.subActividad.porcentajeComisionVisa)
        : 0;
    let descuento = values.descuentoPaggo && !Number.isNaN(values.descuentoPaggo) ? Number(values.descuentoPaggo) : 0;
    comision = msc + porcentajeComisionVisa + (porcentajeComisionPropio - porcentajeComisionPropio * (descuento / 100));
    comision = comision * 100;

    const monto = 100;
    let porcentajeComisionPropioConDescuento = porcentajeComisionPropio - porcentajeComisionPropio * (descuento / 100);
    let porcentajeRetencion: number = 0.15;
    let ivaMontoTotal = (monto / 1.12) * 0.12;
    let comisionMcc = monto * +(0.02 ?? 0);
    let comisionVisa = monto * +(0.005 ?? 0);
    let retencion = porcentajeRetencion * ivaMontoTotal;
    let liquidoPaggo = monto - (comisionMcc + comisionVisa + retencion);
    let comisionPaggo = monto * +(porcentajeComisionPropioConDescuento ?? 0);
    let ivaComisionPaggo = (comisionMcc + comisionVisa + retencion + comisionPaggo) * 0.12;
    let ventaNeta = monto - (comisionMcc + comisionVisa + retencion + comisionPaggo + ivaComisionPaggo);
    let comisionTotal = 100 - ventaNeta;

    return {
      comisionTotal: Utilities.instance.aproximaDecimal(comisionTotal, 2),
      retencion: Utilities.instance.aproximaDecimal(retencion, 2),
      iva: Utilities.instance.aproximaDecimal(12.0, 2),
    };
  };

  const getPorcentajeComisionPaggo = (): number | undefined => {
    const descuentoPaggo: number = values.descuentoPaggo ?? 0;
    let comisionPaggo: number = values.subActividad?.porcentajeComisionPropio ?? 0;
    comisionPaggo = comisionPaggo * 100;
    // comisionPaggo = Number(comisionPaggo.toFixed(2));
    comisionPaggo = comisionPaggo - comisionPaggo * (descuentoPaggo / 100);

    return comisionPaggo;
  };

  // useEffect(() => {
  //   const getDescuentoPaggo = () => {
  //     let value = Number(comisionPaggoConDescuento);
  //     if (Number.isNaN(value)) return;
  //     value = value / 100;
  //     setComisionPaggo(value);
  //     const porcentajeComisionPropio: number = Number(values.subActividad?.porcentajeComisionPropio) || 0.0085;
  //     let descuentoPaggo: number = ((porcentajeComisionPropio - value) / porcentajeComisionPropio) * 100;
  //     value = value * 100;
  //     value = Number(value.toFixed(2));

  //     setValues({ ...values, descuentoPaggo: descuentoPaggo });
  //   }

  //   getDescuentoPaggo();

  // }, [comisionPaggoConDescuento]);

  useEffect(() => {
    if (tipoNegocioTemp[selectedRecord.id] && selectedRecord.id) {
      setLoadingComision(true);
      const timerId = setTimeout(getDetallesComision, 1000);
      return () => clearTimeout(timerId);
    }
  }, [comisionTotal, tipoNegocioTemp]);

  const getDetallesComision = async () => {
    //henry
    const regimen = tipoNegocioTemp[selectedRecord.id];
    const params = `?comisionTotal=${comisionTotal}&regimen=${regimen}&monto=100`;
    const crudService = CrudService.instance(`${Rutas.instance.urlNegocio}/${selectedRecord.id}/comision${params}`);
    const req = await crudService.get();
    //.then(response => {
    if (req && req.result) {
      setRowsComisionDetalle(req.result);
      //setValues({ ...values, descuentoPaggo: req.result.descuentoPaggo });
      setValues(prevState => {
        return {
          ...prevState,
          descuentoPaggo: req.result.descuentoPaggo,
        };
      });
      setComisionTotal(req.result.comisionTotal);
      setValues({ ...values, comisionTotal: req.result.comisionTotal });
      setLoadingComision(false);
    }

    handleLoading(false);
    //})
    // .catch(e => {
    //   handleLoading(false);
    //   handleErrorMessage(e.message);
    //   handleActiveError(true);
    // });
  };

  const handleChangePorcentajeComisionPaggo = (prop: any) => (event: any) => {
    setComisionPaggoConDescuento(event.target.value);
  };

  const handleChangePorcentajeComisionTotal = () => (event: any) => {
    setComisionTotal(event.target.value);
    setValues({ ...values, comisionTotal: parseFloat(event.target.value) });
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, keyTab, ...other } = props;

    return (
      <div
        key={keyTab}
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            key={'box-' + keyTab}
            style={{ paddingTop: '1rem' }}
          >
            {'box-' + keyTab}
            {children}
          </Box>
        )}
      </div>
    );
  }

  function downloadQR() {
    let canvas: any = document.getElementById('QR-Negocio');
    if (canvas && selectedRecord) {
      let pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `Link Rapido - ${selectedRecord.nombre}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  const generateEpayRetailReport = async (report: string, batch: string = '') => {
    handleLoading(true);

    await NegociosServices.instance
      .getEpayRetailReport(report, mposValues.id, batch)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          let PDFReport: any;
          let dataReport: any;
          let title: string;
          ('');

          switch (report) {
            case '050000':
              dataReport = result;
              title = 'Monto Total y Pendientes de Cierre';
              break;
            case '060000':
              dataReport = {
                ...result.Details,
                ...result.Totals,
              };
              title = 'Cierre de Transacciones ';
              setOpenConfirmCloseTransactions(false);
              break;
            case '070000':
              dataReport = {
                ...result.Details,
                ...result.Totals,
              };
              title = 'Detalle Transacciones Pendientes de Cierre';
              break;
            case '080000':
              dataReport = {
                ...result.Details,
                ...result.Totals,
              };
              title = batch ? `Reimpresion Cierre Lote ${batch}` : 'Reimpresion del Ultimo Cierre';
              break;
            case '090000':
              dataReport = result;
              title = 'Ultimos 7 Cierres Realizados';
              break;
          }

          let tableReportCounter = 0;
          let reportTableIndex = 9;

          let tableReport = result?.Header?.ResumeSettledToPrint?.split('|') ?? [];
          if (report === '060000' || report === '070000' || report === '080000') {
            if (report === '080000') reportTableIndex = 12;

            if (report === '060000') reportTableIndex = 11;

            if (report === '070000') tableReport = result?.ResumeTransactionToPrint?.split('|') ?? [];

            for (let index = reportTableIndex; index < tableReport.length; index++) {
              if (tableReport[index].startsWith('------')) break;
              tableReportCounter++;
            }
          }

          PDFReport = () => (
            <Document>
              <Page
                size='A4'
                wrap={false}
                style={styles.page}
              >
                <Image
                  style={styles.image}
                  src={Images.instance.logo}
                />

                <Text style={styles.title}>{title}</Text>

                {report === '050000' ? (
                  <View style={styles.sectionsContainer}>
                    {Object.keys(dataReport).map((key, i) => (
                      <View
                        key={`${key}_i`}
                        style={styles.titleSection}
                      >
                        <Text style={styles.subtitle}>{key}:</Text>
                        <View style={styles.subContent}>
                          <Text style={styles.textContent}>Total: {dataReport[key].AmountTotal}</Text>
                          <Text style={styles.content}>Transacciones: {dataReport[key].Transactions}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ) : null}

                {report === '060000' ? (
                  tableReport && tableReport[0]?.length > 0 ? (
                    <View style={styles.titleSection}>
                      <Text style={styles.subtitle}>{tableReport[0]}</Text>
                      <View style={styles.subContent}>
                        <Text style={styles.textContent}>{tableReport[2]}</Text>
                        <Text style={styles.textContent}>{tableReport[3]}</Text>
                        <Text style={styles.textContent}>{tableReport[4]}</Text>
                        <Text style={styles.textContent}>{tableReport[5]}</Text>
                        <Text style={styles.textContent}>{tableReport[6]}</Text>
                      </View>
                      <View style={styles.subContent}>
                        <View style={styles.titleSection}>
                          <View style={styles.flexRow}>
                            {tableReport[8]
                              ?.split('  ')
                              .filter((space: string) => space !== '')
                              .map((head: string) => (
                                <Text
                                  key={head}
                                  style={styles.contentRowTitle}
                                >
                                  {head.trim()}
                                </Text>
                              ))}
                          </View>
                          {Array(Math.max(0, tableReportCounter))
                            .fill({})
                            .map((_, reportLine) => (
                              <View
                                key={`reportLine_060000_${reportLine}`}
                                style={styles.flexRow}
                              >
                                {tableReport[reportTableIndex + reportLine]
                                  ?.split(' ')
                                  .filter((space: string) => space !== '' && space !== 'Q')
                                  .map((head: string) => (
                                    <Text
                                      key={head}
                                      style={styles.contentRow}
                                    >
                                      {head.trim()}
                                    </Text>
                                  ))}
                              </View>
                            ))}
                        </View>
                      </View>
                      <View style={{ borderTop: '1px solid #777' }}>
                        <View style={styles.subContent}>
                          <View style={styles.titleSection}>
                            {Array(Math.max(0, tableReport.length - (reportTableIndex + tableReportCounter)))
                              .fill({})
                              .map((_, rowIndex) =>
                                !tableReport[reportTableIndex + tableReportCounter + rowIndex]?.startsWith('-----') ? (
                                  <View
                                    style={styles.flexRow}
                                    key={`restReport_060000_${rowIndex}`}
                                  >
                                    {tableReport[reportTableIndex + tableReportCounter + rowIndex]
                                      ?.split('  ')
                                      .filter((space: string) => space !== '')
                                      .map((head: string) => (
                                        <Text
                                          key={head}
                                          style={styles.contentRow}
                                        >
                                          {head.trim()}
                                        </Text>
                                      ))}
                                  </View>
                                ) : (
                                  <div />
                                ),
                              )}
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <Text>Sin contenido</Text>
                  )
                ) : null}

                {report === '070000' ? (
                  tableReport && tableReport[0]?.length > 0 ? (
                    <View style={styles.titleSection}>
                      <Text style={styles.subtitle}>{tableReport[0]}</Text>
                      <View style={styles.subContent}>
                        <Text style={styles.textContent}>{tableReport[2]}</Text>
                        <Text style={styles.textContent}>{tableReport[3]}</Text>
                        <Text style={styles.textContent}>{tableReport[4]}</Text>
                      </View>
                      <View style={styles.subContent}>
                        <View style={styles.titleSection}>
                          <View style={styles.flexRow}>
                            {tableReport[6]
                              ?.split('  ')
                              .filter((space: string) => space !== '')
                              .map((head: string) => (
                                <Text
                                  key={head}
                                  style={styles.contentRowTitle}
                                >
                                  {head.trim()}
                                </Text>
                              ))}
                          </View>
                          {Array(Math.max(0, tableReportCounter))
                            .fill({})
                            .map((_, reportLine) => (
                              <View
                                key={`reportLine_070000_${reportLine}`}
                                style={styles.flexRow}
                              >
                                {tableReport[reportTableIndex + reportLine]
                                  ?.split(' ')
                                  .filter((space: string) => space !== '' && space !== 'Q')
                                  .map((head: string) => (
                                    <Text
                                      key={head}
                                      style={styles.contentRow}
                                    >
                                      {head.trim()}
                                    </Text>
                                  ))}
                              </View>
                            ))}
                        </View>
                      </View>
                      <View style={{ borderTop: '1px solid #777' }}>
                        <View style={styles.subContent}>
                          <View style={styles.titleSection}>
                            {Array(Math.max(0, tableReport.length - (reportTableIndex + tableReportCounter)))
                              .fill({})
                              .map((_, rowIndex) =>
                                !tableReport[reportTableIndex + tableReportCounter + rowIndex]?.startsWith('-----') ? (
                                  <View
                                    style={styles.flexRow}
                                    key={`restReport_070000_${rowIndex}`}
                                  >
                                    {tableReport[reportTableIndex + tableReportCounter + rowIndex]
                                      ?.split('  ')
                                      .filter((space: string) => space !== '')
                                      .map((head: string) => (
                                        <Text
                                          key={head}
                                          style={styles.contentRow}
                                        >
                                          {head.trim()}
                                        </Text>
                                      ))}
                                  </View>
                                ) : (
                                  <div />
                                ),
                              )}
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <Text>Sin contenido</Text>
                  )
                ) : null}

                {report === '080000' ? (
                  tableReport && tableReport[0]?.length > 0 ? (
                    <View style={styles.titleSection}>
                      <Text style={styles.subtitle}>{tableReport[1]}</Text>
                      <View style={styles.subContent}>
                        <Text style={styles.textContent}>{tableReport[3]}</Text>
                        <Text style={styles.textContent}>{tableReport[4]}</Text>
                        <Text style={styles.textContent}>{tableReport[5]}</Text>
                        <Text style={styles.textContent}>{tableReport[6]}</Text>
                        <Text style={styles.textContent}>{tableReport[7]}</Text>
                      </View>
                      <View style={styles.subContent}>
                        <View style={styles.titleSection}>
                          <View style={styles.flexRow}>
                            {tableReport[9]
                              ?.split('  ')
                              .filter((space: string) => space !== '')
                              .map((head: string) => (
                                <Text
                                  key={head}
                                  style={styles.contentRowTitle}
                                >
                                  {head.trim()}
                                </Text>
                              ))}
                          </View>
                          {Array(Math.max(0, tableReportCounter))
                            .fill({})
                            .map((_, reportLine) => (
                              <View
                                key={`reportLine_080000_${reportLine}`}
                                style={styles.flexRow}
                              >
                                {tableReport[reportTableIndex + reportLine]
                                  ?.split(' ')
                                  .filter((space: string) => space !== '' && space !== 'Q')
                                  .map((head: string) => (
                                    <Text
                                      key={head}
                                      style={styles.contentRow}
                                    >
                                      {head.trim()}
                                    </Text>
                                  ))}
                              </View>
                            ))}
                        </View>
                      </View>
                      <View style={{ borderTop: '1px solid #777' }}>
                        <View style={styles.subContent}>
                          <View style={styles.titleSection}>
                            {Array(Math.max(0, tableReport.length - (reportTableIndex + tableReportCounter)))
                              .fill({})
                              .map((_, rowIndex) =>
                                !tableReport[reportTableIndex + tableReportCounter + rowIndex]?.startsWith('-----') ? (
                                  <View
                                    style={styles.flexRow}
                                    key={`restReport_080000_${rowIndex}`}
                                  >
                                    {tableReport[reportTableIndex + tableReportCounter + rowIndex]
                                      ?.split('  ')
                                      .filter((space: string) => space !== '')
                                      .map((head: string) => (
                                        <Text
                                          key={head}
                                          style={styles.contentRow}
                                        >
                                          {head.trim()}
                                        </Text>
                                      ))}
                                  </View>
                                ) : (
                                  <div />
                                ),
                              )}
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <Text>Sin contenido</Text>
                  )
                ) : null}

                {report === '090000' ? (
                  <View style={styles.sectionsContainer}>
                    {dataReport.DetailsBatch.map((key: any, i: number) => (
                      <View
                        key={`listBatch_${i}`}
                        style={styles.titleSection}
                      >
                        <Text style={styles.subtitle}>Item {i}:</Text>
                        <View style={styles.subContent}>
                          <View style={styles.titleSection}>
                            <Text style={styles.subsubtitle}>ListBatch:</Text>
                            <View style={styles.subContent}>
                              {Object.keys(dataReport.DetailsBatch[i].ListBatch).map((item: any, j: number) =>
                                dataReport.DetailsBatch[i].ListBatch[item]?.length ? (
                                  <Text
                                    style={styles.textContent}
                                    key={`listBatch_${item}_${i}_${j}`}
                                  >
                                    {item}: {dataReport.DetailsBatch[i].ListBatch[item]}
                                  </Text>
                                ) : (
                                  <div />
                                ),
                              )}
                              {Object.values(dataReport.DetailsBatch[i].ListBatch).every(
                                (item: any) => item === undefined || item.length === 0,
                              ) ? (
                                <Text style={styles.textContent}>Sin contenido</Text>
                              ) : (
                                <div />
                              )}
                            </View>
                          </View>
                          <View style={styles.titleSection}>
                            <Text style={styles.subsubtitle}>ListBatchProducts:</Text>
                            <View style={styles.subContent}>
                              {Object.keys(dataReport.DetailsBatch[i].ListBatchProducts).map((item: any, j: number) =>
                                dataReport.DetailsBatch[i].ListBatchProducts[item]?.length ? (
                                  <Text
                                    style={styles.textContent}
                                    key={`listBatchProducts_${item}_${i}_${j}`}
                                  >
                                    {item}: {dataReport.DetailsBatch[i].ListBatchProducts[item]}
                                  </Text>
                                ) : (
                                  <div />
                                ),
                              )}
                              {Object.values(dataReport.DetailsBatch[i].ListBatchProducts).every(
                                (item: any) => item === undefined || item.length === 0,
                              ) ? (
                                <Text style={styles.textContent}>Sin contenido</Text>
                              ) : (
                                <div />
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    ))}
                  </View>
                ) : null}
              </Page>
            </Document>
          );

          setReportPDFEpayRetail(PDFReport);
          handleLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const verHistorial = async () => {
    const params = '?origenLike=credenciales';
    await RazonesService.instance
      .getByNegocio(values.id, params)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsHistorialCredenciales(result);
          setShowClosedTransactions(true);
          handleLoading(false);
          setOpenHistorialRazones(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const getClosedTransactions = async () => {
    handleLoading(true);

    await NegociosServices.instance
      .getClosedTransactions(mposValues.id)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsClosedTransactions(result);
          setShowClosedTransactions(true);
          handleLoading(false);
          handleSuccessMessage(message);
          handleActiveSuccess(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  };

  const activarDesactivarCredencialesRazon = (event: React.MouseEvent<HTMLElement>, valor: string) => {
    if (valor) {
      setRazonCredenciales('');
      setLinksDocumentosRazones([]);
      setOpenCredenciales(true);
    } else {
      const accion = !values.disabledCredentials ? ' habilitadas' : ' deshabilitadas';
      handleErrorMessage(`Credenciales ya estan ${accion}`);
      handleActiveError(true);
    }
  };

  const activarDesactivarCredenciales = () => {
    const getUser = selectedRecord.rolUsuario.filter((user: any) => user.nombre === 'administrador');
    const UsuarioId = getUser && getUser[0] ? getUser[0]?.UsuarioId : 0;
    if (!loading) {
      NegociosServices.instance
        .activarDesactivarCredenciales(
          {
            activar: !values.disabledCredentials,
            razon: razonCredenciales,
            documentos: linksDocumentosRazones,
            userId: UsuarioId,
          },
          selectedRecord.id,
        )
        .then(response => {
          const { error, message, result } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            setValues({ ...values, ['disabledCredentials']: !values.disabledCredentials });
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
          setOpenCredenciales(false);
          setRazonCredenciales('');
          setLinksDocumentosRazones([]);
        })
        .catch(e => {
          handleErrorMessage(e.message);
          handleActiveError(true);
          setOpenCredenciales(false);
          setRazonCredenciales('');
          setLinksDocumentosRazones([]);
        });
    }
  };

  const updateTipoNegocio = (id: string, tipoNegocio: string) => {
    if (!loading) {
      //setLoading(true);
      NegociosServices.instance
        .updateTipoNegocio(+id, { tipoNegocio })
        .then(response => {
          //setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            refreshData();
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
            setLoading(false);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const getLimitePos = () => {
    if (Number(values.id) > 6502 && values?.tipoNegocio?.pais?.nombre === 'Guatemala') {
      return Number(temporalNegocioLimites[values?.tipoNegocio?.nombre]?.pos ?? 0) === 0
        ? 'Sin límite'
        : temporalNegocioLimites[values?.tipoNegocio?.nombre].pos;
    } else return Number(values?.tipoNegocio?.limitePOS) === 0 ? 'Sin límite' : values?.tipoNegocio?.limitePOS;
  };

  const getLimiteLink = () => {
    if (Number(values.id) > 6502 && values?.tipoNegocio?.pais?.nombre === 'Guatemala') {
      return Number(temporalNegocioLimites[values?.tipoNegocio?.nombre]?.link ?? 0) === 0
        ? 'Sin límite'
        : temporalNegocioLimites[values?.tipoNegocio?.nombre].link;
    } else return Number(values?.tipoNegocio?.limiteLink) === 0 ? 'Sin límite' : values?.tipoNegocio?.limiteLink;
  };

  const handleCloseCambioTipoNegocio = () => {
    setOpenCambioTipoNegocio(false);
    setTipoNegocioPreSeleccionado({});
  };

  const handleCloseCambioComision = () => {
    setOpenCambioComision(false);
  };

  const handleConfirmCambioTipoNegocio = async () => {
    if (tipoNegocioPreSeleccionado && tipoNegocioPreSeleccionado?.comision && tipoNegocioPreSeleccionado?.value) {
      setTipoNegocioTemp(prevTipos => ({ ...prevTipos, [values.id]: tipoNegocioPreSeleccionado?.value }));
      setComisionTotal(tipoNegocioPreSeleccionado?.comision);
      setValues({ ...values, comisionTotal: parseFloat(tipoNegocioPreSeleccionado?.comision) });
      setOpenCambioTipoNegocio(false);
    }
  };

  const handleConfirmCambioComision = async () => {
    if (selectedRecord.id && !isNaN(parseFloat(values.descuentoPaggo)) && !isNaN(comisionTotal)) {
      NegociosServices.instance
        .updateDescuentoPago(selectedRecord.id, { descuentoPaggo: values.descuentoPaggo, comisionTotal: comisionTotal })
        .then(response => {
          //setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            setOpenCambioComision(false);
            setOpenModalComision(false);
            cargaData();
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
            setLoading(false);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  const TipoNegocioSelect = () => {
    let afiliacion = '';
    if (
      values &&
      values.rolUsuario &&
      values.rolUsuario[0] &&
      values.rolUsuario[0].rol &&
      values.rolUsuario[0].rol.tipo
    ) {
      afiliacion = values.rolUsuario.find((rol: any) => rol.rol.nombre === 'administrador')?.rol?.tipo ?? '';
    }

    afiliacion = afiliacion.replace('administrador', '');
    if (afiliacion.startsWith('_')) afiliacion = afiliacion.substring(1);
    let tipos: any[] = [];
    const tiposPayfac = [
      { name: 'No formalizado', value: 'no_formalizado', comision: '4.96' },
      { name: 'No formalizado Medio', value: 'no_formalizado_medio', comision: '4.96' },
      { name: 'No formalizado Plus', value: 'no_formalizado_plus', comision: '4.96' },

      { name: 'title', value: 'Emprendedor / Comerciante' },
      { name: 'Pequeño Contribuyente', value: 'emprendedor', comision: '4.96' },
      { name: 'Pequeño Contribuyente Plus', value: 'emprendedor_plus', comision: '4.96' },

      { name: 'title', value: 'Persona individual' },
      { name: 'Persona individual', value: 'persona_individual_payfac', comision: '3.35' },
      { name: 'Persona individual Plus', value: 'persona_individual_plus', comision: '3.35' },

      { name: 'title', value: 'Sociedad anónima' },
      { name: 'Sociedad anónima', value: 'sociedad_anonima_payfac', comision: '3.35' },
      { name: 'Sociedad anónima Plus', value: 'sociedad_anonima_plus', comision: '3.35' },

      { name: 'title', value: 'Pequeño contribuyente' },
      { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'pequeno_contribuyente_payfac', comision: '4.96' },
      { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'pequeno_contribuyente_plus', comision: '4.96' },
    ];
    const tiposPayfacHonduras = [
      { name: 'No formalizado', value: 'h_no_formalizado' },
      { name: 'No formalizado Plus', value: 'h_no_formalizado_plus' },

      { name: 'title', value: 'Emprendedor / Comerciante' },
      { name: 'Pequeño Contribuyente', value: 'h_emprendedor' },
      { name: 'Pequeño Contribuyente Plus', value: 'h_emprendedor_plus' },

      { name: 'title', value: 'Sociedad anónima' },
      { name: 'Sociedad anónima', value: 'h_sociedad_anonima_payfac' },
      { name: 'Sociedad anónima Plus', value: 'h_sociedad_anonima_plus' },

      { name: 'title', value: 'Pequeño contribuyente' },
      { name: 'Sociedad Anónima Pequeño Contribuyente', value: 'h_pequeno_contribuyente_payfac' },
      { name: 'Sociedad Anónima Pequeño Contribuyente Plus', value: 'h_pequeno_contribuyente_plus' },
    ];

    const tiposAgregador = [
      { name: 'Persona individual', value: 'persona_individual' },
      { name: 'Pequeño contribuyente', value: 'pequeno_contribuyente' },
      { name: 'Sociedad anónima', value: 'sociedad_anonima' },
      { name: 'Servicios Profesionales', value: 'servicios_profesionales' },
      { name: 'Fundación', value: 'fundacion' },
      { name: 'Institución Educativa', value: 'institucion_educativa' },
    ];

    if (
      values?.tipoNegocio?.categoriaVisa === 'payfac' &&
      values.tipoNegocio &&
      values.tipoNegocio.pais &&
      values.tipoNegocio.pais.nombre
    ) {
      afiliacion = values?.tipoNegocio?.nombre;
      tipos.push({
        name: values?.tipoNegocio?.nombre,
        value: values?.tipoNegocio?.nombre,
      });
      tipos = values?.tipoNegocio?.pais.nombre === 'Honduras' ? tiposPayfacHonduras : tiposPayfac;
    } else {
      tipos = tiposAgregador;
    }

    if (!tipos.map(tipo => tipo.value).includes(afiliacion)) {
      tipos.push({
        name: afiliacion,
        value: afiliacion,
      });
    }

    const stateValue = tipoNegocioTemp[values.id];

    // let negocioId = '';
    // if (values && values.id) negocioId = values.id;

    const businessType: string = stateValue || afiliacion;

    let plusTypeIndex = tipos.findIndex(
      tipo =>
        tipo.value === `${businessType}_plus` ||
        tipo.value === `h_${businessType}_plus` ||
        tipo.value === `${businessType?.replace('_medio', '')}_plus`,
    );

    return (
      <FormControl
        fullWidth
        variant='outlined'
      >
        <InputLabel>Tipo de negocio</InputLabel>
        <Select
          fullWidth
          label='Tipo de negocio'
          disabled={loadingComision}
          value={businessType}
          name='tiponegocio'
          inputProps={{ 'aria-label': 'tipo_negocio' }}
          onChange={e => {
            console.log('e', e.target.value);
            // henry
            if (tipos) {
              const tipo = tipos.find(tipo => tipo.value === e.target.value);
              console.log('tipo', tipo);
              if (tipo) {
                setTipoNegocioPreSeleccionado(tipo);
                setOpenCambioTipoNegocio(true);
              }
            }
            //setTipoNegocioTemp(prevTipos => ({ ...prevTipos, [values.id]: e.target.value }));
          }}
        >
          {tipos.map((tipo, index) =>
            tipo.name === 'title' ? (
              <ListSubheader key={index}>
                <hr />
              </ListSubheader>
            ) : (
              <MenuItem
                value={tipo.value}
                key={`tipo_negocio_${tipo.value}`}
                disabled={tipo.value === afiliacion}
              >
                <Text
                  key={index}
                  style={{ fontWeight: plusTypeIndex === index ? 'bold' : 'normal' }}
                >
                  {tipo.name}
                </Text>
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
    );
  };

  const getAllVerifications = async () => {
    const response: any = await VerificationService.instance.getAllVerificationsNegocio(selectedRecord.id);
    setVerifications(response.result);
  };

  const getVerificationData = async () => {
    if (values && values.verificacionMetamap?.verificacionId) {
      const result: any = await VerificationService.instance.getVerificationByMetaMapId(
        values.verificacionMetamap.verificacionId,
      );
      setVerificationJson(result.result);
    }
  };

  const openVerificationDetail = async (params: any) => {
    try {
      if (params.hooks) {
        const hooks = JSON.parse(params.hooks);
        let urlRedirect = '';
        let verificacionId = params.verificacionId;
        for (let i = 0; i < hooks.length; i++) {
          if (hooks[i].matiDashboardUrl) {
            urlRedirect = hooks[i].matiDashboardUrl;
            break;
          }
        }

        if (!urlRedirect) {
          try {
            const result = await VerificationService.instance.getVerificationByMetaMapId(verificacionId);
            const identityId = result?.result?.identity?.id ?? '';
            urlRedirect = `https://dashboard.metamap.com/identity/${identityId}/verification/${verificacionId}`;
          } catch (error) {
            if (error instanceof Error) {
              handleErrorMessage(error.message);
            } else {
              handleErrorMessage('An unexpected error occurred.');
            }
          }
        }

        window.open(urlRedirect, '_blank');
      }
    } catch (err) {}
  };

  const updateFirmaFEL = (files: File[]) => {
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);
    DocumentosService.instance
      .uploadFileDocSinId(data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const links = result.map((file: any) => file.Location);
          const firstLink = links[0];

          const felService = new FELService();
          felService.updateFirmaFEL(selectedRecord.id, firstLink, facturacion?.firmaPassword || '').then(res => {
            if (res.error) {
              handleErrorMessage('Error al subir');
              handleActiveError(true);
              return;
            }

            handleSuccessMessage('Firma actualizada correctamente');
            handleActiveSuccess(true);
          });
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const updateFEL = (toggleEstado?: boolean) => () => {
    setFacturacionLoading(true);

    const felService = new FELService();
    felService
      .updateFEL(selectedRecord.id, {
        facturacion: {
          escenario: facturacion?.escenario,
          tipoFrase: facturacion?.tipoFrase,
          nombreNegocio: facturacion?.nombreNegocio,
          taxId: facturacion?.taxId,
          username: facturacion?.username,
          estado: toggleEstado
            ? facturacion?.estado === 'deshabilitado'
              ? 'habilitado'
              : 'deshabilitado'
            : facturacion?.estado,
          password: facturacion?.password,
          afiliacionIVA: facturacion?.afiliacionIVA,
        },
        establecimientos: {
          nombre: establecimientos?.nombre || '',
          codigo: establecimientos?.codigo || '',
          codigoPostal: establecimientos?.codigoPostal || '',
          direccion: establecimientos?.direccion || '',
          municipio: establecimientos?.municipio || '',
          departamento: establecimientos?.departamento || '',
        },
      })
      .then(res => {
        if (res.error) {
          setFacturacionLoading(false);
          handleErrorMessage('Revisar campos de 1. Facturación');
          handleActiveError(true);
          return;
        }

        if (toggleEstado) {
          setFacturacion(prev => ({
            ...prev,
            estado: prev?.estado === 'deshabilitado' ? 'habilitado' : 'deshabilitado',
          }));
          handleSuccessMessage(
            facturacion?.estado === 'deshabilitado'
              ? 'Facturación electrónica deshabilitada'
              : 'Facturación electrónica habilitada',
          );
        } else {
          handleSuccessMessage('Facturación electrónica actualizada correctamente');
        }

        handleActiveSuccess(true);
        setFacturacionLoading(false);
      });
  };

  const VerificacionMetaMap = () => {
    const verificaciones = verifications;
    const verificado: any = verificaciones.filter((verification: any) => verification.verificado == true);
    const noVerificado: any = verificaciones.filter((verification: any) => verification.verificado == false);
    let fullName = '';
    let dpi = '';
    if (verificado.length > 0 && verificationJson) {
      const documents = verificationJson?.documents;
      documents &&
        documents.length > 0 &&
        documents.forEach(document => {
          document &&
            document.steps.length > 0 &&
            document.steps.forEach(step => {
              if (step.id === 'document-reading' && step?.data?.fullName?.value && step?.data?.documentNumber?.value) {
                fullName = step.data.fullName.value;
                dpi = step.data.documentNumber.value;
                return;
              }
            });
          return;
        });
    }

    return (
      <Paper
        elevation={3}
        style={{ padding: '1em' }}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <Chip
            style={{ padding: '8px', fontSize: '16px', width: '100%' }}
            size='medium'
            onClick={() =>
              openVerificationDetail(
                verificado.length > 0 ? verificado[0] : noVerificado.length > 0 ? noVerificado[0] : null,
              )
            }
            label={
              verificado.length > 0
                ? 'MetaMap | Verificado'
                : noVerificado.length > 0
                ? 'MetaMap | Pendiente'
                : 'MetaMap | Sin verificar'
            }
            color={verificado.length > 0 ? 'primary' : 'default'}
          />
        </div>
        {verificado.length > 0 && (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ padding: '1em' }}
          >
            <Typography
              variant='body1'
              style={{ fontSize: '14px' }}
            >
              NOMBRE: {fullName}
            </Typography>
            <Typography
              variant='body1'
              style={{ fontSize: '14px' }}
            >
              DPI: {dpi}
            </Typography>
          </div>
        )}
      </Paper>
    );
  };

  useEffect(() => {
    const getData = async () => {
      const felService = new FELService();
      const getFel = await felService.getFEL(values.id + '');
      setFacturacion(getFel.result);
    };

    getData();
  }, [values.id]);

  return (
    <>
      {loading === true ? (
        <PageLoader loading={loading} />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Box
              mt={1}
              p={2}
              component={Paper}
            >
              <Grid
                container
                direction='column'
                spacing={2}
              >
                <Grid
                  md={showArchivos ? 6 : 12}
                  xs={12}
                  item
                >
                  <Typography
                    variant='h6'
                    gutterBottom
                  >
                    Detalles del negocio
                    <IconButton
                      onClick={refreshData}
                      aria-label='delete'
                      color='primary'
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Typography>

                  <Typography
                    variant='subtitle1'
                    gutterBottom
                  >
                    Información del negocio <strong>{selectedRecord?.id}</strong>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ position: 'relative' }}
                >
                  {tabsNoMostrar.includes('ARCHIVOS') ? null : (
                    <Button
                      variant='contained'
                      color='secondary'
                      size='large'
                      onClick={handleToggle}
                      className='toggle-button-show'
                      style={{ display: showArchivos ? 'none' : 'inline-flex' }}
                      startIcon={<FileCopyTwoToneIcon />}
                    >
                      Archivos
                    </Button>
                  )}
                  <Tabs
                    value={pageSelected}
                    scrollButtons='on'
                    variant='scrollable'
                    onChange={handleChangePage}
                    style={{ backgroundColor: '#efefef' }}
                    indicatorColor='primary'
                  >
                    {tabsNoMostrar.includes('DATA') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Data'
                        id='scrollable-force-tab-0'
                        aria-controls='scrollable-force-tabpanel-0'
                      />
                    )}
                    {tabsNoMostrar.includes('CREDENCIALES') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Credenciales'
                        id='scrollable-force-tab-1'
                        aria-controls='scrollable-force-tabpanel-1'
                      />
                    )}
                    {tabsNoMostrar.includes('POS') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='POS'
                        id='scrollable-force-tab-2'
                        aria-controls='scrollable-force-tabpanel-2'
                      />
                    )}
                    {tabsNoMostrar.includes('INFORMACION_BANCARIA') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Información bancaria'
                        id='scrollable-force-tab-4'
                        aria-controls='scrollable-force-tabpanel-4'
                      />
                    )}
                    {tabsNoMostrar.includes('TRANSACCIONES_ENLACE') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Transacciones enlace'
                        id='scrollable-force-tab-5'
                        aria-controls='scrollable-force-tabpanel-5'
                      />
                    )}
                    {tabsNoMostrar.includes('TRANSACCIONES_TARJETA') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Transacciones tarjeta'
                        id='simple-tab-6'
                        aria-controls='scrollable-force-tabpanel-6'
                      />
                    )}
                    {tabsNoMostrar.includes('QR') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='QR'
                        id='scrollable-force-tab-7'
                        aria-controls='scrollable-force-tabpanel-7'
                      />
                    )}
                    {tabsNoMostrar.includes('CONTRATO') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Contrato'
                        id='scrollable-force-tab-8'
                        aria-controls='scrollable-force-tabpanel-8'
                      />
                    )}
                    {tabsNoMostrar.includes('VERIFICATION_DATA') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Verification data'
                        id='scrollable-force-tab-9'
                        aria-controls='scrollable-force-tabpanel-8'
                      />
                    )}
                    {tabsNoMostrar.includes('ACCIONES') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Acciones'
                        id='scrollable-force-tab-9'
                        aria-controls='scrollable-force-tabpanel-8'
                      />
                    )}
                    {tabsNoMostrar.includes('FEL') ? null : (
                      <Tab
                        style={{ minWidth: 'auto', paddingRight: 20, paddingLeft: 20 }}
                        label='Facturación'
                        id='scrollable-force-tab-20'
                        aria-controls='scrollable-force-tabpanel-8'
                      />
                    )}
                  </Tabs>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  className={`negocio ${showArchivos ? 'full-width' : ''}`}
                  item
                  xs={12}
                >
                  {tabsDinamicos.map((tab, index) => {
                    if (tab === 'DATA') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-0'
                              >
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Nombre'
                                    variant='outlined'
                                    fullWidth
                                    value={values.nombre}
                                    onChange={handleChange('nombre')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Razón social'
                                    variant='outlined'
                                    fullWidth
                                    value={values.razonSocial}
                                    onChange={handleChange('razonSocial')}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel>Tipo de Régimen</InputLabel>
                                    <Select
                                      labelId='documento-select-label'
                                      id='documento-select'
                                      label='Tipo de régimen'
                                      value={values?.TipoRegimenId}
                                      onChange={handleChangeTipoRegimen()}
                                    >
                                      <MenuItem value={undefined}>
                                        <em>No seleccionado</em>
                                      </MenuItem>
                                      {tiposRegimenes.map((tipo: any) => {
                                        return (
                                          <MenuItem
                                            key={tipo.id}
                                            value={tipo.id}
                                          >
                                            {tipo.nombre}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel id='actividad-select-label'>Actividad del negocio</InputLabel>
                                    <Select
                                      labelId='actividad-select-label'
                                      id='negocio-select'
                                      label='Actividad del negocio'
                                      value={values.ActividadId}
                                      onChange={handleChange('ActividadId')}
                                    >
                                      <MenuItem value=''>
                                        <em>Seleccione</em>
                                      </MenuItem>
                                      {selectedRecord &&
                                      selectedRecord.tipoNegocio &&
                                      selectedRecord.tipoNegocio.pais &&
                                      (selectedRecord.tipoNegocio.pais.nombre === 'Guatemala' ||
                                        selectedRecord.tipoNegocio.pais.nombre === 'Honduras')
                                        ? activities
                                            .filter(
                                              (act: any) =>
                                                (selectedRecord.tipoNegocio.pais.nombre === 'Guatemala' &&
                                                  act.paisId === 1) ||
                                                (selectedRecord.tipoNegocio.pais.nombre === 'Honduras' &&
                                                  act.paisId === 2),
                                            )
                                            .map((act: any) => {
                                              return (
                                                <MenuItem
                                                  key={act.id}
                                                  value={act.id}
                                                >
                                                  {act.nombre.toLocaleUpperCase()}
                                                </MenuItem>
                                              );
                                            })
                                        : null}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <Autocomplete
                                      id='negocio-select'
                                      defaultValue={() => {
                                        return subActivities.find(
                                          (subActividadObj: any) => subActividadObj?.id === values.SubActividadId,
                                        );
                                      }}
                                      options={subActivities.map((subActivity: any) => {
                                        return subActivity;
                                      })}
                                      onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                          setValues({ ...values, ['SubActividadId']: newValue.id });
                                        }
                                      }}
                                      getOptionLabel={(option: any) =>
                                        `${option.codigoMcc} -  ${option.nombre?.toLocaleUpperCase()} `
                                      }
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label='SubActividad del negocio'
                                          variant='outlined'
                                          defaultValue={() => {
                                            return subActivities.find(
                                              (subActividadObj: any) => subActividadObj?.id === values.SubActividadId,
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth={true}
                                    variant='outlined'
                                  >
                                    <InputLabel variant='outlined'>Porcentaje Comisión Total %</InputLabel>
                                    <OutlinedInput
                                      label='Porcentaje Comisión Total %'
                                      fullWidth
                                      disabled
                                      color='primary'
                                      style={{ backgroundColor: 'rgb(239, 239, 239)' }}
                                      value={comisionTotal}
                                      //onChange={handleChangePorcentajeComisionTotal()}
                                      endAdornment={
                                        <InputAdornment position='end'>
                                          <Button
                                            disabled={loadingComision}
                                            onClick={() => setOpenModalComision(true)}
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                          >
                                            {loadingComision ? 'Calculando...' : 'Ver detalles'}
                                          </Button>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <TextField
                                      label='Porcentaje de comisión Paggo en %'
                                      variant='outlined'
                                      fullWidth
                                      value={comisionPaggoConDescuento}
                                      onChange={handleChangePorcentajeComisionPaggo('descuentoPaggo')}
                                    />
                                    <Typography style={{ color: '#7D8CA1' }}>
                                      {'Total de comisión ' + getComisionTotal().comisionTotal + '%'} 
                                      <Button size="small" className={classes.margin} onClick={() => setOpenModalComision(true)}>
                                        Ver detalles
                                      </Button>
                                    </Typography>
                                  </Grid>*/}
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel id='departammento-select-label'>Departamento</InputLabel>
                                    <Select
                                      labelId='departammento-select-label'
                                      id='dep-negocio-select'
                                      label='Departamento'
                                      value={values?.departamento?.toLocaleLowerCase()}
                                      onChange={handleChange('departamento')}
                                    >
                                      <MenuItem value=''>
                                        <em>Seleccione</em>
                                      </MenuItem>
                                      {selectedRecord &&
                                      selectedRecord.tipoNegocio &&
                                      selectedRecord.tipoNegocio.pais &&
                                      selectedRecord.tipoNegocio.pais.nombre &&
                                      departamentos &&
                                      departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()] &&
                                      Array.isArray(departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()])
                                        ? departamentos[selectedRecord.tipoNegocio.pais.nombre.toLowerCase()].map(
                                            (dep: any) => {
                                              return (
                                                <MenuItem
                                                  key={dep}
                                                  value={dep.toLocaleLowerCase()}
                                                >
                                                  {dep}
                                                </MenuItem>
                                              );
                                            },
                                          )
                                        : null}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel id='municipio-select-label'>Municipio</InputLabel>
                                    <Select
                                      labelId='municipio-select-label'
                                      id='muni-negocio-select'
                                      label='Municipio'
                                      value={values.municipio.toLocaleLowerCase()}
                                      onChange={handleChange('municipio')}
                                    >
                                      <MenuItem value=''>
                                        <em>Seleccione</em>
                                      </MenuItem>
                                      {values &&
                                      values.departamento &&
                                      listamunicipios &&
                                      listamunicipios[values?.departamento?.toLocaleLowerCase()] &&
                                      Array.isArray(listamunicipios[values?.departamento?.toLocaleLowerCase()])
                                        ? listamunicipios[values?.departamento?.toLocaleLowerCase()].map(
                                            (municipio: any) => {
                                              return (
                                                <MenuItem
                                                  key={municipio}
                                                  value={municipio.toLocaleLowerCase()}
                                                >
                                                  {municipio}
                                                </MenuItem>
                                              );
                                            },
                                          )
                                        : null}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Dirección'
                                    variant='outlined'
                                    fullWidth
                                    value={values.numeroCasa}
                                    onChange={handleChange('numeroCasa')}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Teléfono'
                                    variant='outlined'
                                    fullWidth
                                    value={values.telefono}
                                    onChange={handleChange('telefono')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Teléfono Adicional'
                                    variant='outlined'
                                    fullWidth
                                    value={values.telefonoAdicional}
                                    onChange={e => handleTelefonoAdicional(e)}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='NIT'
                                    variant='outlined'
                                    fullWidth
                                    value={values.nit}
                                    onChange={handleChange('nit')}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <Autocomplete
                                    multiple
                                    disableClearable
                                    id='tags-readOnly'
                                    options={listadoMotivos}
                                    getOptionLabel={option => option.title}
                                    value={motivoTemp}
                                    groupBy={option => option.process}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        key={params.id}
                                        variant='outlined'
                                        label='Motivos de perdida'
                                        color='primary'
                                        fullWidth
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value.map((value, index) => (
                                        <Chip
                                          key={value.id}
                                          variant='default'
                                          label={value.title}
                                          style={{ margin: 5 }}
                                        />
                                      ))
                                    }
                                    onChange={(event, value) => {
                                      setMotivoTemp(value);
                                      setValues({
                                        ...values,
                                        motivoPerdida: value,
                                        NegocioPerdido: value.length === 0 ? [] : values.NegocioPerdido,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  {TipoNegocioSelect()}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Limite transaccional'
                                    variant='outlined'
                                    disabled
                                    fullWidth
                                    value={values.tipoNegocio.limitePOS ? values.tipoNegocio.limitePOS : ''}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Limite POS'
                                    variant='outlined'
                                    disabled
                                    fullWidth
                                    value={getLimitePos()}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <TextField
                                    label='Limite Link'
                                    variant='outlined'
                                    fullWidth
                                    disabled
                                    value={getLimiteLink()}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  {VerificacionMetaMap()}
                                </Grid>
                                <Grid item>
                                  <LoadingButton
                                    loading={loading}
                                    disabled={loadingComision}
                                    color='primary'
                                    variant='contained'
                                    title='Editar negocio'
                                    onClick={editNegocio}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'CREDENCIALES') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-1'
                              >
                                <Grid
                                  item
                                  className='p-10'
                                >
                                  <FormControl
                                    component='fieldset'
                                    variant='standard'
                                  >
                                    <FormHelperText>CREDENCIALES ACTIVADAS</FormHelperText>
                                    <ToggleButtonGroup
                                      value={!values.disabledCredentials ? 'si' : 'no'}
                                      exclusive
                                      onChange={activarDesactivarCredencialesRazon}
                                    >
                                      <ToggleButton
                                        style={{
                                          width: '80px',
                                          backgroundColor: values.disabledCredentials ? '#5166AF' : 'transparent',
                                          color: values.disabledCredentials ? 'white' : 'rgba(0, 0, 0, 0.54)',
                                        }}
                                        value='no'
                                      >
                                        No
                                      </ToggleButton>
                                      <ToggleButton
                                        style={{
                                          width: '80px',
                                          backgroundColor: !values.disabledCredentials ? '#5166AF' : 'transparent',
                                          color: !values.disabledCredentials ? 'white' : 'rgba(0, 0, 0, 0.54)',
                                        }}
                                        value='si'
                                      >
                                        Si
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                    <FormHelperText>
                                      <Button
                                        onClick={verHistorial}
                                        color='inherit'
                                      >
                                        <small>Ver historial</small> <HistoryIcon fontSize='small'></HistoryIcon>
                                      </Button>
                                    </FormHelperText>
                                  </FormControl>
                                  <Modal
                                    className={classes.modal}
                                    open={openCredenciales}
                                    onClose={handleCloseModal}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={openCredenciales}>
                                      <div className={classes.paper}>
                                        <h2 id='transition-modal-title'>
                                          {values.disabledCredentials ? 'Habilitar ' : 'Deshabilitar '} Credenciales
                                        </h2>
                                        <p id='transition-modal-description'></p>

                                        <Grid
                                          container
                                          direction='column'
                                          spacing={2}
                                        >
                                          <Grid
                                            item
                                            style={{ padding: '10px' }}
                                          >
                                            <p>
                                              INGRESA LA RAZON POR LA QUE VAS A{' '}
                                              {values.disabledCredentials ? 'HABILITAR ' : 'DESHABILITAR '} (
                                              <span style={{ color: 'red' }}>*</span>){' '}
                                            </p>
                                            <TextAreaAutoSize
                                              onChange={e => {
                                                setRazonCredenciales(e.target.value);
                                              }}
                                              autoFocus
                                              style={{ width: '100%', padding: '10px' }}
                                              minRows={8}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <LoadingButton
                                              loading={uploading}
                                              color='primary'
                                              variant='contained'
                                              title='Agregar documentos de respaldo'
                                              onClick={() => {
                                                handleOpenUpload();
                                              }}
                                            />
                                            <DropzoneDialog
                                              dropzoneText='Arrastra los documentos o presiona aquí'
                                              previewText='Vista prévia'
                                              dialogTitle={'Elige los archivos'}
                                              submitButtonText='Subir archivos'
                                              cancelButtonText='Cancelar'
                                              open={openUploadFile}
                                              onSave={handleDocumentosRazones}
                                              acceptedFiles={[
                                                'image/jpeg',
                                                'image/png',
                                                'image/bmp',
                                                'application/pdf',
                                              ]}
                                              showPreviews={true}
                                              maxFileSize={25000000}
                                              filesLimit={10}
                                              onClose={handleCloseUpload}
                                            />
                                          </Grid>
                                          <Grid
                                            container
                                            direction='column'
                                            justifyContent='center'
                                            alignItems='center'
                                            style={{ padding: '8px' }}
                                          >
                                            <Grid item>
                                              <Button
                                                disabled={
                                                  razonCredenciales.trim().length > 0 && !uploading ? false : true
                                                }
                                                onClick={() => activarDesactivarCredenciales()}
                                                style={{ textAlign: 'center' }}
                                                color={values.disabledCredentials ? 'default' : 'secondary'}
                                                variant='contained'
                                              >
                                                {values.disabledCredentials ? (
                                                  <>
                                                    <CheckIcon /> &nbsp; Habilitar{' '}
                                                  </>
                                                ) : (
                                                  <>
                                                    <BlockIcon /> &nbsp; Deshabilitar{' '}
                                                  </>
                                                )}
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Fade>
                                  </Modal>
                                  <Modal
                                    className={classes.modal}
                                    open={openHistorialRazones}
                                    onClose={handleCloseModal}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={openHistorialRazones}>
                                      <div className={classes.paper}>
                                        <p id='transition-modal-description'>Registro historico</p>

                                        <Grid
                                          container
                                          direction='column'
                                          spacing={2}
                                        >
                                          <Grid
                                            item
                                            style={{ height: 700, width: 'auto' }}
                                          >
                                            <DataGrid
                                              isRowSelectable={(params: GridRowParams) => false}
                                              loading={loading}
                                              rows={rowsHistorialCredenciales}
                                              columns={columnsHistorialCredenciales}
                                              pageSize={10}
                                              rowsPerPageOptions={[10, 25, 50, 100]}
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Fade>
                                  </Modal>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='Afiliación Visa'
                                    variant='outlined'
                                    fullWidth
                                    value={values.afiliacionVisa}
                                    onChange={handleChange('afiliacionVisa')}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='Terminal Visa'
                                    variant='outlined'
                                    fullWidth
                                    value={values.terminalVisa}
                                    onChange={handleChange('terminalVisa')}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='User Visa'
                                    variant='outlined'
                                    fullWidth
                                    value={values.userVisa}
                                    onChange={handleChange('userVisa')}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='Password Visa'
                                    variant='outlined'
                                    fullWidth
                                    value={values.passwordVisa}
                                    onChange={handleChange('passwordVisa')}
                                  />
                                </Grid>
                                <Grid item>
                                  <LoadingButton
                                    loading={loading}
                                    color='primary'
                                    variant='contained'
                                    title='Editar Credenciales'
                                    onClick={updateCredencialesNegocio}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'POS') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-2'
                              >
                                <Grid item>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={10}
                                    >
                                      <Typography
                                        variant='h6'
                                        gutterBottom
                                      >
                                        Terminales mPOS
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                    >
                                      <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={() => setOpenModalMpos(true)}
                                      >
                                        Crear
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 'calc(100vh - 390px)', width: 'auto' }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsMpos}
                                    columns={columnsMpos}
                                    rowHeight={50}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'INFORMACION_BANCARIA') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-4'
                              >
                                <Grid item>
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel id='banco-select-label'>Banco</InputLabel>
                                    <Select
                                      labelId='banco-select-label'
                                      id='tipo-cuenta-select'
                                      label='Banco'
                                      value={informacionBancaria.banco}
                                      onChange={handleChangeInformacionBancaria('banco')}
                                    >
                                      <MenuItem value=''>
                                        <em>Seleccione</em>
                                      </MenuItem>
                                      {(selectedRecord.tipoNegocio.pais.nombre === 'Honduras'
                                        ? listaBancosHonduras
                                        : listabancos
                                      ).map((act: any, index: number) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={act.toLocaleLowerCase()}
                                          >
                                            {act.toLocaleUpperCase()}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item>
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                  >
                                    <InputLabel id='tipocuenta-select-label'>Tipo de cuenta</InputLabel>
                                    <Select
                                      labelId='tipocuenta-select-label'
                                      id='tipo-cuenta-select'
                                      label='Tipo de cuenta'
                                      value={informacionBancaria.tipoCuenta}
                                      onChange={handleChangeInformacionBancaria('tipoCuenta')}
                                    >
                                      <MenuItem value=''>
                                        <em>Seleccione</em>
                                      </MenuItem>
                                      {(informacionBancaria.banco === 'banco azteca'
                                        ? tiposcuenta.filter(tipo => tipo.toLocaleLowerCase() === 'monetaria')
                                        : tiposcuenta
                                      ).map((act, index) => (
                                        <MenuItem
                                          key={index}
                                          value={act.toLocaleLowerCase()}
                                        >
                                          {act.toLocaleUpperCase()}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='Nombre de la cuenta'
                                    variant='outlined'
                                    fullWidth
                                    value={informacionBancaria.nombreCuenta}
                                    onChange={handleChangeInformacionBancaria('nombreCuenta')}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label='Numero de cuenta'
                                    variant='outlined'
                                    fullWidth
                                    value={informacionBancaria.numeroCuenta}
                                    onChange={handleChangeInformacionBancaria('numeroCuenta')}
                                  />
                                </Grid>

                                <Grid item>
                                  <LoadingButton
                                    loading={loading}
                                    color='primary'
                                    variant='contained'
                                    title='Actualizar información bancaria'
                                    onClick={editInformacionBancaria}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'TRANSACCIONES_ENLACE') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-5'
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingBottom: '3px', paddingTop: '3px' }}
                                >
                                  <>
                                    <Grid
                                      container
                                      direction='column'
                                    ></Grid>
                                    <Grid
                                      container
                                      spacing={3}
                                      key='t-0'
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={10}
                                      >
                                        <TextField
                                          label='Buscar por:'
                                          type='search'
                                          placeholder='Id, nombre, status, cliente, concepto, autorizacion, enlace, id de liquidacion'
                                          variant='filled'
                                          fullWidth
                                          name='search'
                                          value={searchValueEnlace}
                                          onChange={handleSearchChangeEnlace}
                                          onKeyDown={handleKeyDownChangeEnlace}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        style={{ paddingTop: '18px' }}
                                      >
                                        <Button
                                          fullWidth
                                          variant='contained'
                                          color='primary'
                                          onClick={filtrarEnlace}
                                        >
                                          Buscar
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 'calc(85vh - 308px)', width: 'auto' }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsTransaccionesEnlace}
                                    columns={columnsTransaccionesEnlace}
                                    rowHeight={50}
                                    paginationMode='server'
                                    pageSize={pageSizeEnlace}
                                    rowCount={totalPagesEnlace * pageSizeEnlace}
                                    hideFooterPagination
                                  />
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Pagination
                                      count={totalPagesEnlace}
                                      page={pageEnlace}
                                      shape='rounded'
                                      size='large'
                                      color='primary'
                                      style={{ padding: '8px', textAlign: 'center' }}
                                      onChange={(event, value) => setPageEnlace(value)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'TRANSACCIONES_TARJETA') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-6'
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingBottom: '3px', paddingTop: '3px' }}
                                >
                                  <>
                                    <Grid
                                      container
                                      direction='column'
                                    ></Grid>
                                    <Grid
                                      container
                                      spacing={3}
                                      key='t-0'
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={10}
                                      >
                                        <TextField
                                          label='Buscar por:'
                                          type='search'
                                          placeholder='Id, nombre, status, cliente, concepto, autorizacion, enlace, id de liquidacion'
                                          variant='filled'
                                          fullWidth
                                          name='search'
                                          value={searchValueTarjeta}
                                          onChange={handleSearchChangeTarjeta}
                                          onKeyDown={handleKeyDownChangeTarjeta}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        style={{ paddingTop: '18px' }}
                                      >
                                        <Button
                                          fullWidth
                                          variant='contained'
                                          color='primary'
                                          onClick={filtrarTarjeta}
                                        >
                                          Buscar
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 'calc(85vh - 308px)', width: 'auto' }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsTransaccionesTarjeta}
                                    columns={columnsTransaccionesTarjeta}
                                    rowHeight={50}
                                    paginationMode='server'
                                    pageSize={pageSizeTarjeta}
                                    rowCount={totalPagesTarjeta * pageSizeTarjeta}
                                    hideFooterPagination
                                  />
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Pagination
                                      count={totalPagesTarjeta}
                                      page={pageTarjeta}
                                      shape='rounded'
                                      size='large'
                                      color='primary'
                                      style={{ padding: '8px', textAlign: 'center' }}
                                      onChange={(event, value) => setPageTarjeta(value)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'QR') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-7'
                                style={{ paddingTop: '1rem' }}
                              >
                                <Grid
                                  item
                                  style={{ width: 'auto' }}
                                >
                                  <Typography
                                    variant='h6'
                                    gutterBottom
                                  >
                                    Código QR
                                  </Typography>
                                </Grid>
                                {linkRapido && (
                                  <Grid
                                    item
                                    style={{ width: 'auto', alignItems: 'center' }}
                                  >
                                    <QRCode
                                      id='QR-Negocio'
                                      value={linkRapido}
                                      size={290}
                                      level={'H'}
                                      includeMargin={true}
                                      imageSettings={{
                                        src: Images.instance.logoInvitacion,
                                        excavate: true,
                                        height: 100,
                                        width: 100,
                                      }}
                                      fgColor={'#5166AF'}
                                    />
                                  </Grid>
                                )}
                                {linkRapido && (
                                  <Grid
                                    item
                                    style={{ width: 'auto' }}
                                  >
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      onClick={downloadQR}
                                    >
                                      Descargar QR
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'CONTRATO') {
                      {
                        if (pageSelected === index)
                          return (
                            <Box
                              key={tab}
                              style={{ paddingTop: '1rem', marginTop: '1rem' }}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={3}
                                key='t-7'
                              >
                                <Grid
                                  item
                                  style={{ width: 'auto' }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ padding: '5px 20px' }}
                                    onClick={handleSetOpenModalFileContrato}
                                  >
                                    Subir contrato
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 500, width: 'auto' }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsContrato}
                                    columns={columnsContratos}
                                    rowHeight={38}
                                    pagination={undefined}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          );
                      }
                    }
                    if (tab === 'VERIFICATION_DATA') {
                      {
                        if (pageSelected === index)
                          return (
                            <TabVerification
                              key={tab}
                              verificationData={values}
                            />
                          );
                      }
                    }
                    if (tab === 'ACCIONES') {
                      {
                        if (pageSelected === index) return <Logs NegocioId={selectedRecord.id} />;
                      }
                    }
                    if (tab === 'FACTURACION') {
                      if (pageSelected === index)
                        return (
                          <>
                            <Box
                              key={tab}
                              style={{
                                paddingTop: '1rem',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '15px',
                              }}
                            >
                              <Box
                                gridColumn='1/3'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexDirection: 'row',
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant='h6'
                                    style={{ fontWeight: 'bold' }}
                                    gutterBottom
                                  >
                                    1. Facturación
                                  </Typography>
                                  <Typography gutterBottom>
                                    Credenciales de acceso recibos en el correo de contacto
                                  </Typography>
                                </Box>

                                <Box
                                  height='100%'
                                  style={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <CircularProgress
                                    size={20}
                                    style={{ marginRight: 20, display: facturacionLoading ? 'block' : 'none' }}
                                  />

                                  <Button
                                    color='primary'
                                    style={{ marginRight: 10 }}
                                    size='small'
                                    startIcon={<SaveOutlined />}
                                    variant='outlined'
                                    onClick={updateFEL()}
                                  >
                                    Guardar cambios
                                  </Button>

                                  <Chip
                                    style={{
                                      color: 'white',
                                      backgroundColor: facturacion?.estado === 'deshabilitado' ? 'green' : '#D35057',
                                    }}
                                    label={facturacion?.estado === 'deshabilitado' ? 'Activar FEL' : 'Desactivar FEL'}
                                    disabled={facturacionLoading}
                                    onClick={updateFEL(true)}
                                    clickable
                                    size='medium'
                                  />
                                </Box>
                              </Box>

                              <TextField
                                label='NIT'
                                name='taxId'
                                value={facturacion?.taxId}
                                onChange={onChangeFacturacion}
                                variant='outlined'
                              />

                              <TextField
                                name='username'
                                value={facturacion?.username}
                                onChange={onChangeFacturacion}
                                label='Usuario'
                                variant='outlined'
                              />

                              <TextField
                                name='password'
                                value={facturacion?.password}
                                onChange={onChangeFacturacion}
                                label='Contraseña'
                                variant='outlined'
                              />

                              <Box
                                style={{ display: 'flex', height: '100%' }}
                                alignItems='center'
                              >
                                <Link
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  href='https://felgtaws.digifact.com.gt/Intel/MarketingDashboard'
                                  target='_blank'
                                >
                                  <img
                                    src='/assets/images/LogoDigifact.png'
                                    alt='Logo Digifact'
                                    style={{
                                      height: 30,
                                      width: 30,
                                      marginRight: 10,
                                    }}
                                  />

                                  <Button
                                    color='primary'
                                    size='small'
                                    variant='contained'
                                  >
                                    Ingresar a DIGIFACT
                                  </Button>
                                </Link>
                              </Box>

                              <Box
                                gridColumn='1/3'
                                marginTop={2}
                              >
                                <hr />
                                <Typography
                                  style={{ marginTop: '1rem', fontWeight: 'bold' }}
                                  variant='h6'
                                  gutterBottom
                                >
                                  2. Establecimiento
                                </Typography>

                                <Typography gutterBottom>
                                  Ver sección de Establecimientos en Digifact.partners
                                </Typography>
                              </Box>

                              <TextField
                                label='Código'
                                onChange={onChangeEstablecimientos}
                                name='establecimiento-codigo'
                                value={establecimientos?.codigo}
                                variant='outlined'
                              />

                              <TextField
                                label='Nombre'
                                name='establecimiento-nombre'
                                onChange={onChangeEstablecimientos}
                                value={establecimientos?.nombre}
                                variant='outlined'
                              />

                              <TextField
                                label='Direccion'
                                name='establecimiento-direccion'
                                onChange={onChangeEstablecimientos}
                                value={establecimientos?.direccion}
                                variant='outlined'
                              />

                              <TextField
                                name='establecimiento-municipio'
                                onChange={onChangeEstablecimientos}
                                value={establecimientos?.municipio}
                                label='Municipio'
                                variant='outlined'
                              />

                              <TextField
                                label='Departamento'
                                name='establecimiento-departamento'
                                onChange={onChangeEstablecimientos}
                                value={establecimientos?.departamento}
                                variant='outlined'
                              />
                              <TextField
                                label='Código postal'
                                onChange={onChangeEstablecimientos}
                                name='establecimiento-codigoPostal'
                                value={establecimientos?.codigoPostal}
                                variant='outlined'
                              />

                              <Box
                                gridColumn='1/3'
                                marginTop={2}
                              >
                                <hr />
                                <Typography
                                  style={{ marginTop: '1rem', fontWeight: 'bold' }}
                                  variant='h6'
                                  gutterBottom
                                >
                                  3. Información fiscal
                                </Typography>

                                <Typography gutterBottom>
                                  Ver sección de Información Fiscal en Digifact.partners
                                </Typography>
                              </Box>

                              <TextField
                                label='Nombre'
                                name='nombreNegocio'
                                onChange={onChangeFacturacion}
                                value={facturacion?.nombreNegocio}
                                variant='outlined'
                              />

                              <FormControl variant='outlined'>
                                <InputLabel>Tipo de afiliacion</InputLabel>
                                <Select
                                  name='afiliacionIVA'
                                  onChange={e =>
                                    setFacturacion(prev => ({
                                      ...prev,
                                      estado: prev?.estado || 'deshabilitado',
                                      afiliacionIVA: e.target.value?.toString() || '',
                                    }))
                                  }
                                  label='Tipo de afiliacion'
                                  value={facturacion?.afiliacionIVA || ''}
                                >
                                  <MenuItem value='PEQ'>PEQ</MenuItem>
                                  <MenuItem value='GEN'>GEN</MenuItem>
                                </Select>
                              </FormControl>

                              <TextField
                                label='Tipo'
                                name='tipoFrase'
                                onChange={onChangeFacturacion}
                                value={facturacion?.tipoFrase}
                                variant='outlined'
                              />

                              <TextField
                                label='Escenario'
                                onChange={onChangeFacturacion}
                                name='escenario'
                                value={facturacion?.escenario}
                                variant='outlined'
                              />
                            </Box>

                            <Grid
                              container
                              direction='column'
                              spacing={3}
                              key='t-7'
                            >
                              <Grid
                                item
                                style={{ height: 500, width: 'auto' }}
                              >
                                <DataGrid
                                  isRowSelectable={(params: GridRowParams) => false}
                                  loading={loading}
                                  rows={[
                                    {
                                      id: 1,
                                      nombre: facturacion?.firma?.substring(70),
                                      estado: facturacion?.estado,
                                      acciones: '',
                                    },
                                  ]}
                                  columns={[
                                    {
                                      headerName: 'Firma',
                                      minWidth: 200,
                                      renderCell: params => {
                                        return (
                                          <a
                                            href={facturacion?.firma}
                                            target='_blank'
                                            rel='noreferrer'
                                          >
                                            {facturacion?.firma?.substring(70)}
                                          </a>
                                        );
                                      },
                                      field: 'nombre',
                                    },
                                    {
                                      headerName: 'Estado',
                                      field: 'estado',
                                      renderCell: params => {
                                        return (
                                          <Chip
                                            label={facturacion?.estado || ''}
                                            style={{
                                              color: 'white',
                                              backgroundColor:
                                                facturacion?.estado === 'deshabilitado' ? '#FCBF49' : 'green',
                                            }}
                                          />
                                        );
                                      },
                                      minWidth: 200,
                                    },
                                    {
                                      headerName: 'Acciones',
                                      minWidth: 200,
                                      renderCell: params => {
                                        return (
                                          <Button
                                            size='small'
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setFacturacionUpload(true)}
                                          >
                                            Subir firma
                                          </Button>
                                        );
                                      },
                                      field: 'acciones',
                                    },
                                  ]}
                                  rowHeight={38}
                                  pagination={undefined}
                                />
                              </Grid>
                            </Grid>

                            <DropzoneDialog
                              dropzoneText='Arrastra los documentos o presiona aquí'
                              previewText='Vista prévia'
                              dialogTitle={'Elige archivo'}
                              submitButtonText='Subir archivo'
                              cancelButtonText='Cancelar'
                              open={facturacionUpload}
                              onSave={updateFirmaFEL}
                              acceptedFiles={['application/x-pkcs12']}
                              showPreviews={true}
                              maxFileSize={5000000000}
                              filesLimit={10}
                              onClose={() => {
                                setFacturacionUpload(false);
                              }}
                            />
                          </>
                        );
                    }
                  })}

                  <Modal
                    className={classes.modal}
                    open={open}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={classes.paper}>
                        <h2 id='transition-modal-title'>Cambiar estado</h2>
                        <p id='transition-modal-description'></p>
                        <List
                          component='nav'
                          aria-label='secondary mailbox folders'
                        >
                          <ListItem
                            button
                            onClick={() =>
                              documentoRespaldoIdSelected > 0
                                ? cambiarEstadoDocumentoDespaldo('pendiente', '')
                                : cambiarEstadoDocumento(4, '')
                            }
                          >
                            <ListItemIcon>
                              <PanTool style={{ color: '#FCBF49' }} />
                            </ListItemIcon>
                            <ListItemText primary='Pendiente' />
                          </ListItem>

                          <ListItem
                            button
                            onClick={() =>
                              documentoRespaldoIdSelected > 0
                                ? cambiarEstadoDocumentoDespaldo('subido', '')
                                : cambiarEstadoDocumento(1, '')
                            }
                          >
                            <ListItemIcon>
                              <CloudUpload style={{ color: '#5166AF' }} />
                            </ListItemIcon>
                            <ListItemText primary='Subido' />
                          </ListItem>

                          <ListItem
                            button
                            onClick={() =>
                              documentoRespaldoIdSelected > 0
                                ? cambiarEstadoDocumentoDespaldo('aprobado', '')
                                : cambiarEstadoDocumento(2, '')
                            }
                          >
                            <ListItemIcon>
                              <Check style={{ color: 'green' }} />
                            </ListItemIcon>
                            <ListItemText primary='Aprobado' />
                          </ListItem>

                          <ListItem
                            button
                            onClick={() => setShow(true)}
                          >
                            <ListItemIcon>
                              <Cancel style={{ color: 'red' }} />
                            </ListItemIcon>
                            <ListItemText primary='Rechazado' />
                          </ListItem>

                          {show ? (
                            <ListItem>
                              <Grid container>
                                <TextField
                                  label='Motivo de rechazo'
                                  variant='outlined'
                                  fullWidth
                                  onChange={handleChangeMessage}
                                />
                              </Grid>

                              <Button
                                disabled={messageRejected.length > 0 ? false : true}
                                variant='contained'
                                color='primary'
                                style={{ margin: 10 }}
                                onClick={() =>
                                  documentoRespaldoIdSelected > 0
                                    ? cambiarEstadoDocumentoDespaldo('rechazado', '')
                                    : cambiarEstadoDocumento(3, '')
                                }
                              >
                                Guardar
                              </Button>
                            </ListItem>
                          ) : (
                            ''
                          )}
                        </List>
                      </div>
                    </Fade>
                  </Modal>

                  {/* Epay Retail Credentials */}
                  <Modal
                    className={classes.modal}
                    open={openModalMpos}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalMpos}>
                      <div className={classes.paper}>
                        <Box style={{ paddingTop: '2rem' }}>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-0'
                          >
                            <Grid
                              container
                              item
                              direction='row'
                            >
                              <Grid
                                item
                                style={{ flexGrow: 1, display: 'flex' }}
                              >
                                <FormControl
                                  fullWidth={true}
                                  variant='outlined'
                                >
                                  <InputLabel variant='outlined'>Número de Serie</InputLabel>
                                  <OutlinedInput
                                    color='primary'
                                    fullWidth
                                    label='Número de Serie'
                                    value={mposValues.numeroDeSerie}
                                    onChange={handleChangeMposValues('numeroDeSerie')}
                                    inputComponent={numeroDeSerieMask}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  size='medium'
                                  color='primary'
                                  style={{ margin: 5 }}
                                  onClick={() => {
                                    getMposId();
                                    DiscoveOrDisConnect();
                                  }}
                                >
                                  <Bluetooth />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Afiliación ePayRetail'
                                variant='outlined'
                                fullWidth
                                value={mposValues.afiliacionEpayRetail}
                                onChange={handleChangeMposValues('afiliacionEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                disabled={
                                  selectedRecord &&
                                  selectedRecord.tipoNegocio &&
                                  selectedRecord.tipoNegocio.categoriaVisa == 'payfac'
                                }
                                label='Terminal ePayRetail'
                                variant='outlined'
                                fullWidth
                                value={mposValues.terminalEpayRetail}
                                onChange={handleChangeMposValues('terminalEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Merchant User ePayRetail'
                                variant='outlined'
                                fullWidth
                                disabled
                                value={mposValues.merchantUserEpayRetail}
                                onChange={handleChangeMposValues('merchantUserEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Merchant Password ePayRetail'
                                variant='outlined'
                                fullWidth
                                disabled
                                value={mposValues.merchantPasswordEpayRetail}
                                onChange={handleChangeMposValues('merchantPasswordEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction='row'
                                spacing={3}
                                key='t-0'
                                justifyContent='center'
                              >
                                <Grid item>
                                  <Button
                                    size='medium'
                                    color='primary'
                                    variant='contained'
                                    onClick={initialConfigurationsEpayRetail}
                                  >
                                    Configurar Terminal
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>

                  {/* Edit Epay Retail Credentials */}
                  <Modal
                    className={classes.modal}
                    open={openModalEditMpos}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalEditMpos}>
                      <div className={classes.paper}>
                        <Box style={{ paddingTop: '2rem' }}>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-0'
                          >
                            <Grid
                              container
                              item
                              direction='row'
                            >
                              <Grid
                                item
                                style={{ flexGrow: 1, display: 'flex' }}
                              >
                                <FormControl
                                  fullWidth={true}
                                  variant='outlined'
                                >
                                  <InputLabel variant='outlined'>Número de Serie</InputLabel>
                                  <OutlinedInput
                                    color='primary'
                                    fullWidth
                                    label='Número de Serie'
                                    value={mposValues.numeroDeSerie}
                                    onChange={handleChangeMposValues('numeroDeSerie')}
                                    inputComponent={numeroDeSerieMask}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  size='medium'
                                  color='primary'
                                  style={{ margin: 5 }}
                                  onClick={() => {
                                    getMposId();
                                    DiscoveOrDisConnect();
                                  }}
                                >
                                  <Bluetooth />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Afiliación ePayRetail'
                                variant='outlined'
                                fullWidth
                                value={mposValues.afiliacionEpayRetail}
                                onChange={handleChangeMposValues('afiliacionEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Terminal ePayRetail'
                                variant='outlined'
                                fullWidth
                                value={mposValues.terminalEpayRetail}
                                onChange={handleChangeMposValues('terminalEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Merchant User ePayRetail'
                                variant='outlined'
                                fullWidth
                                disabled
                                value={mposValues.merchantUserEpayRetail}
                                onChange={handleChangeMposValues('merchantUserEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label='Merchant Password ePayRetail'
                                variant='outlined'
                                fullWidth
                                disabled
                                value={mposValues.merchantPasswordEpayRetail}
                                onChange={handleChangeMposValues('merchantPasswordEpayRetail')}
                              />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction='row'
                                spacing={3}
                                key='t-0'
                                justifyContent='center'
                              >
                                <Grid item>
                                  <Button
                                    size='medium'
                                    color='primary'
                                    variant='contained'
                                    onClick={editEpayRetailCredentials}
                                  >
                                    Editar Terminal
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>

                  {/* Delete Mpos Modal Confirm */}
                  <Modal
                    className={classes.modal}
                    open={openConfirmDeleteMpos}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade
                      in={openConfirmDeleteMpos}
                      style={{ minWidth: 400 }}
                    >
                      <div className={classes.paper}>
                        <Box style={{ paddingTop: '2rem' }}>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-0'
                          >
                            <Grid item>
                              <TextField
                                label='Escribe la parabra CONFIRMAR'
                                variant='outlined'
                                fullWidth
                                value={confirmWord}
                                onChange={e => setConfirmWord(e.target.value)}
                              />
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                direction='row'
                                spacing={3}
                                key='t-0'
                                justifyContent='center'
                              >
                                <Grid item>
                                  <Button
                                    size='medium'
                                    color='primary'
                                    variant='contained'
                                    onClick={deleteMpos}
                                    disabled={confirmWord != 'CONFIRMAR'}
                                  >
                                    ELIMINAR MPOS
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>

                  {/* Close Transactions Modal Confirm */}
                  <Modal
                    className={classes.modal}
                    open={openConfirmCloseTransactions}
                    onClose={() => {
                      setOpenConfirmCloseTransactions(false);
                      setConfirmWord('');
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade
                      in={openConfirmCloseTransactions}
                      style={{ minWidth: 400, maxWidth: 450 }}
                    >
                      <div className={classes.paper}>
                        <Box style={{ paddingTop: '2rem' }}>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-0'
                          >
                            <Grid item>
                              <TextField
                                label='Escribe la parabra CONFIRMAR'
                                variant='outlined'
                                fullWidth
                                value={confirmWord}
                                onChange={e => setConfirmWord(e.target.value)}
                              />
                              <p>
                                Esta acción provocara el cierre de las transacciones pendientes, confirma para continuar
                              </p>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                direction='row'
                                spacing={3}
                                key='t-0'
                                justifyContent='center'
                              >
                                <Grid item>
                                  <Button
                                    size='medium'
                                    color='primary'
                                    variant='contained'
                                    onClick={() => generateEpayRetailReport('060000')}
                                    disabled={confirmWord != 'CONFIRMAR'}
                                  >
                                    REALIZAR CIERRE
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>

                  {/* Edit Epay Reports*/}
                  <Modal
                    className={classes.modal}
                    open={openModalGenerateReport}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalGenerateReport}>
                      <div className={classes.paper}>
                        <Box style={{ paddingTop: '1rem', marginTop: '1rem' }}>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-1'
                          >
                            {showClosedTransactions ? (
                              <Grid
                                item
                                style={{ height: 300, width: 'auto' }}
                              >
                                <DataGrid
                                  isRowSelectable={(params: GridRowParams) => false}
                                  loading={loading}
                                  rows={rowsClosedTransactions}
                                  columns={columnsClosedTransactions}
                                  rowHeight={50}
                                  pageSize={10}
                                  rowsPerPageOptions={[10, 25, 50, 100]}
                                  onCellClick={params => generateEpayRetailReport('080000', params.row.batch)}
                                />
                              </Grid>
                            ) : (
                              <>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Monto total y pendientes de cierre'
                                    onClick={() => generateEpayRetailReport('050000')}
                                  >
                                    Monto total y pendientes de cierre (0500-050000)
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Cierre de transacciones'
                                    onClick={() => setOpenConfirmCloseTransactions(true)}
                                  >
                                    Cierre de transacciones (0500-060000)
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Reporte detallado transacciones pendientes de cierre (0500-070000)'
                                    onClick={() => generateEpayRetailReport('070000')}
                                  >
                                    Reporte detallado transacciones pendientes de cierre (0500-070000)
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Reimpresión del ultimo cierre'
                                    onClick={() => generateEpayRetailReport('080000')}
                                  >
                                    Reimpresión del ultimo cierre (0500-080000)
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Reimpresión cierre en especifico'
                                    onClick={() => getClosedTransactions()}
                                  >
                                    Reimpresión cierre en especifico (0500-080000) - Batch
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    title='Ultimos 7 cierres realizados'
                                    onClick={() => generateEpayRetailReport('090000')}
                                  >
                                    Ultimos 7 cierres realizados (0500-090000)
                                  </Button>
                                </Grid>
                              </>
                            )}

                            <Grid
                              item
                              container
                              justifyContent='center'
                            >
                              <BlobProvider document={reportPDFEpayRetail}>
                                {({ url }) => {
                                  return (
                                    <Button
                                      color='primary'
                                      variant='contained'
                                      title='Ver Reporte'
                                      onClick={() => window.open(url ? url : '')}
                                    >
                                      Ver Reporte
                                    </Button>
                                  );
                                }}
                              </BlobProvider>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>

                  {/*MODAL CONTRATO*/}
                  <Modal
                    className={classes.modal}
                    open={openModalContrato}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalContrato}>
                      <div className={classes.paper}>
                        <h2 id='transition-modal-title'>Cambiar estado</h2>
                        <p id='transition-modal-description'></p>
                        <List
                          component='nav'
                          aria-label='secondary mailbox folders'
                        >
                          <ListItem
                            button
                            onClick={() => cambiarEstadoContrato(true)}
                          >
                            <ListItemIcon>
                              <Check style={{ color: 'green' }} />
                            </ListItemIcon>
                            <ListItemText primary='Comercio habilitado' />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => cambiarEstadoContrato(false)}
                          >
                            <ListItemIcon>
                              <Cancel style={{ color: 'red' }} />
                            </ListItemIcon>
                            <ListItemText primary='Comercio no habilitado' />
                          </ListItem>
                        </List>
                      </div>
                    </Fade>
                  </Modal>

                  <Modal
                    className={classes.modal}
                    open={openImagesModal}
                    onClose={() => {
                      setOpenImagesModal(false);
                      setTipoDocumentoSeleccionado('');
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openImagesModal}>
                      <div className={classes.paper}>
                        <h2 id='transition-modal-title'>Documentos</h2>
                        <p id='transition-modal-description'></p>
                        <DialogContent dividers>
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              style={{ padding: '2px' }}
                              item
                              xs={12}
                              md={4}
                            >
                              <>
                                <Paper
                                  style={{
                                    padding: 20,
                                    textAlign: 'left',
                                    marginTop: 10,
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                  >
                                    {documentosDetails &&
                                      documentosDetails.map((item, index) => (
                                        <Grid
                                          title={item?.value}
                                          key={index}
                                          item
                                          xs={12}
                                        >
                                          {componenteFiles(item.key)}
                                        </Grid>
                                      ))}
                                    {documentosDetails && documentosDetails.length > 0 && (
                                      <Grid item>
                                        <LoadingButton
                                          loading={loading}
                                          disabled={loadingComision}
                                          color='primary'
                                          variant='contained'
                                          title='Guardar'
                                          onClick={
                                            tipoDocumentoSeleccionado === 'cuenta_bancaria'
                                              ? editInformacionBancaria
                                              : editNegocio
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Paper>
                              </>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={8}
                            >
                              <ImageList
                                rowHeight={160}
                                className={classes.imageList}
                                cols={1}
                              >
                                {arrayImages.map((item: any) => (
                                  <ImageListItem
                                    key={item}
                                    cols={1}
                                    rows={4}
                                  >
                                    {item.includes('pdf') ? (
                                      <iframe
                                        src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                                        style={{ width: '100%', height: '600px' }}
                                        key={item}
                                        title='PDF Viewer'
                                      />
                                    ) : (
                                      <img
                                        style={{ width: '600px' }}
                                        src={item}
                                        onClick={() => openFile(item)}
                                      />
                                    )}
                                  </ImageListItem>
                                ))}
                              </ImageList>
                            </Grid>
                          </Grid>
                        </DialogContent>
                      </div>
                    </Fade>
                  </Modal>

                  <Modal
                    className={classes.modal}
                    open={openModalFile}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalFile}>
                      <div className={classes.paper}>
                        <h2 id='transition-modal-title'>Subir archivo</h2>
                        <p id='transition-modal-description'></p>
                        <Grid
                          container
                          direction='column'
                          spacing={2}
                        >
                          <Grid item>
                            <FormControl
                              fullWidth
                              variant='outlined'
                            >
                              {valTabDocuments === 0 && (
                                <>
                                  <InputLabel id='documento-select-label'>Tipo de documento</InputLabel>

                                  <Select
                                    labelId='documento-select-label'
                                    id='documento-select'
                                    label='Tipo de documento'
                                    value={tipoDocumentoSelected}
                                    onChange={i => handleSetTipoDocumentoSelected(i)}
                                  >
                                    <MenuItem value=''>
                                      <em>Seleccione</em>
                                    </MenuItem>
                                    {tipoDocumentos.map((tipo: any) => {
                                      return (
                                        <MenuItem
                                          key={tipo}
                                          value={tipo}
                                        >
                                          {tipo === 'foto_negocio' ? 'foto_selfie_negocio' : tipo}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </>
                              )}

                              {valTabDocuments === 1 && (
                                <TextField
                                  type={'text'}
                                  label='Tipo de documento'
                                  variant='outlined'
                                  fullWidth
                                  value={tipoDocumento}
                                  onChange={field => handleTipoDocumentoRespaldo(field.target.value)}
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <LoadingButton
                              loading={uploading}
                              color='primary'
                              variant='contained'
                              title='Elegir archivo'
                              onClick={() => {
                                handleOpenUpload();
                              }}
                            />
                            <DropzoneDialog
                              dropzoneText='Arrastra los documentos o presiona aquí'
                              previewText='Vista prévia'
                              dialogTitle={'Elige archivo'}
                              submitButtonText='Subir archivo'
                              cancelButtonText='Cancelar'
                              open={openUploadFile}
                              onSave={valTabDocuments === 0 ? handleSave : handleSaveRespaldo}
                              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                              showPreviews={true}
                              maxFileSize={5000000000}
                              filesLimit={10}
                              onClose={handleCloseUpload}
                            />
                          </Grid>
                          {/*  <Grid item>
                                          <input
                                              style={{display:'none'}}
                                                  accept="image/*"
                                                  id="contained-button-file"
                                                  multiple
                                                  type="file"
                                          />
                                          <label htmlFor="contained-button-file">
                                              <LoadingButton loading={uploading} color="primary" variant="contained" title='Subir archivo' onClick={()=>{ setUploading(true)}} /> 
                                          </label>
                                      </Grid>
                                      
                                      <Grid item>
                                          <LoadingButton loading={loading} color="primary" variant="contained" title='Guardar' onClick={()=>{}} /> 
                                    </Grid> */}
                        </Grid>
                      </div>
                    </Fade>
                  </Modal>

                  <Modal
                    className={classes.modal}
                    open={openModalFileContrato}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalFileContrato}>
                      <div className={classes.paper}>
                        <h2 id='transition-modal-title'>Subir Contrato</h2>
                        <p id='transition-modal-description'></p>
                        <Grid
                          container
                          direction='column'
                          spacing={2}
                        >
                          <Grid item>
                            <LoadingButton
                              loading={uploading}
                              color='primary'
                              variant='contained'
                              title='Elegir archivo'
                              onClick={() => {
                                handleOpenUpload();
                              }}
                            />
                            <DropzoneDialog
                              dropzoneText='Arrastra los documentos o presiona aquí'
                              previewText='Vista prévia'
                              dialogTitle={'Elige archivo'}
                              submitButtonText='Subir archivo'
                              cancelButtonText='Cancelar'
                              open={openUploadFile}
                              onSave={handleSaveContrato}
                              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                              showPreviews={true}
                              maxFileSize={25000000}
                              filesLimit={10}
                              onClose={handleCloseUpload}
                            />
                          </Grid>
                          {/*  <Grid item>
                                          <input
                                              style={{display:'none'}}
                                                  accept="image/*"
                                                  id="contained-button-file"
                                                  multiple
                                                  type="file"
                                          />
                                          <label htmlFor="contained-button-file">
                                              <LoadingButton loading={uploading} color="primary" variant="contained" title='Subir archivo' onClick={()=>{ setUploading(true)}} /> 
                                          </label>
                                      </Grid>
                                      
                                      <Grid item>
                                          <LoadingButton loading={loading} color="primary" variant="contained" title='Guardar' onClick={()=>{}} /> 
                                    </Grid> */}
                        </Grid>
                      </div>
                    </Fade>
                  </Modal>
                  {/*MODAL CONTRATO*/}

                  <Modal
                    className={classes.modal}
                    open={openLiquidationDetailModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openLiquidationDetailModal}>
                      <div className={classes.modalLiquidacion}>
                        <h2 id='transition-modal-title'>Detalle de liquidación</h2>
                        <DataGrid
                          style={{ height: '80%' }}
                          rows={rowsLiquidacionDetails}
                          columns={columnsLiquidacionDetails}
                          hideFooter={true}
                        />
                      </div>
                    </Fade>
                  </Modal>
                  <Modal
                    className={classes.modal}
                    open={openModalComision}
                    onClose={() => {
                      setComisionTotal(0);
                      setOpenModalComision(false);
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModalComision}>
                      <div className={classes.modalComision}>
                        <Grid
                          container
                          direction='column'
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TableContainer component={Paper}>
                              <Table size='small'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <strong>COBRO DE COMISIÓN</strong>
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      VENTAS AFILIACIÓN PAGGO:
                                    </TableCell>
                                    <TableCell align='center'>
                                      {selectedRecord?.tipoNegocio?.pais?.moneda} 100
                                    </TableCell>
                                    <TableCell align='center'>(Monto transaccionado)</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      Ventas sin IVA:
                                    </TableCell>
                                    <TableCell align='center'>
                                      {selectedRecord?.tipoNegocio?.pais?.moneda} {rowsComisionDetalle.ventasSinIva}
                                    </TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      IVA de las ventas:
                                    </TableCell>
                                    <TableCell align='center'>
                                      {selectedRecord?.tipoNegocio?.pais?.moneda} {rowsComisionDetalle.ivaDeLasVentas}
                                    </TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      Comisión sin iva
                                    </TableCell>
                                    <TableCell align='center'>
                                      <OutlinedInput
                                        fullWidth
                                        className={classes.inputComision}
                                        value={comisionTotal}
                                        endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                                        onChange={handleChangePorcentajeComisionTotal()}
                                      />
                                    </TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      Iva de la comisión:
                                    </TableCell>
                                    <TableCell align='center'>
                                      {selectedRecord?.tipoNegocio?.pais?.moneda} {rowsComisionDetalle.ivaTotal}
                                    </TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableRow>
                                  <TableRow style={{ backgroundColor: '#4CDADD' }}>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      <strong>Comisión con iva</strong>
                                    </TableCell>
                                    <TableCell align='center'>
                                      <strong>{rowsComisionDetalle.ivaTotalPorcentaje} %</strong>
                                    </TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableRow>
                                  {rowsComisionDetalle.tieneRetencion && (
                                    <TableRow>
                                      <TableCell
                                        component='th'
                                        scope='row'
                                      >
                                        RETENCIÓN DEL 15% DEL 12%:
                                      </TableCell>
                                      <TableCell align='center'>
                                        {selectedRecord?.tipoNegocio?.pais?.moneda} {rowsComisionDetalle.retencion}
                                      </TableCell>
                                      <TableCell align='center'></TableCell>
                                    </TableRow>
                                  )}
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                    >
                                      LIQUIDO A PAGAR COMERCIO AFILIADO:
                                    </TableCell>
                                    <TableCell align='center'>
                                      {selectedRecord?.tipoNegocio?.pais?.moneda} {rowsComisionDetalle.liquidoAPagar}
                                    </TableCell>
                                    <TableCell align='center'>(Monto Neto)</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Grid
                              container
                              direction='column'
                            >
                              <Grid
                                item
                                xs={12}
                              >
                                <Button
                                  fullWidth
                                  style={{ marginTop: 14, textAlign: 'center' }}
                                  disabled={loadingComision}
                                  variant='contained'
                                  color='primary'
                                  onClick={() => {
                                    setOpenCambioComision(true);
                                  }}
                                >
                                  {' '}
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Fade>
                  </Modal>

                  <ModalConfirm
                    buttonText='Confirmar'
                    title='Tipo de Negocio'
                    contentText={textoConfirmacionCambioTipoNegocio}
                    cancelText='Cancelar'
                    confirmText='Confirmar'
                    handleConfirm={handleConfirmCambioTipoNegocio}
                    handleClose={handleCloseCambioTipoNegocio}
                    open={openCambioTipoNegocio}
                  />

                  <ModalConfirm
                    buttonText='Confirmar'
                    title='<strong>Cambio de % comisión total</strong>'
                    contentText={
                      'Cambiar el % de comisión afectará los cálculos actuales. Si está seguro, confirme para proceder. ¿Desea continuar?'
                    }
                    cancelText='Cancelar'
                    confirmText='Confirmar'
                    handleConfirm={handleConfirmCambioComision}
                    handleClose={handleCloseCambioComision}
                    open={openCambioComision}
                  />
                </Grid>

                <Transition
                  in={showArchivos}
                  timeout={200}
                  unmountOnExit
                  mountOnEnter
                >
                  {state =>
                    tabsNoMostrar.includes('ARCHIVOS') ? null : (
                      <Grid
                        className={`archivos ${state === 'entered' ? 'fixed' : 'hide'} ${
                          width > 800 ? 'archivos-porcentaje' : 'archivos-total'
                        } `}
                        item
                        xs={12}
                      >
                        <Box>
                          <Grid
                            container
                            direction='column'
                            spacing={3}
                            key='t-3'
                          >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                              <Tabs
                                value={valTabDocuments}
                                onChange={handleTabDocuments}
                                aria-label='basic tabs example'
                                centered
                              >
                                <Tab
                                  label='Documentos Requeridos'
                                  {...setTabDocuments(0)}
                                />
                                <Tab
                                  label='Otros documentos de Respaldo'
                                  {...setTabDocuments(1)}
                                />
                              </Tabs>
                            </Box>
                            <Grid
                              item
                              style={{ width: 'auto' }}
                            >
                              <Button
                                variant='contained'
                                color='secondary'
                                size='large'
                                onClick={handleToggle}
                                className='toggle-button'
                                startIcon={<CloseTwoToneIcon />}
                              >
                                Cerrar
                              </Button>
                            </Grid>
                            {valTabDocuments === 0 && (
                              <Grid>
                                <Grid
                                  item
                                  style={{ width: 'auto', margin: 10 }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ padding: '5px 20px' }}
                                    onClick={handleSetOpenModalFile}
                                  >
                                    Subir archivo
                                  </Button>
                                </Grid>

                                <Grid
                                  item
                                  style={{ height: 300, width: 'auto', margin: 10 }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rows}
                                    columns={columns}
                                    rowHeight={38}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{ width: 'auto', margin: 10 }}
                                >
                                  <Typography
                                    variant='h6'
                                    gutterBottom
                                  >
                                    Historial de archivos
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 300, width: 'auto', margin: 10 }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsHistory}
                                    columns={columnsHistory}
                                    rowHeight={38}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {valTabDocuments === 1 && (
                              <Grid>
                                <Grid
                                  item
                                  style={{ width: 'auto', margin: 10 }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ padding: '5px 20px' }}
                                    onClick={handleSetOpenModalFile}
                                  >
                                    Subir documento de respaldo
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 300, width: 'auto', margin: 10 }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsDocumentosRespaldo}
                                    columns={columnsRespaldos}
                                    rowHeight={38}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{ width: 'auto', margin: 10 }}
                                >
                                  <Typography
                                    variant='h6'
                                    gutterBottom
                                  >
                                    Historial de archivos
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  style={{ height: 300, width: 'auto', margin: 10 }}
                                >
                                  <DataGrid
                                    isRowSelectable={(params: GridRowParams) => false}
                                    loading={loading}
                                    rows={rowsHistorialDocumentosRespaldo}
                                    columns={columnsHistoryRespaldo}
                                    rowHeight={38}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    )
                  }
                </Transition>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
