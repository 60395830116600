import React, { useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/LoadingButton';
import { Chip, colors, FormControl, IconButton, InputLabel, MenuItem, Select, TablePagination } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import { UserInterface } from '../../interfaces/Interfaces';
import UsersServices from '../../services/UsersServices';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Delete, EditTwoTone as Edit, RestoreTwoTone as Restore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import * as momentZone from 'moment-timezone'

import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridValueGetterParams
} from "@material-ui/data-grid";
import PageLoader from '../../components/PageLoader';
import { Pagination } from '@material-ui/lab';

const CustomToolbar = () => {
    return (
      <React.Fragment>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </React.Fragment>
    );
  };

export default function Users() {
    const { handleErrorMessage, handleActiveError, handleSelectRecord, handleSuccessMessage, handleActiveSuccess } = useContext(GeneralContext)
    let history = useHistory()

    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        getUsuarios()
    }, [page, pageSize])

    const columns = [
        {
            field: "acciones",
            headerName: "Acciones",
            sortable: false,
            minWidth: 120,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params: any) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
                        <IconButton size='small' onClick={() => editRecord(params.row)}>
                            <Edit />
                        </IconButton>
                        <IconButton size='small' style={{ color: 'green' }} onClick={() => resetPassword(params.row)}>
                            <Restore />
                        </IconButton>
                    </div>);
            }
        },
        { field: "id", headerName: "Id", flex: 1, minWidth: 100 },
        {
            field: "idNegocio", headerName: 'Negocio Id', flex: 1, minWidth: 180,
            valueGetter: (params: GridValueGetterParams) => `${params && params.row && params.row.rolUsuario && params.row.rolUsuario[0] && params.row.rolUsuario[0].NegocioId ? params.row.rolUsuario[0].NegocioId : ''}`
        },
        {
            field: "nombreNegocio", headerName: 'Nombre del negocio', flex: 1, minWidth: 225,
            valueGetter: (params: GridValueGetterParams) => `${params && params.row && params.row.rolUsuario && params.row.rolUsuario[0] && params.row.rolUsuario[0].negocio && params.row.rolUsuario[0].negocio.nombre ? params.row.rolUsuario[0].negocio.nombre : ''}`
        },
        {
            field: "rol", headerName: 'Rol Usuario', flex: 1, minWidth: 275,
            renderCell: (params: any) => {
                let rol = params && params.row && params.row.rolUsuario && params.row.rolUsuario[0] && params.row.rolUsuario[0].rol && params.row.rolUsuario[0].rol.tipo ? params.row.rolUsuario[0].rol.tipo : ''
                rol = rol.replaceAll('_', ' ')
                return (<span>{`${rol}`}</span>)
            }
        },
        { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 150, },
        { field: "email", headerName: "Email", flex: 1, minWidth: 225, },
        {
            field: "apellidos",
            headerName: "Apellidos",
            sortable: false,
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.getValue(params.id, 'apellidoPaterno') || ''} ${params.getValue(params.id, 'apellidoMaterno') || ''}`
        },
        { field: "cui", headerName: 'CUI', flex: 1, minWidth: 150, },
        { field: "telefono", headerName: 'Teléfono', flex: 1, minWidth: 150, },
        { field: "activada", headerName: 'Activada', flex: 1, type: 'boolean', minWidth: 140, },
        {
            field: "createdAt", headerName: 'Creado', flex: 1, minWidth: 200,
            renderCell: (params: any) => {
                const fechaTZ6 = momentZone.tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala').locale('es').format('YYYY-MM-DD HH:mm A')
                return (<span>{fechaTZ6}</span>)
            }
        },
        {
            field: "procesoRegistro",
            headerName: "Proceso actual",
            sortable: false,
            flex: 1,
            minWidth: 200,
            disableClickEventBubbling: true,
            renderCell: (params: any) => {
                const actual = params.value[0]?.procesoActual ?? 0
                return (<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
                    <Chip label={`${actual} de 6`} color={actual === 6 ? 'default' : 'secondary'} />
                </div>);
            }
        },
        
    ];

    const resetPassword = (params: any) => {
        UsersServices.instance.resetPassword(params).then(response => {
            const { error, result, message } = response
            if (!error) {
                handleSuccessMessage(message)
                handleActiveSuccess(true)
            } else {
                handleErrorMessage(message)
                handleActiveError(true)
            }
        }).catch(e => {
            handleErrorMessage('message')
            handleActiveError(true)
        })
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            getUsuarios();
        }
    };

    const procesarGetUsuarios = () => {
        if (page === 1) getUsuarios();
        else setPage(1);
      }
    
      useEffect(() => {
        if (searchValue){
          const timerId = setTimeout(procesarGetUsuarios, 1000);
          return () => clearTimeout(timerId);
        }
      }, [searchValue]);

    const getUsuarios = () => {
        setLoading(true)
        const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
        UsersServices.instance.get(params).then(response => {
            let { error, result, totalPages } = response
            result = result.filter((e: any) => {
                if (e.rolUsuario
                    && e.rolUsuario
                    && e.rolUsuario[0]
                    && e.rolUsuario[0].rol
                    && e.rolUsuario[0].rol.tipo
                    && !(e.rolUsuario[0].rol.tipo == 'afiliador')
                    && !(e.rolUsuario[0].rol.tipo == 'administrador_master')
                ) return e
            })
            if (!error) {
                setRows(result)
            }
            setTotalPages(totalPages)
            setLoading(false)
        }).catch(e => {
            handleErrorMessage('message')
            handleActiveError(true)
            setLoading(false)
        })
    }

    const editRecord = (record: any) => {
        const { id } = record
        handleSelectRecord(record)
        history.push(`/admin-home/users/${id}`)
    }

    const deleteRecord = (record: any) => {

    }

    return (<Box mt={1} p={2} component={Paper} style={{ maxHeight: '100vh', overflow: 'auto' }}>
        <Grid container direction="column" spacing={2} >
        <Grid item>
          <Grid container>
            <Grid
              item
              xs={12}
              md={2}
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <Typography variant='h6'>USUARIOS</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
            >
              <Box
                justifyContent={['flex-start', 'flex-start', 'flex-end']}
                sx={{ flexDirection: 'row', display: 'flex' }}
              >
                <TextField
                  label='Buscar usuario'
                  placeholder='Ingrese valor'
                  variant='outlined'
                  style={{ marginRight: 10 }}
                  size='small'
                  name='search'
                  value={searchValue}
                  onKeyDown={handleSearchKeyDown}
                  onChange={handleSearchChange}
                />

                <Button
                  component={Link}
                  to='user'
                  fullWidth
                  size='small'
                  style={{ width: '100px' }}
                  variant='contained'
                  color='primary'
                >
                  CREAR
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
            <Grid item style={{ height: '100%', width: 'auto' }}>
                <DataGrid 
                    components={{ Toolbar: CustomToolbar }} 
                    rows={rows} 
                    columns={columns} 
                    rowHeight={38} 

                    loading={loading}
                    paginationMode='server'
                    pageSize={pageSize}
                    rowCount={totalPages * pageSize}
                    hideFooterPagination
                    hideFooter
                    autoHeight
                />
                <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                    <Pagination
                    count={totalPages}
                    page={page}
                    shape='rounded'
                    size='medium'
                    variant='outlined'
                    color='primary'
                    style={{ padding: '8px', textAlign: 'center' }}
                    onChange={(event, value) => setPage(value)}
                    />
                </div>
            </Grid>
        </Grid>
        <PageLoader loading={loading} />
    </Box>)
}
