/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import * as momentZone from 'moment-timezone';
import { useState, useContext, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';
import PageLoader from '../../components/PageLoader';

export default function CrearLiquidacionHonduras() {
  const { handleErrorMessage, handleActiveError, handleLoading, loading } = useContext(GeneralContext);

  //Filtros por dia
  let filtrosDia = [
    { id: 1, dia: 'Lunes', startDate: '', endDate: '', estado: false, desabilitado: true },
    { id: 2, dia: 'Martes', startDate: '', endDate: '', estado: false, desabilitado: false },
    { id: 3, dia: 'Miércoles', startDate: '', endDate: '', estado: false, desabilitado: true },
    { id: 4, dia: 'Jueves', startDate: '', endDate: '', estado: false, desabilitado: true },
    { id: 5, dia: 'Viernes', startDate: '', endDate: '', estado: false, desabilitado: true },
  ];

  const startDateSettlementRef = useRef<TextFieldProps>(null);
  const endDateSettlementRef = useRef<TextFieldProps>(null);

  const startDate2SettlementRef = useRef<TextFieldProps>(null);
  const endDate2SettlementRef = useRef<TextFieldProps>(null);

  const [startDateSettlement, setStartDateSettlement] = useState<string | undefined>();
  const [endDateSettlement, setEndDateSettlement] = useState<string | undefined>();

  const [startDate2Settlement, setStartDate2Settlement] = useState<string | undefined>();
  const [endDate2Settlement, setEndDate2Settlement] = useState<string | undefined>();

  const getSettlement = (type: string, index: number) => {
    handleLoading(true);
    ReportesServices.instance
      .getSettlementHonduras(type, index)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            handleActiveError(true);
            handleErrorMessage(response.message || 'Liquidación anterior pendiente de procesar');
          }
          handleLoading(false);
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
          handleLoading(false);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(false);
      });
  };

  const getParticipants = (type: string, index: number) => {
    ReportesServices.instance
      .getParticipantsHonduras(type, index)
      .then(response => {
        try {
          if (response.file?.blob instanceof Blob) {
            const blob = new Blob([response.file?.blob as BlobPart], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.file.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            handleActiveError(true);
          }
        } catch (error: any) {
          handleErrorMessage(error.message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const obtnerFiltrosDia = (tipoReporte: string) => {
    return (
      <>
        {filtrosDia.map((filtro, index) => {
          if (filtro.id !== momentZone.tz('America/Guatemala').day()) filtro.estado = true;
          return (
            <Grid
              item
              style={{ marginTop: 5 }}
              key={filtro.id}
            >
              <Button
                color='primary'
                variant='contained'
                title={filtro.dia}
                disabled={filtro.desabilitado}
                onClick={() => getSettlement(tipoReporte, index + 1)}
              >
                {filtro.dia}
              </Button>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Box
      display='flex'
      mt={1}
    >
      <Grid
        item
        sm={6}
        xs={12}
        container
        spacing={3}
        component={Paper}
        direction='column'
        style={{ padding: 10, margin: 10 }}
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            Participantes
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Exportar reporte de participantes (negocios)
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type={'date'}
            label='Fecha inicio'
            variant='outlined'
            inputRef={startDate2SettlementRef}
            value={startDate2Settlement}
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              setStartDate2Settlement(e.target.value);
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type={'date'}
            label='Fecha fin'
            variant='outlined'
            value={endDate2Settlement}
            inputRef={endDate2SettlementRef}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDate2Settlement(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => getParticipants('interno', momentZone.tz('America/Guatemala').day())}
          >
            Participantes
          </Button>
        </Grid>

        {/* <Grid item>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => getParticipants('externo', momentZone.tz('America/Guatemala').day())}
          >
            Participantes (Externo)
          </Button>
        </Grid> */}

        <Grid
          item
          hidden
        >
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => {
              setEndDate2Settlement(undefined);
              setStartDate2Settlement(undefined);
              if (startDate2SettlementRef.current) startDate2SettlementRef.current.value = '';
              if (endDate2SettlementRef.current) endDate2SettlementRef.current.value = '';
            }}
          >
            Limpiar Filtros
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={3}
        direction='column'
        component={Paper}
        style={{ padding: 10, margin: 10 }}
        sm={6}
        xs={12}
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            Reporte de liquidaciones internas y externas
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Exportar reporte de liquidaciones
          </Typography>
        </Grid>

        <Grid
          item
          hidden
        >
          <TextField
            fullWidth
            type={'date'}
            label='Fecha inicio'
            variant='outlined'
            inputRef={startDateSettlementRef}
            value={startDateSettlement}
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              setStartDateSettlement(e.target.value);
            }}
          />
        </Grid>

        <Grid
          item
          hidden
        >
          <TextField
            fullWidth
            type={'date'}
            label='Fecha fin'
            variant='outlined'
            value={endDateSettlement}
            inputRef={endDateSettlementRef}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDateSettlement(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Typography
            paragraph
            variant='subtitle2'
          >
            Liquidación interna
          </Typography>

          <Grid
            style={{ paddingTop: 10 }}
            direction='row'
            justifyContent='center'
            alignItems='center'
            container
            spacing={2}
          >
            {obtnerFiltrosDia('interno')}
          </Grid>
        </Grid>

        {/* <Grid item>
          <Typography
            paragraph
            variant='subtitle2'
          >
            Liquidación externa
          </Typography>

          <Grid
            style={{ paddingTop: 10 }}
            direction='row'
            justifyContent='center'
            alignItems='center'
            container
            spacing={2}
          >
            {obtnerFiltrosDia('externo')}
          </Grid>
        </Grid> */}

        <Grid
          item
          hidden
        >
          <Button
            fullWidth
            color='primary'
            variant='contained'
            title='Liquidación Externa'
            onClick={() => {
              setEndDateSettlement(undefined);
              setStartDateSettlement(undefined);
              if (startDateSettlementRef.current) startDateSettlementRef.current.value = '';
              if (endDateSettlementRef.current) endDateSettlementRef.current.value = '';
            }}
          >
            Limpiar Filtros
          </Button>
        </Grid>
      </Grid>
      <PageLoader loading={loading} />
    </Box>
  );
}
