import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class VerificationService {
  static instance = new VerificationService();

  getVerificationByMetaMapId = async (id: any) => {
    try {
      const ruta = `${Rutas.instance.urlBase}/metamap/dataMetamapByMetamapApi/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.result.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  verificationByVerificationId = async (id: any) => {
    try {
      const ruta = `${Rutas.instance.urlBase}/metamap/verificationByVerificationId/${id}`;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.result.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  getAllVerifications = async (signal?: AbortSignal) => {
    try {
      const ruta = `${Rutas.instance.urlBase}/metamap/getAllVerifications`;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true, signal);
      if (req.error) throw new Error(req.result.message);
      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  getAllVerificationsNegocio = async (id: number, signal?: AbortSignal) => {
    try {
      const ruta = `${Rutas.instance.urlBase}/metamap/getAllVerifications/negocio/${id}`;
      const httpInstance = new http();
      let req = await httpInstance.get(ruta, true, signal);
      if (req.error) throw new Error(req.result.message);
      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };

  setVerificacion = async (data: any) => {
    try {
      const ruta = `${Rutas.instance.urlBase}/metamap/setVerificacion`;
      const httpInstance = new http();
      let req = await httpInstance.post(ruta, data, true);
      return { error: req.error, message: req.message, result: req.result };
    }
    catch (error: any) {
      return { error: true, message: error.message, result: null };
    }
  };
}
