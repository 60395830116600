import { Box, Button, Fade, Grid, Modal, TextareaAutosize, Theme, createStyles, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import LoadingButton from "./LoadingButton";
import { DropzoneDialog } from "material-ui-dropzone";
import { GeneralContext } from "../context/GeneralContext";
import DocumentosService from "../services/DocumentosService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
    },
    imageList: {
      width: 500,
      height: 450,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 300,
    },
    button: {
      margin: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageListAbono: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }),
);

interface ModalRazonInterface {
  handleUpdate: (data: any) => void;
  handleCloseModal: (open: boolean) => void;
  open: boolean;
  textoAMostrar: string;
  valor: any;
}

const ModalRazonDocumentos = (
  props: ModalRazonInterface
) => {

  const classes = useStyles();
  const {
    selectedRecord,
    handleErrorMessage,
    handleActiveError,
    handleSelectRecord,
    handleSuccessMessage,
    handleActiveSuccess,
    handleLoading
  } = useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [textoAMostrar, setTextoAMostrar] = useState('');
  const [uploading, setUploading] = useState(false);
  const [values, setValues] = useState<any>({
    razon: '',
    documentos: [],
    valor: props.valor
  });

  useEffect(() => {
    setValues({ ...values, valor: props.valor });
  }, [props.valor]);


  const handleOpenUpload = () => {
    setOpenUploadFile(true);
  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const handleSaveDocumentos = (files: any) => {

    let data = new FormData();

    files.forEach((file: any) => {
      data.append('file[]', file);
    });
    handleLoading(true);
    setUploading(true);
    setLoading(true);
    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setUploading(false);
        if (response.length > 0) {
          const links = response;
          setValues({ ...values, documentos: links });
          handleSuccessMessage('Documentos subidos correctamente');
          handleActiveSuccess(true);
          setLoading(false);
          handleLoading(false);
          setOpenUploadFile(false);
        } else {
          setLoading(false);
          handleErrorMessage("No se pudo subir archivo");
          handleActiveError(true);
          handleLoading(false);
          setOpenUploadFile(false);
        }
      })
      .catch(e => {
        setOpenUploadFile(false);
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(false);
        setLoading(false);
      });
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => {
        props.handleCloseModal(false)
      }}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <Box style={{ paddingTop: '2rem' }}>
            <Grid
              container
              direction='column'
              spacing={3}
              key='t-0'
            >
              <Grid
                container
                item
                direction='row'
              >
                <Grid
                  container
                  direction='column'
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', padding: '8px' }}
                >
                  <Grid item >
                    <p>INGRESA LA RAZON POR LA QUE CAMBIARAS A: <strong>{props.textoAMostrar.toUpperCase()}</strong> (<span style={{ color: 'red' }}>*</span>) </p>
                    <TextareaAutosize onChange={(e) => { setValues({...values, razon: e.target.value}) }} autoFocus style={{ width: '97%', padding: '10px' }} minRows={8} />
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={uploading}
                      color='primary'
                      variant='contained'
                      title='Agregar documentos de respaldo'
                      onClick={() => {
                        handleOpenUpload();
                      }}
                    />
                    <DropzoneDialog
                      dropzoneText='Arrastra los documentos o presiona aquí'
                      previewText='Vista prévia'
                      dialogTitle={'Elige los archivos'}
                      submitButtonText='Subir archivos'
                      cancelButtonText='Cancelar'
                      open={openUploadFile}
                      onSave={handleSaveDocumentos}
                      acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                      showPreviews={true}
                      maxFileSize={25000000}
                      filesLimit={10}
                      onClose={handleCloseUpload}
                    />
                  </Grid>
                </Grid>
              </Grid>



              <Grid item>
                <Grid
                  container
                  direction='row'
                  spacing={3}
                  key='t-0'
                  justifyContent='center'
                >
                  <Grid item>
                    <Button size='medium' color='primary' variant='contained' onClick={()=>{
                      props.handleUpdate(values);
                    }}>CAMBIAR</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalRazonDocumentos