import momentZone from 'moment-timezone';
import { AppBar, Backdrop, Badge, Box, Button, Chip, Fade, FormControl, FormHelperText, Grid, IconButton, MenuItem, Modal, Paper, Select, Tab, Tabs, TextField, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { DataGrid, GridCellParams, GridRowParams } from "@material-ui/data-grid";
import { Pagination } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react";
import CrudService from "../../services/CrudService";
import { Rutas } from "../../resources/Statics";
import { GeneralContext } from "../../context/GeneralContext";
import { Link, useHistory } from 'react-router-dom';
import { DoubleArrowTwoTone, Edit, FindInPageTwoTone, History, VisibilityTwoTone } from '@material-ui/icons';
import ModalRazonDocumentos from '../../components/ModalRazonDocumentos';
import PageLoader from '../../components/PageLoader';
import RazonesService from '../../services/RazonesService';

interface Estado {
  id: number;
  descripcion: string;
  color: string;
  deshabilitar?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      flexGrow: 1,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '75vw',
    },
  })
);

const Ordenes = () => {

  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    handleLoading,
    selectedRecord,
    loading
  } = useContext(GeneralContext);
  const classes = useStyles();
  let history = useHistory()
  const [rows, setRows] = useState([]);
  const [rowsGestionar, setRowsGestionar] = useState([]);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueGestionar, setSearchValueGestionar] = useState('');
  const [page, setPage] = useState(1);
  const [pageGestionar, setPageGestionar] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeGestionar, setPageSizeGestionar] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesGestionar, setTotalPagesGestionar] = useState(0);
  const [estadosOrden, setEstadosOrden] = useState<Estado[]>();
  const [estadosPago, setEstadosPago] = useState<Estado[]>();
  const [value, setValue] = useState(0);
  const [openModalEstado, setOpenModalEstado] = useState(false);
  const [estadoIdCambiar, setEstadoIdCambiar] = useState(0);
  const [estadoACambiar, setEstadoACambiar] = useState('');
  const [idOrdenCambiar, setIdOrdenCambiar] = useState(0);
  const [rowsHistorialEstados, setRowsHistorialEstados] = useState([]);
  const [openHistorialEstados, setOpenHistorialEstados] = useState(false);
  const [arrayImages, setArrayImages] = useState<any[]>([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);

  useEffect(() => {
    getRows();
  }, [page, pageSize]);

  useEffect(() => {
    getEstados('orden');
    getEstados('pago');
  }, []);

  useEffect(() => {
    getRowsGestionar();
  }, [pageGestionar, pageSizeGestionar]);

  useEffect(() => {
    if (searchValue){
      const timerId = setTimeout(procesarOrdenes, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValueGestionar){
      const timerId = setTimeout(procesarOrdenesGestionar, 1000);
      return () => clearTimeout(timerId);
    }
  }, [searchValueGestionar]);

  const procesarOrdenes = () => {
    if (page === 1) getRows();
    else setPage(1);
  }

  const procesarOrdenesGestionar = () => {
    if (pageGestionar === 1) getRowsGestionar();
    else setPageGestionar(1);
  }

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchChangeGestionar = (event: any) => {
    setSearchValueGestionar(event.target.value);
  };

  const handleKeyDownChange = (event: any) => {
    if (event.key === 'Enter') {
      getRows()
    }
  };

  const handleKeyDownChangeGestionar = (event: any) => {
    if (event.key === 'Enter') {
      getRowsGestionar()
    }
  };

  const getEstados = (tipo: string) => {
    const crudService = CrudService.instance(Rutas.instance.urlOrdenEstados);
    crudService
      .getAll(`?tipo=${tipo}`)
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          if (tipo === 'orden') {
            result.map((estado: Estado) => {
              if (estado.descripcion === 'Entregado' || estado.descripcion === 'Rechazado' ) estado.deshabilitar = true;
            });
          } else if (tipo === 'pago'){
            result.map((estado: Estado) => {
              if (estado.descripcion === 'Pagado') estado.deshabilitar = true;
            });
          }
          tipo === 'orden' ? setEstadosOrden(result) : setEstadosPago(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  }

  const getRows = () => {
    setLoadingGrid(true);
    const params = `?page=${page}&pageSize=${pageSize}&valor=${searchValue}`;
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRows(result);
          setTotalPages(totalPages);
        }
        setLoadingGrid(false);
      })
      .catch(e => {
        setLoadingGrid(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getRowsGestionar = () => {
    setLoadingGrid(true);
    const params = `?page=${pageGestionar}&pageSize=${pageSizeGestionar}&valor=${searchValueGestionar}`;
    const crudService = CrudService.instance(`${Rutas.instance.urlOrden}/gestionar/envios`);
    crudService
      .getPaginationFilter(params)
      .then(async response => {
        const { error, result, totalPages } = response;
        if (!error) {
          setRowsGestionar(result);
          setTotalPagesGestionar(totalPages);
        }
        setLoadingGrid(false);
      })
      .catch(e => {
        setLoadingGrid(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = async (e: any, tipo: any, id: number) => {
    const { value } = e.target;
    const name = e.target.name;
    const urlEstado = tipo === 'orden' ? '/estadoOrden' : '/estadoPago';
    const crudService = CrudService.instance(Rutas.instance.urlOrden);
    const data = { [name]: Number(value) };
    crudService
      .updateOneComplemento(id, data, urlEstado)
      .then(async response => {
        const { error, message } = response;
        if (!error) {
          handleSuccessMessage(message);
          handleActiveSuccess(true);
          getRows();
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
    
  }

  const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // const updateInventary = (row: MposInventoryInterface) => {
  //   handleLoading(true);
  //   if (row.id)
  //     MposInventoryService
  //       .update(
  //         row.id,
  //         row
  //       )
  //       .then(response => {
  //         const { error, message } = response;
  //         if (!error) {
  //           handleActiveSuccess(true);
  //           handleSuccessMessage(message);
  //         } else {
  //           handleActiveError(true);
  //           handleErrorMessage(message);
  //         }
  //         getInventoryMpos();
  //         handleLoading(false);
  //       })
  //       .catch(e => {
  //         handleLoading(false);
  //         handleActiveError(true);
  //         handleErrorMessage(e.message);
  //       });
  // }

  const verHistorial = async (id: any, origenLike: string) => {
    const params = '?origenLike='+origenLike;
    setLoadingGrid(true);
    await RazonesService.instance
      .getByOrdenId(id,params)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsHistorialEstados(result);
          setLoadingGrid(false);
          setOpenHistorialEstados(true);
        } else {
          setLoadingGrid(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        setLoadingGrid(true);
      });
  }

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      minWidth: 120,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
        <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
          <Link to={`/admin-home/ordenes/edicion/${params.row.id}`}>
            <IconButton size='small'>
                <VisibilityTwoTone />
            </IconButton>
          </Link>
        </div>);
      }
    },
    {
      field: 'id',
      headerName: 'ID Orden',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
    },
    {
      field: 'EstadoOrdenId',
      headerName: 'Estado orden',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const rowNew: any = params.row;
        const valorSeleccionado = params.row.EstadoOrdenId?.toString();
        const opcionesDisponibles = estadosOrden?.map(status => status.id.toString());
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                disabled={(estadosOrden?.find((estado: Estado) => estado.id === rowNew.EstadoOrdenId)?.deshabilitar) ? true : false}
                title={(estadosOrden?.find((estado: Estado) => estado.id === rowNew.EstadoOrdenId)?.deshabilitar) ? 'No se puede cambiar el estado' : ''}
                name='EstadoOrdenId'
                value={opcionesDisponibles?.includes(valorSeleccionado) ? valorSeleccionado : ''}
                //onChange={e => handleChange(e, 'orden', params.row.id)}
                onChange={(e) => {
                  const estado = estadosOrden?.find((estado: Estado) => estado.id === Number(e.target.value));
                  setIdOrdenCambiar(params.row.id);
                  setEstadoIdCambiar(Number(e.target.value));
                  setEstadoACambiar(estado?.descripcion || '');
                  setOpenModalEstado(true);
                }}
                style={{ minWidth: 110, lineHeight: 'unset' }}
              >
                {estadosOrden?.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.id}
                      value={status.id.toString()}
                    >
                      <Chip
                        label={status.descripcion}
                        style={{ background: status.color, color: 'white', fontSize: 12, fontWeight: 'bold' }}
                      />
                    </MenuItem>
                  );
                  
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'historicoestados',
      headerName: 'Historico estados',
      flex: 1,
      minWidth: 235,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id,'ordenes_estados')} color='inherit' >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    {
      field: 'ClienteId',
      headerName: 'Cliente',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.cliente?.negocio?.nombre}</span>;
      },
    },
    {
      field: 'logistica',
      headerName: 'Logística',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.logistica?.ordenTipoLogistica?.descripcion}</span>;
      },
    },
    {
      field: 'UsuarioVendeId',
      headerName: 'Usuario vende',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.usuarioVende?.nombre}</span>;
      },
    },
    {
      field: 'EstadoPagoId',
      headerName: 'Estado pago',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const rowNew: any = params.row;
        const valorSeleccionado = params.row.EstadoPagoId?.toString();
        const opcionesDisponibles = estadosPago?.map(status => status.id.toString());
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                disabled={(estadosPago?.find((estado: Estado) => estado.id === rowNew.EstadoPagoId)?.deshabilitar) ? true : false}
                title={(estadosPago?.find((estado: Estado) => estado.id === rowNew.EstadoPagoId)?.deshabilitar) ? 'No se puede cambiar el estado' : ''}
                value={opcionesDisponibles?.includes(valorSeleccionado) ? valorSeleccionado : ''}
                name='EstadoPagoId'
                onChange={e => handleChange(e, 'pago', params.row.id)}
                style={{ minWidth: 110, lineHeight: 'unset' }}
              >
                {estadosPago?.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.id}
                      value={status?.id?.toString()}
                    >
                      <Chip
                        label={status.descripcion}
                        style={{ background: status.color, color: 'white', fontSize: 12, fontWeight: 'bold' }}
                      />
                    </MenuItem>
                  );
                  
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'fechaPago',
      headerName: 'Fecha pago',
      minWidth: 170,
      renderCell: (params: any) => {
        const field = params.getValue(params.id, 'fechaPago');
        return (
          <span>{field ? momentZone.tz(field, 'America/Guatemala').format('YYYY-MM-DD HH:mm A') : ''}</span>
        );
      },
    },
    {
      field: 'usuarioPagoId',
      headerName: 'Usuario actualiza pago',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.usuarioPago?.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creación',
      minWidth: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

  ];

  const columnsGestionar = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      minWidth: 120,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
        <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
          <Link to={{ pathname: `/admin-home/ordenes/edicion/${params.row.id}`, search: 'gestionar' }} >
            <IconButton size='small'>
                <DoubleArrowTwoTone />
            </IconButton>
          </Link>
        </div>);
      }
    },
    {
      field: 'id',
      headerName: 'ID Orden',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
    },
    {
      field: 'EstadoOrdenId',
      headerName: 'Estado orden',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const rowNew: any = params.row;
        const valorSeleccionado = params.row.EstadoOrdenId?.toString();
        const opcionesDisponibles = estadosOrden?.map(status => status.id.toString());
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                disabled={(estadosOrden?.find((estado: Estado) => estado.id === rowNew.EstadoOrdenId)?.deshabilitar) ? true : false}
                title={(estadosOrden?.find((estado: Estado) => estado.id === rowNew.EstadoOrdenId)?.deshabilitar) ? 'No se puede cambiar el estado' : ''}
                name='EstadoOrdenId'
                value={opcionesDisponibles?.includes(valorSeleccionado) ? valorSeleccionado : ''}
                onChange={(e) => {
                  const estado = estadosOrden?.find((estado: Estado) => estado.id === Number(e.target.value));
                  setIdOrdenCambiar(params.row.id);
                  setEstadoIdCambiar(Number(e.target.value));
                  setEstadoACambiar(estado?.descripcion || '');
                  setOpenModalEstado(true);
                }}
                //onChange={e => handleChange(e, 'orden', params.row.id)}
                style={{ minWidth: 110, lineHeight: 'unset' }}
              >
                {estadosOrden?.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.id}
                      value={status.id.toString()}
                    >
                      <Chip
                        label={status.descripcion}
                        style={{ background: status.color, color: 'white', fontSize: 12, fontWeight: 'bold' }}
                      />
                    </MenuItem>
                  );
                  
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'historicoestados',
      headerName: 'Historico estados',
      flex: 1,
      minWidth: 235,
      renderCell: (params: any) => {
        return <FormHelperText>
        <Button onClick={(event) => verHistorial(params?.row?.id,'ordenes_estados')} color='inherit' >
          <small>Ver historial</small> <History fontSize='small'></History>
        </Button>
      </FormHelperText>;
      },
    },
    {
      field: 'ClienteId',
      headerName: 'Cliente',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.cliente?.negocio?.nombre}</span>;
      },
    },
    {
      field: 'logistica',
      headerName: 'Logística',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.logistica?.ordenTipoLogistica?.descripcion}</span>;
      },
    },
    {
      field: 'UsuarioVendeId',
      headerName: 'Usuario vende',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.usuarioVende?.nombre}</span>;
      },
    },
    {
      field: 'EstadoPagoId',
      headerName: 'Estado pago',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const rowNew: any = params.row;
        const valorSeleccionado = params.row.EstadoPagoId?.toString();
        const opcionesDisponibles = estadosPago?.map(status => status.id.toString());
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <FormControl>
              <Select
                disabled={(estadosPago?.find((estado: Estado) => estado.id === rowNew.EstadoPagoId)?.deshabilitar) ? true : false}
                title={(estadosPago?.find((estado: Estado) => estado.id === rowNew.EstadoPagoId)?.deshabilitar) ? 'No se puede cambiar el estado' : ''}
                value={opcionesDisponibles?.includes(valorSeleccionado) ? valorSeleccionado : ''}
                name='EstadoPagoId'
                onChange={e => handleChange(e, 'pago', params.row.id)}
                style={{ minWidth: 110, lineHeight: 'unset' }}
              >
                {estadosPago?.map(status => {
                  return (
                    <MenuItem
                      style={{ padding: 10 }}
                      key={status.id}
                      value={status?.id?.toString()}
                    >
                      <Chip
                        label={status.descripcion}
                        style={{ background: status.color, color: 'white', fontSize: 12, fontWeight: 'bold' }}
                      />
                    </MenuItem>
                  );
                  
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: 'fechaPago',
      headerName: 'Fecha pago',
      minWidth: 170,
      renderCell: (params: any) => {
        const field = params.getValue(params.id, 'fechaPago');
        return (
          <span>{field ? momentZone.tz(field, 'America/Guatemala').format('YYYY-MM-DD HH:mm A') : ''}</span>
        );
      },
    },
    {
      field: 'usuarioPagoId',
      headerName: 'Usuario actualiza pago',
      width: 250,
      renderCell: (params: any) => {
        return <span>{params.row?.usuarioPago?.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creación',
      minWidth: 200,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

  ];

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCloseModal = () => {
    setOpenModalEstado(false);
    setOpenHistorialEstados(false);
  };

  const openFiles = (link: string[]) => {

    try {
      if (link) {
        try {

          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([link]);
          setOpenImagesModal(true);
        }
      } else {

        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log('ERROR AQUI', error);
    }
  };

  const updateEstadoOrden = (data: any) => {
    handleLoading(true);
    if (idOrdenCambiar)
      CrudService.instance(Rutas.instance.urlOrden)
        .updateOneComplemento(idOrdenCambiar, data, '/estadoOrden')
        .then(response => {
          const { error, message } = response;
          if (!error) {
            handleActiveSuccess(true);
            handleSuccessMessage(message);
          } else {
            handleActiveError(true);
            handleErrorMessage(message);
          }
          getRows();
          getRowsGestionar();
          handleLoading(false);
          setOpenModalEstado(false);
        })
        .catch(e => {
          handleLoading(false);
          handleActiveError(true);
          handleErrorMessage(e.message);
        });
  }

  const columnsHistorialEstados = [
    {
      field: 'acciones',
      headerName: 'Acciones',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        console.log('params.row', params.row)
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            {params.row?.documentos?.length > 0 && (
              <IconButton
                size='small'
                title='Ver documento'
                onClick={() => openFiles(params.row.documentos.map((doc: any) => doc?.link ))}
              >
                <FindInPageTwoTone />
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      field: 'descripcion',
      headerName: 'Cambio',
      width: 520,
      title: 'Cambios',
      renderCell: (params: any) => {
        return <span title={params.row.descripcion}>{params.row.descripcion}</span>;
      }
    },
    {
      field: 'valor',
      headerName: 'Estado actual',
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },
    
  ];

  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeValue}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Mis Órdenes" {...a11yProps(0)} />
            <Tab label={
              <Badge style={{paddingRight: '8px'}} badgeContent={totalPagesGestionar*rowsGestionar.length} color="error">
                Gestionar
              </Badge>
            } {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container >
          <Grid
            item
            xs={12}
          >
            <Typography variant='h6'>
              Mis Órdenes
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Box
              justifyContent={['flex-start', 'flex-start', 'flex-end']}
              sx={{ flexDirection: 'row', display: 'flex' }}
            >
              <Grid item>
                <Button
                  component={Link}
                  to='ordenes/crear'
                  fullWidth
                  style={{ width: '100px',  margin: 6 }}
                  variant='contained'
                  color='primary'
                >
                  CREAR
                </Button>
                {/* <TextField
                  label='Buscar'
                  placeholder='Buscar'
                  variant='outlined'
                  size='small'
                  style={{ margin: 5}}
                  name='search'
                  value={searchValue}
                  onKeyDown={handleKeyDownChange}
                  onChange={handleSearchChange}
                /> */}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <DataGrid
            loading={loadingGrid}
            rows={rows}
            columns={columns}
            rowHeight={50}
            paginationMode='server'
            pageSize={pageSize}
            rowCount={totalPages * pageSize}
            hideFooterPagination
            hideFooter
            autoHeight
            rowsPerPageOptions={[10, 25, 50, 100]} />
            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                shape='rounded'
                size='medium'
                variant='outlined'
                color='primary'
                style={{ padding: '8px', textAlign: 'center' }}
                onChange={(event, value) => setPage(value)}
              />
            </div>
              
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container >
          <Grid
            item
            xs={12}
          >
            <Typography variant='h6'>
              Órdenes a gestionar
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Box
              justifyContent={['flex-start', 'flex-start', 'flex-end']}
              sx={{ flexDirection: 'row', display: 'flex' }}
            >
              <Grid item>
              <Button
                  component={Link}
                  to='ordenes/crear'
                  fullWidth
                  style={{ width: '100px',  margin: 6 }}
                  variant='contained'
                  color='primary'
                >
                  CREAR
                </Button>
                {/* <TextField
                  label='Buscar'
                  placeholder='Buscar'
                  variant='outlined'
                  size='small'
                  style={{ margin: 5}}
                  name='search'
                  value={searchValueGestionar}
                  onKeyDown={handleKeyDownChangeGestionar}
                  onChange={handleSearchChangeGestionar}
                /> */}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <DataGrid
            loading={loadingGrid}
            rows={rowsGestionar}
            columns={columnsGestionar}
            rowHeight={50}
            paginationMode='server'
            pageSize={pageSizeGestionar}
            rowCount={totalPagesGestionar * pageSizeGestionar}
            hideFooterPagination
            hideFooter
            autoHeight
            rowsPerPageOptions={[10, 25, 50, 100]} />
            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
              <Pagination
                count={totalPagesGestionar}
                page={pageGestionar}
                shape='rounded'
                size='medium'
                variant='outlined'
                color='primary'
                style={{ padding: '8px', textAlign: 'center' }}
                onChange={(event, value) => setPageGestionar(value)}
              />
            </div>
            
          </Grid>
        </Grid>
      </TabPanel>
      <ModalRazonDocumentos 
        open={openModalEstado} 
        valor={estadoIdCambiar} 
        textoAMostrar={estadoACambiar}  
        handleCloseModal={handleCloseModal}
        handleUpdate={updateEstadoOrden}
      />
      <Modal
        className={classes.modal}
        open={openHistorialEstados}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openHistorialEstados}>
          <div className={classes.paper}>
            <p id='transition-modal-description'>Registro histórico</p>
            
            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid
                item
                style={{ height: 700, width: 'auto' }}
              >
                <DataGrid
                  isRowSelectable={(params: GridRowParams) => false}
                  loading={loadingGrid}
                  rows={rowsHistorialEstados}
                  columns={columnsHistorialEstados}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openImagesModal}
        onClose={() => {
          setOpenImagesModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={openImagesModal}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Documento</h2>
            <p id='transition-modal-description'></p>

            {arrayImages.map((item: any) => (
              item.includes('pdf') ? (
                <iframe
                  src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                  style={{ width: '100%', height: '600px' }}
                  key={item}
                  title="PDF Viewer"

                />
              ) : (item.includes('mp4') ? (
                <video
                  width='100%'
                  height='600px'
                  controls
                  autoPlay
                  key={item}
                >
                  <source src={item} type='video/mp4' />
                </video>
              ) : (
                <img
                  src={item}
                //onClick={() => openFile(item)}
                />
              ))


            ))}

          </div>
        </Fade>
      </Modal>
      <PageLoader loading={loading} />
    </Box>  
  )
}

export default Ordenes