import { useEffect, useState } from 'react';
import FELService from '../../../services/FEL';
import { FacturacionNegocios } from '../../../interfaces/Interfaces';

/**
 * La función `useFacturacionNegocios` es un gancho personalizado en TypeScript que maneja la carga y
 * recuperación de información de facturación comercial.
 * @returns Se está devolviendo el gancho personalizado `useFacturacionNegocios`. Devuelve un objeto
 * con las siguientes propiedades:
 */
export const useFacturacionNegocios = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [facturacionNegocios, setFacturacionNegocios] = useState<FacturacionNegocios[]>([]);

  const loadData = async () => {
    const request = await new FELService().getAllFEL();

    const result = request.result;

    if (!result) {
      throw new Error('No se encontró información de facturación');
    }

    if (request.error) {
      throw new Error('No se encontró información de facturación');
    }

    setFacturacionNegocios(result);
  };

  useEffect(() => {
    setLoading(true);
    loadData()
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    loadData,
    setLoading,
    facturacionNegocios,
    setFacturacionNegocios,
  } as const;
};
