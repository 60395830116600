/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button, FormLabel, RadioGroup, FormControlLabel, Radio, ListItemIcon, Checkbox } from '@material-ui/core';
/**
 * Internal Libraries
 */
import localStorage from '../../libs/localStorage';
import { CONTACTMPOS, contactOrigin, contactStatus, departamentos, listamunicipios, listapaises } from '../../resources/Statics';
import ValidationFields from '../../libs/validationFields';
/**
 * Components
 */
import PageLoader from '../../components/PageLoader';
/**
 * Interfaces
 */
import { ContactsInterface, UserInterface } from '../../interfaces/Interfaces';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Services
 */
import ContactsService from '../../services/ContactsService';
import Utilities from '../../libs/utilities';
import RegisterService from '../../services/RegisterService';
import NegociosServices from '../../services/NegociosServices';

export default function ContactsCreate() {
  const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess } =
    useContext(GeneralContext);

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<ContactsInterface>({});

  const [activities, setActivities] = useState([]);
  const [subActivities, setSubActivities] = useState([]);
  const [usuariosAfiliadores, setUsuariosAfiliadores] = useState<UserInterface[]>([]);

  useEffect(() => {}, []);

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const getUsuariosAdministradoresAfiliadores = async () => {
    setLoading(true)
    let req = await NegociosServices.instance.getUsuariosAdministradoresAfiliadores()
    setUsuariosAfiliadores(req.result)
    setLoading(false)
  }

  const createContact = async () => {
    setLoading(true);

    const { error, validation } = ValidationFields.instance.newContact(values);

    if (error) {
      setLoading(false);
      handleErrorMessage(validation);
      handleActiveError(true);
    } else {
      ContactsService.instance
        .createContact({ ...values })
        .then(response => {
          setLoading(false);
          const { error, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            history.push('/admin-home/contacts');
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    }
  };

  useEffect(() => {
      async function start() {
          let req = await RegisterService.instance.getActivities()
          let reqsub = await RegisterService.instance.getSubActivities()
          setActivities(req.result)
          setSubActivities(reqsub.result)
      }
      start();
      getUsuariosAdministradoresAfiliadores()
  }, [])

  return (
    <Box
      mt={1}
      p={2}
      component={Paper}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid item>
          <Typography
            variant='h6'
            gutterBottom
          >
            Nuevo Contacto
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
          >
            Creación de nuevo contacto
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            label='Nombre'
            variant='outlined'
            fullWidth
            onChange={handleChange('nombre')}
          />
        </Grid>

        <Grid item>
          <TextField
            label='Apellido'
            variant='outlined'
            fullWidth
            onChange={handleChange('apellido')}
          />
        </Grid>

        <Grid item>
          <TextField
            label='Nombre del negocio'
            variant='outlined'
            fullWidth
            onChange={handleChange('nombreNegocio')}
          />
        </Grid>

        <Grid item>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="actividad-select-label" >Actividad del negocio</InputLabel>
                <Select
                    labelId="actividad-select-label"
                    id="contact-activity-select"
                    label="Actividad del negocio"
                    value={values.ActividadId}
                    onChange={handleChange('ActividadId')}>
                    <MenuItem value="">
                        <em>Seleccione</em>
                    </MenuItem>
                    {activities.map((act: any) => {
                        return <MenuItem key={act.id} value={act.id}>{act.nombre.toLocaleUpperCase()}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>

        <Grid item>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="sub-actividad-select-label" >Sub-Actividad del negocio</InputLabel>
                <Select
                    labelId="sub-actividad-select-label"
                    id="contact-subactivity-select"
                    label="SubActividad del negocio"
                    value={values.SubActividadId}
                    onChange={handleChange('SubActividadId')}>
                    <MenuItem value="">
                        <em>Seleccione</em>
                    </MenuItem>
                    {subActivities.map((act: any) => {
                        if (values.ActividadId == act.ActividadId || values.ActividadId === act.ActividadId) {
                            return <MenuItem key={act.id} value={act.id}>{act.nombre.toLocaleUpperCase() + ' - ' + Utilities.instance.aproximaDecimal(act.msc * 100, 3) + '%'}</MenuItem>
                        }
                    })}
                </Select>
            </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label='Teléfono'
            variant='outlined'
            fullWidth
            onChange={handleChange('telefono')}
          />
        </Grid>

        <Grid item>
          <TextField
            label='Teléfono 2'
            variant='outlined'
            fullWidth
            onChange={handleChange('telefono2')}
          />
        </Grid>

        <Grid item>
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            onChange={handleChange('email')}
          />
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='pais-select-label'>Pais</InputLabel>
            <Select
              labelId='pais-select-label'
              id='pais-contactos-select'
              label='Pais'
              onChange={handleChange('pais')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>

              {listapaises.map(pais => {
                return (
                  <MenuItem
                    key={pais}
                    value={pais.toLocaleLowerCase()}
                  >
                    {pais}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='departammento-select-label'>Departamento</InputLabel>
            <Select
              labelId='departammento-select-label'
              id='dep-contactos-select'
              label='Departamento'
              onChange={handleChange('departamento')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {values &&
              values.pais &&
              departamentos &&
              departamentos[values.pais] &&
              Array.isArray(departamentos[values.pais])
                ? departamentos[values.pais].map((dep: any) => {
                    return (
                      <MenuItem
                        key={dep}
                        value={dep.toLocaleLowerCase()}
                      >
                        {dep}
                      </MenuItem>
                    );
                  })
                : null}
              
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='municipio-select-label'>Municipio</InputLabel>
            <Select
              labelId='municipio-select-label'
              id='muni-contactos-select'
              label='Municipio'
              onChange={handleChange('municipio')}
            >
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
              {values &&
              values.departamento &&
              listamunicipios &&
              listamunicipios[values.departamento] &&
              Array.isArray(listamunicipios[values.departamento])
                ? listamunicipios[values.departamento].map((municipio: any) => {
                    return (
                      <MenuItem
                        key={municipio}
                        value={municipio.toLocaleLowerCase()}
                      >
                        {municipio}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label='Dirección'
            variant='outlined'
            fullWidth
            onChange={handleChange('direccion')}
          />
        </Grid>
                
        <Grid item>
            <FormControl fullWidth variant="outlined">
                    <InputLabel id="afiliador-select-label">Usuario afiliador</InputLabel>
                    <Select
                        labelId="afiliador-select-label"
                        id="contact-afiliate-select"
                        label="Usuario afiliador"
                        onChange={handleChange('UsuarioAfiliadorId')}>
                        <MenuItem value="">
                            <em>Seleccione</em>
                        </MenuItem>
                        {
                              usuariosAfiliadores.map((user) => {
                                  return <MenuItem style={{ minWidth: 150, }} key={user.id} value={user.id}>
                                      <Typography variant="inherit" noWrap>
                                          {user.nombre}
                                      </Typography>
                                  </MenuItem>
                              })
                          }
                    </Select>
            </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label='Notas'
            variant='outlined'
            fullWidth
            onChange={handleChange('notas')}
          />
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='origen-select-label'>Origen</InputLabel>
            <Select
              labelId='origen-select-label'
              id='origen-select'
              label='Origen'
              onChange={handleChange('origen')}
            >
              <MenuItem>
                <em>Seleccione</em>
              </MenuItem>
              {contactOrigin.map(origin => {
                return (
                  <MenuItem
                    key={origin}
                    value={origin}
                  >
                    {origin.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel id='estado-select-label'>Estado</InputLabel>
            <Select
              labelId='estado-select-label'
              id='estado-select'
              label='Estado'
              onChange={handleChange('estado')}
            >
              <MenuItem>
                <em>Seleccione</em>
              </MenuItem>
              {contactStatus.map(status => {
                return (
                  <MenuItem
                    key={status}
                    value={status}
                  >
                    {status.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Paper variant="outlined" style={{ padding: '16px' }}>
            <FormControl>
              <RadioGroup
                row
                onChange={handleChange('mPOS')}
                name="row-radio-buttons-group"
                aria-labelledby="mpos-row-radio-buttons-group-label"
              >
                <FormControlLabel value={CONTACTMPOS.VIRGEN} control={<Radio color="primary"/>} label="Virgen" />
                <FormControlLabel value={CONTACTMPOS.MEDIOVIRGEN} control={<Radio  color="primary"/>} label="Medio Virgen" />
                <FormControlLabel value={CONTACTMPOS.NOVIRGEN} control={<Radio  color="primary"/>} label="No Virgen" />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={createContact}
            fullWidth
          >
            Crear Contacto
          </Button>
        </Grid>
      </Grid>

      <PageLoader loading={loading} />
    </Box>
  );
}
