/**
 * External Libraries
 */
import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useState, useContext } from 'react';
import * as momentZone from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/*
* Components
*/
import LoadingButton from './LoadingButton';
import MenuItem from '@material-ui/core/MenuItem';

/*
* Interface
*/
import { NegocioWithTipoNegocioInterface } from './../interfaces/Interfaces'

interface componentProps {
    getReport: (fechaInicio: string, fechaFin: string, negocioId: number) => void
    titleReport?: string
    negociosPayfac: NegocioWithTipoNegocioInterface[]
}


const FacturasReport = (props: componentProps) => {

    let now = momentZone.tz('America/Guatemala');

    const [loading, setLoading] = useState(false);
    const [negocioId, setNegocioId] = useState<number>(-1);
    const [fechaInicio, setFechaInicio] = useState(now.startOf('month').format('YYYY-MM-DD') as string);
    const [fechaFin, setFechaFin] = useState(now.endOf('month').format('YYYY-MM-DD') as string);

    const handleChange = (event: any) => {
        setNegocioId(event.target.value);
    };

    return (
        <Box
            mt={1}
            p={2}
            component={Paper}
        >
            <Grid
                container
                direction='column'
                spacing={2}
            >
                <Grid item>
                    <Typography
                        variant='h6'
                        gutterBottom
                    >
                        {props.titleReport ? props.titleReport : 'Reporte de facturas por servicios a negocios payfac'}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        gutterBottom
                    >
                        Exportar reporte de facturas
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="standard-select-currency"
                        select
                        label="Negocio"
                        variant='outlined'
                        value={negocioId}
                        onChange={handleChange}
                        fullWidth
                    >
                        {props.negociosPayfac.map((option: NegocioWithTipoNegocioInterface) => (
                            <MenuItem key={option.id} value={option.id}>
                                {`Id: ${option.id} Negocio: ${option.nombre}`}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        type={'date'}
                        label='Fecha inicio'
                        variant='outlined'
                        fullWidth
                        value={fechaInicio}
                        onChange={field => setFechaInicio(field.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        type={'date'}
                        label='Fecha fin'
                        variant='outlined'
                        fullWidth
                        value={fechaFin}
                        onChange={field => setFechaFin(field.target.value)}
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        loading={loading}
                        color='primary'
                        variant='contained'
                        title='Descargar reporte'
                        onClick={() => props.getReport(fechaInicio, fechaFin, negocioId)}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default FacturasReport