import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class AbonoContracargoService {
  static instance = new AbonoContracargoService();

  createAbonoContracargo = async (params: { transactionId: string, tipoPago: string, ContracargoId: string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlAbonoContracargo;

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  createAbonoContracargoOtrosMedios = async (params: { monto: number, tipoAbono: string, ContracargoId: string, comentarios?: string, comprobante?:string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlAbonoContracargo + '/createAbonoOtrosMedios';

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Contracargo Abono Otros Medios service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  deleteAbonoContracargo = async (params: { abonoContracargoId: string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlAbonoContracargo + '/deleteAbonoContracargo/' + params.abonoContracargoId;

      const httpInstance = new http();

      let req = await httpInstance.delete(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  deleteAbonoContracargoOtrosMedios = async (params: { abonoContracargoId: string }) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlAbonoContracargo + '/deleteAbonoContracargoOtrosMedios/' + params.abonoContracargoId;

      const httpInstance = new http();

      let req = await httpInstance.delete(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.results };
    } catch (error: any) {
      console.log('Create Negocio service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
}