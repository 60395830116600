import React, { useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/LoadingButton';
import { Chip, colors, FormControl, IconButton, InputLabel, MenuItem, Select, TablePagination } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import { UserInterface } from '../../interfaces/Interfaces';
import UsersServices from '../../services/UsersServices';
import LogsService from '../../services/LogsService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as momentZone from 'moment-timezone'

import { Delete, Edit, Restore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import {
    DataGrid,
    GridToolbar,
    GridValueGetterParams
} from "@material-ui/data-grid";
import { Pagination } from '@material-ui/lab';
import { render } from '@testing-library/react';

interface NegocioProps {
    NegocioId?: number
}

export default function Users(props: NegocioProps) {
    const { handleErrorMessage, handleActiveError, handleSelectRecord, handleSuccessMessage, handleActiveSuccess } = useContext(GeneralContext)
    let history = useHistory()

    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        setPage(1)
    } , [])

    useEffect(() => {
        if (page > 0) getLogs();
      }, [page, pageSize]);

    const columns = [
        {
            field: "createdAt", headerName: 'Fecha de acción', width: 200,
            renderCell: (params: any) => {
                const fechaTZ6 = momentZone.tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala').locale('es').format('YYYY-MM-DD HH:mm A')
                return (<span>{fechaTZ6}</span>)
            }
        },
        { field: "descripcion", width: 550, headerName: "Acción / Descripción",
            renderCell: (params: any) => {
                return (<span title={params.row.descripcion}>{params.row.descripcion}</span>)
            }
         },
        {
            field: "usuario",
            headerName: "Usuario",
            sortable: false,
            width: 350,
            valueGetter:  (params: GridValueGetterParams) => {
                let temp = params.row
                if ((temp && temp.usuario && temp.usuario.nombre) && (temp.usuario.apellidoPaterno && temp.usuario.apellidoMaterno)) {
                    return `${temp.usuario.nombre} ${temp.usuario.apellidoPaterno} ${temp.usuario.apellidoMaterno}`
                }else if (temp.usuario && temp.usuario.apellidoPaterno && temp.usuario.apellidoMaterno){
                    return temp.usuario.apellido
                }else if (temp && temp.usuario && temp.usuario.nombre) {
                    return `${temp.usuario.nombre}`
                }else if (temp.usuario && temp.usuario.email){
                    return temp.usuario.email
                }else if (temp.usuario && temp.usuario.telefono){
                    return temp.usuario.telefono
                }else{
                    return ''
                }
            }
        },
        {
            field: "usuarioId",
            headerName: "Id Usuario",
            sortable: false,
            width: 130,
            valueGetter:  (params: GridValueGetterParams) => {
                let temp = params.row
                if (temp && temp.usuario && temp.usuario.id) {
                    return temp.usuario.id
                }else{
                    return ''
                }
            },
            
        },
        {
            field: "negocionombre",
            headerName: "Negocio",
            sortable: false,
            width: 350,
            valueGetter:  (params: GridValueGetterParams) => {
                let temp = params.row
                if (temp && temp.negocio && temp.negocio.nombre) {
                    return temp.negocio.nombre
                }else{
                    return ''
                }
            },
            
        },
        {
            field: "negocioId",
            headerName: "Id Negocio",
            sortable: false,
            width: 130,
            valueGetter:  (params: GridValueGetterParams) => {
                let temp = params.row
                if (temp && temp.negocio && temp.negocio.id) {
                    return temp.negocio.id
                }else{
                    return ''
                }
            },
            
        },
        
        { field: "id", headerName: "Id", width: 110, },
    ];

    const getLogs = async () => {
        const params = props.NegocioId ? `?page=${page}&pageSize=${pageSize}&NegocioId=${props.NegocioId}` : `?page=${page}&pageSize=${pageSize}`;
        let req = await LogsService.instance.get(params)
        setTotalPages(req.totalPages)
        setRows(req.result)
    }


    return (<Box mt={1} p={2} component={Paper} style={{ maxHeight: '100vh', height:' 74vh'}}>
        <Grid container direction="column" spacing={2} >
            {!props.NegocioId &&
                <Grid item >
                    <Grid container>
                        <Grid item xs={10} >
                            <Typography variant="h6" gutterBottom>
                                Historial
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item style={{ height: 500, width: 'auto' }}>
                <DataGrid 
                    components={{ Toolbar: GridToolbar }} 
                    paginationMode='server'
                    pageSize={pageSize}
                    rowCount={totalPages * pageSize}
                    hideFooterPagination
                    hideFooter
                    loading={loading} 
                    rows={rows} 
                    columns={columns} 
                    rowHeight={38} 
                     />
                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            shape='rounded'
                            size='medium'
                            variant='outlined'
                            color='primary'
                            style={{ padding: '8px', textAlign: 'center' }}
                            onChange={(event, value) => setPage(value)}
                            />
                    </div>
            </Grid>
        </Grid>
    </Box>)
}
