import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'
import { primary, grisFigma } from '../styles/colors'
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import PayWithLinkService from '../services/PayWithLinkService'
import { GeneralContext } from '../context/GeneralContext'
import PaymentIcon from '@material-ui/icons/Payment';
import * as momentZone from 'moment-timezone'
import { Images } from '../styles/images'
import ValidationFields from '../libs/validationFields'
import { estadosEnlace } from '../resources/Statics'
import PageLoader from '../components/PageLoader';
import { ConfirmPayment } from './ConfirmPayment';
import { ConfirmErrorPayment } from './ConfirmErrorPayment';
import moment from "moment";

const localization = require('moment/locale/es')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    paddingTop: '5%'
  },
  inputPadding: {
    paddingTop: '1%'
  }
}));

function CardNumberMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autocomplete="cc-number"
      maxlength="19"
    />
  );
}

CardNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirationMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

ExpirationMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CvvMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autocomplete="cc-number"
      maxlength="19"
    />
  );
}

CvvMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


export default function PayWithLink() {
  const classes = useStyles();
  const params = useParams<{ payCode: string }>()
  const history = useHistory();
  const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage } = useContext(GeneralContext)

  const [width, setWidth] = useState(window.innerWidth);

  const [monto, setMonto] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [pagoRapido, setEsPagoRapido] = React.useState(false);
  const [nombreEmpresa, setNombreEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [cantidadCuotas, setCantidadCuotas] = React.useState('');

  const [enlacePagado, setEnlacePagado] = React.useState(false);
  const [enlaceCaducado, setEnlaceCaducado] = React.useState(false);
  const [enlaceAnulado, setEnlaceAnulado] = React.useState(false);

  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {

    async function getLinkInfo() {
      handleLoading(true)

      const req = await PayWithLinkService.instance.Paso5Epayserver3dSecure(params.payCode)

      console.log(req)

      if (!req.error) {
        if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
          handleErrorMessage('Error de conexion por favor intente mas tarde')
          setErrorMessage('Error de conexion por favor intente mas tarde')
        } else {
          setShowConfirmComponent(true);
          handleSuccessMessage(req.message);
          handleActiveSuccess(true)
        }
      } else {
        if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
          handleErrorMessage('Error de conexion por favor intente mas tarde')
          setErrorMessage('Error de conexion por favor intente mas tarde')
        } else {
          setErrorMessage(req.message)
          handleErrorMessage(req.message)
        }
        setShowErrorComponent(true);
        // handleActiveError(true)
      }
      handleLoading(false)
    }

    getLinkInfo()

  }, [])

  return (
    <div className={classes.root}>
      {enlacePagado ? (
        <div
          style={{
            paddingTop: '3%',
            paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <div style={{ textAlign: 'center' }}>
                <img
                  width={width >= 700 ? width * 0.5 : 350}
                  src={Images.instance.linkYaFuePagado}
                  alt=''
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : enlaceCaducado ? (
        <div
          style={{
            paddingTop: '3%',
            paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <div>
                <img
                  width={width >= 700 ? width * 0.15 : 200}
                  src={Images.instance.logo}
                  alt=''
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{ textAlign: 'center' }}>
                <img
                  width={width >= 700 ? width * 0.5 : 350}
                  src={Images.instance.linkCaducado}
                  alt=''
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : enlaceAnulado ? (
        <div
          style={{
            paddingTop: '3%',
            paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <div>
                <img
                  width={width >= 700 ? width * 0.15 : 200}
                  src={Images.instance.logo}
                  alt=''
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{ textAlign: 'center' }}>
                <img
                  width={width >= 700 ? width * 0.5 : 350}
                  src={Images.instance.linkAnulado}
                  alt=''
                  onClick={() => (window.location.href = '/')}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        showConfirmComponent
          ?
          <ConfirmPayment />
          :
          showErrorComponent ?
            <ConfirmErrorPayment businessName={errorMessage} />
            :
            null
      )}
      <PageLoader loading={loading} />
    </div>
  );
}
