import React, { useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/LoadingButton';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import { NegocioInterface, RoleInterface, UsuarioInterface, EditRecordInterface } from '../../interfaces/Interfaces';
import UsersServices from '../../services/UsersServices';
import NegociosServices from '../../services/NegociosServices';
import ValidationFields from '../../libs/validationFields';
import RoleServices from '../../services/RoleServices';
import { useHistory, useParams } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';



export default function UsersEdit() {
    const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess, selectedRecord } = useContext(GeneralContext)
    
    let history = useHistory()
    let { id } = useParams<EditRecordInterface>();

    const [loading, setLoading] = React.useState(false);
    const [loadingActivar, setLoadingActivar] = React.useState(false);

    const [roleId, setRoleId] = React.useState(0);
    const [activar, setActivar] = React.useState(false);
    const [role, setRole] = React.useState<RoleInterface>({
        id: 0,
        nombre: '',
        tipo: ''
    });
    const [roles, setRoles] = React.useState<RoleInterface[]>([]);

    const [negocio, setNegocio] = React.useState<NegocioInterface | null>(null);

    const [negocioId, setNegocioId] = React.useState(0);
    const [negocios, setNegocios] = React.useState<NegocioInterface[]>([]);
    const [filteredBusinesses, setFilteredBusinesses] = useState<NegocioInterface[]>([]);
    const [selectedBusiness, setSelectedBusiness] = useState<NegocioInterface | null>(null);

    const [values, setValues] = useState<UsuarioInterface>({
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        cui: '',
        telefono: '',
        email: '',
        password: ''
    });
    useEffect(() => {
        getRoles()
        getNegocios()
    }, [])

    useEffect(() => {
        if (selectedRecord.id > 0) {
            const { eliminado, activada, ...resto } = selectedRecord
            setValues({ ...resto, password: '' })

            const { rolUsuario } = selectedRecord
            const { RolId, NegocioId, negocio } = rolUsuario[0];
            setActivar(selectedRecord.activada == 1 ? true : false)
            setNegocioId(NegocioId)
            setRoleId(RolId)
            setNegocio({id: NegocioId, nombre: negocio?.nombre})
        } else {
            history.push(`/admin-home/users`)
        }

    }, [selectedRecord])

    useEffect(() => {
        if (negocioId > 0) {
            const item = negocios.find(i => i.id === negocioId)
            //setNegocio(item as NegocioInterface)
        }
    }, [negocioId])

    useEffect(() => {
        if (roleId > 0) {
            const item = roles.find(i => i.id === roleId)
            setRole(item as RoleInterface)
        }
    }, [roleId])


    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleBusinessSelect = (event: React.ChangeEvent<{}>, value: NegocioInterface | null) => {
        setNegocio(value);
        setNegocioId(value?.id as number)
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.toLowerCase();
    
        getNegocios('?nombre='+searchValue)
        setNegocio(null);
      };

    const getNegocios = (params="") => {
        NegociosServices.instance.get(params).then(response => {
            const { result } = response
            setNegocios(result)
            setFilteredBusinesses(result);
        }).catch(e => {
            handleErrorMessage('message')
            handleActiveError(true)
        })
    }
    const getRoles = () => {
        RoleServices.instance.get().then(response => {
            const { result } = response
            setRoles(result)
        }).catch(e => {
            handleErrorMessage('message')
            handleActiveError(true)
        })
    };

    const createPassword = () => {
        const password = Math.random().toString(36).slice(-8);
        setValues({ ...values, password });
        handleSuccessMessage("Password generado")
        handleActiveSuccess(true)
    }

    const editUser = () => {
        let validation = ValidationFields.instance.editUser(values)
        let validationPass = ValidationFields.instance.password(values.password)
        if (validation.error) {
            handleErrorMessage(validation.message)
            handleActiveError(true)
        } else if (validationPass.error && values.password != '') {
            handleErrorMessage(validationPass.message)
            handleActiveError(true)
        } else {
            if (negocioId == 0) {
                handleErrorMessage('Debes seleccionar un negocio')
                handleActiveError(true)
                return;
            }
            if (roleId == 0) {
                handleErrorMessage('Debes seleccionar un rol para el usuario')
                handleActiveError(true)
                return;
            }
            if (!loading) {
                setLoading(true);
                UsersServices.instance.update({ ...values, role, negocio }, selectedRecord.id, negocioId).then(response => {
                    setLoading(false);
                    const { error, message } = response
                    if (!error) {
                        handleSuccessMessage(message)
                        handleActiveSuccess(true)
                        history.push('/admin-home/users')
                    } else {
                        handleErrorMessage(message)
                        handleActiveError(true)
                    }

                }).catch(e => {
                    setLoading(false);
                    handleErrorMessage(e.message)
                    handleActiveError(true)
                })

            }
        }

    };
    const activarDesactivar = () => {

        if (!loading) {
            setLoadingActivar(true);
            UsersServices.instance.activarDesactivar({ activar: !activar }, selectedRecord.id).then(response => {
                setLoadingActivar(false);
                const { error, message, result } = response
                if (!error) {
                    handleSuccessMessage(message)
                    handleActiveSuccess(true)
                    setActivar(!activar)
                } else {
                    handleErrorMessage(message)
                    handleActiveError(true)
                }

            }).catch(e => {
                setLoadingActivar(false);
                handleErrorMessage(e.message)
                handleActiveError(true)
            })
        }


    };

    return (<Box mt={1} p={2} component={Paper}>
        <Grid container direction="column" spacing={2} >
            <Grid item>
                <Typography variant="h6" gutterBottom>
                    Editar usuario
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Editar de cuenta del usuario
                </Typography>

            </Grid>
            <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                    Estado
                </Typography>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Desactivada</Grid>
                        <Grid item>
                            <Switch
                                checked={activar}
                                onChange={() => { activarDesactivar() }}
                                name="activar_cuenta"
                                color="primary"
                            />
                        </Grid>
                        <Grid item>Activada</Grid>
                    </Grid>
                </Typography>
            </Grid>
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    

                    <Autocomplete
                        id="autocomplete"
                        
                        options={negocios}
                        getOptionLabel={(business) => business.nombre}
                        value={negocio}
                        onChange={handleBusinessSelect}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Negocio'
                            onChange={handleSearchChange}
                        />
                        )}
                    />

                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="role-select-label">Rol</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={roleId}
                        label="Rol"
                        onChange={(item) => { setRoleId(item.target.value as number) }}>
                        {roles.map(({ id, nombre, tipo }) => <MenuItem key={id} value={id}>{nombre} - {tipo}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <TextField label="Nombre" variant="outlined" fullWidth value={values.nombre} onChange={handleChange('nombre')} />
            </Grid>
            <Grid item>
                <TextField label="Apellido Paterno" variant="outlined" fullWidth value={values.apellidoPaterno} onChange={handleChange('apellidoPaterno')} />
            </Grid>
            <Grid item>
                <TextField label="Apellido Materno" variant="outlined" fullWidth value={values.apellidoMaterno} onChange={handleChange('apellidoMaterno')} />
            </Grid>
            <Grid item>
                <TextField label="Teléfono" variant="outlined" fullWidth value={values.telefono} onChange={handleChange('telefono')} />
            </Grid>
            <Grid item>
                <TextField label="DPI" variant="outlined" fullWidth value={values.cui} onChange={handleChange('cui')} />
            </Grid>
            <Grid item>
                <TextField label="Correo" variant="outlined" fullWidth value={values.email} onChange={handleChange('email')} />
            </Grid>
            <Grid item>
                <TextField placeholder='Si lo dejas vacío quedará el password actual.' label="Contraseña" variant="outlined" fullWidth value={values.password} onChange={handleChange('password')} />
            </Grid>
            <Grid item>
                <Button fullWidth onClick={createPassword}>Generar contraseña</Button>
            </Grid>

            <Grid item>
                <LoadingButton loading={loading} color="primary" variant="contained" title='Guardar cambios' onClick={editUser} />
            </Grid>

        </Grid>
    </Box>)
}
