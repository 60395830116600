import { UsuarioInterface } from '../interfaces/Interfaces';
import http from '../libs/http'
import { Rutas } from '../resources/Statics'

export default class UsersServices {
    static instance = new UsersServices();

    get = async (params: any = "") => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + params

            const httpInstance = new http();

            let req = await httpInstance.get(ruta, true)

            if (req.error) throw new Error(req.result.message)

            return { error: req.error, message: '', result: req.result, totalPages: req.totalPages }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    create = async (params: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/admin'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, params, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    update = async (params: any, userId: any, NegocioId: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/' + userId + `/?NegocioId=${NegocioId}`

            const httpInstance = new http();

            let req = await httpInstance.put(ruta, params, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    activarDesactivar = async (params: any, userId: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/' + userId + '/activar'

            const httpInstance = new http();

            let req = await httpInstance.post(ruta, params, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req.result }
        } catch (error: any) {
            console.log('ActivarDesactivar User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    resetPassword = async (params: any) => {

        try {

            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/resetpassword'

            const httpInstance = new http();
            console.log(params)
            let req = await httpInstance.post(ruta, params, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    updateContratoEstado = async (idUsuario: any, negocioId: any, estadoDelContrato: boolean) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/updateContratoEstado/' + idUsuario + '/' + negocioId
            let data = {
                contratoEstado: estadoDelContrato
            }
            const httpInstance = new http();
            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    getUserContrato = async (idUsuario: any) => {
        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/getContrato/' + idUsuario
            const httpInstance = new http();
            let req = await httpInstance.get(ruta, true)
            if (req.error) throw new Error(req.message)
            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

    updateContrato = async (idUsuario: any, arregloLinks: string) => {

        try {
            const ruta = Rutas.instance.urlBase + Rutas.instance.urlUsuario + '/updateContrato/' + idUsuario
            let data = {
                contrato: arregloLinks
            }
            const httpInstance = new http();
            let req = await httpInstance.put(ruta, data, true)

            if (req.error) throw new Error(req.message)

            return { error: req.error, message: req.message, result: req }
        } catch (error: any) {
            console.log('Create User service error', error)
            return { error: true, message: error.message, result: null }
        }

    }

}