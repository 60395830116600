/**
 * External Libraries
 */
import { useEffect, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
/**
 * Animations
 */
import errorAnimation from '../animations/error-animation.json';
/**
 * Resources
 */
import { Images } from '../styles/images';
/**
 * Interfaces
 */
interface ComponentInterface {
  businessName: string;
}
/**
 * Styles
 */
const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
  },
  spacing_1: {
    padding: theme.spacing(2),
  },
  spacing_2: {
    padding: theme.spacing(4),
  },
  businessName: {
    fontWeight: 'bold',
    display: 'inline',
  },
  text: {
    fontWeight: 'bold',
    display: 'inline',
    textAlign: 'center',
  },
  googleLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
    },
  },
  iosLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start',
    },
  },
}));
/**
 * Component Definition
 */
export const ConfirmErrorPayment = ({ businessName }: ComponentInterface) => {
  const styles = useStyles();
  const animationRef = useRef<LottieRefCurrentProps>(null);

  const animationStyle = {
    width: 100,
  };

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.setSpeed(0.5);
      animationRef.current.playSegments([0, 70], true);
    }
  }, []);

  return (
    <Container
      className={styles.container}
      fixed
    >
      <Box className={styles.content}>
        <Lottie
          loop={true}
          animationData={errorAnimation}
          style={animationStyle}
          lottieRef={animationRef}
        />
        <Typography
          className={styles.spacing_1}
          style={{ textAlign: 'center' }}
        >
          {'No se pudo realizar el pago '}
        </Typography>

        <Typography
          variant='h6'
          className={styles.businessName}
        >
          {businessName}
        </Typography>

        <Typography
          className={styles.text}
          variant='subtitle2'
        >
          ¿Buscas transaccionar rápidamente tarjetas de crédito? <br /> Descarga la aplicación y registrate
        </Typography>

        <img
          className={styles.spacing_2}
          width={150}
          src={Images.instance.logoInvitacion}
          alt='Paggo'
        />

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={styles.googleLinkContainer}
          >
            <Button
              href='https://play.google.com/store/apps/details?id=com.paggoapp'
              target={'_blank'}
            >
              <img
                width={150}
                src={Images.instance.logoGooglePlay}
                alt='GooglePlay'
              />
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={styles.iosLinkContainer}
          >
            <Button
              href='https://apps.apple.com/gt/app/paggo/id1611051340'
              target={'_blank'}
            >
              <img
                width={150}
                src={Images.instance.logoAppStore}
                alt='AppStore'
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
