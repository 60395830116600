import http from '../libs/http';
import { Rutas } from '../resources/Statics';
import { DealersInterface } from '../interfaces/Interfaces';



export default class DealersService {
    static instance = new DealersService();
  
    getDealersList = async () => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/`;
  
        const httpInstance = new http();
  
        let req = await httpInstance.get(url, true);
  
        if (req.error) throw new Error(req.message);
  
        return {
          error: req.error,
          message: '',
          result: req.result,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };

    getDealerDeatil = async (id: string) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/byId/${id}`;
  
        const httpInstance = new http();
  
        let req = await httpInstance.get(url, true);
  
        if (req.error) throw new Error(req.message);
  
        return {
          error: req.error,
          message: '',
          result: req.result,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };
    

    getDealersTipos = async () => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/tipos`;
  
        const httpInstance = new http();
  
        let req = await httpInstance.get(url, true);
  
        if (req.error) throw new Error(req.message);
  
        return {
          error: req.error,
          message: '',
          result: req.result,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };

    getDealersReferidosList = async (id:string | number) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/referidosByDealer/${id}`;
  
        const httpInstance = new http();
  
        let req = await httpInstance.get(url, true);
  
        if (req.error) throw new Error(req.message);
  
        return {
          error: req.error,
          message: '',
          result: req.result,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };
  
    createDealer = async (params: DealersInterface) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}`;
  
        const httpInstance = new http();
  
        let { error, message } = await httpInstance.post(url, params, true);
  
        return {
          error,
          message,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };
    
    updateDealer = async (params: DealersInterface, id: number | string) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/${id}`;
  
        const httpInstance = new http();
  
        let { error, message } = await httpInstance.put(url, params, true);
  
        return {
          error,
          message,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };

    deleteDealer = async (id: number | string) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/${id}`;
  
        const httpInstance = new http();
  
        let { error, message } = await httpInstance.delete(url, {}, true);
  
        return {
          error,
          message,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };
    
    
    createBulkDealers = async (params: {dealers: Array<DealersInterface>}) => {
      try {
        const url = `${Rutas.instance.urlBase}${Rutas.instance.urlDealers}/bulkDealers`;
  
        const httpInstance = new http();
  
        let { error, message } = await httpInstance.post(url, params, true);
  
        return {
          error,
          message,
        };
      } catch (error: any) {
        console.log('Get contacts with error', error);
        return {
          error: true,
          message: error.message,
          result: null,
        };
      }
    };
  }
  