import { Accordion, AccordionDetails, AccordionSummary, AppBar, Avatar, Box, Button, ClickAwayListener, CssBaseline, Dialog, DialogActions, DialogTitle, Divider, Drawer, Grid, Grow, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Theme, Toolbar, Typography, createStyles, makeStyles, useTheme } from '@material-ui/core'
import { Menu as MenuIcon, ExpandMore as ExpandMoreIcon, PieChartTwoTone, LinkTwoTone, PaymentTwoTone, WidgetsTwoTone, BusinessTwoTone, PeopleTwoTone, MonetizationOnTwoTone, VisibilityTwoTone, AssignmentTwoTone, AssessmentTwoTone, HistoryTwoTone, DescriptionTwoTone, ThreeSixtyTwoTone, PauseTwoTone, BackupTwoTone, SettingsTwoTone, LockOpenTwoTone, LocationOnTwoTone, KeyboardTwoTone, FileCopyTwoTone, ShoppingCartTwoTone, ListAltTwoTone, ViewHeadlineTwoTone, ZoomOutMapTwoTone, FlareTwoTone, InputTwoTone, PlaylistAddCheckTwoTone, BeenhereTwoTone } from '@material-ui/icons';
import { Fragment, useEffect, useRef, useState, useContext } from 'react';

import { Images } from '../styles/images';
import localStorage from '../libs/localStorage';
import { Link, useLocation } from 'react-router-dom';
import { defColors } from '../App';
import { grisFigma } from '../styles/colors';
import { GeneralContext } from '../context/GeneralContext'

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    shadow: {
      boxShadow: '0px 8px 12px #091E4226, 0px 0px 1px #091E424F'
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      boxShadow: ''
    },
    appBar: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#5166AF',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    logo: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      height: 'calc(100vh)',
      width: 'calc(100% - 240px)',
      padding: theme.spacing(3),
      background: '#EFEFEF',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

interface Menu {
  id: number;
  label: string;
  path: string;
  componente: string;
  isExterno: boolean;
  icon: string;
  submenus: Menu[];
}

interface Props {
  menus: Menu[],
}

interface IconsModule {
  [key: string]: React.ElementType;
}

const icons: { [key: string]: any } = {
  'PieChartTwoTone': PieChartTwoTone,
  'LinkTwoTone': LinkTwoTone,
  'PaymentTwoTone': PaymentTwoTone,
  'WidgetsTwoTone': WidgetsTwoTone,
  'BusinessTwoTone': BusinessTwoTone,
  'PeopleTwoTone': PeopleTwoTone,
  'MonetizationOnTwoTone': MonetizationOnTwoTone,
  'VisibilityTwoTone': VisibilityTwoTone,
  'AssignmentTwoTone': AssignmentTwoTone,
  'AssessmentTwoTone': AssessmentTwoTone,
  'HistoryTwoTone': HistoryTwoTone,
  'DescriptionTwoTone': DescriptionTwoTone,
  'ThreeSixtyTwoTone': ThreeSixtyTwoTone,
  'PauseTwoTone': PauseTwoTone,
  'BackupTwoTone': BackupTwoTone,
  'SettingsTwoTone': SettingsTwoTone,
  'LockOpenTwoTone': LockOpenTwoTone,
  'LocationOnTwoTone': LocationOnTwoTone,
  'KeyboardTwoTone': KeyboardTwoTone,
  'FileCopyTwoTone': FileCopyTwoTone,
  'ShoppingCartTwoTone': ShoppingCartTwoTone,
  'ListAltTwoTone': ListAltTwoTone,
  'ZoomOutMapTwoTone': ZoomOutMapTwoTone,
  'ViewHeadlineTwoTone': ViewHeadlineTwoTone,
  'FlareTwoTone': FlareTwoTone,
  'InputTwoTone': InputTwoTone,
  'PlaylistAddCheckTwoTone': PlaylistAddCheckTwoTone,
  'BeenhereTwoTone': BeenhereTwoTone
}

const AppBarAdmin = (props: Props) => {

  const classes = useStyles();
  const theme = useTheme();
  const container = window !== undefined ? () => window.document.body : undefined;
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const anchorRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const [pathActual, setPathActual] = useState<string>('');
  const [expanded, setExpanded] = useState<string | false>(false);
const [esCliente, setEsCliente] = useState<boolean>(false);

  const cerrarSesion = async () => {
    await localStorage.instance.cerrarSesion();
    window.location.href = '/';
  };

  useEffect(() => {
    verificarSiEsCliente();
    const { pathname } = location;
    let nuevo = pathname.replace('/admin-home', '').replace('/home', '');

    setPathActual(nuevo);
    obtenerUsuario()
  }, [])

  const obtenerUsuario = async () => {
    const usuario = await localStorage.instance.getUserParseado();
    if (usuario && usuario?.nombre)
      setUsername(usuario.nombre);

    if (usuario && usuario.rolUsuario && usuario.rolUsuario[0].rol)
      setRole(usuario.rolUsuario[0].rol?.tipo);

    if (usuario?.email) setEmail(usuario.email)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const dialogo = (
    <Dialog
      open={openDialog}
      keepMounted
      onClose={() => setOpenDialog(false)}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>{'¿Quieres cerrar sesión?'}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => setOpenDialog(false)}
          color='primary'
        >
          Cancelar
        </Button>
        <Button
          onClick={() => cerrarSesion()}
          color='primary'
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const ObtenerIcon = (icon: string) => {

    if (icons[icon]) {
      const Icon = icons[icon];
      return <Icon key={'icon-' + icon} />;
    } else {
      // Manejar el caso en el que el icono no exista
      return <Fragment key={'fragment-' + icon} />;
    }
  }

  const handleClick = (param: any) => {
    setPathActual(param);
  };

  const handleClickExterno = (path: any) => {
    window.open(path, '_blank');
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const verificarSiEsCliente = async () => {
    let user = await localStorage.instance.getUserParseado();
    if (
      user
      && user.rolUsuario
      && user.rolUsuario[0].rol
      && user.rolUsuario[0].rol.tipo
      &&
      (
        user.rolUsuario[0].rol.tipo == 'administradorPayFac'
        || user.rolUsuario[0].rol.tipo == 'cajeroPayFac'
        || user.rolUsuario[0].rol.tipo == 'financieroPayFac'
        || user.rolUsuario[0].rol.tipo == 'gerentePayFac'
        || user.rolUsuario[0].rol.tipo == 'administrador'
        || user.rolUsuario[0].rol.tipo == 'cajero'
        || user.rolUsuario[0].rol.tipo == 'financiero'
        || user.rolUsuario[0].rol.tipo == 'gerente'
      )
    )
      setEsCliente(true);
  }



  const generarListItemMenu = (menu: Menu) => {
    let ruta = esCliente ? '/home' + menu.path : '/admin-home' + menu.path;
    return (
      !menu.isExterno ?
        <ListItem
          key={menu.id}
          button
          selected={menu.path === pathActual}
          component={Link}
          to={ruta}
          onClick={() => {
            handleClick(menu.path);
          }}
        >
          <ListItemIcon key={'itemicon-'+menu.id} style={{ color: pathActual === menu.path ? defColors[0] : grisFigma }}>
            { ObtenerIcon(menu.icon) }
          </ListItemIcon>
          <ListItemText
            key={'itemtext-'+menu.id}
            style={{ color: pathActual === menu.path ? defColors[0] : grisFigma }}
            primary={menu.label.toLocaleUpperCase()}
          />
        </ListItem>
      : 
        <ListItem
          key={menu.id}
          button
          selected={menu.path === pathActual}
          onClick={() => {
            handleClickExterno(menu.path);
          }}
        >
          <ListItemIcon key={'itemicon-'+menu.id} style={{ color: pathActual === menu.path ? defColors[0] : grisFigma }}>
            { ObtenerIcon(menu.icon) }
          </ListItemIcon>
          <ListItemText
            key={'itemtext-'+menu.id}
            style={{ color: pathActual === menu.path ? defColors[0] : grisFigma }}
            primary={menu.label.toLocaleUpperCase()}
          />
        </ListItem>
      )
  }

  const drawer = (
    <>
      <Box
        justifyContent='center'
        display='flex'
        style={{ borderRadius: 0 }}
      >
        <img
          src={Images.instance.logo}
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            height: '73px',
            width: 'auto',
          }}
        />
      </Box>
      <List>
        {props?.menus.map((menu: Menu) => {
          if (menu.submenus.length > 0) {
            return (
              <Accordion style={{ background: 'transparent' }} expanded={menu.submenus.find(elem => elem.path === pathActual) ? true : false || expanded === 'acordion-' + menu.id} key={'acordion-' + menu.id} onChange={handleChange('acordion-' + menu.id)}>
                <AccordionSummary key={'acordionsummary-' + menu.id} expandIcon={<ExpandMoreIcon />}>
                  <ListItemIcon key={'itemicon-' + menu.id} style={{ color: pathActual === menu.path ? defColors[0] : grisFigma }}>
                    {ObtenerIcon(menu.icon)}
                  </ListItemIcon>
                  <ListItemText
                    key={'itemtext-' + menu.id}
                    style={{ color: pathActual === menu.path ? defColors[0] : grisFigma, fontSize: '8px !important' }}
                    primary={menu.label.toLocaleUpperCase()}
                  />
                </AccordionSummary>
                <AccordionDetails key={'acordiondetails-' + menu.id}>
                  <List key={'acordionlist-' + menu.id}>
                    {menu.submenus.map((submenu: Menu) => {
                      return generarListItemMenu(submenu)
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>)
          } else {
            return generarListItemMenu(menu)
          }
        })}
      </List>
    </>
  );

  const getInitials = (name: string) => {
    const parts = name.split(' ');
    const initials = parts.map(part => part[0]);
    return initials.join('').toUpperCase();
  };

  return (
    <>
      <CssBaseline />
      {dialogo}
      <AppBar
        position='fixed'
        style={{ borderRadius: 0, background: '#fafafa' }}
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <img
            className={classes.logo}
            src={Images.instance.logo}
            style={{
              width: 'auto',
              height: '60px',
            }}
          />

          <Typography
            variant='h6'
            style={{
              flexGrow: 1,
            }}
          ></Typography>
          <div className={classes.root}>
            <div>
              <Button

                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <Avatar style={{ backgroundColor: grisFigma }}>{getInitials(username || email)}</Avatar>
              </Button>
              <Popper style={{ width: '26em' }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className={classes.shadow} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <Grid container direction='row' alignItems='center' style={{ padding: '1rem' }}>
                            <Grid item xs={2}>
                              <Avatar style={{ backgroundColor: grisFigma }}>{getInitials(username || email)}</Avatar>
                            </Grid>
                            <Grid item xs={10} style={{ paddingLeft: '0.5em' }}>
                              <div><strong>{username || email}</strong></div>
                              <small style={{ color: 'grey' }}>{role}</small>
                            </Grid>
                          </Grid>
                          <Divider />
                          <div style={{ height: '0.4em' }}></div>
                          <MenuItem style={{ padding: '1rem' }} onClick={() => setOpenDialog(true)}>Cerrar sesión</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden
          smUp
          implementation='css'
        >
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            style={{ borderRadius: 0 }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden
          mdDown
          implementation='css'
        >
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open

          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  )
}

export default AppBarAdmin