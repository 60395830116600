import Modal from '@material-ui/core/Modal';
import React, { useContext, useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PropTypes, { InferProps } from 'prop-types';
import VerificationService from '../../../services/VerificationService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../../components/LoadingButton';
import { GeneralContext } from '../../../context/GeneralContext';
import ReactJson from 'react-json-view';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: "3rem",
      marginBottom: "3rem"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
    },
    imageList: {
      width: 500,
      height: 450,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '60vw',
      height: 500,
      overflow: 'scroll',
    },
  }),
);

export function TabVerification({
  verificationData,
}: InferProps<typeof TabVerification.propTypes>) {
  const classes = useStyles();
  const [verificationJson, setVerificationJson] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      setLoading(true);
      const result: any = await VerificationService.instance.getVerificationByMetaMapId(
        verificationData.verificacionMetamap.verificacionId
      );
      setVerificationJson(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [verificationCode, setVerificationCode] = useState('');
  const {
    loading: generalLoading,
    handleLoading,
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
    handleSelectRecord,
    selectedRecord,
  } = useContext(GeneralContext);

  const updateVerificationCode = async () => {
    handleLoading(true);

    let Hooks = {};
    try {
      const result = await VerificationService.instance.getVerificationByMetaMapId( verificationCode );
      Hooks = result;
      const { error, message } = result;
      if (!error) {
        handleSelectRecord(result);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error.message);
    } else {
        handleErrorMessage("An unexpected error occurred.");
    }
  }
    try {
      const result = await VerificationService.instance.setVerificacion({
        hooks: Hooks,
        verificacionId: verificationCode,
        negocioId: verificationData.id
      });
      handleLoading(false);
      const { error, message } = result;
      if (!error) {
        handleSuccessMessage(message);
        handleActiveSuccess(true);
      } else {
        handleErrorMessage(message);
        handleActiveError(true);
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof Error) {
        handleErrorMessage(error.message);
    } else {
        handleErrorMessage("An unexpected error occurred.");
    }
    handleActiveError(true);
    }
  };
  return (
    <Box style={{ paddingTop: '1rem', marginTop: '1rem' }}>
    <Grid
      container
      direction='column'
      spacing={3} // Esto agrega un margen entre los Grid items
      key='t-1'
    >
      <Grid item>
        <TextField
          label='Código de Verificación'
          variant='outlined'
          fullWidth
          value={verificationCode}
          onChange={(event) => setVerificationCode(event.target.value)}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          loading={loading}
          color='primary'
          variant='contained'
          title='Enviar código de verificación'
          onClick={updateVerificationCode}
        />
      </Grid>
    </Grid>
    <Box
      className={classes.modal}
    >
      <div className={classes.modalLiquidacion}>
        <h2 id='transition-modal-title'>Información de la Verificación</h2>
        {loading ? <CircularProgress /> : <ReactJson src={verificationJson} />}
      </div>
    </Box>
  </Box>
  );
}

TabVerification.propTypes = {
  verificationData: {
    verificacionId: PropTypes.string,
  },
};
