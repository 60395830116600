import { Box, Button, Chip, Dialog, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { Add, AssignmentTwoTone, CancelTwoTone, CheckCircleTwoTone, DoneOutlineTwoTone, HighlightOffTwoTone, HourglassEmptyTwoTone, HourglassFullTwoTone, RemoveTwoTone } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import CrudService from '../../services/CrudService';
import { Rutas, estadosSolicitudes } from '../../resources/Statics';
import { GeneralContext } from '../../context/GeneralContext';
import { Autocomplete } from '@material-ui/lab';
import { Link, useHistory, useParams } from 'react-router-dom';
import Movimiento from './Movimiento';


interface Ubicacion {
  id: number;
  descripcion: string;
}

interface Movimiento {
  id: number;
  ResponsableUsuarioId: number;
  SolicitudPosUbicacionId: number;
  estado: string;
  razonRechazo: string;
  rollos: number;
  sobres: number;
  stickers: number;
}

interface ItemPedido {
  id: number;
  cantidad: number;
  modelo: string;
  UbicacionDestinoId: number;
  eliminar?: boolean;
  ubicacion: Ubicacion;
  movimiento?: Movimiento;
}

interface Solicitud {
  pedidos: ItemPedido[];
  UsuarioDestinoId: number;
  descripcion: string;
  notas?: string;
  usuarioSolicita: {nombre: string};
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formControlHeader: {
      margin: theme.spacing(1),
      minWidth: 280,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    formControlCantidad: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(0),
      right: theme.spacing(2),
    },
  }),
);



const AtenderSolicitudesOne = () => {

  const classes = useStyles();
  let history = useHistory()
  const {
    handleErrorMessage,
    handleActiveError,
    handleSuccessMessage,
    handleActiveSuccess,
  } = useContext(GeneralContext);

  let { id } = useParams<{id: string}>();
  const [ubicaciones, setUbicaciones] = useState<{id: number, descripcion: string, disponibles: number}[]>([]);
  const [responsables, setResponsables] = useState<{id: number, nombre: string}[]>([]);
  const [loading, setLoading] = useState(false);
  const [openSolicitud, setOpenSolicitud] = useState(false);
  const [accion, setAccion] = useState('')
  const [cantidad, setCantidad] = useState(0);
  const [modelo, setModelo] = useState('');
  const [values, setValues] = useState<Solicitud>({
    pedidos: [{id: 0, cantidad: 1, modelo: 'Model', UbicacionDestinoId: 0, ubicacion: {id: 0, descripcion: ''},
      movimiento: undefined}],
    UsuarioDestinoId: 0,
    descripcion: '',
    notas: '',
    usuarioSolicita: {nombre: ''}
  });

  const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState<Ubicacion>({id: 0, descripcion: ''});
  const [movimientoSeleccionado, setMovimientoSeleccionado] = useState<Movimiento | undefined>({id: 0, ResponsableUsuarioId: 0, SolicitudPosUbicacionId:0, estado: '', razonRechazo: '', rollos: 0, sobres: 0, stickers: 0});
  const [pedidoId, setPedidoId] = useState(0);

  useEffect(() => {
    reset();
  }, []);

  const reset = () => {
    getSolicitud();
    getUbicaciones();
    getResponsables();
    setUbicacionSeleccionada({id: 0, descripcion: ''});
    setMovimientoSeleccionado({id: 0, ResponsableUsuarioId: 0, SolicitudPosUbicacionId:0, estado: '', razonRechazo: '', rollos: 0, sobres: 0, stickers: 0});
    setPedidoId(0);
    setCantidad(0);
    setModelo('');
  }


  const getSolicitud = () => {
    if (id !== '0') {
      const crudService = CrudService.instance(Rutas.instance.urlSolicitudPos);
      crudService
        .getOne(Number(id))
        .then(async response => {
          const { error, result } = response;
          if (!error) {
            console.log('result', result)
            setValues(result);
          }
        })
        .catch(e => {
          handleErrorMessage('message');
          handleActiveError(true);
        });
    }
  }

  const getUbicaciones = () => {
    setLoading(true);
    const crudService = CrudService.instance(Rutas.instance.urlUbicacionesInventarioMpos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setUbicaciones(result);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const getResponsables = () => {
    const crudService = CrudService.instance(Rutas.instance.urlUsuario + '/recibensolicitudes');
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setResponsables(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleCantidadChange = (e: any, index: number, key: any, type: string = 'string') => {
    const newItemsPedidos = [...values.pedidos]; // Crear una nueva instancia del array
    newItemsPedidos[index] = {...newItemsPedidos[index], [key]: type === 'string' ? e.target.value : Number(e.target.value)}; // Actualizar la cantidad del elemento en el índice dado
    setValues({...values, pedidos: newItemsPedidos}); // Actualizar el estado
  };

  const guardarSolicitud = () => {
    const crudService = CrudService.instance(Rutas.instance.urlSolicitudPos);
    if (id === '0') {
      crudService
        .create(values)
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage('Solicitud creada con éxito');
            handleActiveSuccess(true);
            history.push(`/admin-home/movimientos/solicitar-pos`)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          handleErrorMessage('message');
          handleActiveError(true);
        });
    } else {
      crudService
        .updateOne(Number(id), values)
        .then(async response => {
          const { error, result, message } = response;
          if (!error) {
            handleSuccessMessage(message);
            handleActiveSuccess(true);
            history.push(`/admin-home/movimientos/solicitar-pos`)
          } else {
            handleErrorMessage(message);
            handleActiveError(true);
          }
        })


      
    }
  }

  return (
    <Box
      mt={1}
      mb={2}
      pt={2}
      pl={2}
      pr={2}
      pb={4}
      component={Paper}
    >
      <Grid container >
        <Grid
        style={{position: 'relative'}}
          item
          xs={12}
        >
          <Typography variant='h6'>
            Atender solicitud de POS: {id}
          </Typography>
         
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ padding: '0.5em', marginTop: '2em' }} >
            <Grid item xs={12}>
              <strong style={{backgroundColor:'rgba(0, 0, 0, 0.02)', padding:'1em'}}>DESCRIPCIÓN</strong>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{position: 'relative'}}
        >
          <FormControl className={classes.formControlHeader}>
            <TextField disabled label="Descripción" value={values.descripcion} onChange={(e)=>setValues({...values,descripcion: e.target.value}) } fullWidth multiline />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          style={{position: 'relative'}}
          >
          <FormControl className={classes.formControlHeader}>
            <TextField disabled label="Solicita el usuario:" value={values?.usuarioSolicita?.nombre} />
          </FormControl>
          
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid container style={{ padding: '0.5em', marginTop: '2em' }} >
            <Grid item xs={12}>
              <strong style={{backgroundColor:'rgba(0, 0, 0, 0.02)', padding:'1em'}}>DETALLE DE LA SOLICITUD</strong>
            </Grid>
          </Grid>
          {values.pedidos.map((itemPedido, index) => (
            console.log('itemPedido', itemPedido),
            itemPedido.eliminar === true ? null : 
              <>
                <Grid key={'grid_'+index} container style={{backgroundColor:'rgba(0, 0, 0, 0.02)', borderRadius:'20px', padding: '0.5em', marginTop: '0.5em' }} >
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Para la Ubicación: </InputLabel>
                      <Select
                        fullWidth
                        value={itemPedido.UbicacionDestinoId}
                        disabled
                      >
                        {ubicaciones.map((ubicacion) => (
                          <MenuItem key={ubicacion.id} value={ubicacion.id}>
                            {ubicacion?.descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                      {itemPedido.UbicacionDestinoId === 0 ? <small style={{color:'red'}}>Seleccione una ubicación</small> :
                        <small style={{color:'rgba(0, 0, 0, 0.38)'}}>(<strong>{ubicaciones.find(ubicacion => ubicacion.id === itemPedido.UbicacionDestinoId)?.disponibles}</strong> POS disponibles en esta ubicación )</small>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth className={classes.formControlCantidad}>
                      <TextField
                        disabled
                        label="Cantidad"
                        type='number'
                        fullWidth
                        value={itemPedido.cantidad}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        label="Modelo"
                        disabled
                        fullWidth
                        value={itemPedido.modelo}
                      />
                    </FormControl>
                  </Grid>
                  {id !== '0' &&
                    <Grid item xs={12} md={2}>
                        <FormControl style={{paddingLeft:'1em'}} className={classes.formControl}>
                            <small style={{color:'rgba(0, 0, 0, 0.38)'}}>Estado movimiento</small>
                            <Chip
                              label={itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].value : 'No atendido'}
                              style={{background: itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].background : '#e0e0e0', color: itemPedido.movimiento ? estadosSolicitudes[itemPedido.movimiento.estado].color : '#000' }}
                            />
                        </FormControl>
                    </Grid>
                  }
                  <Grid item xs={12} md={2} style={{padding:'1em'}}>
                    
                    <Fab title='Atender solicitud'  onClick={()=> {
                      (!itemPedido?.movimiento || itemPedido?.movimiento?.estado === 'P') ? '' : setAccion('view')
                      setUbicacionSeleccionada(itemPedido.ubicacion)
                      setMovimientoSeleccionado(itemPedido.movimiento)
                      setPedidoId(itemPedido.id)
                      setCantidad(itemPedido.cantidad)
                      setModelo(itemPedido.modelo)
                      setOpenSolicitud(true)
                    }} 
                      
                      size='small' 
                      aria-label="add">
                        <AssignmentTwoTone />
                    </Fab>
                  </Grid>
                </Grid>
              </>
          ))}
        </Grid>
        {values.notas &&
          <Grid
            item
            xs={12}
            style={{position: 'relative', marginTop: '2em'}}
          >
            <FormControl fullWidth className={classes.formControl}>
              <TextField disabled label="Notas adicionales" value={values.notas} fullWidth multiline />
            </FormControl>
          </Grid>
        }
      </Grid>
      <Grid container>
        <Grid item xs={12} md={9}></Grid>
        <Grid item xs={12} md={3} style={{textAlign:'right', paddingTop: '1em'}}>
          <Button
            component={Link}
            to='/admin-home/movimientos/atender-solicitudes'
            variant='contained'
            color='secondary'
            style={{ margin: 6 }}
          >
            Cancelar
          </Button>
        </Grid>
        
      </Grid>
      {pedidoId > 0 &&
        <Movimiento
          openSolicitud={openSolicitud}
          ubicacion={ubicacionSeleccionada}
          setOpenSolicitud={()=> setOpenSolicitud(false)}
          SolicitudPosUbicacionId={Number(pedidoId)}
          movimiento={movimientoSeleccionado}
          reset={reset}
          accion={accion}
          setAccion={setAccion}
          cantidad={cantidad}
          modelo={modelo}
        />
      }
    </Box>
  )
}

export default AtenderSolicitudesOne