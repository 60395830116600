import { EstablecimientosNegocio, FacturacionNegocios } from '../../interfaces/Interfaces';
import http from '../../libs/http';
import { Rutas } from '../../resources/Statics';

export default class FELService {
  static instance = new FELService();

  getAllFEL = async () => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlFEL}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: '',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get all fel with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getFEL = async (negocioId?: string) => {
    try {
      if (!negocioId) throw new Error('No se ha proporcionado el id del negocio');

      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlFEL}/negocio/${negocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: '',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get fel with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  updateFirmaFEL = async (negocioId: string, firma: string, firmaPassword: string) => {
    try {
      if (!negocioId) throw new Error('No se ha proporcionado el id del negocio');

      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlFEL}/updateFirma/negocio/${negocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, { firma, firmaPassword }, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: '',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update firma fel with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  updateFEL = async (
    negocioId: string,
    props: {
      facturacion: Partial<FacturacionNegocios>;
      establecimientos: Partial<EstablecimientosNegocio>;
    },
  ) => {
    try {
      if (!negocioId) throw new Error('No se ha proporcionado el id del negocio');

      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlFEL}/update/negocio/${negocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.put(url, props, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: '',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Update fel with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };
}
