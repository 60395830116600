/**
 * Internal Libraries
 */
import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class ReportesServices {
  static instance = new ReportesServices();

  transacciones = async (params: any) => {
    try {
      const httpInstance = new http();
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transacciones`;
      return {
        error: false,
        file: await httpInstance.download(ruta, params, true),
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  reporteTransaccionesLinkPayfac = async (params: any) => {
    try {
      const httpInstance = new http();
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/reporteTransaccionesLinkPayfac`;
      return {
        error: false,
        file: await httpInstance.download(ruta, params, true),
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  transaccionesTarjeta = async (params: any) => {
    try {
      const httpInstance = new http();
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transaccionesTarjeta`;
      return {
        error: false,
        file: await httpInstance.download(ruta, params, true),
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  transaccionesTarjetaPayfac = async (params: { fechaInicio: string; fechaFin: string }) => {
    try {
      const httpInstance = new http();
      const ruta = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transaccionesTarjetaPayfac`;
      return {
        error: false,
        file: await httpInstance.download(ruta, params, true),
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  getSettlement = async (type: string, selectedDay: number, fechaDia: string) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/?tipo=${type}`;
      return {
        error: false,
        file: await httpInstance.download(url, { selectedDay, fechaDia }, true),
      };
    } catch (error) {
      console.log('Settlement service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  getDaysSettlement = async () => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/dias`;
      const httpInstance = new http();
      let req = await httpInstance.get(url, true);
      if (req.error) throw new Error(req.message);
      return {
        error: req.error,
        message: 'Dias de liquidaciones Guatemala obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get dias liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getSettlementHonduras = async (type: string, selectedDay: number) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion-honduras/?tipo=${type}`;
      return {
        error: false,
        file: await httpInstance.download(url, { selectedDay }, true),
      };
    } catch (error) {
      console.log('Settlement service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  getSettlements = async (startDateSettlement?: string, endDateSettlement?: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/?startDateSettlement=${startDateSettlement}&endDateSettlement=${endDateSettlement}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Liquidaciones obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getSettlementsHonduras = async (startDateSettlement?: string, endDateSettlement?: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/honduras/?startDateSettlement=${startDateSettlement}&endDateSettlement=${endDateSettlement}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Liquidaciones obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getBusinessSettlements = async (liquidacion: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/negocios/${liquidacion}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Liquidaciones por negocio obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getTransactionsSettlement = async (liquidacion: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/transacciones/${liquidacion}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Liquidaciones por negocio obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getTransactionsSettlementsByBusiness = async (liquidacion: string, negocioId: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/negocio/transacciones/${liquidacion}/?negocioId=${negocioId}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Liquidaciones por negocio obtenidas exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  getParticipants = async (
    type: string,
    selectedDay: number,
    startDateSettlement?: string,
    endDateSettlement?: string,
  ) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/participantes/?tipo=${type}`;
      return {
        error: false,
        file: await httpInstance.download(url, { selectedDay, startDateSettlement, endDateSettlement }, true),
      };
    } catch (error) {
      console.log('Settlement service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  getParticipantsHonduras = async (type: string, selectedDay: number) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/participantes-honduras/?tipo=${type}`;
      return {
        error: false,
        file: await httpInstance.download(url, { selectedDay }, true),
      };
    } catch (error) {
      console.log('Settlement service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  updateSettlementState = async (params: any, settlementId: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/${settlementId}/edit`;

      const httpInstance = new http();

      const { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Update settlements with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  updateSettlementBusinessState = async (params: any, settlementId: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/negocio/${settlementId}/edit`;

      const httpInstance = new http();

      const { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Update settlements with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  validateSettlements = async (params: any) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/validar`;

      const httpInstance = new http();

      const { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Validate settlements with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  generatePendingSettlement = async (liquidacion: string) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/pendientes/${liquidacion}`;

      return {
        error: false,
        file: await httpInstance.download(url, {}, true),
      };
    } catch (error) {
      console.log('Settlement service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  generateRetenidasSettlement = async (id: string, rowsSeleccionados: []) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/liquidacion/${id}/procesar/retenidas`;

      return {
        error: false,
        file: await httpInstance.download(url, { rowsSeleccionados }, true),
      };
    } catch (error) {
      console.log(
        'generateRetenidasSettlement service error',
        error instanceof Error ? error.message : `Unexpected error: ${error}`,
      );
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
      };
    }
  };

  getSettlementFiles = async (liquidacion: string | number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/archivos/${liquidacion}`;

      const httpInstance = new http();

      let req = await httpInstance.get(url, true);

      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: 'Archivos de liquidaciones por negocio obtenidos exitosamente!',
        result: req.result,
      };
    } catch (error: any) {
      console.log('Get liquidaciones with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  visualizacion = async (index: number) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/visualizacion/?selectedDay=${index}`;
      let req = await httpInstance.get(url, true);
      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  visualizacionHonduras = async (index: number) => {
    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/visualizacion/honduras/?selectedDay=${index}`;
      let req = await httpInstance.get(url, true);
      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  transaccionesGenerales = async (data: {
    index: number;
    startDateFilter?: string;
    endDateFilter?: string;
    searchValue?: string;
  }) => {
    const { index, startDateFilter, endDateFilter, searchValue } = data;

    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transacciones-generales/?selectedDay=${index}&searchValue=${searchValue}`;
      let req = await httpInstance.post(url, { startDateFilter, endDateFilter }, true);
      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  transaccionesGeneralesHonduras = async (data: {
    index: number;
    startDateFilter?: string;
    endDateFilter?: string;
    searchValue?: string;
  }) => {
    const { index, startDateFilter, endDateFilter, searchValue } = data;

    try {
      const httpInstance = new http();
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transacciones-generales-honduras/?selectedDay=${index}&searchValue=${searchValue}`;
      let req = await httpInstance.post(url, { startDateFilter, endDateFilter }, true);
      if (req.error) throw new Error(req.message);

      return {
        error: req.error,
        message: req.message,
        result: req.result,
      };
    } catch (error) {
      console.log('Report service error', error instanceof Error ? error.message : `Unexpected error: ${error}`);
      return {
        error: true,
        message: error instanceof Error ? error.message : `Unexpected error: ${error}`,
        result: null,
      };
    }
  };

  updateTransactionState = async (params: any, id: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/transacciones-generales/${id}`;

      const httpInstance = new http();

      const { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Update transaction with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  updateTransactionSettlementState = async (params: any, id: number) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/update/${id}`;

      const httpInstance = new http();

      const { error, message } = await httpInstance.post(url, params, true);

      return {
        error,
        message,
      };
    } catch (error: any) {
      console.log('Update transaction with error', error);
      return {
        error: true,
        message: error.message,
        result: null,
      };
    }
  };

  reporteApp = async (businessId: any, type: any, email: string) => {
    try {
      const url = `${Rutas.instance.urlBase}${Rutas.instance.urlReportes}/reporteApp/${businessId}/${type}/?email=${email}`;

      const httpInstance = new http();

      const { error, message }: { error: boolean; message: string } = await httpInstance.get(url, true);

      return { error, message };
    } catch (error) {
      return { error: true, message: 'Error in reporteService' };
    }
  };
}
