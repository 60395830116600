import React, { useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/LoadingButton';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { GeneralContext } from '../../context/GeneralContext';
import { NegocioInterface, RoleInterface, UsuarioInterface } from '../../interfaces/Interfaces';
import NegociosServices from '../../services/NegociosServices';
import ValidationFields from '../../libs/validationFields';
import RoleServices from '../../services/RoleServices';
import { useHistory } from "react-router-dom";
import RegisterService from '../../services/RegisterService';
import { departamentos, listamunicipios } from '../../resources/Statics'
import Utilities from '../../libs/utilities'
import localStorage from '../../libs/localStorage'

export default function NegociosCreate() {
    const { handleErrorMessage, handleActiveError, handleSuccessMessage, handleActiveSuccess } = useContext(GeneralContext)

    let history = useHistory()

    const [loading, setLoading] = React.useState(false);
    const [activities, setActivities] = useState([]);
    const [subActivities, setSubActivities] = useState([]);

    const [values, setValues] = useState<NegocioInterface>({
        id: 0,
        nombre: '',
        numeroCasa: '',
        departamento: '',
        municipio: '',
        telefono: '',
        telefonoAdicional: '',
        nit: '',
        status: '',
        eliminado: '',
        afiliacionVisa: '',
        terminalVisa: '',
        userVisa: '',
        passwordVisa: '',
        ActividadId: 0,
        SubActividadId: 0,
        descuentoPaggo: 0
    });
    useEffect(() => {
        async function start() {
            let req = await RegisterService.instance.getActivities()
            let reqsub = await RegisterService.instance.getSubActivities()
            console.log(req);
            console.log(reqsub);
            setActivities(req.result)
            setSubActivities(reqsub.result)
        }
        start();
    }, [])

    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleTelefonoAdicional = (e:any) => {
        if (/^[0-9]{0,8}$/.test(e.target.value)) {
          setValues({ ...values, ['telefonoAdicional']: e.target.value || ''});
        } 
      }

    const createNegocio = async () => {
        let validation = ValidationFields.instance.newNegocio(values)
        if (validation.error) {
            handleErrorMessage(validation.message)
            handleActiveError(true)
        } else {
            if (!loading) {
                setLoading(true);
                let UsuarioAfiliadorID = await localStorage.instance.getUserId()
                NegociosServices.instance.create({ ...values }, UsuarioAfiliadorID).then(response => {
                    setLoading(false);
                    const { error, message } = response
                    if (!error) {
                        handleSuccessMessage(message)
                        handleActiveSuccess(true)
                        history.push('/admin-home/negocios')
                    } else {
                        handleErrorMessage(message)
                        handleActiveError(true)
                    }

                }).catch(e => {
                    setLoading(false);
                    handleErrorMessage(e.message)
                    handleActiveError(true)
                })
            }

        }
    }

    return (<Box mt={1} p={2} component={Paper}>
        <Grid container direction="column" spacing={2} >
            <Grid item>
                <Typography variant="h6" gutterBottom>
                    Nuevo negocio
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Creación de nuevo negocio
                </Typography>
            </Grid>
            <Grid item>
                <TextField label="Nombre" variant="outlined" fullWidth value={values.nombre} onChange={handleChange('nombre')} />
            </Grid>
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="actividad-select-label" >Actividad del negocio</InputLabel>
                    <Select
                        labelId="actividad-select-label"
                        id="negocio-select"
                        label="Actividad del negocio"
                        value={values.ActividadId}
                        onChange={handleChange('ActividadId')}>
                        <MenuItem value="">
                            <em>Seleccione</em>
                        </MenuItem>
                        {activities.map((act: any) => {
                            return <MenuItem key={act.id} value={act.id}>{act.nombre.toLocaleUpperCase()}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="sub-actividad-select-label" >Sub-Actividad del negocio</InputLabel>
                    <Select
                        labelId="sub-actividad-select-label"
                        id="negocio-select"
                        label="SubActividad del negocio"
                        value={values.SubActividadId}
                        onChange={handleChange('SubActividadId')}>
                        <MenuItem value="">
                            <em>Seleccione</em>
                        </MenuItem>
                        {subActivities.map((act: any) => {
                            if (values.ActividadId == act.ActividadId || values.ActividadId === act.ActividadId) {
                                return <MenuItem key={act.id} value={act.id}>{act.nombre.toLocaleUpperCase() + ' - ' + Utilities.instance.aproximaDecimal(act.msc * 100, 3) + '%'}</MenuItem>
                            }
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField label="Descuento a aplicar: % (se aplica al 1% de paggo)" variant="outlined" fullWidth value={values.descuentoPaggo} onChange={handleChange('descuentoPaggo')} />
            </Grid>
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="departammento-select-label" >Departamento</InputLabel>
                    <Select
                        labelId="departammento-select-label"
                        id="dep-negocio-select"
                        label="Departamento"
                        value={values.departamento}
                        onChange={handleChange('departamento')}>
                        <MenuItem value="">
                            <em>Seleccione</em>
                        </MenuItem>
                        {values &&
                        values.pais &&
                        departamentos &&
                        departamentos[values.pais] &&
                        Array.isArray(departamentos[values.pais])
                          ? departamentos[values.pais].map((dep: any) => {
                              return (
                                <MenuItem
                                  key={dep}
                                  value={dep.toLocaleLowerCase()}
                                >
                                  {dep}
                                </MenuItem>
                              );
                            })
                          : null}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="municipio-select-label" >Municipio</InputLabel>
                    <Select
                        labelId="municipio-select-label"
                        id="muni-negocio-select"
                        label="Municipio"
                        value={values.municipio}
                        onChange={handleChange('municipio')}>
                        <MenuItem value="">
                            <em>Seleccione</em>
                        </MenuItem>
                        {
                            values && values.departamento && listamunicipios && listamunicipios[values.departamento] && Array.isArray(listamunicipios[values.departamento]) ?
                                listamunicipios[values.departamento].map((municipio: any) => {
                                    return <MenuItem key={municipio} value={municipio.toLocaleLowerCase()}>{municipio}</MenuItem>
                                }) :
                                null
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <TextField label="Dirección" variant="outlined" fullWidth value={values.numeroCasa} onChange={handleChange('numeroCasa')} />
            </Grid>
            <Grid item>
                <TextField label="Teléfono" variant="outlined" fullWidth value={values.telefono} onChange={handleChange('telefono')} />
            </Grid>
            <Grid item>
                <TextField  type='text'  label="Teléfono Adicional" variant="outlined" fullWidth value={values.telefonoAdicional} onChange={(e) => handleTelefonoAdicional(e)}/>
            </Grid>
            <Grid item>
                <TextField label="NIT" variant="outlined" fullWidth value={values.nit} onChange={handleChange('nit')} />
            </Grid>
            <Grid item>
                <TextField label="Afiliación Visa" variant="outlined" fullWidth value={values.afiliacionVisa} onChange={handleChange('afiliacionVisa')} />
            </Grid>
            <Grid item>
                <TextField label="Terminal Visa" variant="outlined" fullWidth value={values.terminalVisa} onChange={handleChange('terminalVisa')} />
            </Grid>
            <Grid item>
                <TextField label="User Visa" variant="outlined" fullWidth value={values.userVisa} onChange={handleChange('userVisa')} />
            </Grid>
            <Grid item>
                <TextField label="Password Visa" variant="outlined" fullWidth value={values.passwordVisa} onChange={handleChange('passwordVisa')} />
            </Grid>
            <Grid item>
                <LoadingButton loading={loading} color="primary" variant="contained" title='Crear negocio' onClick={createNegocio} />
            </Grid>
        </Grid>
    </Box>)
}
