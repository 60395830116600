import http from '../libs/http';
import { Rutas } from '../resources/Statics';

export default class PaisesServices {
  static instance = new PaisesServices();

  get = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlPais;

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Paises service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  getParaPanel = async () => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlPais + '/panel';

      const httpInstance = new http();

      let req = await httpInstance.get(ruta, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: '', result: req.result };
    } catch (error: any) {
      console.log('get Paises service error', error);
      return { error: true, message: error.message, result: null };
    }
  };
}