import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralContext } from '../context/GeneralContext'

function StepUpComponent() {

  const params = useParams<{ jwtValue: string }>()

  const { deviceDataCollectionUrl } = useContext(GeneralContext)

  useEffect(() => {

    console.log('deviceDataCollectionUrl', deviceDataCollectionUrl)

    const ejecutar = async () => {
      const stepUpForm = document.querySelector('#step_up_form') as any;

      if (stepUpForm) {
        stepUpForm.submit();
      }
    }

    ejecutar();

  }, []);

  return (
    <div>
      <iframe
        id="step_up_iframe"
        style={{ border: 'none', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
        height="800"
        width="100%"
        name="stepUpIframe"
      />
      <form
        id="step_up_form"
        name="stepup"
        method="POST"
        target="stepUpIframe"
        action={deviceDataCollectionUrl}
      >
        <input
          id="step_up_form_jwt_input"
          type="hidden"
          name="JWT"
          value={params.jwtValue}
        />
      </form>
    </div>
  );
}

export default StepUpComponent;
