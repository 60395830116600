import { useEffect, useState } from 'react';
import { guatemalaBussinesTypes } from '../../../resources/Statics';
import Utilities from '../../../libs/utilities';
import cardText from '../utils';
import localStorage from '../../../libs/localStorage'
//import { LocaleStorage } from '../../../../../utilities/localeStorage';
import NegociosServices from '../../../services/NegociosServices';

/**
 * La función `useAjustesCardProps` devuelve un objeto con propiedades que determinan el texto, la
 * altura, la visibilidad de los botones y el texto del botón para una tarjeta de cuenta según el
 * estado de los documentos comerciales del usuario y la verificación de la cuenta.
 * @returns La función `useAjustesCardProps` devuelve un objeto con las siguientes propiedades:
 */
const useAjustesCardProps = () => {
    
  const [negocioStatusFlags, setNegocioStatusFlags] = useState<any>(null);
  const [moneda, setMoneda] = useState<string>('Q');
  const [ngId, setNgId] = useState<number>(0);
  const [state, setState] = useState({
    showCloseButton: false,
    hideButton: false,
    outputTitle: '',
    loadingHook: true,
    text: '',
  });

  const setLoadinHook = (loadingHook: boolean) => {
    setState({ ...state, loadingHook });
  }

  useEffect(() => {

    const getDataNegocio = async () => {
      let user = await localStorage.instance.getUserParseado();
      if (user && user.rolUsuario && user.rolUsuario[0] && user.rolUsuario[0].NegocioId && user.rolUsuario[0].UsuarioId) {
        const NegocioId = user.rolUsuario[0].NegocioId;
        setNgId(NegocioId);
        const UsuarioId = user.rolUsuario[0].UsuarioId;
        NegociosServices.instance
        .getNegocioStatus(NegocioId, UsuarioId)
        .then(response => {
          const { error, result, message } = response;
          if (!error && result.tipoNegocio && result.tipoNegocio.pais && result.tipoNegocio.pais.moneda) {
            setNegocioStatusFlags(result);
            setMoneda(result.tipoNegocio.pais.moneda);
          };
          
        })
        .catch(e => {
          console.log(e);
        });
      }
    };

    getDataNegocio()
  }, []);


  useEffect(() => {
    const getData = async () => {
      if (!negocioStatusFlags) {
        return;
      }

      

      // OBTENER DOCUMENTOS OBLIGATORIOS
      const tieneDocumentosObligatorios =
        (negocioStatusFlags?.documents?.filter(
          (doc: any) => doc.quitarLimite === false && doc.subirloNegocio,
        )?.length ?? 0) > 0;

      // VALIDAR SI TIENE DOCUMENTOS OPCIONALES PENDIENTES
      const tieneDocumentosOpcionalesPendientes: boolean =
        negocioStatusFlags?.documents?.some(
          (document: any) =>
            document.quitarLimite === true && document.status !== 'aprobado',
        ) ?? false;

      // VALIDAR SI SE TIENE PENDIENTES
      const negocioTienePendientes =
        !negocioStatusFlags?.metamapVerified ||
        !negocioStatusFlags?.uploadedDocuments ||
        !negocioStatusFlags?.approvedDocuments ||
        !negocioStatusFlags?.signedContract ||
        !negocioStatusFlags.metamapStarted ||
        tieneDocumentosOpcionalesPendientes;

      let outputText = '';
      let outputTitle = '';

      // VALIDACIONES LIMITES
      const isNoFormal =
        negocioStatusFlags?.tipoNegocio?.nombre ===
          guatemalaBussinesTypes.no_formalizado ||
        negocioStatusFlags?.tipoNegocio?.nombre ===
          guatemalaBussinesTypes.no_formalizado_plus;

      // VALIDACIONES METAMAP
      if (!negocioStatusFlags?.metamapStarted) {
        outputText = cardText.body.metamapNotStarted;
        outputTitle = cardText.title.metamapNotStarted;
      } else if (!negocioStatusFlags?.metamapVerified) {
        outputText = cardText.body.metamapInProcess;
        outputTitle = cardText.title.metamapInProcess;
      }

      // VALIDACIONES DOCUMENTOS
      else if (!negocioStatusFlags?.uploadedDocuments) {
        outputText = tieneDocumentosObligatorios
          ? cardText.body.pendingDocuments
          : cardText.body.allButPending;
        outputTitle = tieneDocumentosObligatorios
          ? cardText.title.pendingDocuments
          : cardText.title.allButPending;
      } else if (!negocioStatusFlags?.approvedDocuments) {
        outputText = cardText.body.documentsNotApproved;
        outputTitle = cardText.title.documentsNotApproved;
      } else if (!negocioStatusFlags.signedContract) {
        outputText = cardText.body.allButPending;
        outputTitle = cardText.title.allButPending;
      } else if (tieneDocumentosOpcionalesPendientes) {
        if (ngId < 6502) {
          if (isNoFormal) {
            outputText = cardText.body.limitedNoFormalLessThan6502;
            outputTitle = cardText.title.limitedNoFormalLessThan6502;
          } else {
            outputText = cardText.body.limitedLessThan6502;
            outputTitle = cardText.title.limitedLessThan6502;
          }
        } else {
          if (isNoFormal) {
            outputText = cardText.body.limitedNoFormal;
            outputTitle = cardText.title.limitedNoFormal;
          } else {
            outputText = cardText.body.limited;
            outputTitle = cardText.title.limited;
          }
        }
      } else {
        if (ngId < 6502) {
          if (isNoFormal) {
            outputText = cardText.body.limitlessLessThan6502;
            outputTitle = cardText.title.limitlessLessThan6502;
          } else {
            outputText = cardText.body.limitlessLessThan6502;
            outputTitle = cardText.title.limitlessLessThan6502;
          }
        } else {
          if (isNoFormal) {
            outputText = cardText.body.limitlessNoFormal;
            outputTitle = cardText.title.limitlessNoFormal;
          } else {
            outputText = cardText.body.limitless;
            outputTitle = cardText.title.limitless;
          }
        }
      }

      outputText = outputText
        .replace(/\{moneda\}/g, moneda)
        .replace(
          /\{limiteLink\}/g,
          Utilities.instance.formatCurrency(negocioStatusFlags?.limiteLink ?? 0) ?? '0.00',
        )
        .replace(
          /\{limite\}/g,
          Utilities.instance.formatCurrency(negocioStatusFlags?.limite ?? 0) ?? '0.00',
        );

      const hideButton = !negocioTienePendientes;
      const showCloseButton = tieneDocumentosOpcionalesPendientes
        ? false
        : !negocioTienePendientes;

      setState({
        text: outputText,
        showCloseButton,
        loadingHook: false,
        outputTitle,
        hideButton,
      });
    };
    if (negocioStatusFlags && moneda)
      getData();
  }, [negocioStatusFlags, moneda]);


  return {...state, negocioStatusFlags, moneda, ngId, setLoadinHook};
};

/**
 * La función `useHideModal` es un gancho React personalizado que recupera un valor del almacenamiento
 * local y actualiza el estado de un componente modal.
 * @param setHideModal - setHideModal es una función que se utiliza para actualizar la variable de
 * estado que controla la visibilidad de un modal. Es de tipo
 * React.Dispatch<React.SetStateAction<boolean>>.
 */
// export const useHideModal = (
//   setHideModal: React.Dispatch<React.SetStateAction<boolean>>,
// ) => {
//   useEffect(() => {
//     const getFromStorage = async () => {
//       // MOSTRAR MODAL DE LIMITE
//       let hideLimitModal = await LocaleStorage.instance.getHideLimitModal();
//       return hideLimitModal;
//     };

//     getFromStorage().then(setHideModal);
//   }, [setHideModal]);
// };

export default useAjustesCardProps;
