/**
 * External Libraries
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useState, useContext } from 'react';
import * as momentZone from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
/**
 * Context
 */
import { GeneralContext } from '../../context/GeneralContext';
/**
 * Components
 */
import LoadingButton from '../../components/LoadingButton';
import TransactionsReport from '../../components/TransactionsReport';
/**
 * Services
 */
import ReportesServices from '../../services/ReportesService';

export default function Transacciones() {
  const { handleErrorMessage, handleActiveError } = useContext(GeneralContext);

  const [loading, setLoading] = useState(false);

  const getReporte = (fechaInicio: string, fechaFin: string) => {
    let params = {
      fechaInicio,
      fechaFin,
    };

    if (fechaInicio.length > 0 && fechaFin.length > 0 && !loading) {
      setLoading(true);
      ReportesServices.instance
        .transacciones(params)
        .then(response => {
          setLoading(false);
          try {
            if (response.file?.blob instanceof Blob) {
              const blob = new Blob([response.file?.blob as BlobPart], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `reporte.xlsx`);
              document.body.appendChild(link);
              link.click();
            } else {
              handleErrorMessage(response.message ? response.message : 'Error al descargar el reporte');
              handleActiveError(true);
            }
          } catch (error: any) {
            handleErrorMessage(error.message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    } else {
      handleErrorMessage('Debes completar fecha fin e inicio para exportar el reporte.');
      handleActiveError(true);
    }
  };

  const getReporteTarjeta = (fechaInicio: string, fechaFin: string) => {
    let params = {
      fechaInicio,
      fechaFin,
    };

    if (fechaInicio.length > 0 && fechaFin.length > 0 && !loading) {
      setLoading(true);
      ReportesServices.instance
        .transaccionesTarjeta(params)
        .then(response => {
          setLoading(false);
          try {
            if (response.file?.blob instanceof Blob) {
              const blob = new Blob([response.file?.blob as BlobPart], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `reporte.xlsx`);
              document.body.appendChild(link);
              link.click();
            } else {
              handleErrorMessage(response.message ? response.message : 'Error al descargar el reporte');
              handleActiveError(true);
            }
          } catch (error: any) {
            handleErrorMessage(error.message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    } else {
      handleErrorMessage('Debes completar fecha fin e inicio para exportar el reporte.');
      handleActiveError(true);
    }
  };

  const getReporteTarjetaPayFac = (fechaInicio: string, fechaFin: string) => {
    let params = {
      fechaInicio,
      fechaFin,
    };

    if (fechaInicio.length > 0 && fechaFin.length > 0 && !loading) {
      setLoading(true);
      ReportesServices.instance
        .transaccionesTarjetaPayfac(params)
        .then(response => {
          setLoading(false);
          try {
            if (response.file?.blob instanceof Blob) {
              const blob = new Blob([response.file?.blob as BlobPart], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `reporte.xlsx`);
              document.body.appendChild(link);
              link.click();
            } else {
              handleErrorMessage(response.message ? response.message : 'Error al descargar el reporte');
              handleActiveError(true);
            }
          } catch (error: any) {
            handleErrorMessage(error.message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    } else {
      handleErrorMessage('Debes completar fecha fin e inicio para exportar el reporte.');
      handleActiveError(true);
    }
  };

  const reporteTransaccionesLinkPayfac = (fechaInicio: string, fechaFin: string) => {
    let params = {
      fechaInicio,
      fechaFin,
    };

    if (fechaInicio.length > 0 && fechaFin.length > 0 && !loading) {
      setLoading(true);
      ReportesServices.instance
        .reporteTransaccionesLinkPayfac(params)
        .then(response => {
          setLoading(false);
          try {
            if (response.file?.blob instanceof Blob) {
              const blob = new Blob([response.file?.blob as BlobPart], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `reporte.xlsx`);
              document.body.appendChild(link);
              link.click();
            } else {
              handleErrorMessage(response.message ? response.message : 'Error al descargar el reporte');
              handleActiveError(true);
            }
          } catch (error: any) {
            handleErrorMessage(error.message);
            handleActiveError(true);
          }
        })
        .catch(e => {
          setLoading(false);
          handleErrorMessage(e.message);
          handleActiveError(true);
        });
    } else {
      handleErrorMessage('Debes completar fecha fin e inicio para exportar el reporte.');
      handleActiveError(true);
    }
  };

  return (
    <>
    <TransactionsReport getReport={getReporte} titleReport='Reporte de transacciones por link'/>
    <TransactionsReport getReport={getReporteTarjeta} titleReport='Reporte de transacciones mpos'/>
    <TransactionsReport getReport={reporteTransaccionesLinkPayfac} titleReport='Reporte de transacciones por link PayFac'/>
    <TransactionsReport getReport={getReporteTarjetaPayFac} titleReport='Reporte de transacciones mpos PayFac'/>
    </>
  );
}
