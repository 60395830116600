import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ValidationFields from '../libs/validationFields'
import { primary } from '../styles/colors'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link'
import { Images } from '../styles/images'

import { GeneralContext } from '../context/GeneralContext'

import LoginService from '../services/LoginService';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        paddingTop: '10%',
        paddingBottom: '10%'
    },
    buttonIniciar: {
        marginRight: theme.spacing(1),
        backgroundColor: primary,
        color: 'white',
        paddingTop: '4%',
        paddingBottom: '4%',
        display: 'block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    container: {
        padding: '5%',
        paddingTop: '1%'
    },
    buttonNext: {
        paddingTop: '5%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    stepTittle: {
        fontWeight: 550
    },
    inputPadding: {
        paddingTop: '1%'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    rootResponsive: {
        width: '100%',
    },
    buttonResponsive: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    resInputsPadding: {
        paddingTop: '2%'
    }

}));


export default function ForgotPassword() {
    const classes = useStyles();
    const { handleErrorMessage, handleActiveError, handleLoading } = useContext(GeneralContext)

    const [values, setValues] = useState({
        email: '',
        codigoActivacion: '',
        password: '',
        password2: ''
    });
    const [step, setStep] = useState(1);
    const [ochoCaracteresMinimos, setOchoCaracteresMinimos] = useState(false);
    const [mayusculas, setMayusculas] = useState(false);
    const [minusculas, setMinusculas] = useState(false);
    const [numeros, setNumeros] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [passwordMatch, setPasswordMatch] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })

    const handleNext = async () => {

        //setStep(step + 1)

        handleLoading(true)

        if (step === 1) {

            let req = await LoginService.instance.forgotPasswordStep1(values.email)
            if (!req.error) {
                setStep(step + 1)
            } else {
                handleErrorMessage(req.message)
                handleActiveError(true)
            }

        }
        else if (step === 2) {

            let req = await LoginService.instance.forgotPasswordStep2(values.email, values.codigoActivacion)
            if (!req.error) {
                setStep(step + 1)
            } else {
                handleErrorMessage(req.message)
                handleActiveError(true)
            }

        }
        else if (step === 3) {

            if (!ValidationFields.instance.password(values) || !ValidationFields.instance.equalsPasswords(values.password, values.password2)) {
                handleClickAlert()
                return
            };

            let req = await LoginService.instance.forgotPasswordStep3(values.email, values.password)
            if (!req.error) {
                setStep(step + 1)
            } else {
                handleErrorMessage(req.message)
                handleActiveError(true)
            }

        }

        handleLoading(false)

    }

    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleChangePassword = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
        if (event.target.value.length >= 8) setOchoCaracteresMinimos(true)
        else if (event.target.value.length < 8) setOchoCaracteresMinimos(false)
        if (ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(true)
        else if (!ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(false)
        if (ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(true)
        else if (!ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(false)
        if (ValidationFields.instance.existNumbers(event.target.value)) setNumeros(true)
        else if (!ValidationFields.instance.existNumbers(event.target.value)) setNumeros(false)
        if (!ValidationFields.instance.equalsPasswords(event.target.value, values.password2).error) setPasswordMatch(true)
        else setPasswordMatch(false)

    };

    const handleChangePassword2 = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
        if (!ValidationFields.instance.equalsPasswords(event.target.value, values.password).error) setPasswordMatch(true)
        else setPasswordMatch(false)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseDialogLogin = () => {
        setOpenDialog(false);
        window.location.href = '/login'
    };

    const reSendMail = async () => {
        setLoading(true)
        let res = await LoginService.instance.forgotPasswordStep1(values.email)
        if (!res) {
            handleClickAlert()
        }
        setLoading(false)
    }

    const reSendMailButton = () => {
        return (
            <Button
                color="primary"
                disabled={loading}
                variant='text'
                className={classes.button}
                onClick={reSendMail}
                endIcon={<RefreshIcon />}
            >Reenviar Código
            </Button>
        )
    }


    const correoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Correo</InputLabel>
                <OutlinedInput
                    type='email'
                    value={values.email}
                    onChange={handleChange('email')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const passwordInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Contraseña</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChangePassword('password')}
                        className={classes.inputPadding}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { setShowPassword(!showPassword) }}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                </FormControl>
                <div className={classes.inputPadding}>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel variant='filled'>Contraseña</InputLabel>
                        <OutlinedInput
                            type={showPassword2 ? 'text' : 'password'}
                            value={values.password2}
                            onChange={handleChangePassword2('password2')}
                            className={classes.inputPadding}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => { setShowPassword2(!showPassword2) }}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                    </FormControl>
                </div>
                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: ochoCaracteresMinimos ? '#009688' : '#766865' }}>8 caracateres</FormHelperText>
                    <FormHelperText style={{ color: minusculas ? '#009688' : '#766865' }}>Letra minusculas</FormHelperText>
                    <FormHelperText style={{ color: mayusculas ? '#009688' : '#766865' }}>Letra mayusculas</FormHelperText>
                    <FormHelperText style={{ color: numeros ? '#009688' : '#766865' }}>Numero</FormHelperText>
                    <FormHelperText style={{ color: passwordMatch ? '#009688' : '#766865' }}>Coinciden contraseñas</FormHelperText>
                </div>
            </>
        )
    }

    const codigoActivacionInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Codigo de activación</InputLabel>
                    <OutlinedInput
                        type='text'
                        value={values.codigoActivacion}
                        onChange={handleChange('codigoActivacion')}
                        className={classes.inputPadding}
                    />
                </FormControl>
                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: '#766865' }}>Enviamos un codigo a tu correo{' ' + values.email}</FormHelperText>
                </div>
            </>
        )
    }



    const responsiveButtons = () => {
        return (
            <div>
                {
                    step === 2 ?
                        <Button
                            color="primary"
                            disabled={loading}
                            variant='text'
                            className={classes.buttonResponsive}
                            onClick={reSendMail}
                            endIcon={<RefreshIcon />}
                        >Reenviar Código
                        </Button>
                        :
                        null
                }
                <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.buttonResponsive}
                >
                    {step === 3 ? 'FINALIZAR' : 'SIGUIENTE'}
                </Button>
            </div>
        )
    }

    return (
        <>
            {width > 800 ?

                <div className={classes.root}>

                    <div>
                        {loading ? <LinearProgress /> : null}
                    </div>

                    <div className={classes.root}>
                        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error">
                                {window.localStorage.getItem('erroractual')}
                            </Alert>
                        </Snackbar>
                    </div>


                    <div>

                        <Dialog
                            fullScreen={fullScreen}
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {window.localStorage.getItem('erroractual')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleCloseDialog} color="primary">
                                    Intentar con otro correo
                                </Button>
                                <Button onClick={handleCloseDialogLogin} color="primary" autoFocus>
                                    Recuperar mi constraseña
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>



                    <div className={classes.container}>

                        <img width={(width * 0.15)} src={Images.instance.logo} alt="" onClick={() => window.location.href = '/'} />

                        <Stepper activeStep={step - 1}>
                            <Step >
                                <StepLabel  >Ingresa tu correo</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Valida tu correo</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Nueva contraseña</StepLabel>
                            </Step>
                        </Stepper>

                        <Grid container spacing={3}>
                            <Grid item xs={7}>
                                <div>
                                    <img width={(width * 0.50)} src={Images.instance.image1} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ paddingTop: '10%' }}>
                                    {
                                        step === 1 ?
                                            <Grid container spacing={3}>

                                                <Grid item xs={12}>
                                                    <Typography className={classes.stepTittle} variant="h5">
                                                        Ingresa tu correo
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {correoInput()}
                                                </Grid>

                                            </Grid>
                                            :
                                            step === 2 ?

                                                <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <Typography className={classes.stepTittle} variant="h5">
                                                            Valida tu correo
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {codigoActivacionInput()}
                                                    </Grid>
                                                </Grid>

                                                :
                                                step === 3 ?

                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <Typography className={classes.stepTittle} variant="h5">
                                                                Nueva contraseña
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {passwordInput()}
                                                        </Grid>

                                                    </Grid>

                                                    :

                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <Typography style={{ color: 'gray' }}>
                                                                {'Ahora puede inciar secion con tu nueva contraseña, dirigete al  '}
                                                                <Link style={{ color: '#1565D8' }} href="/" >
                                                                    {' Login'}
                                                                </Link>
                                                            </Typography>

                                                        </Grid>
                                                    </Grid>
                                    }
                                </div>




                                {
                                    <div style={{ paddingTop: '8%' }}>

                                        <Grid container spacing={3}>

                                            <Grid item xs={4}>
                                                {
                                                    step === 2 ?
                                                        reSendMailButton()
                                                        : null
                                                }
                                            </Grid>

                                            <Grid item xs={4}>
                                            </Grid>

                                            <Grid item xs={4}>
                                                {
                                                    step !== 4 ? <Button color="primary" disabled={loading} variant="contained" className={classes.button} onClick={handleNext}>{step === 3 ? 'Finalizar' : 'Siguiente'}</Button>
                                                        : null
                                                }
                                            </Grid>

                                        </Grid>

                                    </div>
                                }







                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>


                    </div>
                </div >
                :

                <div className={classes.rootResponsive}>

                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={9}>
                            <div style={{ paddingTop: '5%' }}>
                                <img width={(width * 0.50)} src={Images.instance.logo} alt="" />
                            </div>
                        </Grid>
                    </Grid>

                    <div>
                        {loading ? <LinearProgress /> : null}
                    </div>

                    <div className={classes.root}>
                        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error">
                                {window.localStorage.getItem('erroractual')}
                            </Alert>
                        </Snackbar>
                    </div>


                    <div>

                        <Dialog
                            fullScreen={fullScreen}
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {window.localStorage.getItem('erroractual')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleCloseDialog} color="primary">
                                    Intentar con otro correo
                                </Button>
                                <Button onClick={handleCloseDialogLogin} color="primary" autoFocus>
                                    Recuperar mi constraseña
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>

                    <Stepper activeStep={(step - 1)} orientation="vertical">


                        <Step key={1}>
                            <StepLabel>Ingresa tu correo</StepLabel>
                            <StepContent>
                                <div >{correoInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={2}>
                            <StepLabel>Valida tu correo</StepLabel>
                            <StepContent>
                                <div>{codigoActivacionInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={3}>
                            <StepLabel>Cuentanos de ti</StepLabel>
                            <StepContent>
                                <div>{passwordInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                    {(step - 1) === 3 && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            <Typography>Ahora puede inciar secion con tu nueva contraseña!</Typography>
                        </Paper>
                    )}
                </div>


            }

        </>
    );
}