import * as momentZone from 'moment-timezone';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Fade, FormHelperText, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, Modal, NativeSelect, OutlinedInput, Popover, Radio, TextField, TextFieldProps, TextareaAutosize, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { DataGrid, GridRowParams, GridToolbar } from '@material-ui/data-grid';
import { Edit, FindInPageTwoTone, Label, OpenInBrowser, Delete } from '@material-ui/icons';
import AttachFileTwoTone from '@material-ui/icons/AttachFileTwoTone';
import { Link, useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import CloudDownload from '@material-ui/icons/CloudDownload';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ContracargosService from '../../services/ContracargosService';
import AbonoContracargoService from '../../services/AbonoContracargoService';
import PaisesServices from '../../services/PaisesService';
import ValidationFields from '../../libs/validationFields';
import VerificationService from '../../services/VerificationService';
import { GeneralContext } from '../../context/GeneralContext';
import { ListadoMotivos, NegocioInterface, UserInterface } from '../../interfaces/Interfaces';
import LoadingButton from '../../components/LoadingButton';
import { DropzoneDialog } from 'material-ui-dropzone';
import DocumentosService from '../../services/DocumentosService';
import HistoryIcon from '@material-ui/icons/History';
import RazonesService from '../../services/RazonesService';
import Backdrop from '@material-ui/core/Backdrop';
import { Rutas, categoriasContracargos, estadosContracargos } from '../../resources/Statics'
import DocumentosContracargosService from '../../services/DocumentosContracargosService';
import PageLoader from '../../components/PageLoader';
import ModalSeguimiento from '../../components/ModalSeguimientoContracargo';
import ModalConfirm from '../../components/ModalConfirm';
import CrudService from '../../services/CrudService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
    },
    imageList: {
      width: 500,
      height: 450,
    },
    modalLiquidacion: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50vw',
      height: 300,
    },
    button: {
      margin: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageListAbono: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }),
);



export default function Negocios() {
  const {
    selectedRecord,
    handleErrorMessage,
    handleActiveError,
    handleSelectRecord,
    handleSuccessMessage,
    handleActiveSuccess,
    handleLoading
  } = useContext(GeneralContext);
  let history = useHistory();

  const columnsHistorialCredenciales = [

    {
      field: 'origen',
      headerName: 'Categoria asignada',
      sortable: false,
      width: 220,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.valor}</span>;
      },
    },
    {
      field: 'descripcion',
      headerName: 'Razón',
      width: 230,

    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },

    {
      field: 'documentos',
      headerName: 'Documentos',
      width: 730,
      renderCell: (params: any) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const openC = Boolean(anchorEl);
        const idC = open ? 'simple-popover' : undefined;
        const handleClick = (event: any) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };
        if (params.row.documentos.length > 0) {
          return <div>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Ver links
            </Button>
            <Popover
              id={idC}
              open={openC}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ul>
                {params.row.documentos.map((documento: any, index: number) => (
                  <li key={index}>
                    <a href={documento.link} target="_blank" rel="noopener noreferrer">
                      {documento.link}
                    </a>
                  </li>
                ))}
              </ul>
            </Popover>
          </div>
        }
      },
    },


  ];

  const columnsHistorialDocumentos = [

    {
      field: 'acciones',
      headerName: 'Acciones',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center'
            style={{ cursor: 'pointer' }}
          >
            <IconButton
              size='small'
              title='Ver documento'
              onClick={() => openFiles([params.row.link])}
            >
              <FindInPageTwoTone />
            </IconButton>

            <IconButton
              size='small'
              title='Eliminar documento'
              onClick={() => {
                setDocumentoId(params.row.id)
                setOpenDeleteModal(true)
              }
              }
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'extension',
      headerName: 'Tipo documento',
      width: 200,
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <span>{params.row.usuario.nombre}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha subida',
      width: 180,
      renderCell: (params: any) => {
        const fechaTZ6 = momentZone
          .tz(params.getValue(params.id, 'createdAt'), 'America/Guatemala')
          .locale('es')
          .format('YYYY-MM-DD HH:mm A');
        return <span>{fechaTZ6}</span>;
      },
    },


  ];

  const tiposAbono = ['cheque', 'efectivo', 'transferencia']


  const [columnsHistorial, setColumnsHistorial] = useState(columnsHistorialCredenciales);
  const [columnsDocumentos, setColumnsDocumentos] = useState(columnsHistorialDocumentos);
  const startDateFilterRef = useRef<TextFieldProps>(null);

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [openCreateContracargoModel, setOpenCreateContracargoModel] = React.useState<boolean>(false);
  const [openAbonoModal, setOpenAbonoModal] = React.useState<boolean>(false);
  const [valor, setValor] = React.useState<string>('')
  const [tipoTransaccion, setTipoTransaccion] = React.useState<string>('')
  const [tipoAbono, setTipoAbono] = React.useState<string>('')
  const [monto, setMonto] = React.useState<string>('')
  const [comentarioAbono, setComentarioAbono] = React.useState<string>('')
  const [fechaEmisionVisa, setFechaEmisionVisa] = useState<string | undefined>(undefined);
  const [transasactionId, setTransasactionId] = useState<string>('')
  const [contracargoId, setContracargoId] = useState<string>('')
  const [abonoContracargoId, setAbonoContracargoId] = useState<string>('');
  const [negocioId, setNegocioId] = useState<string>('')
  const [openDialogDeleteAbono, setOpenDialogDeleteAbono] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [categoria, setCategoria] = React.useState<string>('')
  const [estadofinal, setEstadoFinal] = React.useState<string>('')
  const [razonCategoria, setRazonCategoria] = useState('');
  const [linksDocumentosRazones, setLinksDocumentosRazones] = useState([]);
  const [openUploadFile, setOpenUploadFile] = React.useState(false);
  const [openModalUpdateRazon, setOpenModalUpdateRazon] = useState(false);
  const [openModalAdjuntos, setOpenModalAdjuntos] = useState(false);
  const [rowsHistorialCredenciales, setRowsHistorialCredenciales] = useState([]);
  const [openHistorialRazones, setOpenHistorialRazones] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pais, setPais] = useState(1);
  const [buscarPor, setBuscarPor] = useState('authorizationNumber')
  const [openHistorialDocumentosContracargos, setOpenHistorialDocumentosContracargos] = useState(false);
  const [rowsHistorialDocumentos, setRowsHistorialDocumentos] = useState([]);
  const [arrayImages, setArrayImages] = React.useState<any[]>([]);
  const [openImagesModal, setOpenImagesModal] = React.useState(false);
  const [openModalSeguimiento, setOpenModalSeguimiento] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [documentoId, setDocumentoId] = useState('');
  const [linksComprobantes, setLinksComprobantes] = useState<string[]>([]);
  const [isComprobanteOtroMedios, setIsComprobanteOtroMedios] = useState(false);
  const [isOpenComprobantes, setIsOpenComprobantes] = useState(false);
  const [abonoEliminar, setAbonoEliminar] = useState<any>(null);
  const [subiendoArchivosAbonos, setSubiendoArchivosAbonos] = useState(false);
  const [contracargoIdParaCrearAbono, setContracargoIdParaCrearAbono] = useState<string>('')

  useEffect(() => {
    getContracargos();
    getCantidadContracargos()
  }, []);

  useEffect(() => {
    getContracargos();
  }, [page, pageSize]);

  useEffect(() => {
    handlePageSize();
  }, [searchValue]);

  const handlePageSize = () => {
    if (searchValue && searchValue != '') {
      setTotalPages(1)
      setPage(1)
    }
    else getCantidadContracargos()
  }

  const handleCloseDelete = () => {
    setContracargoId('');
    setOpenDeleteModal(false);
  }

  const handleConfirmDelete = async () => {
    if (documentoId) {
      handleLoading(true);
      const crudService = CrudService.instance(Rutas.instance.urlDocumentosContracargo);
      crudService
        .deleteOne(Number(documentoId))
        .then(async response => {
          const { error, result } = response;
          if (!error) {
            verDocumentosAdjuntosContracargos({ stopPropagation: () => { }, }, contracargoId);
          }
        })
        .catch(e => {
          handleErrorMessage('message');
          handleActiveError(true);
        });
      setOpenDeleteModal(false);
    }
  }

  const getContracargos = async () => {
    setLoading(true);
    const result = await ContracargosService.instance.getContracargosPaginados({ offset: page, limit: pageSize });
    setRows(result.result);
    setLoading(false);
  };

  const openFiles = (link: string[]) => {

    try {
      if (link) {
        try {

          if (Array.isArray(link)) {
            setArrayImages(link);
            setOpenImagesModal(true);
          } else {
            setArrayImages([link]);
            setOpenImagesModal(true);
          }
        } catch (error) {
          setArrayImages([link]);
          setOpenImagesModal(true);
        }
      } else {

        setArrayImages([]);
        setOpenImagesModal(true);
      }
    } catch (error) {
      console.log('ERROR AQUI', error);
    }
  };

  const getCantidadContracargos = async () => {
    setLoading(true);
    const result = await ContracargosService.instance.getCantidadContracargos();
    setTotalPages(Math.ceil(result.result / pageSize));
    setLoading(false);
  };

  const crearContracargo = async () => {
    handleLoading(true);
    const result = await ContracargosService.instance.createContracargo(
      {
        buscarPor: buscarPor,
        valor: valor,
        tipoPago: tipoTransaccion,
        fechaEmisionVisa: fechaEmisionVisa,
        categoria,
        documentos: linksDocumentosRazones,
        razon: razonCategoria,
        pais: pais
      });
    if (result.error) {
      handleErrorMessage(result.message);
      handleActiveError(true);
    } else {
      setValor('');
      setTipoTransaccion('');
      setFechaEmisionVisa(undefined);
      handleSuccessMessage(result.message);
      setOpenCreateContracargoModel(false);
      handleActiveSuccess(true);
    }
    setCategoria('')
    setEstadoFinal('')
    setRazonCategoria('')
    setContracargoId('')
    setLinksDocumentosRazones([])
    getContracargos();
    getCantidadContracargos()
    handleLoading(false);
  }

  const updateContracargo = async () => {
    handleLoading(true);
    const result = await ContracargosService.instance.updateContracargo(Number(contracargoId),
      { categoria, razon: razonCategoria, documentos: linksDocumentosRazones });
    if (result.error) {
      handleErrorMessage(result.message);
      handleActiveError(true);
    } else {
      setValor('');
      setTipoTransaccion('');
      setCategoria('')
      setEstadoFinal('')
      setRazonCategoria('')
      setContracargoId('')
      setLinksDocumentosRazones([])
      setFechaEmisionVisa(undefined);
      handleSuccessMessage(result.message);
      setOpenModalUpdateRazon(false);
      handleActiveSuccess(true);
    }
    getContracargos();
    getCantidadContracargos()
    handleLoading(false);
  }

  const updateContracargoEstadoFinal = async () => {
    handleLoading(true);
    const result = await ContracargosService.instance.updateContracargoEstadoFinal(Number(contracargoId),
      { estadofinal, razon: razonCategoria, documentos: linksDocumentosRazones });
    if (result.error) {
      handleErrorMessage(result.message);
      handleActiveError(true);
    } else {
      setValor('');
      setTipoTransaccion('');
      setCategoria('')
      setEstadoFinal('')
      setRazonCategoria('')
      setContracargoId('')
      setLinksDocumentosRazones([])
      setFechaEmisionVisa(undefined);
      handleSuccessMessage(result.message);
      setOpenModalUpdateRazon(false);
      handleActiveSuccess(true);
    }

    getContracargos();
    getCantidadContracargos()
    handleLoading(false);
  }

  const createAbonoContracargo = async () => {

    handleLoading(true);
    if (tiposAbono.includes(tipoAbono)) {

      if (contracargoIdParaCrearAbono == '') {
        handleErrorMessage('No se ha seleccionado un contracargo');
        handleActiveError(true);
        handleLoading(false);
        return;
      } else if (monto == '') {
        handleErrorMessage('El monto es requerido');
        handleActiveError(true);
        handleLoading(false);
        return;
      } else if (comentarioAbono == '') {
        handleErrorMessage('El comentario es requerido');
        handleActiveError(true);
        handleLoading(false);
        return;
      } else if (linksComprobantes.length == 0) {
        handleErrorMessage('El comprobante es requerido');
        handleActiveError(true);
        handleLoading(false);
        return;
      }

      const linksStringify = JSON.stringify(linksComprobantes);

      const result = await AbonoContracargoService.instance.createAbonoContracargoOtrosMedios({
        ContracargoId: contracargoIdParaCrearAbono,
        tipoAbono: tipoAbono,
        monto: Number(monto),
        comentarios: comentarioAbono,
        comprobante: linksStringify
      })
      if (result.error) {
        handleErrorMessage(result.message);
        handleActiveError(true);

        setTipoAbono('');
        setMonto('');
        setComentarioAbono('');
        setLinksComprobantes([]);
      } else {
        console.log(result)
        setTipoAbono('');
        setMonto('');
        setComentarioAbono('');
        setLinksComprobantes([]);
        setFechaEmisionVisa(undefined);
        handleSuccessMessage(result.message);
        setOpenCreateContracargoModel(false);
        handleActiveSuccess(true);
      }
      setOpenAbonoModal(false);
      getContracargos();
      getCantidadContracargos()
      handleLoading(false);
    } else {
      const result = await AbonoContracargoService.instance.createAbonoContracargo({ transactionId: transasactionId, tipoPago: tipoTransaccion, ContracargoId: contracargoIdParaCrearAbono });
      if (result.error) {
        handleErrorMessage(result.message);
        handleActiveError(true);
      } else {
        setValor('');
        setTipoTransaccion('');
        setTransasactionId('');
        setFechaEmisionVisa(undefined);
        handleSuccessMessage(result.message);
        setOpenCreateContracargoModel(false);
        handleActiveSuccess(true);
      }
      setOpenAbonoModal(false);
      getContracargos();
      getCantidadContracargos()
      handleLoading(false);
    }

  }

  const deleteAbonoContracargo = async () => {

    console.log('abonoEliminar', abonoEliminar)

    handleLoading(true);

    if (tiposAbono.includes(abonoEliminar?.tipoAbono)) {
      console.log('Eliminar por otros medios')
      const result = await AbonoContracargoService.instance.deleteAbonoContracargoOtrosMedios({ abonoContracargoId: abonoEliminar?.id });
      if (result.error) {
        handleErrorMessage(result.message);
        handleActiveError(true);
      } else {
        setValor('');
        setTipoTransaccion('');
        setTransasactionId('');
        setFechaEmisionVisa(undefined);
        handleSuccessMessage(result.message);
        setOpenCreateContracargoModel(false);
        handleActiveSuccess(true);
      }
    }
    else if (!tiposAbono.includes(abonoEliminar?.tipoAbono)) {
      const result = await AbonoContracargoService.instance.deleteAbonoContracargo({ abonoContracargoId: abonoEliminar?.id });
      if (result.error) {
        handleErrorMessage(result.message);
        handleActiveError(true);
      } else {
        setValor('');
        setTipoTransaccion('');
        setTransasactionId('');
        setFechaEmisionVisa(undefined);
        handleSuccessMessage(result.message);
        setOpenCreateContracargoModel(false);
        handleActiveSuccess(true);
      }
    }

    setOpenDialogDeleteAbono(false);
    getContracargos();
    getCantidadContracargos()
    handleLoading(false);
  }

  const onChangeInputFilter = async (text: any) => {
    setSearchValue(text)
    setLoading(true);
    const result = await ContracargosService.instance.getContracargosPaginados({ offset: page, limit: pageSize, valor: text });
    setRows(result.result);
    setLoading(false);
  }

  const handleOpenUpload = () => {
    setOpenUploadFile(true);
  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const handleDocumentosRazones = (files: any) => {
    setOpenUploadFile(false);
    let data = new FormData();
    files.forEach((file: any) => {
      data.append('file[]', file);
    });

    setUploading(true);
    DocumentosService.instance
      .uploadFileDocSinId(data)
      .then(response => {
        setUploading(false);
        const { error, message, result } = response;
        if (!error) {
          const links = result.map((file: any) => file.Location);
          setLinksDocumentosRazones(links)
        } else {
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
      });
  };

  const createDocumentosContracargos = async (documentos: string[]) => {

    const result = await DocumentosContracargosService.instance.crearDocumentos(
      { ContracargoId: contracargoId, documentos: documentos }
    );
    if (result.error) {
      handleErrorMessage(result.message);
      handleActiveError(true);
    } else {
      setValor('');
      setTipoTransaccion('');
      setFechaEmisionVisa(undefined);
      handleSuccessMessage(result.message);
      setOpenModalUpdateRazon(false);
      handleActiveSuccess(true);
    }
    setCategoria('')
    setEstadoFinal('')
    setRazonCategoria('')
    setContracargoId('')
    setLinksDocumentosRazones([])
    getContracargos();
    getCantidadContracargos()
    handleLoading(false);
    setOpenModalAdjuntos(false);
    setLoading(false);
  }

  const handleDocumentosContracargo = (files: any) => {

    let data = new FormData();

    files.forEach((file: any) => {
      data.append('file[]', file);
    });
    handleLoading(true);
    setUploading(true);
    setLoading(true);
    DocumentosService.instance
      .uploadFilesToS3(data)
      .then(response => {
        setUploading(false);
        if (response.length > 0) {
          const links = response;
          createDocumentosContracargos(links)
          setLinksComprobantes(links)
        } else {
          setLoading(false);
          handleErrorMessage("No se pudo subir archivo");
          handleActiveError(true);
          handleLoading(false);
        }
      })
      .catch(e => {
        setOpenModalAdjuntos(false);
        setUploading(false);
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(false);
        setLoading(false);
      });
  };

  const verHistorial = async (id: any, event: any, origen: string = 'contracargo_categoria') => {
    event.stopPropagation();
    const params = '?origenLike=' + origen;
    await RazonesService.instance
      .getByContracargo(id, params)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          let updatedColumns = [...columnsHistorial];
          if (origen === 'contracargo_estadofinal') {
            updatedColumns[0].headerName = 'Estado asignado';
            setColumnsHistorial(updatedColumns);
          } else if (origen === 'envio_seguimiento') {
            updatedColumns[0].headerName = 'Tipo Seguimiento';
            setColumnsHistorial(updatedColumns);
          } else {
            updatedColumns[0].headerName = 'Categoria asignada';
            setColumnsHistorial(updatedColumns);
          }
          setRowsHistorialCredenciales(result);
          handleLoading(false);
          setOpenHistorialRazones(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  }

  const verDocumentosAdjuntosContracargos = async (event: any, id: any) => {
    console.log(id)
    event.stopPropagation();
    setContracargoId(id);
    await DocumentosContracargosService.instance
      .getByContracargo(id)
      .then(response => {
        const { error, message, result } = response;
        if (!error) {
          setRowsHistorialDocumentos(result);
          handleLoading(false);
          setOpenHistorialDocumentosContracargos(true);
        } else {
          handleLoading(false);
          handleErrorMessage(message);
          handleActiveError(true);
        }
      })
      .catch(e => {
        handleErrorMessage(e.message);
        handleActiveError(true);
        handleLoading(true);
      });
  }

  const handleCloseModal = () => {
    setOpenHistorialRazones(false);
    setOpenHistorialDocumentosContracargos(false);
    setContracargoId('');
  }

  const cuotas = (value: string | undefined | null) => {
    switch (value) {
      case 'VC02':
        return '2 cuotas';
      case 'VC03':
        return '3 cuotas';
      case 'VC06':
        return '6 cuotas';
      case 'VC10':
        return '10 cuotas';
      case 'VC12':
        return '12 cuotas';
      case 'VC15':
        return '15 cuotas';
      case 'VC18':
        return '18 cuotas';
      case 'VC24':
        return '24 cuotas';
      case 'VC36':
        return '36 cuotas';
      case 'VC48':
        return '48 cuotas';
      default:
        return 'Contado';
    }
  };

  const getImagenesAbonos = (imagenesAbonos: any | undefined): string[] => {
    if (imagenesAbonos == undefined) return [];
    imagenesAbonos = JSON.parse(imagenesAbonos);
    return imagenesAbonos;
  }

  return (
    <>
      <Dialog
        open={openDialogDeleteAbono}
        onClose={() => setOpenDialogDeleteAbono(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Deseas eliminar el abono?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogDeleteAbono(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteAbonoContracargo} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        className={classes.modal}
        open={openCreateContracargoModel}
        onClose={() => {
          setOpenCreateContracargoModel(false)
          setValor('');
          setTipoTransaccion('');
          setCategoria('');
          setEstadoFinal('')
          setLinksDocumentosRazones([]);
          setRazonCategoria('');
        }
        }
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCreateContracargoModel}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '2rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
                key='t-0'
              >
                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel id="pais-simple-select-outlined-label">Pais</InputLabel>
                      <Select
                        labelId="pais-simple-select-outlined-label"
                        value={pais}
                        onChange={(e: any) => { setPais(e.target.value) }}
                        variant='outlined'
                        label="Pais"
                      >
                        <MenuItem value={1}>Guatemala</MenuItem>
                        <MenuItem value={2}>Honduras</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Tipo de transacción</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        value={tipoTransaccion}
                        onChange={(e: any) => { setTipoTransaccion(e.target.value) }}
                        variant='outlined'
                        label="Tipo de transacción"
                      >
                        <MenuItem value={'enlace'}>Enlace</MenuItem>
                        {pais !== 2 &&
                          <MenuItem value={'tarjeta'}>Tarjeta</MenuItem>
                        }
                      </Select>
                    </FormControl>

                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                    spacing={2}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel id="buscarpor-simple-select-outlined-label">Buscar por</InputLabel>
                      <Select
                        labelId="buscarpor-customized-select-label"
                        value={buscarPor}
                        variant='outlined'
                        onChange={(e: any) => setBuscarPor(e.target.value)}
                        label='Buscar por'
                      >
                        <MenuItem value={tipoTransaccion === 'enlace' ? 'authorizationNumber' : 'authNumber'}>Número de Autorización</MenuItem>
                        {tipoTransaccion === 'enlace' &&
                          <MenuItem value='hash'>Código de link</MenuItem>
                        }
                        <MenuItem value='id'>Id</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel variant='outlined'>Valor</InputLabel>
                      <OutlinedInput
                        color='primary'
                        fullWidth
                        label='Valor'
                        value={valor}
                        onChange={(e: any) => setValor(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        value={categoria}
                        onChange={(e: any) => { setCategoria(e.target.value) }}
                        variant='outlined'
                        label="Categoria"
                      >
                        {categoriasContracargos.map((categoria) =>
                          <MenuItem key={'menu-' + categoria} value={categoria.value}>{categoria.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>


                  </Grid>
                  {categoria !== '' &&
                    <Grid
                      container
                      direction='column'
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', padding: '8px' }}
                    >
                      <Grid item >
                        <p>INGRESA LA RAZON POR LA QUE ASIGNARAS LA CATEGORIA <strong>{categoria?.toUpperCase()}</strong> (<span style={{ color: 'red' }}>*</span>) </p>
                        <TextareaAutosize onChange={(e) => { setRazonCategoria(e.target.value) }} autoFocus style={{ width: '100%', padding: '10px' }} minRows={8} />
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={uploading}
                          color='primary'
                          variant='contained'
                          title='Agregar documentos de respaldo'
                          onClick={() => {
                            handleOpenUpload();
                          }}
                        />
                        <DropzoneDialog
                          dropzoneText='Arrastra los documentos o presiona aquí'
                          previewText='Vista prévia'
                          dialogTitle={'Elige los archivos'}
                          submitButtonText='Subir archivos'
                          cancelButtonText='Cancelar'
                          open={openUploadFile}
                          onSave={handleDocumentosRazones}
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                          showPreviews={true}
                          maxFileSize={25000000}
                          filesLimit={10}
                          onClose={handleCloseUpload}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>

                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      {/* <InputLabel variant='outlined'>Fecha emisión visa</InputLabel> */}
                      <TextField
                        type={'date'}
                        color='primary'
                        variant='outlined'
                        fullWidth
                        label='Fecha emisión visa'
                        inputRef={startDateFilterRef}
                        value={fechaEmisionVisa}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e: any) => setFechaEmisionVisa(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='row'
                    spacing={3}
                    key='t-0'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Button
                        size='medium'
                        color='primary'
                        variant='contained'
                        onClick={crearContracargo}
                      >
                        Crear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openImagesModal}
        onClose={() => {
          setOpenImagesModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={openImagesModal}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Documento</h2>
            <p id='transition-modal-description'></p>

            {arrayImages.map((item: any) => (
              item.includes('pdf') ? (
                <iframe
                  src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                  style={{ width: '100%', height: '600px' }}
                  key={item}
                  title="PDF Viewer"

                />
              ) : (item.includes('mp4') ? (
                <video
                  width='100%'
                  height='600px'
                  controls
                  autoPlay
                  key={item}
                >
                  <source src={item} type='video/mp4' />
                </video>
              ) : (
                <img
                  src={item}
                //onClick={() => openFile(item)}
                />
              ))


            ))}

          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openHistorialRazones}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openHistorialRazones}>
          <div className={classes.paper}>
            <p id='transition-modal-description'>Registro historico</p>

            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid
                item
                style={{ height: 700, width: 'auto' }}
              >
                <DataGrid
                  isRowSelectable={(params: GridRowParams) => false}
                  loading={loading}
                  rows={rowsHistorialCredenciales}
                  columns={columnsHistorial}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openHistorialDocumentosContracargos}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openHistorialDocumentosContracargos}>
          <div className={classes.paper}>
            <p id='transition-modal-description'>Documentos adjuntos</p>

            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid
                item
                style={{ height: 700, width: 'auto' }}
              >
                <DataGrid
                  isRowSelectable={(params: GridRowParams) => false}
                  loading={loading}
                  rows={rowsHistorialDocumentos}
                  columns={columnsDocumentos}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openModalUpdateRazon}
        onClose={() => {
          setOpenModalUpdateRazon(false)
          setValor('');
          setTipoTransaccion('');
          setCategoria('');
          setEstadoFinal('')
          setRazonCategoria('');
          setLinksDocumentosRazones([]);
        }
        }
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalUpdateRazon}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '2rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
                key='t-0'
              >

                <Grid
                  container
                  item
                  direction='row'
                >
                  {(categoria !== '' || estadofinal !== '') &&
                    <Grid
                      container
                      direction='column'
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', padding: '8px' }}
                    >
                      <Grid item >
                        {categoria !== '' &&
                          <p>INGRESA LA RAZON POR LA QUE CAMBIARAS LA CATEGORIA A: <strong>{categoria?.toUpperCase()}</strong> (<span style={{ color: 'red' }}>*</span>) </p>
                        }
                        {estadofinal !== '' &&
                          <p>INGRESA LA RAZON POR LA QUE CAMBIARAS EL ESTADO A: <strong>{estadofinal?.toUpperCase()}</strong> (<span style={{ color: 'red' }}>*</span>) </p>
                        }
                        <TextareaAutosize onChange={(e) => { setRazonCategoria(e.target.value) }} autoFocus style={{ width: '100%', padding: '10px' }} minRows={8} />
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={uploading}
                          color='primary'
                          variant='contained'
                          title='Agregar documentos de respaldo'
                          onClick={() => {
                            handleOpenUpload();
                          }}
                        />
                        <DropzoneDialog
                          dropzoneText='Arrastra los documentos o presiona aquí'
                          previewText='Vista prévia'
                          dialogTitle={'Elige los archivos'}
                          submitButtonText='Subir archivos'
                          cancelButtonText='Cancelar'
                          open={openUploadFile}
                          onSave={handleDocumentosRazones}
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                          showPreviews={true}
                          maxFileSize={25000000}
                          filesLimit={10}
                          onClose={handleCloseUpload}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>



                <Grid item>
                  <Grid
                    container
                    direction='row'
                    spacing={3}
                    key='t-0'
                    justifyContent='center'
                  >
                    <Grid item>
                      {categoria !== '' &&
                        <Button size='medium' color='primary' variant='contained' onClick={updateContracargo}>CAMBIAR CATEGORIA</Button>
                      }
                      {estadofinal !== '' &&
                        <Button size='medium' color='primary' variant='contained' onClick={updateContracargoEstadoFinal}>CAMBIAR ESTADO</Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={isOpenComprobantes}
        onClose={() => { setIsOpenComprobantes(false) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Fade in={isOpenComprobantes}>
          <div className={classes.paper}>
            <h2 id='transition-modal-title'>Comprobante Abono</h2>
            <p id='transition-modal-description'></p>
            <ImageList
              rowHeight={160}
              className={classes.imageList}
              cols={1}
            >
              {
                linksComprobantes.map((item: any) => (
                  <>
                    {item.includes('pdf') ? (
                      <ImageListItem
                        cols={1}
                        rows={4}
                        style={{
                          width: '100%',
                        }}
                      >

                        <iframe
                          src={`${Rutas.instance.urlBase}/documento/proxy/s3/pdf?url=${item}`}
                          style={{ width: '100%', height: '600px' }}
                          key={item}
                          title="PDF Viewer"
                        />

                      </ImageListItem>
                    )
                      : (
                        <ImageListItem style={{
                          width: '100%',
                        }}>
                          <img style={{
                            width: '100%',
                          }}
                            src={item}
                          />
                        </ImageListItem>
                      )
                    }
                  </>
                ))
              }
            </ImageList>
          </div>
        </Fade>
      </Modal>      <ModalSeguimiento
        setIsOpen={setOpenModalSeguimiento}
        isOpen={openModalSeguimiento}
        contracargoId={contracargoId}
        negocioId={negocioId}
      />
      <DropzoneDialog
        dropzoneText='Arrastra los documentos o presiona aquí'
        previewText='Vista prévia'
        dialogTitle={'Elige los archivos'}
        submitButtonText='Subir archivos'
        cancelButtonText='Cancelar'
        open={openModalAdjuntos}
        onSave={handleDocumentosContracargo}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'video/mp4']}
        showPreviews={true}
        maxFileSize={5000000000}
        filesLimit={subiendoArchivosAbonos ? 1 : 10}
        onClose={() => setOpenModalAdjuntos(false)}
      />

      <Modal
        className={classes.modal}
        open={openAbonoModal}
        onClose={() => {
          setOpenAbonoModal(false)
          setValor('');
          setTipoTransaccion('');
        }
        }
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAbonoModal}>
          <div className={classes.paper}>
            <Box style={{ paddingTop: '2rem' }}>
              <Grid
                container
                direction='column'
                spacing={3}
                key='t-0'
              >
                <Grid
                  container
                  item
                  direction='row'
                >
                  <Grid
                    item
                    style={{ flexGrow: 1, display: 'flex' }}
                  >
                    <FormControl fullWidth={true} variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Tipo de abono</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        value={tipoAbono}
                        onChange={(e: any) => { setTipoAbono(e.target.value) }}
                        variant='outlined'
                        label="Tipo de transacción"
                      >
                        <MenuItem value={'transaccion'}>Transaccion</MenuItem>
                        <MenuItem value={'cheque'}>Cheque</MenuItem>
                        <MenuItem value={'efectivo'}>Efectivo</MenuItem>
                        <MenuItem value={'transferencia'}>Transferencia</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {!tiposAbono.includes(tipoAbono) &&
                  <>
                    <Grid
                      container
                      item
                      direction='row'
                    >
                      <Grid
                        item
                        style={{ flexGrow: 1, display: 'flex' }}
                      >
                        <FormControl fullWidth={true} variant="outlined">
                          <InputLabel variant='outlined'>Id de la transacción</InputLabel>
                          <OutlinedInput
                            color='primary'
                            fullWidth
                            label='Id de la transacción'
                            value={transasactionId}
                            onChange={(e: any) => setTransasactionId(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      direction='row'
                    >
                      <Grid
                        item
                        style={{ flexGrow: 1, display: 'flex' }}
                      >
                        <FormControl fullWidth={true} variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Tipo de transacción</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            value={tipoTransaccion}
                            onChange={(e: any) => { setTipoTransaccion(e.target.value) }}
                            variant='outlined'
                            label="Tipo de transacción"
                          >
                            <MenuItem value={'enlace'}>Enlace</MenuItem>
                            <MenuItem value={'tarjeta'}>Tarjeta</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                  </>
                }

                {tiposAbono.includes(tipoAbono) &&
                  <>
                    <Grid
                      container
                      item
                      direction='row'
                    >
                      <Grid
                        item
                        style={{ flexGrow: 1, display: 'flex' }}
                      >
                        <FormControl fullWidth={true} variant="outlined">
                          <InputLabel variant='outlined'>Monto a abonar</InputLabel>
                          <OutlinedInput
                            type='text'
                            color='primary'
                            fullWidth
                            label='Monto a abonar'
                            value={monto}
                            onChange={(e: any) => {
                              let re = new RegExp(/^\d*\.?\d*$/);
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMonto(e.target.value)
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      direction='row'
                    >
                      <Grid
                        item
                        style={{ flexGrow: 1, display: 'flex' }}
                      >
                        <FormControl fullWidth={true} variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Comentarios</InputLabel>
                          <OutlinedInput
                            type='text'
                            value={comentarioAbono}
                            onChange={(e: any) => setComentarioAbono(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      direction='row'
                    >
                      <Grid
                        item
                        style={{ flexGrow: 1, display: 'flex' }}
                      >
                        <FormControl fullWidth={true} variant="outlined">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(event: any) => {
                              event.stopPropagation();
                              setOpenModalAdjuntos(true);
                              setIsComprobanteOtroMedios(true)
                              setSubiendoArchivosAbonos(true);
                            }}
                            className={classes.button}
                            startIcon={<AttachFileTwoTone />}
                          >
                            Adjuntar Comprobante
                          </Button>
                          <FormHelperText>
                            <Button onClick={(event) => setIsOpenComprobantes(true)} color='inherit' >
                              <small>Ver archivos</small> <HistoryIcon fontSize='small'></HistoryIcon>
                            </Button>
                          </FormHelperText>

                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                }

                <Grid item>
                  <Grid
                    container
                    direction='row'
                    spacing={3}
                    key='t-0'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Button
                        size='medium'
                        color='primary'
                        variant='contained'
                        onClick={createAbonoContracargo}
                      >
                        Crear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Box
        mt={1}
        mb={2}
        p={2}
        pb={4}
        component={Paper}
        style={{ maxHeight: '240vh', height: '90vh' }}
      >
        <Grid
          container
          direction='column'
        >
          <Grid item>
            <Grid container>
              <Grid
                item
                xs={10}
              >
                <Typography
                  variant='h6'
                  gutterBottom
                >
                  CONTRACARGOS
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={() => { setOpenCreateContracargoModel(true) }}
                >
                  Crear
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: "3px", paddingTop: "3px" }} >
            <>
              <Grid
                container
                direction='column'
              >
              </Grid>
              <Grid
                container
                spacing={3}
                key='t-0'
              >
                <Grid item xs={8}>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    color='textSecondary'
                  >
                    Podras buscar por: número de autorización, id de la transacción, teléfono y nombre del negocio
                  </Typography>
                  <TextField
                    style={{ height: '100%' }}
                    label="Buscar por:"
                    variant='filled'
                    fullWidth
                    name='search'
                    value={searchValue}
                    onChange={(e: any) => { onChangeInputFilter(e.target.value) }}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid
            item
            style={{ height: 'calc(95vh - 220px)', width: 'auto' }}
          >
            {
              rows &&
              rows.map((e) => {
                return (
                  <Accordion style={{ paddingTop: '1%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        backgroundColor: "white",
                        color: "#5166AF",
                        borderColor: "#5166AF",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid container spacing={3} direction='row'>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Nombre negocio</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            {e.pagoEnlace && e.pagoEnlace.negocio ? e.pagoEnlace.negocio.nombre : e.pagoTarjeta && e.pagoTarjeta.negocio ? e.pagoTarjeta.negocio.nombre : 'No hay nombre'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Id del negocio</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            {e.pagoEnlace && e.pagoEnlace.negocio ? e.pagoEnlace.negocio.id : e.pagoTarjeta && e.pagoTarjeta.negocio ? e.pagoTarjeta.negocio.id : 'No hay id del negocio'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Id</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            {e.TransaccionEnlaceId ? e.TransaccionEnlaceId : e.TransaccionTarjetaId ? e.TransaccionTarjetaId : 'No hay id'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Autorización de la transacción</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            {e.pagoEnlace ? e.pagoEnlace.authorizationNumber : e.pagoTarjeta ? e.pagoTarjeta.authNumber : 'No hay autorización'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Monto</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.monto}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Monto abonado</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.montoAbonado}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Estado</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.status}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Tipo de transacción</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.pagoEnlace ? "Pago enlace" : "Pago tarjeta"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Fecha de la transacción</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.pagoEnlace ? (new Date(e.pagoEnlace.createdAt)).toLocaleString('es-GT', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }) : e.pagoTarjeta ? (new Date(e.pagoTarjeta.createdAt)).toLocaleString('es-GT', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }) : 'No hay fecha'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Fecha emisión visa</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.fechaEmisionVisa ? (new Date(e.fechaEmisionVisa)).toLocaleString('es-GT', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }) : 'No hay fecha'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Fecha creación contracargo</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {e.createdAt ? (new Date(e.createdAt)).toLocaleString('es-GT', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }) : 'No hay fecha'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth={true} variant="outlined">
                            <InputLabel style={{ color: "#5166AF", fontSize: '18px' }} id="demo-simple-select-outlined-label">CATEGORIA</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              value={e?.categoria}
                              onChange={(even: any) => {
                                even.stopPropagation();
                                setCategoria(even.target.value);
                                setContracargoId(e.id);
                                setOpenModalUpdateRazon(true);
                              }}
                              onClick={(even: any) => { even.stopPropagation(); }}
                              style={{ color: "#5166AF", fontSize: '18px' }}
                              variant='outlined'
                              label="CATEGORIA"
                            >
                              {categoriasContracargos.map((categoria) =>
                                <MenuItem key={'menu2-' + categoria} value={categoria.value}>{categoria.label}</MenuItem>
                              )}
                            </Select>
                            <FormHelperText>
                              <Button onClick={(event) => verHistorial(e.id, event)} color='inherit' >
                                <small>Ver historial</small> <HistoryIcon fontSize='small'></HistoryIcon>
                              </Button>
                            </FormHelperText>

                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth={true} variant="outlined">
                            <InputLabel style={{ color: "#5166AF", fontSize: '18px' }} id="demo-simple-select-outlined-label">RESOLUCIÓN</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              value={e?.estadofinal}
                              onChange={(even: any) => {
                                even.stopPropagation();
                                setEstadoFinal(even.target.value);
                                setContracargoId(e.id);
                                setOpenModalUpdateRazon(true);
                              }}
                              onClick={(even: any) => { even.stopPropagation(); }}
                              style={{ color: "#5166AF", fontSize: '18px' }}
                              variant='outlined'
                              label="ESTADO FINAL"
                            >
                              {estadosContracargos.map((estado) =>
                                <MenuItem key={'estadosC-' + estado} value={estado.value}>{estado.label}</MenuItem>
                              )}
                            </Select>
                            <FormHelperText>
                              <Button onClick={(event) => verHistorial(e.id, event, 'contracargo_estadofinal')} color='inherit' >
                                <small>Ver historial</small> <HistoryIcon fontSize='small'></HistoryIcon>
                              </Button>
                            </FormHelperText>

                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth={true} variant="outlined">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(event) => {
                                event.stopPropagation();
                                setContracargoId(e.id);
                                setOpenModalAdjuntos(true);
                                setIsComprobanteOtroMedios(false);
                                setSubiendoArchivosAbonos(false);
                              }}
                              className={classes.button}
                              startIcon={<AttachFileTwoTone />}
                            >
                              Adjuntar
                            </Button>
                            <FormHelperText>
                              <Button onClick={(event) => verDocumentosAdjuntosContracargos(event, e.id)} color='inherit' >
                                <small>Ver archivos</small> <HistoryIcon fontSize='small'></HistoryIcon>
                              </Button>
                            </FormHelperText>

                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Método de Pago</Typography>
                          <Typography variant="h6" gutterBottom
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {
                              e.pagoEnlace ?
                                cuotas(e.pagoEnlace.numeroDeVisaCuotas)
                                : e.pagoTarjeta ?
                                  cuotas(e.pagoTarjeta.numeroDeVisaCuotas)
                                  : ''
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              setContracargoId(e.id);
                              setOpenModalSeguimiento(true)
                              setNegocioId(e.pagoEnlace && e.pagoEnlace.negocio ? e.pagoEnlace.negocio.id : e.pagoTarjeta && e.pagoTarjeta.negocio ? e.pagoTarjeta.negocio.id : '')
                            }}
                            className={classes.button}
                          >
                            Enviar seguimiento
                          </Button>
                          <FormHelperText>
                            <Button onClick={(event) => verHistorial(e.id, event, 'envio_seguimiento')} color='inherit' >
                              <small>Ver historial</small> <HistoryIcon fontSize='small'></HistoryIcon>
                            </Button>
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <Typography variant="h2" gutterBottom
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Abonos
                    </Typography>
                    <Grid
                      container
                      direction='row'
                      spacing={3}
                      key='t-0'
                      justifyContent='center'
                      style={{ paddingBottom: '3%' }}
                    >
                      <Grid item>
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            console.log(e.id)
                            setOpenAbonoModal(true)
                            setContracargoId(e.id)
                            setContracargoIdParaCrearAbono(e.id)
                          }}
                        >
                          Crear
                        </Button>
                      </Grid>
                    </Grid>
                    <AccordionDetails>
                      <Grid container spacing={3} direction='row'>
                        {
                          e.abonosContracargo && Array.isArray(e.abonosContracargo) && e.abonosContracargo.length != 0 ?
                            e.abonosContracargo.map((abono: any) => {
                              return (
                                <>
                                  <Grid key={'abono-' + abono.id} container spacing={6} direction='row' style={{ paddingBottom: '3%' }}>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Id</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ? abono.pagoEnlace.id : abono.pagoTarjeta ? abono.pagoTarjeta.id : 'No hay id'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Autorización de la transacción</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ? abono.pagoEnlace.authorizationNumber : abono.pagoTarjeta ? abono.pagoTarjeta.authNumber : 'No hay autorización'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Tipo de transacción</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ? "Pago enlace" : "Pago tarjeta"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Monto neto</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.monto ? abono.monto : 'No hay monto neto'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>
                                        {abono.pagoEnlace || abono.pagoTarjeta ? 'Número de tarjeta' : 'Comentarios'}
                                      </Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ? `**** **** **** ${abono.pagoEnlace.numeroTarjeta}` : abono.pagoTarjeta ? `**** **** **** ****` : abono.tipoAbono !== 'transaccion' ? abono.comentarios : 'No hay informacion'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Fecha de la transacción</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ? (new Date(abono.pagoEnlace.createdAt)).toLocaleString('es-GT', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        }) : abono.pagoTarjeta ? (new Date(abono.pagoTarjeta.createdAt)).toLocaleString('es-GT', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        }) : (new Date(abono.createdAt)).toLocaleString('es-GT', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        })}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography style={{ fontSize: "14px", textAlign: 'center' }}>Método de pago</Typography>
                                      <Typography variant="h6" gutterBottom
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {abono.pagoEnlace ?
                                          cuotas(abono.pagoEnlace.numeroDeVisaCuotas)
                                          : abono.pagoTarjeta ?
                                            cuotas(abono.pagoTarjeta.numeroDeVisaCuotas)
                                            : ''}
                                        {
                                          abono.tipoAbono ? `${abono.tipoAbono.charAt(0).toUpperCase()}${abono.tipoAbono.substr(1).toLowerCase()}` : null
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                          setOpenDialogDeleteAbono(true)
                                          setContracargoId(e.id)
                                          setAbonoEliminar(abono)
                                        }}
                                      >
                                        Eliminar
                                      </Button>
                                    </Grid>

                                    {
                                      abono.documento &&
                                      (
                                        <Grid item xs={3}>
                                          <FormHelperText>
                                            <Button style={{
                                              paddingLeft: '25%'
                                            }} onClick={(event) => {
                                              setIsOpenComprobantes(true)
                                              const links = getImagenesAbonos(abono.documento)
                                              setLinksComprobantes(links)
                                            }} color='inherit' >
                                              <small>Ver archivo</small> <HistoryIcon fontSize='small'></HistoryIcon>
                                            </Button>
                                          </FormHelperText>
                                        </Grid>
                                      )
                                    }


                                  </Grid>
                                </>
                              )
                            })
                            :
                            <Grid style={{ flex: 1 }}>
                              <Typography variant="h6" gutterBottom
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                No tiene abonos
                              </Typography>
                            </Grid>
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination count={totalPages}
                page={page}
                shape="rounded"
                size='large'
                color="primary"
                style={{ padding: '8px', textAlign: 'center' }}
                onChange={(event, value) => setPage(value)} />
            </div>
          </Grid>
        </Grid>
        <ModalConfirm
          buttonText="Confirmar"
          title="Borrar documento"
          contentText="¿Estás seguro de borrar este documento?"
          cancelText="Cancelar"
          confirmText="Confirmar"
          handleConfirm={handleConfirmDelete}
          handleClose={handleCloseDelete}
          open={openDeleteModal}
        />
        <PageLoader loading={loading} />
      </Box>
    </>

  );
}

