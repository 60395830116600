import { useContext, useEffect, useState } from 'react'
import CrudDataGrid from '../../Crud/CrudDataGrid'
import { Rutas } from '../../../resources/Statics'
import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import CrudService from '../../../services/CrudService';
import { GeneralContext } from '../../../context/GeneralContext';
import { FormField } from '../../../interfaces/Interfaces';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Rols = () => {

  const [permisos, setPermisos] = useState<{id: number, descripcion: string}[]>([]);
  const [refresh, setRefresh] = useState(false);
  const {
    handleErrorMessage,
    handleActiveError,
  } = useContext(GeneralContext);

  useEffect(() => {
    getPermisos();
  }, []);

  const getPermisos = () => {
    const crudService = CrudService.instance(Rutas.instance.urlPermisos);
    crudService
      .getAll()
      .then(async response => {
        const { error, result } = response;
        if (!error) {
          setPermisos(result);
        }
      })
      .catch(e => {
        handleErrorMessage('message');
        handleActiveError(true);
      });
  };

  const handleChange = async (event: any, row: any, field = 'panel') => {
    const valor = event.target.value; // Valores seleccionados del Select
    let registro = {[field]: valor}
    registro[field] = (field === 'panel' || field === 'responsableUbicacion' || field === 'recibeSolicitudPos') ? (valor === 'Si' ? true : false) : valor
    try {
      const crudService = CrudService.instance(Rutas.instance.urlRole);
      const response = await crudService.updateOne(row.id, registro);
      const { error, result } = response;
      if (!error) {
          setRefresh(true);
      } else {
          handleErrorMessage('message');
          handleActiveError(true);
      }
      setRefresh(false);
      
    } catch (e) {
      console.error('Error al actualizar la base de datos:', e);
    }
  };

  const columns = [
  
      {
          field: 'id',
          headerName: 'ID',
          sortable: false,
          width: 70,
          disableClickEventBubbling: true,
      },
      {
          field: 'nombre',
          headerName: 'Nombre',
          sortable: false,
          width: 170,
          disableClickEventBubbling: true,
          editable: true
      },
      {
        field: 'tipo',
        headerName: 'Tipo',
        sortable: false,
        width: 280,
        disableClickEventBubbling: true,
        editable: true
      },
      {
        field: 'panel',
        headerName: 'Rol de Panel',
        sortable: false,
        width: 165,
        disableClickEventBubbling: true,

        renderCell: (params: any) => {
          const panels = [true, false]
          const valor = params.row.panel === true ? 'Si' : 'No'
          return (
            <div className='d-flex justify-content-between align-items-center'
              style={{ cursor: 'pointer' }}>
              <FormControl>
                <Select value={valor} onChange={(event) => handleChange(event, params.row)}>
                  {panels.map((opcion,index) => (
                    <MenuItem key={index} value={opcion === true ? 'Si' : 'No'}>{opcion === true ? 'Si' : 'No'}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          );
        },
      },
      {
        field: 'responsableUbicacion',
        headerName: 'Responsable de ubicación POS',
        sortable: false,
        width: 280,
        disableClickEventBubbling: true,

        renderCell: (params: any) => {
          const panels = [true, false]
          const valor = params.row.responsableUbicacion === true ? 'Si' : 'No'
          return (
            <div className='d-flex justify-content-between align-items-center'
              style={{ cursor: 'pointer' }}>
              <FormControl>
                <Select value={valor} onChange={(event) => handleChange(event, params.row, 'responsableUbicacion')}>
                  {panels.map((opcion,index) => (
                    <MenuItem key={index} value={opcion === true ? 'Si' : 'No'}>{opcion === true ? 'Si' : 'No'}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          );
        },
      },
      {
        field: 'recibeSolicitudPos',
        headerName: 'Recibe solicitud POS',
        sortable: false,
        width: 280,
        disableClickEventBubbling: true,

        renderCell: (params: any) => {
          const panels = [true, false]
          const valor = params.row.recibeSolicitudPos === true ? 'Si' : 'No'
          return (
            <div className='d-flex justify-content-between align-items-center'
              style={{ cursor: 'pointer' }}>
              <FormControl>
                <Select value={valor} onChange={(event) => handleChange(event, params.row, 'recibeSolicitudPos')}>
                  {panels.map((opcion,index) => (
                    <MenuItem key={index} value={opcion === true ? 'Si' : 'No'}>{opcion === true ? 'Si' : 'No'}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          );
        },
      },
      {
        field: 'permisos',
        headerName: 'Permisos',
        sortable: false,
        width: 580,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
          const permisosSelect = JSON.parse(params.row.permisos) || []
          console.log('permisosSelect',permisosSelect)
          return (
            
              <FormControl>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={permisosSelect}
                onChange={(event) => handleChange(event, params.row, 'permisos')}
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
              >
                {permisos.map((permiso) => (
                  <MenuItem key={permiso.descripcion} value={permiso.descripcion}>
                    <Checkbox checked={permisosSelect.length > 0 ? permisosSelect.indexOf(permiso.descripcion) > -1 : false} />
                    <ListItemText primary={permiso.descripcion} />
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            
          );
        },
      },
    ];

    const formConfig: FormField[] = [
      {
        type: 'text',
        label: 'Nombre',
        name: 'nombre',
        required: true,
        error: 'Debe ingresar un nombre',
        value: '',
      },
      {
        type: 'text',
        label: 'Tipo',
        name: 'tipo',
        required: true,
        error: 'Debe ingresar un tipo',
        value: '',
      },
      {
        type: 'select',
        label: 'Usuario de panel',
        name: 'panel',
        required: true,
        error: 'Debe seleccionar una opcion',
        options: [{value: 1, label: 'Si'}],
        value: 1,
      },
      {
        type: 'select',
        label: 'Responsable de ubicación POS',
        name: 'responsableUbicacion',
        required: true,
        error: 'Debe seleccionar una opcion',
        options: [{value: 0, label: 'No'}, {value: 1, label: 'Si'}],
        value: 0,
      },
      {
        type: 'select',
        label: 'Recibe solicitud POS',
        name: 'recibeSolicitudPos',
        required: true,
        error: 'Debe seleccionar una opcion',
        options: [{value: 0, label: 'No'}, {value: 1, label: 'Si'}],
        value: 0,
      },
      {
        type: 'select-multiple',
        label: 'Permisos',
        name: 'permisos',
        required: true,
        error: 'Debe seleccionar permisos',
        options: permisos.map((permiso) => ({value: permiso.descripcion, label: permiso.descripcion})),
        value: [],
      },
    ];
    
  return (
    <CrudDataGrid  formConfig={formConfig} refresh={refresh} apiEndpoint={Rutas.instance.urlRole}  columns={columns} placeholder='descripcion' />
  )
}

export default Rols