import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Select from '@material-ui/core/Select';
import ValidationFields from '../libs/validationFields'
import { GeneralContext } from '../context/GeneralContext'
import { RegisterInterface } from '../interfaces/Interfaces'
import { listapaises, departamentos, listamunicipios, listabancos, tiposcuenta, tiposAfiliacion } from '../resources/Statics'
import { primary } from '../styles/colors'
import RegisterService from '../services/RegisterService'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Images } from '../styles/images'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        paddingTop: '10%',
        paddingBottom: '10%'
    },
    buttonIniciar: {
        marginRight: theme.spacing(1),
        backgroundColor: primary,
        color: 'white',
        paddingTop: '4%',
        paddingBottom: '4%',
        display: 'block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    container: {
        padding: '5%',
        paddingTop: '1%'
    },
    buttonNext: {
        paddingTop: '5%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    stepTittle: {
        fontWeight: 550
    },
    inputPadding: {
        paddingTop: '1%'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    rootResponsive: {
        width: '100%',
    },
    buttonResponsive: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    resInputsPadding: {
        paddingTop: '2%'
    }

}));

export default function RegisterPage() {
    const classes = useStyles();
    const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage } = useContext(GeneralContext)

    const [values, setValues] = useState<RegisterInterface>({
        telefono: '',
        email: '',
        password: '',
        codigoActivacion: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        cui: '',
        nombreNegocio: '',
        numeroCasa: '',
        pais: '',
        departamento: '',
        municipio: '',
        telefonoNegocio: '',
        nit: '',
        actividad: '',
        subActividad: '',
        banco: '',
        numeroCuenta: '',
        tipoCuenta: '',
        nombreCuenta: '',
        tipoAfiliacion: ''
    });
    const [userActual, setUserActual] = useState(0);
    const [negocioActual, setNegocioActual] = useState(0);
    const [step, setStep] = useState(1);
    const [ochoCaracteresMinimos, setOchoCaracteresMinimos] = useState(false);
    const [mayusculas, setMayusculas] = useState(false);
    const [minusculas, setMinusculas] = useState(false);
    const [numeros, setNumeros] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [openDialog, setOpenDialog] = useState(false);
    const [activities, setActivities] = useState([]);
    const [subActivities, setSubActivities] = useState([]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        async function start() {
            let user: any = window.localStorage.getItem('user')
            let procesoregistro: any = window.localStorage.getItem('procesoregistro')
            let negocio: any = window.localStorage.getItem('negocio')
            let informacionbancaria: any = window.localStorage.getItem('informacionbancaria')

            if (procesoregistro !== null && procesoregistro !== undefined) {

                procesoregistro = JSON.parse(procesoregistro)

                if (procesoregistro.procesoActual < 6) {
                    setStep(procesoregistro.procesoActual)
                } else return
            }

            if (user !== null && user !== undefined) {

                user = JSON.parse(user)

                setUserActual(user.id)

                values.nombre = user.nombre
                values.apellidoPaterno = user.apellidoPaterno
                values.apellidoMaterno = user.apellidoMaterno
                values.cui = user.cui
                values.email = user.email

                //if (procesoregistro.procesoActual === 2) await RegisterService.instance.correoverificacion(values.email)

                setValues(values => values)
            }

            if (negocio !== null && negocio !== undefined) {

                negocio = JSON.parse(negocio)

                setNegocioActual(negocio.id)

                values.nombreNegocio = negocio.nombre
                values.numeroCasa = negocio.numeroCasa
                values.pais = negocio.pais
                values.departamento = negocio.departamento
                values.municipio = negocio.municipio
                values.telefonoNegocio = negocio.telefono
                values.nit = negocio.nit

                setValues(values => values)
            }

            if (informacionbancaria !== null && informacionbancaria !== undefined) {

                informacionbancaria = JSON.parse(informacionbancaria)

                values.banco = informacionbancaria.banco
                values.numeroCuenta = informacionbancaria.numeroCuenta
                values.tipoCuenta = informacionbancaria.tipoCuenta
                values.nombreCuenta = informacionbancaria.nombreCuenta

                setValues(values => values)
            }

        }

        start()

    }, [])

    useEffect(() => {
        async function start() {
            let req = await RegisterService.instance.getActivities()
            let reqsub = await RegisterService.instance.getSubActivities()

            setActivities(req.result)
            setSubActivities(reqsub.result)
        }

        start()
    }, [])

    useEffect(() => {
        function start() {
            let user: any = window.localStorage.getItem('user')
            let negocio: any = window.localStorage.getItem('negocio')

            if (user !== null && user !== undefined) {
                user = JSON.parse(user)
                setUserActual(user.id)
            }

            if (negocio !== null && negocio !== undefined) {
                negocio = JSON.parse(negocio)
                setNegocioActual(negocio.id)
            }
        }

        start()

    }, [values])

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })

    const handleNext = async () => {

        setStep(step + 1)

        return

        handleLoading(true)

        if (step === 1) {

            let validation = ValidationFields.instance.stepp1(values.telefono, values.email, values.password)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step1(values.telefono, values.email, values.password, values.tipoAfiliacion)
                if (res.error) {

                    if (res.message === "El correo ya esta siendo utlizado en otra cuenta") {
                        handleClickOpenDialog()
                    } else {
                        handleErrorMessage(res.message)
                        handleActiveError(true)
                    }

                } else {
                    setStep(step + 1)
                }
            }



        } else if (step === 2) {

            let validation = ValidationFields.instance.stepp2(values.codigoActivacion)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step2(values.codigoActivacion, userActual)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {

                    setStep(step + 1)

                }
            }

        } else if (step === 3) {

            let validation = ValidationFields.instance.stepp3(values.nombre, values.apellidoPaterno, values.apellidoMaterno, values.cui)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step3(values.nombre, values.apellidoPaterno, values.apellidoMaterno, values.cui, userActual)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {

                    setStep(step + 1)

                }
            }

        } else if (step === 4) {

            let validation = ValidationFields.instance.stepp4(values.nombreNegocio, values.numeroCasa, values.departamento, values.municipio, values.telefonoNegocio, values.nit)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step4(values.nombreNegocio, values.numeroCasa, values.departamento, values.municipio, values.telefonoNegocio, values.nit, userActual, negocioActual)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {

                    setStep(step + 1)

                }
            }

        } else if (step === 5) {

            let validation = ValidationFields.instance.stepp5(values.actividad, values.subActividad)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step5(values.actividad, values.subActividad, userActual, negocioActual)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {

                    setStep(step + 1)

                }
            }

        } else if (step === 6) {

            let validation = ValidationFields.instance.stepp6(values.banco, values.numeroCuenta, values.tipoCuenta, values.nombreCuenta)

            if (validation.error) {

                handleErrorMessage(validation.message)
                handleActiveError(true)

            } else {

                let res = await RegisterService.instance.step6(values.banco, values.numeroCuenta, values.tipoCuenta, values.nombreCuenta, userActual, negocioActual)
                if (res.error) {

                    handleErrorMessage(res.message)
                    handleActiveError(true)

                } else {

                    setStep(step + 1)
                    window.location.href = '/home'

                }
            }

        }

        handleLoading(false)

    }

    const handlePrevious = async () => {
        setStep(step - 1)
    }

    const handleChange = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleChangePassword = (prop: any) => (event: any) => {
        setValues({ ...values, [prop]: event.target.value });
        if (event.target.value.length >= 8) setOchoCaracteresMinimos(true)
        else if (event.target.value.length < 8) setOchoCaracteresMinimos(false)
        if (ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(true)
        else if (!ValidationFields.instance.existLowerLetter(event.target.value)) setMinusculas(false)
        if (ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(true)
        else if (!ValidationFields.instance.existUpperLetter(event.target.value)) setMayusculas(false)
        if (ValidationFields.instance.existNumbers(event.target.value)) setNumeros(true)
        else if (!ValidationFields.instance.existNumbers(event.target.value)) setNumeros(false)
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseDialogLogin = () => {
        setOpenDialog(false);
        window.location.href = '/'
    };

    const correoVerificacionCorreo = async () => {
        handleLoading(true)
        let req = await RegisterService.instance.correoVerificacionCorreo(values.email)

        if (req.error) {
            handleErrorMessage(req.message)
            handleActiveError(true)
        } else {
            handleSuccessMessage('Se reenvio un correo a tu cuenta: ' + values.email)
            handleActiveSuccess(true)
        }
        handleLoading(false)
    }

    const correoVerificacionSms = async () => {
        handleLoading(true)
        let req = await RegisterService.instance.correoVerificacionSms(values.email, values.telefono)

        if (req.error) {
            handleErrorMessage(req.message)
            handleActiveError(true)
        } else {
            handleSuccessMessage('Se reenvio un correo a tu cuenta: ' + values.email)
            handleActiveSuccess(true)
        }
        handleLoading(false)
    }

    const telefonoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Teléfono</InputLabel>
                <OutlinedInput
                    type='tel'
                    value={values.telefono}
                    onChange={handleChange('telefono')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const correoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Correo</InputLabel>
                <OutlinedInput
                    type='email'
                    value={values.email}
                    onChange={handleChange('email')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const passwordInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Contraseña</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChangePassword('password')}
                        className={classes.inputPadding}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: ochoCaracteresMinimos ? '#009688' : '#766865' }}>8 caracateres</FormHelperText>
                    <FormHelperText style={{ color: minusculas ? '#009688' : '#766865' }}>Letra minusculas</FormHelperText>
                    <FormHelperText style={{ color: mayusculas ? '#009688' : '#766865' }}>Letra mayusculas</FormHelperText>
                    <FormHelperText style={{ color: numeros ? '#009688' : '#766865' }}>Numero</FormHelperText>
                </div>
            </>
        )
    }

    const tipoAfiliacionInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Tipo Afiliación</InputLabel>

                <Select
                    value={values.tipoAfiliacion}
                    onChange={handleChange('tipoAfiliacion')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        tiposAfiliacion.map(e => {
                            return <MenuItem key={e.id} value={e.id}>{e.nombre}</MenuItem>
                        })
                    }
                </Select>


            </FormControl>
        )
    }

    const codigoActivacionInput = () => {
        return (
            <>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant='filled'>Codigo de activación</InputLabel>
                    <OutlinedInput
                        type='text'
                        value={values.codigoActivacion}
                        onChange={handleChange('codigoActivacion')}
                        className={classes.inputPadding}
                    />
                </FormControl>
                <div style={{ paddingTop: '5%' }}>
                    <FormHelperText style={{ color: '#766865' }}>Enviamos un codigo a tu correo{' ' + values.email}</FormHelperText>
                </div>
            </>
        )
    }

    const nombreClienteInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Nombre</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.nombre}
                    onChange={handleChange('nombre')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const apellidoPaternoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Apellido Paterno</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.apellidoPaterno}
                    onChange={handleChange('apellidoPaterno')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const apellidoMaternoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Apellido Materno</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.apellidoMaterno}
                    onChange={handleChange('apellidoMaterno')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const cuiInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>CUI</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.cui}
                    onChange={handleChange('cui')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const nombreNegocioInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Nombre del negocio</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.nombreNegocio}
                    onChange={handleChange('nombreNegocio')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const numeroCasaInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Dirección</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.numeroCasa}
                    onChange={handleChange('numeroCasa')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const departamentoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Departamento</InputLabel>

                <Select
                    value={values.departamento}
                    onChange={handleChange('departamento')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    
                        {values &&
                        values.pais &&
                        departamentos &&
                        departamentos[values.pais] &&
                        Array.isArray(departamentos[values.pais])
                            ? departamentos[values.pais].map((dep: any) => {
                                return (
                                <MenuItem
                                    key={dep}
                                    value={dep.toLocaleLowerCase()}
                                >
                                    {dep}
                                </MenuItem>
                                );
                            })
                            : null}
                    
                </Select>


            </FormControl>
        )
    }

    const paisInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>País</InputLabel>

                <Select
                    value={values.pais}
                    onChange={handleChange('pais')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        listapaises.map(pais => {
                            return <MenuItem key={pais} value={pais.toLocaleLowerCase()}>{pais}</MenuItem>
                        })
                    }
                </Select>


            </FormControl>
        )
    }

    const municipioInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Municipio</InputLabel>
                <Select
                    value={values.municipio}
                    onChange={handleChange('municipio')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        values && values.departamento && listamunicipios && listamunicipios[values.departamento] && Array.isArray(listamunicipios[values.departamento]) ?
                            listamunicipios[values.departamento].map((municipio: any) => {
                                return <MenuItem key={municipio} value={municipio.toLocaleLowerCase()}>{municipio}</MenuItem>
                            }) :
                            null
                    }
                </Select>
            </FormControl>
        )
    }

    const telefonoNegocioInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Teléfono</InputLabel>
                <OutlinedInput
                    type='tel'
                    value={values.telefonoNegocio}
                    onChange={handleChange('telefonoNegocio')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const nitInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>NIT</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.nit}
                    onChange={handleChange('nit')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const actividadNegocioInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Actividad del negocio</InputLabel>
                <Select
                    value={values.actividad}
                    onChange={handleChange('actividad')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {activities.map((act: any) => {
                        return <MenuItem key={act.id} value={act.id}>{act.nombre.toLocaleUpperCase()}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }

    const subActividadNegocioInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant='filled'>Sub actividad del negocio</InputLabel>
                <Select
                    value={values.subActividad}
                    onChange={handleChange('subActividad')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        subActivities.map((sub: any) => {
                            if (sub.ActividadId === values.actividad) {
                                return <MenuItem key={sub.id} value={sub.id}>{sub.nombre.toLocaleUpperCase()}</MenuItem>
                            } else return null
                        })
                    }
                </Select>
            </FormControl>
        )
    }

    const bancoInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant="filled">Banco</InputLabel>


                <Select
                    value={values.banco}
                    onChange={handleChange('banco')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        listabancos.map(banco => {
                            return <MenuItem key={banco} value={banco.toLocaleLowerCase()}>{banco}</MenuItem>
                        })
                    }
                </Select>

            </FormControl>
        )
    }

    const numeroCuentaInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant="filled">Número de la cuenta</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.numeroCuenta}
                    onChange={handleChange('numeroCuenta')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const tipoCuentaInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant="filled">Tipo de la cuenta</InputLabel>

                <Select
                    value={values.tipoCuenta}
                    onChange={handleChange('tipoCuenta')}
                    className={classes.inputPadding}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        tiposcuenta.map(tc => {
                            return <MenuItem key={tc} value={tc.toLocaleLowerCase()}>{tc}</MenuItem>
                        })
                    }
                </Select>

            </FormControl>
        )
    }

    const nombreCuentaInput = () => {
        return (
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel variant="filled">Nombre de la cuenta</InputLabel>
                <OutlinedInput
                    type='text'
                    value={values.nombreCuenta}
                    onChange={handleChange('nombreCuenta')}
                    className={classes.inputPadding}
                />
            </FormControl>
        )
    }

    const responsiveButtons = () => {
        return (
            <div>
                {step === 1 ?
                    <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.buttonResponsive}
                    >
                        INICIAR
                    </Button>
                    :
                    <>
                        {
                            step > 3 ?
                                <Button
                                    disabled={loading}
                                    onClick={handlePrevious}
                                    className={classes.buttonResponsive}
                                >
                                    Atrás
                                </Button>
                                :
                                null
                        }
                        {
                            step === 2 ?
                                <>
                                    <Button
                                        color="primary"
                                        disabled={loading}
                                        variant='text'
                                        className={classes.buttonResponsive}
                                        onClick={correoVerificacionCorreo}
                                        endIcon={<RefreshIcon />}
                                    >Reenviar Código Email
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={loading}
                                        variant='text'
                                        className={classes.buttonResponsive}
                                        onClick={correoVerificacionSms}
                                        endIcon={<RefreshIcon />}
                                    >Reenviar Código SMS
                                    </Button>
                                </>
                                :
                                null
                        }
                        {
                            step === 6 ?
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setStep(7)
                                        window.location.href = '/home'
                                    }}
                                    className={classes.buttonResponsive}
                                >
                                    OMITIR
                                </Button>
                                :
                                null
                        }
                        <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.buttonResponsive}
                        >
                            {step === 6 ? 'FINALIZAR' : 'SIGUIENTE'}
                        </Button>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            {width > 800 ?

                <div className={classes.root}>

                    <div>

                        <Dialog
                            fullScreen={fullScreen}
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {window.localStorage.getItem('erroractual')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleCloseDialog} color="primary">
                                    Intentar con otro correo
                                </Button>
                                <Button onClick={handleCloseDialogLogin} color="primary" autoFocus>
                                    Ingresar a mi cuenta
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>



                    <div className={classes.container}>

                        <img width={(width * 0.15)} src={Images.instance.logo} alt="" onClick={() => window.location.href = '/'} />

                        <Stepper activeStep={step - 1}>
                            <Step >
                                <StepLabel  >Crea tu cuenta</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Valida tu correo</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Cuentanos de ti</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Cuentanos de tu negocio</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Tipo de negocio</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Datos bancarios</StepLabel>
                            </Step>
                        </Stepper>

                        <Grid container spacing={3}>
                            <Grid item xs={7}>
                                <div >
                                    <img width={(width * 0.50)} src={Images.instance.image1} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ paddingTop: '10%' }}>
                                    {
                                        step === 1 ?
                                            <Grid container spacing={3}>

                                                <Grid item xs={12}>
                                                    <Typography className={classes.stepTittle} variant="h5">
                                                        Crea tu cuenta
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {tipoAfiliacionInput()}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {telefonoInput()}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {correoInput()}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {passwordInput()}
                                                </Grid>

                                            </Grid>
                                            :
                                            step === 2 ?

                                                <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <Typography className={classes.stepTittle} variant="h5">
                                                            Valida tu correo
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {codigoActivacionInput()}
                                                    </Grid>
                                                </Grid>

                                                :
                                                step === 3 ?

                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <Typography className={classes.stepTittle} variant="h5">
                                                                Cuentanos de ti
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {nombreClienteInput()}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {apellidoPaternoInput()}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {apellidoMaternoInput()}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {cuiInput()}
                                                        </Grid>

                                                    </Grid>

                                                    :
                                                    step === 4 ?
                                                        <Grid container spacing={3}>

                                                            <Grid item xs={12}>
                                                                <Typography className={classes.stepTittle} variant="h5">
                                                                    Cuentanos de tu negocio
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {nombreNegocioInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {numeroCasaInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {paisInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {departamentoInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {municipioInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {telefonoNegocioInput()}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {nitInput()}
                                                            </Grid>

                                                        </Grid>

                                                        :
                                                        step === 5 ?

                                                            <Grid container spacing={3}>

                                                                <Grid item xs={12}>
                                                                    <Typography className={classes.stepTittle} variant="h5">
                                                                        Tipo de negocio
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {actividadNegocioInput()}
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {subActividadNegocioInput()}
                                                                </Grid>

                                                            </Grid>

                                                            :
                                                            step === 6 ?

                                                                <Grid container spacing={3}>

                                                                    <Grid item xs={12}>
                                                                        <Typography className={classes.stepTittle} variant="h5">
                                                                            Datos bancarios
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {bancoInput()}
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {numeroCuentaInput()}
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {tipoCuentaInput()}
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {nombreCuentaInput()}
                                                                    </Grid>

                                                                </Grid>

                                                                :

                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <Typography>Completaste tu proceso de registro con exito!</Typography>
                                                                    </Grid>
                                                                </Grid>
                                    }
                                </div>




                                {
                                    step === 1 ?

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>

                                                <div style={{ paddingTop: '8%' }}>
                                                    <Button color="primary" disabled={loading} fullWidth={true} variant="contained" className={classes.buttonIniciar} onClick={handleNext}>Iniciar</Button>
                                                </div>

                                            </Grid>
                                        </Grid>

                                        :

                                        <div style={{ paddingTop: '8%' }}>

                                            <Grid container spacing={3}>


                                                {

                                                    step === 2 ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={loading}
                                                                    variant='text'
                                                                    className={classes.button}
                                                                    onClick={correoVerificacionCorreo}
                                                                    endIcon={<RefreshIcon />}
                                                                >Reenviar Código Email
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={loading}
                                                                    variant='text'
                                                                    className={classes.button}
                                                                    onClick={correoVerificacionSms}
                                                                    endIcon={<RefreshIcon />}
                                                                >Reenviar Código SMS
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                        :
                                                        null
                                                }

                                                {
                                                    step === 3 ?
                                                        <Grid item xs={8}>
                                                        </Grid>
                                                        : null
                                                }


                                                {
                                                    step > 3 && step !== 7 ?
                                                        <Grid item xs={4}>
                                                            <Button disabled={loading} className={classes.button} onClick={handlePrevious}>
                                                                Atrás
                                                            </Button>
                                                        </Grid>
                                                        : null
                                                }

                                                {
                                                    step === 4 || step === 5 ?
                                                        <Grid item xs={4}>
                                                        </Grid>
                                                        : null
                                                }


                                                {
                                                    step === 6 ?
                                                        <Grid item xs={4}>
                                                            <Button disabled={loading} className={classes.button} onClick={() => {
                                                                setStep(7)
                                                                window.location.href = '/home'
                                                            }}>Omitir
                                                            </Button>
                                                        </Grid>
                                                        : null
                                                }

                                                <Grid item xs={4}>
                                                    {
                                                        step !== 7 ? <Button color="primary" disabled={loading} variant="contained" className={classes.button} onClick={handleNext}>{step === 6 ? 'Finalizar' : 'Siguiente'}</Button>
                                                            : null
                                                    }
                                                </Grid>
                                            </Grid>

                                        </div>
                                }


                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>


                    </div>
                </div >
                :

                <div className={classes.rootResponsive}>

                    <Grid container spacing={3}>

                        <Grid item xs={3}>
                        </Grid>

                        <Grid item xs={9}>
                            <div style={{ paddingTop: '5%' }}>
                                <img width={(width * 0.50)} src={Images.instance.logo} alt="" />
                            </div>
                        </Grid>
                    </Grid>

                    <div>

                        <Dialog
                            fullScreen={fullScreen}
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {window.localStorage.getItem('erroractual')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleCloseDialog} color="primary">
                                    Intentar con otro correo
                                </Button>
                                <Button onClick={handleCloseDialogLogin} color="primary" autoFocus>
                                    Recuperar mi constraseña
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>

                    <Stepper activeStep={(step - 1)} orientation="vertical">


                        <Step key={1}>
                            <StepLabel>Crea tu cuenta</StepLabel>
                            <StepContent>
                                <div >{tipoAfiliacionInput()}</div>
                                <div className={classes.resInputsPadding}>{telefonoInput()}</div>
                                <div className={classes.resInputsPadding}>{correoInput()}</div>
                                <div className={classes.resInputsPadding}>{passwordInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={2}>
                            <StepLabel>Valida tu correo</StepLabel>
                            <StepContent>
                                <div>{codigoActivacionInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={3}>
                            <StepLabel>Cuentanos de ti</StepLabel>
                            <StepContent>
                                <div>{nombreClienteInput()}</div>
                                <div className={classes.resInputsPadding}>{apellidoPaternoInput()}</div>
                                <div className={classes.resInputsPadding}>{apellidoMaternoInput()}</div>
                                <div className={classes.resInputsPadding}>{cuiInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={4}>
                            <StepLabel>Cuentanos del negocio</StepLabel>
                            <StepContent>
                                <div>{nombreNegocioInput()}</div>
                                <div className={classes.resInputsPadding}>{numeroCasaInput()}</div>
                                <div className={classes.resInputsPadding}>{paisInput()}</div>
                                <div className={classes.resInputsPadding}>{paisInput()}</div>
                                <div className={classes.resInputsPadding}>{departamentoInput()}</div>
                                <div className={classes.resInputsPadding}>{municipioInput()}</div>
                                <div className={classes.resInputsPadding}>{telefonoNegocioInput()}</div>
                                <div className={classes.resInputsPadding}>{nitInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={5}>
                            <StepLabel>Tipo de negocio</StepLabel>
                            <StepContent>
                                <div>{actividadNegocioInput()}</div>
                                <div className={classes.resInputsPadding}>{subActividadNegocioInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={6}>
                            <StepLabel>Datos bancarios</StepLabel>
                            <StepContent>
                                <div>{bancoInput()}</div>
                                <div className={classes.resInputsPadding}>{numeroCuentaInput()}</div>
                                <div className={classes.resInputsPadding}>{tipoCuentaInput()}</div>
                                <div className={classes.resInputsPadding}>{nombreCuentaInput()}</div>
                                <div className={classes.actionsContainer}>
                                    {responsiveButtons()}
                                </div>
                            </StepContent>
                        </Step>


                    </Stepper>
                    {(step - 1) === 6 && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            <Typography>Completaste tu proceso de registro con exito!</Typography>
                        </Paper>
                    )}
                </div>


            }

        </>
    );
}


/*
            RegisterService.instance.step1({
                telefono: values.telefono,
                email: values.email,
                hash: values.password
            }).then(res => {
                console.log(res)
                if (res) {
                    setUserActual(RegisterService.instance.userActual)
                    setStep(step + 1)
                }
            })


            <option value={'guatemala'}>Alta Verapaz</option>
                                                                <option value={'baja verapaz'}>Baja Verapaz</option>
                                                                <option value={'chimaltenango'}>Chimaltenango</option>
                                                                <option value={'chiquimula'}>Chiquimula</option>
                                                                <option value={'escuintla'}>Escuintla</option>
                                                                <option value={'guatemala'}>Guatemala</option>
                                                                <option value={'huehuetenango'}>Huehuetenando</option>
                                                                <option value={'izabal'}>Izabal</option>
                                                                <option value={'jalapa'}>Jalapa</option>
                                                                <option value={'jutiapa'}>Jutiapa</option>
                                                                <option value={'peten'}>Petén</option>
                                                                <option value={'el progreso'}>El Progreso</option>
                                                                <option value={'quetzaltenango'}>Quetzaltenango</option>
                                                                <option value={'quiche'}>Quiché</option>
                                                                <option value={'retalhuleu'}>Retalhuleu</option>
                                                                <option value={'sacatepequez'}>Sacatepéquez</option>
                                                                <option value={'san marcos'}>San Marcos</option>
                                                                <option value={'santa rosa'}>Santa Rosa</option>
                                                                <option value={'solola'}>Sololá</option>
                                                                <option value={'suchitepequez'}>Suchitepéquez</option>
                                                                <option value={'totonicapan'}>Totonicapán</option>
                                                                <option value={'zacapa'}>Zacapa</option>





        let req = await RegisterService.instance.existNegocio(negocioActual)

        if (req.existe) {
            let res = await RegisterService.instance.updateStep4(values, negocioActual)
            if (res) setStep(step + 1)
        } else {
            let res = await RegisterService.instance.step4(values, userActual, negocioActual)
            if (res) {
                setNegocioActual(RegisterService.instance.negocioActual)
                setStep(step + 1)
            }
        }



        if (step - 1 === 3) {
            let req = await RegisterService.instance.existAccount(userActual)
            if (req.existe) {
                if (req.procesoRegistroData.NegocioId !== null && req.procesoRegistroData.NegocioId !== undefined) setNegocioActual(req.procesoRegistroData.NegocioId)
                if (req.usuarioData !== null && req.usuarioData !== undefined) {
                    setValues({ ...values, nombre: req.usuarioData.nombre, apellidoPaterno: req.usuarioData.apellidoPaterno, apellidoMaterno: req.usuarioData.apellidoMaterno, cui: req.usuarioData.cui });
                }
            }
        } else if (step - 1 === 4) {
            let req = await RegisterService.instance.existNegocio(negocioActual)
            if (req.existe) {
                if (req.negocio !== null && req.negocio !== undefined) {
                    setValues({ ...values, nombreNegocio: req.negocio.nombre, numeroCasa: req.negocio.numeroCasa, departamento: req.negocio.departamento, municipio: req.negocio.municipio, telefonoNegocio: req.negocio.telefono, nit: req.negocio.nit });
                }
            }
        }



            let req = await RegisterService.instance.existAccount(userActual)

            if (req.existe) {
                let res = await RegisterService.instance.updateStep3(values, userActual)
                if (res) setStep(step + 1)
            } else {
                let res = await RegisterService.instance.step3(values, userActual)
                if (res) setStep(step + 1)
            }

            */