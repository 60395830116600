import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { primary, grisFigma } from '../styles/colors';
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import PayWithLinkService from '../services/PayWithLinkService';
import { GeneralContext } from '../context/GeneralContext';
import PaymentIcon from '@material-ui/icons/Payment';
import * as momentZone from 'moment-timezone';
import { Images } from '../styles/images';
import ValidationFields from '../libs/validationFields';
import { estadosEnlace, codigosIsoDepartamentosHonduras, departamentos } from '../resources/Statics';
import PageLoader from '../components/PageLoader';
import { ConfirmPayment } from './ConfirmPayment';
import moment from 'moment';

const localization = require('moment/locale/es');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    paddingTop: '5%',
  },
  inputPadding: {
    paddingTop: '1%',
  },
}));

function RTNMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      maxLength='14'
    />
  );
}

RTNMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};



function CardNumberMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      autoComplete='cc-number'
      maxLength='19'
    />
  );
}

CardNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirationMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

ExpirationMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CvvMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type='tel'
      inputMode='numeric'
      autoComplete='cc-number'
      maxLength='19'
    />
  );
}

CvvMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CodigoPostalMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="19"
    />
  );
}

CodigoPostalMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


export default function PayWithLinkHn() {
  const classes = useStyles();
  const params = useParams<{ payCode: string }>();
  const history = useHistory();
  const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage } =
    useContext(GeneralContext);

  const [values, setValues] = useState({
    numeroTarjeta: '',
    nombreTitular: '',
    expiracion: '',
    nombre: '',
    apellido: '',
    compania: '',
    direccionUno: '',
    direccionDos: '',
    ciudad: '',
    codigoPostal: '',
    numeroTelefonico: '',
    cvv: '',
    nit: '',
    email: '',
    numeroDeVisaCuotas: '',

    // Datos de link rapido
    concepto: '',
    monto: 0,
    marcaTarjeta: '',
  });

  // const [values, setValues] = useState({
  //   numeroTarjeta: '4110 7600 0000 0008',
  //   nombreTitular: 'Jose Santizo',
  //   expiracion: '12/20',
  //   nombre: 'Alejandro',
  //   apellido: 'Cotto',
  //   compania: '',
  //   direccionUno: 'Direccion ejemplo',
  //   direccionDos: '',
  //   ciudad: 'LEMPIRA',
  //   codigoPostal: '010101',
  //   numeroTelefonico: '222222',
  //   cvv: '123',
  //   nit: '31543838384444',
  //   email: 'josesantizo148@gmail.com',
  //   numeroDeVisaCuotas: '',

  //   // Datos de link rapido
  //   concepto: '',
  //   monto: 0,
  //   marcaTarjeta: '',
  // });

  const [formValidity, setFormValidity] = useState({
    numeroTarjeta: true,
    nombreTitular: true,
    expiracion: true,
    cvv: true,

    nombre: true,
    apellido: true,
    direccionUno: true,
    ciudad: true,
    nit: true,
    codigoPostal: true,
    numeroTelefonico: true,
    email: true,

  });

  const [formValid, setFormValid] = useState(false);

  const validateEmail = (email: any) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const validateSize = (value: String, size: any) => {
    return value ? value.trim().length > size : false;
  }

  const validateForm = () => {

    const sizeNombreTitularValid = validateSize(values.nombreTitular, 0);
    const sizeNombreValid = validateSize(values.nombre, 0);
    const sizenumeroTarjetaValid = validateSize(values.numeroTarjeta, 0);
    const sizeExpiracionValid = validateSize(values.expiracion, 4);
    const sizeCvvValid = validateSize(values.cvv, 2);
    const sizeApellidoValid = validateSize(values.apellido, 0);
    const sizeDireccionUnoValid = validateSize(values.direccionUno, 0);
    const sizeCiudadValid = validateSize(values.ciudad, 0);
    const sizeCodigoPostalValid = validateSize(values.codigoPostal, 0);
    const sizeNumeroTelefonicoValid = validateSize(values.numeroTelefonico, 0);
    const sizeRTNValid = validateSize(values.nit, 0)
    const validEmailValid = validateEmail(values.email);
    const sizeEmailValid = validateSize(values.email, 0);


    setFormValid(
      sizeNombreValid && 
      sizeApellidoValid &&
      sizeDireccionUnoValid &&
      sizeCiudadValid &&
      sizeRTNValid &&
      sizeCodigoPostalValid &&
      sizeNumeroTelefonicoValid &&
      sizeEmailValid &&
      validEmailValid &&

      sizenumeroTarjetaValid && 
      sizeNombreTitularValid && 
      sizeExpiracionValid && 
      sizeCvvValid 
      );
  };

  const [width, setWidth] = useState(window.innerWidth);

  const [monto, setMonto] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [pagoRapido, setEsPagoRapido] = React.useState(false);
  const [nombreEmpresa, setNombreEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [cantidadCuotas, setCantidadCuotas] = React.useState('');

  const [enlacePagado, setEnlacePagado] = React.useState(false);
  const [enlaceCaducado, setEnlaceCaducado] = React.useState(false);
  const [enlaceAnulado, setEnlaceAnulado] = React.useState(false);
  const [enlaceRechazado, setEnlaceRechazado] = React.useState(false);
  const [enlacePendiente, setEnlacePendiente] = React.useState(false);
  const [enlacePENDING, setEnlacePENDING] = React.useState(false);
  const [enlaceNoDisponible, setEnlaceNoDisponible] = React.useState(false);

  const [internalReference, setInternalReference] = React.useState('');

  const [typeCard, setTypeCard] = React.useState<string | undefined>('');
  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const [negocioId, setNegocioId] = useState(null); 

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    async function getLinkInfo() {
      let temp = values.numeroTarjeta.replace(/\s+/g, '');

      let tarjeta = ValidationFields.instance.creditCardType(temp);

      setTypeCard(tarjeta);

      handleLoading(true);
      if (params.payCode !== undefined) {
        let result = (await PayWithLinkService.instance.getEnlaceInfo(params.payCode)) as any;
        
        if (!result.error) {
          const hondurasResponse = JSON.parse(result.result.hondurasTransactionResponse);
          setInternalReference(hondurasResponse?.internalReference)
          if (
            result &&
            result.result &&
            result.result.status &&
            (result.result.status == estadosEnlace.instance.pagado || result.result.status == estadosEnlace.instance.OK || result.result.status == estadosEnlace.instance.APPROVED)
          ) {
            setEnlacePagado(true);
            handleLoading(false);
            return;
          } else if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.anulado
          ) {
            setEnlaceAnulado(true);
            handleLoading(false);
            return;
          } else if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.caducado
          ) {
            setEnlaceCaducado(true);
            handleLoading(false);
            return;
          } else if (
            result &&
            result.result &&
            result.result.status &&
            (
              result.result.status == estadosEnlace.instance.rechazado
              || result.result.status == estadosEnlace.instance.REJECTED
              || result.result.status == estadosEnlace.instance.FAILED
              || result.result.status == estadosEnlace.instance.pendiente
            )
          ) {
            setEnlacePendiente(true);
            handleLoading(false);
          } else if (
            result &&
            result.result &&
            result.result.status &&
            result.result.status == estadosEnlace.instance.PENDING
          ) {
            setEnlacePENDING(true);
            handleLoading(false);
            return;
          } else {
            setEnlaceNoDisponible(true);
            handleLoading(false);
          }

          setMonto(result.result.monto);
          setDescripcion(result.result.concepto);
          setEsPagoRapido(result.result.rapido);
          setNombreEmpresa(result.result.negocio.nombre);
          setEmail(result.result.usuario.email);
          setTelefono(result.result.negocio.telefono);
          setDireccion(result.result.negocio.numeroCasa);
          setNegocioId(result.result.NegocioId)

          values.email = result.result.email;
          values.numeroDeVisaCuotas = result.result.numeroDeVisaCuotas;

          switch (result.result.numeroDeVisaCuotas) {
            case 'VC02':
              setCantidadCuotas('2 cuotas');
              break;
            case 'VC03':
              setCantidadCuotas('3 cuotas');
              break;
            case 'VC06':
              setCantidadCuotas('6 cuotas');
              break;
            case 'VC10':
              setCantidadCuotas('10 cuotas');
              break;
            case 'VC12':
              setCantidadCuotas('12 cuotas');
              break;
            case 'VC15':
              setCantidadCuotas('15 cuotas');
              break;
            case 'VC18':
              setCantidadCuotas('18 cuotas');
              break;
            case 'VC24':
              setCantidadCuotas('24 cuotas');
              break;
            case 'VC36':
              setCantidadCuotas('36 cuotas');
              break;
            case 'VC48':
              setCantidadCuotas('48 cuotas');
              break;
            default:
              setCantidadCuotas('Contado');
              break;
          }

          setValues(values);

          let fechaGuatemala = momentZone.tz(moment(), 'America/Guatemala').locale('es').format('LLLL');

          setFecha(fechaGuatemala.toString());
        } else {
          handleErrorMessage(result.message);
          handleActiveError(true);
          handleLoading(false);
        }
        //handleLoading(false);
      }
    }

    getLinkInfo();
  }, []);

  const payLink = async () => {
    handleLoading(true);
    let tempValues = values;
    tempValues.numeroTarjeta = tempValues.numeroTarjeta.trim().replace(/\s+/g, '');
    tempValues.marcaTarjeta = typeCard as string;

    let req = await PayWithLinkService.instance.payLinkHonduras({
      name: tempValues.nombre,
      surname: tempValues.nombreTitular,
      email: tempValues.email,
      documentType: 'RTN',
      document: tempValues.nit,
      mobile: '',
      description: tempValues.concepto,
      currency: 'HNL',
      total: monto,
      number: tempValues.numeroTarjeta,
      expiration: tempValues.expiracion,
      cvv: tempValues.cvv,
      installments: 0,
      userAgent: navigator.userAgent,

      hashPaggoEnlace: params.payCode,
      nit: tempValues.nit,
      nombre: tempValues.nombre,
      apellido: tempValues.apellido,
      direccionUno: tempValues.direccionUno,
      direccionDos: tempValues.direccionDos,
      ciudad: tempValues.ciudad,
      codigoPostal: tempValues.codigoPostal,
      numeroTelefonico: tempValues.numeroTelefonico,
      nombreTitular: tempValues.nombreTitular,
      marcaTarjeta: tempValues.marcaTarjeta,
    });

    if (!req.error) {


      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleSuccessMessage('Error de conexion por favor intente mas tarde');
      } else if (
        req
        && req.result
        && req.result.lookupResponse
        && req.result.lookupResponse.data
        && req.result.lookupResponse.data.redirectUrl) {
        handleSuccessMessage(req.message);
        window.location.assign(req.result.lookupResponse.data.redirectUrl);
      }
      else {
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        history.push(`/voucherLinkHn/${params.payCode}/simple`);
      }
      handleActiveSuccess(true);
      setValues({
        ...values,
        numeroTarjeta: '',
        nombreTitular: '',
        expiracion: '',
        nombre: '',
        cvv: '',
        nit: '',
        email: '',

        // Datos de link rapido
        concepto: '',
        monto: 0,
      });

    } else {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde');
      } else {
        handleErrorMessage(req.message);
      }
      handleActiveError(true);
    }

    handleLoading(false);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBlur = (event: any) => {

    const { name, value } = event.target;
    let isValid = true;
    switch (name) {
      case 'numeroTarjeta':
        isValid = validateSize(value, 0);
        break;
      case 'nombreTitular':
        isValid = validateSize(value, 0);
        break;
      case 'expiracion':
        isValid = validateSize(value, 4);
        break;
      case 'nombre':
        isValid = validateSize(value, 0);
        break;
      case 'apellido':
        isValid = validateSize(value, 0);
        break;
      case 'direccionUno':
        isValid = validateSize(value, 0);
        break;
      case 'ciudad':
        isValid = validateSize(value, 0);
        break;
      case 'nit':
        isValid = validateSize(value, 0);
        break;
      case 'codigoPostal':
        isValid = validateSize(value, 0);
        break;
      case 'numeroTelefonico':
        isValid = validateSize(value, 0);
        break;
      case 'email':
        isValid = validateSize(value, 0) && validateEmail(value);
        break;
      case 'cvv':
        isValid = validateSize(value, 2);
        break;

      default:
        break;
    }

    setFormValidity((prevValidity) => ({
      ...prevValidity,
      [name]: isValid,
    }));

    //setFormValid(isValid)
    validateForm()
  };

  const handleChangeNumberCard = (prop: any) => (event: any) => {
    let temp = event.target.value;

    setValues({ ...values, [prop]: event.target.value });

    temp = temp.replace(/\s+/g, '');

    let tarjeta = ValidationFields.instance.creditCardType(temp);

    setTypeCard(tarjeta);
  };

  const handleChangeLetters = (prop: any) => (event: any) => {
    const onlyLetters = /^[a-zA-Z ]+$/;
    if (event.target.value.match(onlyLetters) || event.target.value === '') {
      handleChange(prop)(event)
    }
  }

  const onChangeLocalidad = (departamento: string) => {
    setValues({
      ...values,
      ciudad: departamento
    });
  };



  const apellido = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Apellido<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="apellido"
          autoComplete="apellido"
          value={values.apellido}
          onChange={handleChange('apellido')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.apellido}
        />
        {!formValidity.apellido && (
          <Typography color="error">Debe ingresar un apellido</Typography>
        )}
      </FormControl>
    )
  }

  const direccionUno = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección uno<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="direccionUno"
          autoComplete="direccionUno"
          value={values.direccionUno}
          onChange={handleChange('direccionUno')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.direccionUno}
        />
        {!formValidity.direccionUno && (
          <Typography color="error">Debe ingresar una dirección</Typography>
        )}
      </FormControl>
    )
  }

  const direccionDos = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección dos</InputLabel>
        <OutlinedInput
          type='text'
          name="direccionDos"
          autoComplete="direccionDos"
          value={values.direccionDos}
          onChange={handleChange('direccionDos')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const compania = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Compañía</InputLabel>
        <OutlinedInput
          type='text'
          name="compania"
          autoComplete="compania"
          value={values.compania}
          onChange={handleChange('compania')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const localidad = () => {
    return (
      <FormControl fullWidth={true} variant='outlined' style={{
        minWidth: 120,
      }}>
        <InputLabel variant='filled' id="demo-simple-select-outlined-label">Departamento<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name='ciudad'
          value={values.ciudad}
          defaultValue={values.ciudad}
          onChange={handleChange('ciudad')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.ciudad}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {
            departamentos['honduras'].map((item: string, index: string) => {
              return (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              )
            })
          }
        </Select>
        {!formValidity.ciudad && (
          <Typography color="error">Debes seleccionar un Departamento</Typography>
        )}
      </FormControl>
    )
  }

  const codigoPostal = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Código Postal<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="codigoPostal"
          autoComplete="codigoPostal"
          value={values.codigoPostal}
          onChange={handleChange('codigoPostal')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.codigoPostal}
          inputComponent={CodigoPostalMask}
        />
        {!formValidity.codigoPostal && (
          <Typography color="error">Debe ingresar un codigo postal</Typography>
        )}
      </FormControl>
    )
  }

  const numeroTelefonico = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Número Telefónico<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='tel'
          name="numeroTelefonico"
          autoComplete="numeroTelefonico"
          value={values.numeroTelefonico}
          onChange={handleChange('numeroTelefonico')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.numeroTelefonico}
        />
        {!formValidity.numeroTelefonico && (
          <Typography color="error">Debe ingresar un numero telefónico</Typography>
        )}
      </FormControl>
    )
  }

  const nombre = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Nombre<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name='nombre'
          autoComplete='name'
          value={values.nombre}
          onChange={handleChangeLetters('nombre')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.nombre}
        />
        {!formValidity.nombre && (
          <Typography color="error">Debe ingresar un nombre</Typography>
        )}
      </FormControl>
    );
  };

  const conceptoInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Concepto</InputLabel>
        <OutlinedInput
          type='text'
          value={values.concepto}
          onChange={handleChange('concepto')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const montoInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>Monto</InputLabel>
        <OutlinedInput
          type='number'
          value={values.monto == 0 ? '' : values.monto}
          onChange={handleChange('monto')}
          className={classes.inputPadding}
        />
      </FormControl>
    );
  };

  const emailInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Correo electrónico (para compartir voucher)
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='email'
          name='email'
          autoComplete='email'
          value={values.email}
          onChange={handleChange('email')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.email}
        />
        {!formValidity.email && (
          <Typography color="error">Debe ingresar un email</Typography>
        )}
      </FormControl>
    );
  };

  const nitInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          RTN
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          name='nit'
          type='text'
          value={values.nit}
          onChange={handleChange('nit')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          inputComponent={RTNMask}
          error={!formValidity.nit}
        />
        {!formValidity.nit && (
          <Typography color="error">Debes ingresar el RTN</Typography>
        )}
      </FormControl>
    );
  };

  const expiracionInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Expiración
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='expiracion'
          autoComplete='cc-exp'
          value={values.expiracion}
          onChange={handleChange('expiracion')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          inputComponent={ExpirationMask}
          error={!formValidity.expiracion}
        />
        {!formValidity.expiracion && (
          <Typography color="error">Expiración requerida</Typography>
        )}
      </FormControl>
    );
  };

  const numeroTarjetaInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Número de tarjeta
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          name='numeroTarjeta'
          value={values.numeroTarjeta}
          onChange={handleChangeNumberCard('numeroTarjeta')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          inputComponent={CardNumberMask}
          endAdornment={
            typeCard !== undefined && typeCard === 'VISA' ? (
              <FaCcVisa size={30} />
            ) : typeCard !== undefined && typeCard === 'MASTERCARD' ? (
              <FaCcMastercard size={30} />
            ) : null
          }
          error={!formValidity.numeroTarjeta}
        />
        {!formValidity.numeroTarjeta && (
          <Typography color="error">Número de tarjeta requerida</Typography>
        )}
      </FormControl>
    );
  };

  const nombreTitularInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          Nombre del titular
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name='nombreTitular'
          autoComplete='cc-name'
          value={values.nombreTitular}
          onChange={handleChangeLetters('nombreTitular')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          error={!formValidity.nombreTitular}
        />
        {!formValidity.nombreTitular && (
          <Typography color="error">Nombre del titular requerido</Typography>
        )}
      </FormControl>
    );
  };

  const cvvInput = () => {
    return (
      <FormControl
        fullWidth={true}
        variant='outlined'
      >
        <InputLabel variant='filled'>
          CVV
          <Typography
            variant='subtitle2'
            style={{
              color: 'red',
              display: 'inline-block',
            }}
          >
            *
          </Typography>
        </InputLabel>
        <OutlinedInput
          type='number'
          name='cvv'
          value={values.cvv}
          autoComplete='cc-csc'
          onChange={handleChange('cvv')}
          onBlur={handleBlur}
          className={classes.inputPadding}
          inputComponent={CvvMask}
          error={!formValidity.cvv}
        />
        {!formValidity.cvv && (
          <Typography color="error">CVV requerido</Typography>
        )}
      </FormControl>
    );
  };

  return (
    <>
      <div className={classes.root}>
        {enlacePagado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkYaFuePagado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceCaducado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCaducado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceAnulado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkAnulado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlacePENDING ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <Typography>En estos momentos su transacción <strong>#{internalReference}</strong> se encuentra pendiente de validación por la entidad financiera. Por favor espera unos minutos a que se resuelva. En caso de no recibir una respuesta, contactar a la línea de soporte/atención <strong>3032 7066</strong>, puedes enviar un correo a <strong>paggosuport@pagooapp.com</strong>. Muchas Gracias.</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceNoDisponible ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkAnulado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : showConfirmComponent ? (
          <ConfirmPayment businessName={nombreEmpresa} />
        ) : (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ paddingTop: '5%' }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    style={{
                      fontWeight: 550,
                      color: 'black',
                      textAlign: 'center',
                    }}
                    variant='h3'
                  >
                    {nombreEmpresa}
                  </Typography>
                  <Typography
                    style={{
                      color: 'black',
                      textAlign: 'center',
                    }}
                    variant='subtitle1'
                  >
                    Te envió un link de paggo {pagoRapido ? 'rápido' : ''}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: grisFigma,
                    }}
                  >
                    Información de:
                    <Typography
                      variant='subtitle1'
                      style={{
                        fontWeight: 500,
                        color: 'black',
                        display: 'inline',
                      }}
                    >
                      {' ' + nombreEmpresa}
                    </Typography>
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: grisFigma,
                    }}
                  >
                    Correo electrónico:
                    <Typography
                      variant='subtitle1'
                      style={{
                        fontWeight: 400,
                        color: '#5166AF',
                        display: 'inline',
                      }}
                    >
                      {' ' + email}
                    </Typography>
                  </Typography>

                  {!pagoRapido ? (
                    <>
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Monto a pagar:
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontWeight: 500,
                            color: 'black',
                            display: 'inline',
                          }}
                        >
                          {' L' + monto}
                        </Typography>
                      </Typography>

                      {cantidadCuotas.length > 0 ? (
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: grisFigma,
                          }}
                        >
                          Pago {`${cantidadCuotas == 'Contado' ? 'de' : 'en'} ${cantidadCuotas}`}
                        </Typography>
                      ) : (
                        <></>
                      )}
                      {cantidadCuotas.includes('cuotas') ? (
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontSize: '12px',
                            color: grisFigma,
                          }}
                        >
                          <Typography
                            component='span'
                            color='error'
                          >
                            *
                          </Typography>{' '}
                          Tarjetas emitidas por BAC no son aceptadas por Visa Cuotas
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null}
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: grisFigma,
                    }}
                  >
                    Fecha: {fecha}
                  </Typography>
                  {!pagoRapido ? (
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: grisFigma,
                      }}
                    >
                      Concepto: {descripcion}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: grisFigma,
                      fontWeight: 550,
                    }}
                  >
                    Datos de facturación
                  </Typography>
                </Grid>

                {pagoRapido ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {conceptoInput()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {montoInput()}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}


                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nombre()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {apellido()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionUno()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {direccionDos()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {localidad()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {compania()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {nitInput()}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: pagoRapido ? '2%' : '2%',
                    }}
                  >
                    {codigoPostal()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {numeroTelefonico()}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: '2%',
                    }}
                  >
                    {emailInput()}
                  </Grid>

                  

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: grisFigma,
                      fontWeight: 550,
                    }}
                  >
                    Datos de tarjeta
                    <div style={{ display: 'inline', paddingLeft: '1%' }}>
                      <img
                        width={30}
                        src={Images.instance.iconoMasterCard}
                      />
                      <img
                        width={40}
                        src={Images.instance.iconoVisa}
                      />
                    </div>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  {numeroTarjetaInput()}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  {nombreTitularInput()}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  {expiracionInput()}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    paddingTop: '2%',
                  }}
                >
                  {cvvInput()}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: '7%',
                  }}
                >
                  <Button
                    fullWidth={true}
                    color='primary'
                    disabled={loading || !formValid}
                    variant='contained'
                    className={classes.button}
                    onClick={payLink}
                  >
                    Pagar
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    style={{
                      color: '#ABB8C8',
                      textAlign: 'center',
                      fontWeight: 600,
                      paddingTop: 15,
                    }}
                    variant='subtitle2'
                  >
                    <div style={{ display: 'inline', paddingRight: '1%' }}>
                      <img
                        width={15}
                        src={Images.instance.candadoEnlace}
                      />
                    </div>
                    Por políticas de seguridad, tú paggo es seguro.
                  </Typography>
                  <Typography
                    style={{
                      color: '#ABB8C8',
                      textAlign: 'center',
                      paddingBottom: 50,
                    }}
                    variant='subtitle2'
                  >
                    Ni el comercio afiliado, ni Paggo recopila la información de tú tarjeta. Solamente tú puedes ver
                    esta información.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        <PageLoader loading={loading} />
      </div>
    </>
  );
}
