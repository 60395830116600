import http from '../libs/http';
import { Rutas } from '../resources/Statics';
import { S3 } from 'aws-sdk';

interface UpdateNewStatusBody {
  subirloNegocio?: boolean
  negocioId: number;
  message: string;
  estado: number;
  tiempoRevision?: number;
  usuarioRevisionId?: number
}

export default class DocumentosService {
  static instance = new DocumentosService();

  uploadFileDoc = async (id: number, data: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentos + '/' + id + '/upload';

      const httpInstance = new http();

      let req = await httpInstance.upload(ruta, data, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Upload filedor service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  uploadFileDocSinId = async (data: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentos + '/upload';

      const httpInstance = new http();

      let req = await httpInstance.upload(ruta, data, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Upload filedor service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  uploadFilesToS3 = async (formData: FormData): Promise<string[]> => {
    const uploadResults: string[] = [];
  
    try {
      // Configurar la instancia de AWS S3
      const s3 = new S3({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_S3 || 'AKIARVDTB3K6HVCDDLML',
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_S3 || '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',
        signatureVersion: 'v4',
        region: 'us-east-2'
      });


      // Convertir las claves de FormData a un array
      const keysArray = Array.from(formData.keys());
  
      // Iterar sobre las claves del FormData
      for (const [key, value] of formData.entries()) {
        // Obtener el archivo correspondiente a la clave
        if (value instanceof File) {
          const file = value;
    
          // Configurar los parámetros para la carga del archivo en S3
          const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME || 'documentos-paggo',
            Key: file.name, // Puedes cambiar el nombre según tus necesidades
            Body: file,
            ContentType: file.type,
            ACL: 'public-read'
          };
    
          // Subir el archivo comprimido a S3
          const result = await s3.upload(params).promise();
    
          // Almacenar la información del archivo subido
          uploadResults.push(result.Location);
        }
  
      }
    } catch (error) {
      console.error('Error al comprimir o subir los archivos a S3:', error);
    }
  
    return uploadResults;
  };

  createDocumento = async (params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentos + '/subirDocumento';

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);
      console.log("req -> ", req)
      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.documentos };
    } catch (error: any) {
      console.log('createDocumento service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateDocumentStatus = async (id: number, params: any) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentos + '/' + id + '/updatestatus';

      const httpInstance = new http();

      let req = await httpInstance.post(ruta, params, true);

      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Create User service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  updateNewDocumentStatus = async (id: number, params: UpdateNewStatusBody) => {
    try {
      const ruta = Rutas.instance.urlBase + Rutas.instance.urlDocumentos + '/' + id + '/updatenewstatus';
      const httpInstance = new http();
      let req = await httpInstance.post(ruta, params, true);
      if (req.error) throw new Error(req.message);

      return { error: req.error, message: req.message, result: req.result };
    } catch (error: any) {
      console.log('Create User service error', error);
      return { error: true, message: error.message, result: null };
    }
  };

  analyzefiles = async (id: number, negocioId: number) => {
    try {
      const ruta = Rutas.instance.urlAnalyzeFiles
      const httpInstance = new http();
      let req = await httpInstance.post(ruta, {negocioId, documentoId: id}, true);
      return { error: req.error, message: req.message, result: null };

    } catch (error: any) {
      return { error: true, message: error.message, result: null };

    }
  }
}



