import React, { useState, useEffect, useContext } from 'react';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'
import { primary, grisFigma } from '../styles/colors'
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import PayWithLinkService from '../services/PayWithLinkService'
import { GeneralContext } from '../context/GeneralContext'
import PaymentIcon from '@material-ui/icons/Payment';
import * as momentZone from 'moment-timezone'
import { Images } from '../styles/images'
import ValidationFields from '../libs/validationFields'
import { estadosEnlace } from '../resources/Statics'
import PageLoader from '../components/PageLoader';
import { ConfirmPayment } from './ConfirmPayment';
import moment from "moment";
import { valuesInterface3dsTransaction } from '../interfaces/Interfaces';

const localization = require('moment/locale/es')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    paddingTop: '5%'
  },
  inputPadding: {
    paddingTop: '1%'
  }
}));

function CardNumberMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="23"
    />
  );
}

CardNumberMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ExpirationMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

ExpirationMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CvvMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="19"
    />
  );
}

CvvMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CodigoPostalMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      type="tel"
      inputMode="numeric"
      autoComplete="cc-number"
      maxLength="19"
    />
  );
}

CodigoPostalMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const departamentoGuatemala: { departamento: string, iso: string, upperCase: string, municipios: { municipio: string, iso: string, upperCase: string }[] }[] = [
  {
    departamento: 'Guatemala', iso: 'GU', upperCase: 'GUATEMALA',
    municipios: [
      { municipio: 'Guatemala', iso: 'GU', upperCase: 'GUATEMALA' },
      { municipio: 'Santa Catarina Pinula', iso: 'SC', upperCase: 'SANTA CATARINA PINULA' },
      { municipio: 'San José Pinula', iso: 'SJ', upperCase: 'SAN JOSE PINULA' },
      { municipio: 'San José del Golfo', iso: 'SG', upperCase: 'SAN JOSE DEL GOLFO' },
      { municipio: 'Palencia', iso: 'PA', upperCase: 'PALENCIA' },
      { municipio: 'Chinautla', iso: 'CH', upperCase: 'CHINAUTLA' },
      { municipio: 'San Pedro Ayampuc', iso: 'SA', upperCase: 'SAN PEDRO AYAMPUC' },
      { municipio: 'Mixco', iso: 'MI', upperCase: 'MIXCO' },
      { municipio: 'San Pedro Sacatepéquez', iso: 'SS', upperCase: 'SAN PEDRO SACATEPEQUEZ' },
      { municipio: 'San Juan Sacatepéquez', iso: 'SJ', upperCase: 'SAN JUAN SACATEPEQUEZ' },
      { municipio: 'San Raymundo', iso: 'SR', upperCase: 'SAN RAYMUNDO' },
      { municipio: 'Chuarrancho', iso: 'CH', upperCase: 'CHUARRANCHO' },
      { municipio: 'Fraijanes', iso: 'FR', upperCase: 'FRAIJANES' },
      { municipio: 'Amatitlán', iso: 'AM', upperCase: 'AMATITLAN' },
      { municipio: 'Villa Nueva', iso: 'VN', upperCase: 'VILLA NUEVA' },
      { municipio: 'Villa Canales', iso: 'VC', upperCase: 'VILLA CANALES' },
      { municipio: 'Petapa', iso: 'PE', upperCase: 'PETAPA' },
    ]
  },
  {
    departamento: 'Alta Verapaz', iso: 'AV', upperCase: 'ALTA VERAPAZ'
    , municipios: [
      { municipio: 'Cobán', iso: 'CO', upperCase: 'COBAN' },
      { municipio: 'Santa Cruz Verapaz', iso: 'SC', upperCase: 'SANTA CRUZ VERAPAZ' },
      { municipio: 'San Cristóbal Verapaz', iso: 'SC', upperCase: 'SAN CRISTOBAL VERAPAZ' },
      { municipio: 'Tactic', iso: 'TA', upperCase: 'TACTIC' },
      { municipio: 'Tamahú', iso: 'TA', upperCase: 'TAMAHU' },
      { municipio: 'Tucurú', iso: 'TU', upperCase: 'TUCURU' },
      { municipio: 'Panzós', iso: 'PA', upperCase: 'PANZOS' },
      { municipio: 'Senahú', iso: 'SE', upperCase: 'SENAHU' },
      { municipio: 'San Pedro Carchá', iso: 'SP', upperCase: 'SAN PEDRO CARCHA' },
      { municipio: 'San Juan Chamelco', iso: 'SJ', upperCase: 'SAN JUAN CHAMELCO' },
      { municipio: 'Lanquín', iso: 'LA', upperCase: 'LANQUIN' },
      { municipio: 'Cahabón', iso: 'CA', upperCase: 'CAHABON' },
      { municipio: 'Chisec', iso: 'CH', upperCase: 'CHISEC' },
      { municipio: 'Chahal', iso: 'CH', upperCase: 'CHAHAL' },
      { municipio: 'Fray Bartolomé de las Casas', iso: 'FR', upperCase: 'FRAY BARTOLOME DE LAS CASAS' },
      { municipio: 'Santa Catarina La Tinta', iso: 'SC', upperCase: 'SANTA CATARINA LA TINTA' },
      { municipio: 'Raxruhá', iso: 'RA', upperCase: 'RAXRUHA' },
    ]
  },
  {
    departamento: 'Baja Verapaz', iso: 'BV', upperCase: 'BAJA VERAPAZ'
    , municipios: [
      { municipio: 'Salamá', iso: 'SA', upperCase: 'SALAMA' },
      { municipio: 'San Miguel Chicaj', iso: 'SM', upperCase: 'SAN MIGUEL CHICAJ' },
      { municipio: 'Rabinal', iso: 'RA', upperCase: 'RABINAL' },
      { municipio: 'Cubulco', iso: 'CU', upperCase: 'CUBULCO' },
      { municipio: 'Granados', iso: 'GR', upperCase: 'GRANADOS' },
      { municipio: 'Santa Cruz El Chol', iso: 'SC', upperCase: 'SANTA CRUZ EL CHOL' },
      { municipio: 'San Jerónimo', iso: 'SJ', upperCase: 'SAN JERONIMO' },
      { municipio: 'Purulhá', iso: 'PU', upperCase: 'PURULHA' },
    ]
  },
  {
    departamento: 'Chimaltenango', iso: 'CM', upperCase: 'CHIMALTENANGO'
    , municipios: [
      { municipio: 'Chimaltenango', iso: 'CH', upperCase: 'CHIMALTENANGO' },
      { municipio: 'San José Poaquil', iso: 'SJ', upperCase: 'SAN JOSE POAQUIL' },
      { municipio: 'San Martín Jilotepeque', iso: 'SJ', upperCase: 'SAN MARTIN JILOTEPEQUE' },
      { municipio: 'San Juan Comalapa', iso: 'SJ', upperCase: 'SAN JUAN COMALAPA' },
      { municipio: 'Santa Apolonia', iso: 'SA', upperCase: 'SANTA APOLONIA' },
      { municipio: 'Tecpán Guatemala', iso: 'TE', upperCase: 'TECPAN GUATEMALA' },
      { municipio: 'Patzún', iso: 'PA', upperCase: 'PATZUN' },
      { municipio: 'Pochuta', iso: 'PO', upperCase: 'POCHUTA' },
      { municipio: 'Patzicía', iso: 'PA', upperCase: 'PATZICIA' },
      { municipio: 'Santa Cruz Balanyá', iso: 'SC', upperCase: 'SANTA CRUZ BALANYA' },
      { municipio: 'Acatenango', iso: 'AC', upperCase: 'ACATENANGO' },
      { municipio: 'Yepocapa', iso: 'YE', upperCase: 'YEPOCAPA' },
      { municipio: 'San Andrés Itzapa', iso: 'SA', upperCase: 'SAN ANDRES ITZAPA' },
      { municipio: 'Parramos', iso: 'PA', upperCase: 'PARRAMOS' },
      { municipio: 'Zaragoza', iso: 'ZA', upperCase: 'ZARAGOZA' },
    ]
  },
  {
    departamento: 'Chiquimula', iso: 'CQ', upperCase: 'CHIQUIMULA'
    , municipios: [
      { municipio: 'Chiquimula', iso: 'CH', upperCase: 'CHIQUIMULA' },
      { municipio: 'San José La Arada', iso: 'SJ', upperCase: 'SAN JOSE LA ARADA' },
      { municipio: 'San Juan Hermita', iso: 'SJ', upperCase: 'SAN JUAN HERMITA' },
      { municipio: 'Jocotán', iso: 'JO', upperCase: 'JOCOTAN' },
      { municipio: 'Camotán', iso: 'CA', upperCase: 'CAMOTAN' },
      { municipio: 'Olopa', iso: 'OL', upperCase: 'OLOPA' },
      { municipio: 'Esquipulas', iso: 'ES', upperCase: 'ESQUIPULAS' },
      { municipio: 'Concepción Las Minas', iso: 'CO', upperCase: 'CONCEPCION LAS MINAS' },
      { municipio: 'Quetzaltepeque', iso: 'QU', upperCase: 'QUETZALTEPEQUE' },
      { municipio: 'San Jacinto', iso: 'SJ', upperCase: 'SAN JACINTO' },
      { municipio: 'Ipala', iso: 'IP', upperCase: 'IPALA' },
    ]
  },
  {
    departamento: 'El Progreso', iso: 'PR', upperCase: 'EL PROGRESO',
    municipios: [
      { municipio: 'Guastatoya', iso: 'GU', upperCase: 'GUASTATOYA' },
      { municipio: 'Morazán', iso: 'MO', upperCase: 'MORAZAN' },
      { municipio: 'San Agustín Acasaguastlán', iso: 'SA', upperCase: 'SAN AGUSTIN ACASAGUASTLAN' },
      { municipio: 'San Cristóbal Acasaguastlán', iso: 'SC', upperCase: 'SAN CRISTOBAL ACASAGUASTLAN' },
      { municipio: 'El Jícaro', iso: 'EL', upperCase: 'EL JICARO' },
      { municipio: 'Sansare', iso: 'SA', upperCase: 'SANSARE' },
      { municipio: 'Sanarate', iso: 'SA', upperCase: 'SANARATE' },
      { municipio: 'San Antonio La Paz', iso: 'SA', upperCase: 'SAN ANTONIO LA PAZ' },
    ]
  },
  {
    departamento: 'Escuintla', iso: 'ES', upperCase: 'ESCUINTLA',
    municipios: [
      { municipio: 'Escuintla', iso: 'ES', upperCase: 'ESCUINTLA' },
      { municipio: 'Santa Lucía Cotzumalguapa', iso: 'SA', upperCase: 'SANTA LUCIA COTZUMALGUAPA' },
      { municipio: 'La Democracia', iso: 'LA', upperCase: 'LA DEMOCRACIA' },
      { municipio: 'Siquinalá', iso: 'SI', upperCase: 'SIQUINALA' },
      { municipio: 'Masagua', iso: 'MA', upperCase: 'MASAGUA' },
      { municipio: 'Tiquisate', iso: 'TI', upperCase: 'TIQUISATE' },
      { municipio: 'La Gomera', iso: 'LA', upperCase: 'LA GOMERA' },
      { municipio: 'Guanagazapa', iso: 'GU', upperCase: 'GUANAGAZAPA' },
      { municipio: 'San José', iso: 'SJ', upperCase: 'SAN JOSE' },
      { municipio: 'Iztapa', iso: 'IZ', upperCase: 'IZTAPA' },
      { municipio: 'Palín', iso: 'PA', upperCase: 'PALIN' },
      { municipio: 'San Vicente Pacaya', iso: 'SA', upperCase: 'SAN VICENTE PACAYA' },
      { municipio: 'Nueva Concepción', iso: 'NU', upperCase: 'NUEVA CONCEPCION' },
    ]
  },
  {
    departamento: 'Huehuetenango', iso: 'HU', upperCase: 'HUEHUETENANGO',
    municipios: [
      { municipio: 'Huehuetenango', iso: 'HU', upperCase: 'HUEHUETENANGO' },
      { municipio: 'Chiantla', iso: 'CH', upperCase: 'CHIANTLA' },
      { municipio: 'Malacatancito', iso: 'MA', upperCase: 'MALACATANCITO' },
      { municipio: 'Cuilco', iso: 'CU', upperCase: 'CUILCO' },
      { municipio: 'Nentón', iso: 'NE', upperCase: 'NENTON' },
      { municipio: 'San Pedro Necta', iso: 'SP', upperCase: 'SAN PEDRO NECTA' },
      { municipio: 'Jacaltenango', iso: 'JA', upperCase: 'JACALTENANGO' },
      { municipio: 'San Pedro Soloma', iso: 'SP', upperCase: 'SAN PEDRO SOLOMA' },
      { municipio: 'San Ildefonso Ixtahuacán', iso: 'SI', upperCase: 'SAN ILDEFONSO IXTAHUACAN' },
      { municipio: 'Santa Bárbara', iso: 'SA', upperCase: 'SANTA BARBARA' },
      { municipio: 'La Libertad', iso: 'LA', upperCase: 'LA LIBERTAD' },
      { municipio: 'La Democracia', iso: 'LA', upperCase: 'LA DEMOCRACIA' },
      { municipio: 'San Miguel Acatán', iso: 'SA', upperCase: 'SAN MIGUEL ACATAN' },
      { municipio: 'San Rafael La Independencia', iso: 'SA', upperCase: 'SAN RAFAEL LA INDEPENDENCIA' },
      { municipio: 'Todos Santos Cuchumatán', iso: 'TO', upperCase: 'TODOS SANTOS CUCHUMATAN' },
      { municipio: 'San Juan Atitán', iso: 'SJ', upperCase: 'SAN JUAN ATITAN' },
      { municipio: 'Santa Eulalia', iso: 'SA', upperCase: 'SANTA EULALIA' },
      { municipio: 'San Mateo Ixtatán', iso: 'SM', upperCase: 'SAN MATEO IXTATAN' },
      { municipio: 'Colotenango', iso: 'CO', upperCase: 'COLOTENANGO' },
      { municipio: 'San Sebastián Huehuetenango', iso: 'SA', upperCase: 'SAN SEBASTIAN HUEHUETENANGO' },
      { municipio: 'Tectitán', iso: 'TE', upperCase: 'TECTITAN' },
      { municipio: 'Concepción Huista', iso: 'CO', upperCase: 'CONCEPCION HUISTA' },
      { municipio: 'San Juan Ixcoy', iso: 'SJ', upperCase: 'SAN JUAN IXCOY' },
      { municipio: 'San Antonio Huista', iso: 'SA', upperCase: 'SAN ANTONIO HUISTA' },
      { municipio: 'San Sebastián Coatán', iso: 'SA', upperCase: 'SAN SEBASTIAN COATAN' },
      { municipio: 'Santa Cruz Barillas', iso: 'SA', upperCase: 'SANTA CRUZ BARILLAS' },
      { municipio: 'Aguacatán', iso: 'AG', upperCase: 'AGUACATAN' },
      { municipio: 'San Rafael Petzal', iso: 'SA', upperCase: 'SAN RAFAEL PETZAL' },
      { municipio: 'San Gaspar Ixchil', iso: 'SA', upperCase: 'SAN GASPAR IXCHIL' },
      { municipio: 'Santiago Chimaltenango', iso: 'SA', upperCase: 'SANTIAGO CHIMALTENANGO' },
      { municipio: 'Santa Ana Huista', iso: 'SA', upperCase: 'SANTA ANA HUISTA' },
      { municipio: 'Unión Cantinil', iso: 'UN', upperCase: 'UNION CANTINIL' },
      { municipio: 'Petatán', iso: 'PE', upperCase: 'PETATAN' },
    ]
  },
  {
    departamento: 'Izabal', iso: 'IZ', upperCase: 'IZABAL'
    , municipios: [
      { municipio: 'Puerto Barrios', iso: 'PU', upperCase: 'PUERTO BARRIOS' },
      { municipio: 'Livingston', iso: 'LI', upperCase: 'LIVINGSTON' },
      { municipio: 'El Estor', iso: 'EL', upperCase: 'EL ESTOR' },
      { municipio: 'Morales', iso: 'MO', upperCase: 'MORALES' },
      { municipio: 'Los Amates', iso: 'LO', upperCase: 'LOS AMATES' },
    ]
  },
  {
    departamento: 'Jalapa', iso: 'JA', upperCase: 'JALAPA'
    , municipios: [
      { municipio: 'Jalapa', iso: 'JA', upperCase: 'JALAPA' },
      { municipio: 'San Pedro Pinula', iso: 'SP', upperCase: 'SAN PEDRO PINULA' },
      { municipio: 'San Luis Jilotepeque', iso: 'SJ', upperCase: 'SAN LUIS JILOTEPEQUE' },
      { municipio: 'San Manuel Chaparrón', iso: 'SM', upperCase: 'SAN MANUEL CHAPARRON' },
      { municipio: 'San Carlos Alzatate', iso: 'SC', upperCase: 'SAN CARLOS ALZATATE' },
      { municipio: 'Monjas', iso: 'MO', upperCase: 'MONJAS' },
      { municipio: 'Mataquescuintla', iso: 'MA', upperCase: 'MATAQUESCUINTLA' },
    ]
  },
  {
    departamento: 'Jutiapa', iso: 'JU', upperCase: 'JUTIAPA'
    , municipios: [
      { municipio: 'Jutiapa', iso: 'JU', upperCase: 'JUTIAPA' },
      { municipio: 'El Progreso', iso: 'EL', upperCase: 'EL PROGRESO' },
      { municipio: 'Santa Catarina Mita', iso: 'SA', upperCase: 'SANTA CATARINA MITA' },
      { municipio: 'Agua Blanca', iso: 'AG', upperCase: 'AGUA BLANCA' },
      { municipio: 'Asunción Mita', iso: 'AS', upperCase: 'ASUNCION MITA' },
      { municipio: 'Yupiltepeque', iso: 'YU', upperCase: 'YUPILTEPEQUE' },
      { municipio: 'Atescatempa', iso: 'AT', upperCase: 'ATESCATEMPA' },
      { municipio: 'Jerez', iso: 'JE', upperCase: 'JEREZ' },
      { municipio: 'El Adelanto', iso: 'EL', upperCase: 'EL ADELANTO' },
      { municipio: 'Zapotitlán', iso: 'ZA', upperCase: 'ZAPOTITLAN' },
      { municipio: 'Comapa', iso: 'CO', upperCase: 'COMAPA' },
      { municipio: 'Jalpatagua', iso: 'JA', upperCase: 'JALPATAGUA' },
      { municipio: 'Conguaco', iso: 'CO', upperCase: 'CONGUACO' },
      { municipio: 'Moyuta', iso: 'MO', upperCase: 'MOYUTA' },
      { municipio: 'Pasaco', iso: 'PA', upperCase: 'PASACO' },
      { municipio: 'San José Acatempa', iso: 'SA', upperCase: 'SAN JOSE ACATEMPA' },
      { municipio: 'Quesada', iso: 'QU', upperCase: 'QUESADA' },
    ]
  },
  {
    departamento: 'Petén', iso: 'PE', upperCase: 'PETEN',
    municipios: [
      { municipio: 'Flores', iso: 'FL', upperCase: 'FLORES' },
      { municipio: 'San José', iso: 'SJ', upperCase: 'SAN JOSE' },
      { municipio: 'San Benito', iso: 'SB', upperCase: 'SAN BENITO' },
      { municipio: 'San Andrés', iso: 'SA', upperCase: 'SAN ANDRES' },
      { municipio: 'La Libertad', iso: 'LA', upperCase: 'LA LIBERTAD' },
      { municipio: 'San Francisco', iso: 'SF', upperCase: 'SAN FRANCISCO' },
      { municipio: 'Santa Ana', iso: 'SA', upperCase: 'SANTA ANA' },
      { municipio: 'Dolores', iso: 'DO', upperCase: 'DOLORES' },
      { municipio: 'San Luis', iso: 'SL', upperCase: 'SAN LUIS' },
      { municipio: 'Sayaxché', iso: 'SA', upperCase: 'SAYAXCHE' },
      { municipio: 'Melchor de Mencos', iso: 'ME', upperCase: 'MELCHOR DE MENCOS' },
      { municipio: 'Poptún', iso: 'PO', upperCase: 'POPTUN' },
      { municipio: 'Las Cruces', iso: 'LA', upperCase: 'LAS CRUCES' },
      { municipio: 'El Chal', iso: 'EL', upperCase: 'EL CHAL' },
    ]
  },
  {
    departamento: 'Quetzaltenango', iso: 'QZ', upperCase: 'QUETZALTENANGO',
    municipios: [
      { municipio: 'Quetzaltenango', iso: 'QU', upperCase: 'QUETZALTENANGO' },
      { municipio: 'Salcajá', iso: 'SA', upperCase: 'SALCAJA' },
      { municipio: 'Olintepeque', iso: 'OL', upperCase: 'OLINTEPEQUE' },
      { municipio: 'San Carlos Sija', iso: 'SA', upperCase: 'SAN CARLOS SIJA' },
      { municipio: 'Sibilia', iso: 'SI', upperCase: 'SIBILIA' },
      { municipio: 'Cabricán', iso: 'CA', upperCase: 'CABRICAN' },
      { municipio: 'Cajolá', iso: 'CA', upperCase: 'CAJOLA' },
      { municipio: 'San Miguel Siguilá', iso: 'SA', upperCase: 'SAN MIGUEL SIGUILA' },
      { municipio: 'Ostuncalco', iso: 'OS', upperCase: 'OSTUNCALCO' },
      { municipio: 'San Mateo', iso: 'SA', upperCase: 'SAN MATEO' },
      { municipio: 'Concepción Chiquirichapa', iso: 'CO', upperCase: 'CONCEPCION CHIQUIRICHAPA' },
      { municipio: 'San Martín Sacatepéquez', iso: 'SA', upperCase: 'SAN MARTIN SACATEPEQUEZ' },
      { municipio: 'Almolonga', iso: 'AL', upperCase: 'ALMOLONGA' },
      { municipio: 'Cantel', iso: 'CA', upperCase: 'CANTEL' },
      { municipio: 'Huitán', iso: 'HU', upperCase: 'HUITAN' },
      { municipio: 'Zunil', iso: 'ZU', upperCase: 'ZUNIL' },
      { municipio: 'Colomba', iso: 'CO', upperCase: 'COLOMBA' },
      { municipio: 'San Francisco La Unión', iso: 'SA', upperCase: 'SAN FRANCISCO LA UNION' },
      { municipio: 'El Palmar', iso: 'EL', upperCase: 'EL PALMAR' },
      { municipio: 'Coatepeque', iso: 'CO', upperCase: 'COATEPEQUE' },
      { municipio: 'Génova', iso: 'GE', upperCase: 'GENOVA' },
      { municipio: 'Flores Costa Cuca', iso: 'FL', upperCase: 'FLORES COSTA CUCA' },
      { municipio: 'La Esperanza', iso: 'LA', upperCase: 'LA ESPERANZA' },
      { municipio: 'Palestina de Los Altos', iso: 'PA', upperCase: 'PALESTINA DE LOS ALTOS' },
    ]
  },
  {
    departamento: 'Quiché', iso: 'QC', upperCase: 'QUICHE',
    municipios: [
      { municipio: 'Santa Cruz del Quiché', iso: 'SA', upperCase: 'SANTA CRUZ DEL QUICHE' },
      { municipio: 'Chiché', iso: 'CH', upperCase: 'CHICHE' },
      { municipio: 'Chinique', iso: 'CH', upperCase: 'CHINIQUE' },
      { municipio: 'Zacualpa', iso: 'ZA', upperCase: 'ZACUALPA' },
      { municipio: 'Chajul', iso: 'CH', upperCase: 'CHAJUL' },
      { municipio: 'Chichicastenango', iso: 'CH', upperCase: 'CHICHICASTENANGO' },
      { municipio: 'Patzité', iso: 'PA', upperCase: 'PATZITE' },
      { municipio: 'San Antonio Ilotenango', iso: 'SA', upperCase: 'SAN ANTONIO ILOTENANGO' },
      { municipio: 'San Pedro Jocopilas', iso: 'SA', upperCase: 'SAN PEDRO JOCOPILAS' },
      { municipio: 'Cunén', iso: 'CU', upperCase: 'CUNEN' },
      { municipio: 'San Juan Cotzal', iso: 'SA', upperCase: 'SAN JUAN COTZAL' },
      { municipio: 'Joyabaj', iso: 'JO', upperCase: 'JOYABAJ' },
      { municipio: 'Nebaj', iso: 'NE', upperCase: 'NEBAJ' },
      { municipio: 'San Andrés Sajcabajá', iso: 'SA', upperCase: 'SAN ANDRES SAJCABAJA' },
      { municipio: 'Uspantán', iso: 'US', upperCase: 'USPANTAN' },
      { municipio: 'Sacapulas', iso: 'SA', upperCase: 'SACAPULAS' },
      { municipio: 'San Bartolomé Jocotenango', iso: 'SA', upperCase: 'SAN BARTOLOME JOCOTENANGO' },
      { municipio: 'Canillá', iso: 'CA', upperCase: 'CANILLA' },
      { municipio: 'Chicamán', iso: 'CH', upperCase: 'CHICAMAN' },
      { municipio: 'Playa Grande - Ixcán', iso: 'PL', upperCase: 'PLAYA GRANDE - IXCAN' },
    ]
  },
  {
    departamento: 'Retalhuleu', iso: 'RE', upperCase: 'RETALHULEU',
    municipios: [
      { municipio: 'Retalhuleu', iso: 'RE', upperCase: 'RETALHULEU' },
      { municipio: 'San Sebastián', iso: 'SA', upperCase: 'SAN SEBASTIAN' },
      { municipio: 'Santa Cruz Muluá', iso: 'SA', upperCase: 'SANTA CRUZ MULUA' },
      { municipio: 'San Martín Zapotitlán', iso: 'SA', upperCase: 'SAN MARTIN ZAPOTITLAN' },
      { municipio: 'San Felipe', iso: 'SA', upperCase: 'SAN FELIPE' },
      { municipio: 'San Andrés Villa Seca', iso: 'SA', upperCase: 'SAN ANDRES VILLA SECA' },
      { municipio: 'Champerico', iso: 'CH', upperCase: 'CHAMPERICO' },
      { municipio: 'Nuevo San Carlos', iso: 'NU', upperCase: 'NUEVO SAN CARLOS' },
      { municipio: 'El Asintal', iso: 'EL', upperCase: 'EL ASINTAL' },
    ]
  },
  {
    departamento: 'Sacatepéquez', iso: 'SA', upperCase: 'SACATEPEQUEZ',
    municipios: [
      { municipio: 'Antigua Guatemala', iso: 'AN', upperCase: 'ANTIGUA GUATEMALA' },
      { municipio: 'Jocotenango', iso: 'JO', upperCase: 'JOCOTENANGO' },
      { municipio: 'Pastores', iso: 'PA', upperCase: 'PASTORES' },
      { municipio: 'Sumpango', iso: 'SU', upperCase: 'SUMPANGO' },
      { municipio: 'Santo Domingo Xenacoj', iso: 'SA', upperCase: 'SANTO DOMINGO XENACOJ' },
      { municipio: 'Santiago Sacatepéquez', iso: 'SA', upperCase: 'SANTIAGO SACATEPEQUEZ' },
      { municipio: 'San Bartolomé Milpas Altas', iso: 'SA', upperCase: 'SAN BARTOLOME MILPAS ALTAS' },
      { municipio: 'San Lucas Sacatepéquez', iso: 'SA', upperCase: 'SAN LUCAS SACATEPEQUEZ' },
      { municipio: 'Santa Lucía Milpas Altas', iso: 'SA', upperCase: 'SANTA LUCIA MILPAS ALTAS' },
      { municipio: 'Magdalena Milpas Altas', iso: 'MA', upperCase: 'MAGDALENA MILPAS ALTAS' },
      { municipio: 'Santa María de Jesús', iso: 'SA', upperCase: 'SANTA MARIA DE JESUS' },
      { municipio: 'Ciudad Vieja', iso: 'CI', upperCase: 'CIUDAD VIEJA' },
      { municipio: 'San Miguel Dueñas', iso: 'SA', upperCase: 'SAN MIGUEL DUENAS' },
      { municipio: 'Alotenango', iso: 'AL', upperCase: 'ALOTENANGO' },
      { municipio: 'San Antonio Aguas Calientes', iso: 'SA', upperCase: 'SAN ANTONIO AGUAS CALIENTES' },
    ]
  },
  {
    departamento: 'San Marcos', iso: 'SM', upperCase: 'SAN MARCOS',
    municipios: [
      { municipio: 'San Marcos', iso: 'SA', upperCase: 'SAN MARCOS' },
      { municipio: 'San Pedro Sacatepéquez', iso: 'SA', upperCase: 'SAN PEDRO SACATEPEQUEZ' },
      { municipio: 'San Antonio Sacatepéquez', iso: 'SA', upperCase: 'SAN ANTONIO SACATEPEQUEZ' },
      { municipio: 'Comitancillo', iso: 'CO', upperCase: 'COMITANCILLO' },
      { municipio: 'San Miguel Ixtahuacán', iso: 'SA', upperCase: 'SAN MIGUEL IXTAHUACAN' },
      { municipio: 'Concepción Tutuapa', iso: 'CO', upperCase: 'CONCEPCION TUTUAPA' },
      { municipio: 'Tacaná', iso: 'TA', upperCase: 'TACANA' },
      { municipio: 'Sibinal', iso: 'SI', upperCase: 'SIBINAL' },
      { municipio: 'Tajumulco', iso: 'TA', upperCase: 'TAJUMULCO' },
      { municipio: 'Tejutla', iso: 'TE', upperCase: 'TEJUTLA' },
      { municipio: 'San Rafael Pie de La Cuesta', iso: 'SA', upperCase: 'SAN RAFAEL PIE DE LA CUESTA' },
      { municipio: 'Nuevo Progreso', iso: 'NU', upperCase: 'NUEVO PROGRESO' },
      { municipio: 'El Tumbador', iso: 'EL', upperCase: 'EL TUMBADOR' },
      { municipio: 'San José Ojetenam', iso: 'SA', upperCase: 'SAN JOSE OJETENAM' },
      { municipio: 'Ixchiguán', iso: 'IX', upperCase: 'IXCHIGUAN' },
      { municipio: 'San Pablo', iso: 'SA', upperCase: 'SAN PABLO' },
      { municipio: 'El Rodeo', iso: 'EL', upperCase: 'EL RODEO' },
      { municipio: 'Malacatán', iso: 'MA', upperCase: 'MALACATAN' },
      { municipio: 'Catarina', iso: 'CA', upperCase: 'CATARINA' },
      { municipio: 'Ayutla', iso: 'AY', upperCase: 'AYUTLA' },
      { municipio: 'Ocos', iso: 'OC', upperCase: 'OCOS' },
      { municipio: 'San Pablo', iso: 'SA', upperCase: 'SAN PABLO' },
      { municipio: 'El Quetzal', iso: 'EL', upperCase: 'EL QUETZAL' },
      { municipio: 'La Reforma', iso: 'LA', upperCase: 'LA REFORMA' },
      { municipio: 'Pajapita', iso: 'PA', upperCase: 'PAJAPITA' },
      { municipio: 'Ixchiguán', iso: 'IX', upperCase: 'IXCHIGUAN' },
      { municipio: 'San José Ojetenam', iso: 'SA', upperCase: 'SAN JOSE OJETENAM' },
      { municipio: 'San Cristóbal Cucho', iso: 'SA', upperCase: 'SAN CRISTOBAL CUCHO' },
      { municipio: 'Sipacapa', iso: 'SI', upperCase: 'SIPACAPA' },
      { municipio: 'Esquipulas Palo Gordo', iso: 'ES', upperCase: 'ESQUIPULAS PALO GORDO' },
      { municipio: 'Río Blanco', iso: 'RI', upperCase: 'RIO BLANCO' },
      { municipio: 'San Lorenzo', iso: 'SA', upperCase: 'SAN LORENZO' },
      { municipio: 'La Blanca', iso: 'LA', upperCase: 'LA BLANCA' },
    ]
  },
  {
    departamento: 'Santa Rosa', iso: 'SR', upperCase: 'SANTA ROSA',
    municipios: [
      { municipio: 'Cuilapa', iso: 'CU', upperCase: 'CUILAPA' },
      { municipio: 'Barberena', iso: 'BA', upperCase: 'BARBERENA' },
      { municipio: 'Santa Rosa de Lima', iso: 'SA', upperCase: 'SANTA ROSA DE LIMA' },
      { municipio: 'Casillas', iso: 'CA', upperCase: 'CASILLAS' },
      { municipio: 'San Rafael Las Flores', iso: 'SA', upperCase: 'SAN RAFAEL LAS FLORES' },
      { municipio: 'Oratorio', iso: 'OR', upperCase: 'ORATORIO' },
      { municipio: 'San Juan Tecuaco', iso: 'SA', upperCase: 'SAN JUAN TECUACO' },
      { municipio: 'Chiquimulilla', iso: 'CH', upperCase: 'CHIQUIMULILLA' },
      { municipio: 'Taxisco', iso: 'TA', upperCase: 'TAXISCO' },
      { municipio: 'Santa María Ixhuatán', iso: 'SA', upperCase: 'SANTA MARIA IXHUATAN' },
      { municipio: 'Guazacapán', iso: 'GU', upperCase: 'GUAZACAPAN' },
      { municipio: 'Santa Cruz Naranjo', iso: 'SA', upperCase: 'SANTA CRUZ NARANJO' },
      { municipio: 'Pueblo Nuevo Viñas', iso: 'PU', upperCase: 'PUEBLO NUEVO VINAS' },
      { municipio: 'Nueva Santa Rosa', iso: 'NU', upperCase: 'NUEVA SANTA ROSA' },
    ]
  },
  {
    departamento: 'Sololá', iso: 'SO', upperCase: 'SOLOLA'
    , municipios: [
      { municipio: 'Nahualá', iso: 'NA', upperCase: 'NAHUALA' },
      { municipio: 'Santa Catarina Ixtahuacán', iso: 'SA', upperCase: 'SANTA CATARINA IXTAHUACAN' },
      { municipio: 'Santa Lucía Utatlán', iso: 'SA', upperCase: 'SANTA LUCIA UTATLAN' },
      { municipio: 'Santa Clara La Laguna', iso: 'SA', upperCase: 'SANTA CLARA LA LAGUNA' },
      { municipio: 'Concepción', iso: 'CO', upperCase: 'CONCEPCION' },
      { municipio: 'San Andrés Semetabaj', iso: 'SA', upperCase: 'SAN ANDRES SEMETABAJ' },
      { municipio: 'Panajachel', iso: 'PA', upperCase: 'PANAJACHEL' },
      { municipio: 'Santa Catarina Palopó', iso: 'SA', upperCase: 'SANTA CATARINA PALOPO' },
      { municipio: 'San Antonio Palopó', iso: 'SA', upperCase: 'SAN ANTONIO PALOPO' },
      { municipio: 'San José Chacayá', iso: 'SA', upperCase: 'SAN JOSE CHACAYA' },
      { municipio: 'San Juan La Laguna', iso: 'SA', upperCase: 'SAN JUAN LA LAGUNA' },
      { municipio: 'San Pablo La Laguna', iso: 'SA', upperCase: 'SAN PABLO LA LAGUNA' },
      { municipio: 'San Marcos La Laguna', iso: 'SA', upperCase: 'SAN MARCOS LA LAGUNA' },
      { municipio: 'San Pedro La Laguna', iso: 'SA', upperCase: 'SAN PEDRO LA LAGUNA' },
      { municipio: 'Santiago Atitlán', iso: 'SA', upperCase: 'SANTIAGO ATITLAN' },
      { municipio: 'Sololá', iso: 'SO', upperCase: 'SOLOLA' },
      { municipio: 'San Lucas Tolimán', iso: 'SA', upperCase: 'SAN LUCAS TOLIMAN' },
      { municipio: 'Santa María Visitación', iso: 'SA', upperCase: 'SANTA MARIA VISITACION' },
      { municipio: 'Santa Bárbara', iso: 'SA', upperCase: 'SANTA BARBARA' },
    ]
  },
  {
    departamento: 'Suchitepéquez', iso: 'SU', upperCase: 'SUCHITEPEQUEZ'
    , municipios: [
      { municipio: 'Mazatenango', iso: 'MA', upperCase: 'MAZATENANGO' },
      { municipio: 'Cuyotenango', iso: 'CU', upperCase: 'CUYOTENANGO' },
      { municipio: 'San Francisco Zapotitlán', iso: 'SA', upperCase: 'SAN FRANCISCO ZAPOTITLAN' },
      { municipio: 'San Bernardino', iso: 'SA', upperCase: 'SAN BERNARDINO' },
      { municipio: 'San José El Ídolo', iso: 'SA', upperCase: 'SAN JOSE EL IDOLO' },
      { municipio: 'Santo Domingo Suchitepéquez', iso: 'SA', upperCase: 'SANTO DOMINGO SUCHITEPEQUEZ' },
      { municipio: 'San Lorenzo', iso: 'SA', upperCase: 'SAN LORENZO' },
      { municipio: 'Samayac', iso: 'SA', upperCase: 'SAMAYAC' },
      { municipio: 'San Pablo Jocopilas', iso: 'SA', upperCase: 'SAN PABLO JOCOPILAS' },
      { municipio: 'San Antonio Suchitepéquez', iso: 'SA', upperCase: 'SAN ANTONIO SUCHITEPEQUEZ' },
      { municipio: 'San Miguel Panán', iso: 'SA', upperCase: 'SAN MIGUEL PANAN' },
      { municipio: 'San Gabriel', iso: 'SA', upperCase: 'SAN GABRIEL' },
      { municipio: 'Chicacao', iso: 'CH', upperCase: 'CHICACAO' },
      { municipio: 'Patulul', iso: 'PA', upperCase: 'PATULUL' },
      { municipio: 'Santa Bárbara', iso: 'SA', upperCase: 'SANTA BARBARA' },
      { municipio: 'San Juan Bautista', iso: 'SA', upperCase: 'SAN JUAN BAUTISTA' },
      { municipio: 'Santo Tomás La Unión', iso: 'SA', upperCase: 'SANTO TOMAS LA UNION' },
      { municipio: 'Zunilito', iso: 'ZU', upperCase: 'ZUNILITO' },
      { municipio: 'Pueblo Nuevo', iso: 'PU', upperCase: 'PUEBLO NUEVO' },
      { municipio: 'Río Bravo', iso: 'RI', upperCase: 'RIO BRAVO' },
    ]
  },
  {
    departamento: 'Totonicapán', iso: 'TO', upperCase: 'TOTONICAPAN'
    , municipios: [
      { municipio: 'Totonicapán', iso: 'TO', upperCase: 'TOTONICAPAN' },
      { municipio: 'San Cristóbal Totonicapán', iso: 'SA', upperCase: 'SAN CRISTOBAL TOTONICAPAN' },
      { municipio: 'San Francisco El Alto', iso: 'SA', upperCase: 'SAN FRANCISCO EL ALTO' },
      { municipio: 'San Andrés Xecul', iso: 'SA', upperCase: 'SAN ANDRES XECUL' },
      { municipio: 'Momostenango', iso: 'MO', upperCase: 'MOMOSTENANGO' },
      { municipio: 'Santa María Chiquimula', iso: 'SA', upperCase: 'SANTA MARIA CHIQUIMULA' },
      { municipio: 'Santa Lucía La Reforma', iso: 'SA', upperCase: 'SANTA LUCIA LA REFORMA' },
      { municipio: 'San Bartolo', iso: 'SA', upperCase: 'SAN BARTOLO' },
    ]
  },
  {
    departamento: 'Zacapa', iso: 'ZA', upperCase: 'ZACAPA'
    , municipios: [
      { municipio: 'Zacapa', iso: 'ZA', upperCase: 'ZACAPA' },
      { municipio: 'Estanzuela', iso: 'ES', upperCase: 'ESTANZUELA' },
      { municipio: 'Río Hondo', iso: 'RI', upperCase: 'RIO HONDO' },
      { municipio: 'Gualán', iso: 'GU', upperCase: 'GUALAN' },
      { municipio: 'Teculután', iso: 'TE', upperCase: 'TECULUTAN' },
      { municipio: 'Usumatlán', iso: 'US', upperCase: 'USUMATLAN' },
      { municipio: 'Cabañas', iso: 'CA', upperCase: 'CABANAS' },
      { municipio: 'San Diego', iso: 'SA', upperCase: 'SAN DIEGO' },
      { municipio: 'La Unión', iso: 'LA', upperCase: 'LA UNION' },
      { municipio: 'Huité', iso: 'HU', upperCase: 'HUITE' },
      { municipio: 'San Jorge', iso: 'SA', upperCase: 'SAN JORGE' },
      { municipio: 'Tizoja', iso: 'TI', upperCase: 'TIZOJA' },
    ]
  },

]

export default function PayWithLink() {
  const classes = useStyles();
  const params = useParams<{ payCode: string }>()
  const history = useHistory();
  const { handleErrorMessage, handleActiveError, loading, handleLoading, handleActiveSuccess, handleSuccessMessage, handleDeviceDataCollectionUrl } = useContext(GeneralContext)

  const [values, setValues] = useState<valuesInterface3dsTransaction>({
    numeroTarjeta: '',
    nombreTitular: '',
    expiracion: '',
    expiracionFormatoVisa: '',
    cvv: '',
    nit: '',
    email: '',
    numeroDeVisaCuotas: '',
    nombre: '',
    apellido: '',
    concepto: '',
    monto: 0,
    marcaTarjeta: '',
    montoString: '0',
    compania: '',
    direccionUno: '',
    direccionDos: '',
    localidad: '',
    areaAdministrativa: '',
    codigoPostal: '',
    pais: 'GT',
    numeroTelefonico: '',
  });

  // const [values, setValues] = useState<valuesInterface3dsTransaction>({
  //   numeroTarjeta: '4000 0000 0000 0416',
  //   // numeroTarjeta: '2223 0000 1002 5549',
  //   nombreTitular: 'ALEJANDRO SANTIZO',
  //   expiracion: '01/24',
  //   expiracionFormatoVisa: '',
  //   nombre: 'Jose',
  //   cvv: '123',
  //   nit: '123456',
  //   email: 'josesantizo148@gmail.com',
  //   numeroDeVisaCuotas: '',
  //   apellido: 'cotto',

  //   // Datos de link rapido
  //   concepto: 'test',
  //   monto: 0,
  //   marcaTarjeta: "",
  //   montoString: '0',

  //   compania: '',
  //   direccionUno: '7ma calle 11-52 zona 11 col roosevelt',
  //   direccionDos: '',
  //   localidad: '',
  //   areaAdministrativa: '',
  //   codigoPostal: '01010',
  //   pais: 'GT',
  //   numeroTelefonico: '50311501',
  // });

  const [width, setWidth] = useState(window.innerWidth);

  const [monto, setMonto] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [pagoRapido, setEsPagoRapido] = React.useState(false);
  const [nombreEmpresa, setNombreEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [cantidadCuotas, setCantidadCuotas] = React.useState('');

  const [enlacePagado, setEnlacePagado] = React.useState(false);
  const [enlaceCaducado, setEnlaceCaducado] = React.useState(false);
  const [enlaceAnulado, setEnlaceAnulado] = React.useState(false);
  const [enlaceCancelado, setEnlaceCancelado] = React.useState(false);

  const [typeCard, setTypeCard] = React.useState<string | undefined>('');
  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const [mostrarIFrame, setMostrarIFrame] = useState<boolean>(false);
  const [AccessToken, setAccessToken] = useState<string>('');
  const [DeviceDataCollectionUrl, setDeviceDataCollectionUrl] = useState<string>('');
  const [municipiosActuales, setMunicipiosActuales] = useState<{ municipio: string, iso: string, upperCase: string }[]>([]);
  const [departamentoActual, setDepartamentoActual] = useState<string>('');
  const [mostrarIframe, setMostrarIframe] = useState<boolean>(false);
  const [hashPagoEnlace, setHashPagoEnlace] = useState<string>('');
  const [negocioId, setNegocioId] = useState(null); 

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {

    async function getLinkInfo() {

      let temp = values.numeroTarjeta.replace(/\s+/g, '');

      let tarjeta = ValidationFields.instance.creditCardType(temp)
      setTypeCard(tarjeta)

      handleLoading(true)
      if (params.payCode !== undefined) {
        let result = await PayWithLinkService.instance.getEnlaceInfo(params.payCode) as any
        if (!result.error) {
          if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.pagado) {
            setEnlacePagado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.anulado) {
            setEnlaceAnulado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.caducado) {
            setEnlaceCaducado(true)
            handleLoading(false)
            return
          } else if (result && result.result && result.result.status && result.result.status == estadosEnlace.instance.cancelado) {
            setEnlaceCancelado(true)
            handleLoading(false)
            return
          }

          setMonto(result.result.monto)
          setDescripcion(result.result.concepto)
          setEsPagoRapido(result.result.rapido)
          setNombreEmpresa(result.result.negocio.nombre)
          setEmail(result.result.usuario.email)
          setTelefono(result.result.negocio.telefono)
          setDireccion(result.result.negocio.numeroCasa)
          setNegocioId(result.result.NegocioId)
          setValues({ ...values, email: result.result.email, numeroDeVisaCuotas: result.result.numeroDeVisaCuotas });

          setValues({
            numeroTarjeta: values.numeroTarjeta,
            nombreTitular: values.nombreTitular,
            expiracion: values.expiracion,
            nombre: values.nombre,
            cvv: values.cvv,
            nit: values.nit,
            email: values.email,
            numeroDeVisaCuotas: result.result.numeroDeVisaCuotas ?? '',
            apellido: values.apellido,

            // Datos de link rapido
            concepto: values.concepto,
            monto: values.monto,
            marcaTarjeta: values.marcaTarjeta,
            montoString: values.montoString,
            expiracionFormatoVisa: '',
            compania: '',
            direccionUno: values.direccionUno,
            direccionDos: values.direccionDos,
            localidad: values.localidad,
            areaAdministrativa: values.areaAdministrativa,
            codigoPostal: values.codigoPostal,
            pais: values.pais,
            numeroTelefonico: values.numeroTelefonico,
          })

          switch (result.result.numeroDeVisaCuotas) {
            case 'VC02':
              setCantidadCuotas('2 cuotas');
              break;
            case 'VC03':
              setCantidadCuotas('3 cuotas');
              break;
            case 'VC06':
              setCantidadCuotas('6 cuotas');
              break;
            case 'VC10':
              setCantidadCuotas('10 cuotas');
              break;
            case 'VC12':
              setCantidadCuotas('12 cuotas');
              break;
            case 'VC15':
              setCantidadCuotas('15 cuotas');
              break;
            case 'VC18':
              setCantidadCuotas('18 cuotas');
              break;
            case 'VC24':
              setCantidadCuotas('24 cuotas');
              break;
            case 'VC36':
              setCantidadCuotas('36 cuotas');
              break;
            case 'VC48':
              setCantidadCuotas('48 cuotas');
              break;
            default:
              setCantidadCuotas('Contado');
              break;
          }

          let fechaGuatemala = momentZone.tz(moment(), 'America/Guatemala').locale('es').format('LLLL')

          setFecha(fechaGuatemala.toString())
        } else {
          handleErrorMessage(result.message)
          handleActiveError(true)
        }
        handleLoading(false)
      }
    }

    getLinkInfo()

  }, [])

  useEffect(() => {
    const execute = async () => {
      const stepUpForm = document.querySelector('#step_up_form_3ds_paso1') as any;

      if (stepUpForm) {
        await stepUpForm.submit();
        await payLinkPaso3()
        handleLoading(false)
      }

    }
    if (mostrarIFrame) execute()
  }, [mostrarIFrame])


  const validarFechaPago = (fechaIngresada: string): { error: boolean, message: string } => {
    // Verifica si la fecha ingresada tiene el formato MM/YY
    if (!/^\d{2}\/\d{2}$/.test(fechaIngresada)) {
      return { error: true, message: 'El formato de la fecha de expiración de la tarjeta no es válido' }
    }

    // Parsea la fecha ingresada
    const partesFecha = fechaIngresada.split('/');
    const mes = parseInt(partesFecha[0], 10);
    const anio = parseInt(partesFecha[1], 10);

    // Verifica si el mes es válido (de 1 a 12)
    if (mes < 1 || mes > 12) {
      return { error: true, message: 'El mes de la fecha de expiración de la tarjeta no es válido' };
    }

    // Obtiene la fecha actual
    const fechaActual = moment();

    // Define la fecha límite como el último día del mes ingresado
    const fechaLimite = moment().year(anio + 2000).month(mes - 1).endOf('month');

    // Compara la fecha ingresada con la fecha 
    if (fechaActual.isBefore(fechaLimite)) {
      return { error: false, message: 'La fecha de expiración de la tarjeta es válida' }
      // La fecha ingresada es válida
    }

    return { error: true, message: 'La fecha de expiración de la tarjeta no es válida' }; // La fecha ingresada no es válida
  }

  const validarTarjetaCredito = (number: string): boolean => {
    number = number.replace(/\s+/g, '');  // Elimina los espacios en blanco.
    // Convierte la cadena de caracteres en un array de dígitos.
    const digits = number.split('').map((char) => parseInt(char));

    let sum = 0;
    for (let i = digits.length - 1; i >= 0; i--) {
      let digit = digits[i];

      // Si el índice es impar (cuenta desde 0), duplica el dígito.
      if ((digits.length - 1 - i) % 2 === 1) {
        digit *= 2;
        // Si el doble es mayor que 9, resta 9.
        if (digit > 9) {
          digit -= 9;
        }
      }

      // Suma el dígito al acumulador.
      sum += digit;
    }

    // Si la suma total es un múltiplo de 10, el número es válido.
    return sum % 10 === 0;
  };

  const validateEmail = (email: string): boolean => {
    // Expresión regular para validar direcciones de correo electrónico.
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const payLinkPaso1 = async () => {
    let tempValues = {...values}

    if (values.nombre.trim() == '') {
      handleErrorMessage('Ingrese el nombre del nombre para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.apellido.trim() == '') {
      handleErrorMessage('Ingrese el apellido del nombre para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.direccionUno.trim() == '') {
      handleErrorMessage('Ingrese la dirección uno para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.localidad == '' && !isSuperIntendencia) {
      handleErrorMessage('Ingrese la localidad para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.nit.trim() == '' && !isSuperIntendencia) {
      handleErrorMessage('Ingrese el DPI o NIT para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.codigoPostal.trim() == '') {
      handleErrorMessage('Ingrese un código postal para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.codigoPostal.trim().length < 5) {
      handleErrorMessage('Ingrese un código postal valido para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.numeroTelefonico.trim() == '') {
      handleErrorMessage('Ingrese un número telefónico para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.numeroTelefonico.trim().length < 8) {
      handleErrorMessage('Ingrese un número telefónico valido para los datos de facturación')
      handleActiveError(true)
      return
    } else if (values.email == '') {
      handleErrorMessage('Ingrese el correo electrónico para los datos de facturación')
      handleActiveError(true)
      return
    } else if (validateEmail(values.email) == false) {
      handleErrorMessage('Ingrese un correo electrónico válido')
      handleActiveError(true)
      return
    } else if (values.numeroTarjeta == '') {
      handleErrorMessage('Ingrese el número de la tarjeta')
      handleActiveError(true)
      return
    } else if (values.numeroTarjeta.length < 19) {
      handleErrorMessage('Ingrese el número de la tarjeta completo')
      handleActiveError(true)
      return
    } else if (validarTarjetaCredito(values.numeroTarjeta) == false) {
      handleErrorMessage('Ingrese un número de tarjeta válido')
      handleActiveError(true)
      return
    } else if (values.nombreTitular.trim() == '') {
      handleErrorMessage('Ingrese el nombre del titular de la tarjeta')
      handleActiveError(true)
      return
    } else if (values.expiracion == '') {
      handleErrorMessage('Ingrese la fecha de expiración de la tarjeta')
      handleActiveError(true)
      return
    } else if (validarFechaPago(values.expiracion).error) {
      handleErrorMessage(validarFechaPago(values.expiracion).message)
      handleActiveError(true)
      return
    } else if (values.cvv == '') {
      handleErrorMessage('Ingrese el CVV de la tarjeta')
      handleActiveError(true)
      return
    } else if (typeCard != 'VISA' && typeCard != 'MASTERCARD') {
      handleErrorMessage('Ingrese una tarjeta valida')
      handleActiveError(true)
      return
    }
    if (values.compania.trim() == '') tempValues.compania = 'NA'
    if (values.direccionDos.trim() == '') tempValues.direccionDos = ''

    // tempValues.numeroTarjeta = tempValues.numeroTarjeta.trim().replace(/\s+/g, '')
    
    tempValues.marcaTarjeta = typeCard as string;

    let expiracionSeparado = tempValues.expiracion.split('/')
    let mes = expiracionSeparado[0]
    let anio = expiracionSeparado[1]
    tempValues.expiracionFormatoVisa = anio + mes

    if (monto.toString() != '' && (typeof monto) == 'number') tempValues.montoString = monto.toFixed(2).replace('.', '')
    else tempValues.montoString = monto.toString().replace('.', '')

    tempValues.numeroDeVisaCuotas = tempValues.numeroDeVisaCuotas.trim().replaceAll('VC01', '')
    tempValues.nombre = tempValues.nombre.trim()
    tempValues.nit = tempValues.nit.trim()
    tempValues.email = tempValues.email.trim()
    tempValues.nombreTitular = tempValues.nombreTitular.trim()
    tempValues.nombre = tempValues.nombre.replaceAll('ñ', 'n').replaceAll('Ñ', 'N').trim()
    tempValues.apellido = tempValues.apellido.replaceAll('ñ', 'n').replaceAll('Ñ', 'N').trim()

    handleLoading(true)

    let req = await PayWithLinkService.instance.TransaccionPayfacRest(params.payCode, tempValues)

    if (!req.error) {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
      } else {
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        handleActiveSuccess(true)
      }
    } else {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
      } else {
        handleErrorMessage(req.message)
      }
      handleActiveError(true)
    }

    handleLoading(false)
  }

  const payLinkPaso3 = async () => {

    handleLoading(true)

    let req = await PayWithLinkService.instance.Paso3Epayserver3dSecure(hashPagoEnlace)

    if (!req.error) {
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
      } else if (req && req.result && req.result.PayerAuthentication && req.result.PayerAuthentication.Step && req.result.PayerAuthentication.Step != '3') {
        handleDeviceDataCollectionUrl(req.result.PayerAuthentication.DeviceDataCollectionUrl)
        setMostrarIFrame(true)

        history.push(`/payWithLinkV2Paso4/${req.result.PayerAuthentication.AccessToken}`);
      } else {
        setValues({
          ...values,
          numeroTarjeta: '',
          nombreTitular: '',
          expiracion: '',
          nombre: '',
          cvv: '',
          nit: '',

          // Datos de link rapido
          concepto: '',
          monto: 0,
        })
        setShowConfirmComponent(true);
        handleSuccessMessage(req.message);
        handleActiveSuccess(true)
      }
    } else {
      console.log('error aquiii')
      if (req.message === 'TypeError: Failed to fetch' || req.message.includes('TypeError: Failed to fetch')) {
        handleErrorMessage('Error de conexion por favor intente mas tarde')
      } else {
        handleErrorMessage(req.message)
      }
      handleActiveError(true)
    }
    handleLoading(false)
    setMostrarIFrame(false)
  }

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeNumberCard = (prop: any) => (event: any) => {

    let temp = event.target.value

    setValues({ ...values, [prop]: event.target.value });

    temp = temp.replace(/\s+/g, '');

    let tarjeta = ValidationFields.instance.creditCardType(temp)

    setTypeCard(tarjeta)
  };

  const onChangeLocalidad = (upperCaseDepartamento: string) => {
    const departamento = departamentoGuatemala.find((item) => item.upperCase === upperCaseDepartamento);
    if (!departamento) return;
    setDepartamentoActual(departamento.upperCase);
    setValues({
      ...values,
      areaAdministrativa: departamento.iso,
    });
    setMunicipiosActuales(departamento.municipios);
  };

  const onChangeMunicipio = (municipio: string) => {
    setValues({
      ...values,
      localidad: municipio,
    });
  };

  const isSuperIntendencia = process.env.REACT_APP_ID_SUPERINTENDENCIA
  ? Number(process.env.REACT_APP_ID_SUPERINTENDENCIA) === Number(negocioId)
  : false;


  const nombre = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Nombre<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography>
        </InputLabel>
        <OutlinedInput
          type='text'
          name="name"
          autoComplete="name"
          value={values.nombre}
          onChange={handleChange('nombre')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const apellido = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Apellido<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="apellido"
          autoComplete="apellido"
          value={values.apellido}
          onChange={handleChange('apellido')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const direccionUno = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección uno<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="direccionUno"
          autoComplete="direccionUno"
          value={values.direccionUno}
          onChange={handleChange('direccionUno')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const direccionDos = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Dirección dos</InputLabel>
        <OutlinedInput
          type='text'
          name="direccionDos"
          autoComplete="direccionDos"
          value={values.direccionDos}
          onChange={handleChange('direccionDos')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const compania = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Compañía</InputLabel>
        <OutlinedInput
          type='text'
          name="compania"
          autoComplete="compania"
          value={values.compania}
          onChange={handleChange('compania')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const localidad = () => {
    return (
      <FormControl fullWidth={true} variant='outlined' style={{
        minWidth: 120,
      }}>
        <InputLabel variant='filled' id="demo-simple-select-outlined-label">Departamento
        {!isSuperIntendencia && <Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography>}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={departamentoActual}
          defaultValue={departamentoActual}
          onChange={(event: any) => onChangeLocalidad(event.target.value)}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {
            departamentoGuatemala.map((item, index) => {
              return (
                <MenuItem key={index} value={item.upperCase}>{item.upperCase}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  const municipio = () => {
    return (
      <FormControl fullWidth={true} variant='outlined' style={{
        minWidth: 120,
      }}>
        <InputLabel variant='filled' id="demo-simple-select-outlined-label">Municipio
        {!isSuperIntendencia && <Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography>}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.localidad}
          defaultValue={values.localidad}
          onChange={(event: any) => onChangeMunicipio(event.target.value)}
          style={{
            width: '100%',
            paddingTop: '1%',
          }}
        >
          {
            municipiosActuales.map((item, index) => {
              return (
                <MenuItem key={index} value={item.upperCase}>{item.upperCase}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  const codigoPostal = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Código Postal<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          name="codigoPostal"
          autoComplete="codigoPostal"
          value={values.codigoPostal}
          onChange={handleChange('codigoPostal')}
          className={classes.inputPadding}
          inputComponent={CodigoPostalMask}
        />
      </FormControl>
    )
  }

  const numeroTelefonico = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Número Telefónico<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='tel'
          name="numeroTelefonico"
          autoComplete="numeroTelefonico"
          value={values.numeroTelefonico}
          onChange={handleChange('numeroTelefonico')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const conceptoInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Concepto</InputLabel>
        <OutlinedInput
          type='text'
          value={values.concepto}
          onChange={handleChange('concepto')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  useEffect(() => {
    if (isSuperIntendencia) {
      setMonto(3900);
    }
  }, [isSuperIntendencia]);

  const montoInput = () => {
    const value = monto == 0 ? '' : monto;
    const valueAsText = isSuperIntendencia ? '$500' : value
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Monto</InputLabel>
        <OutlinedInput
          type={isSuperIntendencia ? 'text' : 'number'}
          disabled={isSuperIntendencia}
          value={valueAsText}
          onChange={e => {
            let montoInt = parseFloat(e.target.value)
            setMonto(montoInt)
          }}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const emailInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Correo electrónico<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='email'
          name="email"
          autoComplete="email"
          value={values.email}
          onChange={handleChange('email')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const nitInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>DPI o NIT
        {!isSuperIntendencia && <Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography>}
        </InputLabel>
        <OutlinedInput
          type='text'
          value={values.nit}
          onChange={handleChange('nit')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const expiracionInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Expiración MM/YY<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          autoComplete="cc-exp"
          value={values.expiracion}
          onChange={handleChange('expiracion')}
          className={classes.inputPadding}
          inputComponent={ExpirationMask}
        />
      </FormControl>
    )
  }

  const numeroTarjetaInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Número de tarjeta<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          value={values.numeroTarjeta}
          onChange={handleChangeNumberCard('numeroTarjeta')}
          className={classes.inputPadding}
          inputComponent={CardNumberMask}
          endAdornment={
            typeCard !== undefined && typeCard === 'VISA' ?
              <FaCcVisa size={30} />
              :
              typeCard !== undefined && typeCard === 'MASTERCARD' ?
                <FaCcMastercard size={30} />
                :
                null
          }
        />
      </FormControl>
    )
  }

  const nombreTitularInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>Nombre del titular<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type='text'
          autoComplete="cc-name"
          value={values.nombreTitular}
          onChange={handleChange('nombreTitular')}
          className={classes.inputPadding}
        />
      </FormControl>
    )
  }

  const cvvInput = () => {
    return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel variant='filled'>CVV<Typography
          variant='subtitle2'
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          *
        </Typography></InputLabel>
        <OutlinedInput
          type="number"
          value={values.cvv}
          autoComplete="cc-csc"
          onChange={handleChange('cvv')}
          className={classes.inputPadding}
          inputComponent={CvvMask}
        />
      </FormControl>
    )
  }

  return (
    <>
      {
        mostrarIFrame &&
        (
          <div >
            <iframe
              id="step_up_iframe_3ds_paso1"
              style={{
                border: 'none',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
              }}
              height="800"
              width="100%"
              name="stepUpIframe"
            />
            <form
              id="step_up_form_3ds_paso1"
              name="stepup"
              method="POST"
              target="stepUpIframe"
              action={DeviceDataCollectionUrl}
            >
              <input
                id="step_up_form_jwt_input_3ds_paso1"
                type="hidden"
                name="JWT"
                value={AccessToken}
              />
            </form>
          </div>
        )
      }

      <div className={classes.root}>
        {enlacePagado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkYaFuePagado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceCaducado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCaducado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : enlaceAnulado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkAnulado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ): enlaceCancelado ? (
          <div
            style={{
              paddingTop: '3%',
              paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
              paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <img
                    width={width >= 700 ? width * 0.15 : 200}
                    src={Images.instance.logo}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    width={width >= 700 ? width * 0.5 : 350}
                    src={Images.instance.linkCancelado}
                    alt=''
                    onClick={() => (window.location.href = '/')}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          showConfirmComponent
            ?
            <ConfirmPayment businessName={nombreEmpresa} />
            :
              <div
                style={{
                  paddingTop: '3%',
                  paddingLeft: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
                  paddingRight: width > 900 ? '25%' : width > 700 ? '15%' : '3%',
                }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <div>
                      <img
                        width={width >= 700 ? width * 0.15 : 200}
                        src={Images.instance.logo}
                        alt=''
                        onClick={() => (window.location.href = '/')}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div style={{ paddingTop: '5%' }}>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        style={{
                          fontWeight: 550,
                          color: 'black',
                          textAlign: 'center',
                        }}
                        variant='h3'
                      >
                        {nombreEmpresa}
                      </Typography>
                      <Typography
                        style={{
                          color: 'black',
                          textAlign: 'center',
                        }}
                        variant='subtitle1'
                      >
                        Te envió un link de paggo {pagoRapido ? 'rápido' : ''}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Información de:
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontWeight: 500,
                            color: 'black',
                            display: 'inline',
                          }}
                        >
                          {' ' + nombreEmpresa}
                        </Typography>
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Correo electrónico:
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontWeight: 400,
                            color: '#5166AF',
                            display: 'inline',
                          }}
                        >
                          {' ' + email}
                        </Typography>
                      </Typography>

                      {!pagoRapido ? (
                        <>
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: grisFigma,
                            }}
                          >
                            Monto a pagar:
                            <Typography
                              variant='subtitle1'
                              style={{
                                fontWeight: 500,
                                color: 'black',
                                display: 'inline',
                              }}
                            >
                              {' Q' + monto}
                            </Typography>
                          </Typography>

                          {cantidadCuotas.length > 0 ? (
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: grisFigma,
                              }}
                            >
                              Pago {`${cantidadCuotas == 'Contado' ? 'de' : 'en'} ${cantidadCuotas}`}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {cantidadCuotas.includes('cuotas') ? (
                            <Typography
                              variant='subtitle1'
                              style={{
                                fontSize: '12px',
                                color: grisFigma,
                              }}
                            >
                              <Typography
                                component='span'
                                color='error'
                              >
                                *
                              </Typography>{' '}
                              Tarjetas emitidas por BAC no son aceptadas por Visa Cuotas
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : null}
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                        }}
                      >
                        Fecha: {fecha}
                      </Typography>
                      {!pagoRapido ? (
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: grisFigma,
                          }}
                        >
                          Concepto: {descripcion}
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                          fontWeight: 550,
                        }}
                      >
                        Datos de facturación
                      </Typography>
                    </Grid>

                    {pagoRapido ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingTop: '2%',
                          }}
                        >
                          {conceptoInput()}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingTop: '2%',
                          }}
                        >
                          {montoInput()}
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: pagoRapido ? '2%' : '2%',
                      }}
                    >
                      {nombre()}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: pagoRapido ? '2%' : '2%',
                      }}
                    >
                      {apellido()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {direccionUno()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {direccionDos()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {localidad()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {municipio()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {compania()}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: pagoRapido ? '2%' : '2%',
                      }}
                    >
                      {nitInput()}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: pagoRapido ? '2%' : '2%',
                      }}
                    >
                      {codigoPostal()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {numeroTelefonico()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {emailInput()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: grisFigma,
                          fontWeight: 550,
                        }}
                      >
                        Datos de tarjeta
                        <div style={{ display: 'inline', paddingLeft: '1%' }}>
                          <img
                            width={30}
                            src={Images.instance.iconoMasterCard}
                          />
                          <img
                            width={40}
                            src={Images.instance.iconoVisa}
                          />
                        </div>
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {numeroTarjetaInput()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {nombreTitularInput()}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {expiracionInput()}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: '2%',
                      }}
                    >
                      {cvvInput()}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: '7%',
                      }}
                    >
                      <Button
                        fullWidth={true}
                        color='primary'
                        disabled={loading}
                        variant='contained'
                        className={classes.button}
                        onClick={payLinkPaso1}
                      >
                        Pagar
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        style={{
                          color: '#ABB8C8',
                          textAlign: 'center',
                          fontWeight: 600,
                          paddingTop: 15,
                        }}
                        variant='subtitle2'
                      >
                        <div style={{ display: 'inline', paddingRight: '1%' }}>
                          <img
                            width={15}
                            src={Images.instance.candadoEnlace}
                          />
                        </div>
                        Por políticas de seguridad, tú paggo es seguro.
                      </Typography>
                      <Typography
                        style={{
                          color: '#ABB8C8',
                          textAlign: 'center',
                          paddingBottom: 50,
                        }}
                        variant='subtitle2'
                      >
                        Ni el comercio afiliado, ni Paggo recopila la información de tú tarjeta. Solamente tú puedes ver
                        esta información.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
        )}

        <PageLoader loading={loading} />

      </div>
    </>
  );
}
